import React, { useContext } from 'react';
import useListLoader from 'external/utils/useListLoader';
import usePermissions from 'store/authentication/usePermissions';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import { DeliverablesListQuery } from 'external/components/Deliverable/List/graphql';
import { ExternalPlacementListQuery } from 'external/components/Placement/List/graphql';

import { PLACEMENT_METRICS_FOR_DISPLAY } from 'components/Placement/View/Hidrator';
import { PRODUCTION_EXTERNAL_VIEW_METRICS } from 'components/Metrics/constants/metricTypes';
import BaseSection from '@UIComponents/BaseSection/BaseSection';
import BaseCard from '@UIComponents/BaseCard/BaseCard';

import KeyDetails from 'external/components/KeyDetails/KeyDetails';
import { Campaign } from 'components/Campaign/types';
import { Deliverable } from 'components/Deliverable/types';
import { Placement } from 'components/Placement/types';
import Loading from '@UIComponents/Loading/Loading';

import { ExternalApproval } from 'constants/approvalStatuses';
import CampaignComplete from './Complete/CampaignComplete';
import CampaignSummaryEmptyState from './Empty/CampaignSummaryEmptyState';
import RunningTotals from './Review/RunningTotals/RunningTotals';
import './CampaignSummary.scss';

type CampaignSummaryReviewProps = {
  content: Placement[];
  creators: Deliverable[];
  totalCreators: number;
  totalContent: number;
  campaign?: Campaign | null;
};

enum CampaignState {
  Empty = 'empty',
  Running = 'running',
  Finished = 'finished',
}

function getCampaignState({
  totalCreators,
  totalContent,
  status,
}: {
  totalCreators?: number;
  totalContent?: number;
  status?: string;
}): CampaignState {
  if (status === 'completed') return CampaignState.Finished;

  if (totalCreators || totalContent) return CampaignState.Running;

  return CampaignState.Empty;
}

const templateEmpty: React.ReactNode = (
  /* @ts-ignore */
  <BaseSection title="Welcome to your campaign summary" type="main">
    {/* @ts-ignore */}
    <BaseCard title="Campaign Launch!">
      <CampaignSummaryEmptyState />
    </BaseCard>
  </BaseSection>
);

const templateRunning = ({
  content,
  creators,
  totalCreators,
  totalContent,
  campaign,
}: CampaignSummaryReviewProps) => {
  const { clientAccessTabs } = campaign || {};
  /* @ts-ignore */
  const { listView, listReview, contentView, contentReview } = clientAccessTabs;

  const permissions = {
    enabledCreators: (listView || listReview) && totalCreators,
    enabledContent: (contentView || contentReview) && totalContent,
    enabledCreatorsReview: listReview && totalCreators,
    enabledContentReview: contentReview && totalContent,
  };

  const filteredCreators = creators.filter(
    (creator) =>
      ![ExternalApproval.Approved, ExternalApproval.Rejected].includes(
        /* @ts-ignore */
        creator?.externalApproval,
      ),
  );

  const filteredContent = content.filter((c) => !c?.externalApproval);

  return (
    /* @ts-ignore */
    <BaseSection title="Campaign Summary" type="main" align="center">
      <RunningTotals
        enabledCreators={!!permissions.enabledCreators}
        enabledContent={!!permissions.enabledContent}
        creators={filteredCreators}
        content={filteredContent}
      />
      <KeyDetails />
    </BaseSection>
  );
};

const templateFinished: React.ReactNode = (
  /* @ts-ignore */
  <BaseSection title="Campaign complete" type="main">
    <CampaignComplete />
  </BaseSection>
);

export default function CampaignSummary() {
  const { campaign, campaignId } = useContext(ExternalApplicationContext);
  const campaignsPermissions = usePermissions('campaigns', campaignId);

  const {
    data: creators,
    meta: creatorsMeta,
    loading: loadingCreators,
  } = useListLoader({
    entityName: 'deliverables',
    skip: undefined,
    query: DeliverablesListQuery,
    variables: {
      metricConfig: PRODUCTION_EXTERNAL_VIEW_METRICS,
      sort: 'externalApprovalSortIndex-desc',
      filter: {
        displayToClient: true,
        campaignId,
        executionStatus: {
          phase: 'list',
        },
      },
      campaignsSeeSensitiveData: Boolean(campaignsPermissions?.seeSensitiveData),
    },
    // @ts-ignore
    onCompleted: () => null,
  });

  const {
    data: content,
    meta: contentMeta,
    loading: loadingContent,
  } = useListLoader({
    skip: undefined,
    entityName: 'externalPlacements',
    query: ExternalPlacementListQuery,
    variables: {
      columns: ['_all_'],
      sort: 'executionStatusRelevance-desc',
      summaryMetricConfig: PLACEMENT_METRICS_FOR_DISPLAY,
      filter: {
        campaignId,
      },
      reportingWindow: campaign?.subType?.includes('Performance')
        ? 'monthEnd'
        : campaign?.reportingWindow,
    },
    // @ts-ignore
    onCompleted: () => null,
  });

  const { total: totalCreators }: { total: number } = creatorsMeta || {};
  const { total: totalContent }: { total: number } = contentMeta || {};
  const campaignState: CampaignState = getCampaignState({
    totalCreators,
    totalContent,
    status: campaign?.status,
  });

  if (loadingContent || loadingCreators) return <Loading />;

  return (
    <div data-cy="CampaignSummary" className="CampaignSummary">
      {
        {
          [CampaignState.Empty]: templateEmpty,
          [CampaignState.Running]: templateRunning({
            totalCreators,
            totalContent,
            content,
            creators,
            campaign,
          }),
          [CampaignState.Finished]: templateFinished,
        }[campaignState]
      }
    </div>
  );
}
