import React from 'react';
import Edit03 from '@UIComponents/_assets/react_svg/platforms/Edit03';
import UIModal from 'components/UI/Modal/Modal';
import { Property } from 'components/Property/types';
import PropertyForm from 'components/Creator/Profile/Details/PropertyForm/PropertyForm';
import './EditProperty.scss';
import ApplicationContext from 'components/Application/Context';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import { Campaign } from 'components/Campaign/types';

export default function EditContactInfo({
  property,
  refetchProperty,
  campaign,
}: {
  property?: Property;
  refetchProperty: () => void;
  campaign: Campaign;
}) {
  const [showModal, setShowModal] = React.useState(false);
  const { segment, user } = React.useContext(ApplicationContext);

  const handleSetShowModal = React.useCallback(() => {
    segment.track(
      SegmentEvents.CreatorProfile,
      {
        action: SegmentActions.OpenModal,
        modal: 'Property Form',
        property: {
          id: property?.id,
          name: property?.name,
        },
      },
      {
        context: { groupId: formatCampaignId(campaign?.id) },
        userId: formatUserId(user?.id),
      },
    );
    setShowModal(true);
  }, [campaign?.id, property?.id, property?.name, segment, user?.id]);

  return (
    <>
      <button
        onClick={handleSetShowModal}
        type="button"
        className="edit-contact-button"
      >
        <Edit03 />
      </button>

      <UIModal
        className="edit-contact-modal"
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        {showModal ? (
          <PropertyForm property={property} refetchProperty={refetchProperty} />
        ) : null}
      </UIModal>
    </>
  );
}
