import LinkCellRenderer from 'components/DataGrid/Destination/Cells/Link/LinkCellRenderer';

import baseColDef from './baseColDef';

const linkColDef = {
  ...baseColDef,
  cellRenderer: LinkCellRenderer,
};

export default linkColDef;
