import React from 'react';
import AppHeader from 'external/components/AppHeader/AppHeader';
import LayoutFooter from 'external/components/Footer/Footer';
import classnames from 'classnames';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import './BaseLayout.scss';

type BaseLayoutProps = {
  children: React.ReactNode | React.ReactNodeArray | null;
  className?: string;
};

function BaseLayout({ children, className }: BaseLayoutProps) {
  return (
    <div className={classnames('base-layout__wrapper', className)}>
      {/* @ts-ignore */}
      <ErrorBoundary>
        <AppHeader />
        <div className={classnames('base-layout__content', className)}>
          <div className="base-layout">{children}</div>
        </div>
        <LayoutFooter />
      </ErrorBoundary>
    </div>
  );
}

export default BaseLayout;
