export default function PlusCircle({ fill = 'none' }: { fill?: string }) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0002 11.3333V22.6666M11.3335 17H22.6668M31.1668 17C31.1668 24.824 24.8242 31.1666 17.0002 31.1666C9.17613 31.1666 2.8335 24.824 2.8335 17C2.8335 9.17595 9.17613 2.83331 17.0002 2.83331C24.8242 2.83331 31.1668 9.17595 31.1668 17Z"
        stroke="#BFBFBF"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
