import React from 'react';
import MetadataPill from 'external/components/UI/MetadataPill/MetadataPill';

type DetailsPanelMetricProps = {
  label: string;
  children: React.ReactElement | string;
  prediction?: boolean;
  showLive30Message?: boolean;
};

function DetailsPanelMetric({
  label,
  children,
  prediction = false,
  showLive30Message = false,
}: DetailsPanelMetricProps) {
  return (
    <div className="external-content__detail-panel__channel__data_wrapper">
      <MetadataPill label={`${label}`}>
        <div>
          <span className="external-content__detail-panel__channel__data__value">
            {children}
          </span>
          {prediction && showLive30Message && ' *'}
        </div>
      </MetadataPill>
    </div>
  );
}

export default DetailsPanelMetric;
