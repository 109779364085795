import { string, func, shape } from 'prop-types';
import UITable from 'components/UI/Table/ModernTable';
import UITableHeader from 'components/UI/Table/Header';
import UITableHCol from 'components/UI/Table/HCol';
import UITableCol from 'components/UI/Table/Col';
import UITableBody from 'components/UI/Table/Body';
import UITableRow from 'components/UI/Table/Row';
import UINumber from 'components/UI/Number/Number';
import { REPORTING_WINDOW_OPTIONS } from 'constants/constants';
import { PERFORMANCE_METRIC_WINDOW } from 'components/Placement/constants';
import { toKeysMap } from 'utils/array';

import './PerformanceTable.scss';

const metricWindows = toKeysMap(PERFORMANCE_METRIC_WINDOW, 'value');

const getRows = (rollupMetricSummary, channelDefault) => {
  const result = {};
  const rw = [...REPORTING_WINDOW_OPTIONS, 'defaultReportingWindow'];

  rollupMetricSummary.forEach(({ reportingWindow, value }) => {
    if (!result[reportingWindow]) {
      result[reportingWindow] = {
        value: null,
        reportingWindow,
      };
    }
    if (value) result[reportingWindow].value += value;
  });
  result.defaultReportingWindow = {
    ...result[channelDefault],
    reportingWindow: 'defaultReportingWindow',
  };

  return Object.values(result).sort(
    (a, b) => rw.indexOf(a.reportingWindow) - rw.indexOf(b.reportingWindow),
  );
};

function PlacementViewPerformanceTable({
  cbn,
  channelDefault,
  channelName,
  mediaValues,
  renderLabel,
  rollupMetricSummary,
  shouldRender,
}) {
  const showMediaValue = ['film', 'tv'].includes(channelName);
  return (
    <>
      <div className={`${cbn}__content__block__title`}>Performance</div>
      <div className={`${cbn}__content`}>
        <UITable className="placement-view__performance-table">
          <UITableHeader>
            <UITableHCol id="labels" />
            {shouldRender('performanceSection.impressions') && (
              <UITableHCol id="impressions">
                {renderLabel('performanceSection.impressions', 'Impressions')}
              </UITableHCol>
            )}
            {shouldRender('performanceSection.mediaValue') &&
              showMediaValue && (
                <UITableHCol id="mediaValue">
                  {renderLabel('performanceSection.mediaValue', 'Media Value')}
                </UITableHCol>
              )}
          </UITableHeader>
          <UITableBody
            data={getRows(rollupMetricSummary, channelDefault)}
            render={(row) =>
              (shouldRender(`performanceSection.${row.reportingWindow}`) ||
                (channelDefault === row.reportingWindow &&
                  shouldRender('performanceSection.channelDefault'))) && (
                  <UITableRow key={row.id}>
                    <UITableCol id="labels">
                      {renderLabel(
                      `performanceSection.${row.reportingWindow}`,
                      metricWindows[row.reportingWindow].label,
                    )}
                    </UITableCol>
                    {shouldRender('performanceSection.impressions') && (
                    <UITableCol id="impressions" component={UINumber}>
                      {row.value}
                    </UITableCol>
                  )}
                    {shouldRender('performanceSection.mediaValue') &&
                    showMediaValue && (
                      <UITableCol
                        id="mediaValue"
                        component={UINumber}
                        prefix="$"
                      >
                        {
                          mediaValues?.[
                            row.reportingWindow === 'defaultReportingWindow'
                              ? channelDefault
                              : row.reportingWindow
                          ]
                        }
                      </UITableCol>
                    )}
                  </UITableRow>
              )
            }
          />
        </UITable>
      </div>
    </>
  );
}

PlacementViewPerformanceTable.propTypes = {
  cbn: string.isRequired,
  channelName: string.isRequired,
  channelDefault: string.isRequired,
  mediaValues: shape({}),
  rollupMetricSummary: shape({}),
  renderLabel: func.isRequired,
  shouldRender: func.isRequired,
};

PlacementViewPerformanceTable.defaultProps = {
  mediaValues: {},
  rollupMetricSummary: [],
};

export default PlacementViewPerformanceTable;
