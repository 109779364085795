import {
  INFLUENCER_MEDIA_TARGETS,
  FILM_PLATFORM,
  SEASON_PLATFORM,
  YOUTUBE_PLATFORM,
  INSTAGRAM_PLATFORM,
  FACEBOOK_PLATFORM,
  TWITTER_PLATFORM,
  TWITCH_PLATFORM,
  TIKTOK_PLATFORM,
  OTHER_PLATFORM,
  VIDEO_MEDIA_TYPE,
  REELS_MEDIA_TYPE,
  LIVE_MEDIA_TYPE,
  STORY_MEDIA_TYPE,
  STREAM_MEDIA_TYPE,
  POST_MEDIA_TYPE,
  PHOTO_MEDIA_TYPE,
  EPISODE_MEDIA_TYPE,
  FILM_MEDIA_TYPE,
  PRODUCTION_MEDIA_TYPE,
  VIEWS,
  CLICKS,
  INTEGRATION_STYLE,
  THEMATIC_STYLE,
  DEDICATED_STYLE,
} from './kpis';

export {
  VIDEO_MEDIA_TYPE,
  REELS_MEDIA_TYPE,
  LIVE_MEDIA_TYPE,
  STORY_MEDIA_TYPE,
  STREAM_MEDIA_TYPE,
  POST_MEDIA_TYPE,
  PHOTO_MEDIA_TYPE,
  EPISODE_MEDIA_TYPE,
  FILM_MEDIA_TYPE,
  PRODUCTION_MEDIA_TYPE,
  INFLUENCER_MEDIA_TARGETS,
  VIEWS,
  CLICKS,
  INTEGRATION_STYLE,
  THEMATIC_STYLE,
  DEDICATED_STYLE,
  FILM_PLATFORM,
  SEASON_PLATFORM,
  YOUTUBE_PLATFORM,
  INSTAGRAM_PLATFORM,
  FACEBOOK_PLATFORM,
  TWITTER_PLATFORM,
  TWITCH_PLATFORM,
  TIKTOK_PLATFORM,
  OTHER_PLATFORM,
};

export const PLATFORMS_DISPLAY_MAP = {
  [FILM_PLATFORM]: 'Film',
  [SEASON_PLATFORM]: 'Season',
  [YOUTUBE_PLATFORM]: 'YouTube',
  [INSTAGRAM_PLATFORM]: 'Instagram',
  [FACEBOOK_PLATFORM]: 'Facebook',
  [TWITTER_PLATFORM]: 'Twitter',
  [TWITCH_PLATFORM]: 'Twitch',
  [TIKTOK_PLATFORM]: 'Tiktok',
  [OTHER_PLATFORM]: 'Other',
};

export const SUBSCRIBERS = {
  metricType: 'subscribers',
  mediaType: 'production',
  style: '*',
  reportingWindow: 'allTime',
};

export const REVENUE = {
  metricType: 'revenue',
  style: null,
};

export const CONVERSIONS = {
  metricType: 'conversions',
  style: null,
};

export const VIEW_DURATION = {
  metricType: 'viewDuration',
  style: null,
};

export const DURATION_IN_HOURS = {
  metricType: 'durationInHours',
  style: null,
  format: '0.[00]',
  formatter: 'decimal',
};

export const CCV = {
  metricType: 'ccv',
  style: null,
};
export const CR = {
  metricType: 'cr',
  style: null,
};
export const CTR = {
  metricType: 'ctr',
  style: null,
};
export const VPC = {
  metricType: 'vpc',
  style: null,
};
export const ACTIONS = {
  metricType: 'actions',
  style: null,
};

export const LIKES = {
  metricType: 'likes',
  style: null,
};

export const DISLIKES = {
  metricType: 'dislikes',
  style: null,
};

export const COMMENTS = {
  metricType: 'comments',
  style: null,
};

export const SHARES = {
  metricType: 'shares',
  style: null,
};

export const SAVES = {
  metricType: 'saves',
  style: null,
};

export const STICKER_TAPS = {
  metricType: 'stickerTaps',
  style: null,
};

export const CONTENT_PIECES = { metricType: 'contentPieces', style: null };
export const ESTIMATED_MINUTES_WATCHED = {
  metricType: 'estimatedMinutesWatched',
  style: null,
};
export const MAX_CONCURRENTS = { metricType: 'maxConcurrents', style: null };
export const AVG_CONCURRENTS = {
  metricType: 'avgConcurrents',
  style: null,
};

export const IMPRESSIONS = {
  metricType: 'impressions',
  style: null,
};

export const AVERAGE_ENGAGEMENT = {
  metricType: 'averageEngagement',
  mediaType: 'video',
  style: null,
};

export const AVERAGE_ENGAGEMENT_RATE = {
  metricType: 'averageEngagementRate',
  mediaType: 'video',
  style: null,
  format: '0.[00]%',
  formatter: 'percent',
};

export const ENGAGEMENT_RATE = {
  metricType: 'engagementRate',
  mediaType: 'video',
  style: null,
  format: '0.[00]%',
  formatter: 'percent',
};
export const FOLLOWERS = {
  metricType: 'followers',
  mediaType: 'production',
  style: null,
  reportingWindow: 'allTime',
};
export const BOT_PERCENTAGE = {
  metricType: 'botPercentage',
  mediaType: 'production',
  style: null,
  reportingWindow: 'allTime',
};
export const AVERAGE_VIEWS = {
  metricType: 'averageViews',
  mediaType: 'stream',
  style: null,
};

export const LIVE_VIEWS = {
  metricType: 'liveViews',
  mediaType: 'stream',
  style: null,
};
export const UNIQUE_VISITORS = {
  metricType: 'uniqueVisitors',
  mediaType: 'stream',
  style: null,
};
export const MINUTES_STREAMED = {
  metricType: 'minutesStreamed',
  mediaType: 'stream',
  style: null,
};
export const CHAT_VOLUME = {
  metricType: 'chatVolume',
  mediaType: 'stream',
  style: null,
};

export const TRADITIONAL_MEDIA_TARGETS = {
  [SEASON_PLATFORM]: [
    { mediaType: EPISODE_MEDIA_TYPE, style: null, defaultKpi: 'impressions' },
  ],
  [FILM_PLATFORM]: [
    { mediaType: FILM_MEDIA_TYPE, style: null, defaultKpi: 'impressions' },
  ],
  ...INFLUENCER_MEDIA_TARGETS,
  [OTHER_PLATFORM]: [
    { mediaType: VIDEO_MEDIA_TYPE, style: null, defaultKpi: VIEWS.metricType },
    { mediaType: STORY_MEDIA_TYPE, style: null, defaultKpi: VIEWS.metricType },
    { mediaType: STREAM_MEDIA_TYPE, style: null, defaultKpi: 'ccv' },
    { mediaType: POST_MEDIA_TYPE, style: null, defaultKpi: 'contentPieces' },
    { mediaType: PHOTO_MEDIA_TYPE, style: null, defaultKpi: 'contentPieces' },
  ],
};

export const PLACEMENT_METRICS = [
  CLICKS.metricType,
  CONVERSIONS.metricType,
  REVENUE.metricType,
];

export const METRICS_PER_MEDIA_TYPE = {
  [POST_MEDIA_TYPE]: [
    { ...CONTENT_PIECES, notAllowedPlatforms: ['twitter'] },
    LIKES,
    { ...DISLIKES, notAllowedPlatforms: ['twitter'] },
    COMMENTS,
    SHARES,
    CLICKS,
    CONVERSIONS,
    REVENUE,
    {
      ...IMPRESSIONS,
      allowedPlatforms: ['twitter'],
    },
  ],
  [PHOTO_MEDIA_TYPE]: [
    { ...CONTENT_PIECES, notAllowedPlatforms: ['twitter'] },
    LIKES,
    { ...DISLIKES, notAllowedPlatforms: ['instagram'] },
    COMMENTS,
    SHARES,
    CLICKS,
    CONVERSIONS,
    REVENUE,
    {
      ...IMPRESSIONS,
      allowedPlatforms: ['instagram'],
    },
  ],
  [VIDEO_MEDIA_TYPE]: [
    VIEWS,
    AVERAGE_VIEWS,
    AVERAGE_ENGAGEMENT,
    ENGAGEMENT_RATE,
    AVERAGE_ENGAGEMENT_RATE,
    LIKES,
    { ...DISLIKES, notAllowedPlatforms: ['twitter', 'tiktok'] },
    COMMENTS,
    SHARES,
    CLICKS,
    CONVERSIONS,
    REVENUE,
  ],
  [STORY_MEDIA_TYPE]: [
    VIEWS,
    { ...LIKES, notAllowedPlatforms: [INSTAGRAM_PLATFORM] },
    { ...DISLIKES, notAllowedPlatforms: [INSTAGRAM_PLATFORM] },
    { ...COMMENTS, notAllowedPlatforms: [INSTAGRAM_PLATFORM] },
    SHARES,
    CLICKS,
    CONVERSIONS,
    REVENUE,
    { ...STICKER_TAPS, allowedPlatforms: [INSTAGRAM_PLATFORM] },
  ],
  [STREAM_MEDIA_TYPE]: [
    MAX_CONCURRENTS,
    AVG_CONCURRENTS,
    {
      ...ESTIMATED_MINUTES_WATCHED,
      allowedPlatforms: ['youtube', 'other'],
    },
    { ...CCV, allowedPlatforms: ['youtube', 'other'] },
    CLICKS,
    CONVERSIONS,
    REVENUE,
    VIEW_DURATION,
    DURATION_IN_HOURS,
    {
      ...SUBSCRIBERS,
      allowedPlatforms: ['twitch'],
      allowedCampaigns: ['performance'],
    },
    { ...MINUTES_STREAMED, allowedPlatforms: ['twitch'] },
    { ...LIVE_VIEWS, allowedPlatforms: ['twitch'] },
    { ...UNIQUE_VISITORS, allowedPlatforms: ['twitch'] },
    { ...CHAT_VOLUME, allowedPlatforms: ['twitch'] },
  ],
  [REELS_MEDIA_TYPE]: [
    VIEWS,
    CLICKS,
    { ...LIKES, allowedPlatforms: [INSTAGRAM_PLATFORM] },
    { ...COMMENTS, allowedPlatforms: [INSTAGRAM_PLATFORM] },
    { ...SHARES, allowedPlatforms: [INSTAGRAM_PLATFORM] },
    CONVERSIONS,
    REVENUE,
    { ...SAVES, allowedPlatforms: [INSTAGRAM_PLATFORM] },
  ],
  [LIVE_MEDIA_TYPE]: [VIEWS, CLICKS, CONVERSIONS, REVENUE],
  [EPISODE_MEDIA_TYPE]: [
    {
      ...IMPRESSIONS,
      allowedPlatforms: [FILM_PLATFORM, SEASON_PLATFORM],
    },
  ],
  [FILM_MEDIA_TYPE]: [
    {
      ...IMPRESSIONS,
      allowedPlatforms: [FILM_PLATFORM, SEASON_PLATFORM],
    },
  ],
};

export const COMMON_METRIC_TYPES_WITHOUT_DEMOGRAPHICS = [
  'views',
  'clicks',
  'conversions',
  'likes',
  'dislikes',
  'revenue',
  'comments',
  'shares',
  'ccv',
  'engagementRate',
  'averageEngagementRate',
  'maxConcurrents',
  'avgConcurrents',
  'durationInHours',
  'estimatedMinutesWatched',
  'viewDuration',
  'contentPieces',
  'subscribers',
  'liveViews',
  'minutesStreamed',
  'uniqueVisitors',
  'chatVolume',
  'impressions',
  SAVES.metricType,
  STICKER_TAPS.metricType,
];
export const AWARENESS_METRIC_TYPES_WITHOUT_DEMOGRAPHICS = [
  ...COMMON_METRIC_TYPES_WITHOUT_DEMOGRAPHICS,
  'averageViewDuration',
];

export const PERFORMANCE_METRIC_TYPES_WITHOUT_DEMOGRAPHICS = [
  ...COMMON_METRIC_TYPES_WITHOUT_DEMOGRAPHICS,
  'downloads',
  'cpconv',
  'brandcac',
];
