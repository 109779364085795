import {
  InfluencerMediaTargets,
  MetricDefinition,
} from '../types/MediaTargets';

export const VIDEO_MEDIA_TYPE = 'video';
export const REELS_MEDIA_TYPE = 'reels';
export const LIVE_MEDIA_TYPE = 'live';
export const STORY_MEDIA_TYPE = 'story';
export const STREAM_MEDIA_TYPE = 'stream';
export const POST_MEDIA_TYPE = 'post';
export const PHOTO_MEDIA_TYPE = 'photo';
export const EPISODE_MEDIA_TYPE = 'episode';
export const FILM_MEDIA_TYPE = 'film';
export const PRODUCTION_MEDIA_TYPE = 'production';

export const INTEGRATION_STYLE = 'integration';
export const THEMATIC_STYLE = 'thematic';
export const DEDICATED_STYLE = 'dedicated';
export const FILM_PLATFORM = 'film';
export const SEASON_PLATFORM = 'season';
export const YOUTUBE_PLATFORM = 'youtube';
export const INSTAGRAM_PLATFORM = 'instagram';
export const FACEBOOK_PLATFORM = 'facebook';
export const TWITTER_PLATFORM = 'twitter';
export const TWITCH_PLATFORM = 'twitch';
export const TIKTOK_PLATFORM = 'tiktok';
export const OTHER_PLATFORM = 'other';

export const VIEWS: MetricDefinition = {
  metricType: 'views',
  style: null,
};

export const CLICKS: MetricDefinition = {
  metricType: 'clicks',
  style: null,
};

export const kpiLabelMap: Record<string, string> = {
  clicks: 'Clicks',
  views: 'Views',
  ccv: 'CCV',
  contentPieces: 'Content Pieces',
  impressions: 'Impressions',
};

export const INFLUENCER_MEDIA_TARGETS: InfluencerMediaTargets = {
  [YOUTUBE_PLATFORM]: [
    {
      mediaType: VIDEO_MEDIA_TYPE,
      style: INTEGRATION_STYLE,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: VIDEO_MEDIA_TYPE,
      style: THEMATIC_STYLE,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: VIDEO_MEDIA_TYPE,
      style: DEDICATED_STYLE,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: STREAM_MEDIA_TYPE,
      style: null,
      defaultKpi: 'ccv',
      allowedKpis: [CLICKS.metricType, 'ccv', 'contentPieces'],
    },
  ],
  [INSTAGRAM_PLATFORM]: [
    {
      mediaType: VIDEO_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: STORY_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: PHOTO_MEDIA_TYPE,
      style: null,
      defaultKpi: 'contentPieces',
      allowedKpis: [CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: REELS_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: LIVE_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
  ],
  [TWITTER_PLATFORM]: [
    {
      mediaType: VIDEO_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: POST_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
  ],
  [FACEBOOK_PLATFORM]: [
    {
      mediaType: VIDEO_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: POST_MEDIA_TYPE,
      style: null,
      defaultKpi: 'contentPieces',
      allowedKpis: [CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: LIVE_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS.metricType, 'contentPieces'],
    },
  ],
  [TWITCH_PLATFORM]: [
    {
      mediaType: STREAM_MEDIA_TYPE,
      style: null,
      defaultKpi: 'ccv',
      allowedKpis: [CLICKS.metricType, 'ccv', 'contentPieces'],
    },
  ],
  [TIKTOK_PLATFORM]: [
    {
      mediaType: VIDEO_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [CLICKS.metricType, VIEWS.metricType, 'contentPieces'],
    },
  ],
  [OTHER_PLATFORM]: [
    {
      mediaType: VIDEO_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [CLICKS?.metricType, VIEWS.metricType, 'contentPieces'],
    },
    {
      mediaType: STORY_MEDIA_TYPE,
      style: null,
      defaultKpi: VIEWS.metricType,
      allowedKpis: [VIEWS.metricType, CLICKS?.metricType, 'contentPieces'],
    },
    {
      mediaType: STREAM_MEDIA_TYPE,
      style: null,
      defaultKpi: 'ccv',
      allowedKpis: [CLICKS.metricType, 'ccv', 'contentPieces'],
    },
    {
      mediaType: POST_MEDIA_TYPE,
      style: null,
      defaultKpi: 'contentPieces',
      allowedKpis: [CLICKS.metricType, 'contentPieces'],
    },
    {
      mediaType: PHOTO_MEDIA_TYPE,
      style: null,
      defaultKpi: 'contentPieces',
      allowedKpis: [CLICKS.metricType, 'contentPieces'],
    },
  ],
};
