import { GridApi } from 'ag-grid-community';

type GridParams = {
  api: GridApi;
} | null;

function buildContent(elements: (HTMLElement | Text)[]): HTMLElement {
  const content = document.createElement('div');
  content.setAttribute('style', 'display: none');
  content.setAttribute('class', 'ag-status-name-value');

  elements.forEach((el: HTMLElement | Text) => content.appendChild(el));

  return content;
}

export default class RowsCountStatusBar {
  static componentName = 'rowsCountStatusBar';

  api: GridApi | undefined;

  eGui: (HTMLElement & Node) | undefined;

  $content: HTMLElement | undefined;

  $value: HTMLElement | undefined;

  $totalResults: HTMLElement | undefined;

  init(params: GridParams) {
    this.api = params?.api;

    this.eGui = document.createElement('div');
    this.eGui.setAttribute(
      'style',
      ['display: flex', 'align-items: center', 'min-height: 40px'].join(';'),
    );

    const label = document.createElement('span');
    const space = () => document.createTextNode('\u00A0');
    const value = document.createElement('span');
    const totalResults = document.createElement('span');

    label.innerText = 'Results:';
    value.setAttribute('class', 'ag-status-name-value-value');

    const content = buildContent([
      label,
      space(),
      value,
      space(),
      totalResults,
    ]);

    this.$content = content;
    this.$value = value;
    this.$totalResults = totalResults;
    this.eGui.appendChild(content);
  }

  clearTotalResults() {
    // @ts-ignore
    this.$totalResults?.replaceChildren(document.createTextNode(''));
  }

  setTotalResults(value: number) {
    const total = document.createTextNode(`(Total ${value})`);
    // @ts-ignore
    this.$totalResults?.replaceChildren(total);
  }

  updateTotalRows() {
    const store = this.api?.getServerSideStoreState()[0];

    if (store?.info.loaded) {
      const rowsCount = this.api?.getServerSideStoreState()[0]?.rowCount;
      this.$content?.setAttribute('style', 'display: initial');
      // @ts-ignore
      this.$value?.replaceChildren(document.createTextNode(`${rowsCount}`));
    }
  }

  getGui() {
    return this.eGui;
  }
}

RowsCountStatusBar.componentName = 'rowsCountStatusBar';
