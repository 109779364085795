import { MetricsTableRow } from './types';
import MetricRow from './MetricRow/MetricRow';

import './SimpleMetricsTable.scss';

export default function SimpleMetricsTable({
  rows,
  title,
  className,
}: {
  rows: MetricsTableRow[];
  title?: string;
  className?: string;
}) {
  return (
    <section className={`simple-metrics-table ${className ?? ''}`}>
      {title ? <h3 className="simple-metrics-table__title">{title}</h3> : null}

      {rows.map((row) => (
        <MetricRow key={row?.label} row={row} />
      ))}
    </section>
  );
}
