import { CreatorColDef } from 'shared/types/Campaign';

export const nonAgGridColKeys: Array<keyof CreatorColDef> = [
  'id',
  'custom',
  'index',
  'pin',
  'color',
  'group',
  'clientAccess',
  'displayed',
  'parentGroup',
  'columnName',
  'type',
  'dropdownOptions',
  'hideHeaderName',
];
