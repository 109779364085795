import React from 'react';
import { number, oneOfType, bool, node } from 'prop-types';
import classnames from 'classnames';

import './IconDoubleCounter.scss';

const cbn = 'ui-icon-double-counter';

function UIIconDoubleCounter({
  children,
  count,
  inline,
  isRow,
  otherCount,
  showZero,
}) {
  return (
    <span
      className={classnames(cbn, {
        [`${cbn}--inline`]: inline,
      })}
    >
      {children}
      <span
        className={classnames(`${cbn}__value`, {
          [`${cbn}__row`]: isRow,
        })}
      >
        {(count || showZero) && (
          <span className={classnames(`${cbn}__count`)}>{count || 0}</span>
        )}
        {(otherCount || showZero) && (
          <>
            {(count || showZero) && (
              <span className={classnames(`${cbn}__divider`)}>/</span>
            )}
            <span className={classnames(`${cbn}__other-count`)}>
              {otherCount || 0}
            </span>
          </>
        )}
      </span>
    </span>
  );
}

UIIconDoubleCounter.propTypes = {
  children: node.isRequired,
  count: oneOfType([number, bool, node]),
  inline: bool,
  isRow: bool,
  otherCount: oneOfType([number, bool, node]),
  showZero: bool,
};

UIIconDoubleCounter.defaultProps = {
  count: false,
  inline: false,
  isRow: false,
  otherCount: false,
  showZero: false,
};

export default UIIconDoubleCounter;
