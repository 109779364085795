import {
  GridApi,
  IServerSideDatasource,
  RowNode,
  ColDef,
  SelectionChangedEvent,
  ServerSideStoreType,
  DetailGridInfo,
  GridReadyEvent,
} from 'ag-grid-community';
import { onPostSort } from 'components/Campaign/Influencer/View/shared/utils';
import { loadSavedState } from './savedState';
import RowsCountStatusBar from '../components/RowsCountStatusBar';

const toDataById = (data: RowNode[]): { [index: number]: RowNode } =>
  data.reduce(
    (acc, row) => ({
      ...acc,
      [Number(row.id)]: row,
    }),
    {},
  );

const hasFilter = (value?: boolean) => {
  if (typeof value === 'boolean') return value;

  return Boolean(value);
};

const getContextMenuItems = ({ api }: { api: GridApi }) => {
  const selectedRows = api.getSelectedNodes();
  const hasSelectedRows = Boolean(selectedRows.length);

  return [
    'copy',
    'copyWithHeaders',
    'copyWithGroupHeaders',
    'paste',
    'chartRange',
    {
      name: 'Export as CSV',
      icon:
        '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
      action: () => api.exportDataAsCsv({ onlySelected: hasSelectedRows }),
    },
    {
      name: 'Export as Excel',
      icon:
        '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
      action: () => api.exportDataAsExcel({ onlySelected: hasSelectedRows }),
    },
  ];
};

// eslint-disable-next-line
export default function getRenderingSpecificProps(
  columnDefs: ColDef[] | undefined,
  columnStateKey: string | null | undefined,
  datasource: IServerSideDatasource | null | undefined,
  gridApi: GridReadyEvent | undefined,
  infinityGrid: boolean,
  onGridReady: (arg0: GridReadyEvent) => void,
  onSelectionChanged: ((arg0: SelectionChangedEvent) => void) | undefined,
  rowData: Record<string, unknown>[] | undefined,
  rowsCount: number,
  rowsTotalResult: number,
  setGridApi: ((arg0: GridReadyEvent) => void) | undefined,
  setRowsCount: (arg0: number) => void,
  setRowsTotalResult: (arg0: number) => void,
  campaignSubType: string,
) {
  if (infinityGrid) {
    return {
      onPostSort,
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial' as ServerSideStoreType,
      blockLoadDebounceMillis: 200,
      rowBuffer: 50,
      cacheBlockSize: 100,
      suppressScrollOnNewData: true,
      maxBlocksInCache: 5,
      rowsCount,
      serverSideDatasource: datasource,
      onGridReady: (params: GridReadyEvent) => {
        if (setGridApi) {
          setGridApi(params);
        }
        // @ts-ignore
        if (datasource?.initialFilters) {
          // @ts-ignore
          const models = Object.entries(datasource.initialFilters).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: {
                type: 'set',
                values: value,
              },
            }),
            {},
          );
          params.api.setFilterModel(models);
        }

        if (!columnStateKey) {
          return;
        }
        loadSavedState(
          params.columnApi,
          columnStateKey,
          infinityGrid,
          campaignSubType,
        );
      },
      onRowDataUpdated: ({ api }: { api: GridApi }) => {
        const rows = [];
        api.forEachNode((rowNode) => {
          rows.push(rowNode.data);
        });
        setRowsCount(rows?.length ?? 0);
      },
      getContextMenuItems,
      onSelectionChanged,
      onPaginationChanged: ({ api }: { api: GridApi }) => {
        const statusBarComponent = api.getStatusPanel(
          RowsCountStatusBar.componentName,
        );

        if (statusBarComponent) {
          // If any filter is applied we set total results in RowsCountStatusBar;
          const filters = api?.getFilterModel() || {};
          const hasAppliedFilters = Object.keys(filters).some(
            (key) => filters[key].value,
          );
          if (hasAppliedFilters) {
            // @ts-ignore
            statusBarComponent.setTotalResults(rowsTotalResult);
          } else {
            setRowsTotalResult(
              gridApi?.api?.getServerSideGroupLevelState()[0]?.rowCount || 0,
            );

            // @ts-ignore
            statusBarComponent.clearTotalResults();
          }

          // @ts-ignore
          statusBarComponent.updateTotalRows();
        }
      },
      updateEntity: (data: RowNode[], flashCells: boolean) => {
        if (!gridApi) {
          return;
        }
        const dataById = toDataById(data);
        const rowNodes: RowNode[] = [];

        gridApi.api.forEachNode((rowNode) => {
          if (dataById[rowNode?.data?.id]) {
            /* @ts-ignore */
            rowNodes.push(rowNode);

            const updated = Object.assign(
              rowNode.data,
              dataById[rowNode.data.id],
            );
            rowNode.setData(updated);
          }
        });

        if (flashCells) {
          gridApi.api.flashCells({ rowNodes });
        }
      },
      columnDefs: (columnDefs ?? []).map((c) => ({
        ...c,
        suppressMenu: !hasFilter(c.filter),
        unSortIcon: c.sortable,
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: ['contains', 'notContains'],
          ...c.filterParams,
        },
        comparator: undefined,
        enableRowGroup: false,
      })),
      getRowNodeId: (params?: DetailGridInfo) => params?.id as unknown,
    };
  }
  return {
    rowModelType: 'clientSide',
    rowData: rowData ?? [],
    columnDefs,
    onGridReady,
    getContextMenuItems,
    onSelectionChanged,
  };
}
