import React from 'react';
import {
  Period,
  OnCreateAd,
  OnCloseModal,
} from 'components/Tiktok/TiktokSparkAds/CreateSparkAd/types';
import BaseButton from '@UIComponents/BaseButton/BaseButton';

import { SparkAdsSegmentEvents } from '../../hooks/useSegmentEvents';

import './CreateSubmit.scss';

export default function CreateSubmit({
  onCreateAd,
  onCloseModal,
  period,
  segmentEvents,
}: {
  onCreateAd: OnCreateAd;
  onCloseModal: OnCloseModal;
  period: Period | null;
  segmentEvents?: SparkAdsSegmentEvents;
}) {
  const sendRequest = React.useCallback(() => {
    onCreateAd();

    if (segmentEvents?.trackSendRequest) {
      segmentEvents.trackSendRequest();
    }
  }, [onCreateAd, segmentEvents]);

  const cancelRequest = React.useCallback(() => {
    onCloseModal();

    if (segmentEvents?.trackCancelRequest) {
      segmentEvents.trackCancelRequest();
    }
  }, [onCloseModal, segmentEvents]);

  return (
    <div className="tiktok-spark-ads__create__submit">
      <div className="tiktok-spark-ads__create__submit__buttons">
        <BaseButton
          className="send-request"
          color="purple"
          disabled={!period?.value}
          onClick={sendRequest}
          rounded="rounded-sm"
          text="Send Request"
        />
        <BaseButton
          className="cancel-request"
          color="grey-dark"
          onClick={cancelRequest}
          text="Cancel"
          variant="text"
        />
      </div>
      <p className="tiktok-spark-ads__create__submit__pro-tip">
        <strong>Pro Tip:</strong>{' '}
        <span>
          You can find details about this specific spark ad content by clicking
          on the Spark Ads badge in the the Post Management grid.
        </span>
      </p>
    </div>
  );
}
