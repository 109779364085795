import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';

import './RadioButton.scss';

type RadioButtonProps = {
  checked: boolean;
  label: string;
  onClick: (arg1: React.ChangeEvent<HTMLInputElement>) => void
  value: string;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  checked = false,
  label,
  onClick,
  value,
}) => (
  <label className="RadioButton">
    <input
      type="radio"
      className="radio-button-input"
      value={value}
      name={label}
      onChange={onClick}
      checked={checked}
    />
    <BaseIcon
      icon={
          checked
            ? 'radio_button_checked'
            : 'radio_button_unchecked'
        }
    />
    <span className="radio-button-label">{label}</span>
  </label>
);

export default RadioButton;
