import React from 'react';
import Select, { GroupBase, SingleValue } from 'react-select';
import {
  reportingWindows,
  reportingWindowsGrouped,
  reportingWindowsGroupedWidthDateDisplay,
} from 'shared/campaigns/creatorCampaigns/reportingWindows';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import OptionWithDates from './SelectorComponents/OptionWithDates';
import './ReportingWindowSelector.scss';
import { ReportingWindowOption } from '../types/ReportingWindow';

const reportingWindowsOptions = Object.entries(reportingWindows).map(
  (entry) =>
    ({ value: { key: entry[0] }, label: entry[1] } as ReportingWindowOption),
);

export default function ReportingWindowSelector({
  value,
  onChange,
  campaignDateRangeFrom,
  postReleaseDate,
  campaignReportingWindows,
}: {
  value: string | null;
  onChange: (arg0: SingleValue<ReportingWindowOption>) => void;
  campaignDateRangeFrom?: string;
  postReleaseDate?: string;
  campaignReportingWindows?: string[] | null;
}) {
  const defaultValue: ReportingWindowOption | undefined = React.useMemo(
    () => reportingWindowsOptions.find((option) => option.value?.key === value),
    [value],
  );

  const hasCalculatedDates = React.useMemo(
    () => campaignDateRangeFrom ?? postReleaseDate,
    [campaignDateRangeFrom, postReleaseDate],
  );

  const options: GroupBase<ReportingWindowOption>[] = React.useMemo(() => {
    if (hasCalculatedDates) {
      return reportingWindowsGroupedWidthDateDisplay(
        campaignDateRangeFrom,
        postReleaseDate,
        campaignReportingWindows,
      );
    }
    return reportingWindowsGrouped;
  }, [
    hasCalculatedDates,
    campaignDateRangeFrom,
    postReleaseDate,
    campaignReportingWindows,
  ]);

  const customComponents = React.useMemo(() => {
    if (hasCalculatedDates) {
      return { Option: OptionWithDates };
    }
    return {};
  }, [hasCalculatedDates]);

  return (
    <Select
      classNamePrefix={`reporting-window-selector__${
        hasCalculatedDates ? 'with' : 'without'
      }-dates`}
      components={
        customComponents as
          | Partial<
              SelectComponents<
                ReportingWindowOption,
                false,
                GroupBase<ReportingWindowOption>
              >
            >
          | undefined
      }
      defaultValue={defaultValue}
      placeholder="Reporting Window"
      options={options}
      onChange={(option: SingleValue<ReportingWindowOption>) => {
        onChange(option);
      }}
      isMulti={false}
      styles={{
        indicatorSeparator: (baseStyles: Record<string, unknown>) => ({
          ...baseStyles,
          width: 0,
        }),
        option: (baseStyles: Record<string, unknown>) => ({
          ...baseStyles,
          ...(hasCalculatedDates
            ? { display: 'flex', justifyContent: 'space-between' }
            : {}),
        }),
      }}
    />
  );
}
