import React from 'react';
import './LabelLink.scss';

export default function LabelLink({
  href,
  label,
  linkType = 'internal',
  className,
  onClick,
}: {
  href: string;
  label: string;
  linkType: 'internal' | 'external';
  className?: string;
  onClick?: () => void;
}) {
  return (
    <a
      title={label ?? ''}
      className={`label-link ${linkType} ${className}`}
      href={href}
      target={linkType === 'internal' ? '_self' : '_blank'}
      rel="noreferrer"
      onClick={onClick}
    >
      {label}
    </a>
  );
}
