import BaseDomoIframe from './BaseDomoIframe';
import { FrameProps } from './types';

function DomoIframeTwitter({ campaignId = null }: FrameProps) {
  return (
    <BaseDomoIframe
      campaignId={campaignId}
      IframeName="Twitter"
      platform="twitter"
    />
  );
}

export default DomoIframeTwitter;
