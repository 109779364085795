import percent from 'components/DataGrid/formatters/percent';
import number from 'components/DataGrid/formatters/number';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { TIKTOK_PLATFORM, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import basePerformanceColDef from './baseColDef';
import getTikTokDataFromInsights from '../tikTok/helpers/getTikTokDataFromInsight';

export default function age35() {
  const metric = 'age-35';
  return {
    ...percentColDef,
    ...basePerformanceColDef(
      metric,
      (data: Placement) =>
        getTikTokDataFromInsights(
          data,
          'fct_lifetime_video_performance.lifetime_35_plus_distribution',
        ),
      [TIKTOK_PLATFORM],
    ),
    headerName: 'Age 35+',
    width: 170,
    valueFormatter: ({
      data,
      value,
    }: {
      data: Placement;
      value?: number | null;
    }) => {
      if (data?.platform === YOUTUBE_PLATFORM) {
        return number({ value });
      }

      return percent({ value });
    },
  };
}
