// @ts-nocheck
import React from 'react';
import { mask } from 'utils/number';

const displayValue = (children, placeholder, maskopts) => {
  if (children === null) {
    return placeholder;
  }

  return mask(children, maskopts);
};

type UINumberProps = {
  children: number;
  className?: string;
  component: React.ComponentType;
  format?: string;
  leftPadding?: string;
  paddingChar?: string;
  placeholder?: string;
  prefix?: string;
  showZero?: boolean;
  suffix?: string;
};

function UINumber({
  children,
  component: ComponentType,
  format,
  placeholder,
  prefix,
  showZero,
  suffix,
  ...restProps
}: UINumberProps) {
  /**
   * We are passing invalid DOM elements in the spread props.
   * We should try to be explicit when we pass down props, not just randomly passing everything, as
   * that might cause unintended effects.
   */
  const componentProps = restProps ?? {};
  delete componentProps.leftPadding;
  delete componentProps.paddingChar;
  return (
    <ComponentType {...componentProps}>
      {displayValue(children, placeholder, {
        format,
        prefix,
        suffix,
        showZero,
      })}
    </ComponentType>
  );
}

UINumber.defaultProps = {
  children: null,
  component: 'span',
  format: '0,0[.]000',
  leftPadding: null,
  paddingChar: '0',
  placeholder: 'N/A',
  prefix: null,
  showZero: true,
  suffix: null,
};

export default UINumber;
