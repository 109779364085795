import React from 'react';
import { func, bool, array, string } from 'prop-types';
import Tippy from '@tippyjs/react';
import Header from 'components/UI/Table/Header';
import HCol from 'components/UI/Table/HCol';
import Body from 'components/UI/Table/Body';
import Row from 'components/UI/Table/Row';
import Col from 'components/UI/Table/Col';
import moment from 'moment';
import EmailLink from './Link/Link';

const isBENEmail = (email) =>
  /bengroup\.com|bengroupoutreach\.com|productplacement\.com/.test(email || '');
function PersonListResults({ loading, data, sort, onChange }) {
  return (
    <div className="emails-list__table">
      <Header
        onChangeSort={(newSort) => onChange({ sort: newSort })}
        sort={sort}
      >
        <HCol id="delivered" sortable>
          Date
        </HCol>
        <HCol id="sender" sortable>
          from
        </HCol>
        <HCol id="recipient" sortable>
          to
        </HCol>
        <HCol id="subject" sortable>
          subject
        </HCol>
        <HCol id="linked">Associated</HCol>
      </Header>
      <Body
        isLoading={loading}
        data={data}
        render={({
          messageId,
          recipient,
          sender,
          subject,
          delivered,
          opened,
          linkedPeopleAndProductions: { people, properties, productions },
        }) => {
          const senderIsBEN = isBENEmail(sender);
          const recipientIsBEN = isBENEmail(recipient);
          return (
            <Row key={messageId}>
              <Col id="delivered">
                <div>
                  {delivered
                    ? moment(delivered).local().format("MMM DD, 'YY, h:mm:ss a")
                    : null}
                </div>
                <div>
                  {opened
                    ? moment(opened).local().format("MMM DD, 'YY, h:mm:ss a")
                    : null}
                </div>
              </Col>
              <Col
                className={senderIsBEN ? 'is-ben-email' : 'is-not-ben-email'}
                id="sender"
              >
                <Tippy
                  content={sender}
                  interactive
                  arrow
                  placement="bottom"
                  theme="light"
                  animation="shift-toward"
                >
                  <span>
                    {senderIsBEN ? (
                      sender
                    ) : (
                      <a href={`mailto:${sender}`}>{sender}</a>
                    )}
                  </span>
                </Tippy>
              </Col>
              <Col
                className={recipientIsBEN ? 'is-ben-email' : 'is-not-ben-email'}
                id="recipient"
              >
                <Tippy
                  content={recipient}
                  interactive
                  arrow
                  placement="bottom"
                  theme="light"
                  animation="shift-toward"
                >
                  <span>
                    {recipientIsBEN ? (
                      recipient
                    ) : (
                      <a href={`mailto:${recipient}`}>{recipient}</a>
                    )}
                  </span>
                </Tippy>
              </Col>
              <Col id="subject">{subject}</Col>
              <Col id="linked">
                <EmailLink
                  entityArray={people}
                  entity="people"
                  label="People"
                />
                <EmailLink
                  entityArray={properties}
                  entity="properties"
                  label="Properties"
                />
                <EmailLink
                  entityArray={productions}
                  entity="productions"
                  label="Productions"
                />
              </Col>
            </Row>
          );
        }}
      />
    </div>
  );
}

PersonListResults.propTypes = {
  sort: string,
  onChange: func.isRequired,
  loading: bool.isRequired,
  data: array, // eslint-disable-line react/forbid-prop-types
};

PersonListResults.defaultProps = {
  sort: undefined,
  data: [],
};

export default PersonListResults;
