import React from 'react';
import { createPortal } from 'react-dom';
import './Drawer.scss';
import { DrawerSize } from '../_types/types';
import CloseHeader from './CloseHeader/CloseHeader';

export const Size = DrawerSize;

function wasClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  event: MouseEvent,
) {
  if (!ref.current || event === null) {
    return false;
  }

  const target = event?.target as HTMLButtonElement;

  /**
   * Check the element being clicked on and don't close the drawer if it's inside it.
   *
   * An example: in the Campaign Setup drawer when opening react select
   * and choosing an option the drawer would close.
   */
  if (ref?.current?.id && target?.id) {
    const elementIsInsideDrawer = document
      .getElementById(ref?.current?.id)
      ?.contains(document.getElementById(target?.id));
    if (elementIsInsideDrawer) {
      return false;
    }
  }

  /*
   * If we are clicking inside a modal don't close the drawer,as it will prevent us from actually
   * using the modal inside the drawer.
   *
   * Do the same for the error dialog.
   *
   * This feature is not tested in integration tests, be careful if changing this guard.
   */
  return !(
    target !== null &&
    (target.matches(':where(.ReactModalPortal) :scope') ||
      target.matches(':where(.error-dialog__overlay) :scope'))
  );
}

function buildClassNames(
  className: string | undefined,
  fluid: boolean | undefined,
  hideHeader: boolean | undefined,
  isOpen: boolean | undefined,
  size: DrawerSize | undefined,
) {
  return {
    mainDiv: `Drawer Drawer-${isOpen ? 'open' : 'closed'} ${className} ${
      hideHeader ? 'hide-header' : ''
    }`,
    innerDiv: `drawer-container container-size-${size} ${fluid ? 'fluid' : ''}
        `,
    openSectionDiv: `drawer-content-area  ${hideHeader ? 'hide-header' : ''}`,
  };
}

export default function Drawer({
  children,
  isOpen = false,
  onRequestClose,
  size = DrawerSize.xlarge,
  fluid = false,
  overlay = false,
  className = '',
  hideHeader,
}: Readonly<{
  isOpen?: boolean;
  onRequestClose?: () => void;
  size?: DrawerSize;
  fluid: boolean;
  overlay: boolean;
  children?: React.ReactNode;
  className?: string;
  hideHeader?: boolean;
}>) {
  const ref = React.useRef<HTMLDivElement>(null);

  function handleHideDropdown(event: React.KeyboardEvent) {
    if (onRequestClose && event.key === 'Escape') {
      onRequestClose();
    }
  }

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (!wasClickOutside(ref, event)) {
        return;
      }
      if (
        onRequestClose &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        onRequestClose();
      }
    },
    [onRequestClose],
  );

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside, isOpen]);

  const builtClassNames = React.useMemo(
    () => buildClassNames(className, fluid, hideHeader, isOpen, size),
    [className, fluid, hideHeader, isOpen, size],
  );
  return createPortal(
    <div
      ref={isOpen ? ref : null}
      className={builtClassNames.mainDiv}
      id="drawer"
    >
      <div className={builtClassNames.innerDiv}>
        <CloseHeader onRequestClose={onRequestClose} hideHeader={hideHeader} />
        {isOpen ? (
          <div className={builtClassNames.openSectionDiv}>
            {children ?? undefined}
          </div>
        ) : null}
      </div>
      {overlay ? (
        <div
          role="presentation"
          className="drawer-overlay"
          onClick={onRequestClose}
          onKeyDown={handleHideDropdown}
        />
      ) : null}
    </div>,
    document.getElementById('root') || window.document.body,
  );
}
