import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import tikTokCommon from './helpers/tikTokCommon';

export default function videoCompletionRate50() {
  const tikTokMetric = 'fct_lifetime_video_performance.video_complete_rate_benchmark_p50' as keyof typeof TCMReportMetrics;
  const metric = 'video_complete_rate_benchmark_p50';
  return {
    ...percentColDef,
    ...tikTokCommon(tikTokMetric, metric),
    field: 'videoCompletionRate50',
    headerName: '50% Video Completion Rate',
    width: 310,
  };
}
