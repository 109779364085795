import LinkButton from 'components/Creator/LinkButton/LinkButton';

import './RecentPosts.scss';

export default function RecentPosts({
  productionUrl,
}: {
  productionUrl?: string;
}) {
  if (!productionUrl) {
    return null;
  }
  return (
    <section className="instagram-recent-posts">
      <a href={productionUrl} rel="noreferrer" target="_blank">
        <LinkButton label="Open recent posts" solid />
      </a>

      <a
        className="recent-reels"
        href={`${productionUrl}/reels`}
        rel="noreferrer"
        target="_blank"
      >
        <LinkButton label="Open recent reels" solid />
      </a>
    </section>
  );
}
