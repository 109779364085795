export default function usePreventNavigation(message) {
  function beforeUnload(e) {
    e.returnValue = message;
    return message;
  }

  function start() {
    window.addEventListener('beforeunload', beforeUnload);
  }

  function cancel() {
    window.removeEventListener('beforeunload', beforeUnload);
  }

  return [start, cancel];
}
