import React from 'react';
import { bool, oneOfType, node, arrayOf, string } from 'prop-types';
import classnames from 'classnames';

import './Row.scss';

const cbn = 'ui-grid-row';

function UIGridRow({ children, className, hasOffset }) {
  return (
    <div
      className={classnames(cbn, className, {
        [`${cbn}--has-offset`]: hasOffset,
      })}
    >
      {children}
    </div>
  );
}

UIGridRow.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  hasOffset: bool,
  className: string,
};

UIGridRow.defaultProps = {
  children: null,
  className: null,
  hasOffset: false,
};

export default UIGridRow;
