import RequiredIndicator from './RequiredIndicator/RequiredIndicator';

import './RequiredFormLabel.scss';

export default function RequiredFormLabel({
  text,
  htmlFor,
  children,
}: {
  text: string;
  htmlFor: string;
  children: React.ReactNode;
}) {
  return (
    <label htmlFor={htmlFor} className={`${htmlFor}`}>
      <span className="required-form-label">
        {text} <RequiredIndicator />
      </span>

      {children}
    </label>
  );
}
