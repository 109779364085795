import React from 'react';
import { children as childrenType } from 'components/utils/customPropTypes';

import './Label.scss';

function UILabel({ children }) {
  return <span className="ui-label">{children}</span>;
}

UILabel.propTypes = {
  children: childrenType.isRequired,
};

export default UILabel;
