import svgSizeMapping, { SvgSize } from './utils/svgSizeMapping';

export default function ChevronDown({
  fill = 'none',
  stroke = '#8f8f8f',
  size = 'mid-small',
}: {
  fill?: string;
  stroke?: string;
  size?: SvgSize;
}) {
  return (
    <svg
      id="chevron-down"
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
