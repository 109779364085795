import React from 'react';
import { string, func, bool } from 'prop-types';
import classnames from 'classnames';
import { children as childrenType } from 'components/utils/customPropTypes';
import { childrenToString } from 'utils/string';

import './Tag.scss';

const cbn = 'ui-tag';

function UITag({ children, onClick, className, theme, disabled, onRemove }) {
  return (
    <span
      className={classnames(cbn, className, {
        [`${cbn}--disabled`]: disabled,
        main: theme === 'main',
      })}
    >
      <button
        type="button"
        className={`${cbn}__label`}
        onClick={onClick}
        disabled={disabled}
        title={childrenToString(children)}
      >
        <span className={`${cbn}__text-wrapper`}>{children}</span>
      </button>
      {onRemove && !disabled && (
        <button
          type="button"
          className={`${cbn}__remove-buton`}
          onClick={onRemove}
          onTouchEnd={onRemove}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          ×
        </button>
      )}
    </span>
  );
}

UITag.propTypes = {
  className: string,
  theme: string,
  children: childrenType.isRequired,
  disabled: bool,
  onClick: func,
  onRemove: func,
};

UITag.defaultProps = {
  className: '',
  theme: 'filter',
  disabled: false,
  onClick() {},
  onRemove: null,
};

export default UITag;
