import upperFirst from 'lodash/upperFirst';

export const classes = [
  {
    name: 'direct to home',
    channel: 'film',
  },
  {
    name: 'film/streaming',
    channel: 'film',
  },
  {
    name: 'theatrical',
    channel: 'film',
  },
  {
    name: 'theatrical/streaming',
    channel: 'film',
  },
  {
    name: 'broadcast/basic cable',
    channel: 'tv',
  },
  {
    name: 'premium cable',
    channel: 'tv',
  },
  {
    name: 'streaming',
    channel: 'tv',
  },
  {
    name: 'live stream',
    channel: 'digital',
  },
  {
    name: 'series',
    channel: 'digital',
  },
  {
    name: 'production linked',
    channel: 'digital',
  },
  {
    name: 'music video',
    channel: 'influencer',
  },
  {
    name: 'event',
    channel: 'influencer',
  },
  {
    name: 'icons',
    channel: 'influencer',
  },
  {
    name: 'influencer',
    channel: 'influencer',
  },
].map((klass) => ({
  ...klass,
  value: klass.name,
  label: upperFirst(klass.name),
}));

export const CLASSES_BY_CODE = classes.reduce(
  (acc, klass) => ({
    ...acc,
    [klass.code]: klass,
  }),
  {},
);

export const CLASSES_PER_CHANNEL = classes.reduce((acc, klass) => {
  if (!acc[klass.channel]) {
    acc[klass.channel] = [];
  }

  acc[klass.channel].push(klass);
  return acc;
}, {});

export const PRODUCTION_CLASSES = classes.map((c) => c.name);
export const PRODUCTION_CHANNELS = classes.map((c) => c.channel);
