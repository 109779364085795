import React from 'react';
import { number, oneOfType, bool, node, string } from 'prop-types';
import classnames from 'classnames';

import './IconCounter.scss';

const cbn = 'ui-icon-counter';

function UIIconCounter({ count, className, children, showZero }) {
  return (
    <span className={classnames(cbn, className)}>
      {children}
      {(count || showZero) && (
        <span className={`${cbn}__count`}>{count || 0}</span>
      )}
    </span>
  );
}

UIIconCounter.propTypes = {
  children: node.isRequired,
  className: string,
  count: oneOfType([number, bool, node]),
  showZero: bool,
};

UIIconCounter.defaultProps = {
  className: null,
  count: false,
  showZero: false,
};

export default UIIconCounter;
