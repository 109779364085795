import ManualMetrics from 'components/DataGrid/Editors/Destination/ManualMetrics/ManualMetrics';
import { Placement } from 'shared/types/Placement';
import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import { TIKTOK_PLATFORM } from 'shared/metrics/kpis';
import baseColDef from '../../helpers/baseColDef';
import getTikTokDataFromInsights from './getTikTokDataFromInsight';

export default function tikTokCommon(
  tikTokMetric: keyof typeof TCMReportMetrics,
  metric: string,
) {
  return {
    ...baseColDef(
      metric,
      (data: Placement) => getTikTokDataFromInsights(data, tikTokMetric),
      [TIKTOK_PLATFORM],
    ),
    cellEditorPopup: true,
    cellEditor: ManualMetrics,
  };
}
