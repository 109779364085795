import { gql } from 'apollo-boost';

export const ExternalPlacementListQuery = gql`
  query ExternalPlacementList(
    $limit: Int
    $skip: Int
    $sort: String
    $filter: PlacementFilter
    $reportingMonth: ISODateTime
    $reportingWindow: String
  ) {
    externalPlacements(
      limit: $limit
      skip: $skip
      sort: $sort
      filter: $filter
    ) {
      data {
        id
        title
        status
        platform
        mediaType
        updatedAt
        externalApproval
        displayedToClientAt
        campaign {
          id
          subType
        }
        platformMetrics(
          reportingMonth: $reportingMonth
          reportingWindow: $reportingWindow
          showDemographics: false
        )
        integration {
          id
          production {
            id
            type
          }
          deliverable {
            id
            title
            createdAt
            property {
              id
              name
              productions {
                id
                type
                url
                media {
                  id
                  url
                  attributes {
                    width
                    height
                    thumbnail
                  }
                }
              }
            }
            messagesCount {
              external
            }
          }
        }
        brandAppStatus
        source {
          id
          url
          releaseDate
          production {
            id
            type
            ... on ProductionFacebook {
              username
            }
            ... on ProductionTwitch {
              username
            }
            ... on ProductionTwitter {
              username
            }
            ... on ProductionTiktok {
              username
            }
            ... on ProductionYoutube {
              channelId
            }
            ... on ProductionInstagram {
              username
            }
            property {
              id
              name
            }
          }
        }
        media {
          id
          url
          attributes {
            width
            height
            thumbnail
          }
        }
        drafts {
          id
          url
          attributes {
            width
            height
            thumbnail
          }
        }
        draftUrl
        messagesCount {
          external
        }
      }
      meta {
        total
      }
    }
  }
`;
