export enum ReportingAction {
  changeReportingWindow = 'User changed reporting window',
  closedDrawer = 'User closed drawer',
  openedDrawer = 'User opened drawer',
  updatedManualMetric = 'User updated manual metric',
  cancelManualMetricUpdate = 'User canceled manual metric update',
  userLoadedPerformanceTab = 'User loaded Performance tab',
}

export type ReportingData = {
  reportingWindow?: string | null;
  drawer?: string;
  metric?: string;
  reportingWindowSameAsCampaign?: boolean;
  oldValue?: number | string | null;
  newValue?: number | string | null;
  campaignReportingWindow?: string | null;
  metricReportingWindow?: string | null;
};

export type ReportingSegmentEvent = (
  action: ReportingAction,
  extraData?: ReportingData,
) => void;

export enum AllowedTabs {
  details = 'Details',
  gridManagement = 'Grid Management',
  pricing = 'Pricing',
  tracking = 'Tracking',
  contentRequirements = 'Content Requirements',
  linksDocuments = 'Links & Documents',
}

export enum AllowedSubtabs {
  columns = 'Columns',
  groups = 'Groups',
  permissions = 'Permissions',
  templates = 'Templates',
}

export enum CampaignSetupAction {
  closedDrawer = 'User closed drawer',
  openedDrawer = 'User opened drawer',
  changedTab = 'User changed tab',
  changedSubtab = 'User changed subtab',
  clickedButton = 'User clicked button',
}

export type CampaignSetupData = {
  tab?: AllowedTabs;
  subtab?: AllowedSubtabs;
  button?: string;
};

export type CampaignSetupSegmentEvent = (
  action: CampaignSetupAction,
  extraData?: CampaignSetupData,
) => void;
