import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import { getDataCy } from 'components/Campaign/utils';
import LabelWithIcon from 'components/DataGrid/Destination/Cells/LabelWithIcon';
import { Placement } from 'shared/types/Placement';

export default function postType() {
  return {
    ...baseColDef,
    field: 'type',
    headerName: 'Type',
    valueGetter: ({ data }: { data: Placement }) =>
      `${data?.platform} ${data?.style ? data.style : data?.mediaType}`,
    cellRenderer: LabelWithIcon,
    cellRendererParams: ({ data }: { data: Placement }) => {
      let icon = null;
      if (data?.platform && data?.platform === 'other') {
        icon = 'folder_open';
      }
      if (data?.platform && data?.platform !== 'other') {
        icon = `${data.platform}_no_line`;
      }

      return {
        platform: data?.platform,
        icon,
        label: data?.style ? data.style : data?.mediaType,
        dataCy: getDataCy(data, 'posttype'),
      };
    },
    filter: 'agSetColumnFilter',
  };
}
