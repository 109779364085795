import getBaseColDef from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/getBaseColDef';
import { CampaignCreatorColDef, CreatorColDef } from 'shared/types/Campaign';
import sortCreatorColDefsByIndex from 'shared/campaigns/creatorCampaigns/sortCreatorColDefsByIndex';
import processColumnFields from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/processColumnFields';

function getParentColDefAndChildren(
  mappedColDefs: Map<string, CreatorColDef>,
  columnValues: CreatorColDef,
  isForExternalUse: boolean,
) {
  const parentColDef = mappedColDefs.get(columnValues?.parentGroup ?? '');
  const childrenToAdd = [
    ...(
      mappedColDefs.get(columnValues?.parentGroup ?? '')?.children ?? []
    ).map((c: CreatorColDef) =>
      processColumnFields(c, isForExternalUse, parentColDef),
    ),
  ];

  return { parentColDef, childrenToAdd };
}

function shouldRemoveExternalColumn(columnValues: CreatorColDef) {
  if (columnValues?.children?.length) {
    const visibleChildren = columnValues?.children?.filter(
      (child: CreatorColDef) =>
        ['view', 'edit'].includes(child?.clientAccess ?? ''),
    );

    return !visibleChildren?.length;
  }

  return !['view', 'edit'].includes(columnValues?.clientAccess ?? '');
}

function checkIfColumnIsValidForProcess(
  mappedColDefs: Map<string, CreatorColDef>,
  columnName: string,
  columnDisplay: boolean,
  columnValues: CreatorColDef,
  isForExternalUse: boolean,
) {
  if (!mappedColDefs.has(columnName)) {
    return false;
  }

  if (columnDisplay && columnValues?.displayed !== true) {
    mappedColDefs.delete(columnName);
    return false;
  }

  if (isForExternalUse) {
    const shouldRemoveColumn = shouldRemoveExternalColumn(columnValues);
    if (shouldRemoveColumn) {
      mappedColDefs.delete(columnName);
      return false;
    }
  }

  return true;
}

function getProcessedColDefs(
  campaignColumns: CampaignCreatorColDef | null,
  mappedColDefs: Map<string, CreatorColDef>,
  columnDisplay: boolean,
  isForExternalUse: boolean,
) {
  (mappedColDefs ?? new Map<string, CreatorColDef>()).forEach(
    (initialColumnValues: CreatorColDef, columnName: string) => {
      const columnValues = { ...(initialColumnValues ?? {}) };

      const shouldProcessColumn = checkIfColumnIsValidForProcess(
        mappedColDefs,
        columnName,
        columnDisplay,
        columnValues,
        isForExternalUse,
      );

      if (!shouldProcessColumn) {
        return;
      }

      if (
        columnValues?.parentGroup &&
        !mappedColDefs.has(columnValues?.parentGroup)
      ) {
        delete columnValues?.parentGroup;
      }
      if (columnValues?.parentGroup) {
        const { parentColDef, childrenToAdd } = getParentColDefAndChildren(
          mappedColDefs,
          columnValues,
          isForExternalUse,
        );

        const newColumnExists = childrenToAdd.findIndex(
          (c) => c?.field === columnValues?.field,
        );
        if (newColumnExists === -1) {
          childrenToAdd.push(
            processColumnFields(columnValues, isForExternalUse, parentColDef),
          );
        }

        mappedColDefs.set(columnValues.parentGroup ?? '', {
          ...processColumnFields(
            {
              field: columnValues.parentGroup,
              ...(campaignColumns?.[columnValues.parentGroup ?? ''] ?? {}),
            },
            isForExternalUse,
            parentColDef,
          ),
          children: childrenToAdd,
        });

        mappedColDefs.delete(columnName);
        return;
      }

      mappedColDefs.set(
        columnName,
        processColumnFields(columnValues, isForExternalUse),
      );
    },
  );
  const processedColDefs: CreatorColDef[] = [];

  mappedColDefs.forEach((columnValues) => {
    if (Array.isArray(columnValues?.children)) {
      processedColDefs.push({
        ...columnValues,
        children: sortCreatorColDefsByIndex(columnValues?.children),
      });
      return;
    }
    processedColDefs.push(columnValues);
  });

  return sortCreatorColDefsByIndex(processedColDefs);
}

function buildMappedColumns(
  baseColDef: CreatorColDef[],
  campaignColumns?: CampaignCreatorColDef | null,
) {
  const mappedColDefs: Map<string, CreatorColDef> = new Map();
  const mergedColumns = [...(baseColDef ?? [])];
  Object.entries(campaignColumns ?? {}).forEach(([field, col]) => {
    if (col?.custom === true || col?.group?.length) {
      mergedColumns.push({
        ...col,
        field,
      });
    }
  });

  (mergedColumns ?? []).forEach((colDef, index) => {
    if (!colDef?.field) {
      return;
    }

    if (Array.isArray(colDef.children)) {
      colDef.children.forEach(
        (childColDef: CreatorColDef, childIndex: number) => {
          if (!childColDef?.field) {
            return;
          }
          mappedColDefs.set(childColDef.field, {
            index: childIndex,
            ...childColDef,
            ...(campaignColumns?.[childColDef.field] ?? {}),
            parentGroup: colDef?.group,
          });
        },
      );
    }

    mappedColDefs.set(colDef.field, {
      index,
      ...colDef,
      ...(campaignColumns?.[colDef.field] ?? {}),
    });
  });
  return mappedColDefs;
}

export default function mergeColumns(
  campaignTab: string,
  campaignColumns?: CampaignCreatorColDef | null,
  columnDisplay = true,
  isForExternalUse = true,
) {
  const baseColDef = getBaseColDef(campaignTab);

  if (
    columnDisplay &&
    (!campaignColumns || !Object.keys(campaignColumns)?.length)
  ) {
    return [] as CreatorColDef[];
  }

  if (!campaignColumns) {
    return baseColDef;
  }

  const mappedColDefs = buildMappedColumns(baseColDef, campaignColumns);

  const processedColDefs: CreatorColDef[] = getProcessedColDefs(
    campaignColumns,
    mappedColDefs,
    columnDisplay,
    isForExternalUse,
  );

  return processedColDefs;
}
