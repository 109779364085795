import { LOCAL_STORAGE_DATA_GRID_PREFIX } from 'components/Campaign/constants';
import get from 'lodash/get';
import { Campaign } from 'components/Campaign/types';
import { Deliverable } from 'components/Deliverable/types';
import { Placement } from 'components/Placement/types';
import dataCyReplace from 'shared/utils/cypress/dataCy';

const typeToStyles: { [index: string]: string } = {
  'Performance Team': 'influencer',
  Influencer: 'influencer',
  Traditional: 'traditional',
  'Music Video': 'traditional',
  360: 'traditional',
};

export const getThumbnail = (campaign: Campaign) =>
  get(campaign, 'client.logo.url') || get(campaign, 'brands.0.logo.url');

export const getCampaignStyle = (type: string) =>
  typeToStyles[type] || 'traditional';

export const formatDataCY = (propertyName: string, field: string) =>
  `${dataCyReplace(propertyName ?? '').toLowerCase()}_${dataCyReplace(
    field,
  ).toLowerCase()}`;

const getTab = (data: Deliverable | Placement) => {
  const deliverableData = data as Deliverable;
  if (deliverableData?.property) {
    return 'planning';
  }
  if (deliverableData?.source?.production?.property) {
    return 'stats';
  }

  const placementData = data as Placement;
  if (placementData?.integration?.production?.property) {
    return 'postManagement';
  }

  return 'planning';
};

function getPropertyName(data: Deliverable | Placement) {
  const deliverableData = data as Deliverable;
  if (deliverableData?.property?.name) {
    return deliverableData.property.name;
  }

  const placementData = data as Placement;
  if (
    placementData?.source?.production?.property?.name ||
    placementData?.integration?.production?.property?.name
  ) {
    return (
      placementData?.source?.production?.property?.name ??
      placementData?.integration?.production?.property?.name
    );
  }

  return null;
}

function getFieldToUse(
  data: Deliverable | Placement,
  fieldReceived: string,
  tab: string,
) {
  const placementData = data as Placement;

  if (tab === 'postManagement' || tab === 'stats') {
    let placementFieldToUse = `${placementData?.platform}-${placementData?.mediaType}-${fieldReceived}`;
    if (placementData?.style) {
      placementFieldToUse = `${placementData?.platform}-${placementData?.mediaType}-${placementData?.style}-${fieldReceived}`;
    }

    return placementFieldToUse;
  }

  return fieldReceived;
}

export const getDataCy = (
  data: Deliverable | Placement,
  fieldReceived: string,
) => {
  const propertyName = getPropertyName(data);
  const tab = getTab(data);
  const fieldToUse = getFieldToUse(data, fieldReceived, tab);
  return formatDataCY(propertyName, fieldToUse);
};

export function getCampaignStorageKey(
  tab: string | null,
  infinityGrid: boolean | undefined | null,
  campaignSubType: string,
) {
  if (!tab || typeof infinityGrid !== 'boolean' || !campaignSubType) {
    return null;
  }
  return `${LOCAL_STORAGE_DATA_GRID_PREFIX}${tab}${
    infinityGrid ? 'infinityGrid' : 'grid'
  }--${campaignSubType.includes('Performance') ? 'performance' : 'awareness'}`;
}
