import React from 'react';
import Select, { GroupBase, SingleValue } from 'react-select';

import './PerformanceMenu.scss';
import { Campaign } from 'shared/types/Campaign';
import {
  ReportingAction,
  ReportingSegmentEvent,
} from 'shared/campaigns/creatorCampaigns/segmentEnums';
import {
  reportingWindowsGrouped,
  reportingWindowsMetaData,
} from 'shared/campaigns/creatorCampaigns/reportingWindows';
import useReactSelectCustomStyles from 'components/Campaign/CampaignSetupDrawer/CampaignSetup/hooks/useReactSelectCustomStyles';
import { ReactSelectOption } from 'shared/types/ReactSelect';

export default function PerformanceMenu({
  reportingWindow,
  setReportingWindow,
  trackSegmentEvent = () => null,
  campaign,
}: {
  reportingWindow: string | null;
  setReportingWindow: (arg0: string) => void;
  trackSegmentEvent: ReportingSegmentEvent;
  campaign: Campaign | null;
}) {
  const handleOnChange = React.useCallback(
    (option: string | null) => {
      setReportingWindow(option ?? '');
      trackSegmentEvent(ReportingAction.changeReportingWindow, {
        reportingWindow: option ?? '',
      });
    },
    [setReportingWindow, trackSegmentEvent],
  );

  const availableReportingWindows = React.useMemo(() => {
    const availableReportingWindowsTmp = [];
    const liveReportingWindow = reportingWindowsGrouped[0];
    const timeReportingWindow = reportingWindowsGrouped[1];
    const liveReportingWindowOptions = liveReportingWindow?.options.filter(
      (o) => (campaign?.creatorReportingWindows ?? []).includes(o?.value?.key),
    );
    const timeReportingWindowOptions = timeReportingWindow?.options.filter(
      (o) => (campaign?.creatorReportingWindows ?? []).includes(o?.value?.key),
    );
    if (liveReportingWindowOptions?.length) {
      availableReportingWindowsTmp.push({
        ...liveReportingWindow,
        options: liveReportingWindowOptions.map((r) => ({
          label: r.label,
          value: r.value.key,
        })),
      });
    }
    if (timeReportingWindowOptions?.length) {
      availableReportingWindowsTmp.push({
        ...timeReportingWindow,
        options: timeReportingWindowOptions.map((r) => ({
          label: r.label,
          value: r.value.key,
        })),
      });
    }
    return availableReportingWindowsTmp;
  }, [campaign]);

  const { customStyles } = useReactSelectCustomStyles<
    GroupBase<ReactSelectOption>
  >();

  const defaultValue = React.useMemo(() => {
    if (!reportingWindow) {
      return null;
    }

    const { label } = reportingWindowsMetaData[
      reportingWindow as keyof typeof reportingWindowsMetaData
    ];
    return {
      label,
      options: [
        {
          value: reportingWindow,
          label,
        },
      ],
    };
  }, [reportingWindow]);

  return (
    <div className="performance-menu">
      {availableReportingWindows?.length ? (
        <Select<GroupBase<ReactSelectOption>>
          classNamePrefix="reporting-window-selector__without-dates"
          options={availableReportingWindows}
          value={defaultValue}
          styles={customStyles}
          onChange={(newValue: unknown) => {
            const newValueTyped = newValue as SingleValue<ReactSelectOption>;
            handleOnChange(String(newValueTyped?.value) ?? null);
          }}
        />
      ) : null}
    </div>
  );
}
