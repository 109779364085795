// @ts-ignore
import placeholderImg from '../../_assets/images/ben_placeholder_img.svg';

export const defaultImage =
  'https://d36oukamy1eeu1.cloudfront.net/site/logos/default_brand_icon.jpg';
export const defaultIGImage =
  'https://d36oukamy1eeu1.cloudfront.net/site/logos/instagram_default.jpg';
export const placeholderImage = placeholderImg;

export default {
  defaultImage,
  defaultIGImage,
  placeholderImage,
};
