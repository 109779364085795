import React from 'react';
import { Platforms } from 'shared/types/platforms.d';
import BaseIcon from '../BaseIcon/BaseIcon';
import './PlatformIcon.scss';
import InstagramLogo from '../_assets/react_svg/platforms/InstagramLogo';
import YouTubeLogo from '../_assets/react_svg/platforms/YouTubeLogo';
import TikTokLogo from '../_assets/react_svg/platforms/TikTokLogo';
import TwitterLogo from '../_assets/react_svg/platforms/TwitterLogo';
import FacebookLogo from '../_assets/react_svg/platforms/FacebookLogo';
import TwitchLogo from '../_assets/react_svg/platforms/TwitchLogo';
import OtherLogo from '../_assets/react_svg/platforms/OtherLogo';
import { SvgSize } from '../_assets/react_svg/utils/svgSizeMapping';

type PlatformComponent =
  | typeof InstagramLogo
  | typeof YouTubeLogo
  | typeof TikTokLogo
  | typeof TwitterLogo
  | typeof FacebookLogo
  | typeof TwitchLogo;

const platformMapping: { [index: string]: null | PlatformComponent } = {
  instagram: InstagramLogo,
  youtube: YouTubeLogo,
  tiktok: TikTokLogo,
  twitter: TwitterLogo,
  facebook: FacebookLogo,
  twitch: TwitchLogo,
  other: OtherLogo,
  film: null,
  season: null,
};

const baseIconSize = {
  'x-small': 'x-small',
  small: 'x-small',
  'mid-small': 'small',
  medium: 'mid-small',
  large: 'medium',
  'x-large': 'large',
  huge: 'x-large',
};

type PlatformIconProps = {
  platform: keyof typeof Platforms;
  size?: SvgSize;
  colorType?: 'blackWhite' | 'platform';
};

function getIcon(
  platform: string,
  size: SvgSize,
  colorType: 'blackWhite' | 'platform',
) {
  const SvgComponent = platformMapping[platform];

  if (platform === 'youtube' && SvgComponent) {
    return <SvgComponent size={size} blackWhite={colorType === 'blackWhite'} />;
  }

  if (!SvgComponent || colorType === 'platform') {
    const icon = platform === 'other' ? 'folder_open' : `${platform}_no_line`;
    return <BaseIcon icon={icon} size={baseIconSize[size] as SvgSize} />;
  }

  return <SvgComponent size={size} />;
}

export default function PlatformIcon({
  platform,
  size = 'medium',
  colorType = 'platform',
}: PlatformIconProps) {
  return (
    <div className={`platform-icon platform-icon-${platform}`}>
      {getIcon(platform, size, colorType)}
    </div>
  );
}
