import capitalize from 'lodash/capitalize';
import { TEAMS } from 'components/User/constants';
import { SFF_SORT_OPTIONS, PRODUCTION_SORT_OPTIONS } from './sort';
import { STATUSES, AUTH_STATUS_OPTIONS, RECORD_STATUSES } from './statuses';
import { PRODUCTION_AUDIENCE_KEYS } from './productionAudienceKeys';
import {
  PRODUCTION_RATINGS,
  PRODUCTION_RATINGS_FLATTED,
  PRODUCTION_RATING_OPTIONS,
} from './productionRatings';

export {
  SFF_SORT_OPTIONS,
  PRODUCTION_SORT_OPTIONS,
  STATUSES,
  AUTH_STATUS_OPTIONS,
  RECORD_STATUSES,
  PRODUCTION_AUDIENCE_KEYS,
  PRODUCTION_RATINGS,
  PRODUCTION_RATINGS_FLATTED,
  PRODUCTION_RATING_OPTIONS,
};

export const STORY_TYPES = ['Unscripted', 'Scripted'];
export const SIMILAR_PRODUCTIONS_SOURCES = [
  'ben-research',
  'nielsen-streaming',
];
export const SENSITIVITIES = [
  'Drugs',
  'Language',
  'Sex',
  'Violence',
  'Criminal',
];

export const RATINGS = ['all', 'teenAndOlder', 'adult'];

export const POSTING_FREQUENCY_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Daily', value: 'daily' },
  { label: 'Several Weekly', value: 'several-weekly' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Several Monthly', value: 'several-monthly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Less Than Monthly', value: 'less-than-monthly' },
  { label: 'Infrequent', value: 'infrequent' },
];

export const CREATOR_ETHNICITIES = [
  'Black or African-American',
  'American Indian',
  'Asian or Asian-American',
  'White or Caucasian',
  'Indian',
  'Latin American',
  'Middle Eastern',
  'Pacific Islander',
  'Other',
];

export const CREATOR_GENDERS = ['Male', 'Female', 'Trans'];

export const CREATOR_ETHNICITIES_OPTIONS = CREATOR_ETHNICITIES.map(
  (ethnicity) => ({ label: ethnicity, value: ethnicity }),
);

export const CREATOR_GENDERS_OPTIONS = CREATOR_GENDERS.map((gender) => ({
  label: gender,
  value: gender,
}));

export const PRODUCTION_CIRCA_ERA = [
  'Past',
  'Antiquity',
  'Medieval',
  'Renaissance',
  'Elizabethan',
  '20th Century',
  'Present',
  'Near Future',
  'Distant Future',
  'Alternative Timeline',
];
export const PRODUCTION_CIRCA_ERA_OPTIONS = PRODUCTION_CIRCA_ERA.map(
  (value) => ({
    value,
    label: value,
  }),
);

export const LINK_PLATFORMS = [
  'Facebook',
  'IMDB',
  'Instagram',
  'Pinterest',
  'Snapchat',
  'Tumblr',
  'Twitter',
  'TikTok',
  'Web',
  'Wikipedia',
  'YouTube',
  'Other',
];

export const LINK_PLATFORMS_ICONS = {
  facebook: 'facebook_no_line',
  youtube: 'youtube_no_line',
  instagram: 'instagram_no_line',
  twitter: 'twitter_no_line',
  tiktok: 'tiktok_no_line',
  twitch: 'twitch_no_line',
  season: 'tv',
  film: 'film',
  other: 'folder_open',
};

export const LINK_PLATFORMS_OPTIONS = LINK_PLATFORMS.map((value) => ({
  value,
  label: value,
}));

export const PRODUCTION_VEHICLE_POWERS = ['A', 'B', 'C'];
export const PRODUCTION_VEHICLE_POWERS_OPTIONS = PRODUCTION_VEHICLE_POWERS.map(
  (value) => ({ value, label: value }),
);

export const ALERTS = [
  'Hot Pick',
  'Accepting offers',
  'Trending',
  'Time Sensitive',
  'Quick Turn Around',
  'High Connectivity',
  'Spanish Language',
  '21+',
];

export const ALERTS_OPTIONS = ALERTS.map((value) => ({ value, label: value }));

export const ARRAY_FIELDS = [
  'genres',
  'creators',
  'directors',
  'casts',
  'writers',
  'producers',
  'locations',
  'settingsLocations',
  'includedCategories',
  'excludedCategories',
];

export const OBJECT_FIELDS = ['property'];

export const PRODUCTION_LENGTH_OPTIONS = [
  30,
  60,
  90,
  120,
  999, // 999 = more than 120
].map((value) => ({ value, label: value }));

export const USER_TEAM_OPTIONS = TEAMS.sort().map((v) => ({
  label: capitalize(v),
  value: v,
}));
