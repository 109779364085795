import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { Placement } from 'shared/types/Placement';
import { TIKTOK_PLATFORM, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import percent from 'components/DataGrid/formatters/percent';
import basePerformanceColDef from './baseColDef';
import getYouTubeDistributionDataFromCampaignService from '../youTube/helpers/getYouTubeDistributionDataFromCampaignService';
import getTikTokDataFromInsights from '../tikTok/helpers/getTikTokDataFromInsight';

const youTubeMetric = 'viewers_by_age_group';
const youTubeMetricKey = 'Age18-24';
const tikTokMetricKeyType = '18-24';
const metric = `age-${tikTokMetricKeyType}`;

export function age1824AutomatedValueGetter(data: Placement) {
  if (data?.platform === TIKTOK_PLATFORM) {
    return getTikTokDataFromInsights(
      data,
      'fct_lifetime_video_performance.lifetime_18_24_distribution',
    );
  }
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDistributionDataFromCampaignService(
      data,
      [youTubeMetric, youTubeMetricKey],
      { parsePercentageFromString: true },
    );
  }
  return null;
}

export default function age1824() {
  return {
    ...percentColDef,
    ...basePerformanceColDef(metric, age1824AutomatedValueGetter, [
      YOUTUBE_PLATFORM,
      TIKTOK_PLATFORM,
    ]),
    headerName: 'Age 18-24',
    width: 180,
    valueFormatter: ({ value }: { value?: number | null }) =>
      percent({ value }),
  };
}
