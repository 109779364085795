import { User, FeatureFlags } from 'components/User/types';
import featureFlags from 'store/featureFlags';

type F = keyof typeof featureFlags;

async function onChange(
  value: boolean,
  updateProfile: any,
  setUser: (user: User) => void,
  userFeatureFlags: FeatureFlags,
  key: string,
  user: any,
) {
  if (userFeatureFlags === null) {
    return;
  }

  const updatedFeatureFlags = Object.entries(userFeatureFlags).reduce<{
    [index: string]: { userSelection?: boolean | undefined };
  }>((acc, [elementKey, element]) => {
    if (!featureFlags[elementKey as F]) {
      return acc;
    }

    if (featureFlags[elementKey as F].status !== 'optional') {
      return acc;
    }

    if (elementKey === key) {
      acc[elementKey] = {
        userSelection: value,
      };
    } else {
      acc[elementKey] = element;
    }

    return acc;
  }, {});

  const result = await updateProfile({
    variables: {
      id: user.id,
      payload: {
        featureFlags: updatedFeatureFlags,
      },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateUser: {
        ...user,
        featureFlags: updatedFeatureFlags,
        __typename: 'User',
      },
    },
  });

  if (result?.data?.updateUser) {
    setUser(result.data.updateUser);
  }
}
export default onChange;
