import PlatformIcon from '@UIComponents/PlatformIcon/PlatformIcon';
import { PlatformIconType } from 'external/components/constants';

import './PlatformTitle.scss';

type PlatformTitleProps = {
  onClick: () => void;
  platform: string;
  title: string;
  url?: string;
};
function PlatformTitle({ onClick, platform, title, url }: PlatformTitleProps) {
  return (
    <div className="PlatformTitle">
      <PlatformIcon platform={PlatformIconType[platform]} size="x-large" />
      <a
        className="platform-link"
        href={url}
        onClick={onClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        {title}
      </a>
    </div>
  );
}

export default PlatformTitle;
