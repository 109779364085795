import { DELIVERABLES_EXECUTION_STATUS } from 'shared/metrics/constants';
import { Deliverable } from 'shared/types/Deliverable';
import formatDate from 'shared/dates/formatDate';
import AgGrid from '../../AgGrid/AgGrid';
import MultiPlatformCombo from '../../MultiPlatformCombo/MultiPlatformCombo';
import './ProjectHistory.scss';

const columnDefs = [
  {
    field: 'brand_icon',
    headerName: '',
    minWidth: 60,
    maxWidth: 60,
    sortable: false,
    valueGetter: ({ data }: { data: Deliverable }) => data?.brand?.logo?.url,
    cellRenderer: ({ value }: { value: string }) =>
      value?.length ? (
        <img className="brand-icon" alt="brand_logo" src={value} />
      ) : null,
  },
  {
    field: 'brand',
    headerName: 'Brand',
    cellClass: 'title',
    valueGetter: ({ data }: { data: Deliverable }) => data?.brand?.name,
    cellRenderer: ({ value }: { value: string }) => <p>{value}</p>,
    minWidth: 130,
  },
  {
    field: 'campaign',
    headerName: 'Campaign',
    valueGetter: ({ data }: { data: Deliverable }) =>
      data?.campaign?.projectName,
    cellRenderer: ({ data }: { data: Deliverable }) => (
      <p>
        <a href={`/team/influencer/campaigns/${data?.campaign?.id}`}>
          {data?.campaign?.projectName}
        </a>
      </p>
    ),
    minWidth: 150,
  },
  {
    field: 'contentType',
    headerName: 'Content Type',
    cellRenderer: MultiPlatformCombo,
    cellRendererParams: ({ value }: { value: { platform: string }[] }) => ({
      platforms: value,
      iconSize: 'mid-small',
    }),
    valueGetter: ({ data }: { data: Deliverable }) => {
      if (!data?.placements?.length) {
        return [];
      }

      return [
        ...new Set(data.placements.map((placement) => placement.platform)),
      ].map((platform) => ({ platform }));
    },
    maxWidth: 200,
  },
  {
    field: 'latestContent',
    headerName: 'Latest Content',
    valueGetter: ({ data }: { data: Deliverable }) =>
      (data?.placements ?? []).sort((a, b) => {
        const dateB = b?.updatedAt?.length ? new Date(b.updatedAt) : new Date();
        const dateA = a?.updatedAt?.length ? new Date(a.updatedAt) : new Date();
        return dateB.getTime() - dateA.getTime();
      })[0]?.updatedAt,

    valueFormatter: ({ value }: { value: string }) => formatDate(value) ?? '',

    maxWidth: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: ({ data }: { data: Deliverable }) => {
      const allStatuses = Object.keys(DELIVERABLES_EXECUTION_STATUS);

      if (
        !data?.executionStatusName ||
        !allStatuses.includes(data?.executionStatusName)
      ) {
        return null;
      }

      const executionStatus =
        DELIVERABLES_EXECUTION_STATUS[
          data?.executionStatusName as keyof typeof DELIVERABLES_EXECUTION_STATUS
        ];

      return executionStatus?.label;
    },

    maxWidth: 200,
  },
  {
    field: 'totalFee',
    headerName: 'Total Fee',
    valueGetter: ({ data }: { data: Deliverable }) =>
      Number(data?.targetCogs ?? 0) +
      Number(data?.otherCogs ?? 0) +
      Number(data?.totalBonus ?? 0),

    valueFormatter: ({ value }: { value: number }) =>
      Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        value,
      ),
    maxWidth: 150,
  },
];

export default function ProjectHistory({
  deliverables,
}: {
  deliverables: Deliverable[];
}) {
  if (!deliverables?.length) {
    return null;
  }

  return (
    <AgGrid
      columnDefs={columnDefs}
      data={deliverables}
      className="stream-stats-table project-history"
    />
  );
}
