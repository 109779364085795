import React from 'react';
import { any, oneOfType, string, instanceOf, bool, node } from 'prop-types';
import moment from 'moment';
import {
  longDateFormat,
  longDateFormatWithTime,
} from 'components/utils/formats';

const getFormat = (time) => (time ? longDateFormatWithTime : longDateFormat);

function UIDate({
  date: legacyProps,
  time,
  format: parentFormat,
  className,
  children,
  placeholder,
  as: Component,
}) {
  const value = legacyProps || children;
  const format = parentFormat || getFormat(time);

  const display =
    value && moment(value).isValid()
      ? moment.utc(value).format(format)
      : placeholder;

  return <Component className={className}>{display}</Component>;
}

UIDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  as: any,
  children: oneOfType([string, instanceOf(moment)]),
  className: string,
  date: oneOfType([string, instanceOf(moment)]),
  format: string,
  placeholder: node,
  time: bool,
};

UIDate.defaultProps = {
  as: 'span',
  children: null,
  className: '',
  date: null,
  format: null,
  placeholder: 'N/A',
  time: false,
};

export default UIDate;
