import { Placement } from 'shared/types/Placement';
import benMetricstoCampaignServiceMetrics from 'shared/campaigns/creatorCampaigns/benMetricsToCampaignServiceMetrics';
import { PostAutomatedMetric } from 'shared/types/CreatorCampaigns';
import { INSTAGRAM_PLATFORM } from 'shared/metrics/kpis';

export default function getInstagramDataFromCampaignService(
  data: Placement,
  metric: string,
  metricFallback?: string,
) {
  const metricAsCampaignServiceMetric =
    benMetricstoCampaignServiceMetrics?.[INSTAGRAM_PLATFORM]?.[metric] ??
    benMetricstoCampaignServiceMetrics?.common?.[metric] ??
    metric;

  let metricWithKeyOverride = null;
  if (metricAsCampaignServiceMetric) {
    metricWithKeyOverride = data?.source?.postMetrics
      ?.postAutomatedMetrics?.[0]?.[
      metricAsCampaignServiceMetric as keyof PostAutomatedMetric
    ] as string;
  }

  const result =
    metricWithKeyOverride ??
    (data?.source?.postMetrics?.postAutomatedMetrics?.[0]?.[
      metric as keyof PostAutomatedMetric
    ] as string) ??
    null;

  if (!result && metricFallback) {
    return (
      metricWithKeyOverride ??
      (data?.source?.postMetrics?.postAutomatedMetrics?.[0]?.[
        metricFallback as keyof PostAutomatedMetric
      ] as string) ??
      null
    );
  }

  return result;
}
