import React from 'react';

export default function useDebouncedPromise(fn, delay = 300) {
  const debounceRef = React.useRef(null);

  return React.useCallback(
    (...args) =>
      new Promise((res) => {
        if (debounceRef.current) {
          clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
          res(fn(...args));
          debounceRef.current = null;
        }, delay);
      }),
    [fn, delay],
  );
}
