import { INSTAGRAM_PLATFORM, STORY_MEDIA_TYPE } from 'shared/metrics/kpis';
import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';

const metric = 'follows';
export default function follows() {
  return {
    ...basePerformanceColDef(
      metric,
      () => null,
      [INSTAGRAM_PLATFORM],
      [STORY_MEDIA_TYPE],
    ),
    headerName: 'Follows',
    width: 165,
  };
}
