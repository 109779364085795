import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';
import getYouTubeDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/youTube/helpers/getYouTubeDataFromCampaignService';
import { VIDEO_MEDIA_TYPE, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';

const metric = 'videoThumbnailClicks';

export function videoThumbnailClicksAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, 'card_clicks');
  }
  return null;
}
export default function videoThumbnailClicks() {
  return {
    ...basePerformanceColDef(
      metric,
      videoThumbnailClicksAutomatedValueGetter,
      [YOUTUBE_PLATFORM],
      [VIDEO_MEDIA_TYPE],
    ),
    headerName: 'Video Thumbnail Clicks',
    width: 275,
  };
}
