import { Placement } from 'shared/types/Placement';
import benMetricstoCampaignServiceMetrics from 'shared/campaigns/creatorCampaigns/benMetricsToCampaignServiceMetrics';
import {
  PostAutomatedMetric,
  YouTubeAuthedPostAutomatedMetric,
} from 'shared/types/CreatorCampaigns';

export default function getYouTubeDistributionDataFromCampaignService(
  data: Placement,
  [metric, metricKey]: [string, string],
  options?: {
    parsePercentageFromString?: boolean;
  },
) {
  const metricAsCampaignServiceMetric =
    benMetricstoCampaignServiceMetrics?.common?.[metric] ?? metric;

  if (!metricAsCampaignServiceMetric) {
    return null;
  }

  const commonBasePath = data?.source?.postMetrics?.postAutomatedMetrics?.[0];

  if (!commonBasePath) {
    return null;
  }

  const youTubeMetric = (commonBasePath?.authed_data?.[
    metricAsCampaignServiceMetric as keyof YouTubeAuthedPostAutomatedMetric
  ] ??
    commonBasePath?.[
      metricAsCampaignServiceMetric as keyof PostAutomatedMetric
    ]) as { rows: [[string, number | string]] } | null;

  const value =
    (youTubeMetric?.rows ?? []).find(
      (metricArray: [string, number | string]) =>
        metricArray?.[0] === metricKey,
    )?.[1] ?? null;

  if (value === null) {
    return null;
  }

  if (options?.parsePercentageFromString) {
    try {
      let removedPercentSign = value;
      if (typeof value === 'string') {
        removedPercentSign = (value ?? '').replace('%', '');
      }
      if (Number.isFinite(Number(removedPercentSign))) {
        return Number(removedPercentSign) / 100;
      }

      return null;
    } catch (e) {
      return null;
    }
  }

  return value;
}
