import { getMediaTypeFromUrl } from 'utils/files';

export type Placement = {
  drafts: Media[];
  media: Media[];
  source: { url: string };
  draftUrl: string;
  enableEdit: boolean;
};

type ReturnType = {
  url: string;
};

function isDisplayable(url: string) {
  return getMediaTypeFromUrl(url) !== 'unknown';
}

function formatResponse(data: Media[], url: string): ReturnType[] {
  const result = data.map<ReturnType>(({ url }: Media) => ({
    url: url || '',
  }));

  if (isDisplayable(url)) {
    return [{ url }].concat(result);
  }

  return result;
}

export default function getPlacementMedia({
  drafts = [],
  media = [],
  source,
  draftUrl,
  enableEdit,
}: Placement): ReturnType[] {
  if (enableEdit) {
    return formatResponse(drafts, draftUrl);
  }

  return formatResponse(media, source?.url);
}
