function SimpleCellRenderer({
  valueFormatted,
  dataCy,
  value,
}: {
  value?: string | number | null;
  valueFormatted?: string | number | null;
  dataCy?: string | null;
}) {
  return <span data-cy={dataCy}>{valueFormatted ?? value}</span>;
}

SimpleCellRenderer.componentName = 'simpleCellRenderer';

export default SimpleCellRenderer;
