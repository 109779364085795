export default function getEnvironment(host) {
  switch (host) {
    case 'stable.goben.rocks':
      return 'stable';
    case 'demo.goben.rocks':
      return 'demo';
    case 'devel.goben.rocks':
      return 'devel';
    case 'benlabs.com':
    case 'app.benlabs.com':
    case 'ben.productplacement.com':
      return 'production';
    case 'quest.uat.goben.rocks':
      return 'UAT';
    case 'tragedy.uat.goben.rocks':
      return 'UAT';
    case 'comedy.uat.goben.rocks':
      return 'UAT';
    case 'monster.uat.goben.rocks':
      return 'UAT';
    case 'voyage.uat.goben.rocks':
      return 'UAT';
    case 'rebirth.uat.goben.rocks':
      return 'UAT';
    default:
      return 'local';
  }
}
