import { MetricResult, QmMetrics } from 'components/Production/types';
import BasicStats from './BasicStats/BasicStats';
import './CardMetrics.scss';

export default function CardMetrics({
  metrics,
}: Readonly<{
  metrics: {
    platformMetrics?: QmMetrics | null;
    metricResults?: MetricResult[];
  };
}>) {
  return (
    <section className="instagram-card-metrics">
      <BasicStats metrics={metrics} />
    </section>
  );
}
