import ChevronDown from '@UIComponents/_assets/react_svg/ChevronDown';

import './DropDownCellRenderer.scss';

export default function DropDownCellRenderer({
  value,
}: {
  value: { value: string; label: string; color: string };
}) {
  return (
    <div className={`dropdown-cell-renderer ${value?.color}`}>
      <span className="dropdown-cell-renderer__label">
        {value?.label ?? 'Select'}
      </span>
      <ChevronDown size="x-small" stroke="#4c5571" />
    </div>
  );
}
