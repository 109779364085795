import BaseButton from '@UIComponents/BaseButton/BaseButton';
import BaseModal from '@UIComponents/BaseModal/BaseModal';

type ApprovalModalProps = {
  approved: boolean;
  handleApproval: () => void;
  isOpen: boolean;
  onClose: () => void;
};

function ApprovalModal({
  approved,
  handleApproval,
  isOpen,
  onClose,
}: ApprovalModalProps) {
  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} title="Approve content">
      <div className="external-placement-detail-panel-modal">
        {approved ? (
          <>
            <p className="bold">You’ve successfully approved this content!</p>
            <div className="button-wrapper">
              <BaseButton text="Got it!" onClick={onClose} />
            </div>
          </>
        ) : (
          <>
            <p className="bold">
              Are you sure you want to approve this content?
            </p>
            <div className="button-wrapper">
              <BaseButton
                text="Yes, approve content"
                onClick={handleApproval}
              />
              <BaseButton text="Cancel" variant="text" onClick={onClose} />
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}

export default ApprovalModal;
