import { Production } from '../../types/Production';

export default function getThumbnailFromProductions(
  productions: Array<Production>,
) {
  if (!productions?.length) {
    return '';
  }

  const thumbnailProduction = productions.find(
    (prod: Production) => (prod?.media ?? [])?.length > 0,
  );

  if (thumbnailProduction) {
    return thumbnailProduction?.media?.[0]?.url;
  }

  const tikTokProduction = productions.find((prod) => prod?.type === 'tiktok');

  if (tikTokProduction) {
    const tiktokCreatorDataInsights =
      tikTokProduction?.tiktokCreatorDataInsights;

    return (
      tiktokCreatorDataInsights?.authorizedDataInsights?.profile_image ??
      tiktokCreatorDataInsights?.publicDataInsights?.profile_image ??
      tiktokCreatorDataInsights?.authedUserInfo?.avatar_url
    );
  }

  return '';
}
