import storage from 'utils/storage';

export const getSelectedCampaignKey = (user) =>
  `$BEN$--selected-campaign--${user.id}`;

export const getDefaultLocation = (user) => {
  let pathDefault = '/campaigns';

  const localStorageRedirectPath = storage.get(
    '$BEN$--externalRedirectOnAuthenticated',
  );

  if (localStorageRedirectPath?.length) {
    pathDefault = localStorageRedirectPath;
  }
  return !user ? '/user/login' : pathDefault;
};
