import React, { useState } from 'react';
import {
  arrayOf,
  element,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import Tippy from '@tippyjs/react';
import './List.scss';

const joinBy = (array, joint, max, showMore) => {
  const shrink = max && array.length > max && !showMore;
  const list = shrink ? array.slice(0, max) : array;

  const lastI = list.length - 1;
  const result = [];

  list.forEach((item, i) => {
    result.push(item);
    if (i < lastI) {
      result.push(joint);
    }
  });

  if (shrink) {
    result.push('...');
  }

  return result;
};

const cbn = 'ui-list';

function UIList({ children, className, divider, empty, max }) {
  const [showMore, setShowMore] = useState(false);
  if (!Array.isArray(children) || !children.length) {
    return empty;
  }

  const hasMore = max && children.length > max;

  return (
    <span className={className}>
      {joinBy(children, divider, max, showMore)}
      {hasMore && !showMore && (
        <Tippy
          content={joinBy(children.slice(max), divider)}
          arrow
          animation="shift-toward"
        >
          <button
            className={`${cbn}__toggle-more`}
            type="button"
            onClick={() => setShowMore(true)}
          >
            + 
            {' '}
            {children.length - max}
            {' '}
            more
          </button>
        </Tippy>
      )}
      {hasMore && showMore && (
        <button
          className={`${cbn}__toggle-more`}
          type="button"
          onClick={() => setShowMore(false)}
        >
          show less
        </button>
      )}
    </span>
  );
}

UIList.propTypes = {
  children: arrayOf(oneOfType([string, shape({})])),
  className: string,
  divider: oneOfType([element, string]),
  empty: node,
  max: number,
};

UIList.defaultProps = {
  children: null,
  className: null,
  divider: ', ',
  empty: null,
  max: null,
};

export default UIList;
