export const TYPE_EXT = 'external';
export const TYPE_INT = 'internal';
export const TYPE_ADM = 'admin';
export const TYPE_CRE = 'creator';

export const USER_TYPES = {
  EXT: TYPE_EXT,
  INT: TYPE_INT,
  ADM: TYPE_ADM,
  CRE: TYPE_CRE,
};

export const ROLES_MAP = {
  admin: 'Admin',
  internal: 'Internal',
  external: 'External',
  creator: 'Creator',
  unknown: 'Unknown',
};

export const ALL_ROLE_SELECT_OPTIONS = [
  { value: TYPE_INT, label: ROLES_MAP[TYPE_INT] },
  { value: TYPE_EXT, label: ROLES_MAP[TYPE_EXT] },
  { value: TYPE_ADM, label: ROLES_MAP[TYPE_ADM] },
  { value: TYPE_CRE, label: ROLES_MAP[TYPE_CRE] },
];

export const ROLES_BY_HIERARCHY = {
  internal: ['admin', 'internal'],
};

export const ROLES_BY_TYPE = {
  internal: ['admin', 'internal, creator'],
  external: ['external'],
};

export const ROLES_BY_REPORT_ACCESS = {
  type1: ['admin'],
  type2: ['internal'],
  type3: ['external'],
  type4: ['creator'],
};

export const TEAMS = [
  'scanner',
  'coordinator',
  'qa',
  'bots',
  'content',
  'influencer',
  'client dev',
  'traditional',
  'shared services',
  'platform',
  'executive',
  'greenlight',
  'content manager admin',
  'restricted email access',
  'operations',
  'technology',
  'performance',
  'marketing',
  'artificial intelligence',
  'accounting',
  'big exports',
].sort();

export const INTERNAL_ROLES_NO_BRANDS_RESTRICTED = ['internal'];
