import React from 'react';
import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import usePermissions from 'store/authentication/usePermissions';
import { Production, QmMetrics } from 'components/Production/types';
import Loader from 'components/UI/Loader/Loader';
import { Property } from 'components/Property/types';
import { Campaign } from 'components/Campaign/types';
import { Analytics } from '@segment/analytics-next';
import CardMetrics from './CardMetrics/CardMetrics';
import YouTubeProductionQuery from './graphql/YouTubeProductionQuery.graphql';
import './YoutubeCard.scss';

const QM_YOUTUBE_METRICS = 'yt2-productions';

export default function YouTubeCard({
  production,
  property,
  campaign,
  userId,
  segment,
  apolloClient,
}: {
  production: Production;
  property?: Property;
  campaign?: Campaign;
  userId?: number;
  segment?: Analytics | null;
  apolloClient: ApolloClient<NormalizedCacheObject>;
}) {
  const [youTubeQuery, setYouTubeQuery] = React.useState<ApolloQueryResult<{
    production?: Production;
  }> | null>(null);
  const permissions = usePermissions('productions');

  React.useEffect(() => {
    async function load() {
      const result = await apolloClient.query({
        query: YouTubeProductionQuery,
        variables: {
          id: production?.id,
          productionsSeeSensitiveData: true,
        },
      });

      setYouTubeQuery(result);
    }

    if (!apolloClient || !production?.id) {
      return;
    }

    load();
  }, [production?.id, apolloClient, permissions?.seeSensitiveData]);

  const platformMetrics = React.useMemo(() => {
    const qmMetrics:
      | QmMetrics[]
      | undefined = production?.qmMetrics as QmMetrics[];

    return qmMetrics
      ? qmMetrics.find((p) => p.type === QM_YOUTUBE_METRICS)
      : null;
  }, [production?.qmMetrics]);

  const { audienceMetrics } = React.useMemo(
    () => ({
      audienceMetrics: youTubeQuery?.data?.production?.audience,
    }),
    [youTubeQuery?.data?.production?.audience],
  );

  if (youTubeQuery?.loading) {
    return <Loader />;
  }

  return (
    <section className="creator-youtube-details">
      <CardMetrics
        metrics={{
          platformMetrics,
          audienceMetrics,
        }}
        production={production}
        property={property}
        campaign={campaign}
        userId={userId}
        segment={segment}
      />
    </section>
  );
}
