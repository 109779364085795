import React from 'react';
import EmptyState from 'external/components/UI/EmptyState/EmptyState';

import noDataEmptyState from 'components/assets/img/brand-app-no-data-empty-state.svg';

function NoDataEmptyState() {
  return (
    <EmptyState
      title="Oh, Hello there!"
      message="We’re still getting things ready."
      info="Once we’re done, you’ll be able to review details about your campaign here."
      image={noDataEmptyState}
      position="horizontal"
    />
  );
}

export default NoDataEmptyState;
