import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { func, number, shape, bool, string } from 'prop-types';

import ApplicationContext from 'components/Application/Context';
import { ExternalApplicationContext } from 'external/components/Application/Context';

import { trackCreatorRedirected } from 'external/components/Segment/Segment';

import ExternalPlacementCard from 'external/components/Placement/Card/Card';
import ExternalPropertyPanel from 'external/components/Property/Panel/Panel';
import { getMetaData } from 'external/components/Property/utils';
import { getMetrics } from 'external/components/Placement/utils/getMetrics';
import getPropertyImageForDeliverable from 'external/utils/getPropertyImageForDeliverable';
import UIDisablePageScroll from 'components/UI/DisablePageScroll/DisablePageScroll';
import UILoader from 'components/UI/Loader/Loader';
import usePermissions from 'store/authentication/usePermissions';

import './DetailsPanel.scss';
import { Placement, Source } from 'components/Placement/types';
import { Deliverable } from 'components/Deliverable/types';
import { Production } from 'components/Production/types';
import { Analytics } from '@segment/analytics-next';
import { Campaign } from 'components/Campaign/types';
import { User } from 'components/User/types';
import { Platforms as Platform } from 'shared/types/platforms';
import { RedirectSection } from 'external/components/Segment/creatorsTracks';
import { IInitialsItem } from 'external/components/UI/ColoredInitials/types';

import getPlacementMedia from '../utils/getPlacementMedia';
import { PlacementInDeliverableDetailsQuery } from './graphql';

type ExternalPlacementDetailsPanelProps = {
  deliverable: Deliverable;
  enableEdit: boolean;
  onClickClosePanel: () => void;
  platform: Platform;
  selectedPlacementId: number | null;
  selectPlacement: (arg1: Placement) => void;
  source: Source;
  campaignReportingWindow?: string;
  campaignSubType?: string;
  initials?: IInitialsItem;
};

const mergeDeliverables = (
  selectedDeliverable: Deliverable,
  dataDeliverable?: Deliverable | null,
) => {
  if (dataDeliverable?.id !== selectedDeliverable?.id) {
    return selectedDeliverable;
  }
  return { ...selectedDeliverable, ...dataDeliverable };
};

const getProductionUrl = (productions: Production[], platform: string) => {
  const production = productions?.find((p: Production) => p.type === platform);

  return production?.url;
};

const onClickProductionUrl = (
  segment: Analytics | null,
  campaign: Campaign | null | undefined,
  user: User | null,
  deliverable: Deliverable,
) => {
  if (segment && user && campaign && deliverable) {
    trackCreatorRedirected({
      segment,
      campaign,
      user,
      deliverable,
      section: RedirectSection.Icon,
    });
  }
};

function ExternalPlacementDetailsPanel({
  deliverable,
  enableEdit,
  onClickClosePanel,
  platform,
  selectedPlacementId,
  selectPlacement,
  source,
  campaignReportingWindow,
  campaignSubType,
  initials,
}: ExternalPlacementDetailsPanelProps) {
  const { user } = useContext(ApplicationContext);
  const { campaign, segment } = useContext(ExternalApplicationContext);

  const deliverableId = deliverable?.id;

  const { data } = useQuery(PlacementInDeliverableDetailsQuery, {
    skip: !selectedPlacementId,
    variables: {
      id: selectedPlacementId,
      reportingWindow: campaignSubType?.includes('Performance')
        ? 'monthEnd'
        : campaignReportingWindow,
    },
  });

  const placement = data?.externalPlacement ?? {};

  let { property } = mergeDeliverables(deliverable, placement?.deliverable);

  if (!property && source) {
    ({ property } = source.production);
  }

  const thumb = getPropertyImageForDeliverable({ property });
  const metadata = getMetaData(property);
  const name = property.creator?.name || property?.name;
  const { city, country } = property.creator || {};
  const placementId = placement.id;
  const placementPermissions = usePermissions('placements');
  const managePlacement = placementPermissions?.manage;
  const placementUrl =
    managePlacement && placementId && `/team/placements/${placementId}/edit`;
  const productionUrl = getProductionUrl(property.productions, platform);
  const placementParams = { ...placement, enableEdit };
  const postUrl = enableEdit ? placement?.draftUrl : placement?.source?.url;

  return (
    <ExternalPropertyPanel
      city={city}
      className="external-placement-details-panel"
      country={country}
      metadata={metadata}
      name={name}
      onClickClosePanel={onClickClosePanel}
      thumb={thumb}
      internalLink={placementUrl}
      productionLink={productionUrl}
      onClickProductionUrl={() =>
        onClickProductionUrl(segment, campaign, user, deliverable)
      }
      initials={initials}
    >
      {deliverableId && (
        <UIDisablePageScroll disabledScrollClassName="body-deliverable-modal" />
      )}
      <div className="external-placement-details-panel__placements">
        {data ? (
          <ExternalPlacementCard
            key={placement.id}
            id={placement.id}
            description={placement.description}
            enableEdit={enableEdit}
            isActive={selectedPlacementId === placement.id}
            media={getPlacementMedia(placementParams)}
            externalApproval={placement.externalApproval}
            rejectedReason={placement.rejectedReason}
            externalMessagesCount={placement.messagesCount?.external}
            metrics={getMetrics(placement)}
            onClick={() => selectPlacement(placement)}
            url={postUrl}
            placement={placement}
          />
        ) : (
          <UILoader />
        )}
      </div>
    </ExternalPropertyPanel>
  );
}

ExternalPlacementDetailsPanel.propTypes = {
  deliverable: shape({
    id: number,
  }).isRequired,
  enableEdit: bool,
  onClickClosePanel: func.isRequired,
  platform: string,
  selectedPlacementId: number,
  selectPlacement: func,
};

ExternalPlacementDetailsPanel.defaultProps = {
  selectedPlacementId: null,
  enableEdit: false,
  selectPlacement: () => {},
};

export default ExternalPlacementDetailsPanel;
