import React, { useContext } from 'react';
import { bool, arrayOf, string, oneOfType, node } from 'prop-types';
import classnames from 'classnames';
import Context from './Context';
import AdvancedFiltersContext from '../Context';

import './Section.scss';

const cbn = 'filter-section';

function FilterSection({
  title,
  children,
  isActive,
  classname: parentClassname,
}) {
  const { activeSections } = useContext(AdvancedFiltersContext);
  let classname = parentClassname;
  if (!classname) {
    classname = (title ?? '').toLowerCase();
  }
  return (
    <section
      className={classnames(cbn, classname, {
        [`${cbn}--is-active`]: isActive || activeSections[title],
      })}
    >
      {title && <h1 className={`${cbn}__title`}>{title}</h1>}
      <div className={`${cbn}__children`}>
        <Context.Provider value={{ name: title }}>{children}</Context.Provider>
      </div>
    </section>
  );
}

FilterSection.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  isActive: bool,
  title: string,
  classname: string,
};

FilterSection.defaultProps = {
  children: null,
  isActive: false,
  title: null,
  classname: null,
};

export default FilterSection;
