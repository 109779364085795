export const nFormatter = (num: number): string => {
  let formattedResult: string | number = Math.sign(num) * Math.abs(num);

  if (Math.abs(num) >= 1000) {
    formattedResult = `${
      Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1))
    }K`;
  }

  if (Math.abs(num) >= 1000000) {
    formattedResult = `${
      Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(1))
    }M`;
  }

  return String(formattedResult);
};
