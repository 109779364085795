/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { func, number, shape } from 'prop-types';
import { Deliverable } from 'components/Deliverable/types';
import { ReportingWindow } from 'shared/types/Campaign';

import ApplicationContext from 'components/Application/Context';
import { ExternalApplicationContext } from 'external/components/Application/Context';

import {
  trackCreatorDeclined,
  trackCreatorRedirected,
  trackCreatorApproved,
} from 'external/components/Segment/Segment';

import ExternalPropertyPanel from 'external/components/Property/Panel/Panel';
import { getMetrics } from 'components/Production/Search/utils/getMetrics';
import { PRODUCTION_EXTERNAL_VIEW_METRICS } from 'components/Metrics/constants/metricTypes';
import getPropertyImageForDeliverable from 'external/utils/getPropertyImageForDeliverable';
import {
  EXTERNAL_APPROVAL_STATUSES,
  ExternalApproval,
} from 'constants/approvalStatuses';
import getBestMetricResult from 'external/utils/getBestMetricResult';
import sortProductionsByMediaTarget from 'external/utils/sortProductionsByMediaTarget';
import { getMetaData } from 'external/components/Property/utils';
import CreatorHighlights from 'external/components/UI/CreatorHighlights/CreatorHighlights';
import StatusLabel from 'external/components/UI/StatusLabel/StatusLabel';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import BaseTitle from '@UIComponents/BaseTitle/BaseTitle';

import { UpdateDeliverableExternalApproval } from 'external/components/Deliverable/List/graphql';
import { RedirectSection } from 'external/components/Segment/creatorsTracks';
import { IInitialsItem } from 'external/components/UI/ColoredInitials/types';
import usePermissions from 'store/authentication/usePermissions';

import ApprovalModal from './Modal/ApprovalModal';
import RejectionModal from './Modal/RejectionModal';

import YoutubeDetailsPanel from './Youtube/Youtube';
import TwitterDetailsPanel from './Twitter/Twitter';
import FacebookDetailsPanel from './Facebook/Facebook';
import InstagramDetailsPanel from './Instagram/Instagram';
import TwitchDetailsPanel from './Twitch/Twitch';
import TikTokDetailsPanel from './TikTok/TikTok';
import GenericDetailsPanel from './Generic/Generic';
import { DeliverableDetailsQuery } from './graphql';
import { getDeliverableExternalApprovalStatus } from '../utils';

import './DetailsPanel.scss';

// We might want to construct our own metrics list later on
const METRICS_RESULTS = PRODUCTION_EXTERNAL_VIEW_METRICS;
const METRICS_PREDICTIONS = PRODUCTION_EXTERNAL_VIEW_METRICS.filter(
  (metric) =>
    !['averageView'].includes(metric.metricType) &&
    !['twitch', 'youtube'].includes(metric.platform),
);

const mergeDeliverables = (
  selectedDeliverable: Deliverable,
  dataDeliverable: Deliverable,
) => {
  if (dataDeliverable?.id !== selectedDeliverable.id) {
    return selectedDeliverable;
  }
  return { ...selectedDeliverable, ...dataDeliverable };
};

type DeliverableDetailsPanelProps = {
  campaignReportingWindow?: ReportingWindow;
  deliverable: Deliverable;
  enableEdit?: boolean;
  onClickClosePanel: () => void;
  initials?: IInitialsItem;
};

// eslint-disable-next-line max-lines-per-function, max-statements
function DeliverableDetailsPanel({
  campaignReportingWindow,
  deliverable,
  enableEdit,
  onClickClosePanel,
  initials,
}: DeliverableDetailsPanelProps) {
  const { user } = useContext(ApplicationContext);
  const { campaign, segment } = useContext(ExternalApplicationContext);
  const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
  const [showRejectionModal, setShowRejectionModal] = useState<boolean>(false);
  const [updateDeliverableExternalApproval] = useMutation(
    UpdateDeliverableExternalApproval,
  );

  const { data } = useQuery(DeliverableDetailsQuery, {
    variables: {
      metricResultsConfig: METRICS_RESULTS,
      metricPredictionsConfig: METRICS_PREDICTIONS,
      id: deliverable.id,
    },
  });

  const deliverableId = deliverable.id;
  const { rejectedReason } = deliverable;

  useEffect(() => {
    document.body.classList.add('body-deliverable-modal');
    return () => {
      document.body.classList.remove('body-deliverable-modal');
    };
  }, [deliverableId]);

  const onPlatformRedirectClick = () => {
    if (segment && user && campaign) {
      trackCreatorRedirected({
        segment,
        campaign,
        user,
        deliverable,
        section: RedirectSection.Title,
      });
    }
  };

  const handleApproveForOutreach = async () => {
    if (segment && user && campaign) {
      trackCreatorApproved({ segment, campaign, user, deliverable });
    }
    await updateDeliverableExternalApproval({
      variables: {
        id: deliverableId,
        payload: {
          externalApproval: EXTERNAL_APPROVAL_STATUSES.APPROVED,
          rejectedReason: '',
        },
      },
    });
  };

  const handleDeclineCreator = async (option: string) => {
    if (segment && user && campaign) {
      trackCreatorDeclined({
        segment,
        campaign,
        user,
        deliverable,
        message: option,
      });
    }

    await updateDeliverableExternalApproval({
      variables: {
        id: deliverableId,
        payload: {
          externalApproval: EXTERNAL_APPROVAL_STATUSES.REJECTED,
          rejectedReason: option,
        },
      },
    });
  };

  const { property, externalApproval, viewedByClient } = mergeDeliverables(
    deliverable,
    data?.deliverable,
  );

  const thumb = getPropertyImageForDeliverable({ property });
  const metadata = getMetaData(property);

  const { synopsis } =
    property.productions?.find((production) => production.synopsis) || {};

  const productionGroupsSorted = sortProductionsByMediaTarget({
    productions: property?.productions || [],
    mediaTargets: deliverable?.campaign?.mediaTargets || [],
  });

  const name = property?.name || property?.creator?.name || '';

  const { city, country } = property.creator || {};

  const { state, text } = getDeliverableExternalApprovalStatus({
    externalApproval,
    viewedByClient,
  });

  const deliverablesPermission = usePermissions('deliverables');
  const privilegedControls = deliverablesPermission?.privilegedControls;
  const deliverableUrl =
    privilegedControls &&
    deliverableId &&
    `/team/deliverables/${deliverableId}/edit`;
  const submitted = externalApproval === ExternalApproval.Approved;
  const rejected = externalApproval === ExternalApproval.Rejected;
  const showReasonsToBelieve =
    campaign?.brandAppPermissions?.reasonsToBelieve &&
    deliverable.reasonToBelieve;

  return (
    <ExternalPropertyPanel
      city={city}
      className="external-deliverable-details-panel"
      country={country}
      metadata={metadata}
      name={name}
      onClickClosePanel={onClickClosePanel}
      thumb={thumb}
      internalLink={deliverableUrl}
      initials={initials}
    >
      {enableEdit && (
        <div className="external-content__detail-panel__approval__wrapper">
          {!externalApproval ? (
            <div className="action-area">
              <BaseButton
                text="Approve for outreach"
                onClick={() => setShowApprovalModal(!showApprovalModal)}
              />
              <BaseButton
                text="Decline creator"
                variant="outlined"
                onClick={() => setShowRejectionModal(!showRejectionModal)}
              />
            </div>
          ) : (
            <div className="external-content__detail-panel__status">
              <StatusLabel status={state} text={text} />
              {rejected && rejectedReason ? <p>{rejectedReason}</p> : null}
            </div>
          )}
          <div className="divider actions" />
        </div>
      )}

      <BaseTitle title="Creator highlights" size="small" />

      <CreatorHighlights
        description={synopsis}
        reasonToBelieve={
          showReasonsToBelieve ? deliverable.reasonToBelieve : undefined
        }
        recommendedBy={deliverable.createdBy}
        propertyName={property?.name}
      />
      <div className="divider highlights" />

      <div className="external-content__detail-panel__channels__wrapper">
        <BaseTitle title="Platforms" size="small" />
        <div className="external-content__detail-panel__channels__container">
          {productionGroupsSorted.map((productions) =>
            productions.map((production) => {
              const { results, predictions } = getMetrics(production);

              if (production.type === 'youtube') {
                return (
                  <YoutubeDetailsPanel
                    key={production.id}
                    postFrequency={production.postFrequency}
                    predictedViews={predictions.views}
                    predictedVPC={predictions.vpc}
                    showReportingWindowMessage={
                      campaignReportingWindow !== 'live30'
                    }
                    subscribers={getBestMetricResult(production, 'subscribers')}
                    title={property.name}
                    onRedirectClick={() => onPlatformRedirectClick()}
                    url={production.url}
                    videoCount={production.videoCount}
                  />
                );
              }
              if (production.type === 'twitter') {
                return (
                  <TwitterDetailsPanel
                    key={production.id}
                    followers={getBestMetricResult(production, 'followers')}
                    title={property.name}
                    onRedirectClick={() => onPlatformRedirectClick()}
                    url={production.url}
                  />
                );
              }
              if (production.type === 'facebook') {
                return (
                  <FacebookDetailsPanel
                    key={production.id}
                    likes={getBestMetricResult(production, 'likes')}
                    followers={getBestMetricResult(production, 'followers')}
                    title={property.name}
                    onRedirectClick={() => onPlatformRedirectClick()}
                    url={production.url}
                  />
                );
              }
              if (production.type === 'instagram') {
                return (
                  <InstagramDetailsPanel
                    key={production.id}
                    averageEngagement={results.averageEngagement}
                    followers={getBestMetricResult(production, 'followers')}
                    posts={production.mediaCount}
                    postFrequency={production.postFrequency}
                    predictedViews={predictions.views}
                    showReportingWindowMessage={
                      campaignReportingWindow !== 'live30'
                    }
                    title={property.name}
                    onRedirectClick={() => onPlatformRedirectClick()}
                    url={production.url}
                  />
                );
              }
              if (production.type === 'tiktok') {
                return (
                  <TikTokDetailsPanel
                    key={production.id}
                    followers={getBestMetricResult(production, 'followers')}
                    title={property.name}
                    onRedirectClick={() => onPlatformRedirectClick()}
                    url={production.url}
                  />
                );
              }

              if (production.type === 'twitch') {
                return (
                  <TwitchDetailsPanel
                    key={production.id}
                    followers={getBestMetricResult(production, 'followers')}
                    title={property.name}
                    onRedirectClick={() => onPlatformRedirectClick()}
                    url={production.url}
                  />
                );
              }

              if (production.type === 'other') {
                return (
                  <GenericDetailsPanel
                    key={production.id}
                    followers={getBestMetricResult(production, 'followers')}
                    title={property.name}
                    onRedirectClick={() => onPlatformRedirectClick()}
                    url={production.url}
                  />
                );
              }
              return null;
            }),
          )}
        </div>
        <ApprovalModal
          handleApproval={handleApproveForOutreach}
          isOpen={showApprovalModal}
          name={name}
          onClose={() => setShowApprovalModal(false)}
          submitted={submitted}
        />
        <RejectionModal
          handleRejection={handleDeclineCreator}
          isOpen={showRejectionModal}
          name={name}
          onClose={() => setShowRejectionModal(false)}
          rejected={rejected}
        />
      </div>
    </ExternalPropertyPanel>
  );
}

DeliverableDetailsPanel.propTypes = {
  deliverable: shape({
    id: number,
  }).isRequired,
  onClickClosePanel: func.isRequired,
};

export default DeliverableDetailsPanel;
