import './DisablePageScroll.scss';
import { useEffect } from 'react';

type UIDisablePageScrollProps = {
  disabledScrollClassName: string;
};

// @ts-ignore
function UIDisablePageScroll({
  disabledScrollClassName,
}: UIDisablePageScrollProps) {
  useEffect(() => {
    document.body.classList.add(disabledScrollClassName);
    return () => {
      document.body.classList.remove(disabledScrollClassName);
    };
  }, [disabledScrollClassName]);
  return null;
}

UIDisablePageScroll.defaultProps = {
  disabledScrollClassName: 'ui-disable-page-scroll',
};

export default UIDisablePageScroll;
