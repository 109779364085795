import get from 'lodash/get';
import Joi from 'joi';
import { language } from 'store/form';
import set from 'lodash/set';
import { errorStream } from 'store/errorHandler';

export const getTooltip = (field, tooltips) =>
  get(tooltips, `${field}.description`, null);

export const normSchema = (schema) =>
  (Joi.isSchema(schema) ? schema : Joi.object().keys(schema));

export const formatErrors = (error) => {
  const errors = {};
  if (!error) {
    return errors;
  }

  if (error.details) {
    error.details.forEach((err) => {
      const path = Array.isArray(err.path) ? err.path.join('.') : err.path;

      set(errors, path, err.message);
    });

    return errors;
  }

  if (error.message) {
    errors.error = error.message;
    return errors;
  }
  return errors;
};

export const parse = (values, schema) => {
  const { error, value } = normSchema(schema).validate(values, {
    allowUnknown: false,
    stripUnknown: true,
    abortEarly: false,
    convert: true,
  });

  return { errors: formatErrors(error), value };
};

export const validate = (schema) => (values) => {
  const { error } = normSchema(schema).validate(values, {
    convert: true,
    allowUnknown: true,
    skipFunctions: true,
    abortEarly: false,
    language,
  });

  return formatErrors(error);
};

const getKeys = (schema) =>
  (Joi.isSchema(schema)
    ? schema.$_terms.keys.map(({ key }) => key)
    : Object.keys(schema));

export const defaultValues = (schema) => {
  const normalizedSchema = normSchema(schema).fork(getKeys(schema), (x) =>
    x.optional());
  const { value } = normalizedSchema.validate(
    {},
    {
      abortEarly: false,
    },
  );
  return value;
};

export const hasErrors = (errors) => errors && Object.keys(errors) > 0;

export const getInitialValues = (template, id, data) => {
  if (!id) {
    return template;
  }
  return data || null;
};

export const throwFormError = (errors) => {
  errorStream.dispatch({ type: 'formValidationError', error: errors });
};

export const preSubmit = (payload, schema, ignoreErrors) => {
  const { errors, value } = parse(payload, schema);

  if (!ignoreErrors && Object.keys(errors).length) {
    errorStream.dispatch({ type: 'formValidationError', error: errors });
    throw errors;
  }

  return value;
};

export const createObjectIdFields = (fields) => {
  const result = {};
  Object.entries(fields).forEach(([field, value]) => {
    if (value && !Number.isNaN(Number(value))) {
      result[field] = { id: Number(value) };
    }
  });
  return result;
};
