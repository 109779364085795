import React from 'react';
import './DataPill.scss';

type DataPillProps = {
  label: string;
  value: string;
  vertical?: boolean;
  className?: string;
};

const DataPill: React.FC<DataPillProps> = ({
  label, value, vertical = false, className = '',
}) => (
  <div
    data-cy="DataPill"
    className={`DataPill DataPill-${vertical ? 'vertical' : 'horizontal'} ${className}`}
  >
    <span className="label">{label}</span>
    <span className="value">{value}</span>
  </div>
);

export default DataPill;
