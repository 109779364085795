import { Placement } from 'shared/types/Placement';
import {
  INSTAGRAM_PLATFORM,
  PHOTO_MEDIA_TYPE,
  POST_MEDIA_TYPE,
} from 'shared/metrics/metrics';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';

import getInstagramDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/instagram/helpers/getInstagramDataFromCampaignService';
import basePerformanceColDef from './helpers/baseColDef';

const metric = 'impressions';

export function viewsAutomatedValueGetter(data: Placement) {
  if (
    data?.platform === INSTAGRAM_PLATFORM &&
    [POST_MEDIA_TYPE, PHOTO_MEDIA_TYPE].includes(data?.mediaType ?? '')
  ) {
    return getInstagramDataFromCampaignService(data, metric);
  }
  return null;
}

export default function impressions() {
  return {
    ...numberColDef,
    ...basePerformanceColDef(
      metric,
      viewsAutomatedValueGetter,

      [INSTAGRAM_PLATFORM],
      [POST_MEDIA_TYPE, PHOTO_MEDIA_TYPE],
    ),
    headerName: 'Impressions',
  };
}
