import SimpleMetricsTable from '@UIComponents/SimpleMetricsTable/SimpleMetricsTable';
import { Production, QmMetrics } from 'components/Production/types';
import { getBasicStatsRows } from '../../utils/tableRows';

export default function BasicStats({
  production,
  platformMetrics,
}: {
  production: Production;
  platformMetrics?: QmMetrics | null;
}) {
  return (
    <section className="youtube-basic-stats-container">
      <h2>Basic Stats</h2>
      <SimpleMetricsTable
        className="basic-stats"
        rows={getBasicStatsRows(platformMetrics, production)}
      />
    </section>
  );
}
