import { Route, Routes } from 'react-router-dom';
import RoutePrivate from 'external/components/Route/Private/Private';
import View from './View/View';
import List from './List/List';

function ScreensExternalCampaigns() {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <RoutePrivate
            element={List}
            exact
            requiredPermission="campaigns.read"
          />
        }
      />
      <Route
        path=":campaignId/*"
        element={
          <RoutePrivate element={View} requiredPermission="campaigns.read" />
        }
      />
      <Route
        path=":campaignId/:tab/*"
        element={
          <RoutePrivate element={View} requiredPermission="campaigns.read" />
        }
      />
    </Routes>
  );
}

export default ScreensExternalCampaigns;
