import upperFirst from 'lodash/upperFirst';
import { platformUrlsRegex } from 'shared/regex/platformUrls';

export const PRODUCTION_TYPES = [
  'season',
  'film',
  'instagram',
  'youtube',
  'facebook',
  'twitter',
  'twitch',
  'tiktok',
  'other',
];

export const PRODUCTION_TYPES_CUSTOM_LABELS = { youtube: 'YouTube' };

export const PRODUCTION_TYPES_URL_MAPPING = [
  {
    name: 'instagram',
    regex: /^(https?:\/\/)?(www\.)?(instagram\.com)\/.*$/i,
    usernameRegex: /^(?:https?:\/\/)?(?:www\.)?(?:instagram\.com)\/([^/]*)(?:\/?)(?:[^/]*?)$/i,
    channel: 'digital',
    type: 'instagram',
  },
  {
    name: 'youtube',
    regex: platformUrlsRegex.youTube,
    usernameRegex: platformUrlsRegex.youTubeUsername,
    channel: 'digital',
    type: 'youtube',
    example: 'https://www.youtube.com/channel/UCN9Jv9dvDSNKzYtUaTdP3YQ',
    additionalPatterns: [
      {
        regex: /^(https?:\/\/)(www\.)(youtube\.com)\/@\w*/i,
        message:
          'This page does not YET support adding of YouTube channels with the "@handle" links. You can either add the channel via the updated Send2BEN plug-in, which will work for "@handle" links, or visit https://commentpicker.com/youtube-channel-id.php to change the link into the structure below and add via the current page.',
      },
    ],
  },
  {
    name: 'facebook',
    regex: /^(https?:\/\/)?(www\.)?(facebook\.com)\/.*$/i,
    usernameRegex: /^(?:https?:\/\/)?(?:www\.)?(?:facebook\.com)\/([^/]*)(?:\/?)(?:[^/]*?)$/i,
    channel: 'digital',
    type: 'facebook',
  },
  {
    name: 'twitter',
    regex: /^(https?:\/\/)?(www\.)?(twitter\.com)\/.*$/i,
    usernameRegex: /^(?:https?:\/\/)?(?:www\.)?(?:twitter\.com)\/([^/]*)(?:\/?)(?:[^/]*?)$/i,
    channel: 'digital',
    type: 'twitter',
  },
  {
    name: 'twitch',
    regex: /^(https?:\/\/)?(www\.)?(twitch\.tv)\/.*$/i,
    usernameRegex: /^(?:https?:\/\/)?(?:www\.)?(?:twitch\.tv)\/([^/]*)(?:\/?)(?:[^/]*?)$/i,
    channel: 'digital',
    type: 'twitch',
  },
  {
    name: 'tiktok',
    regex: /^(https?:\/\/)?(www\.)?(tiktok\.com)\/.*$/,
    usernameRegex: /^(?:https?:\/\/)?(?:www\.)?(?:tiktok\.com)\/@([^/]*)(?:\/?)(?:[^/]*?)$/,
    channel: 'digital',
    type: 'tiktok',
  },
];

export const PRODUCTION_TYPES_URL_MAPPING_BY_KEY = PRODUCTION_TYPES_URL_MAPPING.reduce(
  (acc, el) => {
    const newAcc = { ...acc };
    newAcc[el.name] = el;
    return newAcc;
  },
  {},
);

export const PRODUCTION_TYPES_OPTIONS = PRODUCTION_TYPES.map((value) => ({
  value,
  label: PRODUCTION_TYPES_CUSTOM_LABELS[value] || upperFirst(value),
}));

export const PRODUCTION_TYPE_ICONS = {
  facebook: 'facebook_no_line',
  youtube: 'youtube_no_line',
  instagram: 'instagram_no_line',
  twitter: 'twitter_no_line',
  twitch: 'twitch_no_line',
  season: 'tv',
  film: 'film',
  tiktok: 'tiktok_no_line',
  other: 'folder_open',
};
