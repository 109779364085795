// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import { childrenToString } from 'utils/string';
import { FormSelectorValueProps } from 'components/Form/Selector/types';

import './LabelValue.scss';

const cbn = 'form-selector-label-value';

type FormSelectorLabelValueProps = FormSelectorValueProps;

function FormSelectorLabelValue({
  data,
  isDisabled,
  isFocused,
  removeProps,
  selectProps,
}: FormSelectorLabelValueProps) {
  const label = selectProps.getOptionLabel(data);

  return (
    <button
      type="button"
      {...removeProps}
      disabled={isDisabled}
      className={classnames(cbn, {
        [`${cbn}--is-disabled`]: isDisabled,
        [`${cbn}--is-focused`]: isFocused,
      })}
    >
      <div className={`${cbn}__label-wrapper`}>
        <span className={`${cbn}__label`} title={childrenToString(label)}>
          {label}
        </span>
      </div>
    </button>
  );
}

FormSelectorLabelValue.defaultProps = {
  data: null,
  isDisabled: false,
  isFocused: false,
  removeProps: {},
  selectProps: {},
};

export default FormSelectorLabelValue;
