import { createBrowserHistory } from 'history';
import { get, omit } from 'lodash';

import basePath from 'utils/basePath';

const history = createBrowserHistory({ basename: `${basePath}/` });
/**
 * @TODO
 * This content will be removed in the near future
 * Files to change when this file it's removed:
 * app/web/src/components/Brand/Form/handlers.js
 * app/web/src/components/Deliverable/Form/Form.jsx
 * app/web/src/components/Navigation/Provider.jsx
 * app/web/src/components/Person/Form/handlers.js
 * app/web/src/components/Placement/Form/Form.jsx
 * app/web/src/components/Program/Form/Form.jsx
 * app/web/src/components/Property/Form/handlers.js
 * app/web/src/components/Source/Form/handlers.js
 * app/web/src/components/Taxonomy/Form/handlers.js
 * app/web/src/components/UI/Button/Button.tsx
 * app/web/src/components/UI/Link/Link.tsx
 * app/web/src/components/UI/NavigationArrows/NavigationArrows.jsx
 * app/web/src/components/Campaign/Influencer/Form/Form.jsx
 * app/web/src/components/Campaign/Form/Form.jsx
 * app/web/src/components/Client/Form/handlers.js
 * app/web/src/components/Form/Filter/useVelcro.js
 * app/web/src/components/Form/PersonSelector/PersonSelector.jsx
 * app/web/src/components/InstagramStories/InstagramStories.jsx
 * app/web/src/components/Person/Form/handlers.js
 * app/web/src/components/Production/Create/Create.jsx
 * app/web/src/components/Production/Create/handlers.js
 * app/web/src/components/User/AccessAgreement/AccessAgreement.jsx
 * app/web/src/external/components/User/AccessAgreement/AccessAgreement.jsx
 * app/web/src/components/User/Form/handlers.js
 * app/web/src/components/User/Form/Form.jsx
 * web/src/external/components/Application/Provider.jsx
 */

history.rawPush = history.push;

history.push = (to, state) => {
  let toFinal = to;
  if (typeof to === 'string' && to?.indexOf('/team/') < 0) {
    toFinal = `/team${  to}`;
  }
  const currentReferrers = get(history, 'location.state.referrers') || [];
  const restState = omit(state, [
    'keepReferrer',
    'pushReferrer',
    'popReferrer',
  ]);
  if (state && state.keepReferrer) {
    return history.rawPush(toFinal, {
      ...restState,
      referrers: currentReferrers,
    });
  }

  if (state && state.pushReferrer) {
    const { pathname, search } = history.location;
    return history.rawPush(toFinal, {
      ...restState,
      referrers: [...currentReferrers, `${pathname}${search}`],
    });
  }

  if (state && state.popReferrer) {
    return history.rawPush(toFinal, {
      ...restState,
      referrers: currentReferrers.slice(0, -1),
    });
  }

  return history.rawPush(toFinal, restState);
};

export default history;
