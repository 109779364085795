import BaseDomoIframe from './BaseDomoIframe';
import { FrameProps } from './types';

function DomoIframeYoutube({ campaignId = null }: FrameProps) {
  return (
    <BaseDomoIframe
      IframeName="Youtube"
      campaignId={campaignId}
      platform="youtube"
    />
  );
}

export default DomoIframeYoutube;
