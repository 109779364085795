import ReasonToBelieve from 'external/components/UI/CreatorHighlights/ReasonToBelieve';
import RecommendedBy from 'external/components/UI/CreatorHighlights/RecommendedBy';
import Description from './Description';

import './CreatorHighlights.scss';

type CreatorHighlightsProps = {
  description?: string;
  reasonToBelieve?: string;
  recommendedBy?: {
    id: number;
    firstName: string;
    lastName: string;
    title: string;
  };
  propertyName: string;
};

function CreatorHighlights({
  description,
  reasonToBelieve,
  recommendedBy,
  propertyName,
}: CreatorHighlightsProps) {
  return (
    <div className="CreatorHighlights">
      {reasonToBelieve ? (
        <ReasonToBelieve
          reasonToBelieve={reasonToBelieve}
          propertyName={propertyName}
        />
      ) : null}
      {description ? <Description description={description} /> : null}
      {recommendedBy ? <RecommendedBy recommendedBy={recommendedBy} /> : null}
    </div>
  );
}

export default CreatorHighlights;
