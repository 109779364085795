import { SegmentEvents } from 'components/Segment/segmentEvents';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import { Campaign } from 'shared/types/Campaign';
import React from 'react';
import ApplicationContext from 'components/Application/Context';
import * as Sentry from '@sentry/react';
import { Filters } from 'components/Discovery/types';

export enum AllowedAction {
  addedToCampaign = 'User added deliverable to campaign',
  appliedNewFilters = 'User applied new Discovery filters',
  cancelOrder = 'User is canceling order',
  changedPeriodOption = 'User changed period option',
  clickedCancelButton = 'User clicked on the Cancel button',
  clickedContentLink = 'User clicked on the content link',
  clickedCreatorHandleName = "User clicked on the creator's handle name",
  clickedManageSparkAds = 'User clicked on the manage Spark Ads button',
  clickedResendRequest = 'User clicked on the Resend Request button ',
  clickedSendRequest = 'User clicked on the Send Request button',
  closeCancelationModal = 'User closed cancelation modal',
  closeConfirmationModal = 'User closed confirm order modal',
  closedDrawer = 'User closed drawer',
  confirmCancelOrder = 'User confirmed order cancelation',
  confirmOrder = 'User confirmed order creation',
  copiedContentId = 'User copied content ID to clipboard',
  copiedDeliverableUrl = 'User copied deliverable URL to clipboard',
  copiedInviteLink = 'User copied TikTok invite link to clipboard',
  openedDrawer = 'User opened drawer',
  selectedCampaign = 'User selected campaign',
  triggerOrderCreationDrawer = 'User triggered TikTok order creation drawer',
}

export type SegmentData = {
  filters?: Filters;
  campaign_id?: number;
  deliverable_url?: string;
  tab?: string;
  drawer?: string;
  period?: string;
};

export default function useSegmentTTCMTracking(campaign?: Campaign) {
  const { segment, user } = React.useContext(ApplicationContext);

  return React.useCallback(
    (action: AllowedAction, extraData?: SegmentData) => {
      segment
        .track(
          SegmentEvents.TTCMIntegration,
          {
            action,
            ...extraData,
          },
          {
            context: campaign
              ? { groupId: formatCampaignId(campaign?.id) }
              : null,
            userId: formatUserId(user?.id),
          },
        )
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
    [campaign, segment, user?.id],
  );
}
