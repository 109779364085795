import addTrailingZeroToString from './addTrailingZeroToString';

export default function getDateFromHtmlString(value: string) {
  try {
    const valueAsDate = new Date(value);

    if (valueAsDate.toString() === 'Invalid Date') {
      return null;
    }

    const offset = valueAsDate.getTimezoneOffset();

    const offsetSign = offset < 0 ? '+' : '-';
    return `${valueAsDate.getUTCFullYear()}-${addTrailingZeroToString(
      valueAsDate.getUTCMonth() + 1,
    )}-${addTrailingZeroToString(
      valueAsDate.getUTCDate(),
    )}T${addTrailingZeroToString(
      valueAsDate.getHours(),
    )}:00:00.000${offsetSign}${addTrailingZeroToString(
      Math.abs(offset) / 60,
    )}:00`;
  } catch (e) {
    return null;
  }
}
