import { getDataCy } from 'components/Campaign/utils';
import { Placement } from 'shared/types/Placement';
import dateColDef from 'components/DataGrid/Destination/colDef/dateColDef';
import { getPostReleaseDate } from 'shared/campaigns/creatorCampaigns/postDates';

export default function postedDate() {
  return {
    ...dateColDef,
    field: 'postedDate',
    headerName: 'Posted Date',
    valueGetter: ({ data }: { data: Placement }) => {
      if (!data?.source) {
        return null;
      }

      return getPostReleaseDate(data);
    },
    cellRendererParams: ({ data }: { data: Placement }) => ({
      dataCy: getDataCy(data, 'posted'),
      alignRight: true,
    }),
    filter: 'agDateColumnFilter',
  };
}
