import getDateElementsAsArrayFromString from './getDateElementsAsArrayFromString';
import getUTCDateAtBeginningOfDay from './getUTCDateAtBeginningOfDay';

export default function formatDate(
  date: string | null | Date | undefined,
  timeZone: null | undefined | string = 'en-US',
  ignoreTimeZone: boolean = false,
) {
  if (date === null || date === undefined) {
    return null;
  }

  try {
    if (ignoreTimeZone) {
      let dateToParse = date;
      if (typeof dateToParse !== 'string') {
        dateToParse = new Date(date).toISOString();
      }
      const dateElements = getDateElementsAsArrayFromString(dateToParse);
      if (!dateElements) {
        return null;
      }
      return Intl.DateTimeFormat(timeZone ?? 'en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(new Date(dateElements[0], dateElements[1], dateElements[2]));
    }
    const dateToFormat = getUTCDateAtBeginningOfDay(date);
    if (!dateToFormat) {
      return null;
    }
    return Intl.DateTimeFormat(timeZone ?? 'en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(dateToFormat);
  } catch (e) {
    return null;
  }
}
