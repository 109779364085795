const sanitize = (string = '') => {
  let urlToParse = string.match(/(.*)(\.[\w|d]*)$/, 'g');
  if (!urlToParse || !urlToParse.length) {
    urlToParse = [null, string, ''];
  }
  const [, filename, extension] = urlToParse;
  const spaceRegex = new RegExp(/\s/, 'g');
  const nonWordRegex = new RegExp(/[^\w]/, 'g');
  const newFileName = filename
    .replace(spaceRegex, '_')
    .replace(nonWordRegex, '');
  return `${newFileName}${extension}`;
};

export default sanitize;
