export const getFakeEvent = (name, value, selected) => ({
  stopPropagation() {
    console.info('Stop propagation function is a mocked one'); // eslint-disable-line no-console
  },
  preventDefault() {
    console.info('Prevent default function  event is a mocked one'); // eslint-disable-line no-console
  },
  target: {
    name,
    value,
    selected,
  },
});

export const hasError = (error) => {
  if (!error) {
    return false;
  }

  if (Array.isArray(error)) {
    return error.length > 0;
  }

  return typeof error !== 'object' || Object.keys(error).length > 0;
};
