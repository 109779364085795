import { DrawerTabsTexts, CreatorTabs } from './types.d';

export const drawerTabsTexts: DrawerTabsTexts = {
  socialChannels: {
    label: 'Social Channels',
    segmentValue: 'social_channels',
  },
  projectHistory: {
    label: 'Project History',
    segmentValue: 'project_history',
    underConstructionMessage:
      'We are currently running an experiment for Project History until the end of January, and we will remove this feature by then.',
    ratingQuestion: 'How interested are you in keeping this feature?',
  },
  contactHistory: {
    label: 'Contact History',
    segmentValue: 'contact_history',
  },
};

export const creatorTabs: CreatorTabs = {
  statsAndContent: {
    label: 'Stats and Content',
    segmentValue: 'stats_and_content',
  },
  demographicsAndAi: {
    label: 'Demographics and AI',
    segmentValue: 'demographics_and_ai',
  },
};
