import './SimpleCellRenderer.scss';

export default function SimpleCellRenderer({
  valueFormatted,
  dataCy,
  value,
  alignRight = false,
  hideOverflow = false,
}: {
  value?: string | number | null;
  valueFormatted?: string | number | null;
  dataCy?: string | null;
  alignRight?: boolean;
  hideOverflow?: boolean;
}) {
  return (
    <div
      className={`ag-cell-destination simple ${
        alignRight ? 'align-right' : ''
      }${hideOverflow ? 'hide-overflow' : ''}`}
    >
      <span data-cy={dataCy}>{valueFormatted ?? value}</span>
    </div>
  );
}
