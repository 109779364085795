import React from 'react';
import Select from 'react-select';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import TikTokSparkAdsRecommendation from 'components/Tiktok/TiktokSparkAds/graphql/TikTokSparkAdsRecommendation.graphql';
import objectiveTypeOptions from './constants/objectiveTypeOptions';
import RecommendedVideos from './RecommendedVideos/RecommendedVideos';

import TikTokAdvertiserNamesQuery from '../graphql/TiktokAdvertiserNames.graphql';

import './SparkAdsRecommendation.scss';

type Option = { label: string; value: string };

export default function SparkAdsRecommendation({
  videoId,
}: Readonly<{
  videoId: string | null;
}>) {
  const [
    selectedObjectiveType,
    setSelectedObjectiveType,
  ] = React.useState<Option | null>(null);

  const [
    selectedAdvertiser,
    setSelectedAdvertiser,
  ] = React.useState<Option | null>(null);

  const [recommendationError, setRecommendationError] = React.useState(null);
  const [recommendedVideos, setRecommendedVideos] = React.useState([]);

  const {
    data: tikTokAdvertiserNames,
    loading: tikTokAdvertiserNamesLoading,
  } = useQuery(TikTokAdvertiserNamesQuery);

  const [
    getRecommendationQuery,
    {
      data: tikTokSparkAdsRecommendations,
      loading: tikTokSparkAdsRecommendationsLoading,
    },
  ] = useLazyQuery(TikTokSparkAdsRecommendation);
  React.useEffect(() => {
    if (
      tikTokSparkAdsRecommendations?.tikTokSparkAdsRecommendation?.videos
        ?.length
    ) {
      const hasError = (
        tikTokSparkAdsRecommendations?.tikTokSparkAdsRecommendation?.videos ??
        []
      )
        .map((video: { item_id: string; error?: string }) => video?.error)
        .filter(Boolean);

      if (hasError?.length) {
        setRecommendationError(hasError?.[0]);
        return;
      }
      setRecommendedVideos(
        tikTokSparkAdsRecommendations.tikTokSparkAdsRecommendation.videos,
      );
    }
  }, [tikTokSparkAdsRecommendations?.tikTokSparkAdsRecommendation]);

  return (
    <>
      <section className="spark-ads-recommendation">
        <div>
          <strong>Get Spark Ads Recommendation (experimental)</strong>
          <p className="description">
            Spark Ads recommendation is an experimental feature subject to
            change. Feedback is welcome!
          </p>
        </div>

        <div className="advertiser-container">
          <span> Use BEN account or select advertiser</span>
          <Select
            className="advertiser-selector"
            placeholder="-- Select --"
            options={(tikTokAdvertiserNames?.tiktokAdvertiserNames ?? []).map(
              (a: { advertiser_id?: string; advertiser_name?: string }) => ({
                value: a?.advertiser_id,
                label: a?.advertiser_name,
              }),
            )}
            onChange={(selectedOption: Option | null) => {
              setSelectedAdvertiser(selectedOption);
            }}
          />
        </div>

        <div>
          <p>Objective Type</p>
          <Select
            className="objective-type-selector"
            placeholder="-- Select --"
            options={objectiveTypeOptions}
            onChange={(selectedOption) => {
              setSelectedObjectiveType(selectedOption);
            }}
          />
        </div>

        <BaseButton
          disabled={tikTokAdvertiserNamesLoading || !selectedObjectiveType}
          className="btn-get-recommendation"
          color="purple"
          onClick={() => {
            setRecommendedVideos([]);
            setRecommendationError(null);
            if (!selectedAdvertiser) {
              getRecommendationQuery({
                variables: {
                  objectiveType: selectedObjectiveType?.value,
                  videoIds: [videoId],
                },
              });
            } else {
              getRecommendationQuery({
                variables: {
                  advertiserId: selectedAdvertiser?.value,
                  objectiveType: selectedObjectiveType?.value,
                  videoIds: [videoId],
                },
              });
            }
          }}
          rounded="rounded-sm"
          text={
            tikTokSparkAdsRecommendationsLoading
              ? 'Loading...'
              : 'Get Recommendation'
          }
          variant="outlined"
        />
        {recommendationError ? (
          <div>
            <strong>{recommendationError}</strong>
          </div>
        ) : null}
      </section>

      <RecommendedVideos
        videos={recommendedVideos}
        loading={tikTokAdvertiserNamesLoading}
      />
    </>
  );
}
