export const EXPORT_TYPES = {
  users: {
    'xlsx-list': {
      type: 'xlsx',
    },
  },
  brands: {
    'xlsx-list': {
      type: 'xlsx',
    },
  },
  clients: {
    'xlsx-list': {
      type: 'xlsx',
    },
  },
  'media-plans': {
    productions: {
      name: 'Productions',
      description: 'Get media-plan opportunities',
      type: 'csv',
    },
    'xlsx-list-content': {
      name: 'List',
      description:
        'Data from content formatted in a excel based list without subtotals',
      type: 'xlsx',
    },
    summary: {
      name: 'Summary',
      description: 'Get Placements summary list',
      type: 'csv',
    },
    airings: {
      name: 'Airings',
      description: 'Get Placements airings list',
      type: 'csv',
    },
    geographics: {
      name: 'Geographics (YT)',
      description: 'Get Placements geographics list',
      type: 'csv',
    },
    demographics: {
      name: 'Demographics (YT)',
      description: 'Get Placements demographics list',
      type: 'csv',
    },
    'traffic-sources': {
      name: 'Traffic Sources (YT)',
      description: 'Get Placements traffic sources list',
      type: 'csv',
    },
    'custom-report-yt': {
      name: 'Custom Report (YT)',
      description: 'Get Placements YT custom-report',
      type: 'csv',
    },
    deliverables: {
      name: 'Deliverables',
      description: 'Get Deliverables list',
      type: 'csv',
    },
    distributions: {
      name: 'Distributions',
      description: 'Get Opportunities distributions list',
      type: 'csv',
    },
    'xlsx-list': {
      name: 'List',
      description: 'Data formatted in a excel based list without subtotals',
      type: 'xlsx',
    },
    'xlsx-monthly': {
      name: 'Monthly',
      description:
        'Data formatted in a excel based list grouped by month with totals',
      type: 'xlsx',
    },
    'xlsx-by-production': {
      name: 'By Production',
      description:
        'Data formatted in a excel based list grouped by Production with totals',
      type: 'xlsx',
    },
    'xlsx-by-channel': {
      name: 'By Channel',
      description:
        'Data formatted in a excel based list grouped by Channel with totals',
      type: 'xlsx',
    },
    'xlsx-by-client-by-brand': {
      name: 'By Client/Brand',
      description:
        'Data formatted in a excel based list grouped by Client/Brand with totals',
      type: 'xlsx',
    },
    'xlsx-deliverables': {
      name: 'Deliverables',
      description: 'Get Deliverables list',
      type: 'xlsx',
    },
  },
  deliverables: {
    'search-csv-list': {
      name: 'List',
      type: 'csv',
    },
    'search-xlsx-list': {
      name: 'List',
      type: 'xlsx',
    },
  },
  placements: {
    'search-pdf': {
      name: 'PDF',
      type: 'pdf',
    },
    'search-csv-summary': {
      name: 'Summary',
      type: 'csv',
    },
    'search-csv-metrics': {
      name: 'Airings',
      type: 'csv',
    },
    'search-csv-demographics': {
      name: 'Demographics',
      type: 'csv',
    },
    'search-csv-geographics': {
      name: 'Geographics',
      type: 'csv',
    },
    'search-csv-traffic-sources': {
      name: 'Traffic Sources',
      type: 'csv',
    },
    'search-xlsx-list': {
      name: 'List',
      type: 'xlsx',
    },
    'search-xlsx-monthly': {
      name: 'Monthly',
      type: 'xlsx',
    },
    'search-xlsx-by-production': {
      name: 'By Production',
      type: 'xlsx',
    },
    'search-xlsx-by-channel': {
      name: 'By Channel',
      type: 'xlsx',
    },
    'search-xlsx-by-client-by-brand': {
      name: 'By Client/Brand',
      type: 'xlsx',
    },
    'search-csv-list-content': {
      name: 'Productions',
      type: 'csv',
    },
    'search-xlsx-list-content': {
      name: 'Productions',
      type: 'xlsx',
    },
    'search-pptx': {
      name: 'PPTX',
      type: 'pptx',
    },
  },
  opportunities: {
    'search-csv-list': {
      name: 'List',
      type: 'csv',
    },
    'search-xlsx-list': {
      name: 'List',
      type: 'xlsx',
    },
    'search-csv-distributions': {
      name: 'Distributions',
      type: 'csv',
    },
    'search-xlsx-distributions': {
      name: 'Distributions',
      type: 'xlsx',
    },
  },
};
