import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';

import './ListLeftRight.scss';

type ListLeftRightHeaders = {
  leftHeader: string;
  rightHeader: string;
};

export type ListLeftRightRow = {
  leftRow: string;
  rightRow: string;
  callback?: () => void;
  icon?: string;
};

type ListLeftRightProps = {
  headers: ListLeftRightHeaders;
  rows: Array<ListLeftRightRow>;
};

const ListLeftRight: React.FC<ListLeftRightProps> = ({ headers = {}, rows = [] }) => {
  const leftRows = rows.map(({ leftRow }, index) => (
    <div key={index} className="table-row table-cell">
      {leftRow}
    </div>
  ));

  const rightRows = rows.map(({ rightRow, callback, icon }, index) => (
    <div key={index} className="table-row table-cell" onClick={callback} title={rightRow}>
      {icon ? <BaseIcon icon={icon} size="small" /> : null}
      <span className="text">{rightRow}</span>
    </div>
  ));

  return (
    <div className="ListLeftRight" data-cy="ListLeftRight">
      <div className="table-wrap">
        <div className="table-left">
          <div className="table-header table-cell">{headers.leftHeader}</div>
          {leftRows}
        </div>
        <div className="table-right">
          <div className="table-header table-cell">{headers.rightHeader}</div>
          {rightRows}
        </div>
      </div>
    </div>
  );
};

export default ListLeftRight;
