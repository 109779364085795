import React from 'react';
import {
  TCMReportMetrics,
  TCMReportMetricsViewsSource,
} from 'shared/types/TikTokMarketPlace';

import {
  getViewershipRows,
  getEngagementRows,
  getTrafficSourcesRows,
} from './utils/getRows';
import SimpleMetricsTable from '../../../../../SimpleMetricsTable/SimpleMetricsTable';

import './BasicStats.scss';

export default function BasicStats({
  metrics,
}: {
  metrics?: TCMReportMetrics;
}) {
  const hasTrafficSources = React.useMemo(() => {
    const trafficSources = metrics?.video_views_by_source;

    if (!trafficSources || !Object.keys(trafficSources ?? {})?.length) {
      return false;
    }

    const trafficSourcesKeys = Object.keys(trafficSources) as Array<
      keyof TCMReportMetricsViewsSource
    >;

    return trafficSourcesKeys.some((key) => (trafficSources[key] ?? 0) > 0);
  }, [metrics?.video_views_by_source]);

  return (
    <section className="tiktok-video-content__metrics__basicStats">
      <h2 className="header-title">Stats</h2>

      <SimpleMetricsTable
        rows={getViewershipRows(metrics)}
        title="Viewership"
      />

      <SimpleMetricsTable
        rows={getEngagementRows(metrics)}
        title="Engagement"
      />

      {hasTrafficSources ? (
        <SimpleMetricsTable
          title="Traffic Sources"
          rows={getTrafficSourcesRows(metrics?.video_views_by_source ?? {})}
        />
      ) : null}
    </section>
  );
}
