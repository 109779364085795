import React from 'react';
import ReactModal from 'react-modal';

import './BaseModal.scss';

type BaseModalProps = {
  isOpen: boolean;
  onRequestClose: (arg0?: unknown) => void;
  shouldCloseOnOverlayClick?: boolean;
  title?: string;
  style?: Record<string, unknown>;
  className?: string;
  children:
    | React.ReactNode
    | React.ReactNodeArray
    | null
    | React.ReactChildren
    | React.ReactChild;
};

ReactModal.setAppElement('body');

function BaseModal({
  children,
  isOpen = false,
  onRequestClose,
  shouldCloseOnOverlayClick = true,
  title,
  style,
  className,
}: BaseModalProps) {
  return (
    <ReactModal
      className={`BaseModal ${className ?? ''}`}
      overlayClassName="BaseModal--overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={style ?? {}}
    >
      {title ? <div className="modal-title">{title}</div> : null}

      {/* @ts-ignore */}
      <div className="modal-content">{children}</div>
    </ReactModal>
  );
}

export default BaseModal;
