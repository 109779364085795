import { VIDEO_MEDIA_TYPE, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import basePerformanceColDef from '../../helpers/baseColDef';
import getYouTubeDistributionDataFromCampaignService from '../helpers/getYouTubeDistributionDataFromCampaignService';

const metric = 'subscriberViews';
const youTubeMetric = 'viewers_by_subscriber_status';
const youTubeMetricKey = 'SUBSCRIBED';

export function subscriberViewsAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDistributionDataFromCampaignService(data, [
      youTubeMetric,
      youTubeMetricKey,
    ]);
  }
  return null;
}

export default function subscriberViews() {
  return {
    ...basePerformanceColDef(
      metric,
      subscriberViewsAutomatedValueGetter,
      [YOUTUBE_PLATFORM],
      [VIDEO_MEDIA_TYPE],
    ),
    headerName: 'Subscriber Views',
    width: 235,
  };
}
