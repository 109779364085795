import percent from 'components/DataGrid/formatters/percent';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { Placement } from 'shared/types/Placement';
import { YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import basePerformanceColDef from './baseColDef';
import getYouTubeDistributionDataFromCampaignService from '../youTube/helpers/getYouTubeDistributionDataFromCampaignService';

const youTubeMetric = 'viewers_by_age_group';
const youTubeMetricKey = 'Age45-54';
const tikTokMetricKeyType = '45-54';
const metric = `age-${tikTokMetricKeyType}`;

export function age4554AutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDistributionDataFromCampaignService(
      data,
      [youTubeMetric, youTubeMetricKey],
      { parsePercentageFromString: true },
    );
  }
  return null;
}

export default function age4554() {
  return {
    ...percentColDef,
    ...basePerformanceColDef(metric, age4554AutomatedValueGetter, [
      YOUTUBE_PLATFORM,
    ]),
    headerName: 'Age 45-54',
    width: 185,
    valueFormatter: ({ value }: { value?: number | null }) =>
      percent({ value }),
  };
}
