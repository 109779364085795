import { Platforms } from 'shared/types/platforms';
import PlatformValueCombo from '../PlatformValueCombo/PlatformValueCombo';
import './MultiPlatformCombo.scss';
import { SvgSize } from '../_assets/react_svg/utils/svgSizeMapping';

type ComboType = {
  platform: keyof typeof Platforms;
  value?: string;
  url?: string;
};

export default function MultiPlatformCombo({
  platforms,
  iconSize = 'medium',
}: {
  platforms: ComboType[];
  iconSize?: SvgSize;
}) {
  return (
    <section className="multi-platform-combo">
      {platforms.map(({ platform, value, url }) => (
        <PlatformValueCombo
          key={`${platform}-${url}`}
          platform={platform}
          value={value}
          url={url}
          iconSize={iconSize}
        />
      ))}
    </section>
  );
}
