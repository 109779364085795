/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useContext, useMemo } from 'react';
import { number } from 'prop-types';
import useListLoader from 'external/utils/useListLoader';
import sortFunction from 'external/utils/sortFunction';
import UILoader from 'components/UI/Loader/Loader';
import { useApolloClient } from '@apollo/react-hooks';

import { ExternalApplicationContext } from 'external/components/Application/Context';

import PlacementRow from 'external/components/Placement/Row/Row';
import { PLACEMENT_METRICS_FOR_DISPLAY } from 'components/Placement/View/Hidrator';
import StatusFilter from 'external/components/UI/StatusFilter/StatusFilter';
import getPropertyImageForDeliverable from 'external/utils/getPropertyImageForDeliverable';
import useTabletOrMobile from 'external/utils/useTabletOrMobile';
import { getDeliverableExternalApprovalStatus } from 'external/components/Placement/utils/utils';

import useFacets from 'external/utils/useFacets';
import NoneEnabled from 'external/components/Campaign/Placeholder/NoneEnabled/NoneEnabled';
import NoResultsEmptyState from 'external/components/shared/NoResultsEmptyState';
import NoDetailsEmptyState from 'external/components/shared/NoDetailsEmptyState/NoDetailsEmptyState';
import ListHeader from 'external/components/UI/ListHeader/ListHeader';
import { Placement } from 'components/Placement/types';

import BaseSection from '@UIComponents/BaseSection/BaseSection';
import { CreatedMessagePayload } from 'components/Chat/types';
import useUser from 'store/authentication/useUser';
import { trackPostChatClicked } from 'external/components/Segment/Segment';

import { getInitialsMapByPlacement } from 'external/components/UI/ColoredInitials/ColoredInitials';
import { ExternalPlacementListQuery } from './graphql';
import DetailsPanel from '../DetailsPanel/DetailsPanel';

import './List.scss';

const defaultValues = {
  context: 'campaign',
  skip: 0,
  limit: 24,
  sort: 'executionStatusRelevance-desc',
};

const listHeaderItems = [
  { title: '', value: 'thumb', gridArea: 'thumb' },
  { title: 'Name', value: 'propertyName', gridArea: 'name' },
  { title: 'Status', value: 'status', gridArea: 'status' },
  { title: 'Notes', value: 'chat', gridArea: 'chat' },
  { title: 'Last Updated', value: 'displayedToClientAt', gridArea: 'date' },
];

type DeliverableListProps = {
  campaignId: number;
};

type ListLoaderResponseProps = {
  externalPlacements: {
    data: Placement[];
    meta: { total: number };
  };
};

function sortData(
  data: Placement[],
  sorting: string | null,
  activeFilter: string | null,
) {
  if (!data) {
    return [];
  }

  const sortFn = sortFunction(
    {
      propertyName: 'integration.deliverable.property.name',
      displayedToClientAt: 'displayedToClientAt',
      status: 'status',
      chat: 'messagesCount.external',
    },
    sorting,
  );

  const normalizedData = data.map((placement) => {
    const { externalApproval } = placement;
    // @ts-ignore
    const { state } = getDeliverableExternalApprovalStatus(externalApproval);

    return { ...placement, status: state };
  });

  return normalizedData
    .filter(({ integration }) => integration)
    .filter(({ externalApproval }) => {
      if (!activeFilter) {
        return true;
      }

      if (activeFilter === 'forReview') {
        return externalApproval === null;
      }
      // @ts-ignore
      return externalApproval === activeFilter;
    })
    .sort(sortFn);
}

function setFilter(
  setActiveFilter: (arg0: string | null) => void,
  activeFilter: string | null,
  filter: string | null,
) {
  if (activeFilter === filter) {
    return setActiveFilter(null);
  }
  return setActiveFilter(filter);
}

// eslint-disable-next-line max-statements
function ExternalPlacementList({ campaignId }: DeliverableListProps) {
  const apolloClient = useApolloClient();
  const { campaign, segment } = useContext(ExternalApplicationContext);
  const { user } = useUser();
  const [deliverableId, setDeliverableId] = useState<number | null | undefined>(
    null,
  );
  const [placementId, setPlacementId] = useState<number | null | undefined>(
    null,
  );

  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [sorting, setSorting] = useState<string | null>(null);
  const [sortBy, sortOrder] = sorting?.split('-') ?? [];
  const isTabletOrMobile = useTabletOrMobile();
  const enableEdit = campaign?.clientAccessTabs?.contentReview;

  const placementVariables = {
    limit: defaultValues.limit,
    sort: defaultValues.sort,
    columns: ['_all_'],
    summaryMetricConfig: PLACEMENT_METRICS_FOR_DISPLAY,
    filter: {
      campaignId,
    },
    reportingWindow: campaign?.subType?.includes('Performance')
      ? 'monthEnd'
      : campaign?.reportingWindow,
  };

  const { data, meta } = useListLoader({
    skip: !campaign,
    entityName: 'externalPlacements',
    query: ExternalPlacementListQuery,
    variables: placementVariables,
    onCompleted: (response: ListLoaderResponseProps) => {
      const responseData = response?.externalPlacements?.data;
      if (
        !isTabletOrMobile &&
        responseData.length &&
        responseData.length <= defaultValues.limit &&
        deliverableId === null
      ) {
        const placement = responseData.find(({ integration }) => integration);
        setDeliverableId(placement?.integration?.deliverable?.id);
        setPlacementId(placement?.id);
      }
    },
  });

  const facets = useFacets(data);

  const handlePlacementClick = (placement: Placement) => {
    const { id } = placement;

    setPlacementId(id);
  };

  const onCreatePlacementMessage = (placement: Placement) => (
    payload: CreatedMessagePayload,
  ) => {
    const queryOptions = {
      query: ExternalPlacementListQuery,
      variables: placementVariables,
    };

    const response = apolloClient.readQuery(queryOptions);

    const increatePlacementsMessagesCount = (p: Placement) => ({
      ...p,
      messagesCount: {
        ...p.messagesCount,
        external: (p.messagesCount?.external ?? 0) + 1,
      },
    });

    const newData = response.externalPlacements.data.map((p: Placement) => {
      if (p.id !== placement.id) {
        return p;
      }

      return increatePlacementsMessagesCount(p);
    });

    apolloClient.writeQuery({
      ...queryOptions,
      data: {
        ...response,
        placements: {
          ...response.placements,
          data: newData,
        },
      },
    });
    if (placement && segment && user && campaign) {
      trackPostChatClicked({
        segment,
        campaign,
        user,
        placement,
        message: payload.message,
      });
    }
  };

  const loadedData = data && data?.length === meta.total;

  const sortedData = useMemo(() => sortData(data, sorting, activeFilter), [
    activeFilter,
    sorting,
    data,
  ]);

  const selectedPlacement = data?.find(
    (placement: Placement) => placement.id === placementId,
  );

  const initialsMap = getInitialsMapByPlacement(sortedData);

  if (!loadedData) return <UILoader className="external-content__loader" />;

  if (!data?.length) {
    return (
      /* @ts-ignore */
      <BaseSection title="Content review" type="main">
        <NoneEnabled />
      </BaseSection>
    );
  }

  return (
    /* @ts-ignore */
    <BaseSection title="Content review" type="main" wide>
      <div className="creators-grid PlacementList">
        <div className="grid-box creators-list external-content__list">
          <h3 className="grid-box-list-title">Content list</h3>

          <div className="external-content__list-actions">
            <div className="external-content__list-actions__filters">
              <StatusFilter
                tab="Posts"
                facets={facets}
                onFilterChangeCallback={(filter) => {
                  setFilter(setActiveFilter, activeFilter, filter);
                }}
              />
            </div>
          </div>
          {sortedData?.length ? (
            <>
              <ListHeader
                setSorting={setSorting}
                sorting={sorting}
                sortBy={sortBy}
                sortOrder={sortOrder}
                items={listHeaderItems}
                gridTemplate="placement"
              />
              <div className="campaign-placements__list">
                {sortedData?.map((placement) => (
                  <PlacementRow
                    key={placement.id}
                    updatedAt={placement.updatedAt}
                    // @ts-ignore
                    externalApproval={placement.externalApproval}
                    externalMessagesCount={placement.messagesCount?.external}
                    id={placement.id}
                    integrationId={placement.integration?.id}
                    isSelected={placementId === placement.id}
                    onClick={() => handlePlacementClick(placement)}
                    onCreateMessage={onCreatePlacementMessage(placement)}
                    thumb={getPropertyImageForDeliverable(
                      /* @ts-ignore */
                      placement.integration?.deliverable,
                    )}
                    name={
                      (
                        placement.integration?.deliverable ??
                        placement.source?.production
                      )?.property?.name
                    }
                    placement={placement}
                    initials={initialsMap.get(placement.id || 0)}
                  />
                ))}
              </div>
            </>
          ) : (
            <NoResultsEmptyState />
          )}
        </div>
        <div className="grid-box creators-card">
          {selectedPlacement ? (
            <DetailsPanel
              key={selectedPlacement.id}
              enableEdit={enableEdit}
              campaignReportingWindow={campaign?.reportingWindow}
              campaignSubType={campaign?.subType}
              source={selectedPlacement.source}
              platform={selectedPlacement.platform}
              deliverable={selectedPlacement.integration?.deliverable}
              onClickClosePanel={() => setPlacementId(null)}
              selectedPlacementId={placementId}
              selectPlacement={({ id }) => {
                if (id !== placementId) {
                  setPlacementId(id);
                }
              }}
              initials={initialsMap.get(selectedPlacement.id || 0)}
            />
          ) : (
            <NoDetailsEmptyState />
          )}
        </div>
      </div>
    </BaseSection>
  );
}

ExternalPlacementList.propTypes = {
  campaignId: number.isRequired,
};

export default ExternalPlacementList;
