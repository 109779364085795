/*
 * Load additional data.
 * */
import React from 'react';
import merge from 'lodash/merge';
import { DocumentNode } from 'graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import { Placement } from 'shared/types/Placement';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import { Filter } from '../../../hooks/types';

const ITERATOR_LIMIT = 50;

function mergeAdditionalData(gridApi: GridApi, placementsUpdated: Placement[]) {
  const itemsToUpdate: Placement[] = [];
  gridApi.forEachNode((rowNode: { data: Placement }) => {
    const updatedRow: Placement | undefined = placementsUpdated.find(
      (d) => d?.id === rowNode?.data?.id,
    );

    if (updatedRow) {
      const d = rowNode?.data;

      merge(d, updatedRow);
      itemsToUpdate.push(d);
    }
  });

  return itemsToUpdate;
}

export default function useLoadAdditionalData(
  gridApi: GridReadyEvent | null,
  loading: boolean,
  setLoading: (arg0: boolean) => void,
  additionalDataQuery: DocumentNode,
  additionalDataQueryVariables?: Filter,
) {
  const [idsToQuery, setIdsToQuery] = React.useState<Set<number>>(new Set());

  const [getAdditionalDataQuery] = useLazyQuery(additionalDataQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (!gridApi) {
        return;
      }

      const updatedPlacements = response?.placements?.data ?? [];
      const itemsToUpdate = mergeAdditionalData(gridApi.api, updatedPlacements);
      gridApi.api.applyTransaction({ update: itemsToUpdate });

      const newIdsToQuery = new Set([...idsToQuery]);

      newIdsToQuery.forEach((value, key) => {
        const placement = updatedPlacements.find(
          (p: Placement) => p?.id === key,
        );

        if (
          placement?.source?.postMetrics?.postAutomatedMetrics?.[0]
            ?.errorDetail === 'Continue wait'
        ) {
          return;
        }

        newIdsToQuery.delete(key);
      });

      setIdsToQuery(newIdsToQuery);
    },
  });

  React.useEffect(() => {
    if (!additionalDataQuery || !additionalDataQueryVariables) {
      return;
    }

    /**
     * There's a limit for the deliverables we can query, so we need to batch the requests.
     */
    const currentIdsToQuery = [...(idsToQuery ?? new Set())];
    const arraySplice = currentIdsToQuery.splice(0, ITERATOR_LIMIT);

    if (!arraySplice?.length) {
      setLoading(false);
      return;
    }

    if (arraySplice?.length) {
      setLoading(true);

      const mergedVariables = {
        variables: {
          ...(additionalDataQueryVariables?.variables ?? {}),
          f: {
            ...(additionalDataQueryVariables?.variables?.f ?? {}),
            id: arraySplice,
          },
          limit: ITERATOR_LIMIT,
        },
        skip: !additionalDataQueryVariables?.variables?.f?.id?.length,
      };

      getAdditionalDataQuery(mergedVariables);
    }
  }, [
    idsToQuery,
    getAdditionalDataQuery,
    additionalDataQueryVariables,
    setLoading,
    additionalDataQuery,
  ]);

  return {
    additionalDataIdsToQuery: idsToQuery,
    setAdditionalDataIdsToQuery: setIdsToQuery,
  };
}
