import { HeaderClass } from 'ag-grid-community/dist/lib/main';
import { CreatorColDef } from 'shared/types/Campaign';

function addClass(classToAdd: string, colClass?: HeaderClass) {
  if (!colClass) {
    return classToAdd;
  }

  if (Array.isArray(colClass) && !colClass.includes(classToAdd)) {
    return [...colClass, classToAdd];
  }

  if (typeof colClass === 'string' && !colClass.includes(classToAdd)) {
    return `${colClass} ${classToAdd}`;
  }

  return colClass;
}

export default function processColumnFields(
  columnValues: CreatorColDef,
  isForExternalUse: boolean,
  parentColDef?: CreatorColDef,
) {
  const newColDef = {
    ...columnValues,
  };

  if (isForExternalUse && newColDef?.clientAccess !== 'edit') {
    newColDef.editable = false;
  }

  if (newColDef?.columnName) {
    newColDef.headerName = newColDef.columnName;
  }

  if (newColDef?.pin) {
    newColDef.pinned = newColDef.pin;
  }

  const newColor = parentColDef?.color?.length
    ? parentColDef.color
    : newColDef?.color;

  if (!newColor) {
    return newColDef;
  }

  newColDef.cellClassRules = {
    [`cell-background-${newColor}`]: () => true,
  };

  newColDef.headerClass = addClass(
    `header-background-${newColor}`,
    newColDef?.headerClass,
  );

  return newColDef;
}
