type GenericMetric = {
  [key: string]: string | number;
  percentage: number;
};

export default function sortMetricsByProgression(metrics: GenericMetric[]) {
  return structuredClone(metrics).sort(
    (a: GenericMetric, b: GenericMetric) => b.percentage - a.percentage,
  );
}
