import SimpleCellRenderer from 'components/DataGrid/Cells/SimpleCellRenderer';
import { getDataCy } from 'components/Campaign/utils';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import { Placement } from 'shared/types/Placement';

export default function verticals() {
  return {
    ...baseColDef,
    field: 'verticals',
    headerName: 'Verticals',
    valueGetter: ({ data }: { data: Placement }) => {
      const { verticals: deliverableVerticals } =
        data?.integration?.deliverable ?? {};

      return (data?.verticals ?? deliverableVerticals ?? [])
        .map((vertical) => vertical?.taxonomy?.name)
        .filter(Boolean)
        .join(', ');
    },
    cellRenderer: SimpleCellRenderer.componentName,
    cellRendererParams: ({ data }: { data: Placement }) => ({
      dataCy: getDataCy(data, 'verticals'),
    }),
    filter: 'agTextColumnFilter',
  };
}
