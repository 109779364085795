import ReactModal from 'react-modal';
import classnames from 'classnames';
import UIIconButton from 'components/UI/IconButton/IconButton';

import './Modal.scss';

type UIModalSize =
  | 'medium'
  | 'normal'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | 'xxx-large'
  | 'full-screen';

export default function UIModal({
  cbn = 'ui-modal',
  children = null,
  className = null,
  closeDisabled = false,
  onRequestClose = () => {},
  overlayClassName = '',
  size = 'medium',
  title = null,
  icon = null,
  shouldCloseOnOverlayClick = true,
  ...restProps
}: {
  cbn?: string;
  className?: string | null;
  closeDisabled?: boolean;
  icon?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  overlayClassName?: string;
  shouldCloseOnOverlayClick?: boolean;
  size?: UIModalSize;
  title?: string;
  children?: React.ReactNode;
}) {
  return (
    <ReactModal
      overlayClassName={classnames(`${cbn}__overlay`, overlayClassName)}
      className={classnames(cbn, className, {
        [`${cbn}--${size}`]: size,
      })}
      contentLabel={title}
      onRequestClose={closeDisabled ? undefined : onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      {...restProps}
    >
      <span className={`${cbn}__close`}>
        <UIIconButton icon="close" onClick={onRequestClose} />
      </span>
      {title && (
        <div className={`${cbn}__header`}>
          <span className={icon} />
          <span className={`${cbn}__title`}>{title}</span>
        </div>
      )}
      <div className={`${cbn}__content`}>{children}</div>
    </ReactModal>
  );
}
