import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import storage from 'utils/storage';

export default function useStorage<ValueT = any>(
  key: string,
  initialState: ValueT | null = null,
): [ValueT | null, Dispatch<SetStateAction<ValueT | null>>] {
  const [state, setState] = useState<ValueT | null>(
    () => storage.get(key) ?? initialState,
  );

  useEffect(() => {
    storage.set(key, state);
  }, [state, key]);

  return [state, setState];
}
