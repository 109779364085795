import React from 'react';
import { bool, string } from 'prop-types';
import classnames from 'classnames';
import UIIcon from 'components/UI/Icon/Icon';

import './ErrorMessage.scss';

const cbn = 'ui-error-message';

function UIErrorMessage({ children, showIcon, highlight, className }) {
  return (
    <span
      className={classnames(cbn, className, {
        [`${cbn}--highlight`]: highlight,
      })}
    >
      {showIcon && <UIIcon type="warning" className={`${cbn}__icon`} />}
      {children}
    </span>
  );
}

UIErrorMessage.propTypes = {
  children: string.isRequired,
  className: string,
  highlight: bool,
  showIcon: bool,
};

UIErrorMessage.defaultProps = {
  className: null,
  highlight: false,
  showIcon: false,
};

export default UIErrorMessage;
