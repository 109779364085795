import { Field, FieldProps, FormikProps } from 'formik';
import { Property } from 'components/Property/types';

export default function EmailInput() {
  return (
    <Field name="primaryContact.email">
      {({
        field,
        form,
      }: {
        field: FieldProps;
        form: FormikProps<Property>;
      }) => (
        <div>
          <Field
            {...field}
            type="email"
            placeholder="Primary Contact Email"
            className="form-input-text"
          />

          {form.errors?.primaryContact && (
            /* @ts-ignore */
            <div className="error">{form.errors.primaryContact}</div>
          )}
        </div>
      )}
    </Field>
  );
}
