import React from 'react';
import { bool, number, string, oneOf, node, shape } from 'prop-types';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import './Icon.scss';

function UIIcon({
  className,
  icon: parentIcon,
  platformOriginalColors,
  size,
  tooltip,
  tooltipDelay,
  tooltipInteractive,
  tooltipPopperOptions,
  tooltipPosition,
  tooltipTheme,
  type,
}) {
  const icon = parentIcon ?? type;
  const classNames = classnames('ui-icon', `icon-${icon}`, className, {
    [`ui-icon--${size}`]: size,
    'platform-original-colors': platformOriginalColors,
  });

  if (!tooltip) {
    return <span className={classNames} />;
  }

  return (
    <Tippy
      content={tooltip}
      placement={tooltipPosition}
      popperOptions={tooltipPopperOptions}
      theme={tooltipTheme}
      interactive={tooltipInteractive}
      arrow
      delay={tooltipDelay}
      animation="shift-toward"
      appendTo={document.body}
    >
      <span className={classNames} />
    </Tippy>
  );
}

UIIcon.propTypes = {
  className: string,
  icon: string,
  platformOriginalColors: bool,
  size: string,
  tooltip: node,
  tooltipDelay: number,
  tooltipInteractive: bool,
  tooltipPopperOptions: shape({}),
  tooltipPosition: oneOf(['top', 'bottom', 'left', 'right']),
  tooltipTheme: oneOf(['dark', 'light']),
  type: string,
};

UIIcon.defaultProps = {
  className: null,
  icon: null,
  platformOriginalColors: false,
  size: null,
  tooltip: null,
  tooltipDelay: undefined,
  tooltipInteractive: false,
  tooltipPopperOptions: undefined,
  tooltipPosition: 'top',
  tooltipTheme: 'dark',
};

export default UIIcon;
