import React from 'react';
import { shape } from 'prop-types';
import './ClearIndicator.scss';

function FormSelectorClearIndicator({ innerProps }) {
  return (
    <span {...innerProps} className="form-selector-clear-indicator">
      ×
    </span>
  );
}

FormSelectorClearIndicator.propTypes = {
  innerProps: shape({}).isRequired,
};

export default FormSelectorClearIndicator;
