import { Deliverable } from 'components/Deliverable/types';

// This ids are static values from api
const AI_SIMILARITY_IG_USER_ID = 2484;
const AI_SIMILARITY_YT_USER_ID = 2485;

const similarityYT: Deliverable['createdBy'] = {
  id: AI_SIMILARITY_YT_USER_ID,
  firstName: 'AI Sim',
  lastName: 'YT',
  title: 'BOT',
};

const similarityIG: Deliverable['createdBy'] = {
  id: AI_SIMILARITY_IG_USER_ID,
  firstName: 'AI Sim',
  lastName: 'IG',
  title: 'BOT',
};

const isSimilarityRecommendation = (
  recommendedBy: Deliverable['createdBy'],
): boolean => {
  const fullName = `${recommendedBy.firstName} ${recommendedBy.lastName}`;
  const fullNameYT = `${similarityYT.firstName} ${similarityYT.lastName}`;
  const fullNameIG = `${similarityIG.firstName} ${similarityIG.lastName}`;

  const isSimilarityNames = [fullNameYT, fullNameIG].includes(fullName);
  const isSimilarityIds = [similarityYT.id, similarityIG.id].includes(
    recommendedBy.id,
  );
  const isSimilarityBOT = recommendedBy.title === 'BOT';

  return isSimilarityNames && isSimilarityIds && isSimilarityBOT;
};

export default isSimilarityRecommendation;
