import React from 'react';

import './EmptyState.scss';

const cbn = 'external-ui-empty-state';

type ExternalUIEmptyStateProps = {
  title?: string;
  message: string;
  info?: string;
  image?: string;
  position?: 'vertical' | 'horizontal';
};

function ExternalUIEmptyState({
  title,
  message,
  info,
  image,
  position = 'vertical',
}: ExternalUIEmptyStateProps) {
  return (
    <div className={`EmptyState ${cbn}__wrapper ${cbn}__${position}`}>
      <div className={`${cbn}__message`}>
        <h1>{title}</h1>
        <h3>{message}</h3>
        <p>{info}</p>
      </div>
      <div>
        <img src={image} alt="empty-state" />
      </div>
    </div>
  );
}

export default ExternalUIEmptyState;
