/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import React from 'react';
import { string, func, node, bool, oneOfType, oneOf } from 'prop-types';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import UIIcon from 'components/UI/Icon/Icon';
import { clearClassName } from 'utils/string';

import './Checkbok.scss';

const cbn = 'form-checkbox';

type FormCheckboxProps = WithChildren<{
  checked?: boolean;
  className: string;
  disabled?: boolean;
  label?: string;
  name?: string;
  onChange: (isChecked: boolean) => void;
  readOnly: boolean;
  size?: 'small';
  tooltip?: JSX.Element | string;
  value?: boolean | null;
}>;

function FormCheckbox({
  checked,
  children,
  className,
  disabled,
  label,
  name,
  onChange,
  readOnly,
  size,
  tooltip,
  value,
}: FormCheckboxProps) {
  const isChecked = Boolean(value || checked);
  return (
    <label
      className={classNames(clearClassName(`${cbn}--${name}`), cbn, className, {
        'is-checked': isChecked,
        'is-disabled': disabled,
        'is-read-only': readOnly,
        [`form-checkbox--${size}`]: size,
      })}
    >
      {!readOnly && (
        <input
          type="checkbox"
          className={`${cbn}__input`}
          onChange={() => onChange(!isChecked)}
          disabled={disabled}
          checked={isChecked}
          name={name}
        />
      )}
      <span className="form-checkbox__icon">
        <UIIcon
          type={isChecked ? 'check_box' : 'check_box_outline_blank'}
          size={size}
        />
      </span>
      <span className="form-checkbox__label">
        {tooltip && label ? (
          <Tippy
            content={tooltip}
            placement="top"
            theme="dark"
            arrow
            animation="shift-toward"
          >
            <span>{label}</span>
          </Tippy>
        ) : null}
        {!tooltip && label ? label : null}
        {!tooltip && !label ? children ?? null : null}
      </span>
    </label>
  );
}

FormCheckbox.propTypes = {
  checked: bool,
  children: node,
  label: node,
  className: string,
  disabled: bool,
  name: string,
  onChange: func,
  onBlur: func,
  readOnly: bool,
  size: oneOf(['', 'small']),
  value: oneOfType([bool, string]),
};

FormCheckbox.defaultProps = {
  checked: false,
  children: '',
  label: '',
  className: null,
  disabled: false,
  name: null,
  onChange: () => {},
  onBlur: () => {},
  readOnly: false,
  size: '',
  value: false,
};

export default FormCheckbox;
