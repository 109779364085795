export default function getRelativeTimeToDate(
  date1: string,
  date2 = new Date().toString(),
  style = 'short',
) {
  if (!date1) {
    return '';
  }

  if (Number.isNaN(Date.parse(date1)) || Number.isNaN(Date.parse(date2))) {
    return '';
  }

  const dateFrom = new Date(date1).toUTCString();
  const dateTo = new Date(date2).toUTCString();

  const difference =
    (new Date(dateTo).getTime() - new Date(dateFrom).getTime()) / 1000;

  // @ts-ignore
  const rtf1 = new Intl.RelativeTimeFormat('en', { style });

  let output = '';
  if (difference < 86400) {
    // Less than a day has passed:
    output = 'today';
  } else if (difference < 2620800) {
    // Less than a month has passed:
    const days = Math.floor(difference / 86400) * -1;

    output = rtf1.format(days, 'day');
  } else if (difference < 31449600) {
    // Less than a year has passed:
    const months = Math.floor(difference / 2620800) * -1;
    output = rtf1.format(months, 'month');
  } else {
    // More than a year has passed:
    const years = Math.floor(difference / 31449600) * -1;
    output = rtf1.format(years, 'year');
  }
  return output;
}
