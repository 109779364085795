export function checkIfApplicationWasRefreshed(error) {
  let isRefreshing = false;
  /**
   *  API is refreshing;
   */
  if (
    /Unexpected token < in JSON at position 0/.test(error.message ?? '') ||
    error.message ===
      'JSON.parse: unexpected character at line 1 column 1 of the JSON data'
  ) {
    isRefreshing = true;
  }

  /*
   * Page is being refreshed.
   */
  if (error.message === 'NetworkError when attempting to fetch resource.') {
    isRefreshing = true;
  }

  /*
   * There was a WEB deploy.
   */
  if (
    /Loading(\s|\sCSS\s)chunk\s\d*\sfailed/.test(error.message ?? '') ||
    error.message === 'Failed to fetch'
  ) {
    isRefreshing = true;
  }

  if (/error loading dynamically imported module/.test(error.message ?? '')) {
    isRefreshing = true;
  }
  return isRefreshing;
}

export function displayApplicationWasRefreshed(error) {
  if (checkIfApplicationWasRefreshed(error)) {
    return {
      headline: 'Application was refreshed',
      description:
        "Please refresh your browser's tab to update to the latest version of the platform.",
      custom: true,
    };
  }
  return false;
}

export function checkIfUserIsUnauthorized(error) {
  if (/code 401/.test(error.message ?? '')) {
    return true;
  }
}
