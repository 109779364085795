import React from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useDebouncedValue from 'utils/useDebouncedValue';
import ProductionSearch from 'components/Creator/Productions/AddSocialChannel/graphql/SearchPossibleProductions.graphql';
import CreateProduction from 'components/Creator/Productions/AddSocialChannel/graphql/CreateProduction.graphql';
import reducer, {
  State,
} from 'components/Creator/Productions/AddSocialChannel/utils/reducer';
import { GET_TAXONOMIES } from 'components/Taxonomy/Selector/Selector';
import { Property } from 'components/Property/types';
import LabelInputCombo from 'components/UI/LabelInputCombo/LabelInputCombo';
import { platformUrlsRegex } from 'shared/regex/platformUrls';
import './Form.scss';

const DEFAULT_STATE: State = {
  url: '',
  urlValid: false,
  errorMessage: '',
  possibleProductions: null,
};

export default function Form({
  property,
  refetchProperty,
}: {
  property: Property;

  refetchProperty: () => void;
}) {
  const [state, dispatch] = React.useReducer(reducer, DEFAULT_STATE);

  const onInputChange = React.useCallback(
    (ev: { target: { value: string } }) => {
      dispatch({ url: ev.target.value });
    },
    [],
  );

  const { data: channelData } = useQuery(GET_TAXONOMIES, {
    fetchPolicy: 'cache-first',
    variables: {
      filter: { query: 'influencer', vocabulary: 'channel' },
    },
  });

  const { data: classData } = useQuery(GET_TAXONOMIES, {
    fetchPolicy: 'cache-first',
    variables: {
      filter: { query: 'influencer', vocabulary: 'class' },
    },
  });

  React.useEffect(() => {
    if (channelData?.taxonomies?.data?.[0]?.id) {
      dispatch({ channel: { id: channelData.taxonomies.data[0].id } });
    }
  }, [channelData]);

  React.useEffect(() => {
    if (classData?.taxonomies?.data?.[0]?.id) {
      dispatch({ class: { id: classData.taxonomies.data[0].id } });
    }
  }, [classData]);

  const [fetchCanonicalURL] = useLazyQuery(
    gql`
      query FetchYouTubeCanonicalURL($url: Url) {
        fetchYouTubeCanonicalURL(url: $url)
      }
    `,
    {
      onCompleted: (res) => {
        const canonicalURL = res?.fetchYouTubeCanonicalURL?.canonicalUrl;
        dispatch({
          youtubeUrlToCheck: null,
          url: canonicalURL ?? state?.youtubeUrlToCheck,
        });
      },
    },
  );

  const [
    searchPossibleProductions,
    { loading: loadingPossibleProductions },
  ] = useLazyQuery(ProductionSearch, {
    onCompleted: (res) => {
      dispatch({
        possibleProductions: { id: res?.productions?.data?.[0]?.id },
      });
    },
    onError: () => {
      dispatch({ possibleProductions: { id: 0 } });
    },
  });

  const debouncedUrl = useDebouncedValue(state?.url, 500, '');
  React.useEffect(() => {
    if (!debouncedUrl?.length) {
      return;
    }
    const regex = platformUrlsRegex.youTubeUsername;
    const match = regex.test(debouncedUrl);
    if (!match) {
      dispatch({ youtubeUrlToCheck: debouncedUrl });

      fetchCanonicalURL({
        variables: { url: debouncedUrl },
      });
      return;
    }
    dispatch({ url: debouncedUrl });
  }, [debouncedUrl, fetchCanonicalURL]);

  React.useEffect(() => {
    if (!state?.urlValid) {
      return;
    }

    searchPossibleProductions({
      variables: {
        filter: { query: `url:${state?.url}` },
        limit: 1,
        skip: 0,
        type: 'production',
      },
    });
  }, [state?.urlValid, state?.url, searchPossibleProductions]);

  const [createProduction, { loading: creatingProduction }] = useMutation(
    CreateProduction,
    {
      variables: {
        payload: {
          channel: state.channel,
          class: state.class,
          property: { id: property?.id },
          type: state?.type,
          url: state.url,
        },
      },
      onCompleted: () => {
        refetchProperty();
        dispatch(DEFAULT_STATE);
      },
    },
  );
  const handleCreateProduction = React.useCallback(() => {
    createProduction();
  }, [createProduction]);

  const disabled = React.useMemo(
    () =>
      loadingPossibleProductions ||
      state?.youtubeUrlToCheck ||
      !state?.channel?.id ||
      !state?.class?.id ||
      !state?.urlValid ||
      creatingProduction,
    [
      loadingPossibleProductions,
      state?.youtubeUrlToCheck,
      state?.channel?.id,
      state?.class?.id,
      state?.urlValid,
      creatingProduction,
    ],
  );

  if (!property?.id) {
    return null;
  }

  return (
    <section className="form-container">
      <LabelInputCombo
        label="Social Channel URL"
        inputValue={state?.url}
        onInputChange={onInputChange}
        placeholder="Paste URL"
      />
      {state?.urlValidErrorMessage ?? null}
      {state?.possibleProductions?.message ?? null}
      <button
        disabled={Boolean(disabled)}
        type="submit"
        className="add-channel-button"
        onClick={handleCreateProduction}
      >
        Add Social Channel
      </button>
    </section>
  );
}
