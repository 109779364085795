import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { CampaignExternalReport } from '../graphql';
import { UrlProps, DomoProps } from './types';

import Placeholder from '../Placeholder/Placeholder';

import './BaseDomoIframe.scss';

/*
 * call Domo
 */
function getGeneratedPageURL({ IframeName, embedToken, embedUrl }: UrlProps) {
  if (!embedToken || !embedUrl) {
    return '';
  }

  return (
    `<html>\n   <body>\n        <form id="form${IframeName}" action="${embedUrl}?transparentBackground=true" method="post">\n` +
    `        <input type="hidden" name="embedToken" value="${embedToken}">\n` +
    '        </form>\n' +
    '        <script>\n' +
    `        document.getElementById("form${IframeName}").submit();\n` +
    '        </script>\n' +
    '    </body>\n' +
    '</html>\n'
  );
}

function buildIframeElement(iframe: HTMLIFrameElement, src: string) {
  iframe.srcdoc = src;

  const supportsSrcdoc = 'srcdoc' in document.createElement('iframe');

  if (!supportsSrcdoc) {
    const js = 'javascript: window.frameElement.getAttribute("data-src")'; // eslint-disable-line no-script-url

    iframe.setAttribute('src', js);

    if (iframe.dataset) {
      iframe.dataset.src = src;
    }

    if (iframe.contentWindow) {
      iframe.contentWindow.location.href = js;
    }
  }
}

function BaseDomoIframe({ IframeName, campaignId, platform }: DomoProps) {
  const client = useApolloClient();

  const [hasError, setHasError] = useState<boolean | null>(null);

  const [iframeHeight, setIframeHeight] = useState<string>('100%');

  React.useEffect(() => {
    async function buildIframe() {
      let externalReport;
      try {
        const { data } = await client.query({
          query: CampaignExternalReport,
          variables: { id: campaignId, platform },
        });
        externalReport = data?.campaign?.externalReport;
      } catch (err) {
        setHasError(true);
      }

      try {
        const iframe = document.getElementById(
          `iframe${IframeName}`,
        ) as HTMLIFrameElement;

        const src = getGeneratedPageURL({
          IframeName,
          embedToken: externalReport.embedToken,
          embedUrl: externalReport.embedUrl,
        });

        buildIframeElement(iframe, src);
      } catch (e) {} // eslint-disable-line
    }

    buildIframe();
  }, [IframeName, client, platform, campaignId]);

  return (
    <div className="domo-iframe__wrapper">
      {hasError && (
        <Placeholder>
          There was an error trying to load this report.
          <br />
          Please refresh the page or try again later.
        </Placeholder>
      )}
      {!hasError && (
        <iframe
          title={`Domo Report iFrame ${IframeName}`}
          id={`iframe${IframeName}`}
          data-v-09b992c4=""
          marginHeight={0}
          marginWidth={0}
          className="EmbedSection__iframe"
          height={iframeHeight}
          width="100%"
          frameBorder="0"
          onLoad={() => {
            window.addEventListener(
              'message',
              (event) => {
                const frame = document.getElementById(
                  `iframe${IframeName}`,
                ) as HTMLIFrameElement;

                if (frame?.contentWindow !== event.source) {
                  return;
                }

                if (event?.data?.params?.height) {
                  setIframeHeight(`${event.data.params.height}px`);
                }
              },
              false,
            );
          }}
        />
      )}
    </div>
  );
}

export default BaseDomoIframe;
