import './LabelValueComboHighlighted.scss';

export default function LabelValueComboHighlighted({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) {
  return (
    <p>
      <span className="combo-label">{`${label} `}</span> {value}
    </p>
  );
}
