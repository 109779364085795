// import React from 'react';
import { createContext } from 'react';

type FormTableOfContentsContextType = {
  registerField: (fieldName: string, sectionName: string) => void;
  unregisterField: (fieldName: string) => void;

  registerSection: (sectionName: string) => void;
  unregisterSection: (sectionName: string) => void;
  sections: [];
};

const FormTableOfContentsContext = createContext<FormTableOfContentsContextType>(
  {
    registerField: () => {},
    unregisterField: () => {},

    registerSection: () => {},
    unregisterSection: () => {},
    sections: [],
  },
);

export default FormTableOfContentsContext;
