export default function mapRecommendationLevel(
  recommendationLevel: 'HIGH' | 'MEDIUM' | 'LOW',
) {
  switch (recommendationLevel) {
    case 'HIGH':
      return 'Highly Recommended';
    case 'MEDIUM':
      return 'Moderately Recommended';
    case 'LOW':
      return 'Not Recommended';
    default:
      return '';
  }
}
