import { useEffect } from 'react';

export type AbsoluteRedirectProps = {
  to: string;
  replace: boolean | undefined;
};

// @ts-ignore
function AbsoluteRedirect({ to, replace }: AbsoluteRedirectProps) {
  useEffect(() => {
    if (replace) {
      window.location.replace(to);
    } else {
      window.location.href = to;
    }
  }, [to, replace]);

  return null;
}

export default AbsoluteRedirect;
