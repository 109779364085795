import React from 'react';
import Select from 'react-select';
import UIImage from 'components/UI/Image/Image';
import LabelLink from '@UIComponents/LabelLink/LabelLink';
import {
  Period,
  SetPeriod,
} from 'components/Tiktok/TiktokSparkAds/CreateSparkAd/types';

import { SparkAdsSegmentEvents } from '../../hooks/useSegmentEvents';

import './CreateContent.scss';

const timePeriodOptions: Period[] = [
  {
    label: '7 Days',
    value: 7,
  },
  {
    label: '30 Days',
    value: 30,
  },
  {
    label: '60 Days',
    value: 60,
  },
  {
    label: '365 Days',
    value: 365,
  },
];

export default function CreateContent({
  contentLink,
  thumbnailUrl,
  period,
  setPeriod,
  segmentEvents,
}: {
  contentLink: string | undefined | null;
  thumbnailUrl: string | undefined | null;
  period: Period | null;
  setPeriod: SetPeriod;
  segmentEvents?: SparkAdsSegmentEvents;
}) {
  const onPeriodChange = React.useCallback(
    (newPeriod: Period) => {
      setPeriod(newPeriod);
      if (segmentEvents?.trackPeriodChange) {
        segmentEvents.trackPeriodChange(newPeriod?.label);
      }
    },
    [segmentEvents, setPeriod],
  );

  return (
    <div
      className={`tiktok-spark-ads__create__content ${
        thumbnailUrl ? '' : 'no-image'
      }`}
    >
      {thumbnailUrl ? <UIImage url={thumbnailUrl} /> : null}

      <div
        className={`tiktok-spark-ads__create__content__info ${
          thumbnailUrl ? '' : 'no-image'
        }`}
      >
        <p className="tiktok-spark-ads__create__content__info__link">
          <strong>Content Link: </strong>{' '}
          {contentLink ? (
            <LabelLink
              href={contentLink}
              label={contentLink}
              linkType="external"
              onClick={segmentEvents?.trackContentLinkClick}
            />
          ) : null}
        </p>

        <div>
          <p>Time period</p>
          <Select
            value={period}
            className="period-selector"
            placeholder="-- Select --"
            options={timePeriodOptions}
            onChange={onPeriodChange}
          />
        </div>
      </div>
    </div>
  );
}
