import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import useUser from 'store/authentication/useUser';
import FormGroup from 'components/Form/Group/Group';
import FormHorizontal from 'components/Form/Horizontal/Horizontal';
import FormLabel from 'components/UI/Form/Label/Label';
import FormRow from 'components/Form/Row/Row';
import FormSection from 'components/Form/Section/Section';
import UIGridCol from 'components/UI/Grid/Col/Col';
import UIGridRow from 'components/UI/Grid/Row/Row';
import UIIcon from 'components/UI/Icon/Icon';
import UIPageStatus from 'components/UI/PageStatus/PageStatus';
import usePermissions from 'store/authentication/usePermissions';
import ProfileExperiments from './Experiments/Experiments';
import SendBenExtension from './SendBenExtension/SendBenExtension';
import { ProfileQuery } from './graphql';

import './Profile.scss';

function Profile() {
  const { user: loggedUser } = useUser();
  const permissions = usePermissions('users');
  const { data, loading, error } = useQuery(ProfileQuery, {
    skip: !loggedUser.id,
    variables: {
      id: loggedUser.id,
    },
  });

  if (!data || loading || error) {
    return <UIPageStatus error={error} loading={loading} />;
  }

  const {
    user,
    user: { client, brands },
  } = data;

  return (
    <UIGridRow>
      <UIGridCol md="6">
        <FormSection title="Personal Information" noTableOfContents>
          <FormRow>
            <FormGroup size="medium">
              <FormLabel>First Name</FormLabel>
              <div id="first-name">{user.firstName}</div>
            </FormGroup>

            <FormGroup size="medium">
              <FormLabel>Last Name</FormLabel>
              <div id="last-name">{user.lastName}</div>
            </FormGroup>
          </FormRow>

          <FormHorizontal />

          <FormRow>
            <FormGroup size="medium">
              <FormLabel>Job Title</FormLabel>
              <div id="job-title">{user.title}</div>
            </FormGroup>

            <FormGroup size="medium">
              <FormLabel>Email</FormLabel>
              <div id="email">{user.email}</div>
            </FormGroup>
          </FormRow>
        </FormSection>

        {client && (
          <FormSection title="Company information" noTableOfContents>
            <FormRow>
              <FormGroup>
                <FormLabel>Company name</FormLabel>
                <div id="company-name">{client.name}</div>
              </FormGroup>
            </FormRow>

            {client.addresses && (
              <FormRow>
                <FormHorizontal />

                <FormGroup>
                  <FormLabel>Company address</FormLabel>
                  {client.addresses.map((address) => (
                    <div key={address.name}>
                      <div>
                        {address.name}
                        {' '}
                        {address.fullAddress}
                      </div>
                      <div>
                        {address.city}
                        {' '}
                        {address.stateAbbreviation}
                      </div>
                      <div>{address.ZIPCode}</div>
                      <div>{address.country}</div>
                      <br />
                      <div>{address.phone}</div>
                    </div>
                  ))}
                </FormGroup>
              </FormRow>
            )}
          </FormSection>
        )}

        <FormSection title="Website access" noTableOfContents>
          <FormRow>
            <FormGroup size="medium">
              <FormLabel>User Role</FormLabel>
              <div id="user-role">{user.role}</div>
            </FormGroup>
          </FormRow>

          <FormHorizontal />

          <FormRow>
            <FormGroup>
              <FormLabel>Brands</FormLabel>
              <div>
                {brands.map((brand) => (
                  <div className="brand-name" key={brand.name}>
                    <UIIcon type="check" size="small" />
                    {brand.name}
                  </div>
                ))}
                {brands && !brands.length && (
                  <span>No Brands associated with this user</span>
                )}
              </div>
            </FormGroup>
          </FormRow>
        </FormSection>

        {user?.role !== 'external' ? <SendBenExtension /> : null}

        {permissions.updateOptionalFeatureFlags && (
          <ProfileExperiments user={user} />
        )}
      </UIGridCol>

      <UIGridCol md="6">
        <FormSection noTableOfContents>
          <div className="profile__notice">
            <h4>Need to update your account?</h4>
            <p>
              Please
              <a href="mailto:servicedesk@bengroup.com"> contact us </a>
              with questions regarding your account and to manage your account
              information.
            </p>
          </div>
        </FormSection>
      </UIGridCol>
    </UIGridRow>
  );
}

export default Profile;
