import { useContext } from 'react';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import { Deliverable } from 'components/Deliverable/types';
import { Placement } from 'components/Placement/types';
import BaseSection from '@UIComponents/BaseSection/BaseSection';
import Loading from '@UIComponents/Loading/Loading';
import campaignMetricsService from 'external/components/CampaignSummary/_services/CampaignMetricsService';
import FigmaIcon from '@UIComponents/FigmaIcon/FigmaIcon';
import PlatformMetrics from './PlatformMetrics';

import './RunningTotals.scss';

type RunningTotalsProps = {
  enabledCreators: boolean;
  enabledContent: boolean;
  creators: Deliverable[];
  content: Placement[];
};

function PreviewButton({
  data = [],
  type = 'creator',
  icon = '',
}: {
  data: Deliverable[] | Placement[];
  type: 'creator' | 'content';
  icon: string;
}) {
  const { campaignId } = useContext(ExternalApplicationContext);
  const { host, protocol } = window.location;
  const urlSuffix = type === 'creator' ? 'creators' : 'posts';
  const redirectToCreatorList = `${protocol}//${host}/client/campaigns/${campaignId}/${urlSuffix}`;

  return (
    <a href={redirectToCreatorList} className="PreviewButton">
      <FigmaIcon icon={icon} type="solid" size="large" color="blue-1" />
      <span>
        {data.length} {type} to approve
      </span>
    </a>
  );
}

const getContent = (
  campaignId: number,
  {
    enabledCreators = false,
    enabledContent = false,
    creators = [],
    content = [],
  }: RunningTotalsProps,
) => {
  const data = campaignMetricsService(campaignId);
  const permissions = {
    clickTracking: data?.clickTracking,
    conversionTracking: data?.conversionTracking,
  };

  if (!data) return <Loading white />;

  return (
    <div data-cy="RunningTotals">
      <PlatformMetrics
        data={data?.contentMetrics?.byAll}
        permissions={permissions}
      />
      <div className="running-actions">
        {enabledCreators && creators.length ? (
          <PreviewButton data={creators} type="creator" icon="image-user" />
        ) : null}
        {enabledContent && content.length ? (
          <PreviewButton data={content} type="content" icon="monitor-02" />
        ) : null}
      </div>
    </div>
  );
};

function RunningTotals(props: RunningTotalsProps) {
  const { campaignId } = useContext(ExternalApplicationContext);

  return (
    /* @ts-ignore */
    <BaseSection parentClassName="RunningTotals" type="transparent">
      {campaignId ? getContent(campaignId, props) : <Loading white />}
    </BaseSection>
  );
}

export default RunningTotals;
