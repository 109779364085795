import {
  onCellValueChanged,
  valueGetterObject,
  valueGetterSimpleValue,
  valueSetterObject,
  valueSetterSimpleValue,
} from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/colDefFns';
import { CreatorColDef } from 'shared/types/Campaign';
import { ReactSelectOption } from 'shared/types/ReactSelect';
import dateColDef from 'components/DataGrid/Destination/colDef/dateColDef';
import TagsEditor from 'components/DataGrid/Editors/Destination/Tags/Tags';
import {
  ColDef,
  SuppressKeyboardEventParams,
} from 'ag-grid-community/dist/lib/main';
import dropdownColDef from 'components/DataGrid/Destination/colDef/dropdownColDef';
import DropDownCellRenderer from 'components/DataGrid/Destination/Cells/DropDownCellRenderer/DropDownCellRenderer';
import DropDownOptionCellRenderer from 'components/DataGrid/Destination/Cells/DropDownOptionCellRenderer/DropDownOptionCellRenderer';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import TagCellRenderer from 'components/DataGrid/Destination/Cells/Tag/TagCellRenderer';
import { manualMetricColDef } from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/calculationFns';
import DestinationCheckboxRenderer from 'components/DataGrid/Destination/Cells/CheckboxRenderer/CheckboxRenderer';
import DestinationCheckboxEditor from 'components/DataGrid/Destination/Editors/CheckboxEditor/CheckboxEditor';
import { Placement } from 'shared/types/Placement';

function setEditability(colDef: CreatorColDef, isForExternalUse: boolean) {
  if (!isForExternalUse) {
    return true;
  }
  return colDef?.clientAccess === 'edit';
}

function getDropdownOptions(colDef: CreatorColDef) {
  return [
    { label: 'None', value: 'none' },
    ...(colDef?.dropdownOptions ?? []).map(
      (dropdownOption: Record<string, unknown>) => ({
        value: dropdownOption?.label,
        label: dropdownOption?.label,
        color: dropdownOption?.color,
      }),
    ),
  ];
}

const customDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
    const cellValueDate = new Date(cellValue);

    if (cellValueDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellValueDate > filterLocalDateAtMidnight) {
      return 1;
    }

    return 0;
  },
};

export default function addAgGridDefinitionsToCustomColumns(
  colDef: CreatorColDef,
  isForExternalUse: boolean,
): CreatorColDef {
  if (!colDef?.custom) {
    return colDef;
  }

  const newColDef = {
    ...colDef,
    cellStyle: { display: 'block' },
    editable: setEditability(colDef, isForExternalUse),
    cellRenderer: ({ value }: { value: number }) => <span>{value}</span>,
    onCellValueChanged:
      colDef?.type !== 'customManualMetric' ? onCellValueChanged : undefined,
  };

  switch (colDef?.type) {
    case 'customManualMetric': {
      return manualMetricColDef(newColDef);
    }
    case 'checkbox':
      return {
        ...newColDef,
        cellEditor: DestinationCheckboxEditor,
        cellRenderer: DestinationCheckboxRenderer,
        valueGetter: valueGetterSimpleValue,
        valueSetter: valueSetterSimpleValue,
        filter: false,
      };
    case 'date':
      return {
        ...newColDef,
        ...dateColDef,
        valueGetter: valueGetterSimpleValue,
        valueSetter: valueSetterSimpleValue,
        filter: 'agDateColumnFilter',
        filterParams: customDateFilterParams,
      };
    case 'dropdown':
      return {
        ...newColDef,
        ...dropdownColDef,
        cellClass: `${newColDef?.cellClass ?? ''} ${
          dropdownColDef?.cellClass ?? ''
        }`,
        cellEditorParams: () => ({
          values: getDropdownOptions(colDef),
          formatValue: (value: ReactSelectOption) => value?.label ?? '',
          cellHeight: 40,
          cellRenderer: DropDownOptionCellRenderer,
        }),
        cellRenderer: DropDownCellRenderer,
        valueGetter: (props: {
          data: Placement;
          colDef: ColDef<Placement>;
        }) => {
          const processedValue = valueGetterObject(props);
          return processedValue?.value !== 'none' ? processedValue : null;
        },
        comparator: (
          firstOption: ReactSelectOption,
          secondOption: ReactSelectOption,
        ) =>
          (firstOption?.label ?? '')
            .toLowerCase()
            .localeCompare((secondOption?.label ?? '')?.toLowerCase()),
        valueSetter: valueSetterObject,
      };
    case 'text':
      return {
        ...newColDef,
        valueGetter: valueGetterSimpleValue,
        valueSetter: valueSetterSimpleValue,
        filter: 'agSetColumnFilter',
      };
    case 'number':
      return {
        ...newColDef,
        ...numberColDef,
        headerClass: `${newColDef?.headerClass ?? ''} ${
          numberColDef?.headerClass ?? ''
        }`,
        cellStyle: {},
        valueGetter: valueGetterSimpleValue,
        valueSetter: valueSetterSimpleValue,
      };
    case 'tag':
      return {
        ...newColDef,
        valueGetter: valueGetterSimpleValue,
        valueSetter: valueSetterSimpleValue,
        cellEditor: TagsEditor,
        cellRenderer: TagCellRenderer,
        suppressKeyboardEvent: ({
          event,
          editing,
        }: SuppressKeyboardEventParams<unknown>) => {
          const { key } = event;
          return editing && key === 'Enter';
        },
        valueFormatter: ({ value }: { value: string[] | null }): string =>
          (value ?? []).join(','),
        filter: 'agSetColumnFilter',
      };
    default:
      return colDef;
  }
}
