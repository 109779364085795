import { arrayOf, shape, number, string, func } from 'prop-types';
import { Link } from 'react-router-dom';
import UIIconLink from 'components/UI/IconLink/IconLink';
import UIDate from 'components/UI/Date/Date';
import UIList from 'components/UI/List/List';

import { longDateFormat } from 'components/utils/formats';
import { getNumberLabel } from 'components/utils/helpers';

import './Header.scss';

const cbn = 'placement-view-header';

function PlacementViewHeader({
  channel,
  id,
  networks,
  permissions,
  productionName,
  propertyName,
  releaseDate,
  renderLabel,
  seasonOrSpecificName,
  shouldRender,
  sourceName,
  sourceNumber,
  type,
  vehiclePower,
  search,
}) {
  const customSeasonName = renderLabel(
    'headerSection.productionName',
    productionName,
  );

  return (
    <>
      {shouldRender('headerSection') && (
        <div className={cbn}>
          <div className={`${cbn}__title-wrapper`}>
            <h1 className={`${cbn}__title`}>
              {shouldRender('headerSection.propertyName') && (
                <span>{propertyName}</span>
              )}
              {networks && shouldRender('headerSection.networks') && (
                <span className={`${cbn}__networks__value`}>
                  <UIList>{networks.map(({ name }) => name)}</UIList>
                </span>
              )}
            </h1>
          </div>

          <h3 className={`${cbn}__subtitle`}>
            {shouldRender('headerSection.source.name') && sourceName}{' '}
            <strong>
              {shouldRender('headerSection.productionName') && (
                <>
                  {customSeasonName !== productionName
                    ? `${customSeasonName} ${seasonOrSpecificName}`
                    : productionName}
                </>
              )}
              {shouldRender('headerSection.source.number') && type !== 'film' && (
                <span>
                  &nbsp;{' '}
                  {renderLabel(
                    'headerSection.source.number',
                    getNumberLabel(channel, type),
                  )}
                  {sourceNumber || ' N/A'}
                </span>
              )}
            </strong>
          </h3>
          <div className={`${cbn}__id-section`}>
            {shouldRender('headerSection.channel') && (
              <div className={`${cbn}__channel`}>
                {channel ? channel.name : 'N/A'}
              </div>
            )}

            {shouldRender('headerSection.placementId') && (
              <>
                <span className={`${cbn}__id__label`}>
                  {renderLabel('headerSection.placementId', 'Placement ID')}
                </span>
                <Link
                  className={`${cbn}__id__link`}
                  to={`/placements/${id}${search}`}
                >
                  {id}
                </Link>
                {permissions.update && (
                  <UIIconLink
                    to={`/placements/${id}/edit${search}`}
                    className={`${cbn}__id__link--edit`}
                    keepReferrer
                    icon="mode_edit"
                    tooltip="Edit"
                  />
                )}
              </>
            )}
          </div>

          <div className={`${cbn}__premiere-vehicle`}>
            {shouldRender('headerSection.premiereDate') && (
              <>
                <strong>
                  {renderLabel('headerSection.premiereDate', 'Premiere')}
                </strong>
                &nbsp;
                <span className={`${cbn}__premiere-date`}>
                  <UIDate date={releaseDate} format={longDateFormat} />
                </span>
              </>
            )}
            {shouldRender('headerSection.vehiclePower') && (
              <span className={`${cbn}__vehicle-power`}>
                |&nbsp;
                {renderLabel('headerSection.vehiclePower', 'Vehicle Power')}:
                {vehiclePower}
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
}

PlacementViewHeader.propTypes = {
  channel: shape({}),
  id: string.isRequired,
  networks: arrayOf(shape({})),
  permissions: shape({}),
  productionName: string,
  propertyName: string,
  releaseDate: string,
  renderLabel: func.isRequired,
  search: string,
  seasonOrSpecificName: string,
  shouldRender: func.isRequired,
  sourceName: string,
  sourceNumber: number,
  type: string,
  vehiclePower: string,
};

PlacementViewHeader.defaultProps = {
  channel: null,
  networks: [],
  permissions: {},
  productionName: string,
  propertyName: null,
  releaseDate: null,
  search: '',
  seasonOrSpecificName: null,
  sourceName: null,
  sourceNumber: null,
  type: null,
  vehiclePower: null,
};

export default PlacementViewHeader;
