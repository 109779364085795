import React from 'react';
import getDateFromHtmlString from 'shared/dates/getDateFromHtmlString';
import getHtmlLocalDate from 'shared/dates/getHtmlLocalDate';
import './DatePicker.scss';

const DataGridDateInput = React.forwardRef(
  (
    {
      value,
      stopEditing,
    }: {
      value: Date | string | null;
      stopEditing: () => void;
    },
    ref,
  ) => {
    const refInput: React.MutableRefObject<HTMLInputElement | null> = React.useRef(
      null,
    );

    const htmlLocalDate = React.useMemo(() => getHtmlLocalDate(value), [value]);

    React.useEffect(() => {
      if (!refInput?.current?.focus) {
        return;
      }
      refInput.current.focus();
    }, [refInput]);

    React.useImperativeHandle(ref, () => ({
      getValue() {
        /**
         * If we have a value, convert it to UTC at the beginning of the date and store it and the
         * db.
         */
        if (refInput?.current?.value) {
          return getDateFromHtmlString(refInput.current.value);
        }
        return null;
      },
    }));

    return (
      <input
        className="data-grid-date-input"
        name="date"
        ref={refInput}
        type="date"
        defaultValue={htmlLocalDate}
        onBlur={stopEditing}
      />
    );
  },
);

DataGridDateInput.componentName = 'DataGridEditorInput';
DataGridDateInput.displayName = 'DataGridEditorInput';

export default DataGridDateInput;
