import { createContext } from 'react';
import { Campaign } from 'components/Campaign/types';
import { Analytics } from '@segment/analytics-next';
import { History } from 'history';

type Noop = () => void;

type initialExternalApplicationContextType = {
  campaign?: Campaign | null;
  campaignId?: number | null;
  initialTab?: string | null;
  onChangeCampaign?: Noop;
  setTheme?: Noop;
  theme: string;
  setSegment?: Noop;
  segment: Analytics | null;
  history: History | null;
};

export const initialExternalApplicationContext: initialExternalApplicationContextType = {
  campaign: null,
  campaignId: null,
  initialTab: null,
  onChangeCampaign: () => {},
  setTheme: () => {},
  theme: 'light',
  setSegment: () => {},
  segment: null,
  history: null,
};

export const ExternalApplicationContext = createContext<initialExternalApplicationContextType>(
  initialExternalApplicationContext,
);

export default ExternalApplicationContext;
