import React from 'react';
import { Campaign } from 'shared/types/Campaign';
import { Platforms } from 'shared/types/platforms';
import CampaignFrame from '../frames/Campaign';
import YoutubeFrame from '../frames/Youtube';
import InstagramFrame from '../frames/Instagram';
import TwitchFrame from '../frames/Twitch';
import TwitterFrame from '../frames/Twitter';
import FacebookFrame from '../frames/Facebook';
import TikTokFrame from '../frames/TikTok';

export default function FramesWrapper({
  campaign,
}: Readonly<{ campaign: Campaign }>) {
  const platforms: Platforms[] = React.useMemo(
    () =>
      (campaign?.mediaTargets ?? []).reduce((acc: Platforms[], mediaTarget) => {
        if (
          mediaTarget?.platform &&
          !acc.includes(mediaTarget.platform as Platforms)
        ) {
          acc.push(mediaTarget.platform as Platforms);
        }
        return acc;
      }, []),
    [campaign?.mediaTargets],
  );

  if (!campaign?.id) {
    return null;
  }

  return (
    <>
      <CampaignFrame campaignId={campaign.id} />
      {!campaign.clientReportEmbed && campaign.subType === 'Awareness CPV' && (
        <>
          {platforms.includes('youtube' as Platforms) && (
            <YoutubeFrame campaignId={campaign.id} />
          )}
          {platforms.includes('instagram' as Platforms) && (
            <InstagramFrame campaignId={campaign.id} />
          )}
          {platforms.includes('twitch' as Platforms) && (
            <TwitchFrame campaignId={campaign.id} />
          )}
          {platforms.includes('tikTok' as Platforms) && (
            <TikTokFrame campaignId={campaign.id} />
          )}
          {platforms.includes('twitter' as Platforms) && (
            <TwitterFrame campaignId={campaign.id} />
          )}
          {platforms.includes('facebook' as Platforms) && (
            <FacebookFrame campaignId={campaign.id} />
          )}
        </>
      )}
    </>
  );
}
