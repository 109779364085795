import formatters from 'components/DataGrid/formatters';
import { VIDEO_MEDIA_TYPE, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import basePerformanceColDef from '../../helpers/baseColDef';
import getYouTubeDataFromCampaignService from '../helpers/getYouTubeDataFromCampaignService';

const metric = 'averagePercentWatched';

export function averagePercentWatchedAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric);
  }
  return null;
}

export default function averagePercentWatched() {
  return {
    ...basePerformanceColDef(
      metric,
      averagePercentWatchedAutomatedValueGetter,
      [YOUTUBE_PLATFORM],
      [VIDEO_MEDIA_TYPE],
    ),
    valueFormatter: ({ value }: { value: number }) =>
      formatters.percent({ value: value / 100 }),
    headerName: 'Average % Watched',
    width: 250,
  };
}
