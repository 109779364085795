import { Placement } from 'shared/types/Placement';
import { YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import basePerformanceColDef from './helpers/baseColDef';
import getYouTubeDataFromCampaignService from './youTube/helpers/getYouTubeDataFromCampaignService';

const metric = 'dislikes';

export function dislikesAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric);
  }
  return null;
}

export default function dislikes() {
  return {
    ...basePerformanceColDef(metric, dislikesAutomatedValueGetter, [
      YOUTUBE_PLATFORM,
    ]),
    headerName: 'Dislikes',
    width: 165,
  };
}
