export const segmentKeys = {
  local: {
    writeKey: 'FncOL3VFYY7N0Xm2vxbvtoOcuK3jw2rI',
    snippetVersion: '4.15.3',
  },
  stable: {
    writeKey: 'FncOL3VFYY7N0Xm2vxbvtoOcuK3jw2rI',
    snippetVersion: '4.15.3',
  },
  demo: {
    writeKey: '',
    snippetVersion: '4.15.3',
  },
  devel: {
    writeKey: '',
    snippetVersion: '4.15.3',
  },
  UAT: {
    writeKey: 'FncOL3VFYY7N0Xm2vxbvtoOcuK3jw2rI',
    snippetVersion: '4.15.3',
  },
  production: {
    writeKey: '6J2nYeEGnwE03RonKtvWD3JGxhq81aDn',
    snippetVersion: '4.15.3',
  },
};
