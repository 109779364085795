import { Placement } from 'shared/types/Placement';
import { getOffsetByReportingKey } from 'shared/campaigns/creatorCampaigns/reportingWindows';
import { getPostReleaseDate } from 'shared/campaigns/creatorCampaigns/postDates';
import { PostManualMetric } from 'shared/types/CreatorCampaigns';
import { Context } from 'components/Campaign/Influencer/View/shared/types';

export function getMatchingPostManualMetric(
  data: Placement,
  context?: Context,
  metric?: string,
  postCustomColumnsUuid?: string,
) {
  if (
    !data?.source?.postMetrics?.postManualMetrics?.length ||
    !context?.reportingWindow
  ) {
    return null;
  }
  const reportingWindowDateWithOffset = getOffsetByReportingKey(
    context?.reportingWindow,
    context?.campaign?.dateRangeFrom,
    getPostReleaseDate(data)?.toISOString(),
  );

  if (!reportingWindowDateWithOffset) {
    return null;
  }
  const datesBeforeReportingWindowEnd = data?.source?.postMetrics?.postManualMetrics
    .filter((manualMetric: PostManualMetric) => {
      if (!manualMetric?.date) {
        return false;
      }

      return (
        new Date(manualMetric?.date) <= reportingWindowDateWithOffset &&
        manualMetric?.metricType === metric &&
        (manualMetric?.postCustomColumnsUuid ?? null) ===
          (postCustomColumnsUuid ?? null)
      );
    })
    .sort((a: PostManualMetric, b: PostManualMetric) => {
      if (!a?.date || !b?.date) {
        return 0;
      }
      return Date.parse(b?.date) - Date.parse(a?.date);
    });

  if (!datesBeforeReportingWindowEnd?.length) {
    return null;
  }

  return datesBeforeReportingWindowEnd;
}

export default function manualMetricsValueGetter(
  data: Placement,
  context?: Context,
  metric?: string,
  postCustomColumnsUuid?: string,
) {
  return (
    getMatchingPostManualMetric(
      data,
      context,
      metric,
      postCustomColumnsUuid,
    )?.[0]?.value ?? null
  );
}
