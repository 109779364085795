import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function RouteResetScroll() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // useEffect(() => {
  //   // document.addEventListener('keydown', (e) => {
  //   //   console.log(e);
  //   //   e.stopPropagation();
  //   //   e.preventDefault();
  //   //   const { value, tagName } = e.target;
  //   //   console.log('HEY', e.keyCode, value, tagName);
  //   //   if (e.keyCode === 8 && !value && ['INPUT', 'TEXTAREA'].includes(tagName)) {
  //   //     console.log('test')
  //   //     e.stopPropagation();
  //   //     e.preventDefault();
  //   //   }
  //   // });
  //   window.addEventListener('keydown', (e) => {
  //     console.log(e);
  //     e.stopPropagation();
  //     e.preventDefault();
  //     const { value, tagName } = e.target;
  //     console.log('HEY', e.keyCode, value, tagName);
  //     if (e.keyCode === 8 && !value && ['INPUT', 'TEXTAREA'].includes(tagName)) {
  //       console.log('test')
  //       e.stopPropagation();
  //       e.preventDefault();
  //     }
  //   });
  // }, [])

  return null;
}
