export default function percent({ value, format = {} }) {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...format,
  });

  if (!value) {
    return null;
  }

  if (value === '--') {
    return value;
  }

  return formatter.format(value);
}
