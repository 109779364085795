import { Analytics } from '@segment/analytics-next';
import { Campaign } from 'components/Campaign/types';
import { loadSegment, segmentPageCall } from 'components/Segment/Segment';
import { User } from 'components/User/types';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import { SegmentExternalEvents } from './segmentExternalEvents';

import {
  trackCreatorDeclined,
  trackCreatorRedirected,
  trackCreatorApproved,
  trackCreatorViewed,
  trackCreatorChatClicked,
} from './creatorsTracks';

import {
  trackPostApproved,
  trackPostViewed,
  trackPostDeclined,
  trackPostChatClicked,
  trackPostRedirected,
} from './postsEventsTracks';

function trackOnFilterChanged({
  segment,
  campaign,
  user,
  filterName,
  filteredQuantity,
  totalCount,
}: {
  segment: Analytics;
  tab: string;
  campaign: Campaign;
  user: User;
  filterName: string;
  filteredQuantity: number;
  totalCount: number;
}) {
  const { origin, pathname } = window.location;

  segment.track(
    SegmentExternalEvents.PageFilter,
    {
      action: 'clicked',
      url: `${origin}${pathname}`,
      filter_name: filterName || null,
      filtered_quantity: filteredQuantity || null,
      total_count: totalCount || 0,
    },
    {
      context: { groupId: formatCampaignId(campaign.id) },
      userId: formatUserId(user.id),
    },
  );
}

function trackSortClick({
  segment,
  campaign,
  user,
  columnName,
}: {
  segment: Analytics;
  campaign?: Campaign;
  user: User;
  columnName: string;
}) {
  const { origin, pathname } = window.location;

  segment.track(
    SegmentExternalEvents.ColumnSort,
    {
      action: 'clicked',
      url: `${origin}${pathname}`,
      columnName: columnName || null,
    },
    {
      context: { groupId: campaign ? formatCampaignId(campaign.id) : null },
      userId: formatUserId(user.id),
    },
  );
}

export {
  loadSegment,
  segmentPageCall,
  trackOnFilterChanged,
  trackSortClick,
  trackCreatorDeclined,
  trackCreatorApproved,
  trackCreatorChatClicked,
  trackPostChatClicked,
  trackPostDeclined,
  trackCreatorRedirected,
  trackPostApproved,
  trackCreatorViewed,
  trackPostViewed,
  trackPostRedirected,
};
