import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import tikTokCommon from 'components/Campaign/Influencer/View/Stats/Performance/columns/tikTok/helpers/tikTokCommon';

const tikTokMetric = 'fct_lifetime_video_performance.video_views_by_source_for_you' as keyof typeof TCMReportMetrics;
const metric = 'video_views_by_source-for_you';
export default function forYouTraffic() {
  return {
    ...numberColDef,
    ...tikTokCommon(tikTokMetric, metric),
    headerName: 'For You Traffic',
    width: 210,
  };
}
