import formatDate from 'shared/dates/formatDate';
import LabelLink from '@UIComponents/LabelLink/LabelLink';
import CopyIcon from '@UIComponents/_assets/react_svg/CopyIcon';
import {
  TCMSparkAdStatusData,
  TCMSparkAdStatusDataAuthCodeStatus,
} from 'shared/types/TikTokMarketPlace';
import ClipboardCopyLabel from '@UIComponents/ClipboardCopyLabel/ClipboardCopyLabel';
import SparkAdsUsingAuthorizedPosts from 'components/Tiktok/TiktokSparkAds/UpdateSparkAd/SparkAdsUsingAuthorizedPosts/SparkAdsUsingAuthorizedPosts';

import './AuthorizationInfo.scss';

const codeStatusMapping = {
  NOT_USED: 'Not Used',
  IN_USE: 'In Use',
  EXPIRED: 'Expired',
  DELETED: 'Deleted',
};

export default function AuthorizationInfo({
  contentLink,
  startDate,
  endDate,
  authCode,
  authCodeStatus,
  trackContentLinkClick,
  tikTokSparkAdStatusRefetch,
}: {
  contentLink: string | undefined | null;
  startDate: string;
  endDate: string;
  authCode?: string;
  authCodeStatus?: TCMSparkAdStatusData['auth_code_status'];
  trackContentLinkClick?: () => void;
  tikTokSparkAdStatusRefetch: () => void;
}) {
  const mappedCodeStatus =
    codeStatusMapping[authCodeStatus as TCMSparkAdStatusDataAuthCodeStatus];

  return (
    <section className="tiktok-spark-ads__update__content__video-data__authorization">
      <p className="tiktok-spark-ads__update__content__video-data__authorization__content-link">
        <strong>Content Link: </strong>{' '}
        {contentLink ? (
          <LabelLink
            href={contentLink}
            label={contentLink}
            linkType="external"
            onClick={trackContentLinkClick}
          />
        ) : null}
      </p>

      <div className="tiktok-spark-ads__update__content__video-data__authorization__period">
        <strong>Authorization Period:</strong>
        <p>
          Start Date:
          {'  '}
          {formatDate(startDate) ?? '--'}
        </p>
        <p>
          End Date:
          {'  '}
          {formatDate(endDate) ?? '--'}
        </p>
      </div>

      <div className="tiktok-spark-ads__update__content__video-data__authorization__code">
        <strong>Authorization Code:</strong>
        <div className="tiktok-spark-ads__update__content__video-data__authorization__code__code">
          <p>
            Auth Code:
            {'  '}
            {authCode ?? '--'}
          </p>
          {authCode ? (
            <ClipboardCopyLabel
              copyValue={authCode}
              label={<CopyIcon size="x-small" stroke="#8F8F8F" />}
            />
          ) : null}
        </div>

        <p>
          Auth Code Status:
          {'  '}
          {mappedCodeStatus ?? '--'}
        </p>
        <SparkAdsUsingAuthorizedPosts
          authCode={authCode}
          tikTokSparkAdStatusRefetch={tikTokSparkAdStatusRefetch}
        />
      </div>
    </section>
  );
}
