import React from 'react';
import { DocumentNode } from 'graphql';
import useUser from 'store/authentication/useUser';
import { useApolloClient } from '@apollo/react-hooks';
import { RowNodeTransaction, RowNode, GridReadyEvent } from 'ag-grid-community';
import getItemsToUpdate from 'components/Campaign/Influencer/View/hooks/utils/getItemsToUpdate';
import {
  Filter,
  Variables,
} from 'components/Campaign/Influencer/View/hooks/types';

export const getResponse = (data?: RowNode[]): RowNode | null => {
  if (!data) {
    return null;
  }
  return Object.values(data)[0];
};

export type Context = {
  id: number;
  by?: number;
  action: string;
  customRowCreation?: (arg0: RowNode[]) => RowNode[];
  customRowDeletion?: (arg0: RowNode[]) => RowNode[];
};

function updateBasedOnAction(
  gridApi: GridReadyEvent | null,
  entities: RowNode[] | null,
  variables: { context: Context },
) {
  if (!entities) {
    return null;
  }
  const { action, customRowCreation, customRowDeletion } = variables.context;

  const transaction: {
    add: RowNode[];
    remove: RowNode[];
    update: RowNode[];
  } = {
    add: [],
    remove: [],
    update: [],
  };

  const contextAction = variables.context.action;
  const idsToUpdate: number[] = [];

  if (action === 'update') {
    transaction.update = getItemsToUpdate(gridApi, entities);
  }

  if (action === 'create') {
    entities.forEach((e: RowNode) => {
      transaction.add.push(e);
    });
  }

  if (contextAction === 'create' && customRowCreation) {
    const newRows = customRowCreation(entities);
    transaction.add = transaction.add.concat(newRows);
  }

  if (contextAction === 'delete' && customRowDeletion) {
    const rowsToDelete = customRowDeletion(entities);
    transaction.remove = transaction.remove.concat(rowsToDelete);
  }
  return {
    transaction,
    idsToUpdate,
  };
}

export default function useLoadEntitiesHelpers(
  filter: Filter | null,
  singleEntityQuery: DocumentNode | null,
) {
  const [idsToUpdateAcc, setIdsToUpdateAcc] = React.useState([]);
  const [idsToRefetch, setIdsToRefetch] = React.useState<number[]>([]);
  const [gridApi, setGridApi] = React.useState<GridReadyEvent | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [skip, setSkip] = React.useState(0);
  const user = useUser();
  const apolloClient = useApolloClient();

  function markIdToRefetch(id: number) {
    if (!id) {
      return;
    }
    setIdsToRefetch([...(idsToRefetch ?? []), id]);
  }

  const applyTransaction = React.useCallback(
    (transaction: RowNodeTransaction | null | undefined) => {
      if (!gridApi || !transaction) {
        return;
      }
      const res = gridApi.api.applyTransaction(transaction);
      if (!res) {
        return;
      }

      gridApi.api.flashCells({
        rowNodes: [...res.add, ...res.update, ...res.remove],
      });
    },
    [gridApi],
  );

  const refetchSingleEntity = async (
    variables: Variables & { context: Context },
  ) => {
    if (!filter) {
      return;
    }
    const contextAction = variables.context.action;
    if (contextAction === 'delete') {
      if (variables.context.by !== user?.user?.id) {
        const itemsToRemove: RowNode[] = [];
        if (!gridApi) {
          return;
        }
        gridApi.api.forEachNodeAfterFilterAndSort((rowNode) => {
          if (rowNode.data.id === variables.context.id) {
            itemsToRemove.push(rowNode.data);
          }
        });
        applyTransaction({ remove: itemsToRemove, add: [], update: [] });
      }
      return;
    }

    if (!singleEntityQuery) {
      return;
    }

    const res = await apolloClient.query({
      query: singleEntityQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    const responsePayload = getResponse(res?.data);
    if (!responsePayload) {
      return;
    }

    const entities = [responsePayload];

    const { transaction } = updateBasedOnAction(
      gridApi,
      entities,
      variables,
    ) ?? { idsToUpdate: [] };
    applyTransaction(transaction);
  };

  return {
    idsToUpdateAcc,
    setIdsToUpdateAcc,
    idsToRefetch,
    setIdsToRefetch,
    markIdToRefetch,
    applyTransaction,
    refetchSingleEntity,
    gridApi,
    setGridApi,
    loading,
    setLoading,
    skip,
    setSkip,
  };
}
