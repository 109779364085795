import gql from 'graphql-tag';

export default gql`
  query ExternalDeliverablesList(
    $limit: Int
    $skip: Int
    $sort: String
    $filter: DeliverableFilter
    $metricConfig: [MetricConfig!]!
    $campaignsSeeSensitiveData: Boolean!
  ) {
    deliverables(limit: $limit, skip: $skip, sort: $sort, filter: $filter) {
      data {
        id
        viewedByClient
        createdAt
        createdBy {
          id
          firstName
          lastName
          title
        }
        property {
          id
          name
          productions {
            id
            type
            url
            synopsis
            postFrequency
            media {
              id
              url
              attributes {
                width
                height
                thumbnail
              }
            }
            bestMetricResults(metricConfig: $metricConfig) {
              id
              mediaType
              metricType
              value
            }
            ... on ProductionFacebook {
              username
            }
            ... on ProductionTwitch {
              username
            }
            ... on ProductionTwitter {
              username
            }
            ... on ProductionTiktok {
              username
            }
            ... on ProductionYoutube {
              username
              channelId
              videoCount
              customUrl
              channel {
                name
                additionalData
              }
            }
            ... on ProductionInstagram {
              username
              mediaCount
            }
          }
        }
        externalApproval
        rejectedReason
        reasonToBelieve
        audienceSimilarity
        campaign {
          id
          mediaTargets(mediaConfig: [{ allMetrics: true }])
            @include(if: $campaignsSeeSensitiveData) {
            id
            platform
          }
        }
        messagesCount {
          external
        }
      }
      meta {
        total
      }
    }
  }
`;
