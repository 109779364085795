import gql from 'graphql-tag';

const ExternalDeliverableSubscription = gql`
  subscription DeliverableByCampaign(
    $campaignId: Int!
    $executionStatusPhase: [DeliverableExecutionStatusPhaseFilter!]
  ) {
    externalDeliverableByCampaign(
      campaignId: $campaignId
      executionStatusPhase: $executionStatusPhase
    ) {
      id
      action
      by
      requestTimestamp
    }
  }
`;

export default ExternalDeliverableSubscription;
