import basePath from 'utils/basePath';
import React, { Suspense } from 'react';
import UILoader from 'components/UI/Loader/Loader';
import UIPageTitle from 'components/UI/PageTitle/PageTitle';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';

import './AccessAgreement.scss';

const AccessAgreement = React.lazy(
  () => import('components/User/AccessAgreement/AccessAgreement'),
);

function ScreensAccessAgreement() {
  return (
    <div className="access-agreement__wrapper">
      <DocumentTitle>Account Access Agreement</DocumentTitle>
      <UIPageTitle>Account Access Agreement</UIPageTitle>
      <div className="access-agreement">
        {/* eslint-disable max-len */}
        <p>
          As a registered user of the website and application owned by BENlabs,
          inc. located at&nbsp;
          <a
            href={`https://benlabs.com${basePath}`}
            className="link link-primary"
          >
            {`https://benlabs.com${basePath}`}
          </a>
          &nbsp;(&quot;Site&quot;), you agree to comply with the following terms
          and conditions, together with any documents they expressly incorporate
          by reference (&ldquo;Account Agreement&rdquo;).
          <br />
          <br />
          By clicking to agree to the Account Agreement when this option is made
          available to you,&nbsp; you accept and agree to be bound and abide by
          this Account Agreement, our Privacy Policy, found at&nbsp;
          <a
            href="https://benlabs.com/privacy-policy"
            className="link link-primary"
          >
            https://benlabs.com/privacy-policy
          </a>
          &nbsp; and Terms of Service{' '}
          <a
            href="https://benlabs.com/terms-of-use/"
            className="link link-primary"
          >
            https://benlabs.com/terms-of-use
          </a>{' '}
          incorporated herein by reference.
        </p>

        <h3>3rd Party APIs</h3>
        <p>
          By using this service and agreeing to this Account Access Agreement,
          you are also agreeing to be bound by the YouTube Terms of Service{' '}
          <br />(
          <a
            href="https://www.youtube.com/t/terms"
            className="link link-primary"
          >
            https://www.youtube.com/t/terms
          </a>
          ).
        </p>
        <Suspense fallback={<UILoader />}>
          <AccessAgreement />
        </Suspense>
      </div>
    </div>
  );
}

export default ScreensAccessAgreement;
