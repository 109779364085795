import UITag from 'components/UI/Tag/Tag';

import './TagCellRenderer.scss';

export default function TagCellRenderer({
  value,
}: Readonly<{ value: string[] }>) {
  return (
    <section className="tag-cell-renderer">
      {(value ?? []).map((tag) => (
        <UITag key={tag} theme="main">
          {tag}
        </UITag>
      ))}
    </section>
  );
}
