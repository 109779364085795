import SimpleCellRenderer from 'components/DataGrid/Destination/Cells/Simple/SimpleCellRenderer';

const baseColDef = {
  cellRenderer: SimpleCellRenderer,
  cellRendererParams: () => ({
    hideOverflow: true,
  }),
};

export default baseColDef;
