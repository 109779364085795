import svgSizeMapping, { SvgSize } from '../utils/svgSizeMapping';

export default function FacebookLogo({
  fill = 'none',
  size,
}: {
  fill?: string;
  size: SvgSize;
}) {
  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_932_9553)">
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.75 8.00002H14.25C13.9542 7.99878 13.6611 8.05613 13.3875 8.16877C13.114 8.2814 12.8654 8.44709 12.6563 8.65627C12.4471 8.86545 12.2814 9.11398 12.1688 9.38752C12.0561 9.66106 11.9988 9.9542 12 10.25L12 21"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 13H15"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_932_9553">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
