/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import UIIcon from 'components/UI/Icon/Icon';
import useClickOutside from 'components/UI/useClickOutside';
import ApplicationContext from 'components/Application/Context';
import ModeSwitcher from 'external/components/UI/ModeSwitcher/ModeSwitcher';
import './AppHeader.scss';

function ExternalAppHeader() {
  const { token, user, logout } = useContext(ApplicationContext);
  const navigate = useNavigate();
  const [isExpandedMobileMenu, setIsExpandedMobileMenu] = useState(false);
  const [isOpenHeaderDropdown, setIsOpenHeaderDropdown] = useState(false);

  const toggleMobileMenu = () => setIsExpandedMobileMenu(!isExpandedMobileMenu);
  const toggleDropdown = () => setIsOpenHeaderDropdown(!isOpenHeaderDropdown);

  const userName = user && `${user.firstName} ${user.lastName}`;
  const isAuthenticated = Boolean(token);
  const containerRef = useClickOutside(
    () => setIsOpenHeaderDropdown(false),
    !isOpenHeaderDropdown,
  );

  function onLogout() {
    logout();
    navigate('/user/login');
  }

  return (
    <header className="app-header print-hide">
      <div className="app-header__inner">
        <nav className="app-header__navigation">
          <div className="app-header__logo__wrapper">
            {token && <Link to="/campaigns" className="app-header__logo" />}
            <button
              type="button"
              className="app-header__mobile-menu__toggle"
              onClick={toggleMobileMenu}
            >
              <span
                className={classNames('app-header__mobile-menu__icon', {
                  'is-open': isExpandedMobileMenu,
                })}
              />
            </button>
          </div>

          <div
            className={classNames('app-header__navigation__inner', {
              'is-open': isExpandedMobileMenu,
            })}
          >
            {isAuthenticated && (
              <div className="external-header-actions-wrap">
                <a
                  href="mailto:benhelp@bengroup.com"
                  className="external-content__subsection__support__button"
                >
                  Need Help
                  <UIIcon type="help" />
                </a>
                <ul className="app-header__secondary-menu">
                  <li className="app-header__menu-item" ref={containerRef}>
                    <button
                      type="button"
                      className={classNames(
                        'app-header__menu-link',
                        'app-header__menu-link--dropdown',
                        {
                          'is-open': isOpenHeaderDropdown,
                        },
                      )}
                      onClick={toggleDropdown}
                    >
                      <UIIcon type="person" />
                    </button>
                    <ul
                      className={classNames('app-header__dropdown', {
                        'is-open': isOpenHeaderDropdown,
                      })}
                    >
                      <li className="app-header__dropdown-username">
                        {userName}
                      </li>
                      <div className="app-header__dropdown-item-divider" />
                      <ModeSwitcher className="app-header__dropdown-link" />
                      <div className="app-header__dropdown-item-divider" />
                      <li>
                        <Link
                          to="/user/profile"
                          className="app-header__dropdown-link"
                          onClick={toggleDropdown}
                        >
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/user/change-password"
                          className="app-header__dropdown-link"
                          onClick={toggleDropdown}
                        >
                          Change Password
                        </Link>
                      </li>
                      <li>
                        <a
                          className="app-header__dropdown-link"
                          onClick={onLogout}
                          rel="noopener noreferrer"
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default ExternalAppHeader;
