import twitchCommon from './helpers/twitchCommon';

const metric = 'clipViews';
export default function clipViews() {
  return {
    ...twitchCommon(metric),
    headerName: 'Clip Views',
    width: 185,
  };
}
