import { useContext } from 'react';
import ApplicationContext from 'components/Application/Context';
import { User } from 'components/User/types';

export default function useUser() {
  const { user, setUser, token } = useContext(ApplicationContext);

  function onUpdateUserSession(newInfo: User) {
    setUser({ ...user, ...newInfo });
  }

  return {
    isAuthenticated: Boolean(token),
    token,
    user,
    updateUserSession: onUpdateUserSession,
    setUser,
  };
}
