const addIsRequiredCheck = (validateMethod) => {
  const localValidateMethod = validateMethod;
  localValidateMethod.isRequired = (props, propName, componentName) => {
    const fieldMissing = !props[propName];
    if (fieldMissing) {
      return new Error(
        `Required "${propName}" was not specified in "${componentName}"`,
      );
    }

    return localValidateMethod(props, propName, componentName);
  };

  return localValidateMethod;
};

export default addIsRequiredCheck;
