import MetricType from 'shared/types/MetricType';
import { Platforms } from 'shared/types/platforms';
import { Production } from 'components/Production/types';
import LabelLink from '@UIComponents/LabelLink/LabelLink';
import ToggleIcon from '@UIComponents/ToggleIcon/ToggleIcon';
import PlatformIcon from '@UIComponents/PlatformIcon/PlatformIcon';
import metricPerPlatform from 'components/Creator/Productions/Card/utils/getMetricPerPlatform';

import './RowHeader.scss';

const metrics: { [index: string]: Array<MetricType | null> } = {
  tiktok: [
    'followers' as MetricType,
    'videos' as MetricType,
    'likes' as MetricType,
  ],
  twitch: [null, 'totalViews' as MetricType, null],
};
export default function RowHeader({
  production,
  handleCollapse,
  collapsed,
  trackChannelOpen,
}: {
  production: Production;
  handleCollapse: (currentCollapsed: boolean) => void;
  collapsed: boolean;
  trackChannelOpen: () => void;
}) {
  return (
    <nav className="creator-profile-card__header">
      <PlatformIcon
        platform={production?.type as Platforms}
        size="large"
        colorType="blackWhite"
      />
      {production?.url ? (
        <LabelLink
          className="creator-profile-card__header__channel-link"
          href={production?.url}
          label={production?.username}
          linkType="external"
          onClick={trackChannelOpen}
        />
      ) : (
        <span className="creator-profile-card__header__channel-link">--</span>
      )}
      <div className="creator-profile-card__header__main-metric">
        {metricPerPlatform(
          production,
          metrics?.[production?.type]?.[0] ?? ('followers' as MetricType),
          false,
        )}
      </div>
      <div className="creator-profile-card__header__main-metric">
        {metricPerPlatform(
          production,
          metrics?.[production?.type]?.[1] ?? ('averageViews' as MetricType),
          false,
        )}
      </div>
      <div className="creator-profile-card__header__main-metric header-engagement-rate">
        {metricPerPlatform(
          production,
          metrics?.[production?.type]?.[2] ??
            ('averageEngagementRate' as MetricType),
          false,
        )}
      </div>
      <ToggleIcon collapsed={collapsed} handleCollapse={handleCollapse} />
    </nav>
  );
}
