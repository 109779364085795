import React from 'react';
import { arrayOf, node, shape, oneOfType } from 'prop-types';
import { components } from 'react-select';
import './MenuList.scss';

const { MenuList } = components;

const cbn = 'form-selector-menu-list';

function FormSelectorMenuList({ children, selectProps, ...restProps }) {
  return (
    <MenuList selectProps={selectProps} {...restProps}>
      {children}
      {selectProps.hasMore && !selectProps.isLoading && (
        <button
          className={`${cbn}__load-more-button`}
          type="button"
          onClick={selectProps.loadMore}
        >
          Load More...
        </button>
      )}
      {selectProps.addNew && (
        <button
          className={`${cbn}__add-new-button`}
          type="button"
          onClick={selectProps.addNew}
        >
          + Add new
        </button>
      )}
    </MenuList>
  );
}

FormSelectorMenuList.propTypes = {
  selectProps: shape({}).isRequired,
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

export default FormSelectorMenuList;
