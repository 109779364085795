import { EXTERNAL_APPROVAL_STATUSES } from 'constants/approvalStatuses';

const { REJECTED, APPROVED } = EXTERNAL_APPROVAL_STATUSES;

type externalApprovalType = string | undefined | null;

const externalApprovalStatuses = (value: externalApprovalType) => {
  switch (value) {
    case APPROVED:
      return 'Submitted';
    case REJECTED:
      return 'Declined';
    default:
      return 'For Review';
  }
};

export const getDeliverableExternalApprovalStatus = (
  externalApproval: externalApprovalType,
) => ({
  state: externalApproval ? externalApproval.toLowerCase() : 'pending',
  text: externalApprovalStatuses(externalApproval),
});
