import React from 'react';
import {
  bool,
  oneOfType,
  node,
  arrayOf,
  string,
  oneOf,
  shape,
  number,
} from 'prop-types';
import classnames from 'classnames';

import './Group.scss';

function FormGroup({
  align,
  grow,
  children,
  className,
  container,
  inline,
  noLabel,
  noMargin,
  noPadding,
  pullRight,
  size,
  style,
  wrapper,
}) {
  return (
    <div
      style={style}
      className={classnames('form-group', className, {
        [`form-group--${size}`]: size,
        'form-group--inline': inline && !wrapper,
        'form-group--wrapper': !inline && wrapper,
        'form-group--inline-wrapper': inline && wrapper,
        'form-group--container': container,
        'form-group--pull-right': pullRight,
        'form-group--no-margin': noMargin,
        'form-group--no-padding': noPadding,
        'form-group--no-label': noLabel,
        [`form-group--align-${align}`]: align,
        [`form-group--grow-${grow}`]: grow,
      })}
    >
      {children}
    </div>
  );
}

FormGroup.propTypes = {
  align: oneOf(['top', 'middle', 'bottom']),
  grow: number,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  className: string,
  container: bool,
  inline: bool,
  noLabel: bool,
  noMargin: bool,
  noPadding: bool,
  pullRight: bool,
  size: string,
  style: shape({}),
  wrapper: bool,
};

FormGroup.defaultProps = {
  align: undefined,
  grow: 0,
  className: null,
  container: false,
  inline: false,
  noLabel: false,
  noMargin: false,
  noPadding: false,
  pullRight: false,
  size: 'fluid',
  style: {},
  wrapper: false,
};

export default FormGroup;
