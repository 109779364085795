import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import UIIcon from 'components/UI/Icon/Icon';
import './ClipboardCopy.scss';

const CLEAR_COPIED_STATE_TIMEOUT = 5000;

export default function ClipboardCopy({
  children,
  className,
  copyReadyTooltip,
  onCopyTooltip,
  value,
  size,
}: {
  children?: React.ReactNode;
  className?: string;
  copyReadyTooltip?: string;
  onCopyTooltip?: string;
  value?: string | number;
  size?: string;
}) {
  const [copied, setCopied] = useState(false);

  const onCopy = React.useCallback(() => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, CLEAR_COPIED_STATE_TIMEOUT);
  }, []);

  const tooltip = copied
    ? onCopyTooltip ?? 'ID copied to clipboard!'
    : copyReadyTooltip ?? 'Copy to clipboard';

  return (
    <CopyToClipboard
      className={className ?? 'clipboard-copy'}
      text={value}
      onCopy={onCopy}
    >
      <span>
        {children !== undefined ? (
          children
        ) : (
          <UIIcon
            className="ui-clipboard-copy"
            type="content_copy"
            size={size ?? 'small'}
            tooltip={tooltip}
          />
        )}
      </span>
    </CopyToClipboard>
  );
}
