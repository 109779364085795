import React from 'react';
import { Property } from 'shared/types/Property';
import formatDate from 'shared/dates/formatDate';
import { TCMAuthorizedMediaInsights } from 'shared/types/TikTokMarketPlace';
import getThumbnailFromProductions from 'shared/utils/productions/getThumbnailFromProductions';
import { SegmentEvents } from '../types';
import Avatar from '../../../../Avatar/Avatar';
import LabelLink from '../../../../LabelLink/LabelLink';
import CopyIcon from '../../../../_assets/react_svg/CopyIcon';

import ClipboardCopyLabel from '../../../../ClipboardCopyLabel/ClipboardCopyLabel';

import './Header.scss';

export default function Header({
  property,
  currentPost,
  segmentEvents,
}: {
  property?: Property;
  currentPost: TCMAuthorizedMediaInsights;
  segmentEvents?: SegmentEvents | null;
}) {
  const [imageError, setImageError] = React.useState(false);

  return (
    <section className="tiktok-video-content__header">
      <Avatar
        alt="avatar"
        name="avatar"
        size="medium"
        imageError={imageError}
        setImageError={setImageError}
        thumb={getThumbnailFromProductions(property?.productions ?? [])}
      />

      <div className="tiktok-video-content__header__info">
        <h1>Content ID #: {currentPost?.video_id}</h1>
        <LabelLink
          label={currentPost?.display_name ?? ''}
          href={`https://www.tiktok.com/@${currentPost?.display_name}`}
          linkType="external"
          onClick={segmentEvents?.trackHandleNameClick}
        />

        {currentPost?.video_id ? (
          <ClipboardCopyLabel
            onClick={segmentEvents?.trackContentIdCopyToClipboard}
            copyValue={currentPost?.video_id}
            label={<CopyIcon size="x-small" stroke="#8F8F8F" />}
          />
        ) : null}
      </div>

      {currentPost?.create_time ? (
        <p className="tiktok-video-content__header__live-date">
          Live Date:{' '}
          {formatDate(
            new Date(Number(currentPost?.create_time) * 1000).toString(),
          )}
        </p>
      ) : null}
    </section>
  );
}
