import LinkButton from 'components/Creator/LinkButton/LinkButton';
import ClipboardCopyLabel from '@UIComponents/ClipboardCopyLabel/ClipboardCopyLabel';
import { Platforms } from 'shared/types/platforms';
import './UnauthorizedWarning.scss';

function getWarningText(platform: keyof typeof Platforms, type: string) {
  if (platform === 'instagram' && type === 'stories') {
    return 'Sorry, this creator isn’t authorized yet. To view Stories and audience stats authorize this creator.';
  }

  return 'Sorry, this creator isn’t authorized yet. To view audience stats authorize this creator. ';
}

export default function UnauthorizedWarning({
  className,
  platform,
  type,
}: {
  className?: string;
  platform?: keyof typeof Platforms;
  type?: string;
}) {
  return (
    <div className={`unauthorized-warning ${className ?? ''}`}>
      <p>{getWarningText(platform, type)}</p>

      <ClipboardCopyLabel
        copyValue="https://www.benlabs.com/social-reporting/"
        label={<LinkButton label="Copy link" />}
      />
    </div>
  );
}
