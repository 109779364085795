import { useState } from 'react';
import useShortcuts from 'utils/shortcuts/useShortcuts';

export default function useModal(initialOpenState = false) {
  const [isOpen, setIsOpen] = useState(initialOpenState);

  function close() {
    setIsOpen(false);
  }

  function open() {
    setIsOpen(true);
  }

  useShortcuts([['Esc', close]], !isOpen);

  return {
    isOpen,
    open,
    close,
  };
}
