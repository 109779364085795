import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Formik, Form } from 'formik';
import usePermissions from 'store/authentication/usePermissions';
import PropertyUpdate from 'components/Creator/graphql/PropertyUpdate.graphql';
import { Property, PrimaryContact } from 'components/Property/types';
import UILoader from 'components/UI/Loader/Loader';
import EmailInput from 'components/Creator/Profile/Details/PropertyForm/EmailInput/EmailInput';
import PeopleQuery from 'components/Creator/Profile/Details/PropertyForm/graphql/PeopleQuery.graphql';
import PeopleMutation from 'components/Creator/Profile/Details/PropertyForm/graphql/PeopleMutation.graphql';

export default function PropertyForm({
  property,
  refetchProperty,
}: Readonly<{
  property?: Property;
  refetchProperty: () => void;
}>) {
  const apolloClient = useApolloClient();
  const personsPermissions = usePermissions('persons');

  const [loading, setLoading] = React.useState(false);

  const fetchExistingPerson = React.useCallback(
    /* @ts-ignore */
    async (email) => {
      if (!email) {
        return null;
      }
      const response = await apolloClient.query({
        query: PeopleQuery,
        variables: {
          filter: { query: email },
          personsSeeSensitiveData: Boolean(
            personsPermissions?.seeSensitiveData,
          ),
        },
      });

      const existingEmail = (response?.data?.persons?.data ?? []).find(
        (person: PrimaryContact) => person?.email === email,
      );

      if (!existingEmail?.id) {
        return null;
      }
      return { id: existingEmail.id };
    },
    [apolloClient, personsPermissions?.seeSensitiveData],
  );

  const createPerson = React.useCallback(
    /* @ts-ignore */
    async (email) => {
      if (!email) {
        return null;
      }
      const response = await apolloClient.mutate({
        mutation: PeopleMutation,
        variables: {
          payload: { email },
        },
      });

      if (response?.data?.createPerson?.id) {
        return { id: response.data.createPerson.id };
      }

      return null;
    },
    [apolloClient],
  );

  const updateProperty = React.useCallback(
    /* @ts-ignore */
    async (propertyToSave) => {
      if (!property?.id) {
        return null;
      }

      return apolloClient.mutate({
        mutation: PropertyUpdate,
        variables: propertyToSave,
      });
    },
    [apolloClient, property?.id],
  );

  const onSubmit = React.useCallback(
    async (formProperty: Property) => {
      if (!formProperty?.id) {
        return;
      }
      setLoading(true);
      const propertyToSave: { id: number; payload: Property } = {
        id: formProperty.id,
        payload: {},
      };
      const email = formProperty?.primaryContact?.email;
      if (email) {
        let existingPerson = await fetchExistingPerson(email);
        if (!existingPerson) {
          existingPerson = await createPerson(email);
        }
        if (existingPerson) {
          propertyToSave.payload.primaryContact = existingPerson;
        }
      }
      if (!Object.keys(propertyToSave?.payload ?? {}).length) {
        return;
      }
      await updateProperty(propertyToSave);
      refetchProperty();
      setLoading(false);
    },
    [updateProperty, createPerson, fetchExistingPerson, refetchProperty],
  );

  const validate = (values: Property) => {
    const errors: { [index: string]: unknown } = {};
    if (
      values?.primaryContact?.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        values?.primaryContact?.email,
      )
    ) {
      errors.primaryContact = 'Invalid email address';
    }
    return errors;
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={property ?? {}}
      className="add-new-metric"
      validate={validate}
    >
      {({ errors }) => (
        // @ts-ignore
        <Form className="form-container">
          <p>Primary Contact</p>
          <EmailInput />

          <button
            type="submit"
            className="save-changes-button"
            disabled={loading || Boolean(Object.keys(errors ?? {}).length)}
          >
            {loading ? <UILoader size="small" /> : 'Save Changes'}
          </button>
        </Form>
      )}
    </Formik>
  );
}
