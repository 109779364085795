import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import {
  INSTAGRAM_PLATFORM,
  TIKTOK_PLATFORM,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/metrics';
import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';
import { Placement } from 'shared/types/Placement';
import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import getTikTokDataFromInsights from 'components/Campaign/Influencer/View/Stats/Performance/columns/tikTok/helpers/getTikTokDataFromInsight';
import getYouTubeDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/youTube/helpers/getYouTubeDataFromCampaignService';
import getInstagramDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/instagram/helpers/getInstagramDataFromCampaignService';

const metric = 'reach';
export function likesAutomatedValueGetter(data: Placement) {
  if (data?.platform === TIKTOK_PLATFORM) {
    const tikTokMetric = 'fct_lifetime_video_performance.num_reach' as keyof typeof TCMReportMetrics;
    return getTikTokDataFromInsights(data, tikTokMetric);
  }
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric);
  }
  if (data?.platform === INSTAGRAM_PLATFORM) {
    return getInstagramDataFromCampaignService(data, metric);
  }
  return null;
}

export default function reach() {
  return {
    ...numberColDef,
    ...basePerformanceColDef(metric, likesAutomatedValueGetter),
    headerName: 'Reach',
  };
}
