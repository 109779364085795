import { TCMInsight } from 'shared/types/TikTokMarketPlace';
import Audience from '../Audience/Audience';
import BasicStats from '../BasicStats/BasicStats';
import ReportingWindowSelector from '../../../../../ReportingWindowSelector/ReportingWindowSelector';

const reportingWindowOptions = [
  { value: '1_days', label: '1 day' },
  { value: '7_days', label: '7 days' },
  { value: '14_days', label: '14 days' },
  { value: '30_days', label: '30 days' },
  { value: 'all_time', label: 'All time', defaultSelected: true },
];

export default function Insight({
  insight,
  onReportingWindowChange,
}: {
  insight: TCMInsight;
  onReportingWindowChange: (value: string) => void;
}) {
  return (
    <section
      key={insight?.dimensions?.video_id}
      className="tiktok-video-content__metrics"
    >
      <div className="tiktok-video-content__metrics__header">
        <p className="tiktok-video-content__metrics__header__update-frequency">
          Stats will update 1x each day between midnight and 2am UTC.
        </p>
        <ReportingWindowSelector
          options={reportingWindowOptions}
          onChange={onReportingWindowChange}
        />
      </div>
      <BasicStats metrics={insight?.metrics} />
      <Audience metrics={insight?.metrics} />
    </section>
  );
}
