import React from 'react';
import './ReportingWindowSelector.scss';

export default function ReportingWindowSelector({
  options,
  onChange,
}: {
  options: { label: string; value: string; defaultSelected?: boolean }[];
  onChange: (selected: string) => void;
}) {
  const [selected, setSelected] = React.useState(null);

  const onClick = React.useCallback(
    /* @ts-ignore */
    (ev) => {
      setSelected(ev?.target?.name);
      onChange(ev?.target?.name);
    },
    [onChange],
  );

  return (
    <div className="reporting-window-selector">
      {options.map(({ value, label, defaultSelected }) => (
        <button
          key={label}
          className={`${
            (!selected && defaultSelected) || selected === value
              ? 'selected'
              : ''
          } selector-option`}
          type="button"
          name={value}
          onClick={onClick}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
