import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';

import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import tikTokCommon from './helpers/tikTokCommon';

export default function videocompletionrate() {
  const tikTokMetric = 'fct_lifetime_video_performance.video_completion_rate' as keyof typeof TCMReportMetrics;
  const metric = 'video_completion_rate';
  return {
    ...percentColDef,
    ...tikTokCommon(tikTokMetric, metric),
    field: 'videocompletionrate',
    headerName: 'Video completion rate',
    width: 265,
  };
}
