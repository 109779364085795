import {
  INSTAGRAM_PLATFORM,
  STORY_MEDIA_TYPE,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/kpis';
import { Placement } from 'shared/types/Placement';
import { QmMetrics } from 'shared/types/Production';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';

import {
  EUREKA_IG_CTR_INFERENCE,
  EUREKA_IG_VIEWS_INFERENCE,
  YT_CLICKS_INFERENCE,
} from '../../../shared/columnPresets/qmMetricUtils/constants';

export default function aiClicks() {
  return {
    ...baseColDef,
    field: 'aiClicks',
    headerName: 'AI clicks',
    valueGetter: ({ data }: { data: Placement }) => {
      const productionType = data?.integration?.production?.type;
      const qmMetricAttributes = (data?.integration?.production
        ?.qmMetrics as QmMetrics[])?.[0]?.attributes;

      if (productionType === YOUTUBE_PLATFORM) {
        const clicksValue =
          qmMetricAttributes?.[YT_CLICKS_INFERENCE]?.values?.predicted_clicks?.[
            '-1'
          ];

        return clicksValue !== -1 ? clicksValue : null;
      }
      if (
        productionType === INSTAGRAM_PLATFORM &&
        data?.mediaType === STORY_MEDIA_TYPE
      ) {
        const brandName = data?.brand?.name ?? 'other';

        const views =
          qmMetricAttributes?.[EUREKA_IG_VIEWS_INFERENCE]?.values
            ?.story_view_prediction;
        const ctr =
          qmMetricAttributes?.[EUREKA_IG_CTR_INFERENCE]?.values?.[brandName];

        return Number(views) * Number(ctr);
      }
      return null;
    },
  };
}
