import { Deliverable } from 'components/Deliverable/types';
import { Placement } from 'components/Placement/types';
import { getInitials } from '../../../../../utils/getInitials';
import { IInitialsItem, InitialsMap } from '../types';

export const defaultColor = '#5246ae';

export const initialsDefault: IInitialsItem = {
  letters: '-',
  color: defaultColor,
};

const mapColors = {
  '[A-Da-d]': '#295FCC',
  '[E-Ke-k]': '#5F29CC',
  '[L-Pl-p]': '#9629CC',
  '[Q-Tq-t]': '#CC2996',
  '[U-Zu-z]': '#006980',
};

function getColorByLetter(letter: string): string {
  const [, color] = Object.entries(mapColors).find(([key]) =>
    key.match(letter),
  ) || [null, defaultColor];

  return color;
}

export function getInitialsByName(name: string): IInitialsItem {
  const letters = getInitials(name) || '';

  const color = getColorByLetter(letters.substring(0, 1));

  const initials: IInitialsItem = {
    letters,
    color,
  };

  return initials;
}

export function getInitialsByDeliverable(item?: Deliverable): IInitialsItem {
  const name = item?.property?.name || '';

  return getInitialsByName(name);
}

export function getInitialsByPlacement(item: Placement): IInitialsItem {
  const name =
    (item.integration?.deliverable ?? item.source?.production)?.property
      ?.name || '';

  return getInitialsByName(name);
}

export function getInitialsMapByDeliverable(
  deliverablesList: Deliverable[],
): InitialsMap {
  return new Map(
    deliverablesList.map((item: Deliverable) => {
      const initials = getInitialsByDeliverable(item);

      return [item.id, initials];
    }),
  );
}

export function getInitialsMapByPlacement(
  deliverablesList: Placement[],
): InitialsMap {
  return new Map(
    deliverablesList.map((item: Placement) => {
      const initials = getInitialsByPlacement(item);

      return [item.id || 0, initials];
    }),
  );
}
