import React from 'react';
import { arrayOf, func, node, number, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import UIImage from 'components/UI/Image/Image';
import UIIcon from 'components/UI/Icon/Icon';
import UILink from 'components/UI/Link/Link';
import ColoredInitials from 'external/components/UI/ColoredInitials/ColoredInitials';
import { IInitialsItem } from 'external/components/UI/ColoredInitials/types';

import './Panel.scss';

type ExternalPropertyPanelProps = {
  children: React.ReactNode;
  city: string;
  className: string;
  country: string;
  metadata: (number | string)[];
  name?: string;
  onClickClosePanel: () => void;
  internalLink: string;
  productionLink?: string;
  thumb: string;
  onClickProductionUrl?: () => void;
  initials?: IInitialsItem;
};

function ExternalPropertyPanel({
  children,
  className,
  country,
  metadata,
  name,
  onClickClosePanel,
  productionLink,
  thumb,
  onClickProductionUrl,
  initials,
}: ExternalPropertyPanelProps) {
  return (
    <div className={classnames('external-property-panel', className)}>
      <div className="external-property-panel__content">
        <button
          className="external-property-panel__close"
          onClick={onClickClosePanel}
          type="button"
        >
          <span className="icon-close" />
        </button>

        {/*
      TODO: Can it be removed?
      internalLink && (
        <div className="external-property-panel__open__internal__wrapper">
          <UILink
            href={internalLink}
            target="_blank"
            className="external-property-panel__internal-link"
          >
            <UIIcon
              icon="exit_to_app"
              tooltip="Open in internal app"
              size="mid-small"
            />
          </UILink>
        </div>
      ) */}

        <div className="external-property-panel__profile-image__wrapper">
          {thumb ? (
            <UIImage
              alt="ben-thumbnail"
              className="external-property-panel__profile-image"
              fallbackImage="placeholderImage"
              imagecachePreset="medium"
              url={thumb}
            />
          ) : (
            <ColoredInitials initials={initials} />
          )}
          {productionLink && (
            <div
              className="external-property-panel__production-link__wrapper"
              onClick={() => onClickProductionUrl && onClickProductionUrl()}
            >
              <UILink
                href={productionLink}
                target="_blank"
                className="external-property-panel__production-link"
              >
                <UIIcon icon="open_in_new" size="mid-small" />
              </UILink>
            </div>
          )}
        </div>

        <div className="external-property-panel__profile-info__wrapper">
          <h2 className="external-property-panel__profile-info__name">
            {name}
          </h2>

          <h3 className="external-property-panel__profile-info__metadata">
            <span>{metadata.join(' | ')}</span>
            {!!country && <div>{country}</div>}
          </h3>

          {children}
        </div>
      </div>
    </div>
  );
}

ExternalPropertyPanel.propTypes = {
  children: node,
  city: string,
  className: string,
  country: string,
  internalLink: string,
  metadata: arrayOf(oneOfType([number, string])),
  name: string,
  onClickClosePanel: func,
  productionLink: string,
  thumb: string,
};

ExternalPropertyPanel.defaultProps = {
  children: null,
  city: '',
  className: '',
  country: '',
  metadata: [],
  name: '',
  onClickClosePanel: null,
  thumb: '',
};

export default ExternalPropertyPanel;
