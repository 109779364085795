// @ts-nocheck
import { useMemo } from 'react';
import FileSaver from 'file-saver';
import S3Upload from 'react-s3-uploader/s3upload';
import sanitize from 'utils/sanitize';
import { updateIn } from 'utils/array';
import usePreventNavigation from 'utils/usePreventNavigation';
import useLatest from 'utils/useLatest';
import useMapState from 'utils/useMapState';
import { downloadFromAPI, urlIsValid } from 'components/utils/file';
import { mapFile } from './utils';
import {
  SignS3Brand,
  SignS3Campaign,
  SignS3Client,
  SignS3Deliverable,
  SignS3Placement,
  SignS3Production,
  SignS3AsyncImport,
} from './graphql';

const signin = {
  brand: SignS3Brand,
  campaign: SignS3Campaign,
  client: SignS3Client,
  deliverable: SignS3Deliverable,
  placement: SignS3Placement,
  production: SignS3Production,
  asyncImport: SignS3AsyncImport,
};

function downloadBlob(file) {
  FileSaver.saveAs(file, file.filename);
}
const isProcessing = (file, errors) => !urlIsValid(file) && !errors[file.url];
const didFinishUpload = (files) => files?.every(urlIsValid);
const normalize = (files) =>
  Array.isArray(files) ? files.map(mapFile) : [mapFile(files)];
const nextValue = (isMulti, value, files) =>
  isMulti && value ? [...value, ...normalize(files)] : normalize(files);

const PREVENT_NAV_MSG =
  'Upload is still in progress, if you leave now pending uploads will be lost.';

export default function useS3({
  apolloClient,
  isPrivate,
  isMulti,
  onChange,
  resourceId,
  resourceType,
  value,
  onUploadStart,
  onUploadComplete,
}) {
  const latestValueRef = useLatest(value);
  const [preventNavigation, allowNavigation] = usePreventNavigation(
    PREVENT_NAV_MSG,
  );
  const [cache, setCache] = useMapState();
  const [downloading, setDownloading] = useMapState();
  const [progress, setProgress] = useMapState();
  const [errors, setErrors] = useMapState();

  const pending = useMemo(
    () => value?.some((file) => isProcessing(file, errors)),
    [value, errors],
  );

  function onFinish(info, file) {
    setCache(info.url, file);
    const finder = (item) => item.url === file.preview;
    const files = updateIn(latestValueRef.current, finder, (item) => ({
      ...item,
      url: info.url,
      filename: info.name,
    }));

    if (didFinishUpload(files)) {
      onUploadComplete(files);
      allowNavigation();
    }

    onChange(files);
  }

  function onError(status, file) {
    const url = file.preview || file.url;
    setProgress(url, undefined);
    setErrors(url, status);
  }

  async function getSignedUrl(file, cb) {
    try {
      const result = await apolloClient.mutate({
        mutation: signin[resourceType],
        variables: {
          resourceId,
          payload: {
            isPrivate,
            fileName: file.name,
            contentType: file.type,
          },
        },
      });

      const [response] = Object.values(result.data);
      cb(response);
    } catch (error) {
      const message = error.networkError ? 'Network Error' : error.message;
      onError(message, file);
    }
  }

  /* handlers */
  function onDrop(files, isFromUrl) {
    // if we copy the File, we loose some required props
    // so, we need to change the same instance
    files.forEach((f) => {
      Object.defineProperty(f, 'name', {
        writable: true,
        value: sanitize(f.name ?? ''),
      });
      try {
        // eslint-disable-next-line no-param-reassign
        f.preview = URL.createObjectURL(f);
      } catch (e) {
        return f;
      }
      return f;
    });
    onUploadStart(files, isFromUrl);
    onChange(nextValue(isMulti, value, files));

    if (isFromUrl) {
      return null;
    }

    preventNavigation();

    return new S3Upload({
      files,
      onProgress: (percent, status, file) => setProgress(file.preview, percent),
      onFinishS3Put: onFinish,
      onError,
      uploadRequestHeaders: {},
      getSignedUrl,
    });
  }

  async function onDownload(event, fileObject) {
    const file = event?.url ? event : fileObject;
    if (cache[file.url]) {
      return downloadBlob(cache[file.url]);
    }

    setDownloading(file.id, true);

    await downloadFromAPI(
      resourceType,
      resourceId,
      file.id,
      file.filename,
      isPrivate,
    );

    setDownloading(file.id, false);
    return null;
  }

  return {
    downloading,
    errors,
    onDownload,
    onDrop,
    onError,
    pending,
    progress,
  };
}
