import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ApplicationContext from 'components/Application/Context';
import { getDefaultLocation } from 'utils/auth';

const isFunction = (value: string | Function) =>
  Boolean(typeof value === 'function');

type RouteRedirectProps = {
  to?: string | Function;
  getLocation?: Function;
  replace?: boolean;
};
function RouteRedirect({
  to,
  getLocation = getDefaultLocation,
  ...restProps
}: RouteRedirectProps) {
  const { user, permissions } = useContext(ApplicationContext);
  const defaultLocation = getLocation(user);
  const { search } = useLocation();

  return (
    <Navigate
      {...restProps}
      replace
      to={
        isFunction(to)
          ? (to as Function)({ defaultLocation, permissions })
          : `${to}${search}`
      }
    />
  );
}

export default RouteRedirect;
