// @ts-nocheck
/* eslint-disable max-lines */
import React, { useLayoutEffect, useContext, useCallback } from 'react';
import { FieldValidator, getIn, useFormikContext } from 'formik';
import AdvancedFiltersContext from 'components/AdvancedFilters/Context';
import AdvancedFiltersSectionContext from 'components/AdvancedFilters/Section/Context';
import FormInputGroup from 'components/Form/Input/Group/Group';
import FormSectionContext from 'components/Form/Section/Context';
import FormTableOfContentsContext from 'components/Form/TableOfContents/Context';
import InputText from 'components/Form/Input/Text/Text';
import UIErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import UIFormLabel from 'components/UI/Form/Label/Label';
import useDebounce from 'utils/useDebounce';
import { toArray } from 'utils/function';
import UIGridRow from 'components/UI/Grid/Row/Row';
import UIGridGroup from 'components/UI/Grid/Group/Group';
import ClipboardCopy from 'components/UI/ClipboardCopy/ClipboardCopy';
import { getFakeEvent, hasError } from './utils';

type RenderProps<InputElementType extends HTMLElement> = Partial<{
  required: boolean;
  name;
  value: any;
  inputRef: React.Ref<InputElementType>;
  onBlur: (event: React.FocusEvent) => void;
  onChange: (event: React.ChangeEvent, selected?: [] | {}) => void;
  onFocus: (event: React.FocusEvent) => void;
  label: string;
  error: string | unknown;
  debounce: boolean;
  type: string;
  [x: string]: any;
}>;

type SetFieldValueFn = (fieldName: string, value) => void;

type FormFieldProps<InputElementType extends HTMLElement> = WithChildren<{
  addon?: string;
  component?: React.FC<RenderProps>;
  disabled?: boolean;
  highlightAsError?: boolean;
  icon?: string;
  inputRef?: React.Ref<InputElementType>;
  label?: string;
  name: string;
  onBlur?: (event: React.FocusEvent) => void;
  onChange?: (
    event: React.ChangeEvent,
    value: any,
    setFieldValue: SetFieldValueFn,
    values: object,
  ) => void;
  onFocus?: (event: React.FocusEvent) => void;
  render?: (props: RenderProps) => JSX.Element;
  required?: boolean;
  shouldDisplayLabel?: boolean;
  showErrors?: boolean;
  tooltip?: string;
  type?: string;
  validate?: FieldValidator;
  value?: any;
  [x: string]: any; // restProps
}>;

// eslint-disable-next-line max-statements
function FormField({
  addon,
  component: InputComponent,
  highlightAsError,
  icon,
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  render,
  required,
  showErrors,
  tooltip,
  type,
  validate,
  value,
  shouldDisplayLabel: parentShouldDisplayLabel,
  showCopyValue,
  ...props
}: FormFieldProps) {
  const formik = useFormikContext<{}>();
  const advancedFilters = useContext(AdvancedFiltersContext);
  const advancedFiltersSection = useContext(AdvancedFiltersSectionContext);
  const debouncedValidation = useDebounce(formik.validateForm, 300);

  const formTableOfContents = useContext(FormTableOfContentsContext);
  const formSection = useContext(FormSectionContext);

  useLayoutEffect(() => {
    if (name) {
      advancedFilters.registerField(name, advancedFiltersSection.name);
      formTableOfContents.registerField(name, formSection.name);
      formik.registerField(name, {
        validate,
      });
    }
    return () => {
      if (name) {
        advancedFilters.unregisterField(name);
        formTableOfContents.unregisterField(name);
        formik.unregisterField(name);
      }
    };
  }, [name, validate]); // eslint-disable-line react-hooks/exhaustive-deps

  const toEvent = useCallback(
    (ev: any, selected?: any) =>
      (ev?.target ? ev : getFakeEvent(name, ev, selected)),
    [name],
  );

  const handleChange = useCallback(
    (ev, selected) => {
      const event = toEvent(ev, selected);
      const { value: nvalue } = event.target;

      const setFieldValue = (n, v) => {
        formik.setFieldValue(n, v, false);
        // validations should be debounced, otherwise formik validates over outdated values.
        debouncedValidation();
      };

      setFieldValue(name, nvalue);
      onChange(event, nvalue, setFieldValue, formik.values);
    },
    [name, onChange, formik.setFieldValue], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleFocus = useCallback(
    (ev) => onFocus && onFocus(toEvent(ev)),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleBlur = useCallback(
    (ev) => {
      formik.setFieldTouched(name, true);
      return onBlur && onBlur(toEvent(ev));
    },
    [name], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { errors, values } = formik;

  const error = errors && getIn(errors, name);
  const normalizedValue =
    type === 'radio' || type === 'checkbox' ? value : getIn(values, name);

  const getShouldDisplayLabel = () =>
    (parentShouldDisplayLabel !== undefined
      ? parentShouldDisplayLabel
      : label && !advancedFilters.hasProvider);

  // if we are inside advanced filters, ignore label
  const shouldDisplayLabel = getShouldDisplayLabel();

  const extraProps = {};

  if (typeof InputComponent !== 'string') {
    extraProps.debounce = true;
    extraProps.inputRef = inputRef;
  }

  const field = render ? (
    render({
      ...props,
      required,
      name,
      value: normalizedValue,
      inputRef,
      onChange: handleChange,
      onBlur: handleBlur,
      onFocus: handleFocus,
      label,
      error,
      debounce: true,
    })
  ) : (
    <InputComponent
      {...props}
      {...extraProps}
      type={type}
      name={name}
      value={normalizedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      error={error || highlightAsError}
      required={required}
      label={label}
      tooltip={tooltip}
    />
  );

  return (
    <>
      {shouldDisplayLabel && (
        <UIGridRow>
          <UIGridGroup>
            <UIFormLabel required={required} tooltip={tooltip}>
              {label}
            </UIFormLabel>
            {showCopyValue === true && field.props.value && (
              <ClipboardCopy
                value={field.props.value}
                onCopyTooltip="URL copied to clipboard!"
                copyReadyTooltip="Copy URL to clipboard"
              />
            )}
          </UIGridGroup>
        </UIGridRow>
      )}
      {icon || addon ? (
        <FormInputGroup icon={icon} addon={addon}>
          {field}
        </FormInputGroup>
      ) : (
        field
      )}
      {showErrors &&
        hasError(error) &&
        toArray(error).map((e, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <UIErrorMessage key={i}>{e.toString()}</UIErrorMessage>
        ))}
    </>
  );
}

FormField.defaultProps = {
  addon: null,
  component: InputText,
  disabled: undefined,
  highlightAsError: null,
  icon: null,
  inputRef: undefined,
  label: undefined,
  name: undefined,
  onBlur() {},
  onChange() {},
  onFocus() {},
  render: null,
  required: false,
  shouldDisplayLabel: undefined,
  showErrors: true,
  tooltip: null,
  type: undefined,
  validate: undefined,
  value: undefined,
};

export { SetFieldValueFn };

export default FormField;
