import React, { useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { toArray } from 'utils/array';
import { stripTypenames } from 'utils/graphql';
import Dropzone from './Dropzone/Dropzone';
import DefaultFileList from './FileList/FileList';
import DefaultFile from './File/File';
import MediaFileList, {
  FormFileUploaderMediaFileListProps,
} from './MediaFileList/MediaFileList';
import MediaFile from './MediaFile/MediaFile';
import { FileType, MediaFileComponentProps, ValueType } from './types';

import useS3 from './useS3';

import './FileUploader.scss';

type FormFileUploaderProps = {
  acceptMessage: string;
  acceptMime: string;
  axis: 'x' | 'y' | 'xy';
  className: string;
  components: {
    File: React.Component<MediaFileComponentProps>;
    FileList: React.Component<FormFileUploaderMediaFileListProps>;
  };
  dropzoneText?: string;
  disabled: boolean;
  disablePreview: boolean;
  disableSort: boolean;
  disableFileList?: boolean;
  hasDragHandle: boolean;
  isMulti: boolean;
  isPrivate: boolean;
  nonPlayable: boolean;
  onChange: (value: ValueType) => void;
  onClick: (event: React.MouseEvent) => void;
  onRemove?: (value?: ValueType) => void;
  onUploadComplete: (value: ValueType) => void | Promise<void>;
  onUploadStart: (files: FileType[], isFromUrl: boolean) => void;
  resourceId: number;
  resourceType: string;
  showLinkButton: boolean;
  value: FileType | FileType[] | null;
};

function FormFileUploader({
  acceptMessage,
  acceptMime,
  axis,
  className,
  components,
  dropzoneText,
  disabled,
  disablePreview,
  disableSort,
  disableFileList,
  hasDragHandle,
  isMulti,
  isPrivate,
  nonPlayable,
  onChange: parentOnChange,
  onClick,
  onRemove,
  onUploadComplete,
  onUploadStart,
  resourceId,
  resourceType,
  showLinkButton,
  value: parentValue,
}: Readonly<FormFileUploaderProps>) {
  const apolloClient = useApolloClient();

  const value = useMemo(() => toArray(parentValue), [parentValue]);

  const onChange = (nvalue: ValueType) => {
    const strippedValue = stripTypenames(nvalue);

    parentOnChange(isMulti ? strippedValue : strippedValue[0]);
  };

  const { downloading, errors, onDownload, onDrop, onError, progress } = useS3({
    apolloClient,
    isMulti,
    isPrivate,
    onChange,
    onUploadComplete,
    onUploadStart,
    resourceId,
    resourceType,
    value,
  });

  return (
    <Dropzone
      acceptMessage={acceptMessage}
      acceptMime={acceptMime}
      axis={axis}
      className={className}
      components={components}
      disabled={disabled}
      disablePreview={disablePreview}
      disableSort={disableSort}
      disableFileList={disableFileList}
      downloadingById={downloading}
      errorsByUrl={errors}
      hasDragHandle={hasDragHandle}
      isMulti={isMulti}
      isPrivate={isPrivate}
      nonPlayable={nonPlayable}
      onChange={onChange}
      onClick={onClick}
      onDownload={onDownload}
      onDrop={onDrop}
      onError={onError}
      onRemove={onRemove ?? (() => null)}
      progressByUrl={progress}
      resourceId={resourceId}
      resourceType={resourceType}
      showLinkButton={showLinkButton}
      value={value}
      dropzoneText={dropzoneText}
    />
  );
}

FormFileUploader.defaultProps = {
  acceptMessage: undefined,
  acceptMime: undefined,
  axis: 'y',
  className: null,
  components: {
    File: DefaultFile,
    List: DefaultFileList,
  },
  dropzoneText: undefined,
  disabled: false,
  disablePreview: false,
  disableSort: false,
  hasDragHandle: false,
  isMulti: false,
  isPrivate: false,
  nonPlayable: false,
  onClick: undefined,
  onUploadComplete: () => {},
  onUploadStart: () => {},
  resourceId: null,
  showLinkButton: false,
  value: [],
};

export {
  DefaultFileList as FileList,
  DefaultFile as File,
  MediaFile,
  MediaFileList,
};

export default FormFileUploader;
