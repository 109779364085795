import moment from 'moment';
import { Property } from 'components/Property/types';

export const getMetaData = (property: Property) => {
  const metadata = [];
  if (property?.creator) {
    const { genders, dob } = property.creator;

    if (genders) {
      metadata.push(genders.join(', '));
    }

    if (dob) {
      const age = moment().diff(dob, 'years');
      metadata.push(age);
    }
  }

  const languages = [
    ...new Set(
      property?.productions
        ?.map((production) => production.languages || [])
        .flat(2)
        .filter((lang) => lang),
    ),
  ];
  if (languages.length) {
    metadata.push(`Language: ${languages.join(', ')}`);
  }

  return metadata.filter((data) => data);
};
