export default function booleanF({
  value,
}: {
  value: boolean | null;
}): string | null {
  if (value !== true && value !== false) {
    return null;
  }

  if (value) {
    return 'Yes';
  }

  return 'No';
}
