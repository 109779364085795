import React, { Component } from 'react';
import { errorStream } from 'store/errorHandler';
import { children as childrenType } from 'components/utils/customPropTypes';
import UIPageError from 'components/UI/PageError/PageError';
import UIFullContainer from 'components/UI/FullContainer/FullContainer';

type MyProps = { children: React.ReactNode | React.ReactNodeArray | null };
type MyState = { hasError: boolean };

// eslint-disable-next-line react/prop-types
class ErrorBoundary extends Component<MyProps, MyState> {
  static propTypes = {
    children: childrenType,
  };

  static defaultProps = {
    // @ts-ignore
    children: null,
  };

  constructor(props: MyProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });

    errorStream.dispatch({
      type: 'runTimeError',
      error,
      info,
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <UIFullContainer>
          <UIPageError>An error occured.</UIPageError>
        </UIFullContainer>
      );
    }

    return children;
  }
}
export default ErrorBoundary;
