import { ReactElement } from 'react';
import classnames from 'classnames';

import './PageTitle.scss';

const cbn = 'ui-page-title';

type PageTitleProps = WithChildren<{
  description?: string;
  margin?: 'tight' | 'none';
  renderActions?: () => ReactElement | null;
}>;

function PageTitle({
  children,
  description,
  margin,
  renderActions,
}: PageTitleProps) {
  return (
    <div className={`${cbn}__wrapper`}>
      <div className={`${cbn}__title-row`}>
        <h1
          className={classnames(cbn, {
            [`${cbn}--${margin}`]: margin,
            [`${cbn}--has-description`]: description,
          })}
        >
          {children}
        </h1>
        {renderActions && renderActions()}
      </div>
      {description && (
        <span className={`${cbn}__description`}>{description}</span>
      )}
    </div>
  );
}

export default PageTitle;
