import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export interface Document {
  id: number;
  filename: string;
  created: string;
  url: string;
}

export interface ICampaignDocumentCenter {
  campaign: {
    id: number;
    documentCenter: Document[];
  };
}

export type CampaignDocumentsCenter = ICampaignDocumentCenter['campaign'];

const documentCenterGql = gql`
  query CampaignDocumentCenter($id: Int!) {
    campaign(id: $id) {
      id
      documentCenter {
        id
        filename
        displayName
      }
    }
  }
`;

function DocumentsCenterService(campaignId: number): Document[] | null {
  const { data } = useQuery<ICampaignDocumentCenter>(documentCenterGql, {
    variables: {
      id: campaignId,
    },
  });

  if (!data) return null;

  return data.campaign.documentCenter;
}

export default DocumentsCenterService;
