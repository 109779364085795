import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import tikTokCommon from './helpers/tikTokCommon';

export default function videoViewsOrganic() {
  const tikTokMetric = 'fct_lifetime_video_performance.num_video_views_organic' as keyof typeof TCMReportMetrics;
  const metric = 'video_views_organic' as keyof typeof TCMReportMetrics;
  return {
    ...numberColDef,
    ...tikTokCommon(tikTokMetric, metric),
    field: 'videoViewsOrganic',
    headerName: 'Video Views Organic',
    width: 260,
  };
}
