import React from 'react';
import {
  AllowedAction,
  SegmentData,
} from 'utils/segment/useSegmentTTCMTracking';

const DRAWER = 'Spark Ads';

export type SparkAdsSegmentEvents = {
  trackHandleNameClick?: () => void;
  trackContentLinkClick?: () => void;
  trackSendRequest?: () => void;
  trackPeriodChange?: (period: string) => void;
  trackCancelRequest?: () => void;
  trackResendRequest?: () => void;
};

export default function useSegmentEvents(
  trackTikTokAction: (action: AllowedAction, extraData?: SegmentData) => void,
  tab: string,
) {
  return React.useMemo(
    () => ({
      trackHandleNameClick: () =>
        trackTikTokAction(AllowedAction.clickedCreatorHandleName, {
          tab,
          drawer: DRAWER,
        }),
      trackContentLinkClick: () =>
        trackTikTokAction(AllowedAction.clickedContentLink, {
          tab,
          drawer: DRAWER,
        }),
      trackPeriodChange: (period: string) =>
        trackTikTokAction(AllowedAction.changedPeriodOption, {
          tab,
          drawer: DRAWER,
          period,
        }),
      trackSendRequest: () =>
        trackTikTokAction(AllowedAction.clickedSendRequest, {
          tab,
          drawer: DRAWER,
        }),
      trackCancelRequest: () =>
        trackTikTokAction(AllowedAction.clickedCancelButton, {
          tab,
          drawer: DRAWER,
        }),
      trackResendRequest: () =>
        trackTikTokAction(AllowedAction.clickedResendRequest, {
          tab,
          drawer: DRAWER,
        }),
    }),
    [tab, trackTikTokAction],
  );
}
