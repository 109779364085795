import React from 'react';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { Column, RowNode } from 'ag-grid-community/dist/lib/main';
import AlertSquare from '@UIComponents/_assets/react_svg/creatorCampaigns/AlertSquare';

import { getMatchingPostManualMetric } from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import { getOffsetByReportingKey } from 'shared/campaigns/creatorCampaigns/reportingWindows';
import { getPostReleaseDate } from 'shared/campaigns/creatorCampaigns/postDates';
import { Placement } from 'shared/types/Placement';
import { PostManualMetric } from 'shared/types/CreatorCampaigns';
import { Context } from 'components/Campaign/Influencer/View/shared/types';

import './ManualMetricCellRenderer.scss';

export default function ManualMetricCellRenderer({
  valueFormatted,
  value,
  api,
  column,
  node,
  metric,
  data,
  context,
  automatedMetricsGetter,
  postCustomColumnsUuid,
}: Readonly<{
  valueFormatted: number | null;
  value: number | null;
  metric: string;
  node: RowNode;
  column: Column;
  api: GridApi;
  context: Context;
  data: Placement;
  automatedMetricsGetter: (arg0: Placement) => string | number | null;
  postCustomColumnsUuid?: string;
}>) {
  const shouldDisplayWarning = React.useMemo(() => {
    if (!column?.isCellEditable(node) || !context?.reportingWindow) {
      return false;
    }
    const postManualMetric = getMatchingPostManualMetric(
      data,
      context,
      metric,
      postCustomColumnsUuid,
    );

    if (!postManualMetric?.length) {
      return true;
    }
    const reportingWindowDateWithOffset = getOffsetByReportingKey(
      context?.reportingWindow,
      context?.campaign?.dateRangeFrom,
      getPostReleaseDate(data)?.toISOString(),
    );
    const matchingPostManualMetric = (postManualMetric ?? []).find(
      (m: PostManualMetric) =>
        m?.date === reportingWindowDateWithOffset?.toISOString(),
    );
    return !matchingPostManualMetric;
  }, [context, data, metric, column, node, postCustomColumnsUuid]);

  const isAutomatedMetric = React.useMemo(
    () => Boolean(automatedMetricsGetter(data)),
    [automatedMetricsGetter, data],
  );

  const onUpdateClick = React.useCallback(
    (ev: unknown) => {
      const event = ev as React.ChangeEvent<Element>;
      event.stopPropagation();
      if (!node?.rowIndex) {
        return;
      }
      api.startEditingCell({
        rowIndex: node.rowIndex,
        colKey: column.getColId(),
      });
    },
    [column, api, node.rowIndex],
  );

  const dataCy = React.useMemo(
    () => `${data?.platform}-${data?.mediaType}-${metric}`,
    [data?.mediaType, data?.platform, metric],
  );

  const shouldDisplayUpdateButton = React.useMemo(() => {
    if (!column?.isCellEditable(node)) {
      return false;
    }

    return !shouldDisplayWarning;
  }, [column, node, shouldDisplayWarning]);

  return (
    <div data-cy={dataCy} className="ag-destination-cell manual-metric">
      <span
        data-cy="manual-metric-value"
        className={
          !shouldDisplayWarning && !shouldDisplayUpdateButton
            ? 'no-warning'
            : ''
        }
      >
        {valueFormatted ?? value}
      </span>

      {shouldDisplayWarning ? (
        <div
          className={`dynamic-container ${
            !valueFormatted && !value ? 'no-value' : ''
          }`}
        >
          <AlertSquare />

          <BaseButton
            text="Update"
            variant="text"
            color="grey-dark"
            size="x-small"
            className="btn-update"
            onClick={onUpdateClick}
          />
        </div>
      ) : (
        <div className="update-button-container">
          {shouldDisplayUpdateButton && !isAutomatedMetric ? (
            <BaseButton
              text="Update"
              variant="text"
              color="grey-dark"
              size="x-small"
              className="btn-update"
              onClick={onUpdateClick}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
