import SignS3BrandQuery from './brand/SignS3Brand.graphql';

import SignS3CampaignQuery from './campaign/SignS3Campaign.graphql';

import SignS3ClientQuery from './client/SignS3Client.graphql';

import SignS3DeliverableQuery from './deliverable/SignS3Deliverable.graphql';

import SignS3PlacementQuery from './placement/SignS3Placement.graphql';

import SignS3ProductionQuery from './production/SignS3Production.graphql';

import SignS3AsyncImportQuery from './asyncImport/SignS3AsyncImport.graphql';

export const SignS3Brand = SignS3BrandQuery;
export const SignS3Campaign = SignS3CampaignQuery;
export const SignS3Client = SignS3ClientQuery;
export const SignS3Deliverable = SignS3DeliverableQuery;
export const SignS3Placement = SignS3PlacementQuery;
export const SignS3Production = SignS3ProductionQuery;
export const SignS3AsyncImport = SignS3AsyncImportQuery;
