import isEqual from 'lodash/isEqual';
import { toKeysMap } from 'utils/array';

const getItemsToUpdate = (gridApi, entities) => {
  const entitiesById = toKeysMap(entities, 'id');
  const res = [];
  if (!gridApi) {
    return res;
  }
  gridApi.api.forEachNode((rowNode) => {
    const entityToUpdate = entitiesById[rowNode?.data?.id];
    if (!entityToUpdate) {
      return;
    }
    const { data: nodeData } = rowNode;
    let equal = true;
    Object.keys(entityToUpdate).forEach((key) => {
      if (!isEqual(nodeData[key], entityToUpdate[key])) {
        equal = false;
        nodeData[key] = entityToUpdate[key];
      }
    });
    if (!equal) {
      res.push(nodeData);
    }
  });
  return res;
};

export default getItemsToUpdate;
