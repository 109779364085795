import React, { Suspense } from 'react';
import UILoader from 'components/UI/Loader/Loader';
import UIPageTitle from 'components/UI/PageTitle/PageTitle';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';

import './Login.scss';

const cbn = 'login';

const Login = React.lazy(() => import('external/components/User/Login/Login'));

function ScreensUserLogin() {
  return (
    <>
      <DocumentTitle>Sign In</DocumentTitle>
      <div className={`${cbn}__placement-wrapper`}>
        <div className={`${cbn}__wrapper ${cbn}__window`}>
          <div className={cbn}>
            <UIPageTitle>Client Sign In</UIPageTitle>
            <Suspense fallback={<UILoader />}>
              <Login />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScreensUserLogin;
