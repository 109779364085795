type DescriptionProps = {
  description: string;
};

function Description({ description }: DescriptionProps) {
  return (
    <div className="Description highlight-wrapper">
      <h5 className="highlight-title">Description</h5>
      <p className="highlight-text">{description}</p>
    </div>
  );
}

export default Description;
