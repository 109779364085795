import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import tikTokCommon from './helpers/tikTokCommon';

export default function engagementRate25() {
  const tikTokMetric = 'fct_lifetime_video_performance.engagement_benchmark_25' as keyof typeof TCMReportMetrics;
  const metric = 'engagement_rate_benchmark_p25';
  return {
    ...percentColDef,
    ...tikTokCommon(tikTokMetric, metric),
    field: 'engagementRate25%EngagementRate',
    headerName: '25% Engagement Rate',
    width: 270,
  };
}
