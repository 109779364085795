import React from 'react';
import { bool, number, oneOfType, node, element, arrayOf } from 'prop-types';

const child = oneOfType([node, element]);
const propTypes = {
  showCount: bool,
  count: number,
  children: oneOfType([child, arrayOf(child)]),
};

const defaultProps = {
  count: 0,
  showCount: true,
};

function UITableFilters({ children, count, showCount }) {
  return (
    <nav className="ui-table__filters">
      {children}
      {showCount && (
        <div className="ui-table__filters__count">
          Total:
          {count}
        </div>
      )}
    </nav>
  );
}

UITableFilters.propTypes = propTypes;
UITableFilters.defaultProps = defaultProps;

export default UITableFilters;
