import SimpleMetricsTable from '@UIComponents/SimpleMetricsTable/SimpleMetricsTable';
import { MetricResult, QmMetrics } from 'components/Production/types';
import { getBasicStatsRows } from 'components/Creator/Productions/Card/Instagram/utils/tableRows';
import './BasicStats.scss';

export default function BasicStats({
  metrics,
}: Readonly<{
  metrics: {
    platformMetrics?: QmMetrics | null;
    metricResults?: MetricResult[];
  };
}>) {
  return (
    <section className="instagram-basic-stats-container">
      <h2>Basic Stats</h2>
      <SimpleMetricsTable
        className="instagram-basic-stats"
        rows={getBasicStatsRows(metrics)}
      />
    </section>
  );
}
