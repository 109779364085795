import React from 'react';
import './MetadataPill.scss';

type MetadataPillType = {
  label?: string;
  key?: string;
  children: React.ReactElement;
};

const cbn = 'external__metadata-pill';

function MetadataPill({ label, children }: MetadataPillType) {
  return (
    <div className={`${cbn}__data`}>
      <div className={`${cbn}__data__value`}>{children}</div>
      {label && <p className={`${cbn}__data__label`}>{label}</p>}
    </div>
  );
}

export default MetadataPill;
