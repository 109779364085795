const updateQuery = (entitiesName) => (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return prev;
  }

  return {
    ...prev,
    [entitiesName]: {
      ...prev[entitiesName],
      data: [
        ...(prev?.[entitiesName]?.data ?? []),
        ...(fetchMoreResult[entitiesName].data ?? []),
      ],
    },
  };
};

export default updateQuery;
