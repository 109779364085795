import twitchCommon from './helpers/twitchCommon';

const metric = 'gameRankBeforestream';
export default function gameRankBeforestream() {
  return {
    ...twitchCommon(metric),
    headerName: 'Game Rank Before stream',
    width: 295,
  };
}
