import React from 'react';
import UIModal from 'components/UI/Modal/Modal';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import SectionLabel from '@UIComponents/SectionLabel/SectionLabel';
import { TCMSparkAdStatusData } from 'shared/types/TikTokMarketPlace';

import '../SparkAdsModals.scss';

export default function ResendRequestConfirmationModal({
  isOpen,
  onRequestClose,
  resendConfirmationModal,
  sparkAdStatusData,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  resendConfirmationModal: () => void;
  sparkAdStatusData: TCMSparkAdStatusData;
}) {
  const remainingRequests = React.useMemo(
    () =>
      sparkAdStatusData?.num_remaining_request ??
      sparkAdStatusData?.num_remaining_requests,
    [
      sparkAdStatusData?.num_remaining_request,
      sparkAdStatusData?.num_remaining_requests,
    ],
  );

  return (
    <UIModal
      className="spark-ads-modal"
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
    >
      <SectionLabel text="Oops!" size="large" />
      <p className="confirmation-text">
        You have already sent a Tiktok Spark Ad request to this creator for this
        piece of content. Are you sure you want to send another? You can only
        resend this request {remainingRequests} more times.
      </p>
      <BaseButton
        color="purple"
        onClick={resendConfirmationModal}
        rounded="rounded-sm"
        text="Resend Spark Ad Request"
      />
    </UIModal>
  );
}
