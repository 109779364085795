import React, { useState } from 'react';
import { bool, string, func, shape, number } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import UIIconButton from 'components/UI/IconButton/IconButton';
import './Message.scss';

const ENTER_KEYCODE = 13;
const ESC_KEYCODE = 27;

const cbn = 'chat-message';

function Message({
  action,
  authorId,
  authorName,
  authorRole,
  clientName,
  createdAt,
  isDeleted,
  isEditing,
  message,
  onDeleteMessage,
  onToggleEdit,
  onUpdateMessage,
  permissions,
  updatedAt,
  user,
  visibility,
}) {
  const [inputValue, setInputValue] = useState(() => message);
  const convertedClientName = authorRole !== 'external' ? 'BEN' : clientName;
  const isUserMessage = user.id === authorId;
  const time = updatedAt || createdAt || Date.now();
  const date = moment(time).format('MMM DD, YYYY, h:mm A');

  const saveMessage = React.useCallback(() => {
    if (inputValue) {
      onUpdateMessage(inputValue);
    }
  }, [inputValue, onUpdateMessage]);

  const cancelEdit = React.useCallback(() => {
    setInputValue(message);
    onToggleEdit();
  }, [message, onToggleEdit]);

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEYCODE) {
      if (!inputValue) e.preventDefault();

      if (!e.shiftKey) saveMessage();
    }

    if (e.keyCode === ESC_KEYCODE) cancelEdit();
  };

  const m = action === 'create' && !message ? 'created' : message;
  const normalizedMessage = m.replace(/<br\/>/gi, '\n');
  const normalizedInputValue = inputValue.replace(/<br\/>/gi, '\n');

  return (
    <div className={`${cbn}__wrapper`}>
      <div
        className={classnames(cbn, visibility, {
          'user-message': isUserMessage,
          [`${cbn}--is-deleted`]: isDeleted,
        })}
      >
        <div className={`${cbn}__header`}>
          <span className="name">{authorName}</span>
          &nbsp;
          <span>
            (
            {convertedClientName}
            )
          </span>
        </div>
        <div className={`${cbn}__time`}>
          {date}
          {permissions.delete && !isDeleted && (
            <UIIconButton
              icon="delete"
              size="small"
              onClick={onDeleteMessage}
            />
          )}
        </div>
        {isDeleted && <div className={`${cbn}__is-deleted-info`}>Deleted</div>}
        {!isDeleted && (
          <div className={`${cbn}__message`}>
            {isEditing ? (
              <div className={`${cbn}__edit`}>
                <textarea
                  onKeyDown={handleKeyDown}
                  className={`${cbn}__edit__input`}
                  rows={10}
                  name="message"
                  onChange={(e) => setInputValue(e.target.value)}
                  value={normalizedInputValue}
                />
                <UIIconButton
                  className={`${cbn}__save-button`}
                  icon="save"
                  size="small"
                  onClick={saveMessage}
                />
                <UIIconButton
                  className={`${cbn}__cancel-button`}
                  icon="close"
                  size="small"
                  onClick={cancelEdit}
                />
              </div>
            ) : (
              <p>
                {normalizedMessage}
                {permissions.update && (
                  <UIIconButton
                    icon="mode_edit"
                    size="small"
                    onClick={onToggleEdit}
                    className={`${cbn}__edit-button`}
                  />
                )}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Message.propTypes = {
  action: string,
  authorId: number,
  authorName: string.isRequired,
  authorRole: string,
  clientName: string,
  createdAt: string,
  id: number.isRequired,
  isEditing: bool,
  isDeleted: bool,
  message: string,
  onDeleteMessage: func.isRequired,
  onToggleEdit: func.isRequired,
  onUpdateMessage: func.isRequired,
  permissions: shape({
    update: bool,
    delete: bool,
  }),
  updatedAt: string,
  user: shape({}),
  visibility: string.isRequired,
};

Message.defaultProps = {
  action: '',
  authorId: '',
  authorRole: '',
  clientName: '',
  createdAt: null,
  isEditing: false,
  isDeleted: false,
  message: '',
  permissions: {},
  updatedAt: null,
  user: {},
};

export default Message;
