import { REPORTING_WINDOW_OPTIONS } from 'constants/constants';
import { COUNTRIES } from 'shared/metrics/constants';

export const PLACEMENT_QUALITY_TYPES = [
  { name: 'Premium', status: 'premium', vehiclePower: 'A' },
  { name: 'Prominent', status: 'prominent', vehiclePower: 'B' },
  { name: 'Standard', status: 'standard', vehiclePower: 'C' },
];

export const PLACEMENT_STATUSES_DISPLAY_MAP = {
  draft: 'Draft',
  internalReview: 'Internal Review',
  brandReady: 'Brand Ready',
  brandReview: 'Brand Review',
  brandEditsRequired: 'Brand Edits Required',
  brandApproval: 'Brand Approval',
  influencerFeedbackSent: 'Influencer Feedback Sent',
  influencerApprovalSent: 'Influencer Approval Sent',
  published: 'Live / Published',
  deleted: 'Deleted',
};

export const PLACEMENT_TRADITIONAL_STATUSES_DISPLAY_MAP = {
  draft: 'Draft',
  internalReview: 'In Review',
  published: 'Published',
  deleted: 'Deleted',
};

export const PLACEMENT_TYPE_SELECT_OPTIONS = [
  { label: 'Client', value: 'placement' },
  { label: 'Competition', value: 'competition' },
];

export const STORY_TYPE_SELECT_OPTIONS = [
  { label: 'Scripted', value: 'scripted' },
  { label: 'Unscripted', value: 'unscripted' },
];

export const SOURCE_SELECT_OPTIONS = [
  { label: 'Paid', value: 'paid' },
  { label: 'Propped', value: 'propped' },
  { label: 'Not placed by BEN', value: 'notplacedbyben' },
];

export const VERBALS_SELECT_OPTIONS = [
  { label: 'Mentioned', value: 'mentioned' },
  { label: 'Only', value: 'only' },
];

export const SOURCE_CLASS_SELECT_OPTIONS = [
  { label: 'Video', value: 'video' },
  { label: 'Social', value: 'social' },
  { label: 'Press', value: 'press' },
];

export const PLACEMENT_QUALITY_TYPES_SELECT_OPTIONS = PLACEMENT_QUALITY_TYPES.map(
  (type) => ({
    value: type.status,
    label: type.name,
  }),
).concat([{ value: 'msft x', label: 'MSFT X' }]);

export const PLACEMENT_STATUSES = Object.keys(
  PLACEMENT_STATUSES_DISPLAY_MAP,
).sort();

export const PLACEMENT_STATUS_SELECT_OPTIONS = PLACEMENT_STATUSES.map(
  (status) => ({
    value: status,
    label: PLACEMENT_STATUSES_DISPLAY_MAP[status],
  }),
);

export const PLACEMENT_TRADITIONAL_STATUSES = Object.keys(
  PLACEMENT_TRADITIONAL_STATUSES_DISPLAY_MAP,
);

export const PLACEMENT_TRADITIONAL_STATUS_SELECT_OPTIONS = PLACEMENT_TRADITIONAL_STATUSES.map(
  (status) => ({
    value: status,
    label: PLACEMENT_TRADITIONAL_STATUSES_DISPLAY_MAP[status],
  }),
);

export const PLACEMENT_SORT_FIELDS = [
  {
    name: 'relevance',
    label: 'Relevance',
    from: 'Z',
    to: 'A',
    defaultDirection: 'desc',
    hideSort: true,
  },
  {
    name: 'name',
    label: 'Production Name',
    from: 'A',
    to: 'Z',
    defaultDirection: 'asc',
  },
  {
    name: 'quality',
    label: 'Quality',
    from: 'Low',
    to: 'High',
    defaultDirection: 'desc',
  },
  {
    name: 'premiereDate',
    label: 'Premiere Date',
    from: 'Oldest',
    to: 'Newest',
    defaultDirection: 'asc',
  },
  {
    name: 'lastEdit',
    label: 'Last edit',
    from: 'Oldest',
    to: 'Newest',
    defaultDirection: 'desc',
  },
  {
    name: 'episodeNumber',
    label: 'Season/Episode',
    from: 'Low',
    to: 'High',
    defaultDirection: 'asc',
  },
];

export const PLACEMENT_ATTRIBUTES = [
  {
    label: 'Appropriateness & Believability',
    key: 'appropriatenessBelievability',
    group: 'quality',
  },
  {
    label: 'Hands on Usage',
    key: 'handsOnUsage',
    group: 'quality',
  },
  {
    label: 'Showcase Product Features',
    key: 'showcaseProductFeatures',
    group: 'quality',
  },
  {
    label: 'Visibility of Product and Logo',
    key: 'visibilityProductLogo',
    group: 'quality',
  },
  {
    label: 'Verbal Mention',
    key: 'verbalMention',
    group: 'quality',
  },
  {
    label: 'Storyline Integration',
    key: 'storylineIntegration',
    group: 'quality',
  },
  {
    label: 'Implied Celebrity Endorsement',
    key: 'impliedCelebrityEndorsement',
    group: 'quality',
  },
  {
    label: 'Positive Brand Message',
    key: 'positiveBrandMessage',
    group: 'quality',
  },
  {
    label: 'Minimum Duration',
    key: 'minimumDuration',
    group: 'quality',
  },
  {
    label: 'Reel Ready',
    key: 'reelReady',
    group: 'attributes',
  },
  {
    label: 'Media Spend',
    key: 'mediaSpend',
    group: 'attributes',
  },
  {
    label: 'Added Value',
    key: 'addedValue',
    group: 'attributes',
  },
];

export const PLACEMENT_QUALITY_ATTRIBUTE = PLACEMENT_ATTRIBUTES.filter(
  (attr) => attr.group === 'quality',
);

export const PLACEMENT_COUNTRY_SELECT_OPTIONS = COUNTRIES;

export const FILTER_ID = 'placementsList';

export const PLACEMENT_SOURCE_TYPES_MAP = {
  paid: 'Paid',
  propped: 'Propped',
  notPlacedByBEN: 'Not BEN',
};

export const SINGLE_VIEW_FIELDS_STORAGE_KEY = '$BEN$--placementSearchFilter';
export const SHOW_FIELDS_LABEL_KEY = '__label';

export const VIEW_CURRENCY_LOCALE = { style: 'currency', currency: 'USD' };

export const PERFORMANCE_METRIC_WINDOW = [
  { value: 'defaultReportingWindow', label: 'Channel Default' },
  ...REPORTING_WINDOW_OPTIONS,
];

export const tooltips = {};
