/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { shape, func } from 'prop-types';
import { children as childrenType } from 'components/utils/customPropTypes';
import ErrorHandlerContext from './Context';
import errorParser from './errorParser';

function shouldClear(errEvents) {
  return errEvents[0] && errEvents[0].type === 'CLEAR';
}

class ErrorHandlerProvider extends Component {
  static propTypes = {
    children: childrenType.isRequired,
    errorStream: shape({ subscribe: func.isRequired }).isRequired,
  };

  constructor(props) {
    super(props);

    const push = (...errorArgs) =>
      this.setState(({ errors }) => ({
        errors: [...errors, ...errorArgs],
      }));

    const reset = () => this.setState({ errors: [] });

    this.state = {
      errors: [],
      push,
      reset,
    };
  }

  componentDidMount() {
    const { errorStream } = this.props;
    const { push, reset } = this.state;

    this.listener = errorStream.subscribe((...errEvents) => {
      if (shouldClear(errEvents)) {
        return reset();
      }

      const parsedErrors = errEvents.map(errorParser).filter((err) => err);
      return push(...parsedErrors);
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    const { children } = this.props;

    return (
      <ErrorHandlerContext.Provider value={this.state}>
        {children}
      </ErrorHandlerContext.Provider>
    );
  }
}

export default ErrorHandlerProvider;
