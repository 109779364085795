const keysDictionary = {
  ArrowRight: 'Right',
  ArrowLeft: 'Left',
  ArrowUp: 'Up',
  ArrowDown: 'Down',
  Escape: 'Esc',
  Meta: 'Ctrl',
  Control: 'Ctrl',
  Cmd: 'Ctrl',
};

export function getKey(key) {
  return keysDictionary[key] || key;
}

export function getKeys(shortcut) {
  return shortcut.split('+').map((key) => getKey(key.trim()));
}

export function isTriggeredOnInput({ target }) {
  return target && ['INPUT', 'TEXTAREA'].includes(target.tagName);
}

export function toKeys(pressed) {
  const result = {};
  pressed.forEach((key) => {
    result[key] = true;
  });
  return result;
}

export function match(config, pressed) {
  const pressedMap = toKeys(pressed);
  const result = config.find(
    ([keys]) =>
      keys.length === pressed.length && keys.every((key) => pressedMap[key]),
  );

  if (result) {
    result[1]();
  }
}
