import { useContext } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import classnames from 'classnames';

import ApplicationContext from 'components/Application/Context';
import { ExternalApplicationContext } from 'external/components/Application/Context';

import {
  trackPostDeclined,
  trackPostApproved,
  trackPostRedirected,
} from 'external/components/Segment/Segment';

import prepareClickableContainer from 'external/utils/prepareClickableContainer';
import UIGallery from 'components/UI/Gallery/Gallery';

import ViewContentButton from 'external/components/UI/ViewContentButton/ViewContentButton';
import { Placement } from 'components/Placement/types';
import ExternalApproval from '../ExternalApproval/ExternalApproval';

import ExternalPlacementCardMetric from './Metric/Metric';
import ExternalPlacementCardMedia from './Media/Media';

import './Card.scss';
import { ExternalApproval as ExternalApprovalTypes } from 'constants/approvalStatuses';

type MetricsProps = {
  label: string;
  format: string;
  value: number;
};

type ExternalPlacementCardProps = {
  className?: string;
  id: number;
  description?: string;
  enableEdit: boolean;
  externalApproval: ExternalApprovalTypes | null;
  externalMessagesCount: number;
  media: {
    url: string;
  }[];
  metrics: MetricsProps[] | null;
  onClick: () => void;
  rejectedReason: string;
  url: string;
  placement: Placement;
};

function ExternalPlacementCard({
  className,
  id,
  enableEdit,
  externalApproval,
  externalMessagesCount,
  description,
  rejectedReason,
  media,
  metrics,
  onClick,
  url,
  placement,
}: ExternalPlacementCardProps) {
  const clickableContainer = prepareClickableContainer(onClick);
  const { user } = useContext(ApplicationContext);
  const { campaign, segment } = useContext(ExternalApplicationContext);

  const trackApproveClick = () => {
    if (segment && user && campaign) {
      trackPostApproved({ segment, campaign, user, placement });
    }
  };

  const trackRejectClick = (message: string) => {
    if (segment && user && campaign) {
      trackPostDeclined({ segment, campaign, user, placement, message });
    }
  };

  const handleMediaUrlClick = () => {
    if (segment && user && campaign) {
      trackPostRedirected({ segment, campaign, user, placement });
    }
  };

  const shouldRenderMetrics = !campaign?.clientAccessTabs?.contentReview;

  return (
    <div
      className={classnames(
        'external-placement-card',
        'external-placement-card--active',
        className,
      )}
      {...clickableContainer}
    >
      {(media.length > 0 || url) && enableEdit && (
        <div
          className={classnames(
            'external-placement-card__external-approval-wrapper',
          )}
        >
          <ExternalApproval
            id={id}
            externalApproval={externalApproval}
            externalMessagesCount={externalMessagesCount}
            handleApproveClick={() => trackApproveClick()}
            handleRejectClick={(message: string) => trackRejectClick(message)}
          />
        </div>
      )}

      {rejectedReason && (
        <div className="external-placement-card__rejectedReason">
          <h4 className="external-placement-card__rejectedReason-label">
            Declined Reason
          </h4>

          <p>{rejectedReason}</p>
        </div>
      )}

      <div className="external-placement-card__media-wrapper">
        <div className="external-placement-card__media">
          {media?.length ? (
            <UIGallery
              className="external-placement-card__gallery"
              fallbackImage="placeholderImage"
              files={media}
              imageComponent={ExternalPlacementCardMedia}
              resourceId={id}
              resourceType="placement"
              showThumbs={media?.length > 1}
              videoComponent={ExternalPlacementCardMedia}
              download={false}
              hasMask={false}
              nonPlayable={false}
              selectedIndex={null}
              setSelectedIndex={null}
            />
          ) : (
            <div className="external-placement-card__no-media-message__wrapper">
              <div className="external-placement-card__no-media-message">
                No media uploaded yet
              </div>
            </div>
          )}
        </div>
      </div>

      {url && (
        <div className="external-placement-card__links">
          <div
            onClick={() => handleMediaUrlClick()}
            className="external-placement-card__button__wrapper"
          >
            <ViewContentButton url={url} platform={placement.platform!} />
          </div>
        </div>
      )}

      {shouldRenderMetrics && (
        <div className="external-placement-card__metrics">
          {metrics?.map(({ label, format, value }) => (
            <ExternalPlacementCardMetric
              key={label}
              format={format}
              label={label}
              value={value}
            />
          ))}
        </div>
      )}

      {description && (
        <div className="external-placement-card__description">
          <h4 className="external-placement-card__description-label">
            Description
          </h4>

          <p>{description}</p>
        </div>
      )}
    </div>
  );
}

ExternalPlacementCard.propTypes = {
  className: string,
  description: string,
  isActive: bool,
  media: arrayOf(
    shape({
      url: string,
    }),
  ),
  metrics: arrayOf(
    shape({
      label: string,
      format: string,
      value: number,
    }),
  ),
  onClick: func,
  url: string,
};

ExternalPlacementCard.defaultProps = {
  className: '',
  description: '',
  isActive: false,
  media: null,
  metrics: null,
  onClick: null,
  url: '',
};

export default ExternalPlacementCard;
