/* eslint-disable max-lines-per-function */
import { Tooltip } from 'react-tippy';
import UINumber from 'components/UI/Number/Number';
import DetailsPanelMetric from '../Metric/Metric';
import PlatformTitle from '../PlatformTitle/PlatformTitle';

type DeliverableDetailsPanelInstagramProps = {
  averageEngagement?: {
    format?: string;
    value?: number;
  };
  followers?: number;
  onRedirectClick: () => void;
  postFrequency?: string;
  posts?: number;
  predictedViews?: {
    format?: string;
    value?: number;
  };
  showReportingWindowMessage: boolean;
  title: string;
  url?: string;
};

function DeliverableDetailsPanelInstagram({
  averageEngagement,
  followers,
  onRedirectClick,
  postFrequency,
  posts: mediaCount,
  predictedViews,
  showReportingWindowMessage,
  title,
  url,
}: DeliverableDetailsPanelInstagramProps) {
  return (
    <div className="external-content__detail-panel__channel">
      <PlatformTitle
        onClick={onRedirectClick}
        platform="instagram"
        title={title}
        url={url}
      />
      <div className="external-content__detail-panel__channel__info">
        <div className="external-content__detail-panel__channel__data__wrapper">
          <div className="external-content__detail-panel__channel__data__content">
            {followers && (
              <DetailsPanelMetric label="Followers">
                {/* @ts-ignore */}
                <Tooltip
                  html={<UINumber>{followers}</UINumber>}
                  position="bottom"
                  theme="dark"
                  size="big"
                  arrow
                  animation="shift"
                >
                  <UINumber format="0a">{followers}</UINumber>
                </Tooltip>
              </DetailsPanelMetric>
            )}
            {mediaCount && (
              <DetailsPanelMetric label="Posts">
                {/* @ts-ignore */}
                <Tooltip
                  html={<UINumber>{mediaCount}</UINumber>}
                  position="bottom"
                  theme="dark"
                  size="big"
                  arrow
                  animation="shift"
                >
                  <UINumber format="0a">{mediaCount}</UINumber>
                </Tooltip>
              </DetailsPanelMetric>
            )}
          </div>
        </div>
        {predictedViews && (
          <div className="external-content__detail-panel__channel__data__wrapper">
            <div className="external-content__detail-panel__channel__data__ben-labs-metrics">
              <h5>BENlabs metrics</h5>
              <p>
                Metrics calculated based on the creator’s history in BEN
                campaigns.
              </p>
              <div className="external-content__detail-panel__channel__data__content">
                {predictedViews && (
                  <DetailsPanelMetric
                    prediction
                    showLive30Message={showReportingWindowMessage}
                    label="Predicted Views"
                  >
                    <UINumber format={predictedViews.format}>
                      {predictedViews.value}
                    </UINumber>
                  </DetailsPanelMetric>
                )}
                {averageEngagement && (
                  <DetailsPanelMetric label="Engagement">
                    <UINumber format={averageEngagement.format}>
                      {averageEngagement.value}
                    </UINumber>
                  </DetailsPanelMetric>
                )}
                {postFrequency && (
                  <DetailsPanelMetric label="Post Frequency">
                    {postFrequency}
                  </DetailsPanelMetric>
                )}
              </div>
            </div>
            {showReportingWindowMessage && (
              <div className="external-content__detail-panel__channel__data__description">
                * Predicted 30-Day Performance
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DeliverableDetailsPanelInstagram;
