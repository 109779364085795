import createErrorStream from './createErrorStream';
import Provider from './Provider';
import Context from './Context';
import useErrorHandler from './useErrorHandler';

const errorStream = createErrorStream();

const types = {
  formValidationError: 'formValidationError',
  genericError: 'genericError',
  graphglFormValidationError: 'graphglFormValidationError',
  graphQLError: 'graphQLError',
  networkError: 'networkError',
  restApiError: 'restApiError',
  runTimeError: 'runTimeError',
};

export { errorStream, Provider, Context, useErrorHandler, types };

const errorHandler = {
  errorStream,
  Provider,
  Context,
  useErrorHandler,
  types,
};

export default errorHandler;
