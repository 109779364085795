import { trunc } from 'utils/string';
import * as Sentry from '@sentry/react';
import restErrorParser from './restErrorParser';
import { displayApplicationWasRefreshed } from './utils/errorCheck';

function graphQLError(error) {
  Sentry.captureMessage(`Error on graphQLError: ${error}`);
  const { message, extensions } = error;
  if (extensions.code === 'GRAPHQL_VALIDATION_FAILED') {
    return {
      title: 'Error on GraphQL Query',
      description: message,
      raw: error,
      custom: false,
    };
  }

  const { exception } = extensions;
  const title =
    extensions.code === 'BAD_USER_INPUT'
      ? 'Unable to complete request'
      : extensions.code;

  return {
    title: exception.title || title,
    headline: exception.headline,
    description: message,
    raw: error,
    custom: !!exception.isCustom,
    link: exception.link,
  };
}

function networkError(error) {
  const applicationRefreshed = displayApplicationWasRefreshed(error);
  if (applicationRefreshed) {
    return applicationRefreshed;
  }

  Sentry.captureMessage(`Error on networkError: ${error}`);
  const { response, networkError: netError } = error;

  if (!response && netError) {
    return {
      raw: error.message,
      title: netError.type,
      description: trunc(netError.message, 74),
    };
  }

  const isServerOffline = error.name === 'ServerParseError';
  const title = response
    ? `${response.status} ${response.statusText}`
    : 'Connection Error';

  const message = response
    ? response.message
    : 'Unable to communicate with server';

  return {
    raw: error,
    title,
    description: isServerOffline ? 'Server is temporarily offline' : message,
    custom: false,
  };
}

function formValidationError(error) {
  return {
    title: 'Form validation Error',
    description: 'There are fields with errors',
    raw: error,
    custom: false,
  };
}

function genericError(error) {
  Sentry.captureMessage(`Error on genericError: ${error}`);
  return {
    title: error.title || 'Error',
    description: error.description,
    link: error.link,
    custom: true,
  };
}

function graphglFormValidationError(error) {
  return {
    title: 'Graphql form schema validation Error',
    description: error.message || 'Error validating the schema',
    raw: error,
    custom: false,
  };
}

function runTimeError(error, info) {
  const applicationRefreshed = displayApplicationWasRefreshed(error);
  if (applicationRefreshed) {
    return applicationRefreshed;
  }
  Sentry.captureMessage(`Error on runTimeError: ${error}`);
  return {
    title: error.name || 'Runtime error',
    description: error.message,
    raw: {
      stack: error.stack.toString().replace(/webpack-internal:\/\/\//g, ''),
      componentStack: info.componentStack,
    },
    custom: false,
  };
}

function defaultHandler(error) {
  Sentry.captureMessage(`Error on defaultHandler: ${error}`);
  return {
    title: error.message || 'An unknown error occurred',
    raw: error?.details ?? error,
    custom: false,
  };
}

const lookup = {
  restApiError: restErrorParser,
  graphQLError,
  networkError,
  formValidationError,
  genericError,
  graphglFormValidationError,
  runTimeError,
};

export default function errorParser(errorEvent) {
  if (!errorEvent.type) {
    return defaultHandler(errorEvent);
  }

  const { type, error, info } = errorEvent;

  return lookup[type] ? lookup[type](error, info) : defaultHandler(error, info);
}
