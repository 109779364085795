import { gql } from 'apollo-boost';

export default gql`
  query ExternalCampaingsList(
    $limit: Int
    $skip: Int
    $filter: CampaignFilter
  ) {
    campaigns(limit: $limit, filter: $filter, skip: $skip) {
      data {
        id
        projectName
        status
        dateRangeFrom
        dateRangeTo
        clientAccessTabs
        brandAppPermissions
        reportingWindow
        client {
          id
          logo {
            id
            url
          }
        }
        brands {
          id
          logo {
            id
            url
          }
        }
      }
      meta {
        total
      }
      facets {
        status
      }
    }
  }
`;
