import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';
import manualMetricsValueGetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import getYouTubeDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/youTube/helpers/getYouTubeDataFromCampaignService';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { VIDEO_MEDIA_TYPE, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';

const metric = 'videoThumbnailCTR';
export default function videoThumbnailCTR() {
  return {
    ...basePerformanceColDef(
      metric,
      () => null,

      [YOUTUBE_PLATFORM],
      [VIDEO_MEDIA_TYPE],
    ),
    valueGetter: ({ data, context }: { data: Placement; context: Context }) => {
      if (data?.platform !== YOUTUBE_PLATFORM) {
        return null;
      }
      const videoThumbnailImpressions =
        getYouTubeDataFromCampaignService(data, 'videoThumbnailImpressions') ??
        manualMetricsValueGetter(data, context, 'videoThumbnailImpressions') ??
        null;
      const videoThumbnailClicks =
        getYouTubeDataFromCampaignService(data, 'videoThumbnailClicks') ??
        manualMetricsValueGetter(data, context, 'videoThumbnailClicks') ??
        null;

      const videoThumbnailImpressionsNumber = Number(videoThumbnailImpressions);
      const videoThumbnailClicksNumber = Number(videoThumbnailClicks);
      if (
        Number.isNaN(videoThumbnailImpressionsNumber) ||
        Number.isNaN(videoThumbnailClicksNumber) ||
        !videoThumbnailImpressionsNumber ||
        !videoThumbnailClicksNumber
      ) {
        return '';
      }

      return videoThumbnailClicksNumber / videoThumbnailImpressionsNumber;
    },
    ...percentColDef,
    editable: false,
    headerName: 'Video Thumbnail CTR',
    width: 265,
  };
}
