import { useContext } from 'react';
import moment from 'moment';
import BaseCard from '@UIComponents/BaseCard/BaseCard';
import ListLeftRight from '@UIComponents/ListLeftRight/ListLeftRight';
import Loading from '@UIComponents/Loading/Loading';
import ExternalApplicationContext from '../Application/Context';
import quickLinksService, { QuickLink } from './QuickLinksService';

const quickLinksHeaders = {
  leftHeader: 'Shared On',
  rightHeader: 'Link Name',
};

const formatDate = (date: string) => moment(date).utc().format('MM/DD/YYYY');

const formatRenderData = (quickLinks: QuickLink[]) =>
  quickLinks.map(({ created, displayName, url }) => ({
    leftRow: formatDate(created),
    rightRow: displayName,
    callback: () => window.open(url),
    icon: 'link',
  }));

const getRenderData = (campaignId: number) => {
  const quickLinks = quickLinksService(campaignId);

  if (!quickLinks) {
    return [];
  }

  return formatRenderData(quickLinks);
};

function QuickLinksRender() {
  const { campaignId } = useContext(ExternalApplicationContext);

  if (!campaignId) return <Loading />;

  const quickLinksRows = getRenderData(campaignId);

  return (
    /* @ts-ignore */
    <BaseCard transparent dataCy="QuickLinksRender">
      <ListLeftRight headers={quickLinksHeaders} rows={quickLinksRows} />
    </BaseCard>
  );
}

export default QuickLinksRender;
