import svgSizeMapping, { SvgSize } from '../utils/svgSizeMapping';

export default function TikTokLogo({
  fill = 'none',
  size = 'medium',
}: {
  fill?: string;
  size?: SvgSize;
}) {
  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 20 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1618 4.20949C14.9885 3.47187 14.1412 2.29198 13.8769 0.916189C13.8199 0.618578 13.7886 0.312679 13.7886 0H10.0429L10.0366 14.4691C9.9733 16.0898 8.59053 17.3903 6.89431 17.3903C6.36747 17.3903 5.87111 17.2629 5.43415 17.0414C4.43206 16.5328 3.74575 15.5202 3.74575 14.3554C3.74575 12.682 5.15823 11.3205 6.89431 11.3205C7.2187 11.3205 7.52902 11.3717 7.82371 11.4606V7.77479C7.51964 7.73486 7.2101 7.71 6.89431 7.71C3.09306 7.71 0 10.6914 0 14.3554C0 16.6037 1.16547 18.5935 2.94298 19.796C4.06311 20.554 5.42477 21 6.89431 21C10.6956 21 13.7886 18.0194 13.7886 14.3546V7.01758C15.2574 8.03398 17.0576 8.63297 19 8.63297V5.02246C17.9541 5.02246 16.9794 4.72259 16.1625 4.20874L16.1618 4.20949Z"
        fill="black"
      />
    </svg>
  );
}
