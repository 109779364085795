import twitchCommon from './helpers/twitchCommon';

const metric = 'reactions';
export default function reactions() {
  return {
    ...twitchCommon(metric),
    headerName: 'Reactions',
    width: 180,
  };
}
