import { useEffect, useRef } from 'react';
import { isTriggeredOnInput, getKey, match, getKeys } from './utils';

/*
 * expected [shortcurt: Array, calbback Fn]
 * eg: ['Esc', close] or ['Ctrl + s', save]
 *
 *
 */
export default function useShortcuts(options, disabled = false) {
  const config = useRef([]);
  const pressed = useRef([]);

  function onKeyDown(e) {
    if (isTriggeredOnInput(e)) {
      return;
    }
    const key = getKey(e.key);
    if (key === 'Dead') {
      return;
    }
    pressed.current = [...pressed.current, key];

    match(config.current, pressed.current);
  }

  function onKeyUp(e) {
    if (isTriggeredOnInput(e)) {
      return;
    }
    const key = getKey(e.key);
    pressed.current = pressed.current.filter((k) => k !== key);
  }

  useEffect(() => {
    if (disabled) {
      return undefined;
    }

    const opt = [];
    options.forEach(([shortcut, cb]) => {
      opt.push([getKeys(shortcut), cb]);
    });

    config.current = opt;

    document.addEventListener('keydown', onKeyDown, false);
    document.addEventListener('keyup', onKeyUp, false);

    return () => {
      pressed.current = [];
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [options, disabled]);
}
