import React from 'react';
import BaseButton from '@UIComponents/BaseButton/BaseButton';

import './UpdateSubmit.scss';

export default function UpdateSubmit({
  onResendRequest,
  remainingRequests,
  trackResendRequest,
}: {
  onResendRequest: () => void;
  remainingRequests: number;
  trackResendRequest?: () => void;
}) {
  const resendRequest = React.useCallback(() => {
    onResendRequest();

    if (trackResendRequest) {
      trackResendRequest();
    }
  }, [onResendRequest, trackResendRequest]);

  if (remainingRequests === 0) {
    return null;
  }

  return (
    <div className="tiktok-spark-ads__update__submit">
      {remainingRequests > 0 ? (
        <>
          <p>
            Saving changes only updates the original request details. If the
            Creator didn’t see the request or for some reason it didn’t work you
            can resend a request {remainingRequests} more times.
          </p>

          <BaseButton
            color="purple"
            onClick={resendRequest}
            rounded="rounded-sm"
            text="Resend Request"
            variant="outlined"
          />
        </>
      ) : (
        <p>
          You have reached the limit of 30 Spark Ad requests for this piece of
          content.
        </p>
      )}
    </div>
  );
}
