import React, { useMemo } from 'react';
import classnames from 'classnames';
import {
  SortableElement as sortElement,
  SortableContainer as sortContainer,
} from 'react-sortable-hoc';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { removeIn } from 'utils/array';
import arrayMove from 'array-move';
import { isVideo } from 'utils/files';
import DropzoneText from '../DropzoneText/DropzoneText';
import DropzoneURL from '../DropzoneURL/DropzoneURL';
import DefaultFileList from '../FileList/FileList';
import DefaultFile from '../File/File';
import { MediaFileComponentProps, FileType, ValueType } from '../types';

const cbn = 'form-file-uploader';

type FormFileUploaderDropzoneProps = {
  acceptMessage: string;
  acceptMime: string;
  axis: 'x' | 'y' | 'xy';
  className: string;
  components: {
    File: React.Component<MediaFileComponentProps>;
    FileList: React.Component;
  };
  disabled: boolean;
  disablePreview: boolean;
  disableSort: boolean;
  disableFileList?: boolean;
  downloadingById: { [x: string]: boolean };
  dropzoneText?: string;
  errorsByUrl: { [x: string]: string };
  hasDragHandle: boolean;
  isMulti: boolean;
  nonPlayable: boolean;
  onChange?: (value: ValueType) => void;
  onClick: (event: React.MouseEvent) => void;
  onDownload: (event: React.MouseEvent, file: FileType) => void;
  onDrop: (files: FileWithPath[] | FileType[], isFromUrl?: boolean) => void;
  onError: (status: string, error: FileType) => void;
  onRemove?: (value: ValueType) => void;
  pending: boolean;
  progressByUrl: { [x: string]: number };
  showLinkButton: boolean;
  value: FileType | FileType[];
  isPrivate: boolean;
  resourceId: number;
  resourceType: string;
};

function FormFileUploaderDropzone({
  acceptMessage,
  acceptMime,
  axis,
  className,
  components,
  disabled,
  disablePreview,
  disableSort,
  disableFileList,
  downloadingById,
  dropzoneText,
  errorsByUrl,
  hasDragHandle,
  isMulti,
  isPrivate,
  nonPlayable,
  onChange,
  onClick,
  onDownload,
  onDrop,
  onError,
  onRemove: parentOnRemove,
  pending,
  progressByUrl,
  resourceId,
  resourceType,
  showLinkButton,
  value,
}: Readonly<FormFileUploaderDropzoneProps>) {
  const fileComponent = components.File || DefaultFile;
  const fileListComponent = components.FileList || DefaultFileList;
  const acceptRemoteMedia = useMemo(() => isVideo(acceptMime), [acceptMime]);
  const FileComponent = useMemo(() => sortElement(fileComponent), [
    fileComponent,
  ]);
  const FileListComponent = useMemo(() => sortContainer(fileListComponent), [
    fileListComponent,
  ]);

  const handleDownload = React.useCallback(
    (e: React.MouseEvent, file: FileType) => {
      e.preventDefault();
      onDownload(e, file);
    },
    [onDownload],
  );

  const onRemove = React.useCallback(
    (index: number) => {
      const newValue = removeIn(value, index);
      if (onChange) {
        onChange(newValue);
      }
      if (parentOnRemove) {
        parentOnRemove(newValue);
      }
    },
    [onChange, parentOnRemove, value],
  );

  const onSortEnd = React.useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const nvalue = arrayMove(value as FileType[], oldIndex, newIndex);
      if (onChange) {
        onChange(nvalue);
      }
    },
    [onChange, value],
  );

  const hasFiles = Array.isArray(value) && value.length > 0;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onDrop(files),
    multiple: isMulti,
    accept: acceptMime,
    disabled,
  });
  return (
    <div
      className={classnames(cbn, className, {
        [`${cbn}--is-multi`]: isMulti,
        [`${cbn}--accept-remote-media`]: acceptRemoteMedia,
      })}
    >
      <div className={`${cbn}__dropzone__wrapper`}>
        <div
          {...getRootProps({
            className: classnames(`${cbn}__dropzone`, { disabled }),
          })}
        >
          <DropzoneText
            className={`${cbn}__dropzone-description`}
            acceptMessage={acceptMessage}
            getInputProps={getInputProps}
            text={dropzoneText}
          />
          {pending && (
            <span className={`${cbn}__uploading-message`}>
              Uploading files...
            </span>
          )}
        </div>
        {acceptRemoteMedia && (
          <DropzoneURL
            onDrop={(files) => onDrop(files as FileType[], true)}
            onError={onError}
            disabled={disabled}
          />
        )}
      </div>
      {hasFiles && !disableFileList ? (
        <FileListComponent
          showLinkButton={showLinkButton}
          disabled={disabled}
          disablePreview={disablePreview}
          files={value}
          itemComponent={FileComponent}
          resourceId={resourceId}
          resourceType={resourceType}
          isPrivate={isPrivate}
          nonPlayable={nonPlayable}
          onClick={onClick}
          onDownload={handleDownload}
          onFileRemove={onRemove}
          progressByUrl={progressByUrl}
          errorsByUrl={errorsByUrl}
          downloadingById={downloadingById}
          useDragHandle={hasDragHandle}
          /* sortable props */
          axis={axis}
          disableSort={disableSort || !isMulti}
          distance={10}
          onSortEnd={onSortEnd}
        />
      ) : null}
    </div>
  );
}

FormFileUploaderDropzone.defaultProps = {
  acceptMessage: undefined,
  acceptMime: undefined,
  axis: 'y',
  className: null,
  components: {
    File: DefaultFile,
    List: DefaultFileList,
  },
  disabled: false,
  disablePreview: false,
  disableSort: false,
  downloadingById: null,
  errorsByUrl: null,
  hasDragHandle: false,
  isMulti: false,
  isPrivate: null,
  nonPlayable: false,
  onClick: undefined,
  onDownload: null,
  onDrop: null,
  onError: null,
  pending: null,
  progressByUrl: null,
  resourceId: null,
  resourceType: null,
  showLinkButton: false,
  value: [],
};

export default FormFileUploaderDropzone;
