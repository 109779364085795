export default function getUTCDateAtBeginningOfDay(date: Date | string | null) {
  if (date === null) {
    return null;
  }
  try {
    const dateObject = new Date(date);
    return new Date(
      Date.UTC(
        dateObject.getUTCFullYear(),
        dateObject.getUTCMonth(),
        dateObject.getUTCDate(),
      ),
    );
  } catch (e) {
    return null;
  }
}
