import { Placement } from 'shared/types/Placement';
import { getDataCy } from 'components/Campaign/utils';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import CreatorToggle from 'components/DataGrid/Cells/Creator/CreatorToggle';
import { GridCallbackParams } from 'components/Metrics/AgGridMetrics/utils/columnDefs/utils';
import getPropertyFromPlacement from 'components/Campaign/Influencer/View/shared/columns/utils/getPropertyFromPlacement';

import { StatsContext } from '../../types';

export default function creatorName() {
  return {
    ...baseColDef,
    field: 'creatorName',
    headerName: 'Creator Name',
    width: 300,
    valueGetter: ({ data }: { data: Placement }) => {
      const property = getPropertyFromPlacement(data);
      return property?.name ?? null;
    },
    comparator: (firstName: string, secondName: string) => {
      if (!firstName) {
        return 1;
      }

      if (!secondName) {
        return -1;
      }

      return firstName.toLowerCase().localeCompare(secondName.toLowerCase());
    },
    cellRenderer: CreatorToggle,
    cellRendererParams: ({
      data,
      context,
    }: {
      data: Placement;
      context: StatsContext;
    }) => ({
      property: getPropertyFromPlacement(data) || null,
      dataCy: getDataCy(data, 'creator-profile__drawer') || '',
      campaign: context?.campaign,
      userId: context?.user?.id,
      tab: 'stats',
      executionStatusName:
        data?.integration?.deliverable?.executionStatusName ?? null,
      executionStatusPhase:
        data?.integration?.deliverable?.executionStatusPhase ?? null,
      placementStatus: data?.status,
    }),
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressAndOrCondition: true,
      valueFormatter: function filterParamsValueFormatter(
        params: GridCallbackParams,
      ) {
        return params.value ?? '';
      },
    },
  };
}
