export default function approvalFilter(v) {
  if (v === 'Approved') {
    return 'approved yes';
  }
  if (v === 'Rejected') {
    return 'rejected no';
  }
  if (typeof v === 'string') {
    return v.toLowerCase();
  }
  return v;
}
