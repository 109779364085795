import twitchCommon from './helpers/twitchCommon';

const metric = 'gameRankDuringstream';
export default function gameRankDuringstream() {
  return {
    ...twitchCommon(metric),
    headerName: 'Game Rank During stream',
    width: 295,
  };
}
