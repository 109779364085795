import Loading from '@UIComponents/Loading/Loading';
import BaseSection from '@UIComponents/BaseSection/BaseSection';
import BaseCard from '@UIComponents/BaseCard/BaseCard';
import DeliverablesTypesList from '@UIComponents/DeliverableTypesList/DeliverableTypesList';
import { useContext, useState } from 'react';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import { nFormatter } from 'utils/nFormatter';
import { Platforms as Platform } from 'shared/types/platforms.d';
import getUTCDateAtBeginningOfDay from 'shared/dates/getUTCDateAtBeginningOfDay';
import formatDateToLocal from 'shared/dates/formatDateToLocal';
import agreementService, { AgreementCampaign } from './AgreementService';
import QuickLinksRender from './QuickLinksRender';
import DocumentsRender from './DocumentsRender';
import ContactsRender from './ContactsRender';

import './KeyDetails.scss';

interface RenderDeliverables {
  platform: Platform;
  mediaTypes: Array<string>;
}

interface RenderAgreements {
  totalBudget: string;
  launchDate: string;
  campaignViews: string;
  deliverablesTypes: Array<RenderDeliverables>;
}

function formatRenderData(campaign: AgreementCampaign): RenderAgreements {
  const totalBudget = `$${Number(
    campaign.targetAllocations || 0,
  ).toLocaleString()}`;

  const launchFrom = campaign.dateRangeFrom
    ? formatDateToLocal(getUTCDateAtBeginningOfDay(campaign?.dateRangeFrom))
    : '-';

  const launchTo = campaign.dateRangeTo
    ? formatDateToLocal(getUTCDateAtBeginningOfDay(campaign.dateRangeTo))
    : '-';

  const launchDate = `${launchFrom} - ${launchTo}`;

  const campaignViews = `${nFormatter(campaign.targetViews)} Views`;

  const deliverablesTypes = campaign.mediaTargets.reduce<
    Array<RenderDeliverables>
  >((acc, target) => {
    let findPlatform = acc.find((item) => item.platform === target.platform);

    if (!findPlatform) {
      findPlatform = {
        platform: target.platform as Platform,
        mediaTypes: [],
      };

      acc.push(findPlatform);
    }

    if (target.platform === Platform.youtube && target.style) {
      findPlatform.mediaTypes.push(`${target.style} ${target.mediaType}`);
    } else {
      findPlatform.mediaTypes.push(target.mediaType);
    }

    return acc;
  }, []);

  return {
    totalBudget,
    launchDate,
    campaignViews,
    deliverablesTypes,
  };
}

const getRenderData = (campaignId: number): RenderAgreements => {
  const campaign = agreementService(campaignId);

  if (!campaign) {
    const defaultValues: RenderAgreements = {
      totalBudget: '$0',
      launchDate: '-',
      campaignViews: '0',
      deliverablesTypes: [],
    };

    return defaultValues;
  }

  return formatRenderData(campaign);
};

const tabs = [
  {
    name: 'Quicks links',
    content: (
      <div className="quick-links">
        <QuickLinksRender />
      </div>
    ),
  },
  {
    name: 'Documents center',
    content: (
      <div className="documents-center">
        <DocumentsRender />
      </div>
    ),
  },
  {
    name: 'Contacts',
    content: (
      <div className="contacts">
        <ContactsRender />
      </div>
    ),
  },
];

function KeyDetails() {
  const [tabSelected, setTabSelected] = useState(0);

  const { campaignId } = useContext(ExternalApplicationContext);

  if (!campaignId) return <Loading />;

  const { totalBudget, launchDate, deliverablesTypes } = getRenderData(
    campaignId,
  );

  function handleTabSelection(index: number) {
    setTabSelected(index);
  }

  return (
    /* @ts-ignore */
    <BaseSection
      title="Campaign details"
      parentClassName="KeyDetails"
      type="transparent"
    >
      <div className="campaign-details-wrap">
        <div className="campaign-details-column">
          {/* @ts-ignore */}
          <BaseCard
            title="Total budget"
            titleType="simple"
            inlineBlock
            dark
            titleAlign="right"
            dataCy="CampaignSummaryTotalBudget"
          >
            <div className="numbers-display">{totalBudget}</div>
          </BaseCard>

          {/* @ts-ignore */}
          <BaseCard
            title="Campaign dates"
            titleType="simple"
            inlineBlock
            dark
            titleAlign="right"
            dataCy="CampaignSummaryLaunchDate"
          >
            <div className="numbers-display">{launchDate}</div>
          </BaseCard>
        </div>

        <div>
          {/* @ts-ignore */}
          <BaseCard
            title="Campaign deliverables"
            titleType="simple"
            dark
            titleAlign="left"
          >
            {deliverablesTypes.map((media: RenderDeliverables) => (
              <DeliverablesTypesList
                key={media.platform}
                platform={media.platform}
                types={media.mediaTypes}
              />
            ))}
          </BaseCard>
        </div>

        <div>
          {/* @ts-ignore */}
          <BaseCard
            titleType="simple"
            dark
            titleAlign="left"
            dataCy="CampaignDetailsTabSystem"
          >
            <div className="tab-system">
              <div className="tab-system-header">
                {tabs.map((item, index) => (
                  <div
                    key={index}
                    className={`tab-system-header-item ${
                      index === tabSelected && 'selected'
                    }`}
                    onClick={() => handleTabSelection(index)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <div className="tab-system-body">
                {tabs.map((item, index) => (
                  <div
                    key={index}
                    className={`tab-system-body-content ${
                      index === tabSelected && 'selected'
                    }`}
                    onClick={() => handleTabSelection(index)}
                  >
                    {item.content}
                  </div>
                ))}
              </div>
            </div>
          </BaseCard>
        </div>
      </div>
    </BaseSection>
  );
}

export default KeyDetails;
