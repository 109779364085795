import React from 'react';
import { oneOfType, arrayOf, string } from 'prop-types';

import UIIcon from 'components/UI/Icon/Icon';
import './PageError.scss';

const cbn = 'ui-page-error';

function UIPageError({ children, description }) {
  return (
    <div className={cbn}>
      <UIIcon type="warning" className={`${cbn}__icon`} />
      <div className={`${cbn}__info`}>
        <h1 className={`${cbn}__title`}>{children}</h1>
        {description && <p>{description}</p>}
      </div>
    </div>
  );
}

UIPageError.propTypes = {
  children: oneOfType([string, arrayOf(string)]),
  description: string,
};

UIPageError.defaultProps = {
  children: 'Error',
  description: null,
};

export default UIPageError;
