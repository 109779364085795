import posthog, { PostHog } from 'posthog-js';
import * as Sentry from '@sentry/react';
import { Environment } from 'types';
import { postHogConfigs } from './postHogEnvironments';

export async function loadPostHog(
  environment: Environment,
  setPostHog: (postHog: PostHog) => void,
) {
  // This example assumes you have a way to configure PostHog based on the environment
  // Adjust the logic as necessary for your application's specific requirements
  try {
    const config = postHogConfigs[environment];

    // set api_key and remaining config
    const { apiKey } = config;

    if (!apiKey) {
      return;
    }

    // remove api_key from config
    delete (config as { apiKey?: string })?.apiKey;

    posthog.init(apiKey, {
      ...config,
    });

    setPostHog(posthog);
  } catch (error) {
    Sentry.captureException(`PostHog failed to load: ${error}`);
  }
}
