import numeral from 'numeral';
import padstart from 'lodash/padStart';
import get from 'lodash/get';

export const toNumber = (value) => {
  if (!value || Number.isNaN(value)) {
    return 0;
  }

  if (typeof value === 'number') {
    return value;
  }

  const nValue = Number(value.replace(/[^\d.-]/g, ''));
  return !Number.isNaN(nValue) ? nValue : 0;
};

export const numberOrPlaceholder = (value, placeholder = '') => {
  if (!value || Number.isNaN(value)) {
    return placeholder;
  }
  return Number(value);
};

export const toPositiveNumber = (value) => {
  const number = toNumber(value);
  return number > 0 ? number : 0;
};

const getValue = (item, key) => (key ? get(item, key) : item);

export const sum = (list, key) =>
  (list || []).reduce((acc, item) => toNumber(getValue(item, key)) + acc, 0);

export function mask(value, options) {
  const {
    format,
    leftPadding,
    paddingChar,
    prefix,
    showZero,
    suffix,
    convertFalsyToZero,
    calculatePercentage,
  } = options;

  let v = value;

  if (calculatePercentage && v) {
    v *= 100;
  }

  if (!v && v !== 0 && !convertFalsyToZero) {
    return '';
  }

  if (!toNumber(v) && !showZero) {
    return '';
  }

  let masked = format ? numeral(v).format(format) : v;

  if (prefix) {
    masked = `${prefix}${masked}`;
  }

  if (suffix) {
    masked += `${suffix}`;
  }

  if (leftPadding && paddingChar) {
    masked = padstart(masked, leftPadding, paddingChar);
  }

  return masked;
}

export const roundToMillionthPlace = (modifier) =>
  (!modifier ? 0 : Math.round(modifier * 1000000) / 1000000);
