import React from 'react';
import './BaseTitle.scss';

type BaseTitleProps = {
  size?: 'small' | 'medium' | 'large';
  title: string;
};

const BaseTitle: React.FC<BaseTitleProps> = ({ size = 'medium', title = '' }) => (
  <div data-cy="BaseTitle" className={`BaseTitle BaseTitle--${size}`}>
    <span className="title-border" />
    <span>{title}</span>
  </div>
);

export default BaseTitle;
