export default {
  US: 'United States',
  GB: 'United Kingdom',
  AU: 'Australia',
  BR: 'Brazil',
  ID: 'Indonesia',
  MY: 'Malaysia',
  PH: 'Philippines',
  SA: 'Saudi Arabia',
  SG: 'Singapore',
};
