import percent from 'components/DataGrid/formatters/percent';
import number from 'components/DataGrid/formatters/number';
import { MetricsMap } from 'components/Campaign/Influencer/View/shared/columnPresets/qmMetricUtils/types';

export const EUREKA_IG_CONVERSIONS_INFERENCE =
  'eureka-ig-conversions-inference';
export const EUREKA_IG_VIEWS_INFERENCE = 'eureka-ig-views-inference';
export const EUREKA_IG_CTR_INFERENCE = 'eureka-ig-ctr-inference';

export const YT_VIEWS_INFERENCE = 'youtube-views-inference';
export const YT_CLICKS_INFERENCE = 'youtube-clicks-inference';

export const metricsMap: MetricsMap = {
  instagram: {
    botPercentage: {
      valueLabel: 'AI Bot %',
      valueType: 'bot_score',
      valueFormatter: percent,
      predictionType: 'ig-safe-inference',
      colId: 'aibotPercentage',
    },
    aiStoryViews: {
      valueLabel: 'AI Story Views',
      valueType: 'story_view_prediction',
      valueFormatter: number,
      predictionType: EUREKA_IG_VIEWS_INFERENCE,
      colId: 'aiInstagramStoryViews',
    },
    vpc: {
      valueLabel: 'AI Story VPC',
      valueType: 'vpc',
      valueFormatter: number,
      colId: 'aiInstagramStoryVPC',
    },
    clicks: {
      valueLabel: 'AI Story Clicks',
      valueType: 'clicks',
      valueFormatter: number,
      colId: 'aiInstagramStoryClicks',
    },
    ctr: {
      valueLabel: 'AI Story CTR',
      valueType: 'ctr',
      valueFormatter: percent,
      colId: 'aiInstagramStoryCTR',
    },
    cr: {
      valueLabel: 'AI Story CR',
      valueType: 'cr',
      valueFormatter: percent,
      colId: 'aiInstagramStoryCR',
    },
    conversions: {
      valueLabel: 'AI Story Conversions',
      valueType: 'conversions',
      valueFormatter: number,
      colId: 'aiInstagramStoryConversions',
    },
  },
  youtube: {
    views: {
      valueLabel: 'AI Video Views',
      valueType: 'predicted_views',
      valueFormatter: number,
      predictionType: 'youtube-views-inference',
      colId: 'aiYouTubeViews',
    },
    clicks: {
      valueLabel: 'AI Video Clicks',
      valueType: 'predicted_clicks',
      valueFormatter: number,
      predictionType: 'youtube-clicks-inference',
      colId: 'aiYouTubeClicks',
    },
    ctr: {
      valueLabel: 'AI Video CTR',
      valueType: 'ctr',
      valueFormatter: percent,
      colId: 'aiYouTubeCTR',
    },
  },
};
