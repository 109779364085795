const benMetricstoCampaignServiceMetrics: Record<
  string,
  Record<string, string>
> = {
  common: {
    views: 'view_count',
    likes: 'like_count',
    comments: 'comment_count',
    averageViewDuration: 'average_view_duration',
    averagePercentWatched: 'average_percentage_of_video_watched',
    totalMinutesWatched: 'estimatedMinutesWatched',
    videoThumbnailClicks: 'card_impressions',
    videoThumbnailImpressions: 'card_impressions',
  },
  instagram: {
    saves: 'saved',
  },
};

export default benMetricstoCampaignServiceMetrics;
