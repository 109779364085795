import React from 'react';
import UIImage from 'components/UI/Image/Image';
import UIVideo from 'components/UI/Video/Video';
import { getMediaTypeFromUrl } from 'utils/files';

type UIMediaProps = {
  alt?: string;
  className?: string;
  fallbackImage: string;
  hasMask?: boolean;
  imagecachePreset?: string;
  nonPlayable?: boolean;
  showPlayIcon?: boolean;
  ignoreThumbGen?: boolean;
  thumbnail?: string | null;
  url: string;
};

function UIMedia({
  url,
  nonPlayable,
  fallbackImage,
  imagecachePreset,
  alt,
  ignoreThumbGen,
  thumbnail,
  ...restProps
}: UIMediaProps) {
  const mediaType = getMediaTypeFromUrl(url);

  if (mediaType === 'video' && nonPlayable) {
    return (
      <UIVideo
        {...restProps}
        key={url}
        url={url}
        nonPlayable
        ignoreThumbGen={ignoreThumbGen}
        showPlayIcon={ignoreThumbGen}
        thumbnail={thumbnail}
      />
    );
  }

  if (mediaType === 'image') {
    return (
      <UIImage
        imagecachePreset={imagecachePreset}
        fallbackImage={fallbackImage}
        alt={alt}
        {...restProps}
        url={url}
      />
    );
  }

  if (mediaType === 'video') {
    return <UIVideo {...restProps} url={url} thumbnail={thumbnail} />;
  }

  return <UIImage fallbackImage="placeholderImage" alt={alt} />;
}

export default UIMedia;
