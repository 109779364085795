import React from 'react';
import PlusCircle from '@UIComponents/_assets/react_svg/PlusCircle';
import UIModal from 'components/UI/Modal/Modal';
import Form from 'components/Creator/Productions/AddSocialChannel/Form/Form';
import { Property } from 'components/Property/types';

import './AddSocialChannel.scss';
import { Campaign } from 'components/Campaign/types';
import ApplicationContext from 'components/Application/Context';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';

export default function AddSocialChannel({
  property,
  campaign,
  userId,
  refetchProperty,
}: {
  property?: Property;
  campaign: Campaign;
  userId: number;
  refetchProperty: () => void;
}) {
  const { segment } = React.useContext(ApplicationContext);
  const [showModal, setShowModal] = React.useState(false);

  const onChannelAdd = React.useCallback(() => {
    if (!segment || !property?.id || !property?.name) {
      return;
    }
    segment.track(
      SegmentEvents.CreatorProfile,
      {
        action: SegmentActions.OpenModal,
        modal: 'Add New Social Channel',
        property: {
          id: property?.id,
          name: property?.name,
        },
      },
      {
        context: { groupId: formatCampaignId(campaign?.id) },
        userId: formatUserId(userId),
      },
    );
    setShowModal(true);
  }, [campaign?.id, property?.id, property?.name, segment, userId]);

  if (!property) {
    return null;
  }

  return (
    <>
      <span className="add-channel-label">Add new social channel</span>
      <button
        onClick={onChannelAdd}
        type="button"
        className="add-channel-button"
      >
        <PlusCircle />
      </button>

      <UIModal
        className="new-channel-modal"
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <Form property={property} refetchProperty={refetchProperty} />
      </UIModal>
    </>
  );
}
