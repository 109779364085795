import React from 'react';
import { arrayOf, oneOfType, func, node, string } from 'prop-types';
import classNames from 'classnames';

function UITableRow({
  component: Component,
  children,
  className,
  dataCy,
  ...restProps
}) {
  return (
    <Component
      {...restProps}
      data-cy={dataCy}
      className={classNames('ui-table__row', className)}
    >
      {children}
    </Component>
  );
}

const child = oneOfType([func, node]);

UITableRow.propTypes = {
  children: oneOfType([child, arrayOf(child)]),
  className: string,
  component: oneOfType([func, string, node]),
};

UITableRow.defaultProps = {
  children: null,
  className: null,
  component: 'div',
};

export default UITableRow;
