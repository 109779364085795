import './SectionHeader.scss';

export default function SectionHeader({
  label,
  className,
}: {
  label: string;
  className?: string;
}) {
  return <h1 className={`section-header ${className ?? ''}`}>{label}</h1>;
}
