import { EXTERNAL_APPROVAL_STATUSES } from 'constants/approvalStatuses';

const { REJECTED, APPROVED } = EXTERNAL_APPROVAL_STATUSES;

const externalApprovalStatuses = (value) => {
  switch (value) {
    case APPROVED:
      return 'Submitted';
    case REJECTED:
      return 'Declined';
    default:
      return 'Pending';
  }
};

export const getDeliverableExternalApprovalStatus = ({
  externalApproval,
  viewedByClient,
}) => (externalApproval === null && !viewedByClient
    ? {
        state: 'new',
        text: 'New',
      }
    : {
        state: externalApproval ? externalApproval.toLowerCase() : 'pending',
        text: externalApprovalStatuses(externalApproval),
      });

export const optimisticApprovalToggleParams = ({
  externalApproval,
  id,
  messagesCount,
  viewedByClient,
  rejectedReason,
}) => ({
  variables: {
    id,
    payload: {
      externalApproval,
      rejectedReason,
    },
  },

  optimisticResponse: {
    updateDeliverableExternalApproval: {
      externalApproval,
      id,
      messagesCount: {
        ...messagesCount,
        __typename: 'MessagesResultMeta',
      },
      rejectedReason,
      viewedByClient,
      __typename: 'Deliverable',
    },
  },
});

export const optimisticSetViewedByClientParams = ({ id }) => ({
  variables: {
    deliverableId: id,
  },

  optimisticResponse: {
    updateViewedByClientDeliverable: {
      id,
      viewedByClient: true,
      __typename: 'Deliverable',
    },
  },
});
