import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';
import getYouTubeDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/youTube/helpers/getYouTubeDataFromCampaignService';
import { VIDEO_MEDIA_TYPE, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';

const metric = 'videoThumbnailImpressions';
export function videoThumbnailImpressionsAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, 'card_impressions');
  }
  return null;
}

export default function videoThumbnailImpressions() {
  return {
    ...basePerformanceColDef(
      metric,
      videoThumbnailImpressionsAutomatedValueGetter,
      [YOUTUBE_PLATFORM],
      [VIDEO_MEDIA_TYPE],
    ),
    headerName: 'Video Thumbnail Impressions',
    width: 320,
  };
}
