import { Placement } from 'components/Placement/types';
import { MediaType } from 'shared/types/mediaTypes';
import MetricType from 'shared/types/MetricType';

function getPlatformMetric(
  data: Placement,
  mediaType: MediaType | null | undefined,
  metricType: MetricType,
) {
  if (typeof mediaType !== 'string') {
    return null;
  }
  return data?.platformMetrics?.[mediaType]?.[metricType];
}

export default getPlatformMetric;
