import twitchCommon from './helpers/twitchCommon';

const metric = 'totalLiveViews';
export default function totalLiveViews() {
  return {
    ...twitchCommon(metric),
    headerName: 'Total Live Views',
    width: 225,
  };
}
