import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';
import './BaseButton.scss';

type BaseButtonProps = {
  variant?: 'contained' | 'outlined' | 'text' | 'link';
  color?: 'red' | 'purple' | 'grey' | 'grey-dark';
  disabled?: boolean;
  href?: string;
  icon?: string;
  onClick?: React.MouseEventHandler;
  rounded?: boolean | 'rounded-sm';
  size?:
    | 'x-small'
    | 'small'
    | 'mid-small'
    | 'medium'
    | 'large'
    | 'huge'
    | 'x-huge';
  target?: '_blank' | '_self' | '_parent' | '_top';
  text: string;
  type?: 'button' | 'submit';
  className?: string;
};

const getIconSize = (size: string) => {
  switch (size) {
    case 'x-huge':
      return 'large';
    case 'huge':
      return 'medium';
    case 'large':
      return 'mid-small';
    case 'small':
      return 'x-small';
    default:
      return 'small';
  }
};

const getContent = (text: string, size: string, icon?: string) => {
  const iconSize = icon ? getIconSize(size) : undefined;
  return (
    <>
      {icon ? (
        <div className="icon-wrapper">
          <BaseIcon icon={icon} size={iconSize} />
        </div>
      ) : null}
      {text}
    </>
  );
};

export default function BaseButton({
  variant = 'contained',
  color = 'red',
  disabled,
  href,
  icon,
  onClick,
  rounded = false,
  size = 'medium',
  target = '_self',
  text,
  type = 'button',
  className,
}: BaseButtonProps) {
  const roundedClass =
    typeof rounded === 'string' ? rounded : rounded && 'rounded';

  const mergedClassName = `BaseButton ${roundedClass} ${
    className ?? ''
  } ${variant} ${size} ${color}`;

  if (href) {
    return (
      <a
        data-cy="BaseButton"
        className={mergedClassName}
        href={href}
        target={target}
        onClick={onClick}
      >
        {getContent(text, size, icon)}
      </a>
    );
  }

  return (
    <button
      data-cy="BaseButton"
      className={mergedClassName}
      disabled={disabled}
      onClick={onClick}
      type={type === 'button' ? 'button' : 'submit'}
    >
      {getContent(text, size, icon)}
    </button>
  );
}
