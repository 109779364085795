import React from 'react';
import UrlIcon from '@UIComponents/UrlIcon/UrlIcon';

import './CampaignInfo.scss';

export default function CampaignInfo({
  brandLogo,
  campaignName,
  drawer,
}: {
  brandLogo: string;
  campaignName: string;
  drawer: 'order' | 'spark-ads';
}) {
  const [showBrandLogo, setShowBrandLogo] = React.useState(
    Boolean(brandLogo?.length),
  );

  return (
    <section className={`tiktok-${drawer}__campaign-info`}>
      {showBrandLogo ? (
        <UrlIcon
          url={brandLogo}
          alt="brand_logo"
          size="x-large"
          onError={() => {
            setShowBrandLogo(false);
          }}
        />
      ) : null}

      <p>{campaignName}</p>
    </section>
  );
}
