import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames';
import UIIcon from '../Icon';

import './Button.scss';

class UIIconButton extends Component {
  static propTypes = {
    type: string,
    typeOverwrite: string,
    selected: bool,
    disabled: bool,
    onClick: func,
    id: string,
    enableMpControls: bool,
    className: string,
    tag: string,
    width: string,
  };

  static defaultProps = {
    type: 'allocated',
    typeOverwrite: null,
    selected: false,
    disabled: false,
    onClick: null,
    id: null,
    enableMpControls: true,
    className: '',
    tag: 'span',
    width: '',
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(ev) {
    const {
      enableMpControls, onClick, disabled, type, typeOverwrite, id,
    } = this.props;
    if (!enableMpControls || !onClick || disabled) {
      return null;
    }
    return onClick(typeOverwrite || type, id, ev);
  }

  render() {
    const {
      selected,
      disabled,
      enableMpControls,
      className,
      tag: Tag,
      width,
      id,
      ...restProps
    } = this.props;

    const wrapperClass = classNames(
      'iconbutton',
      {
        [`iconbutton--${width}`]: width,
        'is-hidden': !enableMpControls,
        'is-disabled': disabled,
        'is-active': selected,
      },
      className,
    );

    return (
      <Tag
        type="button"
        className={wrapperClass}
        onClick={this.onClick}
        disabled={disabled}
        id={id}
      >
        <UIIcon {...restProps} />
      </Tag>
    );
  }
}

export default UIIconButton;
