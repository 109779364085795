import percent from 'components/DataGrid/formatters/percent';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { Placement } from 'shared/types/Placement';
import { YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import basePerformanceColDef from './baseColDef';
import getYouTubeDistributionDataFromCampaignService from '../youTube/helpers/getYouTubeDistributionDataFromCampaignService';

const youTubeMetric = 'viewers_by_age_group';
const youTubeMetricKey = 'Age65-';
const metric = 'age-65';

export function age5564AutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDistributionDataFromCampaignService(
      data,
      [youTubeMetric, youTubeMetricKey],
      { parsePercentageFromString: true },
    );
  }
  return null;
}

export default function age65() {
  return {
    ...percentColDef,
    ...basePerformanceColDef(metric, age5564AutomatedValueGetter, [
      YOUTUBE_PLATFORM,
    ]),
    headerName: 'Age 65+',
    width: 165,
    valueFormatter: ({ value }: { value?: number | null }) =>
      percent({ value }),
  };
}
