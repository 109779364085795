import { Analytics } from '@segment/analytics-next';
import ProgressionTable, {
  ProgressionRow,
} from '@UIComponents/ProgressionTable/ProgressionTable';
import Overlay from 'components/Creator/UnderConstruction/Overlay';
import {
  Production,
  Audience as AudienceType,
} from 'components/Production/types';
import { Property } from 'components/Property/types';
import React from 'react';
import { ALLOWED_PRODUCTION_AUTHED_STATUS } from 'shared/productions/constants';
import UnauthorizedWarning from '../../../UnauthorizedWarning/UnauthorizedWarning';
import { checkIfDemographicsHaveData } from '../../../utils/checkIfDemographicsHaveData';
import trackMissingDemographics from '../../../utils/segment/trackMissingDemographics';
import {
  getAgeDemographics,
  getGenderDemographics,
} from '../../utils/tableRows';

import './Audience.scss';

export default function Audience({
  audienceMetrics,
  production,
  segment,
  property,
  campaignId,
  userId,
}: {
  audienceMetrics: AudienceType | null | undefined;
  production: Production;
  segment?: Analytics | null;
  property?: Property;
  campaignId?: number;
  userId?: number;
}) {
  const onTrackMissingDemographics = React.useCallback(() => {
    trackMissingDemographics(segment, property, production, campaignId, userId);
  }, [segment, property, production, campaignId, userId]);

  const {
    ageDemographics,
    genderDemographics,
  }: {
    ageDemographics: ProgressionRow[];
    genderDemographics: ProgressionRow[];
  } = React.useMemo(
    () => ({
      ageDemographics: getAgeDemographics(audienceMetrics),
      genderDemographics: getGenderDemographics(audienceMetrics),
    }),
    [audienceMetrics],
  );

  const onCheckIfDemographicsHaveData = React.useMemo(
    () => checkIfDemographicsHaveData(ageDemographics, genderDemographics),
    [ageDemographics, genderDemographics],
  );

  return (
    <section className="youtube-audience-container">
      <h2>Audience</h2>

      {ALLOWED_PRODUCTION_AUTHED_STATUS.includes(
        production?.authStatus ?? '',
      ) ? (
        <div className="youtube-audience-container__progression-tables">
          <ProgressionTable
            title="Age Range"
            subtitle="AUTH"
            rows={ageDemographics}
            className="youtube-age-range"
          />

          <ProgressionTable
            title="Gender"
            subtitle="AUTH"
            rows={genderDemographics}
            className="youtube-gender"
          />
          <Overlay
            showOverlay={!onCheckIfDemographicsHaveData}
            onOverlayOpenCb={onTrackMissingDemographics}
          />
        </div>
      ) : (
        <UnauthorizedWarning />
      )}
    </section>
  );
}
