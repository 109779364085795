import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InternalRoutePrivate from 'components/Route/Private/Private';
import { getDefaultLocation } from 'external/utils/auth';

function RoutePrivate({ ...restProps }) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <InternalRoutePrivate
            {...restProps}
            externalContext
            getLocation={getDefaultLocation}
          />
        }
      />
    </Routes>
  );
}

export default RoutePrivate;
