import React from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

import './Tags.scss';

const TagsEditor = React.forwardRef(
  (
    {
      value,
    }: {
      value: string[] | null;
    },
    ref,
  ) => {
    const [innerValue, setInnerValue] = React.useState(value);
    const tagsInputRef = React.useRef<HTMLInputElement>(null);

    const onChange = React.useCallback(
      /* @ts-ignore */
      (ev) => {
        setInnerValue(ev);
      },
      [setInnerValue],
    );

    React.useImperativeHandle(ref, () => ({
      getValue: () => innerValue,
    }));

    React.useEffect(() => {
      if (tagsInputRef.current) {
        tagsInputRef.current.focus();
      }
    }, []);

    return (
      <TagsInput
        className="custom-tags-editor"
        ref={tagsInputRef}
        value={innerValue ?? []}
        onChange={onChange}
        addKeys={[9, 13, 188]}
      />
    );
  },
);
TagsEditor.displayName = 'TagsEditor';
export default TagsEditor;
