import UpsertPostCustomColumn from 'components/Campaign/Influencer/View/Stats/Performance/graphql/UpsertPostCustomColumn.graphql';
import { Placement } from 'shared/types/Placement';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import { ColDef } from 'ag-grid-community';

type NewValue = string | { value: string | string[] | null } | null;
type Value = string | boolean | null;

export function onCellValueChanged({
  data,
  context,
  newValue,
  colDef,
}: {
  data: Placement;
  context: Context;
  newValue: NewValue;
  colDef: ColDef;
}) {
  const payload: {
    uuid?: string;
    placement?: Partial<Placement>;
    tab?: string;
    value?: NewValue;
  } = {};
  if (context?.tab === 'stats') {
    payload.uuid = colDef?.field;
    payload.placement = { id: data?.id };
    payload.tab = 'performance';
  }
  if (
    typeof newValue === 'string' ||
    newValue === null ||
    typeof newValue === 'boolean' ||
    Array.isArray(newValue) ||
    Number.isFinite(newValue)
  ) {
    payload.value = { value: newValue as string | string[] | null };
  } else {
    payload.value = newValue;
  }

  if (context?.apolloClient?.mutate) {
    context.apolloClient.mutate({
      mutation: UpsertPostCustomColumn,
      variables: {
        payload,
      },
    });
  }
}

export function valueGetterObject({
  data,
  colDef,
}: {
  data: Placement;
  colDef: ColDef;
}) {
  if (!data?.postCustomColumns?.length) {
    return null;
  }
  const found = data.postCustomColumns.find(
    (col) => col?.uuid === colDef?.field,
  );

  return found?.value ?? null;
}

export function valueGetterSimpleValue({
  data,
  colDef,
}: {
  data: Placement;
  colDef: ColDef;
}) {
  if (!data?.postCustomColumns?.length) {
    return null;
  }
  const found = data.postCustomColumns.find(
    (col) => col?.uuid === colDef?.field,
  );

  const foundValue = found?.value?.value ?? null;

  if (foundValue && !Number.isNaN(Number(foundValue))) {
    return Number(foundValue);
  }

  return foundValue;
}

export function addValueToValueSetter(
  data: Placement,
  colDef: ColDef,
  value: { value: string | boolean | null | string[] } | null,
  context: Context,
) {
  const upsertedColumn = (data?.postCustomColumns ?? []).find(
    (col) => col?.uuid === colDef?.field,
  );

  if (upsertedColumn) {
    // eslint-disable-next-line no-param-reassign
    data.postCustomColumns = [...(data?.postCustomColumns ?? [])].map((col) => {
      if (col?.uuid === colDef?.field) {
        return {
          ...col,
          value,
        };
      }

      return col;
    });
  } else {
    // eslint-disable-next-line no-param-reassign
    data.postCustomColumns = [
      ...(data?.postCustomColumns ?? []),
      {
        tab: context?.tab === 'stats' ? 'performance' : context?.tab,
        uuid: colDef?.field,
        value,
      },
    ];
  }
}

export function valueSetterSimpleValue({
  data,
  oldValue,
  newValue,
  colDef,
  context,
}: {
  data: Placement;
  colDef: ColDef;
  context: Context;
  oldValue: Value;
  newValue: Value;
}) {
  if (newValue === oldValue) {
    return false;
  }
  addValueToValueSetter(data, colDef, { value: newValue }, context);
  return true;
}

export function valueSetterObject({
  data,
  oldValue,
  newValue,
  colDef,
  context,
}: {
  data: Placement;
  colDef: ColDef;
  context: Context;
  oldValue: { value: Value };
  newValue: { value: Value };
}) {
  if (newValue === oldValue) {
    return false;
  }
  addValueToValueSetter(data, colDef, newValue, context);
  return true;
}
