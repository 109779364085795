import UIImage from 'components/UI/Image/Image';
import UILoader from 'components/UI/Loader/Loader';

import { Video } from './types';
import mapRecommendationLevel from './utils/mapRecommendationLevel';

import './RecommendedVideos.scss';

export default function RecommendedVideos({
  videos,
  loading,
}: Readonly<{ videos: Video[]; loading: boolean }>) {
  if (loading) {
    return <UILoader />;
  }

  return (
    <section className="recommended-videos">
      {(videos ?? []).map((video) => (
        <div className="recommended-video" key={video.item_id}>
          <a href={video.embed_url} target="_blank" rel="noreferrer">
            <UIImage url={video.thumbnail_url} />
          </a>
          <strong>{mapRecommendationLevel(video.recommendation_level)}</strong>
          <p>{video.creator_handle}</p>
          <p>{video.item_id}</p>
        </div>
      ))}
    </section>
  );
}
