import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import infoGlyph from 'components/assets/img/tooltip_icon.svg';
import Content from './Content/Content';
import './Tooltip.scss';
import 'tippy.js/dist/tippy.css';

const cbn = 'ui-tooltip';

function UITooltip({
  id,
  entity,
  name,
  keys,
  fields,
  className,
  customComponent,
  tooltips,
  additionalVariables,
}) {
  const hasAdditionalVariables = React.useMemo(
    () => Boolean(Object.keys(additionalVariables ?? {})?.length),
    [additionalVariables],
  );
  const GET_TOOLTIP = gql`
    query Tooltips(
      $id: Int!
      $keys: [String!]!
      $fields: [String!]!
      ${hasAdditionalVariables ? ', $additionalVariables: JSON' : ''}
    ) {
      ${entity}(id: $id) {
        id
        tooltips(keys: $keys, fields: $fields ${
          hasAdditionalVariables
            ? ', additionalVariables: $additionalVariables'
            : ''
        })
      }
    }
  `;

  const [loadTooltip, { data, loading, error }] = useLazyQuery(GET_TOOLTIP, {
    fetchPolicy: 'network-only',
  });

  const variables = React.useMemo(() => {
    const obj = {
      id,
      keys: keys ?? [name],
      fields,
    };

    if (hasAdditionalVariables) {
      obj.additionalVariables = additionalVariables;
    }

    return obj;
  }, [additionalVariables, fields, hasAdditionalVariables, id, keys, name]);

  if (!id || !fields || !name) {
    return null;
  }

  return (
    <Tippy
      className={classnames(cbn, 'tippy-tooltip google-theme', className)}
      content={
        <Content
          loading={loading}
          error={error}
          data={data}
          entity={entity}
          name={name}
          customComponent={customComponent ?? null}
          theme="light"
        />
      }
    >
      <img
        src={infoGlyph}
        alt="Tooltip Icon"
        onMouseEnter={() =>
          (tooltips
            ? null
            : loadTooltip({
                variables,
              }))
        }
      />
    </Tippy>
  );
}

export default UITooltip;
