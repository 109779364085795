import React from 'react';
import {
  func,
  arrayOf,
  shape,
  bool,
  objectOf,
  number,
  string,
} from 'prop-types';
import { getFileType } from '../utils';

import './FileList.scss';

const cbn = 'form-file-uploader-file-list';

function FormFileUploaderFileList({
  disabled,
  disablePreview,
  disableSort,
  downloadingById,
  files,
  itemComponent: ItemComponent,
  onDownload,
  onFileRemove,
  progressByUrl,
  errorsByUrl,
  sorting,
}) {
  return (
    <div className={cbn}>
      {files.map((file, index) => (
        <ItemComponent
          className={`${cbn}-list__item`}
          disablePreview={disablePreview}
          downloading={downloadingById[file.id]}
          error={errorsByUrl[file.url]}
          file={file}
          key={file.key || file.url}
          onDownload={(e) => onDownload(e, file)}
          onRemove={() => onFileRemove(index, file.url)}
          progress={progressByUrl[file.url]}
          showRemoveButton={!sorting && !disabled}
          type={getFileType(file)}
          /* sortable props */
          disabled={disableSort || disabled}
          index={index}
        />
      ))}
    </div>
  );
}

FormFileUploaderFileList.propTypes = {
  disabled: bool,
  disablePreview: bool,
  disableSort: bool,
  downloadingById: objectOf(bool).isRequired,
  files: arrayOf(shape({})),
  itemComponent: func.isRequired,
  onDownload: func,
  onFileRemove: func.isRequired,
  progressByUrl: objectOf(number).isRequired,
  errorsByUrl: objectOf(string).isRequired,
  sorting: bool,
};

FormFileUploaderFileList.defaultProps = {
  disabled: false,
  disablePreview: false,
  disableSort: false,
  files: [],
  onDownload: () => {},
  sorting: false,
};

export default FormFileUploaderFileList;
