// @ts-nocheck
import React from 'react';
import UIIcon from 'components/UI/Icon/Icon';

import './DownloadButton.scss';

const cbn = 'form-file-uploader-download-button';

type FormFileUploaderDownloadButtonProps = WithChildren<{
  allowDownload: boolean;
  downloading: boolean;
  onClick: (event: React.MouseEvent) => void;
  url: string;
}>;

function FormFileUploaderDownloadButton({
  allowDownload,
  children,
  downloading,
  onClick,
  url,
}: FormFileUploaderDownloadButtonProps) {
  const RenderNode = allowDownload ? 'a' : 'div';
  const nodeProps = RenderNode === 'div' ? {} : { onClick, href: url };

  return (
    <RenderNode className={cbn} {...nodeProps}>
      {children}
      {allowDownload && <span className={`${cbn}__download`}>Download</span>}
      {downloading && <UIIcon type="download" className={`${cbn}__loader`} />}
    </RenderNode>
  );
}

FormFileUploaderDownloadButton.defaultProps = {
  children: null,
};

export default FormFileUploaderDownloadButton;
