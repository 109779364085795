import * as Sentry from '@sentry/react';
import { MediaType } from 'shared/types/mediaTypes';
import { Placement } from 'components/Placement/types';
import { Analytics } from '@segment/analytics-next';
import { Campaign } from 'components/Campaign/types';
import { User } from 'components/User/types';
import {
  formatCampaignId,
  formatRemoteId,
  formatUserId,
} from 'components/Segment/utils';
import { Platforms } from 'shared/types/platforms';
import { SegmentExternalEvents } from './segmentExternalEvents';

export enum BrandPostReviewActions {
  Approved = 'approved',
  Declined = 'declined',
  Undeclined = 'undeclined',
  Redirected = 'redirected',
  ChatMessage = 'chat-message',
  Viewed = 'viewed',
}

interface IPostBaseParams {
  remote_id: string;
  plaform?: Platforms | undefined;
  media_type: MediaType | null | undefined;
  post_id: number | undefined;
  creator_id: number;
  creator_name: string;
  action: BrandPostReviewActions;
  message?: string;
}

interface ITrackPostParams {
  segment: Analytics;
  campaign: Campaign;
  user: User;
  placement: Placement;
  message?: string;
}

interface ITrackPostParamsMessageRequired extends ITrackPostParams {
  message: string;
}

// New schema
function getPostProps(
  reviewType: BrandPostReviewActions,
  placement: Placement,
  remoteId?: string,
  message?: string,
): IPostBaseParams {
  const baseParams = {
    remote_id: formatRemoteId(placement.platform, remoteId),
    platform: placement.platform,
    media_type: placement.mediaType,
    post_id: placement.id,
    creator_id: placement.source?.production?.property?.id,
    creator_name: placement.source?.production?.property?.name,
    action: reviewType,
  } as IPostBaseParams;

  switch (reviewType) {
    case BrandPostReviewActions.Declined:
    case BrandPostReviewActions.ChatMessage: {
      return { ...baseParams, message };
    }

    default:
      return baseParams;
  }
}

function isValidPlacement(placement: Placement) {
  if (!placement?.platform) return false;

  const creatorId = placement.source?.production?.property?.id;
  const creatorName = placement.source?.production?.property?.name;
  const remoteId =
    placement.source?.production?.channelId ||
    placement.source?.production?.username;

  return !(!remoteId || !creatorId || !creatorName);
}

function trackPostActions(
  reviewType: BrandPostReviewActions,
  { segment, campaign, user, placement, message }: ITrackPostParams,
) {
  const isValid = isValidPlacement(placement);

  if (!isValid) {
    Sentry.captureMessage(
      `Unable to send segment event: Placement has missing fields. ${JSON.stringify(
        placement,
      )}`,
    );
    return;
  }

  const remoteId =
    placement.platform === 'youtube'
      ? placement.source?.production?.channelId
      : placement.source?.production?.username;

  const props = getPostProps(reviewType, placement, remoteId, message);

  const context = {
    context: { groupId: formatCampaignId(campaign.id) },
    userId: formatUserId(user.id),
  };

  segment.track(SegmentExternalEvents.BrandPostReview, props, context);
}

function trackPostApproved(params: ITrackPostParams) {
  trackPostActions(BrandPostReviewActions.Approved, params);
}

function trackPostViewed(params: ITrackPostParams) {
  trackPostActions(BrandPostReviewActions.Viewed, params);
}

function trackPostRedirected(params: ITrackPostParams) {
  trackPostActions(BrandPostReviewActions.Redirected, params);
}

function trackPostDeclined(params: ITrackPostParamsMessageRequired) {
  trackPostActions(BrandPostReviewActions.Declined, params);
}

function trackPostChatClicked(params: ITrackPostParamsMessageRequired) {
  trackPostActions(BrandPostReviewActions.ChatMessage, params);
}

export {
  trackPostApproved,
  trackPostViewed,
  trackPostRedirected,
  trackPostDeclined,
  trackPostChatClicked,
};
