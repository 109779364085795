import React from 'react';
import * as Sentry from '@sentry/react';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import { Campaign } from 'components/Campaign/types';
import { Property } from 'components/Property/types';
import ApplicationContext from 'components/Application/Context';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import { Production } from 'components/Production/types';

export default function useCard(
  campaign?: Campaign,
  userId?: number,
  tab?: string,
  production?: Production,
  property?: Property,
  initialOpenPlatforms?: string[],
) {
  const { segment } = React.useContext(ApplicationContext);

  const [collapsed, setCollapsed] = React.useState(
    !(initialOpenPlatforms ?? []).includes(production?.type ?? ''),
  );

  const trackChannelOpen = React.useCallback(() => {
    if (
      !segment ||
      !property?.id ||
      !property?.name ||
      !campaign?.id ||
      !userId
    ) {
      return;
    }
    segment.track(
      SegmentEvents.CreatorProfile,
      {
        action: SegmentActions.CardOpenedChannel,
        tab,
        platform: production?.type,
        property: {
          id: property?.id,
          name: property?.name,
        },
      },
      {
        context: { groupId: formatCampaignId(campaign?.id) },
        userId: formatUserId(userId),
      },
    );
  }, [
    segment,
    production?.type,
    tab,
    userId,
    property?.id,
    property?.name,
    campaign?.id,
  ]);

  const handleCollapse = React.useCallback(
    (currentCollapsed: boolean) => {
      setCollapsed(!currentCollapsed);

      if (
        !segment ||
        !property?.id ||
        !property?.name ||
        !campaign?.id ||
        !userId
      ) {
        return;
      }
      try {
        segment.track(
          SegmentEvents.CreatorProfile,
          {
            action: SegmentActions.CardCollapse,
            tab,
            property: {
              id: property?.id,
              name: property?.name,
            },
          },
          {
            context: { groupId: formatCampaignId(campaign?.id) },
            userId: formatUserId(userId),
          },
        );
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [
      setCollapsed,
      campaign,
      segment,
      tab,
      userId,
      property?.id,
      property?.name,
    ],
  );

  return {
    handleCollapse,
    collapsed,
    segment,
    trackChannelOpen,
  };
}
