// @ts-nocheck
import React, { ComponentType, forwardRef, createRef, memo } from 'react';
import classnames from 'classnames';
import Select, {
  Props as SelectProps,
  SelectComponentsConfig,
} from 'react-select';
import { clearClassName, stringSearch } from 'utils/string';
import UIList from 'components/UI/List/List';
import TagValue from './TagValue/TagValue';
import LabelValue from './LabelValue/LabelValue';
import LabelOption from './LabelOption/LabelOption';
import MenuList from './MenuList/MenuList';
import ClearIndicator from './ClearIndicator/ClearIndicator';
import Input from './Input/Input';
import { noOptionsMessage, parseValue, isKey, populateValue } from './utils';
import { ReadOnlySingleValueProps, FormSelectorOptionType } from './types';

import './Selector.scss';

const defaultGetOptionLabel = (labelKey) => (option) =>
  option && option[labelKey];
const defaultGetOptionValue = (valueKey) => (opt) => {
  if (!opt) return opt;

  return isKey(opt) ? opt : opt[valueKey];
};

const defaultFilterOption = (toLabel) => (opt, search) =>
  stringSearch(toLabel(opt), search);

const cbn = 'form-selector';

export type FormSelectorProps<
  Option = FormSelectorOptionType,
  IsMulti extends boolean,
> = SelectProps<Option, IsMulti> & {
  cbn: string;
  className: string;
  classNamePrefix: string;
  delay: number;
  components: SelectComponentsConfig<Option> & {
    ReadOnlySingleValue?: ComponentType<ReadOnlySingleValueProps>;
  };
  hasMinLength: boolean;
  hasOffset: boolean;
  idsOnly: boolean;
  ignoreOptionFilter: boolean;
  multi?: boolean;
  labelKey: string;
  name: string;
  readOnly: boolean;
  placeholder: string;
  valueKey: string;
  hasMore: boolean;
  loadMore: () => void;
  options: Option[];
};

/* eslint-disable complexity */
const FormSelector = forwardRef<any, FormSelectorProps>(
  (
    {
      className,
      classNamePrefix,
      closeMenuOnSelect,
      components,
      disabled,
      emptyValue: parentEmptyValue,
      error,
      filterOption: parentFilterOption,
      getOptionLabel,
      getOptionValue,
      hasMinLength,
      hasOffset,
      idsOnly,
      ignoreOptionFilter,
      isClearable,
      isLoading,
      isMulti: parentIsMulti,
      labelKey,
      multi,
      name,
      noResultsMessage,
      onChange,
      onFocus,
      onMenuOpen,
      options,
      placeholder,
      readOnly,
      styles,
      value,
      valueKey,
      delay,
      ...restProps
    }: FormSelectorProps,
    ref,
  ) => {
    const toValue = getOptionValue || defaultGetOptionValue(valueKey);
    const toLabel = getOptionLabel || defaultGetOptionLabel(labelKey);
    const filterOption = parentFilterOption || defaultFilterOption(toLabel);
    const populatedValue = populateValue(toValue, options, value);
    const isMulti = multi || parentIsMulti;

    if (readOnly) {
      if (!value) {
        return <div className={`${cbn}--display-only`}>N/A</div>;
      }

      const ReadOnlySingleValue =
        components.ReadOnlySingleValue || (() => toLabel(populatedValue));
      return (
        <div className={`${cbn}--display-only`}>
          {Array.isArray(populatedValue) ? (
            <UIList>
              {populatedValue.map((item) => (
                <span key={toValue(item)} className={`${cbn}__value__item`}>
                  {toLabel(item)}
                </span>
              ))}
            </UIList>
          ) : (
            populatedValue && (
              <ReadOnlySingleValue
                data={populatedValue}
                selectProps={restProps}
              />
            )
          )}
        </div>
      );
    }

    const defaultEmpty = isMulti ? [] : null;
    const emptyValue =
      parentEmptyValue !== undefined ? parentEmptyValue : defaultEmpty;

    return (
      <Select
        {...restProps}
        ref={ref}
        name={name}
        options={options}
        value={populatedValue}
        className={classnames(
          name && clearClassName(`${cbn}--${name}`),
          className,
          cbn,
          {
            [`${cbn}--is-multi`]: isMulti,
            [`${cbn}--has-error`]: error,
            [`${cbn}--has-offset`]: hasOffset,
          },
        )}
        classNamePrefix={`${classNamePrefix} ${cbn}`}
        closeMenuOnSelect={closeMenuOnSelect ?? !isMulti}
        components={{
          ClearIndicator: components.ClearIndicator || ClearIndicator,
          DropdownIndicator: () => (
            <span className={`${cbn}__dropdown-indicator`} />
          ),
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
          MenuList,
          MultiValue: components.MultiValue || TagValue,
          Option: components.Option || LabelOption,
          SingleValue: components.SingleValue || LabelValue,
          Input,
          ...components,
        }}
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          ...styles,
        }}
        isDisabled={readOnly || disabled || readOnly}
        readOnly={readOnly}
        getOptionLabel={toLabel}
        getOptionValue={toValue}
        isLoading={isLoading}
        isMulti={isMulti}
        noOptionsMessage={() =>
          noOptionsMessage(
            isLoading,
            hasMinLength,
            placeholder,
            noResultsMessage,
          )
        }
        onChange={(selected) => {
          const parsedValue =
            parseValue(selected, toValue, idsOnly) ?? emptyValue;

          if (onChange) {
            onChange(parsedValue, selected);
          }
        }}
        onFocus={onFocus}
        onMenuOpen={onMenuOpen}
        placeholder={placeholder}
        isClearable={isClearable}
        filterOption={ignoreOptionFilter ? () => true : filterOption}
        delay={delay}
      />
    );
  },
);

FormSelector.defaultProps = {
  className: null,
  classNamePrefix: null,
  closeMenuOnSelect: null,
  components: {},
  disabled: false,
  emptyValue: undefined,
  error: null,
  filterOption: null,
  getOptionLabel: null,
  getOptionValue: null,
  hasMinLength: true,
  hasOffset: false,
  idsOnly: false,
  ignoreOptionFilter: false,
  isClearable: true,
  isLoading: false,
  isMulti: false,
  labelKey: 'label',
  multi: false,
  name: '',
  noResultsMessage: 'No Results Found',
  onChange: () => {},
  onMenuOpen: () => {},
  options: [],
  placeholder: 'Select...',
  readOnly: false,
  selectorRef: createRef(),
  styles: null,
  value: undefined,
  valueKey: 'value',
  delay: 0,
};

export { LabelValue, TagValue };

export default memo(FormSelector);
