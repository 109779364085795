import getUTCDateAtBeginningOfDay from './getUTCDateAtBeginningOfDay';

export default function getHtmlLocalDate(value: Date | string | null): string {
  /**
   * Get the local date at the beginning of the day for the valur provided.
   */
  if (!value) {
    return '';
  }
  /**
   * Return an HTML date.
   */
  try {
    const utcdate = getUTCDateAtBeginningOfDay(value);
    if (!utcdate) {
      return '';
    }
    const year = utcdate?.getFullYear();
    let month: string | number = utcdate.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let date: string | number = utcdate.getDate();
    if (date < 10) {
      date = `0${date}`;
    }

    return `${year}-${month}-${date}`;
  } catch (e) {
    return '';
  }
}
