import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import tikTokCommon from 'components/Campaign/Influencer/View/Stats/Performance/columns/tikTok/helpers/tikTokCommon';

const tikTokMetric = 'fct_lifetime_video_performance.video_views_by_source_search' as keyof typeof TCMReportMetrics;
const metric = 'video_views_by_source-search';

export default function searchTraffic() {
  return {
    ...numberColDef,
    ...tikTokCommon(tikTokMetric, metric),
    headerName: 'Search Traffic',
    width: 205,
  };
}
