import Drawer, { Size } from '@UIComponents/Drawer/Drawer';
import DrawerSize from '@UIComponents/_types/types';
import MediaReview from 'components/Placement/PlacementDrawer/Drawer/MediaReview/MediaReview';
import { Placement } from 'shared/types/Placement';
import './PlacementDrawer.scss';

export default function PlacementDrawer({
  placement,
  showDrawer = false,
  size = Size.xlarge,
  fluid = false,
  overlay = false,
  onRequestClose,
}: {
  placement?: Placement | null;
  showDrawer?: boolean;
  size?: DrawerSize;
  fluid?: boolean;
  overlay?: boolean;
  onRequestClose?: () => void;
}) {
  return (
    <Drawer
      isOpen={showDrawer}
      size={size}
      fluid={fluid}
      overlay={overlay}
      onRequestClose={onRequestClose}
    >
      <section className="placement-drawer-content">
        <p className="drawer-content-area__placement-link">
          Placement link:{' '}
          <a
            href={`/team/placements/${placement?.id}/edit`}
            target="_blank"
            rel="noreferrer"
          >
            <span data-cy="placement-link-id">{`#${placement?.id}`}</span>
          </a>
        </p>
        <MediaReview id={placement?.id} />
      </section>
    </Drawer>
  );
}
