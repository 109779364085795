import React from 'react';
import get from 'lodash/get';
import UILoader from 'components/UI/Loader/Loader';
import Icon from 'components/UI/Icon/Icon';

export default function Content({
  loading,
  error,
  data,
  entity,
  name,
  customComponent,
}: {
  loading?: boolean;
  error?: string;
  entity?: string;
  name?: string;
  customComponent?: React.FunctionComponent | null;
  data: {
    [index: string]: {
      tooltips: {
        [index: string]: {
          tooltip: string;
          additionalInfo: string;
          customObject: any;
        };
      };
    };
  };
}) {
  if (loading) {
    return <UILoader size="x-small" />;
  }
  if (error) {
    return <Icon type="close" />;
  }

  if (Object.keys(data ?? {}).length && entity && name) {
    const { tooltips } = data?.[entity] ?? {};
    const { tooltip, additionalInfo, customObject } =
      get(tooltips, name ?? '') ?? {};
    if (customObject && customComponent) {
      return customComponent(customObject);
    }

    if (additionalInfo) {
      return (
        <div className="aditionalInfo">
          <p>{tooltip}</p>
          <p>{additionalInfo}</p>
        </div>
      );
    }

    return tooltip ?? 'No tooltips exist for this field.';
  }

  return null;
}
