import React from 'react';
import BaseTitle from '../BaseTitle/BaseTitle';
import FigmaIcon from '../FigmaIcon/FigmaIcon';
import './BaseCard.scss';

type TitleType = 'base-title' | 'simple' | 'boxed';

type BaseCardProps = {
  title?: string;
  titleAlign?: 'left' | 'center' | 'right';
  titleType?: TitleType;
  inlineBlock?: boolean;
  dark?: boolean;
  titleIcon?: string;
  transparent?: boolean;
  titleSize?: 'small' | 'medium' | 'large';
  elevation?: 1 | 2 | 3 | 4 | 5;
  titleIconColor?: string;
  dataCy?: string;
};

function testString(flag = false, returnString = '') {
  return (flag && returnString) || '';
}

function getElevationClass(elevation = 0): string {
  if (!elevation) return '';

  return `elevation-box elevation-box-${elevation}`;
}

function getAdditionalClasses({
  inlineBlock = false,
  dark = false,
  titleType = 'base-title',
  transparent = false,
}) {
  const isInlineBlock = testString(inlineBlock, 'inline-block');
  const isDark = testString(dark, 'dark');
  const isTitleType = testString(!!titleType, `title-${titleType}`);
  const isTransparent = testString(transparent, 'transparent');

  return `${isInlineBlock} ${isDark} ${isTitleType} ${isTransparent}`;
}

function getTitle(
  title = '' as string,
  titleAlign = 'left' as string,
  titleType = 'base-title' as string,
  titleSize = 'medium' as 'small' | 'medium' | 'large',
  titleIcon = '' as string,
  titleIconColor = 'black',
) {
  if (title) {
    const baseTitle = <BaseTitle title={title} size={titleSize} />;

    let renderIcon = null;

    if (titleIcon) {
      renderIcon = (
        <FigmaIcon
          icon={titleIcon}
          type="solid"
          size="large"
          color={titleIconColor}
        />
      );
    }

    switch (titleType) {
      case 'base-title':
        return baseTitle;
      case 'simple':
        return (
          <div className={`base-card-title simple ${titleAlign}`}>
            {renderIcon}
            <span>{title}</span>
          </div>
        );
      case 'boxed':
        return (
          <div className={`base-card-title boxed ${titleAlign}`}>
            {renderIcon}
            <span>{title}</span>
          </div>
        );
      default:
        return baseTitle;
    }
  }

  return null;
}

const BaseCard: React.FC<BaseCardProps> = ({
  title = '',
  titleAlign = 'left',
  titleType = 'base-title',
  inlineBlock = false,
  dark = false,
  titleIcon = '',
  transparent = false,
  titleSize = 'medium',
  elevation = 0,
  /* @ts-ignore */
  children = [],
  titleIconColor = 'black',
  dataCy = 'BaseCard',
}) => {
  const renderTitle = getTitle(
    title,
    titleAlign,
    titleType,
    titleSize,
    titleIcon,
    titleIconColor,
  );
  const additionalClasses = getAdditionalClasses({
    inlineBlock,
    dark,
    titleType,
    transparent,
  });

  return (
    <div
      data-cy={dataCy}
      className={`BaseCard ${additionalClasses} ${getElevationClass(
        elevation,
      )}`}
    >
      <div className="base-card-title-wrap">
        {renderTitle}
        <div className="base-card-action-wrap" />
      </div>
      {/* @ts-ignore */}
      <div className="base-card-content-wrap">{children}</div>
    </div>
  );
};

export default BaseCard;
