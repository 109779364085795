import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import EmptyHeader from 'components/DataGrid/Destination/Headers/EmptyHeader';

export default function checkbox() {
  return {
    ...baseColDef,
    field: 'rowCheckbox',
    headerName: 'Row Checkbox',
    headerComponent: EmptyHeader,
    hideHeaderName: true,
    pinned: 'left',
    checkboxSelection: true,
    width: 40,
  };
}
