import twitchCommon from './helpers/twitchCommon';

const metric = 'clipsCreated';
export default function clipsCreated() {
  return {
    ...twitchCommon(metric),
    headerName: 'Clips Created',
    width: 205,
  };
}
