import { Analytics } from '@segment/analytics-next';
import { Campaign } from 'components/Campaign/types';
import { User } from 'components/User/types';
import { Deliverable } from 'components/Deliverable/types';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import { SegmentExternalEvents } from './segmentExternalEvents';

export enum BrandCreatorReviewActions {
  Approved = 'approved',
  Declined = 'declined',
  Undeclined = 'undeclined',
  Redirected = 'redirected',
  ChatMessage = 'chat-message',
  Viewed = 'viewed',
}

export enum RedirectSection {
  Title = 'Title',
  Icon = 'Icon',
}

function trackCreatorViewed({
  segment,
  campaign,
  user,
  deliverable,
}: {
  segment: Analytics;
  campaign: Campaign;
  user: User;
  deliverable: Deliverable;
}) {
  segment.track(
    SegmentExternalEvents.BrandCreatorReview,
    {
      deliverable_id: deliverable.id,
      creator_id: deliverable.property.id,
      creator_name: deliverable.property.name,
      action: BrandCreatorReviewActions.Viewed,
    },
    {
      context: { groupId: formatCampaignId(campaign.id) },
      userId: formatUserId(user.id),
    },
  );
}

function trackCreatorDeclined({
  segment,
  campaign,
  user,
  deliverable,
  message,
}: {
  segment: Analytics;
  campaign: Campaign;
  user: User;
  deliverable: Deliverable;
  message: string;
}) {
  segment.track(
    SegmentExternalEvents.BrandCreatorReview,
    {
      deliverable_id: deliverable.id,
      creator_id: deliverable.property.id,
      creator_name: deliverable.property.name,
      action: BrandCreatorReviewActions.Declined,
      message,
    },
    {
      context: { groupId: formatCampaignId(campaign.id) },
      userId: formatUserId(user.id),
    },
  );
}

function trackCreatorApproved({
  segment,
  campaign,
  user,
  deliverable,
}: {
  segment: Analytics;
  campaign: Campaign;
  user: User;
  deliverable: Deliverable;
}) {
  segment.track(
    SegmentExternalEvents.BrandCreatorReview,
    {
      deliverable_id: deliverable.id,
      creator_id: deliverable.property.id,
      creator_name: deliverable.property.name,
      action: BrandCreatorReviewActions.Approved,
    },
    {
      context: { groupId: formatCampaignId(campaign.id) },
      userId: formatUserId(user.id),
    },
  );
}

function trackCreatorRedirected({
  segment,
  campaign,
  user,
  deliverable,
  section,
}: {
  segment: Analytics;
  campaign: Campaign;
  user: User;
  deliverable: Deliverable;
  section: RedirectSection;
}) {
  segment.track(
    SegmentExternalEvents.BrandCreatorReview,
    {
      deliverable_id: deliverable.id,
      creator_id: deliverable.property.id,
      creator_name: deliverable.property.name,
      action: BrandCreatorReviewActions.Redirected,
      section,
    },
    {
      context: { groupId: formatCampaignId(campaign.id) },
      userId: formatUserId(user.id),
    },
  );
}

function trackCreatorChatClicked({
  segment,
  campaign,
  user,
  entityId,
  propertyId,
  propertyName,
  message,
}: {
  segment: Analytics;
  campaign: Campaign;
  user: User;
  entityId: number;
  propertyId: number;
  propertyName: string;
  message: string;
}) {
  segment.track(
    SegmentExternalEvents.BrandCreatorReview,
    {
      deliverable_id: entityId,
      creator_id: propertyId,
      creator_name: propertyName,
      action: BrandCreatorReviewActions.ChatMessage,
      message,
    },
    {
      context: { groupId: formatCampaignId(campaign.id) },
      userId: formatUserId(user.id),
    },
  );
}

export {
  trackCreatorDeclined,
  trackCreatorRedirected,
  trackCreatorApproved,
  trackCreatorViewed,
  trackCreatorChatClicked,
};
