import campaignStartIcon from 'components/assets/img/brand-app-campaign-start-icon.svg';

import './CampaignSummaryEmptyState.scss';

function CampaignSummaryEmptyState() {
  return (
    <div
      className="CampaignSummaryEmptyState"
      data-cy="CampaignSummaryEmptyState"
    >
      <div className="summary-empty-left-side">
        <img src={campaignStartIcon} alt="campaign-start-icon" />
      </div>
      <div className="summary-empty-right-side">
        <p>
          BEN is analyzing the server racks of data we have to identify the
          perfect creators for you.
        </p>
        <ul>
          <h4>
            <b>What's going to happen next?</b>
          </h4>
          <li>1. Potential creators will be identified for your campaign.</li>
          <li>2. Creators will be displayed here for your review.</li>
        </ul>
      </div>
    </div>
  );
}

export default CampaignSummaryEmptyState;
