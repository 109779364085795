/* eslint-disable max-lines-per-function */
import { Tooltip } from 'react-tippy';
import UINumber from 'components/UI/Number/Number';
import DetailsPanelMetric from '../Metric/Metric';
import PlatformTitle from '../PlatformTitle/PlatformTitle';

type PredictedValues = {
  format?: string;
  value?: number;
};

type DeliverableDetailsPanelYoutubeProps = {
  onRedirectClick: () => void;
  postFrequency?: string;
  predictedViews?: PredictedValues;
  predictedVPC?: PredictedValues;
  showReportingWindowMessage: boolean;
  subscribers?: number;
  title: string;
  url?: string;
  videoCount?: number;
};

function DeliverableDetailsPanelYoutube({
  onRedirectClick,
  postFrequency,
  predictedViews,
  predictedVPC,
  showReportingWindowMessage,
  subscribers,
  title,
  url,
  videoCount,
}: DeliverableDetailsPanelYoutubeProps) {
  return (
    <div className="external-content__detail-panel__channel">
      <PlatformTitle
        onClick={onRedirectClick}
        platform="youtube"
        title={title}
        url={url}
      />
      <div className="external-content__detail-panel__channel__info">
        {(subscribers ||
          videoCount ||
          postFrequency ||
          predictedVPC ||
          predictedViews) && (
          <div className="external-content__detail-panel__channel__data__wrapper">
            <div className="external-content__detail-panel__channel__data__content">
              {subscribers && (
                <DetailsPanelMetric label="Subscribers">
                  {/* @ts-ignore */}
                  <Tooltip
                    html={<UINumber>{subscribers}</UINumber>}
                    position="bottom"
                    theme="dark"
                    size="big"
                    arrow
                    animation="shift"
                  >
                    <UINumber format="0a">{subscribers}</UINumber>
                  </Tooltip>
                </DetailsPanelMetric>
              )}
              {videoCount && (
                <DetailsPanelMetric label="Videos">
                  {/* @ts-ignore */}
                  <Tooltip
                    html={<UINumber>{videoCount}</UINumber>}
                    position="bottom"
                    theme="dark"
                    size="big"
                    arrow
                    animation="shift"
                  >
                    <UINumber format="0a">{videoCount}</UINumber>
                  </Tooltip>
                </DetailsPanelMetric>
              )}
            </div>
          </div>
        )}
        {(predictedVPC || predictedViews) && (
          <div className="external-content__detail-panel__channel__data__wrapper">
            <div className="external-content__detail-panel__channel__data__ben-labs-metrics">
              <h5>BENlabs metrics</h5>
              <p>
                Metrics calculated based on the creator’s history in BEN
                campaigns.
              </p>
              <div className="external-content__detail-panel__channel__data__content">
                {predictedVPC && (
                  <DetailsPanelMetric
                    prediction
                    showLive30Message={showReportingWindowMessage}
                    label="Predicted VPC"
                  >
                    {/* @ts-ignore */}
                    <Tooltip
                      html={
                        <UINumber format={predictedVPC.format}>
                          {predictedVPC.value}
                        </UINumber>
                      }
                      position="bottom"
                      theme="dark"
                      size="big"
                      arrow
                      animation="shift"
                    >
                      <UINumber format={predictedVPC.format}>
                        {predictedVPC.value}
                      </UINumber>
                    </Tooltip>
                  </DetailsPanelMetric>
                )}

                {predictedViews && (
                  <DetailsPanelMetric
                    prediction
                    showLive30Message={showReportingWindowMessage}
                    label="Predicted Views"
                  >
                    {/* @ts-ignore */}
                    <Tooltip
                      html={
                        <UINumber format={predictedViews.format}>
                          {predictedViews.value}
                        </UINumber>
                      }
                      position="bottom"
                      theme="dark"
                      size="big"
                      arrow
                      animation="shift"
                    >
                      <UINumber format="0a">{predictedViews.value}</UINumber>
                    </Tooltip>
                  </DetailsPanelMetric>
                )}

                {postFrequency && (
                  <DetailsPanelMetric label="Post Frequency">
                    {postFrequency}
                  </DetailsPanelMetric>
                )}
              </div>
            </div>

            {showReportingWindowMessage && (
              <div className="external-content__detail-panel__channel__data__description">
                * Predicted 30-Day Performance
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DeliverableDetailsPanelYoutube;
