import getSrc from 'components/UI/Image/getSrc';

const getPropertyImage = ({ property } = {}, placeholder) => {
  const productionMedia = property?.productions?.find(
    (production) => production?.media?.[0]?.url,
  );

  if (productionMedia) {
    return getSrc({
      url: productionMedia.media[0].url,
      fallbackImage: 'placeholderImage',
      imagecachePreset: 'medium',
    });
  }

  return placeholder;
};

export default getPropertyImage;
