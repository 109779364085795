import { ValueSetterParams } from 'ag-grid-community';
import { Placement } from 'shared/types/Placement';
import { PostManualMetric } from 'shared/types/CreatorCampaigns';
import { ReportingAction } from 'shared/campaigns/creatorCampaigns/segmentEnums';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import { ManualMetricValue } from '../types';
import UpsertPostManualMetric from '../../graphql/UpsertPostManualMetric.graphql';

function buildData(
  data: Placement,
  newValue: ManualMetricValue,
  metricType: string,
  postCustomColumnsUuid?: string,
) {
  return {
    ...(data ?? {}),
    source: {
      ...(data?.source ?? {}),
      postMetrics: {
        ...(data?.source?.postMetrics ?? {}),
        postManualMetrics: [
          ...(data?.source?.postMetrics?.postManualMetrics ?? []).filter(
            (existing: PostManualMetric) => {
              if (existing?.metricType !== metricType) {
                return true;
              }

              if (
                postCustomColumnsUuid &&
                (existing?.postCustomColumnsUuid ?? null) !==
                  (postCustomColumnsUuid ?? null)
              ) {
                return true;
              }
              return existing?.date !== newValue?.reportingWindow?.date;
            },
          ),
          {
            sourceId: data?.source?.id ?? null,
            metricType,
            postCustomColumnsUuid: postCustomColumnsUuid ?? null,
            value: newValue?.value,
            date: newValue?.reportingWindow?.date,
          },
        ],
      },
    },
  };
}

function saveNewMetric(
  context: Context,
  newValue: ManualMetricValue,
  sourceId?: number,
  metric?: string,
  postCustomColumnsUuid?: string,
) {
  if (!context?.apolloClient || !newValue?.reportingWindow?.date || !metric) {
    return;
  }

  context.apolloClient.mutate({
    mutation: UpsertPostManualMetric,
    variables: {
      sourceId,
      payload: {
        metricType: metric,
        postCustomColumnsUuid,
        value: newValue?.value,
        date: newValue?.reportingWindow?.date,
      },
    },
  });
}

export default function manualMetricsValueSetter(
  params: ValueSetterParams<Placement>,
  metric: string,
  postCustomColumnsUuid?: string,
) {
  const { newValue, data, oldValue, node, context, api, column } = params;
  if (
    newValue?.reportingWindow?.key === context?.reportingWindow &&
    newValue?.value === oldValue
  ) {
    return false;
  }
  const newData = buildData(data, newValue, metric, postCustomColumnsUuid);
  if (node) {
    node.setData(newData);
    api.refreshCells({
      rowNodes: [node],
      columns: [column],
    });
  }

  saveNewMetric(
    context,
    newValue,
    data?.source?.id,
    metric,
    postCustomColumnsUuid,
  );

  if (context?.trackReportingSegmentEvent) {
    context.trackReportingSegmentEvent(ReportingAction.updatedManualMetric, {
      metric,
      oldValue,
      newValue: newValue?.value ?? null,
      reportingWindowSameAsCampaign:
        context?.reportingWindow === newValue?.reportingWindow?.key,
      metricReportingWindow: newValue?.reportingWindow?.key,
    });
  }

  return true;
}
