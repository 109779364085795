import { useContext, useMemo } from 'react';
import ApplicationContext from 'components/Application/Context';
import featureFlags from 'store/featureFlags';
import { FeatureFlags } from 'components/User/types';

function getEnabledFlags(flags: FeatureFlags) {
  return Object.entries(flags).reduce((acc, [flag, options]) => {
    if (options.status !== 'forceOn') {
      return acc;
    }

    return {
      ...acc,
      [flag]: true,
    };
  }, {});
}

// these are the hard flags, fixed by the json file
const enabledFlags = getEnabledFlags(featureFlags as FeatureFlags);

export default function useFeatureFlags(): FeatureFlags {
  const { user } = useContext(ApplicationContext);

  return useMemo(
    () => ({
      ...enabledFlags,
      ...Object.entries(user?.featureFlags ?? {}).reduce<{
        [x: string]: boolean;
      }>((acc, [flag, options]) => {
        if (!options.userSelection) {
          return acc;
        }
        return {
          ...acc,
          [flag]: true,
        };
      }, {}),
    }),
    [user],
  );
}
