import FormSection from 'components/Form/Section/Section';
import FormGroup from 'components/Form/Group/Group';
import FormRow from 'components/Form/Row/Row';
import FormLabel from 'components/UI/Form/Label/Label';

function SendBenExtension() {
  return (
    <FormSection title="BENLabs Extension" noTableOfContents>
      <FormRow>
        <FormGroup size="medium" grow={1}>
          <FormLabel>Chrome</FormLabel>
          <div>
            <ol>
              <li>
                Download extension{' '}
                <a
                  href="https://benlabs-whiteglove-influencer.s3.us-west-2.amazonaws.com/benlabs-extension-chrome/benlabs-whiteglove-influencer.zip"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>
              </li>
              <li>Unzip the downloaded file</li>
              <li>
                Go to{' '}
                <a href="chrome://extensions/" rel="noreferrer" target="_blank">
                  chrome://extensions/
                </a>
              </li>
              <li>
                Toggle &quot;Developer mode&quot; in the upper right corner
              </li>
              <li>Click the &quot;Load unpacked&quot; button</li>
              <li>Select the unzipped folder</li>
              <li>Restart Chrome</li>
              <li>Logout and Login to the BENlabs</li>
              <li>Open the browser extension inside the BENlabs</li>
              <li>Navigate to a different page inside the BEN app</li>
            </ol>
          </div>
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup size="medium" grow={1}>
          <FormLabel>Firefox</FormLabel>
          <div>
            <ol>
              <li>
                Download extension{' '}
                <a
                  href="https://benlabs-whiteglove-influencer.s3.us-west-2.amazonaws.com/benlabs-extension-firefox/benlabs-whiteglove-influencer.xpi"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>
              </li>
              <li>When prompted by the browser click Add </li>
              <li>Accept / refuse using the extension in private mode</li>
              <li>Logout and Login to the BENlabs</li>
              <li>Open the browser extension inside the BENlabs</li>
              <li>Navigate to a different page inside the BEN app</li>
              <li>
                The extension will auto-update when a new version is released.
              </li>
            </ol>
          </div>
        </FormGroup>
      </FormRow>
    </FormSection>
  );
}

export default SendBenExtension;
