import { InitialData } from 'components/Tiktok/types';
import { Placement } from 'shared/types/Placement';

export default function getDrawerData(
  placement: Placement,
): InitialData | null {
  if (!placement?.integration?.deliverable?.property) {
    return null;
  }

  const sourceUrl = placement?.source?.url;

  if (!sourceUrl) {
    return null;
  }

  let videoId = sourceUrl.split('/').pop();

  if (!videoId) {
    return null;
  }

  /**
   * Some urls have query parameters, so we have to remove that from the video id value.
   */
  try {
    const videoIdWithoutParams = (videoId ?? '').match(/(\d*)(\?.*)?/)?.[1];
    if (videoIdWithoutParams) {
      videoId = videoIdWithoutParams;
    }
  } catch (e) {
    return null;
  }

  return {
    videoId,
    property: placement?.integration?.deliverable?.property,
    deliverableId: placement?.integration?.deliverable?.id,
    sourceUrl,
  };
}
