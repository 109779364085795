import { Route, Routes, Router, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-common';
import { DndProvider } from 'react-dnd';
import * as Sentry from '@sentry/react';

import { HTML5Backend } from 'react-dnd-html5-backend';
import RouteResetScroll from 'components/Route/ResetScroll/ResetScroll';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import ApplicationProvider from 'components/Application/Provider';
import ApplicationContext from 'components/Application/Context';
import ExternalApplicationProvider from 'external/components/Application/Provider';

import RouteRedirect from 'external/components/Route/Redirect/Redirect';

// Overwritten screen
import User from 'external/screens/User/User';
import Campaigns from 'external/screens/Campaigns/Campaigns';
import NotFound404 from 'external/screens/NotFound404/NotFound404';

import history from 'store/history/history';

import BaseLayout from 'external/components/BaseLayout/BaseLayout';

import '../../scss/base/_baseBrandApp.scss';

import { Environment } from 'types';

function Root({ environment }: { environment: Environment }) {
  return (
    <DndProvider backend={HTML5Backend}>
      <ApplicationProvider history={history} environment={environment}>
        <ApplicationContext.Consumer>
          {({ apolloClient }) => (
            <ApolloProvider client={apolloClient}>
              <Router
                location={history.location}
                navigator={history}
                basename="/client"
              >
                <ExternalApplicationProvider environment={environment}>
                  <ErrorDialog />
                  <RouteResetScroll />
                  <BaseLayout>
                    <Routes>
                      <Route path="/user/*" element={<User />} />
                      <Route path="/campaigns/*" element={<Campaigns />} />
                      <Route
                        path="/"
                        element={
                          <RouteRedirect
                            replace
                            to={({
                              defaultLocation,
                            }: {
                              defaultLocation: string;
                            }) => defaultLocation}
                          />
                        }
                      />
                      <Route path="/404" element={<NotFound404 />} />

                      <Route element={<Navigate replace to="/404" />} />
                    </Routes>
                  </BaseLayout>
                </ExternalApplicationProvider>
              </Router>
            </ApolloProvider>
          )}
        </ApplicationContext.Consumer>
      </ApplicationProvider>
    </DndProvider>
  );
}

export default Sentry.withProfiler(Root);
