// @ts-nocheck
import React from 'react';
import { string, func } from 'prop-types';
import AcceptMessage from './AcceptMessage';

type FormFileUploaderDropzoneTextProps = {
  text: string;
  acceptMessage: string;
  className: string;
  getInputProps: () => object;
};

function FormFileUploaderDropzoneText({
  text,
  acceptMessage,
  className,
  getInputProps,
}: FormFileUploaderDropzoneTextProps) {
  return (
    <div className={className}>
      <input {...getInputProps()} />
      {text ? (
        <small>{text}</small>
      ) : (
        <>
          Drop files here! 
          {' '}
          <small>or click to select</small>
          {acceptMessage && ' '}
        </>
      )}
      <AcceptMessage>{acceptMessage}</AcceptMessage>
    </div>
  );
}

FormFileUploaderDropzoneText.propTypes = {
  acceptMessage: string,
  className: string,
  getInputProps: func,
  text: string,
};

FormFileUploaderDropzoneText.defaultProps = {
  acceptMessage: null,
  className: null,
  getInputProps: () => {},
  text: null,
};

export default FormFileUploaderDropzoneText;
