import { ReportingWindowOption } from 'components/Campaign/Influencer/View/ReportingWindows/types/ReportingWindow';
import { ManualMetricValue } from 'components/Campaign/Influencer/View/Stats/Performance/columns/types';
import React from 'react';
import { SingleValue } from 'react-select';
import {
  ReportingAction,
  ReportingSegmentEvent,
} from 'shared/campaigns/creatorCampaigns/segmentEnums';

export default function useManualMetricActions(
  metric: string,
  metricValue: ManualMetricValue | null,
  setReportingWindow: (arg0: ReportingWindowOption | null) => void,
  setMetricValue: (arg0: ManualMetricValue | null) => void,
  setDone: (arg0: boolean) => void,
  trackSegmentEvent: ReportingSegmentEvent,
) {
  const onChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (ev?.target?.value === '') {
        setMetricValue({ ...metricValue, value: null });
        return;
      }
      setMetricValue({ ...metricValue, value: Number(ev?.target?.value) });
    },
    [metricValue, setMetricValue],
  );

  const onCancel = React.useCallback(() => {
    setMetricValue(null);
    setDone(true);

    if (trackSegmentEvent) {
      trackSegmentEvent(ReportingAction.cancelManualMetricUpdate, { metric });
    }
  }, [metric, setDone, setMetricValue, trackSegmentEvent]);

  const onUpdate = React.useCallback(() => {
    setDone(true);
  }, [setDone]);

  const onReportingWindowChange = React.useCallback(
    (option: SingleValue<ReportingWindowOption>) => {
      setReportingWindow(option ?? null);
      const o = option as ReportingWindowOption;
      setMetricValue({ ...metricValue, reportingWindow: o?.value });
    },
    [metricValue, setMetricValue, setReportingWindow],
  );

  return {
    onChange,
    onCancel,
    onUpdate,
    onReportingWindowChange,
  };
}
