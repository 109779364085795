// @ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import classNames from 'classnames';
import UIIcon from 'components/UI/Icon/Icon';

import './Video.scss';

const getId = (url) => {
  const [, id] = url.split('v=');
  return id ? id.split('&')[0] : '';
};

const loadImageEvent = new Event('loadimage');
const errorImageEvent = new Event('errorimage');

const isYouTube = (url) => url.includes('youtube') || url.includes('youtu.be');

function canvasImage(canvas, url, onError) {
  if (!isYouTube(url)) {
    return;
  }

  const img = new Image();
  const id = getId(url);

  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  img.src = `https://img.youtube.com/vi/${id}/hqdefault.jpg`;

  img.onload = () => {
    // eslint-disable-next-line no-param-reassign
    canvas.height = img.height;
    // eslint-disable-next-line no-param-reassign
    canvas.width = img.width;

    try {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.dispatchEvent(loadImageEvent);
    } catch (e) {}
  };

  img.onerror = () => {
    onError(err);
    canvas.dispatchEvent(errorImageEvent);
  };
}

function canvasVideo(canvas, url, onError) {
  const video = document.createElement('video');

  video.onloadedmetadata = () => {
    video.currentTime = 1;
  };

  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  video.onseeked = () => {
    // eslint-disable-next-line no-param-reassign
    canvas.height = video.videoHeight;
    // eslint-disable-next-line no-param-reassign
    canvas.width = video.videoWidth;
    try {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.dispatchEvent(loadImageEvent);
    } catch (e) {}
  };

  video.onerror = (err) => {
    onError(err);
    canvas.dispatchEvent(errorImageEvent);
  };

  video.src = url;
}

function setPosterToCanvas(url, canvas, onError, ignoreThumbGen) {
  if (!url || !canvas) {
    return;
  }

  if (isYouTube(url)) {
    canvasImage(canvas, url, onError);
    return;
  }

  if (!ignoreThumbGen) {
    canvasVideo(canvas, url, onError);
  }
}

const cbn = 'ui-video';

type UIVideoProps = ReactPlayerProps & {
  autoPlay?: boolean;
  className?: string;
  nonPlayable?: boolean;
  ignoreThumbGen?: boolean;
  showPlayIcon?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onError?: () => void;
  preload?: boolean;
  thumbnail?: string | null;
  url: string;
};

function UIVideo({
  autoPlay,
  className,
  nonPlayable,
  showPlayIcon,
  onClick,
  onError,
  preload,
  thumbnail,
  url,
  ignoreThumbGen,
  ...restProps
}: UIVideoProps) {
  const [playing, setPlaying] = useState(false);
  const [mediaError, setMediaError] = useState(null);
  const imgRef = useRef(null);

  useEffect(() => {
    if (url) {
      setMediaError(null);
      setPosterToCanvas(
        url,
        imgRef.current,
        (err) => {
          setMediaError(
            'Unable to play video. This file is corrupted or the application does not support this file type.',
          );
          onError(err);
        },
        ignoreThumbGen,
      );
    }
    // eslint-disable-next-line
  }, [url]);

  if (nonPlayable) {
    return (
      <div
        className={classNames(cbn, className, `${cbn}--non-playable`)}
        onClick={onClick}
      >
        {thumbnail ? (
          <img src={thumbnail} alt="video-thumbnail" />
        ) : (
          <canvas ref={imgRef} />
        )}
        {showPlayIcon && (
          <UIIcon className={`${cbn}__play-icon`} type="play_circle_outline" />
        )}
        {mediaError && <span className={`${cbn}__error`}>{mediaError}</span>}
      </div>
    );
  }

  return (
    <>
      <ReactPlayer
        {...restProps}
        className={classNames(cbn, className)}
        url={url}
        playing={playing}
        onClick={() => setPlaying(true)}
        config={{
          file: {
            attributes: {
              preload,
              autoPlay,
              poster: thumbnail,
            },
          },
        }}
      />
      {mediaError && <span className={`${cbn}__error`}>{mediaError}</span>}
    </>
  );
}

UIVideo.defaultProps = {
  autoPlay: false,
  className: '',
  controls: true,
  height: '100%',
  preload: 'none',
  showPlayIcon: true,
  thumbnail: undefined,
  onError: () => {},
  width: '100%',
};

export default UIVideo;
