import manualMetricsValueGetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import { viewsAutomatedValueGetter } from 'components/Campaign/Influencer/View/Stats/Performance/columns/views';
import { Placement } from 'shared/types/Placement';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/main';

export default function ctrPercentage() {
  return {
    ...percentColDef,
    valueGetter: ({ data, context }: ValueGetterParams<Placement>) => {
      if (!data) {
        return null;
      }
      const views =
        viewsAutomatedValueGetter(data) ??
        manualMetricsValueGetter(data, context, 'views');
      /**
       * Once we have automated data from Tune add it here.
       */
      const clicks = manualMetricsValueGetter(data, context, 'clicks');
      if (
        !views ||
        !clicks ||
        !Number.isFinite(Number(views)) ||
        !Number.isFinite(Number(clicks))
      ) {
        return null;
      }

      return Number(clicks) / Number(views);
    },
    editable: false,
    headerName: 'CTR %',
    field: 'ctrPercentage',
  };
}
