import React from 'react';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import Drawer, { Size } from '@UIComponents/Drawer/Drawer';
import Creator from 'components/Creator/Creator';
import ApplicationContext from 'components/Application/Context';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import {
  SegmentEvents,
  SegmentActions,
} from 'components/Segment/segmentEvents';
import { Campaign } from 'components/Campaign/types';
import { Property } from 'shared/types/Property';
import { Context } from 'components/Campaign/Influencer/View/shared/types';

function CreatorToggle({
  campaign,
  userId,
  tab,
  valueFormatted,
  value,
  dataCy,
  property,
  executionStatusName,
  executionStatusPhase,
  placementStatus,
  context,
}: Readonly<{
  value?: Property | null;
  valueFormatted?: string | null;
  campaign?: Campaign;
  userId?: number;
  tab?: string;
  dataCy?: string;
  property?: Property | null;
  executionStatusName?: string;
  executionStatusPhase?: string;
  placementStatus?: string;
  context?: Context;
}>) {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const { segment } = React.useContext(ApplicationContext);
  const onOpenCreatorDrawer = React.useCallback(() => {
    if (!segment) {
      return;
    }

    segment.track(
      SegmentEvents.CreatorProfile,
      {
        action: SegmentActions.OpenDrawer,
        tab,
        deliverable_status_name: executionStatusName,
        deliverable_status_phase: executionStatusPhase,
        placement_status: placementStatus ?? undefined,
        property: {
          id: property?.id,
          name: property?.name,
        },
      },
      {
        context: {
          groupId: campaign?.id ? formatCampaignId(campaign?.id) : null,
        },
        userId: userId ? formatUserId(userId) : null,
      },
    );
    setShowDrawer(true);
  }, [
    tab,
    property,
    campaign,
    userId,
    segment,
    executionStatusName,
    executionStatusPhase,
    placementStatus,
  ]);

  if (context?.isExternal === true) {
    return <span>{valueFormatted ?? value?.name}</span>;
  }

  return (
    <div data-cy={dataCy}>
      <BaseButton
        onClick={onOpenCreatorDrawer}
        text={valueFormatted ?? value?.name ?? ''}
        color="purple"
        size="small"
        variant="text"
        rounded={false}
      />

      {showDrawer ? (
        <Drawer
          isOpen={showDrawer}
          size={Size.xlarge}
          onRequestClose={() => setShowDrawer(false)}
          fluid={false}
          overlay={false}
        >
          <Creator
            property={property}
            tab={tab}
            campaign={campaign}
            userId={userId}
          />
        </Drawer>
      ) : null}
    </div>
  );
}

export default CreatorToggle;
