export const channels = [
  'film',
  'tv',
  'digital',
  'influencer',
  'music',
] as const;

export const classes = [
  'direct to home',
  'film/streaming',
  'theatrical',
  'theatrical/streaming',
  'broadcast/basic cable',
  'premium cable',
  'streaming',
  'live stream',
  'series',
  'production linked',
  'music video',
  'event',
  'icons',
  'influencer',
] as const;

export type Classes = typeof classes[number];
export type Channel = typeof channels[number];

export const defaultChannelFacets = [
  { name: 'film', total: 0 },
  { name: 'tv', total: 0 },
  { name: 'digital', total: 0 },
  { name: 'influencer', total: 0 },
  { name: 'music', total: 0 },
] as const;

export const channelsForGenresProcessing = {
  film: {
    id: 1,
    name: 'Film',
    code: 'film',
  },
  tv: {
    id: 2,
    name: 'TV',
    code: 'tv',
  },
  digital: {
    id: 3,
    name: 'Digital',
    code: 'digital',
  },
  influencer: {
    id: 5,
    name: 'Influencer',
    code: 'influencer',
  },
  music: {
    id: 6,
    name: 'Music',
    code: 'music',
  },
} as const;

type ClassesForChannel = {
  id: number;
  code: Classes;
  channel: Channel;
  name: string;
  isAcceptingNewStudios: boolean;
};

export const classesForChannels: Record<Classes, ClassesForChannel> = {
  'direct to home': {
    id: 1,
    code: 'direct to home',
    channel: 'film',
    name: 'Direct to Home',
    isAcceptingNewStudios: false,
  },
  'film/streaming': {
    id: 11,
    code: 'film/streaming',
    channel: 'film',
    name: 'Film/Streaming',
    isAcceptingNewStudios: false,
  },
  theatrical: {
    id: 2,
    code: 'theatrical',
    channel: 'film',
    name: 'Theatrical',
    isAcceptingNewStudios: false,
  },
  'theatrical/streaming': {
    id: 12,
    code: 'theatrical/streaming',
    channel: 'film',
    name: 'Theatrical/Streaming',
    isAcceptingNewStudios: false,
  },
  'broadcast/basic cable': {
    id: 3,
    code: 'broadcast/basic cable',
    channel: 'tv',
    name: 'Broadcast/Basic Cable',
    isAcceptingNewStudios: false,
  },
  'premium cable': {
    id: 5,
    code: 'premium cable',
    channel: 'tv',
    name: 'Premium Cable',
    isAcceptingNewStudios: false,
  },
  streaming: {
    id: 4,
    code: 'streaming',
    channel: 'tv',
    name: 'Streaming',
    isAcceptingNewStudios: false,
  },
  'live stream': {
    id: 8,
    code: 'live stream',
    channel: 'digital',
    name: 'Live Stream',
    isAcceptingNewStudios: true,
  },
  series: {
    id: 6,
    code: 'series',
    channel: 'digital',
    name: 'Series',
    isAcceptingNewStudios: true,
  },
  'production linked': {
    id: 15,
    code: 'production linked',
    channel: 'digital',
    name: 'Production Linked',
    isAcceptingNewStudios: true,
  },
  'music video': {
    id: 9,
    code: 'music video',
    channel: 'music',
    name: 'Music Video',
    isAcceptingNewStudios: false,
  },
  event: {
    id: 13,
    code: 'event',
    channel: 'influencer',
    name: 'Event',
    isAcceptingNewStudios: true,
  },
  icons: {
    id: 14,
    code: 'icons',
    channel: 'influencer',
    name: 'Icons',
    isAcceptingNewStudios: true,
  },
  influencer: {
    id: 7,
    code: 'influencer',
    channel: 'influencer',
    name: 'Influencer',
    isAcceptingNewStudios: true,
  },
};

export const channelLabels = {
  film: 'Film',
  tv: 'TV',
  digital: 'Digital',
  influencer: 'Influencer',
  music: 'Music',
} as const;

export const CHANNELS_OPTIONS = channels.map((channel) => ({
  key: channel,
  label: channelLabels[channel],
}));

export const channelClasses = Object.values(classesForChannels);

export const classesByChannel = channelClasses.reduce((acc, klass) => {
  if (!acc[klass.channel]) {
    acc[klass.channel] = [];
  }

  acc[klass.channel].push({ ...klass });

  return acc;
}, {} as Record<Channel, typeof channelClasses>);

export const channelsWithProducerClasses = channels.map((channel) => ({
  ...channelsForGenresProcessing[channel],
  producerClasses: classesByChannel[channel],
}));

export const digitalChannels = classesByChannel.digital;
export const influencerChannels = classesByChannel.influencer;

export function getDefaultReportingWindow(productionChannel: Channel) {
  return productionChannel === 'tv' || productionChannel === 'film'
    ? 'past30'
    : 'live30';
}
