import qs from 'qs';

export const toParams = (search) => {
  if (!search) {
    return {};
  }
  return qs.parse(search.replace('?', ''));
};

export const toSearch = (params, stripNullAndUndefined) => {
  const sortedParams = {};
  Object.keys(params)
    .sort()
    .forEach((key) => {
      if (!stripNullAndUndefined || params[key] != null) {
        sortedParams[key] = params[key];
      }
    });

  const search = qs.stringify(sortedParams, { encode: true });
  return search ? `?${search}` : '';
};

export const excludeSkip = (search) => {
  const params = toParams(search);

  delete params.skip;
  delete params.exitUrl;

  return toSearch(params);
};

export const href = (to, params, stripNullAndUndefined = true) =>
  `${to}${toSearch(params, stripNullAndUndefined)}`;

export const exitSearchParams = (search) => excludeSkip(search);

export const join = (...args) => {
  let finalPath = '';
  args.forEach((path) => {
    if (finalPath.endsWith('/') && path.startsWith('/')) {
      finalPath += path.slice(1);
    } else if (finalPath && !finalPath.endsWith('/') && !path.startsWith('/')) {
      finalPath += `/${path}`;
    } else {
      finalPath += `${path}`;
    }
  });
  return finalPath;
};
