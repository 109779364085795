import React from 'react';
import { Property } from 'components/Property/types';
import { Campaign } from 'components/Campaign/types';
import { Production } from 'components/Production/types';
import SocialChannelsRows from 'components/Creator/Productions/SocialChannelsRows';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { orderProductionsByType } from '../shared/utils/orderProductionsByType';

export default function ProductionsSearch({
  property,
  productions,
  tab,
  campaign,
  userId,
  refetchProperty,
  canAddNewChannel = true,
  showRecentMedia = true,
  openProductionFn,
  apolloClient,
  initialOpenPlatforms = [],
}: {
  property?: Property;
  productions: Production[];
  tab?: string;
  campaign?: Campaign;
  userId?: number;
  refetchProperty: () => void;
  canAddNewChannel: boolean;
  showRecentMedia: boolean;
  openProductionFn?: (arg0: number | undefined) => void;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  initialOpenPlatforms?: string[];
}) {
  const orderedProductions = React.useMemo(
    () => orderProductionsByType(productions),
    [productions],
  );

  return orderedProductions?.length > 0 ? (
    <SocialChannelsRows
      productions={orderedProductions}
      tab={tab}
      campaign={campaign}
      userId={userId}
      property={property}
      refetchProperty={refetchProperty}
      canAddNewChannel={canAddNewChannel}
      showRecentMedia={showRecentMedia}
      openProductionFn={openProductionFn}
      apolloClient={apolloClient}
      initialOpenPlatforms={initialOpenPlatforms}
    />
  ) : null;
}
