import { Placement } from 'shared/types/Placement';
import benMetricstoCampaignServiceMetrics from 'shared/campaigns/creatorCampaigns/benMetricsToCampaignServiceMetrics';
import {
  PostAutomatedMetric,
  YouTubeAuthedPostAutomatedMetric,
} from 'shared/types/CreatorCampaigns';

export default function getYouTubeDataFromCampaignService(
  data: Placement,
  metric: string,
): string | number | null {
  const metricAsCampaignServiceMetric =
    benMetricstoCampaignServiceMetrics?.common?.[metric] ?? metric;

  if (!metricAsCampaignServiceMetric) {
    return null;
  }

  const commonBasePath = data?.source?.postMetrics?.postAutomatedMetrics?.[0];

  if (!commonBasePath) {
    return null;
  }

  const youTubeMetric = (commonBasePath?.authed_data?.[
    metricAsCampaignServiceMetric as keyof YouTubeAuthedPostAutomatedMetric
  ] ??
    commonBasePath?.[
      metricAsCampaignServiceMetric as keyof PostAutomatedMetric
    ]) as string | number | null;

  return youTubeMetric ?? null;
}
