import { validate as createValidator } from 'components/utils/form';
import { legacyPasswordSchema, currentPasswordSchema } from '../utils';

const password = legacyPasswordSchema.required();

const newPassword = currentPasswordSchema.required();

const schema = {
  oldPassword: password.label('Current Password'),
  newPassword: newPassword.label('New Password'),
  confirmPassword: newPassword.label('Confirm New Password'),
};

const validator = createValidator(schema);

const validate = (values) => {
  const errors = validator(values);

  if (
    !errors.confirmPassword &&
    values.newPassword !== values.confirmPassword
  ) {
    errors.confirmPassword = 'Passwords should be equal.';
  }
  return errors;
};

export default validate;
