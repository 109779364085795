import { useMemo } from 'react';
import { ExternalApproval } from 'constants/approvalStatuses';
import isSimilarityRecommendation from 'external/utils/isSimilarityRecommendation';
import { Deliverable } from 'components/Deliverable/types';

export default function useFacets(
  data: {
    createdBy: Deliverable['createdBy'];
    externalApproval: ExternalApproval | null;
  }[],
) {
  return useMemo(() => {
    const result = {
      forReview: 0,
      submitted: 0,
      declined: 0,
      recommendedByAI: 0,
    };
    if (data) {
      data.forEach((item) => {
        if (!item.externalApproval) {
          result.forReview += 1;
        }
        if (item.externalApproval === ExternalApproval.Approved) {
          result.submitted += 1;
        }

        if (item.externalApproval === ExternalApproval.Rejected) {
          result.declined += 1;
        }

        if (item.createdBy && isSimilarityRecommendation(item.createdBy)) {
          result.recommendedByAI += 1;
        }
      });
    }
    return result;
  }, [data]);
}
