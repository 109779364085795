import React from 'react';
import {
  TCMAuthorizedMediaInsights,
  TCMSparkAdStatusData,
} from 'shared/types/TikTokMarketPlace';
import formatDate from 'shared/dates/formatDate';
import { SegmentEvents } from '../types';

import LabelLink from '../../../../LabelLink/LabelLink';

import './ContentInfo.scss';
import TikTokManageSparkAds from '../../../../_assets/react_svg/platforms/TikTokManageSparkAds';

export default function ContentInfo({
  currentPost,
  sparkAdStatusData,
  onManageSparkAds,
  segmentEvents,
}: {
  currentPost: TCMAuthorizedMediaInsights;
  sparkAdStatusData?: TCMSparkAdStatusData;
  onManageSparkAds: () => void;
  segmentEvents?: SegmentEvents | null;
}) {
  const onLocalManageSparkAdsClick = React.useCallback(() => {
    onManageSparkAds();

    if (segmentEvents?.trackManageSparkAdsClick) {
      segmentEvents.trackManageSparkAdsClick();
    }
  }, [onManageSparkAds, segmentEvents]);

  return (
    <section className="tiktok-video-content__content-info">
      {currentPost?.thumbnail_url ? (
        <img
          className="tiktok-video-content__content-info__thumbnail"
          alt="thumbnail"
          src={currentPost?.thumbnail_url}
        />
      ) : null}

      <section
        className={`tiktok-video-content__content-info__data ${
          currentPost?.thumbnail_url ? '' : 'no-image'
        }`}
      >
        <div className="tiktok-video-content__content-info__data__status">
          <p
            className={`auth-status ${sparkAdStatusData?.auth_status?.toLowerCase()}`}
          >
            <strong>Status: </strong> {sparkAdStatusData?.auth_status}
          </p>

          <p className="last-updated">
            Last updated:{' '}
            {formatDate(sparkAdStatusData?.auth_status_updated_time ?? '')}
          </p>
        </div>

        <p className="content-link">
          <strong>Content Link: </strong>{' '}
          <LabelLink
            href={currentPost?.embed_url as string}
            label={currentPost?.embed_url as string}
            linkType="external"
            onClick={segmentEvents?.trackContentLinkClick}
          />
        </p>

        <p className="caption">
          <strong>Caption: </strong>
          <br />
          {currentPost?.caption}
        </p>

        <button
          className="manage-spark-ads"
          type="button"
          onClick={onLocalManageSparkAdsClick}
        >
          <TikTokManageSparkAds width="171" />
        </button>
      </section>
    </section>
  );
}
