import React from 'react';
import classnames from 'classnames';
import './Input.scss';
import useDebouncedInput from 'utils/useDebouncedInput';
import { InputProps } from 'react-select';

const cbn = 'form-selector-input';

type FormSelectorInputProps = InputProps & {
  rowId: number;
  selectProps: object;
  value: string;
  innerRef: React.Ref<HTMLInputElement>;
  delay?: number;
  debounce?: number;
};

/* eslint-disable-next-line */
function FormSelectorInput({
  isHidden,
  isDisabled,
  value = '',
  innerRef,
  delay,
  debounce,
  onChange = () => null,
  ...props
}: FormSelectorInputProps) {
  /**
   * We are passing invalid DOM elements in the spread props.
   * We should try to be explicit when we pass down props, not just randomly passing everything, as
   * that might cause unintended effects.
   */
  // @ts-ignore
  delete props.getStyles;
  // @ts-ignore
  delete props.isEditing;
  // @ts-ignore
  delete props.cx;
  // @ts-ignore
  delete props.selectProps;
  // @ts-ignore
  delete props.getClassNames;
  // @ts-ignore
  delete props.clearValue;
  // @ts-ignore
  delete props.getValue;
  // @ts-ignore
  delete props.hasValue;
  // @ts-ignore
  delete props.setValue;
  // @ts-ignore
  delete props.isMulti;
  // @ts-ignore
  delete props.isRtl;
  // @ts-ignore
  delete props.selectOption;
  const [localValue, onChangeHandler] = useDebouncedInput(
    value,
    onChange,
    delay || 300,
  );

  const v = debounce ? localValue : value;
  const handler = debounce ? onChangeHandler : onChange;

  if (isDisabled) {
    return null;
  }

  if (isHidden) {
    return (
      <input
        {...props}
        ref={innerRef}
        className="form-selector-input"
        value={v || ''}
        style={{ width: 0, padding: 0 }}
        onChange={handler}
      />
    );
  }

  const length = value ? value.length : 0;

  return (
    <input
      {...props}
      ref={innerRef}
      className={classnames(cbn, {
        [`${cbn}--has-value`]: length,
      })}
      value={value || ''}
      style={{
        width: length ? length * 9 + 10 : 1,
        padding: length ? '0 3px' : 0,
      }}
      onKeyDown={(e) => {
        // @ts-ignore
        if (e.keyCode === 8 && !length) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onChange={handler}
    />
  );
}

export default FormSelectorInput;
