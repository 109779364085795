import { useRef, useCallback } from 'react';

export default function useDebounce(fn, delay = 300, leading = false) {
  const timerRef = useRef(null);
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const memoizedHandler = useCallback(
    (...args) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      if (leading && !timerRef.current) {
        fnRef.current(...args);
      }

      timerRef.current = setTimeout(() => {
        if (!leading) {
          fnRef.current(...args);
        }
        timerRef.current = null;
      }, delay);
    },
    [delay, leading],
  );

  memoizedHandler.cancel = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = null;
  }, []);

  return memoizedHandler;
}
