export default function ToolIcon({ fill = 'none' }: { fill?: string }) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0003 13.9998L24.5003 24.4998M14.0003 13.9998H7.00033L4.66699 6.99984L7.00033 4.6665L14.0003 6.99984V13.9998ZM44.938 6.39553L38.8069 12.5266C37.8828 13.4507 37.4208 13.9127 37.2477 14.4455C37.0954 14.9141 37.0954 15.4189 37.2477 15.8875C37.4208 16.4203 37.8828 16.8823 38.8069 17.8064L39.3605 18.36C40.2845 19.284 40.7465 19.746 41.2793 19.9191C41.7479 20.0714 42.2527 20.0714 42.7214 19.9191C43.2541 19.746 43.7162 19.284 44.6402 18.36L50.3753 12.6248C50.993 14.1279 51.3337 15.7741 51.3337 17.4998C51.3337 24.5875 45.588 30.3332 38.5003 30.3332C37.6458 30.3332 36.8109 30.2497 36.0031 30.0904C34.8688 29.8666 34.3016 29.7548 33.9578 29.789C33.5922 29.8255 33.4121 29.8803 33.0882 30.0536C32.7835 30.2166 32.4779 30.5222 31.8667 31.1334L15.167 47.8331C13.234 49.7661 10.1 49.7661 8.167 47.8331C6.234 45.9001 6.234 42.7661 8.167 40.8331L24.8667 24.1334C25.4779 23.5222 25.7835 23.2166 25.9466 22.912C26.1199 22.5881 26.1747 22.4079 26.2111 22.0424C26.2454 21.6986 26.1335 21.1314 25.9098 19.9971C25.7505 19.1893 25.667 18.3543 25.667 17.4998C25.667 10.4122 31.4127 4.6665 38.5003 4.6665C40.8465 4.6665 43.0456 5.29609 44.938 6.39553ZM28.0004 34.9997L40.8337 47.8329C42.7666 49.7659 45.9006 49.7659 47.8336 47.8329C49.7666 45.8999 49.7666 42.7659 47.8336 40.8329L37.2761 30.2755C36.5287 30.2048 35.8 30.07 35.0955 29.8766C34.1877 29.6274 33.1919 29.8083 32.5262 30.474L28.0004 34.9997Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
