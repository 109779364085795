import React, { forwardRef } from 'react';
import { string } from 'prop-types';
import UIIcon from 'components/UI/Icon/Icon';
import './LabelWithIcon.scss';

const cbn = 'data-grid-cell-label-with-icon';

const DataGridCellsLabelWithIcon = forwardRef(
  ({ label, icon, tooltip, dataCy }, ref) => (
    <div className={cbn} ref={ref} data-cy={dataCy}>
      <UIIcon type={icon} size="small" tooltip={tooltip} />
      {label}
    </div>
  ),
);

DataGridCellsLabelWithIcon.propTypes = {
  icon: string.isRequired,
  label: string,
  tooltip: string,
  dataCy: string,
};

DataGridCellsLabelWithIcon.defaultProps = {
  label: null,
  tooltip: null,
  dataCy: undefined,
};

DataGridCellsLabelWithIcon.componentName = 'DataGridCellsLabelWithIcon';

export default DataGridCellsLabelWithIcon;
