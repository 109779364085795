import followUp from 'components/Campaign/Influencer/View/Stats/Performance/columns/followUp';
import verticals from 'components/Campaign/Influencer/View/Stats/Performance/columns/verticals';
import tikTokOrderReport from 'components/Campaign/Influencer/View/Stats/Performance/columns/tiktokOrderReport';
import deliverableExpRevenue from 'components/Campaign/Influencer/View/Stats/Performance/columns/deliverableExpRevenue';
import conversionCode from 'components/Campaign/Influencer/View/Stats/Performance/columns/conversionCode';
import screenshots from 'components/Campaign/Influencer/View/Stats/Performance/columns/screenshots';
import viewsByAiViews from 'components/Campaign/Influencer/View/Stats/Performance/columns/viewsByAiViews';
import checkbox from 'components/Campaign/Influencer/View/Stats/Performance/columns/checkbox';
import saves from 'components/Campaign/Influencer/View/Stats/Performance/columns/saves';
import impressions from 'components/Campaign/Influencer/View/Stats/Performance/columns/impressions';
import ctrPercentage from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/ctrPercentage';
import conversions from 'components/Campaign/Influencer/View/Stats/Performance/columns/conversions';
import vpc from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/vpc';
import crPercentage from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/crPercentage';
import totalFee from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/totalFee';
import postFee from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/postFee';
import cpConv from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/cpConv';
import cpv from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/cpv';
import shares from './shares';
import comments from './comments';
import creatorName from './creatorName';
import likes from './likes';
import contentLink from './contentLink';
import postedDate from './postedDate';
import postType from './postType';
import views from './views';
import engagementRate from './engagementRate';
import dislikes from './dislikes';
import followers from './followers';
import clicks from './clicks';
import id from './id';
import likesDislikesRatio from './youTube/video/likesDislikesRatio';
import totalMinutesWatched from './youTube/video/totalMinutesWatched';
import subscriberViews from './youTube/video/subscriberViews';
import uniqueViews from './youTube/video/uniqueViews';
import videoThumbnailImpressions from './youTube/video/videoThumbnailImpressions';
import averageViewDuration from './youTube/video/averageViewDuration';
import videoThumbnailClicks from './youTube/video/videoThumbnailClicks';
import videoThumbnailCTR from './youTube/video/videoThumbnailCTR';
import averageCCV from './youTube/stream/averageCCV';
import peakCCV from './youTube/stream/peakCCV';
import stickerTaps from './instagram/story/stickerTaps';
import linkClickSwipeUps from './instagram/story/linkClickSwipeUps';
import followerReach from './instagram/story/followerReach';
import completionRate from './instagram/story/completionRate';
import replies from './instagram/story/replies';
import tapsforward from './instagram/story/tapsforward';
import exits from './instagram/story/exits';
import tapsBack from './instagram/story/tapsBack';
import nextStory from './instagram/story/nextStory';
import follows from './instagram/story/follows';
import emailbuttonTaps from './instagram/story/emailbuttonTaps';
import externalLinkTaps from './instagram/story/externalLinkTaps';
import averageWatchTime from './instagram/reels/averageWatchTime';
import totalPlayTime from './tikTok/totalPlayTime';
import videocompletionrate from './tikTok/videocompletionrate';
import reach from './reach';
import forYouTraffic from './tikTok/forYouTraffic';
import hashtagTraffic from './tikTok/hashtagTraffic';
import soundTraffic from './tikTok/soundTraffic';
import personalProfileTraffic from './tikTok/personalProfileTraffic';
import searchTraffic from './tikTok/searchTraffic';
import followingTraffic from './tikTok/followingTraffic';
import videoBoostedDate from './tikTok/videoBoostedDate';
import hoursStreamed from './twitch/hoursStreamed';
import totalLiveViews from './twitch/totalLiveViews';
import totalVODViews from './twitch/totalVODViews';
import chatVolume from './twitch/chatVolume';
import chatters from './twitch/chatters';
import clipsCreated from './twitch/clipsCreated';
import clipViews from './twitch/clipViews';
import reactions from './twitch/reactions';
import gameRankBeforestream from './twitch/gameRankBeforestream';
import gameRankDuringstream from './twitch/gameRankDuringstream';
import gameRankAfterstream from './twitch/gameRankAfterstream';
import detailExpands from './twitter/detailExpands';
import linkClick from './twitter/linkClick';
import totalCCV from './twitch/totalCCV';
import age35 from './helpers/age35';
import age2534 from './helpers/age2534';
import age1824 from './helpers/age1824';
import sec6views from './tikTok/sec6Views';
import videoCompletionRate50 from './tikTok/videoCompletionRate50';
import engagementRate50 from './tikTok/engagementRate50';
import sec2Views from './tikTok/sec2Views';
import videoCompletionRate25 from './tikTok/videoCompletionRate25';
import engagementRate25 from './tikTok/engagementRate25';
import nonFollowerReach from './instagram/story/nonFollowerReach';
import averagePercentWatched from './youTube/video/averagePercentWatched';
import genderMale from './helpers/genderMale';
import genderFemale from './helpers/genderFemale';
import averageViewTime from './tikTok/averageViewTime';
import videoViewsOrganic from './tikTok/videoViewsOrganic';
import videoViewsPaid from './tikTok/videoViewsPaid';
import trackingURL from './trackingURL';
import age1317 from './helpers/age1317';
import age3544 from './helpers/age3544';
import age4554 from './helpers/age4554';
import age5564 from './helpers/age5464';
import age65 from './helpers/age65';
import genderUserSpecified from './helpers/genderUserSpecified';
import aiViews from './aiViews';
import aiClicks from './aiClicks';
import tuneClicks from './tuneClicks';
import tuneRevenue from './tuneRevenue';
import tuneConversions from './tuneConversions';
import tuneAffiliateSource from './tuneAffilliateSource';

export default function performanceReporting() {
  return [
    checkbox(),
    creatorName(),
    postType(),
    contentLink(),
    postedDate(),
    trackingURL(),
    views(),
    impressions(),
    aiViews(),
    aiClicks(),
    viewsByAiViews(),
    likes(),
    comments(),
    shares(),
    engagementRate(),
    followers(),
    saves(),
    followUp(),
    tikTokOrderReport(),
    verticals(),
    deliverableExpRevenue(),
    conversionCode(),
    screenshots(),

    // Calculations
    totalFee(),
    postFee(),
    cpv(),
    cpConv(),
    ctrPercentage(),
    vpc(),
    clicks(),
    conversions(),
    crPercentage(),

    // YouTube
    dislikes(),
    likesDislikesRatio(),
    totalMinutesWatched(),
    averagePercentWatched(),
    subscriberViews(),
    uniqueViews(),
    videoThumbnailImpressions(),
    averageViewDuration(),
    videoThumbnailClicks(),
    videoThumbnailCTR(),

    // // YouTube Stream
    averageCCV(),
    peakCCV(),

    // // Instagram Story
    stickerTaps(),
    linkClickSwipeUps(),
    followerReach(),
    nonFollowerReach(),
    completionRate(),
    replies(),
    tapsforward(),
    exits(),
    tapsBack(),
    nextStory(),
    follows(),
    emailbuttonTaps(),
    externalLinkTaps(),

    // Instagram Reels
    averageWatchTime(),
    reach(),

    // TikTok
    totalPlayTime(),
    videocompletionrate(),
    videoCompletionRate50(),
    videoCompletionRate25(),
    engagementRate25(),
    engagementRate50(),
    forYouTraffic(),
    hashtagTraffic(),
    soundTraffic(),
    personalProfileTraffic(),
    searchTraffic(),
    followingTraffic(),
    sec2Views(),
    sec6views(),
    videoBoostedDate(),
    averageViewTime(),
    videoViewsOrganic(),
    videoViewsPaid(),

    // Twitch
    hoursStreamed(),
    totalCCV(),
    totalLiveViews(),
    totalVODViews(),
    chatVolume(),
    chatters(),
    clipsCreated(),
    clipViews(),
    reactions(),
    gameRankBeforestream(),
    gameRankDuringstream(),
    gameRankAfterstream(),

    // Twiter
    detailExpands(),
    linkClick(),

    // TUNE Columns
    tuneClicks(),
    tuneRevenue(),
    tuneConversions(),
    tuneAffiliateSource(),

    age1317(),
    age1824(),
    age2534(),
    age35(),
    age3544(),
    age4554(),
    age5564(),
    age65(),
    genderMale(),
    genderFemale(),
    genderUserSpecified(),

    // Id
    id(),
  ];
}
