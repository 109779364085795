export const DEFAULT_EXPORT_OPTIONS = [
  {
    label: 'PDF',
    value: 'search-pdf',
  },
  {
    label: 'PPTX',
    value: 'search-pptx',
  },
  {
    label: 'CSV - Summary',
    value: 'search-csv-summary',
  },
  {
    label: 'CSV - Airings',
    value: 'search-csv-metrics',
  },
  {
    label: 'CSV - Geographics (YT)',
    value: 'search-csv-geographics',
  },
  {
    label: 'CSV - Demographics (YT)',
    value: 'search-csv-demographics',
  },
  {
    label: 'CSV - Traffic Sources (YT)',
    value: 'search-csv-traffic-sources',
  },
  {
    label: 'CSV - Opportunities',
    value: 'search-csv-list-content',
  },
  {
    label: 'Excel - List',
    value: 'search-xlsx-list',
  },
  {
    label: 'Excel - Monthly',
    value: 'search-xlsx-monthly',
  },
  {
    label: 'Excel - By Production',
    value: 'search-xlsx-by-production',
  },
  {
    label: 'Excel - By Channel',
    value: 'search-xlsx-by-channel',
  },
  {
    label: 'Excel - By Client/Brand',
    value: 'search-xlsx-by-client-by-brand',
  },
  {
    label: 'Excel - Opportunities',
    value: 'search-xlsx-list-content',
  },
];
