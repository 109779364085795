import formatDate from 'shared/dates/formatDate';
import DatePicker from 'components/DataGrid/Editors/DatePicker/DatePicker';
import baseColDef from './baseColDef';

const dateColDef = {
  ...baseColDef,
  headerClass: 'header-align-right',
  cellClass: 'cell-align-right',
  cellEditor: DatePicker,
  valueFormatter: ({ value }: { value: string | null }) =>
    formatDate(value) ?? '',
};

export default dateColDef;
