import { Placement } from 'shared/types/Placement';
import formatDate from 'shared/dates/formatDate';
import getTikTokDataFromInsights from './helpers/getTikTokDataFromInsight';

export default function videoBoostedDate() {
  return {
    field: 'videoBoostedDate',
    headerName: 'Video Boosted Date',
    width: 200,
    valueGetter({ data }: { data: Placement }) {
      const videoDate = getTikTokDataFromInsights(
        data,
        'dim_tiktok_video.video_boosted_date',
      );

      if (!videoDate) {
        return null;
      }
      const validBoostedDate = new Date(videoDate).getTime() > 0;
      if (validBoostedDate) {
        return videoDate;
      }

      return null;
    },
    valueFormatter: ({ value }: { value: string | null }) =>
      formatDate(value) ?? '',
    filter: 'agDateColumnFilter',
  };
}
