import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

interface ICampaignMetrics {
  brandAppCampaign: {
    id: number;
    contentMetrics: {
      byAi: { views: number; clicks: number };
      byAll: { views: number; clicks: number };
    };
    contentByStatus: {
      approved: { id: number }[];
      firstDraft: { id: number }[];
      finalDraft: { id: number }[];
      inRevision: { id: number }[];
      live: { id: number }[];
    };
    creatorsByStatus: {
      approvedForOutReach: { id: number }[];
      collaborating: { id: number }[];
      declined: { id: number }[];
      forReview: { id: number }[];
      inNegotiation: { id: number }[];
    };
    clickTracking: boolean;
    targetViews: number;
    conversionTracking: boolean;
    placementsPlatformMetrics: {
      views: number;
      clicks: number;
      conversions: number;
    };
  };
}

type CampaignMetricsProps = ICampaignMetrics['brandAppCampaign'];

const campaignMetricsGql = gql`
  query CampaignMetrics($id: Int!) {
    brandAppCampaign(id: $id) {
      id
      contentMetrics {
        byAll {
          views
          clicks
        }
        byAi {
          views
          clicks
        }
      }
      contentByStatus {
        approved {
          id
        }
        firstDraft {
          id
        }
        finalDraft {
          id
        }
        inRevision {
          id
        }
        live {
          id
        }
      }
      creatorsByStatus {
        approvedForOutReach {
          id
        }
        collaborating {
          id
        }
        declined {
          id
        }
        forReview {
          id
        }
        inNegotiation {
          id
        }
        total
        totalByAi
      }
      clickTracking
      targetViews
      conversionTracking
    }
  }
`;

function CampaignMetricsService(
  campaignId: number,
): CampaignMetricsProps | null {
  const { data } = useQuery<ICampaignMetrics>(campaignMetricsGql, {
    variables: {
      id: campaignId,
    },
  });

  if (!data) return null;

  return data.brandAppCampaign;
}

export default CampaignMetricsService;
