import twitterCommon from './helpers/twitterCommon';

const metric = 'linkClick';
export default function linkClick() {
  return {
    ...twitterCommon(metric),
    headerName: 'Link Click',
    width: 175,
  };
}
