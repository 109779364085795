export default function XClose({
  fill = 'none',
  stroke = '#8f8f8f',
}: {
  fill?: string;
  stroke?: string;
}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1663 5.83325L5.83301 14.1666M5.83301 5.83325L14.1663 14.1666"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
