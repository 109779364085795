import {
  TCMReportMetrics,
  TCMReportMetricsViewsSource,
} from 'shared/types/TikTokMarketPlace';
import formatDate from 'shared/dates/formatDate';

import { ValueType } from '../../../../../../utils/formatters';

export function getViewershipRows(metrics?: TCMReportMetrics) {
  const newBoostedDate = new Date(metrics?.video_boosted_date ?? '');
  const validBoostedDate = newBoostedDate.getTime() > 0;

  return [
    {
      label: 'Video views',
      value: metrics?.video_views,
      type: 'floatNumber' as ValueType,
    },
    {
      label: '2 sec views',
      value: metrics?.two_seconds_views,
      type: 'percentage' as ValueType,
    },
    {
      label: '6 sec views',
      value: metrics?.six_seconds_views,
      type: 'percentage' as ValueType,
    },
    {
      label: 'Reach',
      value: metrics?.reach,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Video Completion Rate',
      value: metrics?.video_completion_rate,
      type: 'percentage' as ValueType,
    },
    {
      label: '25th percentile completion',
      value: metrics?.video_complete_rate_benchmark_p25,
      type: 'percentage' as ValueType,
    },
    {
      label: '50th percentile completion',
      value: metrics?.video_complete_rate_benchmark_p50,
      type: 'percentage' as ValueType,
    },
    {
      label: 'Total play time',
      value: metrics?.total_play_time,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Average view time',
      value: metrics?.average_view_time,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Video views organic',
      value: metrics?.video_views_organic,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Video views paid',
      value: metrics?.video_views_paid,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Video boosted date',
      value: validBoostedDate ? formatDate(newBoostedDate.toString()) : null,
      type: 'string' as ValueType,
    },
  ];
}

export function getEngagementRows(metrics?: TCMReportMetrics) {
  return [
    {
      label: 'Likes',
      value: metrics?.likes,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Shares',
      value: metrics?.shares,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Comments',
      value: metrics?.comments,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Engagement Rate',
      value: metrics?.engagement_rate,
      type: 'percentage' as ValueType,
    },
  ];
}

export function getTrafficSourcesRows(metrics: TCMReportMetricsViewsSource) {
  const trafficSources = [
    {
      label: 'For you page',
      value: metrics?.for_you,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Hashtag',
      value: metrics?.hashtag,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Sound',
      value: metrics?.sound,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Personal profile',
      value: metrics?.personal_profile,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Search',
      value: metrics?.search,
      type: 'floatNumber' as ValueType,
    },
    {
      label: 'Following',
      value: metrics?.following,
      type: 'floatNumber' as ValueType,
    },
  ];

  return trafficSources.sort((a, b) => (b.value ?? 0) - (a.value ?? 0));
}
