import { useMutation } from '@apollo/react-hooks';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import FormRow from 'components/Form/Row/Row';
import FormGroup from 'components/Form/Group/Group';
import useUser from 'store/authentication/useUser';
import useFullFeatureFlags from 'store/authentication/useFullFeatureFlags';
import FormSection from 'components/Form/Section/Section';
import { User, FeatureFlags } from 'components/User/types';
import { UserFormUpdate } from 'components/User/Form/graphql';
import onChange from './utils/onChange';

import './Experiments.scss';

type ProfileExperimentsProps = {
  user: User;
};

function ProfileExperiments({ user }: ProfileExperimentsProps) {
  const {
    setUser,
  }: {
    setUser: (user: User) => void;
  } = useUser();

  const userFeatureFlags: FeatureFlags = useFullFeatureFlags() || {};
  const featureFlagsCount = Object.keys(userFeatureFlags || {}).length;
  const [updateProfile] = useMutation(UserFormUpdate);

  return (
    <FormSection
      title="Experiments"
      noTableOfContents
      className="profile-experiments"
    >
      {featureFlagsCount === 0
        ? 'No current experiments running.'
        : Object.entries(userFeatureFlags).map(([key, entry]) => (
          <FormRow key={key}>
            <FormGroup container>
              <Checkbox
                  value={Boolean(entry?.userSelection)}
                  onChange={(value) => {
                    onChange(
                      value,
                      updateProfile,
                      setUser,
                      userFeatureFlags ?? {},
                      key,
                      user,
                    );
                  }}
                >
                {entry?.title}
              </Checkbox>
              <p>{entry.description}</p>
            </FormGroup>
          </FormRow>
          ))}
    </FormSection>
  );
}

export default ProfileExperiments;
