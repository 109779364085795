// @ts-nocheck
import React from 'react';
import numeral from 'numeral';
import classnames from 'classnames';
import UIProgresLayer from 'components/UI/ProgressLayer/ProgressLayer';
import UIIconButton from 'components/UI/Icon/Button/Button';
import { truncMiddle } from 'utils/string';
import { urlIsBlob } from 'components/utils/file';
import DownloadButton from '../DownloadButton/DownloadButton';
import { MediaFileComponentProps } from '../types';

import './File.scss';

const cbn = 'form-file-uploader-file';

type FormFileUploaderFileProps = Pick<
  MediaFileComponentProps,
  | 'downloading'
  | 'error'
  | 'file'
  | 'onDownload'
  | 'onRemove'
  | 'progress'
  | 'showRemoveButton'
>;

function FormFileUploaderFile({
  downloading,
  error,
  file,
  onDownload,
  onRemove,
  progress,
  showRemoveButton,
}: FormFileUploaderFileProps) {
  const isBlobUrl = urlIsBlob(file);
  const showProgressLayer = isBlobUrl && !error;

  return (
    <div className={cbn}>
      {showProgressLayer && (
        <UIProgresLayer
          value={progress || 0}
          className={`${cbn}__progress-layer`}
        />
      )}
      {showRemoveButton && (
        <UIIconButton
          className={`${cbn}__remove`}
          onClick={onRemove}
          size="small"
          type="delete"
        />
      )}
      <div
        className={classnames(`${cbn}__info`, {
          'has-error': error,
          pending: isBlobUrl && !error,
        })}
      >
        <DownloadButton
          url={file.url}
          onClick={onDownload}
          downloading={downloading}
          allowDownload={!isBlobUrl}
        >
          <span
            className={classnames(`${cbn}__filename`, { 'has-error': error })}
          >
            {truncMiddle(file.filename, 50)}
            {' '}
            (
            {numeral(file.size).format('0b')}
            {' '}
            )
          </span>
        </DownloadButton>
        {error && <span className={`${cbn}__error`}>{error.toString()}</span>}
      </div>
    </div>
  );
}

FormFileUploaderFile.defaultProps = {
  downloading: false,
  error: null,
  onDownload: undefined,
  progress: 0,
};

export default FormFileUploaderFile;
