import React from 'react';
import EmptyState from 'external/components/UI/EmptyState/EmptyState';

import noDetailsEmptyState from 'components/assets/img/brand-app-no-details-empty-state.svg';

import './NoDetailsEmptyState.scss';

const cbn = 'external-shared-no-details-empty-state';

function NoDetailsEmptyState() {
  return (
    <div className={`${cbn}__wrapper`}>
      <EmptyState
        message="Select a tile to see more info"
        info="Select a creator or a post to view more  information about them here."
        image={noDetailsEmptyState}
        position="horizontal"
      />
    </div>
  );
}

export default NoDetailsEmptyState;
