import gql from 'graphql-tag';

const PlacementByCampaignSub = gql`
  subscription placementByCampaign($campaignId: Int!) {
    placementByCampaign(campaignId: $campaignId) {
      id
      action
      by
      requestTimestamp
    }
  }
`;

export default PlacementByCampaignSub;
