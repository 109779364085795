import { Placement } from 'shared/types/Placement';

import './PlacementDrawerIdToggle.scss';

export default function PlacementDrawerToggleIdToggle({
  placement,
  openPlacementDrawer,
}: {
  placement: Placement;
  openPlacementDrawer: () => void;
}) {
  if (!placement?.id) {
    return null;
  }

  return (
    <button
      data-cy="placement-drawer-button"
      onClick={() => openPlacementDrawer()}
      className="btn-placement-drawer"
      type="button"
    >
      {placement.id}
    </button>
  );
}
