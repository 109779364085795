import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';
import getInstagramDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/instagram/helpers/getInstagramDataFromCampaignService';
import { INSTAGRAM_PLATFORM, VIDEO_MEDIA_TYPE } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';

const metric = 'averageWatchTime';
export function averageWatchTimeAutomatedValueGetter(data: Placement) {
  if (data?.platform === INSTAGRAM_PLATFORM) {
    return getInstagramDataFromCampaignService(data, metric);
  }
  return null;
}
export default function averageWatchTime() {
  return {
    ...basePerformanceColDef(
      metric,
      averageWatchTimeAutomatedValueGetter,
      [INSTAGRAM_PLATFORM],
      [VIDEO_MEDIA_TYPE],
    ),
    headerName: 'Average Watch Time',
    width: 260,
  };
}
