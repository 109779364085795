import numeral from 'numeral';

const number = ({ value, format = '0,0' }) => {
  if (!value) {
    return null;
  }

  if (value === '--') {
    return value;
  }

  return numeral(value).format(format);
};

export default number;
