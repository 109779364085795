import React from 'react';
import Tippy from '@tippyjs/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import './ClipboardCopyLabel.scss';

export default function ClipboardCopyLabel({
  label,
  copyValue,
  onClick,
  onCopy,
}: {
  label: string | React.ReactNode;
  copyValue: string;
  onClick?: () => void;
  onCopy?: (text: string) => void;
}) {
  const [hiding, setHiding] = React.useState(false);

  const hideAfterDelay = React.useCallback(
    (instance: { hide: () => void }) => {
      if (hiding === false) {
        setHiding(true);
        setTimeout(() => {
          instance.hide();
        }, 2000);
      }
    },
    [hiding],
  );

  return (
    <CopyToClipboard text={copyValue} onCopy={onCopy}>
      <span className="copy-to-clipboard">
        <Tippy
          className="tippy-copy-to-clipboard"
          content="Copied to clipboard"
          arrow={false}
          trigger="click"
          hideOnClick={false}
          onShow={hideAfterDelay}
          onHide={() => setHiding(false)}
        >
          <span
            role="link"
            className="label"
            onClick={onClick}
            onKeyDown={onClick}
            tabIndex={0}
          >
            {label}
          </span>
        </Tippy>
      </span>
    </CopyToClipboard>
  );
}
