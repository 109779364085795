import React, { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import useLazyRest from 'store/api/useLazyRest';
import { useNavigate } from 'react-router-dom';
import Field from 'components/Form/Field/Field';
import ApplicationContext from 'components/Application/Context';
import FormCheckbox from 'components/Form/Checkbox/Checkbox';
import FormGroup from 'components/Form/Group/Group';
import FormHorizontal from 'components/Form/Horizontal/Horizontal';
import FormLabel from 'components/UI/Form/Label/Label';
import FormRow from 'components/Form/Row/Row';
import FormSection from 'components/Form/Section/Section';
import InputText from 'components/Form/Input/Text/Text';
import UIButton from 'components/UI/Button/Button';
import UIErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import UIGridCol from 'components/UI/Grid/Col/Col';
import UIGridRow from 'components/UI/Grid/Row/Row';
import UILabel from 'components/UI/Label/Label';
import validate from './validate';

import './ChangePassword.scss';

function ChangePassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { user, setUser } = useContext(ApplicationContext);
  const [changePassword, { error }] = useLazyRest({
    method: 'put',
    url: 'users/password/change',
  });

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  async function onSubmit(data) {
    try {
      const response = await changePassword({
        errorPolicy: 'ignore',
        data: {
          newPassword: data.newPassword,
          oldPassword: data.oldPassword,
        },
      });
      setUser({
        ...user,
        ...response,
      });
      navigate('/user/change-password/done');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
    >
      {() => (
        <UIGridRow>
          <UIGridCol md="8" lg="6" sm="9">
            <FormSection noTableOfContents>
              <div className="change-password__notice">
                <p>
                  Your password must be between 8 to 20 characters and include
                  both letters and numbers.
                </p>
                <p>Please note that your password is case sensitive.</p>

                {error && (
                  <UIErrorMessage highlight>
                    There is a problem. 
                    {' '}
                    {error.message}
                  </UIErrorMessage>
                )}
              </div>

              <Form>
                <FormRow>
                  <FormGroup>
                    <FormLabel>Email</FormLabel>
                    <div>{user.email}</div>
                  </FormGroup>

                  <FormHorizontal />

                  <FormGroup>
                    <FormLabel>Current password</FormLabel>
                    <Field
                      component={InputText}
                      type={showPassword ? 'text' : 'password'}
                      name="oldPassword"
                      id="oldPassword"
                      required
                    />
                  </FormGroup>

                  <FormHorizontal />

                  <FormGroup>
                    <FormLabel>New password</FormLabel>
                    <Field
                      component={InputText}
                      type={showPassword ? 'text' : 'password'}
                      name="newPassword"
                      id="newPassword"
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Confirm password</FormLabel>
                    <Field
                      component={InputText}
                      type={showPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      id="confirmPassword"
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormCheckbox
                      onChange={toggleShowPassword}
                      value={showPassword}
                    >
                      Show passwords
                    </FormCheckbox>
                  </FormGroup>

                  <div className="change-password__actions">
                    <span>
                      <UILabel>Important</UILabel>
                      This will change your password for the BEN site.
                    </span>
                    <UIButton type="submit" theme="contained-green">
                      Change password
                    </UIButton>
                  </div>
                </FormRow>
              </Form>
            </FormSection>
          </UIGridCol>
        </UIGridRow>
      )}
    </Formik>
  );
}

export default ChangePassword;
