import { Analytics } from '@segment/analytics-next';
import { Production } from 'components/Production/types';
import { Property } from 'components/Property/types';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';

export default function trackMissingDemographics(
  segment?: Analytics | null,
  property?: Property,
  production?: Production,
  campaignId?: number,
  userId?: number,
) {
  if (!segment || !property || !production || !campaignId || !userId) {
    return;
  }
  segment.track(
    SegmentEvents.CreatorProfile,
    {
      action: SegmentActions.MissingDemographics,
      property_id: property?.id,
      property_name: property?.name,
      production_id: production?.id,
      platform: production?.type,
    },
    {
      context: { groupId: formatCampaignId(campaignId) },
      userId: formatUserId(userId),
    },
  );
}
