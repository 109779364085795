import { Production } from 'components/Production/types';
import getBestMetricResult from 'external/utils/getBestMetricResult';

type sortProductionsByMediaTargetProps = {
  productions: Production[];
  mediaTargets: { platform?: string }[];
};

export default function sortProductionsByMediaTarget({
  productions,
  mediaTargets,
}: sortProductionsByMediaTargetProps) {
  const platforms = mediaTargets.reduce<string[]>((acc, mediaTarget) => {
    if (!mediaTarget?.platform) {
      return acc;
    }

    if (!acc.includes(mediaTarget?.platform)) {
      acc.push(mediaTarget?.platform);
    }

    return acc;
  }, []);

  const productionsGroups = productions.reduce<{
    [key: string]: Production[];
  }>((acc, production) => {
    if (!production || !production?.type) {
      return acc;
    }
    const { type } = production;
    if (!acc[type]) {
      acc[type] = [production];
      return acc;
    }

    acc[type].push(production);
    acc[type].sort(
      (prodA, prodB) =>
        (getBestMetricResult(prodB) || 0) - (getBestMetricResult(prodA) || 0),
    );

    return acc;
  }, {});

  return Object.values(productionsGroups).sort((prodA, prodB) => {
    if (
      platforms.includes(prodA[0]?.type) &&
      !platforms.includes(prodB[0]?.type)
    ) {
      return -1;
    }
    if (
      !platforms.includes(prodA[0]?.type) &&
      platforms.includes(prodB[0]?.type)
    ) {
      return 1;
    }

    return (
      (getBestMetricResult(prodB[0]) || 0) -
      (getBestMetricResult(prodA[0]) || 0)
    );
  });
}
