import React, { useEffect, useState, useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import useStorage from 'components/Application/useStorage';
import useCampaignId from 'external/utils/useCampaignId';
import useFeatureFlags from 'store/authentication/useFeatureFlags';
import getCampaignInitialTab from 'external/utils/getCampaignInitialTab';
import {
  loadSegment,
  segmentPageCall,
} from 'external/components/Segment/Segment';
import ApplicationContext from 'components/Application/Context';
import { useNavigate, useLocation } from 'react-router-dom';
import Context from './Context';

export const CampaignQuery = gql`
  query CampaignSelectorQuery($id: Int!) {
    campaign(id: $id) {
      id
      type
      subType
      clientAccessTabs
      clientAccess
      brandAppPermissions
      projectName
      reportingWindow
      brands {
        id
        name
      }
      status
    }
  }
`;

const MODE_SWITCHER_KEY = '$BEN$--mode';

function ExternalApplicationProvider({ environment, children }) {
  const { user } = useContext(ApplicationContext);
  const campaignId = useCampaignId();
  const featureFlags = useFeatureFlags();
  const [theme, setTheme] = useStorage(MODE_SWITCHER_KEY, 'light');
  const [segment, setSegment] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { data: campaignData } = useQuery(CampaignQuery, {
    skip: !campaignId,
    variables: {
      id: campaignId,
    },
  });

  const campaign = campaignData?.campaign;

  useEffect(() => {
    if (!segment) {
      loadSegment(environment, setSegment);
    }
  }, [environment, setSegment, segment]);

  useEffect(() => {
    if (!segment || !campaignId || !user) {
      return;
    }

    segmentPageCall(segment, { id: campaignId }, user);
  }, [segment, campaignId, user, location]);

  const initialTab = campaign
    ? getCampaignInitialTab(campaign, featureFlags)
    : null;

  function onChangeCampaign(campaign) {
    if (!campaign) {
      navigate('/');
    } else {
      const initialTab = getCampaignInitialTab(campaign, featureFlags);
      const url = initialTab
        ? `/campaigns/${campaign?.id}/${initialTab}`
        : `/campaigns/${campaign?.id}`;
      navigate(url);
    }
  }

  useEffect(() => {
    // APP-3747 force light mode unitl definitions for new brand app
    document.body.classList[theme === 'light' ? 'add' : 'remove']('light-mode');
    document.body.classList[theme === 'dark' ? 'add' : 'remove']('light-mode');
  }, [theme]);

  return (
    <Context.Provider
      value={{
        campaign: campaignData?.campaign,
        campaignId,
        initialTab,
        onChangeCampaign,
        setTheme,
        theme,
        setSegment,
        segment,
      }}
    >
      {children}
    </Context.Provider>
  );
}

ExternalApplicationProvider.defaultProps = {
  children: null,
};

export default ExternalApplicationProvider;
