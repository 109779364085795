import React from 'react';
import { Platforms } from 'shared/types/platforms.d';
import PlatformIcon from '../PlatformIcon/PlatformIcon';
import './DeliverableTypesList.scss';

type DeliverableTypesListProps = {
  platform: keyof typeof Platforms;
  types: string[];
};

const DeliverableTypesList: React.FC<DeliverableTypesListProps> = ({
  platform,
  types = [],
}) => (
  <div data-cy={`deliverables-${platform}`} className="DeliverableTypesList">
    <PlatformIcon platform={platform} size="x-large" />
    <div className="list-wrapper">
      {types.map((type) => (
        <span key={type} className="list-item">
          {type}
        </span>
      ))}
    </div>
  </div>
);

export default DeliverableTypesList;
