import { Placement } from 'shared/types/Placement';
import getPlatformMetric from 'components/Campaign/Influencer/View/Stats/columns/utils/getPlatformMetric';
import MetricType from 'shared/types/MetricType';
import basePerformanceColDef from './helpers/baseColDef';

const metric = 'followers';
export default function followers() {
  return {
    ...basePerformanceColDef(
      metric,
      (data: Placement) =>
        getPlatformMetric(data, data?.mediaType, 'followers' as MetricType)
          ?.value ?? null,
    ),
    headerName: 'Followers',
    width: 180,
  };
}
