import svgSizeMapping, { SvgSize } from '../utils/svgSizeMapping';

export default function OtherLogo({
  fill = 'none',
  size = 'medium',
  stroke = 'black',
}: {
  fill?: string;
  size?: SvgSize;
  stroke?: string;
}) {
  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 27 26"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.625 7.58333L13.37 5.16633C13.0088 4.4707 12.8282 4.12287 12.5588 3.86876C12.3205 3.64404 12.0334 3.47314 11.7181 3.36842C11.3616 3.25 10.9577 3.25 10.1501 3.25H5.85C4.58988 3.25 3.95982 3.25 3.47852 3.48615C3.05516 3.69388 2.71095 4.02534 2.49524 4.43302C2.25 4.8965 2.25 5.50322 2.25 6.71667V7.58333M2.25 7.58333H19.35C21.2402 7.58333 22.1853 7.58333 22.9072 7.93756C23.5423 8.24915 24.0586 8.74634 24.3821 9.35786C24.75 10.0531 24.75 10.9632 24.75 12.7833V17.55C24.75 19.3702 24.75 20.2803 24.3821 20.9755C24.0586 21.587 23.5423 22.0842 22.9072 22.3958C22.1853 22.75 21.2402 22.75 19.35 22.75H7.65C5.75982 22.75 4.81473 22.75 4.09278 22.3958C3.45774 22.0842 2.94143 21.587 2.61785 20.9755C2.25 20.2803 2.25 19.3702 2.25 17.55V7.58333Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
