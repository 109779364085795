// @ts-nocheck
import React, { InputHTMLAttributes } from 'react';
import classnames from 'classnames';
import useDebouncedInput from 'utils/useDebouncedInput';
import { clearClassName } from 'utils/string';
import './Text.scss';

const cbn = 'form-input-text';

type FormInputTextProps = {
  className?: string;
  delay?: number;
  error?: string | unknown;
  inputRef?: React.Ref<HTMLInputElement>;
  name: string;
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
  type?: 'text' | 'password' | 'email';
  debounce?: boolean;
  isEditing?: boolean;
};

function FormInputText({
  className,
  debounce,
  delay,
  error,
  inputRef,
  name,
  onChange,
  placeholder,
  readOnly,
  type,
  value,
  ...restProps
}: FormInputTextProps & InputHTMLAttributes<HTMLInputElement>) {
  const [localValue, onChangeHandler] = useDebouncedInput(
    value,
    onChange,
    delay,
  );

  const v = debounce ? localValue : value;
  const handler = debounce ? onChangeHandler : onChange;

  const inputProps = {
    ...(restProps ?? {}),
  };
  delete inputProps.isediting;
  delete inputProps.isEditing;
  return (
    <input
      {...inputProps}
      placeholder={placeholder}
      type={type}
      ref={inputRef}
      name={name}
      value={v || ''}
      onChange={handler}
      readOnly={readOnly}
      className={classnames(clearClassName(`${cbn}--${name}`), cbn, className, {
        'has-error': error,
      })}
    />
  );
}

FormInputText.defaultProps = {
  className: '',
  debounce: false,
  delay: 300,
  error: '',
  inputRef: undefined,
  isEditing: undefined,
  placeholder: undefined,
  readOnly: false,
  type: 'text',
  value: '',
};

export default FormInputText;
