import { ProgressionRow } from '@UIComponents/ProgressionTable/ProgressionTable';

export function checkIfDemographicsHaveData(
  ageDemographics: ProgressionRow[],
  genderDemographics: ProgressionRow[],
) {
  const validAge = ageDemographics.find((demo) => Number(demo?.value) > 0);
  const validGender = genderDemographics.find(
    (demo) => Number(demo?.value) > 0,
  );

  return Boolean(validAge || validGender);
}
