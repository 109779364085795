import { useContext } from 'react';

import ApplicationContext from 'components/Application/Context';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import { trackOnFilterChanged } from 'external/components/Segment/Segment';

import './StatusFilter.scss';
import { Analytics } from '@segment/analytics-next';
import { User } from 'components/User/types';
import { Campaign } from 'components/Campaign/types';

import Select from 'react-select';

const cbn = 'external-ui-status-filter';

enum ApprovalStatus {
  forReview = 'forReview',
  submitted = 'submitted',
  declined = 'declined',
  recByAI = 'recommendedByAI',
}

type ExternalUIStatusFilterProps = {
  onFilterChangeCallback: (filter: string) => void;
  facets: {
    [key in ApprovalStatus]: number;
  };
  recommendedByAI?: boolean;
  tab: string;
};

type OnFilterChangeProps = {
  segment: Analytics | null;
  user: User | null;
  campaign: Campaign | null | undefined;
  tab: string;
  filterName: string;
  quantity: number;
  total: number;
};

type SelectedOption = {
  label: string;
  value: string;
  name: string;
  quantity: number;
};

const onFilterChange = ({
  segment,
  user,
  campaign,
  tab,
  filterName,
  quantity,
  total,
}: OnFilterChangeProps) => {
  if (segment && user && campaign) {
    trackOnFilterChanged({
      segment,
      tab,
      campaign,
      user,
      filterName,
      filteredQuantity: quantity,
      totalCount: total,
    });
  }
};

function ExternalUIStatusFilter({
  facets,
  onFilterChangeCallback,
  recommendedByAI = false,
  tab,
}: ExternalUIStatusFilterProps) {
  const { user } = useContext(ApplicationContext);
  const { campaign, segment } = useContext(ExternalApplicationContext);
  const total = facets.declined + facets.forReview + facets.submitted;

  function handleSelect(selectedOption: SelectedOption) {
    const filterValue = selectedOption?.value;
    const filterName = selectedOption?.name;
    const quantity = Number(selectedOption?.quantity);

    onFilterChange({
      segment,
      user,
      campaign,
      tab,
      filterName,
      quantity,
      total,
    });

    onFilterChangeCallback(filterValue);
  }

  const menuPortalStyle = (base: any) => ({ ...base, zIndex: 800 });

  const selectOptions = [
    {
      value: 'forReview',
      label: `For Review (${facets[ApprovalStatus.forReview]})`,
      name: 'For Review',
      quantity: facets[ApprovalStatus.forReview],
    },
    {
      value: 'Approved',
      label: `Submitted (${facets[ApprovalStatus.submitted]})`,
      name: 'Submitted',
      quantity: facets[ApprovalStatus.submitted],
    },
    {
      value: 'Rejected',
      label: `Declined (${facets[ApprovalStatus.declined]})`,
      name: 'Declined',
      quantity: facets[ApprovalStatus.declined],
    },
  ];

  const recommendedByAIOption = [
    {
      value: 'recommendedByAI',
      label: `Recommended by AI (${facets[ApprovalStatus.recByAI]})`,
      name: 'Recommended by AI',
      quantity: facets[ApprovalStatus.recByAI],
    },
  ];

  const recommendedByAISelectOptions = selectOptions.concat(
    recommendedByAIOption,
  );

  return (
    <div className={`StatusFilter ${cbn}`}>
      <span className="title-span">View:</span>
      <Select
        options={recommendedByAI ? recommendedByAISelectOptions : selectOptions}
        className="external-content__detail-panel__status-selector"
        classNamePrefix="external-content__detail-panel__status-selector"
        placeholder="All"
        isClearable
        isDisabled={false}
        menuPortalTarget={document.body}
        menuShouldBlockScroll
        styles={{ menuPortal: menuPortalStyle }}
        onChange={handleSelect}
      />
    </div>
  );
}

export default ExternalUIStatusFilter;
