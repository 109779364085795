import { useEffect, useState } from 'react';
import { urlIsBlob } from 'components/utils/file';

export default function useImagePreLoader(url) {
  const [imgUrl, setImgUrl] = useState(url);

  useEffect(() => {
    if (urlIsBlob(url)) {
      setImgUrl(url);
    } else {
      const img = new Image();
      img.onload = () => {
        setImgUrl(url);
      };
      img.src = url;
    }
  }, [url]);

  return imgUrl;
}
