import './UrlIcon.scss';

export default function UrlIcon({
  url,
  alt,
  size = 'medium',
  className = null,
  style,
  onError,
}: {
  url: string;
  alt: string;
  size?: 'small' | 'medium' | 'large' | 'x-large';
  className?: string | null;
  style?: { [index: string]: unknown };
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}) {
  return (
    <img
      style={style ?? {}}
      className={`url-icon size-${size} ${className ?? ''}`}
      alt={alt}
      src={url}
      onError={onError}
    />
  );
}
