import svgSizeMapping, { SvgSize } from '../utils/svgSizeMapping';

export default function TwitchLogo({
  fill = 'none',
  size,
}: {
  fill?: string;
  size: SvgSize;
}) {
  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_932_9553)">
        <path
          d="M17.5051 20.9773H12.5C12.2978 20.9773 12.1024 21.0501 11.9494 21.1824L7.00511 25V20.9773H4.86364C4.63459 20.9773 4.41492 20.8863 4.25295 20.7243C4.09099 20.5623 4 20.3427 4 20.1136V4.86364C4 4.63459 4.09099 4.41492 4.25295 4.25296C4.41492 4.091 4.63459 4.00001 4.86364 4.00001H22.1364C22.3654 4.00001 22.5851 4.091 22.747 4.25296C22.909 4.41492 23 4.63459 23 4.86364V16.2597C22.9983 16.3855 22.9694 16.5094 22.9154 16.6231C22.8613 16.7367 22.7834 16.8374 22.6869 16.9182L18.0557 20.7722C17.9027 20.9044 17.7073 20.9772 17.5051 20.9773Z"
          stroke="black"
          strokeWidth="2.04714"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 9V14.0625"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 9V14.0625"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_932_9553">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
