import React from 'react';
import classnames from 'classnames';
import UITooltip from 'components/UI/Tooltip/Tooltip';

import './Label.scss';

function Label({
  required = false,
  className = null,
  children = null,
  size = '',
  tooltip = null,
}: {
  required?: boolean;
  className?: string | string[] | null;
  children?: React.ReactNode;
  size?: string;
  tooltip?:
    | {
        id?: number;
        entity: string;
        name?: string;
        keys?: unknown;
        fields?: unknown;
        className?: string;
        customComponent?: string;
        tooltips?: unknown;
        additionalVariables?: unknown;
      }
    | string
    | null;
}) {
  return (
    <span className="wrapper">
      <label
        className={classnames('ui-form-label', className, {
          'ui-form-label--required': required,
          [`ui-form-label--${size}`]: size,
        })}
      >
        {children}
      </label>
      {/* Ignoring until the component is migrated to Typescript */}
      {/* @ts-ignore */}
      {tooltip ? <UITooltip {...(tooltip ?? {})} /> : null}
    </span>
  );
}

export default Label;
