// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import UIIcon from 'components/UI/Icon/Icon';
import './Group.scss';

const cbn = 'form-input-group';

type FormInputGroupType = {
  addon: string;
  children: JSX.Element;
  icon: string;
  input?: React.FC;
};

function FormInputGroup({
  addon,
  children,
  icon,
  input: Input,
  ...props
}: FormInputGroupType) {
  return (
    <div
      className={classnames(cbn, {
        [`${cbn}--has-icon`]: icon,
        [`${cbn}--has-addon`]: addon,
      })}
    >
      {addon && <span className={`${cbn}__addon`}>{addon}</span>}
      {icon && <UIIcon type={icon} />}
      {Input && <Input {...props} />}
      {children}
    </div>
  );
}

FormInputGroup.defaultProps = {
  addon: null,
  children: null,
  input: null,
  icon: null,
};

export default FormInputGroup;
