import React from 'react';
import { number } from 'prop-types';
import { useLocation } from 'react-router-dom';
import useUser from 'store/authentication/useUser';
import { useQuery } from '@apollo/react-hooks';
import UILoader from 'components/UI/Loader/Loader';
import UIFullContainer from 'components/UI/FullContainer/FullContainer';
import UIPageError from 'components/UI/PageError/PageError';
import { PLACEMENT_METRICS_INDEXES_BY_ID } from 'components/Metrics/constants/metricTypes';
import { PlacementQuery } from './graphql';
import View from './View';

export const PLACEMENT_METRICS_FOR_DISPLAY = [
  ...['impressions', 'averageImpressions', 'clicks', 'conversions'].map(
    (metricType) => ({
      metricType,
      platform: 'season',
      mediaType: 'episode',
    }),
  ),

  ...[
    'impressions',
    'averageImpressions',
    'clicks',
    'conversions',
  ].map((metricType) => ({ metricType, platform: 'film', mediaType: 'film' })),

  ...[
    'views',
    'viewDuration',
    'viewPercentage',
    'likes',
    'comments',
    'engagementRate',
  ].map((metricType) => ({
    metricType,
    platform: 'youtube',
    mediaType: 'video',
  })),

  ...['storyViews', 'feedViews'].map((metricType) => ({
    metricType,
    platform: 'instagram',
    mediaType: 'video',
  })),

  ...['impressions', 'likes'].map((metricType) => ({
    metricType,
    platform: 'facebook',
    mediaType: 'video',
  })),
];

function PlacementViewHidrator({ id }) {
  const { user } = useUser();
  const location = useLocation();
  const { data, loading, error } = useQuery(PlacementQuery, {
    variables: {
      id,
      columns: ['_all_'],
      countryBreakdown: true,
      summaryMetricConfig:
        user.role !== 'external'
          ? [{ allMetrics: true }]
          : PLACEMENT_METRICS_FOR_DISPLAY,
    },
  });

  const metricsDisplay = PLACEMENT_METRICS_INDEXES_BY_ID;

  const shouldRender = (section) => {
    if (section === 'performanceSection') {
      return false;
    }

    if (
      section === 'qualitySection.statsTable.type' ||
      section === 'qualitySection.statsTable.cpm'
    ) {
      return user.role !== 'external';
    }

    return true;
  };

  if (error) {
    return (
      <UIFullContainer>
        <UIPageError>Oops! An error occurred</UIPageError>
      </UIFullContainer>
    );
  }

  if (loading || !data) {
    return (
      <UIFullContainer>
        <UILoader />
      </UIFullContainer>
    );
  }

  return (
    <View
      placement={data.placement}
      showHeaderAndFooter
      metricsDisplay={metricsDisplay}
      shouldRender={shouldRender}
      search={location.search}
    />
  );
}

PlacementViewHidrator.propTypes = {
  id: number.isRequired,
};

export default PlacementViewHidrator;
