import React from 'react';
import { string } from 'prop-types';

import './Horizontal.scss';

function FormHorizontal({ title }) {
  return (
    <div className="form-horizontal">
      {title && <h4 className="form-horizontal__title">{title}</h4>}
      <hr className="form-horizontal__line" />
    </div>
  );
}

FormHorizontal.propTypes = {
  title: string,
};

FormHorizontal.defaultProps = {
  title: null,
};

export default FormHorizontal;
