import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import * as Sentry from '@sentry/react';
import Root from 'external/screens/Root';
import { BrowserTracing } from '@sentry/tracing';
import { LicenseManager } from 'ag-grid-enterprise';

import { benLabs } from '../package.json';
import getEnvironment from '../src/getEnvironment';
import '../src/scss-ben-theme/main.scss';
import '../src/scss/main.scss';

LicenseManager.setLicenseKey(benLabs?.agGridLicenseKey);

const { host } = window.location;
const environment = getEnvironment(host);

if (environment !== 'local') {
  Sentry.init({
    dsn: 'https://447425a685b44944889c1d9392fa04ae@sentry.io/5993695',
    release: process.env.REACT_APP_VERSION,
    environment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

ReactModal.setAppElement('#root');

const rootElement = document.getElementById('root');

ReactDOM.render(<Root environment={environment} />, rootElement);
