import numeral from 'numeral';

export function compose(...fns) {
  return fns.reduce(
    (prevFn, nextFn) => (...args) => nextFn(prevFn(...args)),
    (value) => value,
  );
}

export function capitalize(str) {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function hasProperty(obj, property) {
  return Object.prototype.hasOwnProperty.call(obj, property);
}

export const all = (assertions) => assertions.every((assertion) => assertion);
export const any = (assertions) => assertions.some((assertion) => assertion);
export const isNumber = (value) =>
  typeof value === 'number' && !Number.isNaN(value);

// TODO: Do we need this function?
export const ensureValueKey = (item) =>
  (item.id ? item : { ...item, id: item.id });
export const hasId = (item) => item?.id;

export const toArray = (value) => {
  if (!value) return [];

  return Array.isArray(value) ? value : [value];
};

export const toNumber = (value) => {
  if (!value || Number.isNaN(value)) {
    return 0;
  }

  if (typeof value === 'number') {
    return value;
  }

  const nValue = Number(value.replace(/[^\d.-]/g, ''));
  return !Number.isNaN(nValue) ? nValue : 0;
};

export const getValueFromPercentage = (percentage, total) => {
  if (!total || !percentage) {
    return 0;
  }
  const multiplier = percentage / 100;
  return Number(numeral(total * multiplier).format('0.00'));
};

const externalParams = [
  'includePermissions',
  'limit',
  'order',
  'skip',
  'groupBy',
];

export const unflattenFilters = (urlParams) => {
  if (urlParams.q) {
    return urlParams;
  }

  const { ...q } = urlParams;

  const result = { q };

  externalParams.forEach((param) => {
    if (hasProperty(urlParams, param)) {
      result[param] = urlParams[param];
    }
  });

  return result;
};

export const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (value && typeof value === 'object') {
    return !Object.keys(value);
  }

  return !value;
};

export const removePresetId = (filters) => {
  const { ...q } = filters.q;
  const newFilters = { ...filters, q };
  delete newFilters.presetId;
  return newFilters;
};
