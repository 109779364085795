import Alert from '@UIComponents/Alert/Alert';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import BaseModal from '@UIComponents/BaseModal/BaseModal';

type RejectionModalProps = {
  handleRejection: (arg1: string) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  rejected: boolean;
};

function RejectionModal({
  handleRejection,
  isOpen,
  onClose,
  rejected,
}: RejectionModalProps) {
  const onConfirmRejection = () => {
    const textarea = (document?.getElementById(
      'rejection-reason-textarea',
    ) as HTMLTextAreaElement)?.value;

    handleRejection(textarea);
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} title="Decline content">
      <div className="external-placement-detail-panel-modal">
        {rejected ? (
          <>
            <p className="bold">Content has been successfully declined</p>
            <p>
              We’re sorry that the content didn’t work for you. We’ll note your
              reason and feed it to our data models to make sure you won’t need
              to decline content in the future.
            </p>
            <div className="button-wrapper">
              <BaseButton text="Got it!" onClick={onClose} />
            </div>
          </>
        ) : (
          <>
            <Alert
              showIcon
              text="Declining content should be done as a very last resort. Have you considered leaving a note for our internal team? We’ll do our very best to resolve any issues you have."
              type="error"
            />
            <div className="rejection-reason-textarea">
              <p className="bold">
                Please share your reasons for declining this content
              </p>
              <textarea id="rejection-reason-textarea" />
            </div>
            <div className="button-wrapper">
              <BaseButton text="Decline content" onClick={onConfirmRejection} />
              <BaseButton text="Cancel" variant="text" onClick={onClose} />
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}

export default RejectionModal;
