import { Campaign } from 'components/Campaign/types';
import { Property } from 'components/Property/types';
import { Production } from 'components/Production/types';
import Card from 'components/Creator/Productions/Card/Card';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import AddSocialChannel from './AddSocialChannel/AddSocialChannel';
import './SocialChannelsRows.scss';

export default function SocialChannelsRows({
  productions,
  tab,
  campaign,
  userId,
  property,
  refetchProperty,
  canAddNewChannel = true,
  showRecentMedia = true,
  openProductionFn,
  apolloClient,
  initialOpenPlatforms,
}: {
  productions: Production[];
  tab?: string;
  campaign?: Campaign;
  userId?: number;
  property?: Property;
  refetchProperty: () => void;
  canAddNewChannel: boolean;
  showRecentMedia: boolean;
  openProductionFn?: (arg0: number | undefined) => void;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  initialOpenPlatforms: string[];
}) {
  return (
    <section className="social-channel-rows">
      {productions.map((production) => (
        <Card
          key={production?.id}
          production={production}
          tab={tab}
          userId={userId}
          campaign={campaign}
          property={property}
          showRecentMedia={showRecentMedia}
          openProductionFn={openProductionFn}
          apolloClient={apolloClient}
          initialOpenPlatforms={initialOpenPlatforms}
        />
      ))}

      {
        /**
         * When using this component in the browser extension we use a diffrent component to create
         * productions, not this one.
         */
        canAddNewChannel ? (
          <AddSocialChannel
            property={property}
            campaign={campaign}
            userId={userId}
            refetchProperty={refetchProperty}
          />
        ) : null
      }
    </section>
  );
}
