import { PostAutomatedMetric } from 'shared/types/CreatorCampaigns';
import { Placement } from 'shared/types/Placement';

const DATE_FIELD_COLD = 'dim_date.date_full';
const DATE_FIELD_HOT = 'fct_lifetime_video_performance_hot.collected_at';
const BOOSTED_DATE_COLD = 'dim_tiktok_video.video_boosted_date';
const BOOSTED_DATE_HOT =
  'fct_lifetime_video_performance_hot.video_boosted_date';
const LIFETIME_VIDEO_PERFORMANCE_COLD = 'fct_lifetime_video_performance';
const LIFETIME_VIDEO_PERFORMANCE_HOT = 'fct_lifetime_video_performance_hot';

function getFallbackMetric(currentMetric: string) {
  if (currentMetric === DATE_FIELD_COLD) {
    return DATE_FIELD_HOT;
  }

  if (currentMetric === BOOSTED_DATE_COLD) {
    return BOOSTED_DATE_HOT;
  }

  if (currentMetric.includes(LIFETIME_VIDEO_PERFORMANCE_COLD)) {
    return currentMetric.replace(
      LIFETIME_VIDEO_PERFORMANCE_COLD,
      LIFETIME_VIDEO_PERFORMANCE_HOT,
    );
  }

  return currentMetric;
}

function parseTikTokMetric(metric: string | number | null) {
  try {
    if (typeof metric === 'string') {
      const numberMetric = Number(metric);

      if (Number.isFinite(numberMetric)) {
        return numberMetric;
      }
    }
  } catch (error) {
    return metric;
  }

  return metric;
}

export default function getTikTokDataFromInsights(
  data: Placement,
  metric: string,
) {
  const tikTokAutomatedMetric = parseTikTokMetric(
    data?.source?.postMetrics?.postAutomatedMetrics?.[0]?.[
      metric as keyof PostAutomatedMetric
    ] as string,
  );

  if (!tikTokAutomatedMetric) {
    const fallbackMetric = getFallbackMetric(metric);

    const tikTokAutomatedMetricFallback = parseTikTokMetric(
      data?.source?.postMetrics?.postAutomatedMetrics?.[0]?.[
        fallbackMetric as keyof PostAutomatedMetric
      ] as string,
    );

    return tikTokAutomatedMetricFallback === 0
      ? null
      : tikTokAutomatedMetricFallback;
  }

  return tikTokAutomatedMetric === 0 ? null : tikTokAutomatedMetric;
}
