import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import RoutePrivate from 'external/components/Route/Private/Private';
import RoutePublic from 'external/components/Route/Public/Public';
import UserLogin from './Login/Login';
import UserProfile from './Profile/Profile';
import UserAccessAgreement from './AccessAgreement/AccessAgreement';
import UserChangePassword from './ChangePassword/ChangePassword';
import UserChangePasswordDone from './ChangePassword/Done/Done';
import UserResetPassword from './ResetPassword/ResetPassword';

function ScreensUser() {
  return (
    <Routes>
      <Route path="/login" element={<RoutePublic element={UserLogin} />} />
      <Route
        path="/reset-password"
        element={<RoutePublic element={UserResetPassword} />}
      />
      <Route path="/profile" element={<RoutePrivate element={UserProfile} />} />
      <Route
        path="/account-access-agreement"
        element={<RoutePrivate element={UserAccessAgreement} />}
      />
      <Route
        path="/change-password/done"
        element={<RoutePrivate element={UserChangePasswordDone} />}
      />
      <Route
        path="/change-password"
        element={<RoutePrivate element={UserChangePassword} />}
      />
      <Route path="/" element={<Navigate replace to="/404" />} />
    </Routes>
  );
}

export default ScreensUser;
