import numberFormatter from './number';

export default function wholeNumber({ value }) {
  if (value === '--') {
    return value;
  }
  if (!value || Number.isNaN(value)) {
    return null;
  }

  return numberFormatter({ value: Math.round(value) });
}
