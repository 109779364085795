import { getDataCy } from 'components/Campaign/utils';
import { Placement } from 'shared/types/Placement';
import linkColDef from 'components/DataGrid/Destination/colDef/linkColDef';

export default function trackingURL() {
  return {
    ...linkColDef,
    field: 'trackingURL',
    headerName: 'Tracking URL',
    valueGetter: ({ data }: { data: Placement }) => data?.trackerUrl,
    cellRendererParams: ({ data }: { data: Placement }) => ({
      dataCy: getDataCy(data, 'trackerUrl'),
    }),
    filter: 'agTextColumnFilter',
  };
}
