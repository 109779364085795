import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  bool,
  arrayOf,
  shape,
  oneOfType,
  number,
  node,
  string,
  func,
} from 'prop-types';
import get from 'lodash/get';
import classnames from 'classnames';
import useShortcuts from 'utils/shortcuts/useShortcuts';
import File from 'components/UI/File/File';
import UIMedia from 'components/UI/Media/Media';
import DownloadFile from 'components/UI/DownloadFile/DownloadFile';

import './Gallery.scss';

const GALLERY_THUMB_DEFAULT_HEIGHT = 85;
const GALLERY_MAIN_MEDIA_HEIGHT = 0.5625;

const checkIsVideo = (file) => Boolean(get(file, 'url', '').match(/\.mp4$/));
const checkIsPortrait = (width, height) => Boolean(height > width);
const checkIsYoutubeVideo = (file) =>
  Boolean(get(file, 'url', '').match(/youtube/));

const cbn = 'ui-gallery';

function UIGallery({
  className,
  download,
  fallbackImage,
  files,
  hasMask,
  imageComponent,
  selectedIndex: parentSelectedIndex,
  setSelectedIndex: parentSetSelectedIndex,
  showThumbs,
  videoComponent,
  nonPlayable,
  resourceId,
  resourceType,
  ...restProps
}) {
  const [selectedIndex, setSelectedIndex] = useState(parentSelectedIndex || 0);
  const [mainMediaWidth, setMainMediaWidth] = useState('');

  const selectNextIndex = () => {
    const i = selectedIndex + 1;
    const nextIndex = files[i] ? i : 0;

    setSelectedIndex(nextIndex);
  };

  const selectPreviousIndex = () => {
    const i = selectedIndex - 1;
    const prevIndex = files[i] ? i : files.length - 1;

    setSelectedIndex(prevIndex);
  };

  const getContainerWidth = () => {
    const mediaContainerElm = document.querySelector('.ui-gallery');

    return mediaContainerElm?.clientWidth;
  };

  const getMainMediaWidth = () => {
    const selectedFile = files[selectedIndex];
    const { width, height } = { ...selectedFile?.attributes };
    const portrait = checkIsPortrait(width, height);

    if (width && height && portrait) {
      const containerWidth = getContainerWidth();
      const ratio = (containerWidth * GALLERY_MAIN_MEDIA_HEIGHT) / height;
      const percentageWidth = (width * ratio * 100) / containerWidth;
      const roundedWidth = Math.round(percentageWidth * 1e2) / 1e2;

      return setMainMediaWidth(`${roundedWidth}%`);
    }
    return setMainMediaWidth('');
  };

  const getThumbnailWidth = (size) => {
    const { width, height } = { ...size };

    if (width && height) {
      const ratio = GALLERY_THUMB_DEFAULT_HEIGHT / height;
      const proportionalWidth = width * ratio;

      return Math.round(proportionalWidth * 1e2) / 1e2;
    }
  };

  useEffect(() => {
    if (parentSelectedIndex !== null) {
      if (parentSelectedIndex !== selectedIndex) {
        setSelectedIndex(parentSelectedIndex);
      }
    }
  }, [parentSelectedIndex, selectedIndex]);

  useEffect(() => {
    getMainMediaWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  useLayoutEffect(() => {
    getMainMediaWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useShortcuts([
    ['Right', () => selectNextIndex()],
    ['Left', () => selectPreviousIndex()],
  ]);

  if (!files || !files.length) {
    return null;
  }

  const selectedFile = files[selectedIndex];
  const isVideo = checkIsVideo(selectedFile);
  const fileIsYoutubevideo = checkIsYoutubeVideo(selectedFile);
  const Component = isVideo ? videoComponent : imageComponent;

  return (
    <div className={classnames(cbn, className)} {...restProps}>
      <div
        className={classnames(`${cbn}__main-wrapper`, {
          [`${cbn}__main-wrapper--masked`]: hasMask,
        })}
      >
        <div className={`${cbn}__main`} style={{ width: mainMediaWidth }}>
          <Component
            file={selectedFile}
            className={`${cbn}__media`}
            imagecachePreset="large"
            nonPlayable={nonPlayable}
          />
        </div>
      </div>

      {download && !fileIsYoutubevideo && selectedFile && (
        <DownloadFile
          cbn={cbn}
          url={selectedFile.url}
          fileName={selectedFile.filename}
          id={selectedFile.filename}
          resourceId={resourceId}
          resourceType={resourceType}
          fileId={selectedFile.id}
        />
      )}

      {download && fileIsYoutubevideo && (
        <a
          className={`${cbn}__download`}
          target="_blank"
          rel="noopener noreferrer"
          href={selectedFile.url}
        >
          View on Youtube
        </a>
      )}

      {showThumbs && (
        <div
          className={classnames(`${cbn}__thumbs`, {
            [`${cbn}__thumbs--masked`]: hasMask,
          })}
        >
          {files.map((file, index) => (
            <span
              key={file.id ?? index}
              className={classnames(`${cbn}__thumbs-item__wrapper`, {
                [`${cbn}__thumbs-item__wrapper--is-active`]:
                  selectedIndex === index,
              })}
              style={{ width: getThumbnailWidth(file?.attributes) }}
              onClick={() =>
                (parentSetSelectedIndex
                  ? parentSetSelectedIndex(index)
                  : setSelectedIndex(index))
              }
            >
              <UIMedia
                className={`${cbn}__thumbs-item`}
                fallbackImage={fallbackImage}
                url={file?.url}
                nonPlayable
                thumbnail={file?.attributes?.thumbnail}
              />
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

UIGallery.propTypes = {
  className: string,
  download: bool,
  fallbackImage: string,
  files: arrayOf(shape({})).isRequired,
  hasMask: bool,
  imageComponent: oneOfType([node, func]),
  nonPlayable: bool,
  selectedIndex: number,
  setSelectedIndex: func,
  showThumbs: bool,
  videoComponent: oneOfType([node, func]),
  resourceId: number,
  resourceType: string,
};

UIGallery.defaultProps = {
  className: null,
  download: false,
  fallbackImage: '',
  hasMask: false,
  imageComponent: File,
  nonPlayable: false,
  selectedIndex: null,
  setSelectedIndex: null,
  showThumbs: true,
  videoComponent: File,
  resourceId: null,
  resourceType: null,
};

export default UIGallery;
