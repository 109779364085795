export function calculateGenderDemographics(
  malePercent: number,
  femalePercent: number,
  nonBinaryPercent: number,
) {
  let tmpMalePercent = malePercent ?? 0;
  let tmpFemalePercent = femalePercent ?? 0;
  let tmpNonBinaryPercent = nonBinaryPercent ?? 0;

  if (malePercent > 0 && femalePercent > 0) {
    const calculatedNonBinary = 1 - malePercent - femalePercent;
    if (calculatedNonBinary !== 0 && !tmpNonBinaryPercent) {
      tmpNonBinaryPercent = 1 - malePercent - femalePercent;
    }
    return {
      malePercent,
      femalePercent,
      nonBinaryPercent: tmpNonBinaryPercent,
    };
  }

  if (
    femalePercent > 0 &&
    femalePercent < 1 &&
    (malePercent === 0 || !malePercent)
  ) {
    tmpMalePercent = 1 - femalePercent - nonBinaryPercent;
  }

  if (
    malePercent > 0 &&
    malePercent < 1 &&
    (femalePercent === 0 || !femalePercent)
  ) {
    tmpFemalePercent = 1 - malePercent - nonBinaryPercent;
  }

  return {
    malePercent: tmpMalePercent,
    femalePercent: tmpFemalePercent,
    nonBinaryPercent: tmpNonBinaryPercent,
  };
}
