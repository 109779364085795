import React from 'react';
import {
  bool,
  oneOfType,
  node,
  arrayOf,
  string,
  oneOf,
  shape,
} from 'prop-types';
import classnames from 'classnames';

import './Group.scss';

function UIGridGroup({
  align,
  children,
  className,
  column,
  container,
  noLabel,
  noMargin,
  noPadding,
  noPaddingTop,
  pullRight,
  size,
  style,
  dataCy,
}) {
  return (
    <div
      style={style}
      data-cy={dataCy}
      className={classnames('ui-grid-group', className, {
        [`ui-grid-group--${size}`]: size,
        'ui-grid-group--container': container,
        'ui-grid-group--pull-right': pullRight,
        'ui-grid-group--no-margin': noMargin,
        'ui-grid-group--no-padding': noPadding,
        'ui-grid-group--no-padding-top': noPaddingTop,
        'ui-grid-group--column': column,
        'ui-grid-group--no-label': noLabel,
        [`ui-grid-group--align-${align}`]: align,
      })}
    >
      {children}
    </div>
  );
}

UIGridGroup.propTypes = {
  align: oneOf(['top', 'center', 'bottom']),
  children: oneOfType([node, arrayOf(node)]),
  className: string,
  column: bool,
  container: bool,
  noLabel: bool,
  noMargin: bool,
  noPadding: bool,
  noPaddingTop: bool,
  pullRight: bool,
  size: oneOf([
    'grow',
    'fluid',
    'auto',
    'contained',
    'xx-small',
    'x-small',
    'small',
    'mid-small',
    'medium',
    'mid-large',
    'large',
    'x-large',
    'xx-large',
    'xxx-large',
  ]),
  style: shape({}),
};

UIGridGroup.defaultProps = {
  align: undefined,
  children: null,
  className: null,
  column: false,
  container: false,
  noLabel: false,
  noMargin: false,
  noPadding: false,
  noPaddingTop: false,
  pullRight: false,
  size: 'contained',
  style: {},
  dataCy: '',
};

export default UIGridGroup;
