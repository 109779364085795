import React from 'react';
import DotsHorizontal from '../../../_assets/react_svg/DotsHorizontal';

import './AdditionalOptionsDropdown.scss';

export default function AdditionalOptionsDropdown({
  options,
}: Readonly<{
  options: { label: string; onClick: () => void; disabled?: boolean }[];
}>) {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClickOutside(ev: Event) {
      if (!ref?.current) {
        return;
      }
      if (!ref.current.contains(ev.target as Node)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <section ref={ref} className="additional-options-dropdown">
      <button
        className="btn-options"
        type="button"
        onClick={() => {
          setIsDropdownOpen(true);
        }}
      >
        <DotsHorizontal />
      </button>

      {isDropdownOpen ? (
        <div className="additional-options-dropdown__options-container">
          {options.map((option) => (
            <button
              key={option?.label}
              disabled={option?.disabled}
              type="button"
              className="additional-options-dropdown__options-container__option"
              onClick={() => {
                option?.onClick();
                setIsDropdownOpen(false);
              }}
            >
              {option?.label}
            </button>
          ))}
        </div>
      ) : null}
    </section>
  );
}
