import svgSizeMapping, { SvgSize } from '../utils/svgSizeMapping';

export default function Edit03({
  size = 'small',
  stroke = '#868686',
}: Readonly<{
  size?: SvgSize;
  stroke?: string;
}>) {
  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.3333H14"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12.217C2 11.8909 2 11.7278 2.03684 11.5744C2.0695 11.4383 2.12338 11.3082 2.19648 11.1889C2.27894 11.0544 2.39424 10.9391 2.62484 10.7085L11 2.33333C11.5523 1.78104 12.4477 1.78104 13 2.33333C13.5523 2.88561 13.5523 3.78104 13 4.33333L4.62486 12.7085C4.39425 12.9391 4.27895 13.0544 4.1444 13.1368C4.0251 13.21 3.89504 13.2638 3.75899 13.2965C3.60554 13.3333 3.44248 13.3333 3.11636 13.3333H2V12.217Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
