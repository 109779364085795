import percent from 'components/DataGrid/formatters/percent';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { Placement } from 'shared/types/Placement';
import { YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import getYouTubeDistributionDataFromCampaignService from '../youTube/helpers/getYouTubeDistributionDataFromCampaignService';
import basePerformanceColDef from './baseColDef';

const youTubeMetric = 'viewers_by_gender';
const youTubeMetricKey = 'Genderuserspecified';
const metric = 'gender-user-specified';

export function genderUserSpecifiedAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDistributionDataFromCampaignService(data, [
      youTubeMetric,
      youTubeMetricKey,
    ]);
  }
  return null;
}

export default function genderUserSpecified() {
  return {
    ...percentColDef,
    ...basePerformanceColDef(metric, genderUserSpecifiedAutomatedValueGetter, [
      YOUTUBE_PLATFORM,
    ]),
    headerName: 'User Specified',
    width: 210,
    valueFormatter: ({ value }: { value?: number | null }) =>
      percent({ value }),
  };
}
