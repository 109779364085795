import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import tikTokCommon from './helpers/tikTokCommon';

export default function sec6views() {
  const tikTokMetric = 'fct_lifetime_video_performance.num_six_seconds_views' as keyof typeof TCMReportMetrics;
  const metric = 'six_seconds_views';
  return {
    ...percentColDef,
    ...tikTokCommon(tikTokMetric, metric),
    field: 'sec6Views',
    headerName: '6 Sec views',
    width: 195,
  };
}
