import React from 'react';
import './CheckboxEditor.scss';

const DestinationCheckboxEditor = React.forwardRef(
  (
    {
      dataCy,
      value,
      alignRight = false,
    }: {
      value?: string | number | null;
      dataCy?: string | null;
      alignRight?: boolean;
    },
    ref,
  ) => {
    const refInput = React.useRef(null);
    React.useImperativeHandle(ref, () => ({
      getValue: () => !value,
      isCancelBeforeStart() {
        return true;
      },
    }));
    return (
      <div
        className={`ag-cell-destination ag-cell-destination-checkbox-editor ${
          alignRight ? 'align-right' : ''
        }`}
      >
        <input
          ref={refInput}
          type="checkbox"
          checked={Boolean(value)}
          onChange={() => null}
          data-cy={dataCy}
        />
      </div>
    );
  },
);

DestinationCheckboxEditor.displayName = 'DestinationCheckboxEditor';

export default DestinationCheckboxEditor;
