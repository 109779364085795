export enum MetricType {
  actions = 'actions',
  averageEngagement = 'averageEngagement',
  averageEngagementRate = 'averageEngagementRate',
  averageImpressions = 'averageImpressions',
  averageViews = 'averageViews',
  avgConcurrents = 'avgConcurrents',
  botPercentage = 'botPercentage',
  ccv = 'ccv',
  clicks = 'clicks',
  comments = 'comments',
  contentPieces = 'contentPieces',
  conversions = 'conversions',
  ctr = 'ctr',
  dislikes = 'dislikes',
  durationInHours = 'durationInHours',
  engagementRate = 'engagementRate',
  estimatedMinutesWatched = 'estimatedMinutesWatched',
  followers = 'followers',
  impressions = 'impressions',
  likes = 'likes',
  maxConcurrents = 'maxConcurrents',
  shares = 'shares',
  storyViews = 'storyViews',
  subscribers = 'subscribers',
  views = 'views',
  vpc = 'vpc',
  liveViews = 'liveViews',
  uniqueVisitors = 'uniqueVisitors',
  minutesStreamed = 'minutesStreamed',
  chatVolume = 'chatVolume',
  saves = 'saves',
  stickerTaps = 'stickerTaps',
  totalVideos = 'totalVideos',
  viewDuration = 'viewDuration',
}

export default MetricType;
