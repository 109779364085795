import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useState } from 'react';
import { audienceFilters } from 'constants/audienceFilterOptions';
import { countriesToOptionsWithCountryNames } from 'components/utils/countriesToOptions';
import { ProductPlacementSummary } from './types';
import MetricSummaryWithBreakdown from './MetricSummaryWithBreakdown/MetricSummaryWithBreakdown';
import MetricFilter from './MetricFilter/MetricFilter';

import './Performance.scss';

type Props = Readonly<{
  initialReportingWindow: string;
  initialCountries: string[];
  placementId: number;
  countryOptions?: { label: string; value: string }[];
}>;

type PlacementQueryResponse = {
  placement: {
    id: number;
    productPlacementSummary: ProductPlacementSummary;
  };
};

const GET_PRODUCTPLACEMENT_SUMMARY = gql`
  query Placement($id: Int!, $reportingWindow: String!, $countries: [String]!) {
    placement(id: $id) {
      id
      productPlacementSummary(
        reportingWindow: $reportingWindow
        countries: $countries
      ) {
        value
        reportingWindow
        networkBreakdowns {
          value
          name
        }
        countryBreakdowns {
          value
          name
        }
        countriesAvailable
      }
    }
  }
`;

const cbn = 'placement-view';

function ProductPlacementPerformance({
  initialReportingWindow,
  initialCountries,
  placementId,
}: Props) {
  const [filters, setFilters] = useState({
    reportingWindow: initialReportingWindow,
    countries: initialCountries,
  });

  const { loading, data } = useQuery<PlacementQueryResponse>(
    GET_PRODUCTPLACEMENT_SUMMARY,
    {
      variables: {
        id: placementId,
        countries: filters.countries,
        reportingWindow: filters.reportingWindow,
      },
    },
  );

  const onSubmit = (values: {
    reportingWindow: string;
    countries: string[];
  }) => {
    setFilters({
      reportingWindow: values.reportingWindow ?? 'past30',
      countries: values.countries,
    });
  };

  const summary = data?.placement?.productPlacementSummary;
  const countryOptions =
    summary && summary.countriesAvailable?.length > 0
      ? countriesToOptionsWithCountryNames(summary.countriesAvailable)
      : audienceFilters.countries;

  return (
    <>
      <div className={`${cbn}__content__block__title`}>Performance</div>
      <MetricFilter
        filterValues={filters}
        onSubmit={onSubmit}
        disabled={false}
        formOptions={{
          countries: countryOptions,
          reportingWindow: audienceFilters.reportingWindow,
        }}
        placementId={placementId}
      />
      <MetricSummaryWithBreakdown
        cbn={cbn}
        isLoading={loading}
        summary={summary}
      />
    </>
  );
}

export default ProductPlacementPerformance;
