import { Platforms } from 'shared/types/platforms';
import { Production } from 'components/Production/types';
import getFollowers from 'components/Creator/Profile/Header/utils/getFollowers';
import { formatWholeNumber } from '@UIComponents/utils/formatters';
import { orderProductionsByType } from 'components/Creator/shared/utils/orderProductionsByType';

type PlatformData = {
  platform: keyof typeof Platforms;
  value: string;
  url?: string;
};

export default function getPlatformsData(productions: Production[]) {
  const platformsData = [] as PlatformData[];

  const orderedProductions = orderProductionsByType(productions);

  orderedProductions.forEach((production: Production) => {
    const url = production?.url;

    const followers = getFollowers(production?.id, productions);

    if (!platformsData.find((data) => data?.url === url)) {
      platformsData.push({
        platform: production?.type as Platforms,
        value: followers ? formatWholeNumber(followers) : '--',
        url,
      });
    }
  });

  return platformsData;
}
