import React from 'react';
import classnames from 'classnames';

import './StatusLabel.scss';

type StatusLabelProps = {
  status: string;
  text: string;
};

const cbn = 'external-ui-status-label';

function StatusLabel({ status, text }: StatusLabelProps) {
  return (
    <div className={cbn}>
      <div
        className={classnames(`${cbn}__wrapper`, {
          [`${cbn}__wrapper--${status}`]: status,
        })}
      >
        {text}
      </div>
    </div>
  );
}

export default StatusLabel;
