import ToolIcon from '@UIComponents/_assets/react_svg/ToolIcon';
import React from 'react';
import './Overlay.scss';

export default function Overlay({
  showOverlay,
  onOverlayOpenCb,
}: {
  showOverlay: boolean;
  onOverlayOpenCb: () => void;
}) {
  React.useEffect(() => {
    if (!showOverlay) {
      return;
    }

    onOverlayOpenCb();
  }, [onOverlayOpenCb, showOverlay]);

  if (!showOverlay) {
    return null;
  }

  return (
    <div className="overlay-container">
      <div className="message-container">
        <ToolIcon />
        <p className="message">
          Sorry, audience data is currently under construction. Keep checking
          back, we will have it up and running soon!
        </p>
      </div>
    </div>
  );
}
