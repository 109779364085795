import {
  formatWholeNumber,
  formatFloatNumber,
  formatPercentage,
} from '@UIComponents/utils/formatters';
import MetricType from 'shared/types/MetricType';
import { Production, QmMetrics } from 'shared/types/Production';
import getLatestHistoricData from 'components/utils/productions/getLatestHistoricData';
import { INSTAGRAM_STATISTICS } from 'components/Creator/Productions/Card/Instagram/constants';

const metricPerPlatformMapping: Record<
  string,
  Record<
    string,
    (
      production: Production | null | undefined,
      returnRawValue?: boolean,
    ) => string | number | null
  >
> = {
  tiktok: {
    followers: (production: Production, returnRawValue?: boolean) => {
      const value =
        production?.tiktokCreatorDataInsights?.authorizedDataInsights
          ?.followers_count ??
        production?.tiktokCreatorDataInsights?.publicDataInsights
          ?.followers_count ??
        production?.tiktokCreatorDataInsights?.authedUserInfo?.follower_count;

      if (returnRawValue) {
        return value;
      }
      if (!value) {
        return null;
      }
      const formattedValue = formatWholeNumber(value);
      return `${formattedValue} Followers`;
    },
    videos: (production: Production, returnRawValue?: boolean) => {
      const value =
        production?.tiktokCreatorDataInsights?.publicDataInsights
          ?.videos_count ??
        production?.tiktokCreatorDataInsights?.authedUserInfo?.video_count;
      if (returnRawValue) {
        return value;
      }
      if (!value) {
        return null;
      }
      const formattedValue = formatWholeNumber(value);
      return `${formattedValue} Videos`;
    },
    likes: (production: Production, returnRawValue?: boolean) => {
      const value =
        production?.tiktokCreatorDataInsights?.publicDataInsights
          ?.likes_count ??
        production?.tiktokCreatorDataInsights?.authedUserInfo?.likes_count;
      if (returnRawValue) {
        return value;
      }
      if (!value) {
        return null;
      }
      const formattedValue = formatFloatNumber(value);
      return `${formattedValue} Likes`;
    },
  },
  youtube: {
    followers: (production: Production, returnRawValue?: boolean) => {
      const value = getLatestHistoricData(
        production?.benChannelyticsData?.channelHistoricStats,
      )?.subscriber_count;
      if (returnRawValue) {
        return value;
      }
      const formattedValue = formatWholeNumber(value);
      return `${formattedValue} Subscribers`;
    },
    averageViews: (production: Production, returnRawValue?: boolean) => {
      const latestData = getLatestHistoricData(
        production?.benChannelyticsData?.channelHistoricStats,
      );
      const videoCount = latestData?.video_count;
      const viewCount = latestData?.view_count;

      if (
        Number.isNaN(Number(videoCount)) ||
        !videoCount ||
        Number.isNaN(Number(viewCount)) ||
        !viewCount
      ) {
        return '--';
      }

      const value = viewCount / videoCount;
      if (returnRawValue) {
        return value;
      }
      const formattedValue = formatFloatNumber(value);
      return `${formattedValue} Avg. Views`;
    },
    averageEngagementRate: () => '-- Avg. ER',
  },
  instagram: {
    followers: (production?: Production, returnRawValue?: boolean) => {
      const value = (production?.qmMetrics as QmMetrics[])?.[0]?.attributes
        ?.statistics?.value?.followers_count;
      if (returnRawValue) {
        return value;
      }
      if (!value) {
        return null;
      }
      const formattedValue = formatWholeNumber(value);
      return `${formattedValue} Followers`;
    },
    averageViews: (production: Production, returnRawValue?: boolean) => {
      const value = (production?.qmMetrics as QmMetrics[])?.[0]?.attributes?.[
        'eureka-ig-views-inference'
      ]?.values?.story_view_prediction;
      if (returnRawValue) {
        return value;
      }

      if (!value) {
        return null;
      }
      const formattedValue = formatFloatNumber(value);
      return `${formattedValue} Story View Prediction`;
    },
    averageEngagementRate: (
      production: Production,
      returnRawValue?: boolean,
    ) => {
      const value = (production?.qmMetrics as QmMetrics[])?.[0]?.attributes?.[
        INSTAGRAM_STATISTICS
      ]?.values?.engagement_rate;
      if (returnRawValue) {
        return value;
      }

      if (!value) {
        return null;
      }
      const formattedValue = formatPercentage(value);
      return `${formattedValue} Eng. Rate`;
    },
  },
};

export default function metricPerPlatform(
  production: Production,
  metric: MetricType,
  returnRawValue: boolean | undefined,
) {
  if (!production?.type) {
    return '--';
  }
  const parseFn = metricPerPlatformMapping?.[production?.type]?.[metric];
  if (typeof parseFn === 'function') {
    return parseFn(production, returnRawValue) ?? '--';
  }
  return '--';
}
