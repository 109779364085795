import { Context } from 'components/Campaign/Influencer/View/shared/types';
import { Placement } from 'shared/types/Placement';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import PlacementMediaCount from 'components/DataGrid/Cells/Placement/PlacementMediaCount/PlacementMediaCount';

export default function screenshots() {
  return {
    ...baseColDef,
    field: 'statsScreenshots',
    headerName: 'Screenshots',
    valueGetter: ({ data }: { data: Placement }) =>
      (data?.statsScreenshots || []).map((m) => m.url).join('\n'),
    cellRenderer: PlacementMediaCount,
    cellRendererParams: ({
      data,
      context,
    }: {
      data: Placement;
      context: Context;
    }) => ({
      placement: data,
      openPlacementDrawer: () => {
        if (context?.onOpenPlacementDrawer) {
          context.onOpenPlacementDrawer(data);
        }
      },
    }),
  };
}
