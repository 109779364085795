import React from 'react';
import { Campaign } from 'components/Campaign/types';
import UIDate from 'components/UI/Date/Date';
import UIImage from 'components/UI/Image/Image';
import { getThumbnail } from 'components/Campaign/utils';
import './Card.scss';

type ExternalCampaignCardProps = {
  campaign: Campaign;
};

const cbn = 'external-campaign-card';

function ExternalCampaignCard({ campaign }: ExternalCampaignCardProps) {
  return (
    <div
      data-cy={`ExternalCampaignItem-${(
        campaign.projectName ?? ''
      ).toLowerCase()}`}
      className="ExternalCampaignCard"
    >
      <div className={`${cbn}__thumb`}>
        <UIImage
          url={getThumbnail(campaign)}
          fallbackImage="placeholderImage"
          imagecachePreset="thumbnail"
        />
      </div>
      <div className={`${cbn}__name`}>{campaign.projectName}</div>
      <div className={`${cbn}__start-date`}>
        <UIDate>{campaign.dateRangeFrom}</UIDate>
      </div>
      <div className={`${cbn}__end-date`}>
        <UIDate>{campaign.dateRangeTo}</UIDate>
      </div>
      <div className={`${cbn}__status`}>{campaign.status}</div>
    </div>
  );
}

export default ExternalCampaignCard;
