import formatDate from 'shared/dates/formatDate';
import { OptionProps, components } from 'react-select';
import { ReportingWindowOption } from 'components/Campaign/Influencer/View/ReportingWindows/types/ReportingWindow';

import './OptionWithDates.scss';

export default function OptionWithDates({
  ...props
}: OptionProps<ReportingWindowOption>) {
  return (
    <components.Option {...props}>
      <span className="reporting-window">{props?.data?.label}</span>
      <span className="reporting-window-date">
        {formatDate(props?.data?.value?.date)}
      </span>
    </components.Option>
  );
}
