import React from 'react';
import './FigmaIcon.scss';

type FigmaIconProps = {
  type?: 'solid' | 'line';
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  icon: string;
  color?: string;
};

const FigmaIcon: React.FC<FigmaIconProps> = ({
  icon,
  type = 'line',
  size = 'large',
  color = 'black',
}) => {
  const classNames = `figmaicon figmaicon-${icon}-${type} ${size} figmaicon-color ${color}`;

  return <span className={classNames} />;
};

export default FigmaIcon;
