export enum DrawerSize {
  xsmall = 'x-small',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'x-large',
  full = 'full',
}

export default DrawerSize;
