/* eslint-disable max-lines-per-function */
import { useContext } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { longDateFormat } from 'components/utils/formats';
import Chat from 'components/Chat/Chat';
import UIDate from 'components/UI/Date/Date';
import UIImage from 'components/UI/Image/Image';
import StatusLabel from 'external/components/UI/StatusLabel/StatusLabel';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import { trackCreatorChatClicked } from 'external/components/Segment/Segment';
import useUser from 'store/authentication/useUser';
import { CreatedMessagePayload } from 'components/Chat/types';
import ColoredInitials from 'external/components/UI/ColoredInitials/ColoredInitials';
import { IInitialsItem } from 'external/components/UI/ColoredInitials/types';
import isSimilarityRecommendation from 'external/utils/isSimilarityRecommendation';
import AiRecommendationIcon from 'external/components/UI/AiRecommendationIcon/AiRecommendationIcon';
import { CreatedBy } from 'components/Deliverable/types';
import { getDeliverableExternalApprovalStatus } from '../utils';

import './Item.scss';

type ExternalDeliverableItemProps = {
  campaignId: number;
  createdAt: string;
  createdBy: CreatedBy;
  externalApproval: string;
  id: number;
  messagesCount: {
    external: number;
  };
  propertyId: number;
  propertyName: string;
  thumb: string;
  viewedByClient: boolean;
  onClick: () => void;
  initials?: IInitialsItem;
};

function ExternalDeliverableItem({
  campaignId,
  createdAt,
  createdBy,
  externalApproval,
  id,
  messagesCount,
  propertyId,
  propertyName,
  thumb,
  viewedByClient,
  onClick,
  initials,
}: ExternalDeliverableItemProps) {
  const { campaign, segment } = useContext(ExternalApplicationContext);
  const { user } = useUser();

  const label = getDeliverableExternalApprovalStatus({
    externalApproval,
    viewedByClient,
  });
  const isAIRecommendation: boolean = isSimilarityRecommendation(createdBy);

  const trackSegmentEvent = (payload: CreatedMessagePayload) => {
    if (segment && user && campaign) {
      trackCreatorChatClicked({
        segment,
        campaign,
        user,
        entityId: id,
        propertyId,
        propertyName,
        message: payload.message,
      });
    }
  };

  return (
    <div onClick={onClick} className="ExternalListItem">
      {thumb ? (
        <UIImage
          alt="ben-thumbnail"
          className="ExternalListItem--item external-deliverable-item__thumb"
          fallbackImage="placeholderImage"
          imagecachePreset="thumbnail"
          url={thumb}
        />
      ) : (
        <ColoredInitials initials={initials} icon />
      )}
      <div className="ExternalListItem--item external-deliverable-item__name">
        {propertyName}
      </div>
      <div className="ExternalListItem--item external-deliverable-item__status">
        <div className="external-deliverable-item__status">
          <StatusLabel status={label.state} text={label.text} />
        </div>
      </div>

      <div className="ExternalListItem--item external-deliverable-item__chat">
        <Chat
          childKey={campaignId}
          childType="campaign"
          counts={messagesCount}
          entityId={id}
          entityType="deliverable"
          isExternal
          onCreateMessage={trackSegmentEvent}
        />
      </div>
      <UIDate
        as="div"
        className="ExternalListItem--item external-deliverable-item__date"
        format={longDateFormat}
      >
        {createdAt}
      </UIDate>
      <div className="ExternalListItem--item external-deliverable-item__recommendedByAI">
        {isAIRecommendation ? (
          <div
            className={`recommended-by-badge-wrapper recommended-by-${
              isAIRecommendation ? 'ai' : 'ben'
            }`}
          >
            <AiRecommendationIcon />
          </div>
        ) : null}
      </div>
    </div>
  );
}

ExternalDeliverableItem.propTypes = {
  campaignId: number,
  externalApproval: string,
  id: number.isRequired,
  createdAt: string,
  messagesCount: shape({
    external: number,
  }),
  productions: arrayOf(
    shape({
      type: string,
    }),
  ),
  thumb: string,
  viewedByClient: bool,
};

ExternalDeliverableItem.defaultProps = {
  campaignId: null,
  externalApproval: undefined,
  createdAt: '',
  messagesCount: null,
  productions: null,
  thumb: '',
  viewedByClient: null,
};

export default ExternalDeliverableItem;
