/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { bool, arrayOf, object, func, string } from 'prop-types';
import classNames from 'classnames';
import UILoader from 'components/UI/Loader/Loader';

/*
 * @param {Function(item, props)} render - function to render a single row
 * @param {Array} data - results for your table
 *
 * <UITableBody
 *  data={[ id: 'uniqueid', name: 'My name']}
 *  render={(item, props) => (
 *    <Row key={item.id}>
 *      <Col>{item.name}</Col>
 *    </Row>
 *  )}
 * />
 *
 */

const propTypes = {
  className: string,
  columnIds: arrayOf(string),
  data: arrayOf(object), // eslint-disable-line
  filterItem: func,
  isLoading: bool,
  render: func,
};

const defaultProps = {
  className: null,
  columnIds: null,
  data: [],
  filterItem: () => true,
  isLoading: false,
  render: () => null,
};

function UITableBody({
  className,
  data,
  filterItem,
  isLoading,
  render,
  ...restProps
}) {
  const items = useMemo(() => data.filter(filterItem), [data]);
  if (isLoading) {
    return <UILoader />;
  }

  function renderRow(rowItem, index) {
    return render(rowItem, restProps, index);
  }

  return (
    <div className={classNames('ui-table__rows', className)}>
      {!items?.length ? 'No results found' : items.map(renderRow)}
    </div>
  );
}

UITableBody.propTypes = propTypes;
UITableBody.defaultProps = defaultProps;

export default UITableBody;
