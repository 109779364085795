import { useContext } from 'react';
import ApplicationContext from 'components/Application/Context';
import featureFlags from 'store/featureFlags';

export default function useFeatureFlags() {
  const { user } = useContext(ApplicationContext);

  return Object.entries(featureFlags).reduce((acc, [flag, options]) => {
    if (options.status !== 'optional') {
      return acc;
    }

    return {
      ...acc,
      [flag]: {
        ...options,
        ...(user?.featureFlags?.[flag] || {}),
      },
    };
  }, {});
}
