import NoDataEmptyState from 'external/components/shared/NoDataEmptyState';
import BaseCard from '@UIComponents/BaseCard/BaseCard';

function ExternalCampaignPlaceholderNoneEnabled() {
  return (
    /* @ts-ignore */
    <BaseCard title="No data here">
      <NoDataEmptyState />
    </BaseCard>
  );
}

export default ExternalCampaignPlaceholderNoneEnabled;
