import get from 'lodash/get';

function isServerOffline(err) {
  const data = get(err, 'response.data');
  const message = get(err, 'response.data.message');

  if (typeof data === 'string' && data.indexOf('<html>') === 0) {
    return true;
  }

  return message === 'Request failed with status code 502';
}

export default function restErrorParser(err) {
  const defaultApiError = () => {
    const message =
      err.response && err.response.data
        ? `${err.response.data.error || 'Error'} ${err.response.data.message ||
            'unknown'}`
        : 'An unknown error occurred';

    return {
      title: `${get(err, 'response.status')} ${get(
        err,
        'response.statusText',
      )}`,
      description: message,
      raw: err,
    };
  };

  const forbidden = () => {
    const isSharedOpp = /api\/opportunities\/\w*\?sharedBy=.*&key=\w*/.test(
      get(err, 'response.config.url'),
    );
    if (isSharedOpp) {
      return null;
    }

    return {
      title: '403 Forbidden',
      description: get(err, 'response.data.message'),
      raw: err,
    };
  };

  const badGateway = () => ({
    raw: err,
    title: '502 Bad Gateway',
    description: isServerOffline(err)
      ? 'Server is temporarily offline'
      : get(err, 'response.data.message'),
  });

  const handlers = {
    403: forbidden,
    502: badGateway,
  };

  const statusCode = get(err, 'response.status');

  const handler = handlers[statusCode] || defaultApiError;
  return handler(err);
}
