import Loading from '../../../../Loading/Loading';
import './ApplyButton.scss';

export default function ApplyButton({
  onClick,
  text = 'Apply',
  type = 'button',
  disabled,
  loading,
  dataCy,
}: Readonly<{
  onClick?: () => void;
  text?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  dataCy?: string;
}>) {
  return (
    <button
      data-cy={dataCy ?? null}
      type={type === 'button' ? 'button' : 'submit'}
      className="btn-apply"
      onClick={onClick}
      disabled={Boolean(disabled ?? loading)}
    >
      {loading ? <Loading size="small" /> : text}
    </button>
  );
}
