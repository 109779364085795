import React, { forwardRef } from 'react';
import classnames from 'classnames';
import UIButton from 'components/UI/Button/Button';
import UIButtonGroup from 'components/UI/Button/Group/Group';
import { removeIn, isEmptyArray } from 'utils/array';

type AllBasicTypes = Object | string | number | boolean | [];

type NextValueParams = {
  allValue?: AllBasicTypes | {} | null;
  childrenArray: (React.ReactChild | React.ReactFragment | React.ReactPortal)[];
  getOptionKey: Function;
  isMulti: boolean;
  showAllButton: boolean;
  isToggle: boolean;
  value: AllBasicTypes;
  itemValue: AllBasicTypes;
};

type ButtonGroupSelectorProps = WithChildren<{
  all?: {
    label: string;
    value: number | string | string[] | number[] | null;
  };
  className?: string;
  disabled?: boolean;
  getOptionKey?: Function;
  isMulti?: boolean;
  name?: string;
  onChange?: Function;
  showAllButton?: boolean;
  theme?: string;
  value?: AllBasicTypes;
  isToggle?: boolean;
}>;

type MapSignature = (value: any, index: number, array: any[]) => any;

function getNextValue({
  allValue,
  childrenArray,
  getOptionKey,
  isMulti,
  itemValue,
  showAllButton,
  value,
  isToggle,
}: NextValueParams) {
  const itemKey = getOptionKey(itemValue);

  const valueKey =
    isMulti && Array.isArray(value)
      ? value.map(getOptionKey as MapSignature)
      : getOptionKey(value);

  if (isToggle) return itemKey;

  if (itemValue === '_ALL') {
    return allValue;
  }

  if (!isMulti) {
    return itemKey !== valueKey ? itemValue : null;
  }

  if (!Array.isArray(value)) {
    return [itemValue];
  }

  const index = value.findIndex((v) => getOptionKey(v) === itemKey);
  if (index >= 0) {
    return removeIn(value, index);
  }

  const nvalue = [...value, itemValue];
  const areAllSelected =
    showAllButton && nvalue.length === childrenArray.length;

  return areAllSelected ? allValue : nvalue;
}

function isActive({
  optValue,
  getOptionKey,
  value,
  isMulti,
  valueKey,
}: {
  optValue: AllBasicTypes;
  getOptionKey: Function;
  value: AllBasicTypes;
  isMulti: boolean;
  valueKey: string;
}) {
  const optionKey = getOptionKey(optValue);

  return Array.isArray(value) && isMulti
    ? valueKey.includes(optionKey)
    : valueKey === optionKey;
}

const ButtonGroupSelector = forwardRef<
  typeof UIButtonGroup,
  ButtonGroupSelectorProps
>(
  (
    {
      all = { label: 'All', value: null },
      children,
      className = '',
      disabled = false,
      getOptionKey = (option: AllBasicTypes) => option,
      isMulti = false,
      name = '',
      // @ts-ignore
      onChange = () => null,
      showAllButton = false,
      theme = 'bordered-light',
      value = '',
      isToggle = false,
    },
    ref,
  ) => {
    const valueKey =
      isMulti && Array.isArray(value)
        ? value.map(getOptionKey as MapSignature)
        : getOptionKey(value);

    const childrenArray = children as JSX.Element[];

    function handleChange(itemValue: AllBasicTypes) {
      const nvalue = getNextValue({
        allValue: all?.value,
        childrenArray,
        getOptionKey,
        isMulti,
        itemValue,
        showAllButton,
        value,
        isToggle,
      });

      if (onChange) onChange(nvalue);
    }

    const allIsActive =
      all?.value === value || (isEmptyArray(all?.value) && isEmptyArray(value));

    return (
      <UIButtonGroup
        // @ts-ignore
        ref={ref as React.Ref<HTMLDivElement>}
        className={classnames(
          `formik-button-group-selector--${(name ?? '').replace('.', '-')}`,
          className,
        )}
      >
        {showAllButton && (
          <UIButton
            onClick={() => handleChange('_ALL')}
            active={allIsActive}
            theme={allIsActive ? 'bordered-purple' : 'bordered-grey'}
          >
            {all?.label}
          </UIButton>
        )}

        {childrenArray.map((child: JSX.Element) => {
          if (child?.props?.uncontrolledChild) {
            return child;
          }

          const active = isActive({
            optValue: child?.props?.value,
            getOptionKey,
            value,
            isMulti,
            valueKey,
          });

          return React.cloneElement(child, {
            key: getOptionKey(child?.props?.value),
            onClick: () => handleChange(child?.props?.value),
            theme: active ? 'bordered-purple' : theme || child?.props.theme,
            active,
            disabled: child?.props?.disabled || disabled,
          });
        })}
      </UIButtonGroup>
    );
  },
);

export default ButtonGroupSelector;
