import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import UILink from 'components/UI/Link/Link';

function EmailLink({ entityArray = [], entity, label }) {
  if (!entityArray || !entityArray.length) {
    return null;
  }

  let link;
  if (entityArray.length === 1) {
    link = `/${entity}/${entityArray[0]}/edit`;
  } else {
    link = `/${entity}/list?${entityArray.map((p) => `id=${p}`).join('&')}`;
  }

  return (
    <div>
      <UILink to={link}>
        {label}
        {' '}
        (
        {entityArray.length}
        )
      </UILink>
    </div>
  );
}

EmailLink.propTypes = {
  entity: string,
  entityArray: arrayOf(shape({})),
  label: string,
};

EmailLink.defaultProps = {
  entity: null,
  entityArray: null,
  label: null,
};

export default EmailLink;
