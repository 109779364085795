import { useState } from 'react';
import api from 'components/utils/api';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash/get';
import { errorStream } from 'store/errorHandler';
import { DEFAULT_BAD_GATEWAY_PAYLOAD } from './utils';

/* const [call, { data, pending, error }] = useLazyRest(config);
 * config should be a axios config object
 * https://github.com/axios/axios#request-config
 */

type State<RDataT> = {
  pending: boolean;
  error: object | null;
  called: boolean;
  data: RDataT | null;
};

const initialState = () => ({
  pending: false,
  // @ts-ignore
  error: null,
  called: false,
  // @ts-ignore
  data: null,
});

type Config<RDataT = any> = AxiosRequestConfig & {
  onCompleted?: (data: RDataT) => void;
  errorPolicy?: 'ignore';
  throw?: boolean;
};

type Caller<RDataT> = (
  config?: Config<RDataT>,
  returnFullResponse?: boolean,
) => Promise<RDataT | null>;

const getErrorData = (error: Error) => {
  const errorStatus = get(error, 'response.status');
  return errorStatus === 502
    ? DEFAULT_BAD_GATEWAY_PAYLOAD
    : get(error, 'response.data');
};

export default function useLazyRest<ResponseDataT>(
  config: Config,
): [Caller<ResponseDataT>, State<ResponseDataT>, () => void] {
  const [state, setState] = useState<State<ResponseDataT>>(initialState);

  // eslint-disable-next-line max-statements
  async function call(
    lconfig?: Config,
    returnFullResponse = false,
  ): Promise<ResponseDataT | any> {
    const cfg = { ...config, ...lconfig };
    setState({ ...initialState(), pending: true });

    try {
      const response = await api(cfg);
      setState({
        data: response.data,
        pending: false,
        called: true,
        error: null,
      });
      if (cfg.onCompleted) {
        cfg.onCompleted(response.data);
      }
      if (returnFullResponse) {
        return response;
      }
      return response.data;
    } catch (err) {
      if (cfg.errorPolicy !== 'ignore') {
        errorStream.dispatch({ type: 'restApiError', error: err });
      }
      // @ts-ignore
      const errorData = getErrorData(err);

      setState({ ...initialState(), error: errorData });

      if (cfg.throw) {
        throw errorData;
      }
      return null;
    }
  }

  function reset() {
    setState({
      pending: false,
      error: null,
      called: false,
      data: null,
    });
  }

  return [call, state, reset];
}
