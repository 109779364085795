import React, { forwardRef } from 'react';
import classnames from 'classnames';

import './Group.scss';

const cbn = 'ui-button-group';
type UIButtonGroupProps = WithChildren<{ className?: string }>;
const UIButtonGroup = forwardRef<HTMLDivElement, UIButtonGroupProps>(
  ({ children, className }, ref) => (
    <div ref={ref} className={classnames(cbn, className)} role="group">
      {children}
    </div>
  ),
);

export default UIButtonGroup;
