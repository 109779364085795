import updateQuery from 'components/Campaign/Influencer/View/hooks/utils/updateQuery';
import { useQuery } from '@apollo/react-hooks';

const FETCH_MORE_LIMIT = 100;

export default function useListLoader({
  entityName,
  query,
  skip,
  variables,
  onCompleted,
}) {
  const { data, fetchMore, ...info } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    skip,
    variables,
    onCompleted: (response) => {
      const { data: list, meta } = response[entityName];

      if (meta.total > list.length) {
        fetchMore({
          variables: {
            skip: list.length,
            limit: FETCH_MORE_LIMIT,
          },
          updateQuery: updateQuery(entityName),
        }).catch((e) => {
          // APP-1886 - this is an error triggered by processing a query's return data from an
          // unmounted component. We should be able to safely ignore it, although a proper fix
          // would be to cancel the query in those cases.
          // More info: https://github.com/apollographql/apollo-client/issues/4114
          if (
            !e.message.includes("ObservableQuery with this id doesn't exist:")
          ) {
            throw e;
          }
        });
      }

      if (onCompleted) {
        onCompleted(response);
      }
    },
  });

  return {
    ...info,
    data: data?.[entityName].data,
    meta: data?.[entityName].meta,
    facets: data?.[entityName].facets,
  };
}
