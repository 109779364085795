import React from 'react';

import './Footer.scss';

function LayoutFooter() {
  return (
    <div className="footer">
      <div className="footer__inner">
        <div className="footer__legal">
          <ul className="footer__links">
            <li>
              <a
                href="https://benlabs.com/ben-app-terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                BENlabs: Terms of Use
              </a>
            </li>
            <li>
              <a
                href="https://benlabs.com/rules"
                target="_blank"
                rel="noreferrer"
              >
                Usage Rules
              </a>
            </li>
            <li>
              <a
                href="https://benlabs.com/ben-app-privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                BENlabs: Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://benlabs.com/cookies"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Policy
              </a>
            </li>
          </ul>
          <div className="footer__copyright">
            <span>
              &#169;
              {new Date().getFullYear()}
            </span>
            <span>BENlabs, Inc.</span>
            <span>All rights reserved.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutFooter;
