import './LabelValueCombo.scss';

export default function LabelValueCombo({
  label,
  value,
  children,
}: {
  label: string;
  value: string | number;
  children?: string | React.ReactNode;
}) {
  return (
    <p className="label-value-combo">
      <span>{`${label}:`}</span> {children ?? value}
    </p>
  );
}
