import React, { memo, useCallback } from 'react';
import {
  bool,
  string,
  func,
  shape,
  arrayOf,
  oneOfType,
  number,
} from 'prop-types';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import useAsyncSelector from 'components/Form/useAsyncSelector/useAsyncSelector';
import FormSelector from 'components/Form/Selector/Selector';
import classnames from 'classnames';
import { getFullTaxonomyName } from 'components/Taxonomy/utils';

import './TaxonomySelector.scss';

export const GET_TAXONOMIES = gql`
  query TaxonomyList($filter: TaxonomyFilter, $limit: Int, $skip: Int) {
    taxonomies(filter: $filter, skip: $skip, limit: $limit) {
      data {
        id
        name
        vocabulary
      }
      meta {
        total
      }
    }
  }
`;

function processResults(results, hideVocabulary) {
  if (!hideVocabulary) {
    return results.map((result) => ({
      ...result,
      name: getFullTaxonomyName(result),
    }));
  }
  return results;
}

const cbn = 'form-taxonomy-selector';

function TaxonomySelector({
  client,
  disabled,
  filter: parentFilter,
  idsOnly,
  isClearable,
  isMulti,
  multi,
  onChange,
  onFocus,
  placeholder,
  readOnly,
  value: parentValue,
  valueKey,
  vocabulary,
  ...restProps
}) {
  const memoLoadOptions = useCallback(
    ({ filter, ...options }) => {
      const lfilter = { ...filter };

      if (vocabulary) {
        lfilter.vocabulary = vocabulary;
      }

      return client
        .query({
          query: GET_TAXONOMIES,
          variables: {
            ...options,
            filter: lfilter,
          },
        })
        .then(
          (result) => ({
            data: processResults(result.data.taxonomies.data, vocabulary),
            meta: result.data.taxonomies.meta,
          }),
          (error) => ({ error }),
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vocabulary],
  );

  const {
    hasMinLength,
    hasMore,
    isLoading,
    loadMore,
    onInputChange,
    onMenuOpen,
    onMenuClose,
    options,
    value,
  } = useAsyncSelector(memoLoadOptions, {
    parentFilter,
    parentValue,
    valueKey,
  });

  return (
    <FormSelector
      {...restProps}
      className={classnames(cbn, `${cbn}--${vocabulary}`)}
      labelKey="name"
      valueKey={valueKey}
      isMulti={multi || isMulti}
      disabled={disabled}
      readOnly={readOnly}
      ignoreOptionFilter
      isClearable={isClearable}
      /* useAsyncSelector props */
      hasMinLength={hasMinLength}
      hasMore={hasMore}
      idsOnly={idsOnly}
      isLoading={isLoading}
      loadMore={loadMore}
      onChange={onChange}
      onFocus={onFocus}
      onInputChange={onInputChange}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
}

const taxonomyPropType = shape({ id: number });

TaxonomySelector.propTypes = {
  client: shape({
    query: func.isRequired,
  }).isRequired,
  disabled: bool,
  readOnly: bool,
  filter: shape({}),
  idsOnly: bool,
  isClearable: bool,
  isMulti: bool,
  multi: bool,
  onChange: func.isRequired,
  onFocus: func,
  placeholder: string,
  valueKey: string,
  vocabulary: string,
  value: oneOfType([arrayOf(taxonomyPropType), taxonomyPropType, number]),
};

TaxonomySelector.defaultProps = {
  disabled: false,
  readOnly: false,
  filter: null,
  idsOnly: false,
  isMulti: false,
  isClearable: true,
  multi: false,
  onFocus: null,
  placeholder: '',
  value: null,
  valueKey: 'id',
  vocabulary: null,
};

export default memo(withApollo(TaxonomySelector));
