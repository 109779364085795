export const noOptionsMessage = (
  isLoading,
  hasMinLength,
  placeholder,
  noResultsMessage,
) => {
  if (isLoading) return 'Loading options...';

  return hasMinLength ? noResultsMessage : placeholder;
};

export const isKey = (v) => ['string', 'number', 'boolean'].includes(typeof v);

const pureValue = (value, toValue) => (isKey(value) ? value : toValue(value));

export const toKeys = (selected, toValue) =>
  (Array.isArray(selected)
    ? selected.map((item) => item && pureValue(item, toValue))
    : pureValue(selected, toValue));

export function parseValue(selected, toValue, idsOnly) {
  if (!selected) {
    return null;
  }

  if (Array.isArray(selected) && !selected.length) {
    // we must return null, so the selector decides which empty value should be used.
    return null;
  }

  return !idsOnly ? selected : toKeys(selected, toValue);
}

export function populateValue(toValue, options, v) {
  if ((!v && v !== 0 && v !== false) || !options) return v;

  const map = {};
  options.forEach((opt) => {
    map[toValue(opt)] = opt;
  });

  if (Array.isArray(v)) {
    return v.map((vitem) => (isKey(vitem) ? map[vitem] : vitem));
  }

  return isKey(v) ? map[v] : v;
}
