import React from 'react';
import { func, string } from 'prop-types';
import UIIcon from 'components/UI/Icon/Icon';

import './Input.scss';

const ENTER_KEYCODE = 13;

function ChatInput({ onChange, sendMessage, value }) {
  const hasMessage = value && /\S/.test(value);

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEYCODE) {
      if (!hasMessage) {
        e.preventDefault();
      }

      if (!e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  return (
    <div className="chat-input">
      <textarea
        onKeyDown={handleKeyDown}
        className="chat-input__input"
        value={value}
        onChange={onChange}
        placeholder="Type new message"
      />
      <button
        className="chat-input__submit"
        type="button"
        onClick={sendMessage}
      >
        <UIIcon type="send" tooltip="Send" />
      </button>
    </div>
  );
}

ChatInput.propTypes = {
  sendMessage: func.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

export default ChatInput;
