import twitchCommon from './helpers/twitchCommon';

const metric = 'chatters';
export default function chatters() {
  return {
    ...twitchCommon(metric),
    headerName: 'Chatters',
    width: 170,
  };
}
