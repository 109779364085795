import { useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';

export default function usePortal() {
  const portalRef = useRef(null);
  const portalRootRef = useRef(null);

  useEffect(() => {
    function handleClick(event) {
      event.stopPropagation();
    }

    portalRef.current = document.createElement('div');
    portalRootRef.current = document.getElementById('portal-root');

    if (portalRootRef.current) {
      portalRef.current.addEventListener('mousedown', handleClick);
      portalRootRef.current.appendChild(portalRef.current);
    }

    return () => {
      if (portalRootRef.current) {
        portalRef.current.removeEventListener('mousedown', handleClick);
        if (portalRef.current.parentNode === portalRootRef.current) {
          portalRootRef.current.removeChild(portalRef.current);
        }
      }
    };
  }, []);

  return useCallback(({ children }) => {
    if (portalRef.current != null) {
      return createPortal(children, portalRef.current);
    }
    return null;
  }, []);
}
