/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import {
  getOperationDefinition,
  getFragmentDefinition,
  getFragmentDefinitions,
  createFragmentMap,
  removeArgumentsFromDocument,
} from 'apollo-utilities';

import { visit } from 'graphql/language/visitor';

export function filterInPlace(array, test, context) {
  let target = 0;
  // eslint-disable-next-line func-names
  array.forEach(function (elem, i) {
    if (test.call(this, elem, i, array)) {
      array[(target += 1)] = elem;
    }
  }, context);
  array.length = target;
  return array;
}

function isEmpty(op, fragments) {
  return op.selectionSet.selections.every(
    (selection) =>
      selection.kind === 'FragmentSpread' &&
      isEmpty(fragments[selection.name.value], fragments),
  );
}

function nullIfDocIsEmpty(doc) {
  return isEmpty(
    getOperationDefinition(doc) || getFragmentDefinition(doc),
    createFragmentMap(getFragmentDefinitions(doc)),
  )
    ? null
    : doc;
}

function getDirectiveMatcher(directives) {
  return function directiveMatcher(directive) {
    return directives.some(
      (dir) =>
        (dir.name && dir.name === directive.name.value) ||
        (dir.test && dir.test(directive)),
    );
  };
}

function pushVariablesToRemove(node, variablesToRemove) {
  if (node.arguments?.length) {
    node.arguments.forEach((arg) => {
      if (arg.value.kind === 'Variable') {
        variablesToRemove.push({ name: arg?.value?.name?.value });
      }
    });
  }

  if (node?.selectionSet?.selections?.length) {
    node.selectionSet.selections.forEach((selection) => {
      pushVariablesToRemove(selection, variablesToRemove);
    });
  }
}

function getDirectiveFieldRemove(
  directives,
  node,
  key,
  parent,
  path,
  ancestors,
) {
  return function directiveFieldRemove(directive) {
    return directives.some(
      (dir) =>
        dir.name &&
        dir.name === directive.name.value &&
        dir.remove &&
        dir.remove(directive, node, key, parent, path, ancestors),
    );
  };
}

export function removeDirectivesFromDocument(directives, doc) {
  const variablesInUse = {};
  const variablesToRemove = [];

  let modifiedDoc = nullIfDocIsEmpty(
    visit(doc, {
      Variable: {
        enter(node, _key, parent) {
          // Store each variable that's referenced as part of an argument
          // (excluding operation definition variables), so we know which
          // variables are being used. If we later want to remove a variable
          // we'll fist check to see if it's being used, before continuing with
          // the removal.
          if (parent?.kind !== 'VariableDefinition') {
            variablesInUse[node.name.value] = true;
          }
        },
      },
      Field: {
        enter(node, key, parent, path, ancestors) {
          if (directives && node.directives) {
            // If a directive match and `remove` fn returns true remove the field as well.
            if (
              node.directives &&
              node.directives.some(
                getDirectiveFieldRemove(
                  directives,
                  node,
                  key,
                  parent,
                  path,
                  ancestors,
                ),
              )
            ) {
              pushVariablesToRemove(node, variablesToRemove);
              return null;
            }
          }
        },
      },

      Directive: {
        enter(node) {
          // If a matching directive is found, remove it.
          if (getDirectiveMatcher(directives)(node)) {
            return null;
          }
        },
      },
    }),
  );

  // If we've removed fields with arguments, make sure the associated
  // variables are also removed from the rest of the document, as long as they
  // aren't being used elsewhere.

  const toRemove = variablesToRemove.filter((v) => !variablesInUse[v.name]);
  if (modifiedDoc && toRemove.length) {
    modifiedDoc = removeArgumentsFromDocument(toRemove, modifiedDoc);
  }

  return modifiedDoc;
}
