import {
  STREAM_MEDIA_TYPE,
  TWITCH_PLATFORM,
  VIDEO_MEDIA_TYPE,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import basePerformanceColDef from '../../helpers/baseColDef';
import getYouTubeDataFromCampaignService from '../helpers/getYouTubeDataFromCampaignService';

const metric = 'uniqueViews';
export function uniqueViewsAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric);
  }
  return null;
}
export default function uniqueViews() {
  return {
    ...basePerformanceColDef(
      metric,
      uniqueViewsAutomatedValueGetter,
      [YOUTUBE_PLATFORM, TWITCH_PLATFORM],
      [VIDEO_MEDIA_TYPE, STREAM_MEDIA_TYPE],
    ),
    headerName: 'Unique Views',
    width: 210,
  };
}
