import React from 'react';

type AdvancedFiltersContextType = {
  registerField: (fieldName: string, sectionName: string) => void;
  unregisterField: (fieldName: string) => void;
  fields: object;
  activeFields: object;
  hasProvider: boolean;
};

const AdvancedFiltersContext = React.createContext<AdvancedFiltersContextType>({
  registerField: () => {},
  unregisterField: () => {},
  fields: {},
  activeFields: {},
  hasProvider: false,
});

export default AdvancedFiltersContext;
