import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import tikTokCommon from './helpers/tikTokCommon';

export default function videoCompletionRate25() {
  const tikTokMetric = 'fct_lifetime_video_performance.video_complete_rate_benchmark_p25' as keyof typeof TCMReportMetrics;
  const metric = 'video_complete_rate_benchmark_p25';
  return {
    ...percentColDef,
    ...tikTokCommon(tikTokMetric, metric),
    field: 'videoCompletionRate25',
    headerName: '25% Video Completion Rate',
    width: 310,
  };
}
