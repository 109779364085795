import { TCMSparkAdStatusData } from 'shared/types/TikTokMarketPlace';
import UpdateSubmit from './UpdateSubmit/UpdateSubmit';
import UpdateContent from './UpdateContent/UpdateContent';
import { SparkAdsSegmentEvents } from '../hooks/useSegmentEvents';

import './UpdateSparkAd.scss';

export default function UpdateSparkAd({
  sparkAdStatusData,
  contentLink,
  onResendRequest,
  thumbnailUrl,
  segmentEvents,
  tikTokSparkAdStatusRefetch,
}: {
  sparkAdStatusData: TCMSparkAdStatusData;
  contentLink: string | undefined | null;
  onResendRequest: () => void;
  thumbnailUrl: string | undefined | null;
  segmentEvents?: SparkAdsSegmentEvents;
  tikTokSparkAdStatusRefetch: () => void;
}) {
  return (
    <section className="tiktok-spark-adds__update">
      <UpdateContent
        sparkAdStatusData={sparkAdStatusData}
        contentLink={contentLink}
        thumbnailUrl={thumbnailUrl}
        trackContentLinkClick={segmentEvents?.trackContentLinkClick}
        tikTokSparkAdStatusRefetch={tikTokSparkAdStatusRefetch}
      />

      <UpdateSubmit
        onResendRequest={onResendRequest}
        remainingRequests={
          sparkAdStatusData?.num_remaining_request ??
          sparkAdStatusData?.num_remaining_requests ??
          0
        }
        trackResendRequest={segmentEvents?.trackResendRequest}
      />
    </section>
  );
}
