import {
  getInitialsMapByDeliverable,
  getInitialsByDeliverable,
  getInitialsByPlacement,
  getInitialsMapByPlacement,
  initialsDefault,
} from './utils/getInitialsMap';

import { IInitialsItem } from './types';

import './ColoredInitials.scss';

function ColoredInitials({
  initials = initialsDefault,
  icon = false,
  medium = false,
}: {
  initials?: IInitialsItem;
  icon?: boolean;
  medium?: boolean;
}) {
  return (
    <div
      className={`colored-initials ${icon && 'icon'} ${medium && 'medium'}`}
      style={{ backgroundColor: initials.color }}
    >
      <p>{initials?.letters}</p>
    </div>
  );
}

export default ColoredInitials;

export {
  getInitialsMapByDeliverable,
  getInitialsByDeliverable,
  getInitialsMapByPlacement,
  getInitialsByPlacement,
};
