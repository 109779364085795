import BaseButton from '@UIComponents/BaseButton/BaseButton';
import BaseModal from '@UIComponents/BaseModal/BaseModal';

type ApprovalModalProps = {
  handleApproval: () => void;
  isOpen: boolean;
  name: string;
  onClose: () => void;
  submitted: boolean;
};

function ApprovalModal({
  handleApproval,
  isOpen,
  name,
  onClose,
  submitted,
}: ApprovalModalProps) {
  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} title="Approve creator">
      <div className="external-content-detail-panel-modal">
        {submitted ? (
          <>
            <p className="bold">{`${name} has been approved for outreach!`}</p>
            <p>We’ll inform our team to start the outreach process.</p>
            <div className="button-wrapper">
              <BaseButton text="Got it!" onClick={onClose} />
            </div>
          </>
        ) : (
          <>
            <p className="bold">{`Are you sure you want to approve ${name} for outreach?`}</p>
            <p>Once approved, our team will start the outreach process.</p>
            <div className="button-wrapper">
              <BaseButton
                text="Yes, approve to outreach"
                onClick={handleApproval}
              />
              <BaseButton text="Cancel" variant="text" onClick={onClose} />
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}

export default ApprovalModal;
