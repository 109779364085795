import { ValueFormatterParams } from 'ag-grid-community';
import NumberInput from 'components/DataGrid/Editors/Destination/NumberInput/NumberInput';
import { Placement } from 'shared/types/Placement';

const numberColDef = {
  valueFormatter: ({ value }: ValueFormatterParams<Placement>) => {
    if (!value) {
      return null;
    }

    return Intl.NumberFormat('us-US', {
      style: 'currency',
      currency: 'USD',
      maximumSignificantDigits: 2,
      currencyDisplay: 'narrowSymbol',
    }).format(value);
  },
  cellEditor: NumberInput,
  headerClass: 'header-align-right',
  cellClass: 'cell-align-right',
};

export default numberColDef;
