import React, { Component } from 'react';
import { string, number, func, bool } from 'prop-types';
import classnames from 'classnames';
import filesize from 'filesize';
import './Link.scss';

class UIFileLink extends Component {
  static propTypes = {
    url: string,
    className: string,
    filename: string,
    size: number,
    onClick: func,
    hasMask: bool,
  };

  static defaultProps = {
    hasMask: false,
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { onClick } = this.props;
    if (onClick) {
      onClick(e, this.props);
    }
  }

  render() {
    const { url, className, filename, size, hasMask } = this.props;
    const fileSizeLabel =
      size === null || size === undefined ? '' : ` (${filesize(size)})`;
    const fileLabel = filename ? `${filename}${fileSizeLabel}` : '';
    return (
      <div
        className={classnames(className, 'ui-file-link', {
          'has-mask': hasMask,
        })}
      >
        This media is in an unsupported format. Click
        {' '}
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.onClick}
        >
          here
        </a>
        {' '}
        to view 
        {' '}
        {fileLabel}
        {' '}
        file outside of BEN or download it via the link
        below.
      </div>
    );
  }
}

export default UIFileLink;
