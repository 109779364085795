import FileSaver from 'file-saver';
import { allowedRemoteSources } from './constants';

// storing `key` allows us to have a unique identifier per upload
// even after the file upload is completed; That way we can better control the component
// lifecyle; eg: display blob preview until the actual image is loaded by the browser

export const mapFile = (file) => {
  const isRemote = file.mime && /youtube/.test(file.mime);
  const created = new Date().toISOString();

  return isRemote
    ? {
        key: file.url,
        url: file.url,
        filename: file.url,
        mime: file.mime,
        created,
      }
    : {
        key: file.preview,
        url: file.preview,
        filename: file.name,
        mime: file.type,
        size: file.size,
        created,
      };
};

export const getFileType = (file) => {
  const mimeMatch = /^(video|image)/.exec(file.mime);
  return mimeMatch && mimeMatch[0] ? mimeMatch[0] : null;
};

export const getMimeFromURL = (url) =>
  Object.keys(allowedRemoteSources).find((s) => {
    const keyAsRegex = new RegExp(s, 'i');
    return keyAsRegex.test(url);
  });

export function beforeUnload(e) {
  const confirmationMessage =
    'Upload is still in progress, if you leave now any incomplete uploads will be lost.';
  (e || window.event).returnValue = confirmationMessage;
  return confirmationMessage;
}

export function downloadCachedFile(file) {
  FileSaver.saveAs(file, file.filename);
}

export function downloadPrivateFile(file, endpoint, authorizationToken) {
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${authorizationToken}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => FileSaver.saveAs(blob, file.filename));
}
