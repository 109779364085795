import { INSTAGRAM_PLATFORM, STORY_MEDIA_TYPE } from 'shared/metrics/kpis';
import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';

const metric = 'nonFollowerReach';
export default function nonFollowerReach() {
  return {
    ...basePerformanceColDef(
      metric,
      () => null,
      [INSTAGRAM_PLATFORM],
      [STORY_MEDIA_TYPE],
    ),
    headerName: 'Non Follower Reach',
  };
}
