export const updateIn = (array, indexOrFinder, updater) => {
  if (!Array.isArray(array)) {
    return array;
  }

  const index =
    typeof indexOrFinder === 'function'
      ? array.findIndex(indexOrFinder)
      : indexOrFinder;

  if (index === -1) {
    return array;
  }

  const newArray = [...array];

  newArray[index] = updater(array[index]);

  return newArray;
};

export const updateOrCreateIn = (array, indexOrFinder, updaterOrCreator) => {
  if (!Array.isArray(array)) {
    return array;
  }

  const index =
    typeof indexOrFinder === 'function'
      ? array.findIndex(indexOrFinder)
      : indexOrFinder;

  const newArray = [...array];

  if (index === -1) {
    const newItem = updaterOrCreator(null, array);
    newArray.push(newItem);
    return newArray;
  }

  newArray[index] = updaterOrCreator(array[index], array);

  return newArray;
};

export const swap = (array, a, b) => {
  if (!array || !array.length) {
    return array;
  }

  const newArray = [...array];

  [newArray[a], newArray[b]] = [newArray[b], newArray[a]];

  return newArray;
};

export const move = (array, from, to) => {
  const newArray = [...array];

  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return newArray;
};

export const toArray = (value, acceptFalse = false) => {
  if (!acceptFalse && !value) {
    return [];
  }
  if (acceptFalse && value !== false && !value) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
};

export const removeIn = (array, indexOrFinder) => {
  if (!Array.isArray(array)) {
    return array;
  }

  const finder =
    typeof indexOrFinder === 'function'
      ? (item) => !indexOrFinder(item)
      : (item, index) => index !== indexOrFinder;

  return array.filter(finder);
};

export const toKeysMap = (array, key, transform = (item) => item) => {
  const map = {};
  if (!array) {
    return map;
  }
  let keyFn = key;

  if (!keyFn) {
    return (item) => item;
  }

  if (typeof keyFn !== 'function') {
    keyFn = (item) => item[key];
  }

  array.forEach((item) => {
    if (item != null) {
      map[keyFn(item)] = transform(item);
    }
  });
  return map;
};

export const isEmptyArray = (v) => Array.isArray(v) && v.length === 0;
