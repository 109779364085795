export default function ArrowCircleRight({ fill = 'none' }: { fill?: string }) {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M17.5003 32.0834C25.5545 32.0834 32.0837 25.5542 32.0837 17.5001C32.0837 9.44593 25.5545 2.91675 17.5003 2.91675C9.44617 2.91675 2.91699 9.44593 2.91699 17.5001C2.91699 25.5542 9.44617 32.0834 17.5003 32.0834Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5003 23.3334L23.3337 17.5001M23.3337 17.5001L17.5003 11.6667M23.3337 17.5001H11.667"
        stroke="#8F8F8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
