import React from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent, GridApi } from 'ag-grid-community';

export default function AgGrid({
  data,
  columnDefs,
  className = null,
  striped = false,
  title = null,
  theme = 'alpine',
}: {
  data: { [index: string]: unknown }[];
  columnDefs: ColDef[];
  className?: string | null;
  striped?: boolean;
  title?: string | null;
  theme?: 'alpine' | 'destination';
}) {
  const gridApiRef = React.useRef<GridApi | null>(null);
  const onGridReady = React.useCallback(({ api }: GridReadyEvent) => {
    if (!api) {
      return;
    }
    api.sizeColumnsToFit();
    gridApiRef.current = api;
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      gridApiRef.current?.sizeColumnsToFit();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!columnDefs?.length || !data?.length) {
    return null;
  }

  return (
    <div
      className={`ag-theme-${theme} ${className ?? ''} ${
        striped === true ? 'striped' : ''
      }`}
      style={{ width: '100%' }}
    >
      {title ? <h2>{title}</h2> : null}
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        loadingOverlayComponent="customLoadingOverlay"
        domLayout="autoHeight"
        enableCharts
        enableRangeSelection
        onGridReady={onGridReady}
        rowHeight={48}
        headerHeight={40}
        defaultColDef={{
          cellStyle: () => ({
            display: 'flex',
            alignItems: 'center',
          }),
          sortable: true,
          enableCellChangeFlash: true,
          resizable: true,
          suppressMenu: true,
        }}
      />
    </div>
  );
}
