import React, { useState, useEffect } from 'react';
import UIIcon from 'components/UI/Icon/Icon';
import UIButton from 'components/UI/Button/Button';
import history from 'store/history/history';
import useUser from 'store/authentication/useUser';
import UIIconButton from 'components/UI/IconButton/IconButton';
import useErrorHandler from 'store/errorHandler/useErrorHandler';
import UILink from 'components/UI/Link/Link';

import './ErrorDialog.scss';

const cbn = 'error-dialog';

function clean(str = '') {
  return str.replace(/\\|\\n|\\"/g, '');
}

function ErrorDialog() {
  const { isAuthenticated } = useUser();
  const [expanded, setExpanded] = useState({});
  const { errors, reset } = useErrorHandler();

  function toggleExpanded(key) {
    setExpanded((exp) => ({
      ...exp,
      [key]: !exp[key],
    }));
  }

  const close = React.useCallback(() => {
    setExpanded({});
    reset();
  }, [reset]);

  useEffect(() => history.listen(() => close()), [close]);

  const filteredErrors = React.useMemo(
    () =>
      (errors ?? []).reduce((acc, error) => {
        const existingErrorIndex = acc.findIndex(
          (existing) =>
            existing.title === error.title &&
            existing?.description === error?.description,
        );

        if (existingErrorIndex === -1) {
          acc.push(error);
        }
        return acc;
      }, []),
    [errors],
  );

  if (!filteredErrors.length || !isAuthenticated) {
    return null;
  }

  const isCustom = !!filteredErrors[0].custom;

  return (
    <div className={`${cbn}__overlay`}>
      <div className={cbn}>
        <header className={`${cbn}__header`}>
          <span className={`${cbn}__header__title`}>
            {isCustom && filteredErrors[0].headline ? (
              filteredErrors[0].headline
            ) : (
              <>
                Error
                <UIIcon type="warning" />
              </>
            )}
          </span>
          <UIIconButton
            onClick={close}
            className={`${cbn}__close`}
            icon="close"
          />
        </header>

        <div className={`${cbn}__body`}>
          {filteredErrors.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <section key={`${error}-${index}`} className={`${cbn}__error`}>
              <header className={`${cbn}__error__header`}>
                <span className={`${cbn}__error__header__title`}>
                  {error.title ?? ''}
                </span>
                <div className={`${cbn}__error__description`}>
                  {error.description}{' '}
                  {error.link && (
                    <UILink target="_blank" to={error.link?.url}>
                      {error.link?.text}
                    </UILink>
                  )}
                </div>
                {!isCustom && (
                  <UIIconButton
                    className={`${cbn}__error__header__toggle`}
                    onClick={() => toggleExpanded(index)}
                    icon={expanded[index] ? 'expand_more' : 'navigate_before'}
                  />
                )}
              </header>
              {expanded[index] && !isCustom && (
                <div className={`${cbn}__error__body`}>
                  <div className={`${cbn}__error__raw`}>
                    {clean(
                      JSON.stringify(
                        error.raw ||
                          `${error.title || ''} ${error.description || ''}`,
                        null,
                        2,
                      ),
                    )}
                  </div>
                </div>
              )}
            </section>
          ))}
        </div>
        {!isCustom && (
          <div className={`${cbn}__footer`}>
            If you continue to get this error, please{' '}
            <a href="mailto:servicedesk@bengroup.com">contact us</a>. Your
            feedback is helpful and appreciated.
          </div>
        )}
        {isCustom && (
          <div className={`${cbn}__actions`}>
            <UIButton theme="contained-red" onClick={close}>
              Close
            </UIButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default ErrorDialog;
