// @ts-nocheck
import React from 'react';
import UITag from 'components/UI/Tag/Tag';
import { MultiValueProps, OptionTypeBase } from 'react-select';

import './TagValue.scss';

const cbn = 'form-selector-tag-value';

type FormSelectorTagValueProps = MultiValueProps<OptionTypeBase> & {
  data: object;
};

function FormSelectorTagValue({
  data,
  isDisabled,
  removeProps,
  selectProps,
}: FormSelectorTagValueProps) {
  return (
    <UITag
      onRemove={removeProps?.onClick}
      className={cbn}
      disabled={isDisabled}
    >
      {selectProps?.getOptionLabel(data)}
    </UITag>
  );
}

FormSelectorTagValue.defaultProps = {
  data: null,
  isDisabled: false,
};

export default FormSelectorTagValue;
