import gql from 'graphql-tag';

export default gql`
  query CampaignExternalReport($id: Int!, $platform: String) {
    campaign(id: $id) {
      id
      clientReportEmbed
      externalReport(platform: $platform) {
        embedToken
        embedUrl
      }
    }
  }
`;
