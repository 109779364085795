import storage from 'utils/storage';
import { ColumnState, ColumnApi } from 'ag-grid-community';
import { getCampaignStorageKey } from 'components/Campaign/utils';

export function getNewColumnState(columnApi: ColumnApi) {
  const colsState = columnApi.getColumnState();
  return (colsState ?? []).reduce((acc, col, index) => {
    if (!col?.colId) {
      return acc;
    }
    return {
      ...acc,
      [col.colId]: {
        ...col,
        colIndex: index,
      },
    };
  }, {});
}

export function saveColumnState(
  columnApi: ColumnApi,
  columnStateKey: string,
  infinityGrid: boolean,
  campaignSubType: string,
) {
  const newStorage = getNewColumnState(columnApi);
  storage.set(
    getCampaignStorageKey(columnStateKey, infinityGrid, campaignSubType),
    newStorage,
  );

  return newStorage;
}

export function loadSavedState(
  columnApi: ColumnApi,
  columnStateKey: string,
  infinityGrid: boolean,
  campaignSubType: string,
) {
  const savedColumnState: {
    [index: string]: ColumnState & { colIndex?: number };
  } = storage.get(
    getCampaignStorageKey(columnStateKey, infinityGrid, campaignSubType),
  );

  if (!savedColumnState) {
    saveColumnState(columnApi, columnStateKey, infinityGrid, campaignSubType);
    return;
  }

  const gridColumnKeys = (columnApi.getColumnState() ?? [])
    ?.map((c) => c?.colId)
    .filter(Boolean);
  const savedColumnKeys = Object.keys(savedColumnState ?? {});
  const deletedColumnKeys = [savedColumnKeys, gridColumnKeys].reduce((a, b) =>
    a.filter((c) => !b.includes(c)),
  );
  const addedColumnKeys = [gridColumnKeys, savedColumnKeys].reduce((a, b) =>
    a.filter((c) => !b.includes(c)),
  );

  if (addedColumnKeys?.length || deletedColumnKeys?.length) {
    saveColumnState(columnApi, columnStateKey, infinityGrid, campaignSubType);
    return;
  }
  columnApi.applyColumnState({
    state: Object.values(savedColumnState),
    applyOrder: true,
  });
}
