import { useContext } from 'react';
import ApplicationContext from 'components/Application/Context';

export default function usePermissions(packageName, id, entityPermissions) {
  const { permissions } = useContext(ApplicationContext);

  if (id && entityPermissions) {
    return entityPermissions;
  }

  return permissions && permissions[packageName]
    ? permissions[packageName]
    : {};
}
