import { formatValue } from '@UIComponents/utils/formatters';
import getRelativeTimeToDate from 'shared/dates/getRelativeTimeToDate';
import { ProgressionRow } from '@UIComponents/ProgressionTable/ProgressionTable';
import './MediaStats.scss';

export default function MediaStats({
  metrics,
  createdDate,
  platform,
}: {
  metrics: ProgressionRow[];
  createdDate?: string;
  platform: string;
}) {
  return (
    <div className={`${platform}-recent-media__container__media__stats`}>
      {metrics.map((metric) => (
        <p
          key={metric?.label.toLowerCase()}
          className={`metric metric-${(metric?.label ?? '').toLowerCase()}`}
        >
          {`${formatValue(metric?.value, metric?.type)} ${metric?.label}`}
        </p>
      ))}

      {createdDate ? (
        <p className="age">
          {getRelativeTimeToDate(
            createdDate,
            new Date().toString(),
            'narrow',
          ).replace('ago', '')}
        </p>
      ) : null}
    </div>
  );
}
