import { Property } from 'components/Property/types';
import LabelValueCombo from '@UIComponents/LabelValueCombo/LabelValueCombo';
import ClipboardCopyLabel from '@UIComponents/ClipboardCopyLabel/ClipboardCopyLabel';
import EmailLastUpdated from 'components/Creator/EmailLastUpdated/EmailLastUpdated';
import { Campaign } from 'components/Campaign/types';
import React from 'react';
import ApplicationContext from 'components/Application/Context';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import EditProperty from './EditProperty/EditProperty';
import './Details.scss';

export default function Details({
  property,
  refetchProperty,
  campaign,
}: {
  property?: Property;
  refetchProperty: () => void;
  campaign: Campaign;
}) {
  const { segment, user } = React.useContext(ApplicationContext);

  const onClick = React.useCallback(() => {
    segment.track(
      SegmentEvents.CreatorProfile,
      {
        action: SegmentActions.CopyToClipboard,
        entity: 'Primary Contact Email',
        property: {
          id: property?.id,
          name: property?.name,
        },
      },
      {
        context: { groupId: formatCampaignId(campaign?.id) },
        userId: formatUserId(user?.id),
      },
    );
  }, [campaign?.id, property?.id, property?.name, segment, user?.id]);

  return (
    <section className="details-container ">
      <div className="details-container__values">
        <LabelValueCombo
          label="Primary contact"
          value={
            property?.primaryContact?.email ??
            property?.primaryContact?.name ??
            '--'
          }
        >
          {property?.primaryContact?.email ? (
            <ClipboardCopyLabel
              copyValue={property?.primaryContact?.email}
              label={property?.primaryContact?.email}
              onClick={onClick}
            />
          ) : (
            property?.primaryContact?.name
          )}
        </LabelValueCombo>
      </div>
      <div className="edit-info">
        <EditProperty
          property={property}
          refetchProperty={refetchProperty}
          campaign={campaign}
        />
        <EmailLastUpdated personId={property?.primaryContact?.id} />
      </div>
    </section>
  );
}
