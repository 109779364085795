import get from 'lodash/get';
import isSimilarityRecommendation from 'external/utils/isSimilarityRecommendation';

// Sort function
export default function sortingFunction(config, sort) {
  const [key, order] = sort?.split('-') ?? [];
  const path = config[key];

  return function innerSort(a, b) {
    const vA = get(a, path) ?? null;
    const vB = get(b, path) ?? null;

    if ((!vA && !vB) || order == null) {
      return 0;
    }

    const varA = typeof vA === 'string' ? vA.toUpperCase() : vA;
    const varB = typeof vB === 'string' ? vB.toUpperCase() : vB;

    if (varA === varB) return 0;
    if (order === 'asc') return varA < varB ? -1 : 1;
    return varA < varB ? 1 : -1;
  };
}

export function toggleOrder(value, sorting) {
  const [key, order] = sorting?.split('-') ?? [];

  if (value !== key || sorting === null) return `${value}-asc`;

  if (order === 'asc') return `${value}-desc`;

  return null;
}

export function sortingBooleanFunction(config, sort) {
  const [key, order] = sort?.split('-') ?? [];
  const path = config[key];

  return function innerSort(a, b) {
    const vA = get(a, path) ?? null;
    const vB = get(b, path) ?? null;

    if ((!vA && !vB) || order == null) {
      return 0;
    }

    const varA = isSimilarityRecommendation(vA);
    const varB = isSimilarityRecommendation(vB);

    if (varA === varB) return 0;
    if (order === 'asc') return Number(varB) - Number(varA);
    return Number(varA) - Number(varB);
  };
}
