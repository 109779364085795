import getUTCDateAtBeginningOfDay from '../../dates/getUTCDateAtBeginningOfDay';
import getUTCDateAtEndOfMonth from '../../dates/getUTCDateAtEndOfMonth';

const LIVE_REPORTING_WINDOW = 'liveReportingWindow';
export const LIVE_REPORTING_WINDOWS_LABEL = 'Live Reporting Windows';

export const TIME_REPORTING_WINDOW = 'timeReportingWindow';
export const TIME_REPORTING_WINDOWS_LABEL = 'Time Reporting Windows';

export const reportingWindowsMetaData = {
  live1: {
    label: 'Live 1',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 0,
  },
  live2: {
    label: 'Live 2',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 1,
  },
  live3: {
    label: 'Live 3',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 2,
  },
  live4: {
    label: 'Live 4',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 3,
  },
  live5: {
    label: 'Live 5',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 4,
  },
  live6: {
    label: 'Live 6',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 5,
  },
  live7: {
    label: 'Live 7',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 6,
  },
  live14: {
    label: 'Live 14',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 13,
  },
  live30: {
    label: 'Live 30',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 29,
  },
  live60: {
    label: 'Live 60',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 59,
  },
  live90: {
    label: 'Live 90',
    reportingWindowType: LIVE_REPORTING_WINDOW,
    offset: 89,
  },
  today: {
    label: 'Today',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 'today',
  },
  day1: {
    label: 'Day 1',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 0,
  },
  day2: {
    label: 'Day 2',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 1,
  },
  day3: {
    label: 'Day 3',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 2,
  },
  day4: {
    label: 'Day 4',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 3,
  },
  day5: {
    label: 'Day 5',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 4,
  },
  day6: {
    label: 'Day 6',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 5,
  },
  week1: {
    label: 'Week 1',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 6,
  },
  week2: {
    label: 'Week 2',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 13,
  },
  week3: {
    label: 'Week 3',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 20,
  },
  month1: {
    label: 'Month 1',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 29,
  },
  month2: {
    label: 'Month 2',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 59,
  },
  month3: {
    label: 'Month 3',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 89,
  },
  monthEnd: {
    label: 'Month End',
    reportingWindowType: TIME_REPORTING_WINDOW,
    offset: 'monthEnd',
  },
};
/*
 * Live Reporting windows
 * These will track from the day that specific piece of content is posted. “Today” is used to enter in metrics that are recent as of the day entered.
 */
export const liveReportingWindows = Object.entries(reportingWindowsMetaData)
  .filter((entry) => entry[1].reportingWindowType === LIVE_REPORTING_WINDOW)
  .reduce(
    (acc, entry) => ({
      ...acc,
      [entry[0]]: entry[1].label,
    }),
    {},
  );

/*
 * Time Reporting windows
 * These will track from the day the campaign specifies as the “Start Date” in the brief.
 */
export const timeReportingWindows = Object.entries(reportingWindowsMetaData)
  .filter((entry) => entry[1].reportingWindowType === TIME_REPORTING_WINDOW)
  .reduce(
    (acc, entry) => ({
      ...acc,
      [entry[0]]: entry[1].label,
    }),
    {},
  );

export const reportingWindows = {
  ...liveReportingWindows,
  ...timeReportingWindows,
};

export const reportingWindowsGrouped = [
  {
    label: LIVE_REPORTING_WINDOWS_LABEL,
    options: Object.entries(liveReportingWindows).map((entry) => ({
      value: { key: entry[0] },
      label: entry[1] as string,
    })),
  },
  {
    label: TIME_REPORTING_WINDOWS_LABEL,
    options: Object.entries(timeReportingWindows).map((entry) => ({
      value: { key: entry[0] },
      label: entry[1] as string,
    })),
  },
];

function offsetDateByDay(
  date: Date | string | null,
  offsetInDays: number | string | null,
) {
  if (date === null || offsetInDays === null) {
    return null;
  }

  if (typeof offsetInDays === 'string' && offsetInDays === 'monthEnd') {
    return getUTCDateAtBeginningOfDay(getUTCDateAtEndOfMonth(new Date()));
  }

  if (typeof offsetInDays === 'string' && offsetInDays === 'today') {
    return getUTCDateAtBeginningOfDay(new Date());
  }
  if (typeof offsetInDays === 'number') {
    const offsetedDate = new Date(date);
    return getUTCDateAtBeginningOfDay(
      new Date(offsetedDate.setDate(offsetedDate.getDate() + offsetInDays)),
    );
  }
  return null;
}

export function getOffsetByReportingKey(
  reportingKey?: string | null,
  campaignStartDate?: string,
  postPublishedDate?: string,
) {
  if (!reportingKey) {
    return null;
  }
  const reportingMetaData =
    reportingWindowsMetaData?.[
      reportingKey as keyof typeof reportingWindowsMetaData
    ];
  if (!reportingMetaData) {
    return null;
  }

  let dateToCompareTo;
  if (reportingMetaData?.reportingWindowType === LIVE_REPORTING_WINDOW) {
    dateToCompareTo = postPublishedDate;
  }
  if (reportingMetaData?.reportingWindowType === TIME_REPORTING_WINDOW) {
    dateToCompareTo = campaignStartDate;
  }
  if (!dateToCompareTo) {
    return null;
  }
  return offsetDateByDay(dateToCompareTo, reportingMetaData?.offset);
}

export function reportingWindowsGroupedWidthDateDisplay(
  campaignStartDate?: string,
  postPublishedDate?: string,
  campaignReportingWindows?: string[] | null,
) {
  const campaignAtBeginningOfTheDay = campaignStartDate
    ? getUTCDateAtBeginningOfDay(campaignStartDate ?? null)
    : null;
  const postAtBeginningOfTheDay = postPublishedDate
    ? getUTCDateAtBeginningOfDay(postPublishedDate ?? null)
    : null;

  let filteredLiveReportingWindows = Object.entries(liveReportingWindows);
  let filteredTimeReportingWindows = Object.entries(timeReportingWindows);
  if (campaignReportingWindows?.length) {
    filteredLiveReportingWindows = filteredLiveReportingWindows.filter(
      ([key]) => campaignReportingWindows.includes(key),
    );
    filteredTimeReportingWindows = filteredTimeReportingWindows.filter(
      ([key]) => campaignReportingWindows.includes(key),
    );
  }
  return [
    postAtBeginningOfTheDay
      ? {
          label: LIVE_REPORTING_WINDOWS_LABEL,
          options: filteredLiveReportingWindows.map((entry) => ({
            value: {
              key: entry[0],
              date: offsetDateByDay(
                postAtBeginningOfTheDay,
                reportingWindowsMetaData[
                  entry[0] as keyof typeof reportingWindowsMetaData
                ].offset,
              ),
            },
            label: entry[1] as string,
          })),
        }
      : { label: LIVE_REPORTING_WINDOWS_LABEL, options: [] },
    campaignAtBeginningOfTheDay
      ? {
          label: TIME_REPORTING_WINDOWS_LABEL,
          options: filteredTimeReportingWindows.map((entry) => ({
            value: {
              key: entry[0],
              date: offsetDateByDay(
                campaignAtBeginningOfTheDay,
                reportingWindowsMetaData[
                  entry[0] as keyof typeof reportingWindowsMetaData
                ].offset,
              ),
            },
            label: entry[1] as string,
          })),
        }
      : { label: TIME_REPORTING_WINDOW, options: [] },
  ].filter(Boolean);
}
