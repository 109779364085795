import { ProcessCellForExportParams } from 'ag-grid-community';

function processCellFromClipboard(params: ProcessCellForExportParams) {
  const valueAsNumber = Number(params.value);

  if (!Number.isNaN(valueAsNumber)) {
    return valueAsNumber;
  }

  return params.value;
}

export default processCellFromClipboard;
