import { FeatureFlags } from 'components/User/types';
import { SideBarDef, ToolPanelDef } from 'ag-grid-community';
import RowsCountStatusBar from '../components/RowsCountStatusBar';

const getStatusBar = (rowModelType?: string) => ({
  statusPanels: [
    {
      statusPanel:
        rowModelType === 'serverSide'
          ? RowsCountStatusBar.componentName
          : 'agTotalRowCountComponent',
      align: 'left',
    },
    {
      statusPanel: 'agSelectedRowCountComponent',
      align: 'left',
    },
    {
      statusPanel: 'agAggregationComponent',
      align: 'right',
      statusPanelParams: {
        aggFuncs: ['count', 'sum', 'min', 'max', 'avg'],
      },
    },
  ].filter(Boolean),
});

type GridOptions = {
  enableCellChangeFlash: boolean;
  enableCharts: boolean;
  enableFillHandle: boolean;
  enableRangeSelection: boolean;
  featureFlags: FeatureFlags;
  sideBar?: SideBarDef | string | boolean | string[];
  toolPanels?: (ToolPanelDef | string)[] | null;
  undoRedoCellEditing: boolean;
  undoRedoCellEditingLimit: number;
  rowModelType?: string;
};

const getGridOptions = ({
  enableCellChangeFlash,
  enableCharts,
  enableFillHandle,
  enableRangeSelection,
  toolPanels,
  undoRedoCellEditing,
  undoRedoCellEditingLimit,
  rowModelType,
  sideBar,
}: GridOptions) => ({
  enableRangeSelection,
  enableFillHandle,
  sideBar: sideBar ?? { toolPanels: toolPanels ?? ['columns', 'filters'] },
  toolPanels,
  enableCharts,
  undoRedoCellEditing,
  undoRedoCellEditingLimit,
  enableCellChangeFlash,
  statusBar: getStatusBar(rowModelType),
});

export default getGridOptions;
