import { Placement } from 'shared/types/Placement';
import { ValueGetterParams } from 'ag-grid-community';
import currency from 'components/DataGrid/formatters/currency';
import currencyColDef from 'components/DataGrid/Destination/colDef/currencyColDef';

export function getTotalFee(data: Placement) {
  /*
   * Offer Fee
   */
  const targetCogs = data?.integration?.deliverable?.targetCogs;

  /**
   * Other Fee
   */
  const otherCogs = data?.integration?.deliverable?.otherCogs;

  let fee = 0;
  if (targetCogs && Number.isFinite(Number(targetCogs))) {
    fee += Number(targetCogs);
  }
  if (otherCogs && Number.isFinite(Number(otherCogs))) {
    fee += Number(otherCogs);
  }

  if (fee > 0) {
    return fee;
  }
  return null;
}

export default function totalFee() {
  return {
    ...currencyColDef,
    valueFormatter: ({ value }: { value: number }) => currency({ value }, 2),
    valueGetter: ({ data }: ValueGetterParams<Placement>) => {
      if (!data) {
        return null;
      }
      return getTotalFee(data);
    },
    editable: false,
    headerName: 'Total Fee',
    field: 'totalFee',
  };
}
