import React from 'react';
import { arrayOf, oneOfType, string, element } from 'prop-types';
import classNames from 'classnames';
import Col from './Col';
import Body from './Body';
import Header from './Header';
import HCol from './HCol';
import Row from './Row';
import Filters from './Filters';

import './ModernTable.scss';

/*
  This is the UITable component, to be used the table lists:

  <UITable>
    <UITableFilters count={total}>
    </UITableFilters>
    <UITableHeader onChangeSort={fn} sort="name-asc">
      <UITableHCol {...otherPropsToTheComponent} />
      <UITableHCol
        id="id-for-column"
        sortable
        component={COMPONENT_TO_RENDER_THE_COLUMN_HEADER}
        {...otherPropsToTheComponent}
      />
    </UITableHeader>
    <UITableBody
      isLoading={false}
      data={results}
      render={(result, props) => (
        <UITableRow key={result.id}>
          <UITableCol
            id="id-for-column"
            component={COMPONENT_TO_RENDER_THE_COLUMN}
            {...otherPropsToYourComponent}
            value={result.value}
          />
        </UITableRow>
      )}
    />
  </UITable>
*/

const propTypes = {
  children: oneOfType([element, arrayOf(element)]),
  className: string,
};

const defaultProps = {
  className: null,
  children: null,
};

function UITable({ children, className }) {
  return <div className={classNames(className, 'ui-table')}>{children}</div>;
}

UITable.propTypes = propTypes;
UITable.defaultProps = defaultProps;

UITable.Col = Col;
UITable.Body = Body;
UITable.Header = Header;
UITable.HCol = HCol;
UITable.Row = Row;
UITable.Filters = Filters;

export default UITable;
