const runPromises = async (promiseCreators, executionMode) => {
  const mode = executionMode ?? 'parallel';

  if (mode === 'parallel') {
    return Promise.all(promiseCreators.map((createPromise) => createPromise()));
  }

  const result = [];

  await promiseCreators.reduce(async (previousPromise, createPromise) => {
    await previousPromise;
    const entity = await createPromise();
    result.push(entity);
    return entity;
  }, Promise.resolve());

  return result;
};

export const stripTypenames = (value) => {
  const omitTypename = (key, v) => (key === '__typename' ? undefined : v);

  try {
    return JSON.parse(JSON.stringify(value), omitTypename);
  } catch (e) {
    return value;
  }
};

export const extractResult = (response) => {
  if (!response) {
    return {};
  }

  if (Array.isArray(response)) {
    return response.map((item) => extractResult(item));
  }
  if (response.data) {
    return Object.values(response.data)[0];
  }
  return response;
};

// eslint-disable-next-line max-params, complexity, max-lines-per-function, max-statements
export const mutate = async (apolloClient, data, config, ascendants = []) => {
  let operation = null;

  const { $updated, $deleted, ...payload } = data;

  const nestedFields = [];
  const delayedNestedFields = [];

  Object.keys(config).forEach((field) => {
    if (
      !['$create', '$update', '$delete'].includes(field) &&
      data[field] !== undefined
    ) {
      if (config[field].awaitOtherFields) {
        delayedNestedFields.push(field);
      } else {
        nestedFields.push(field);
      }
      delete payload[field];
    }
  });

  if (config.$create || config.$update || config.$delete) {
    if (!config.allowLinking) {
      if ($deleted && payload.id && config.$delete) {
        operation = {
          mutation: config.$delete.mutation,
          variables: config.$delete.variables(payload, ...ascendants),
        };
      }

      if ($deleted && (!payload.id || !config.$delete)) {
        return null;
      }

      if ($updated && payload.id && config.$update) {
        operation = {
          mutation: config.$update.mutation,
          variables: config.$update.variables(payload, ...ascendants),
        };
      }

      if (!payload.id && config.$create) {
        delete payload.id; // id can still be null, we need to remove it from payload;

        operation = {
          mutation: config.$create.mutation,
          variables: config.$create.variables(payload, ...ascendants),
        };
      }
    } else {
      if (!$deleted && payload.id && config.$update) {
        operation = {
          mutation: config.$update.mutation,
          variables: config.$update.variables(payload, ...ascendants),
        };
      }

      if ($deleted && payload.id && config.$delete) {
        operation = {
          mutation: config.$delete.mutation,
          variables: config.$delete.variables(payload, ...ascendants),
        };
      }
    }
  }

  const response = operation ? await apolloClient.mutate(operation) : null;
  if (response?.errors) {
    throw new Error('mutation failed');
  }

  const result = {
    ...payload,
    ...extractResult(response),
  };

  delete result.$updated;
  delete result.$deleted;

  if (
    $deleted &&
    config.$delete &&
    payload.id &&
    config.ignoreChildrenOnDelete
  ) {
    return result;
  }

  const nestedFieldsResult = await Promise.all(
    nestedFields.map((field) => {
      const node = data[field];
      return Array.isArray(node)
        ? runPromises(
            node.map((item) => () =>
              mutate(apolloClient, item, config[field], [
                result,
                ...ascendants,
              ]),
            ),
            config[field].executionMode,
          )
        : mutate(apolloClient, node, config[field], [result, ...ascendants]);
    }),
  );

  nestedFieldsResult.forEach((v, index) => {
    const key = nestedFields[index];
    result[key] = extractResult(nestedFieldsResult[index]);
  });

  // delayed fields will wait for results of regular fields
  // this way, they can reuse fresh values that have been created or updated.
  if (delayedNestedFields.length) {
    const delayedNestedFieldsResult = await Promise.all(
      delayedNestedFields.map((field) => {
        const node = data[field];
        return Array.isArray(node)
          ? runPromises(
              node.map((item) => () =>
                mutate(apolloClient, item, config[field], [
                  result,
                  ...ascendants,
                ]),
              ),
              config[field].executionMode,
            )
          : mutate(apolloClient, node, config[field], [result, ...ascendants]);
      }),
    );
    delayedNestedFieldsResult.forEach((v, index) => {
      const key = delayedNestedFields[index];
      result[key] = extractResult(delayedNestedFieldsResult[index]);
    });
  }

  return result;
};
