import React from 'react';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import UIPageTitle from 'components/UI/PageTitle/PageTitle';
import UserChangePassword from 'components/User/ChangePassword/ChangePassword';

import './ChangePassword.scss';

function ScreensUserChangePassword() {
  return (
    <>
      <DocumentTitle>Change Password</DocumentTitle>
      <UIPageTitle>Change Password</UIPageTitle>
      <div className="change-password-ee__wrapper">
        <UserChangePassword />
      </div>
    </>
  );
}

export default ScreensUserChangePassword;
