import React, { useState, useReducer } from 'react';
import { bool, shape, arrayOf, node, oneOfType, string } from 'prop-types';
import classnames from 'classnames';
import UIIcon from 'components/UI/Icon/Icon';
import useClickOutside from 'components/UI/useClickOutside';
import { connect } from 'formik';
import Tippy from '@tippyjs/react';
import UIDisablePageScroll from 'components/UI/DisablePageScroll/DisablePageScroll';
import Context from './Context';
import registrationReducer from './registrationReducer';
import { getActiveSectionsAndFields, getFieldSelectedCount } from './utils';
import './AdvancedFilters.scss';

const cbn = 'advanced-filters';

function AdvancedFilters({
  children,
  className,
  formik,
  icon,
  isDisabled,
  label,
  showSelectedCount,
}) {
  const [fields, dispatch] = useReducer(registrationReducer, {});
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useClickOutside(() => {
    setIsOpen(false);
  }, isDisabled || !isOpen);

  const [activeSections, activeFields] = getActiveSectionsAndFields(
    fields,
    formik.values,
  );
  const activeFiltersCount = showSelectedCount
    ? getFieldSelectedCount(fields, formik.values)
    : Object.keys(activeFields).length;

  const values = React.useMemo(() => {
    function registerField(field, section, getValue) {
      dispatch({ type: 'register', field, section, getValue });
    }

    function unregisterField(field, section) {
      dispatch({ type: 'unregister', field, section });
    }

    return {
      fields,
      registerField,
      unregisterField,
      activeSections,
      activeFields,
      values: formik.values,
      hasProvider: true,
    };
  }, [fields, activeSections, activeFields, formik?.values]);

  return (
    <div
      ref={containerRef}
      className={classnames(cbn, className, {
        [`${cbn}--is-open`]: isOpen,
      })}
    >
      <div
        role="presentation"
        className={`${cbn}__overlay`}
        onKeyPress={() => setIsOpen(false)}
        onClick={() => setIsOpen(false)}
      />
      {isOpen && <UIDisablePageScroll />}
      <Tippy
        content={label}
        placement="top"
        animation="shift-toward"
        arrow
        delay={300}
      >
        <button
          type="button"
          className={classnames(`${cbn}__toggle-button`, {
            [`${cbn}__toggle-button--is-active`]: activeFiltersCount > 0,
          })}
          disabled={isDisabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          <UIIcon type={icon} />
          {activeFiltersCount > 0 && (
            <span className={`${cbn}__active-filters-count`}>
              {activeFiltersCount}
            </span>
          )}
          <UIIcon
            className={`${cbn}__toggle-button__arrow-icon`}
            type={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
          />
        </button>
      </Tippy>
      <Context.Provider value={values}>
        <div className={`${cbn}__panel`}>
          <div className={`${cbn}__panel__inner`}>{children}</div>
        </div>
      </Context.Provider>
    </div>
  );
}

AdvancedFilters.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  className: string,
  formik: shape({
    values: shape({}),
  }).isRequired,
  icon: string,
  isDisabled: bool,
  label: string,
};

AdvancedFilters.defaultProps = {
  className: null,
  icon: 'filter',
  isDisabled: false,
  label: 'Advanced Filters',
};

export default connect(AdvancedFilters);
