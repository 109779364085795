import approvalFilter from './approvalFilter';
import currency from './currency';
import date from './date';
import labelMap from './labelMap';
import number from './number';
import percent from './percent';
import wholeNumber from './wholeNumber';
import boolean from './boolean';

const formatters = {
  auto: ({ value }) => (typeof value === 'number' ? number({ value }) : value),
  approvalFilter,
  currency,
  date,
  labelMap,
  number,
  percent,
  wholeNumber,
  boolean,
};

export default formatters;
