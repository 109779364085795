import BaseDomoIframe from './BaseDomoIframe';
import { FrameProps } from './types';

function DomoIframeInstagram({ campaignId = null }: FrameProps) {
  return (
    <BaseDomoIframe
      campaignId={campaignId}
      IframeName="Instagram"
      platform="instagram"
    />
  );
}

export default DomoIframeInstagram;
