import { useState } from 'react';

export default function useMapState<T = any>(
  initialValue = {},
): [{ [x: string]: T }, (key: string, value: any) => void] {
  const [state, setState] = useState<{ [x: string]: T }>(initialValue);

  function updateState(key: string, value: T) {
    setState((currentState) => ({
      ...currentState,
      [key]: value,
    }));
  }

  return [state, updateState];
}
