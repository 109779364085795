import { Production } from 'components/Production/types';
import getMetricPrediction from 'components/Production/utils/getMetricPrediction';
import getMetricResult from 'components/Production/utils/getMetricResult';
import MetricType from 'shared/types/MetricType';

const metrics = [
  {
    name: MetricType.impressions,
    label: 'Impressions',
    format: '0,0',
  },
  { name: 'status', label: 'Status' },
  {
    name: MetricType.averageImpressions,
    label: 'Average Impressions',
    format: '0,0',
  },
  { name: 'likes', label: 'Likes', format: '0,0' },
  {
    name: MetricType.averageViews,
    label: 'Average Views',
    format: '0,0',
  },
  {
    name: MetricType.botPercentage,
    label: 'BOT %',
    format: '0[.]00%',
  },
  {
    name: 'ctr',
    label: 'CTR',
    format: '0[.]00%',
  },
  {
    name: MetricType.averageEngagement,
    label: 'Engagement',
    format: '0,0[.]00',
  },
  {
    name: MetricType.engagementRate,
    label: 'ER',
    format: '0[.]00%',
  },
  { name: 'followers', label: 'Followers', format: '0,0' },
  { name: 'likes', label: 'Likes', format: '0,0' },
  {
    name: MetricType.subscribers,
    label: 'Subscribers',
    format: '0,0',
  },
  { name: 'views', label: 'Views', format: '0,0' },
  {
    name: MetricType.storyViews,
    label: 'Story Views',
    format: '0,0',
  },
  { name: MetricType.vpc, label: 'VPC', format: '0[.]00' },
];

type MetricDef = typeof metrics[number];

type MetricsResult = {
  predictions: {
    [K in MetricDef['name']]: MetricDef & {};
  };
  results: {
    [K in MetricDef['name']]: MetricDef & {};
  };
};

export const getMetrics = (production: Production) => {
  const result: MetricsResult = { predictions: {}, results: {} };

  // iterate each metric and grab predicted/result value
  // for predictins prepend a label
  metrics.forEach((metric) => {
    const predictionValue = getMetricPrediction(production, metric.name, true);
    if (predictionValue !== null) {
      result.predictions[metric.name] = {
        ...metric,
        label: `Predicted ${metric.label}`,
        ...predictionValue,
      };
    }
    const resultValue = getMetricResult(production, metric.name, true);
    if (resultValue !== null) {
      let { label } = metric;
      if (
        production.type === 'youtube' &&
        metric.name === MetricType.averageViews
      ) {
        label = 'BEN Projected Views *';
      }
      result.results[metric.name] = {
        ...metric,
        label,
        ...resultValue,
      };
    }
  });

  return result;
};
