import React from 'react';
import { string, func, bool, shape } from 'prop-types';
import Image from 'components/UI/Image/Image';
import Video from 'components/UI/Video/Video';
import customPropTypes from 'components/utils/customPropTypes';
import { fileIsVideo } from 'components/utils/file';
import Link from './Link/Link';

function getComponent(file, disablePreview) {
  if (disablePreview) {
    return Link;
  }

  if (fileIsVideo(file)) {
    return Video;
  }

  if (file && file.mime && /^image/.test(file.mime)) {
    return Image;
  }

  return Link;
}

function UIFile({ file, disablePreview, ...restProps }) {
  const FileComponent = getComponent(file, disablePreview);
  const filename = file && file.filename ? file.filename : '';
  return <FileComponent {...file} alt={filename} {...restProps} />;
}

UIFile.propTypes = {
  file: shape({
    filename: string,
    url: string.isRequired,
    created: customPropTypes.date,
    mime: string,
    attributes: shape({}),
    onClick: func,
  }),
  disablePreview: bool,
  hasMask: bool,
};

UIFile.defaultProps = {
  disablePreview: false,
  hasMask: false,
  file: {},
};

export default UIFile;
