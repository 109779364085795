import MetricType from 'shared/types/MetricType';
import { Production } from 'components/Production/types';
import metricPerPlatform from 'components/Creator/Productions/Card/utils/getMetricPerPlatform';

export default function getFollowers(
  productionId: number,
  productions: Production[],
): number {
  const platformProduction = productions.find(
    (prod) => prod.id === productionId,
  );

  const result = metricPerPlatform(
    platformProduction,
    'followers' as MetricType,
    true,
  );

  if (Number.isNaN(Number(result))) {
    return null;
  }
  return Number(result);
}
