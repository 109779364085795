import { Placement } from 'components/Placement/types';

export default function getProperty(data: Placement) {
  return (
    data?.source?.production?.property ??
    data?.integration?.production?.property ??
    data?.integration?.deliverable?.property ??
    null
  );
}
