import placeholderImg from 'components/assets/img/ben_placeholder_img.svg';
import upperFirst from 'lodash/upperFirst';
import { EXPORT_TYPES } from './exportTypes';
import { DEFAULT_EXPORT_OPTIONS } from './defaultExportOptions';

export { EXPORT_TYPES, DEFAULT_EXPORT_OPTIONS };

export const TERRITORIES = ['APAC', 'EMEA', 'LATAM', 'NOAM'] as const;
export const TERRITORIES_SELECT_OPTIONS = TERRITORIES.map((territory) => ({
  value: territory,
  label: territory,
}));

export const QLTY_PREMIUM = 'premium' as const;
export const QLTY_PROMINENT = 'prominent' as const;
export const QLTY_STANDARD = 'standard' as const;
export const QLTY_DISPLACEMENT = 'displacement' as const;

export const QUALITIES_ARRAY = [
  QLTY_PREMIUM,
  QLTY_PROMINENT,
  QLTY_STANDARD,
  QLTY_DISPLACEMENT,
];

export const QUALITIES = {
  PREMIUM: QLTY_PREMIUM,
  PROMINENT: QLTY_PROMINENT,
  STANDARD: QLTY_STANDARD,
};

export const QUALITY_SELECT_OPTIONS = QUALITIES_ARRAY.map((q) => ({
  label: upperFirst(q),
  value: q === QLTY_DISPLACEMENT ? 'msft x' : q,
}));

export const CHANNELS = ['film', 'tv', 'digital', 'influencer'] as const;

export const defaultImage =
  'https://d36oukamy1eeu1.cloudfront.net/site/logos/default_brand_icon.jpg';
export const defaultIGImage =
  'https://d36oukamy1eeu1.cloudfront.net/site/logos/instagram_default.jpg';
export const placeholderImage = placeholderImg;

export const VERBAL_SELECT_OPTIONS = [
  { label: 'Mentioned', value: 'mentioned' },
  { label: 'Only', value: 'only' },
];

export const COUNTRY_SELECT_OPTIONS = [
  { label: 'US', value: 'us' },
  { label: 'UK', value: 'uk' },
] as const;

export const SOURCE_CLASS_SELECT_OPTIONS = [
  { label: 'Video', value: 'Video' },
  { label: 'Social', value: 'Social' },
  { label: 'Press', value: 'Press' },
] as const;

// BEN-13861 English first, then alpha, be extra careful when changing this list!
export const LANGUAGE_SELECT_OPTIONS = [
  { label: 'English', value: 'english' },
  { label: 'Arabic', value: 'arabic' },
  { label: 'Chinese (Cantonese)', value: 'chinese_cantonese' },
  { label: 'Chinese (Mandarin)', value: 'chinese_mandarin' },
  { label: 'Danish', value: 'danish' },
  { label: 'Dutch', value: 'dutch' },
  { label: 'Filipino (Tagalog)', value: 'filipino_tagalog' },
  { label: 'Finnish', value: 'finnish' },
  { label: 'French', value: 'french' },
  { label: 'German', value: 'german' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Italian', value: 'italian' },
  { label: 'Japanese', value: 'japanese' },
  { label: 'Korean', value: 'korean' },
  { label: 'Norwegian', value: 'norwegian' },
  { label: 'Portuguese', value: 'portuguese' },
  { label: 'Russian', value: 'russian' },
  { label: 'Spanish (Castilian)', value: 'spanish_castilian' },
  { label: 'Spanish (Latin America)', value: 'spanish_latin_america' },
  { label: 'Swedish', value: 'swedish' },
  { label: 'Turkish', value: 'turkish' },
  { label: 'Welsh', value: 'welsh' },
] as const;

export const DIRECT_DOWNLOAD_THRESHOLD = 500;
export const MAXIMUM_DOWNLOAD_THRESHOLD = {
  default: {
    normal: 1000,
    bigExport: 5000,
  },
  csv: {
    normal: 5000,
    bigExport: 15000,
  },
};

export const UNIT_TYPES = [
  'impressions',
  'averageImpressions',
  'views',
  'clicks',
  'engagements',
  'actions',
  'placements',
  'other',
] as const;

export const ALL_REPORTING_WINDOW_OPTIONS = [
  { value: 'airings', label: 'Airings' },
  { value: 'channelDefault', label: 'Channel Default' },
  { value: 'premiere', label: 'Premiere' },
  { value: 'live1', label: 'Live 1' },
  { value: 'live3', label: 'Live 3' },
  { value: 'live7', label: 'Live 7' },
  { value: 'live30', label: 'Live 30' },
  { value: 'allTime', label: 'All Time' },
  { value: 'monthEnd', label: 'Month End' },
  { value: 'past30', label: 'Past 30' },
  { value: 'past60', label: 'Past 60' },
  { value: 'past90', label: 'Past 90' },
] as const;

export const ALL_REPORTING_WINDOWS_KEYS = ALL_REPORTING_WINDOW_OPTIONS.map(
  (item) => item.value,
);

export type ReportingWindowKeys = typeof ALL_REPORTING_WINDOWS_KEYS[number];
export type ReportingWindowOption = typeof ALL_REPORTING_WINDOW_OPTIONS[number];

export function reportingWindowKeysToOptions<T extends ReportingWindowKeys>(
  reportingWindows: T[],
) {
  return ALL_REPORTING_WINDOW_OPTIONS.filter((item) =>
    reportingWindows.includes(item.value as T),
  ) as Extract<ReportingWindowOption, { value: T }>[];
}

// Don't change old constant values, to avoid breaking old components
const AIRINGS_REPORTING_WINDOW_KEYS = ALL_REPORTING_WINDOWS_KEYS.filter(
  (key) => key !== 'airings',
) as Exclude<ReportingWindowKeys, 'airings'>[];

export const AIRINGS_REPORTING_WINDOW_OPTIONS = reportingWindowKeysToOptions(
  AIRINGS_REPORTING_WINDOW_KEYS,
);

export const REPORTING_WINDOWS = AIRINGS_REPORTING_WINDOW_KEYS.filter(
  (key) => key !== 'channelDefault',
) as Exclude<typeof AIRINGS_REPORTING_WINDOW_KEYS[number], 'channelDefault'>[];

export const NON_PREMIERE_REPORTING_WINDOWS = REPORTING_WINDOWS.filter(
  (period) => period !== 'premiere',
) as Exclude<typeof REPORTING_WINDOWS[number], 'premiere'>[];

export const AIRINGS_SHOW_IMPRESSIONS_LABELS: Record<
  ReportingWindowKeys,
  string
> = ALL_REPORTING_WINDOW_OPTIONS.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {} as Record<ReportingWindowKeys, string>);

export const REPORTING_WINDOW_OPTIONS = reportingWindowKeysToOptions(
  REPORTING_WINDOWS,
);
export const NON_PREMIERE_REPORTING_WINDOWS_OPTIONS = reportingWindowKeysToOptions(
  NON_PREMIERE_REPORTING_WINDOWS,
);
