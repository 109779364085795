import { useQuery } from '@apollo/react-hooks';
import LogsQuery from 'components/Creator/EmailLastUpdated/graphql/Logs.graphql';

import formatDate from 'shared/dates/formatDate';

export default function EmailLastUpdated({ personId }: { personId: number }) {
  const { data, loading } = useQuery(LogsQuery, {
    skip: !personId,
    variables: {
      filter: {
        entityType: 'person',
        entityId: personId,
        message: 'email',
        excludeBots: true,
      },
      sort: 'created_at-desc',
      limit: 1,
    },
  });

  if (loading) {
    return null;
  }

  if (!data?.logs?.data?.length) {
    return null;
  }

  const lastUpdatedDate = formatDate(data?.logs?.data?.[0]?.createdAt);

  return <p>{`Contact info last updated on ${lastUpdatedDate}`}</p>;
}
