/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import get from 'lodash/get';
import { hasDirectives } from 'apollo-utilities';
import { removeDirectivesFromDocument } from './utils';

const DIRECTIVE_NAME = 'check';

export const remove = (directive, permissions) => {
  const requiredPermission = directive.arguments.find(
    (arg) => arg.name.value === 'permission',
  );
  return !get(permissions, requiredPermission.value.value);
};

function overrideSetOptions(queryOrMutation, directiveToRemove, type) {
  // after first call, the updates are executed by this function
  if (queryOrMutation.setOptions) {
    queryOrMutation.__setOptions = queryOrMutation.setOptions;
    queryOrMutation.setOptions = (newObservableQueryOptions) => {
      if (!hasDirectives([DIRECTIVE_NAME], newObservableQueryOptions[type])) {
        return queryOrMutation.__setOptions(newObservableQueryOptions);
      }

      newObservableQueryOptions[type] = removeDirectivesFromDocument(
        directiveToRemove,
        newObservableQueryOptions[type],
      );

      return queryOrMutation.__setOptions(newObservableQueryOptions);
    };
  }
  return queryOrMutation;
}

const removeSensitiveData = (client, permissions) => {
  client.__watchQuery = client.watchQuery;
  client.__query = client.query;
  client.watchQuery = (options, shouldSubscribe) => {
    const directiveToRemove = [
      {
        name: DIRECTIVE_NAME,
        remove: (directive) => remove(directive, permissions),
      },
    ];

    if (!hasDirectives([DIRECTIVE_NAME], options.query)) {
      return overrideSetOptions(
        client.__watchQuery(options, shouldSubscribe),
        directiveToRemove,
        'query',
      );
    }

    options.query = removeDirectivesFromDocument(
      directiveToRemove,
      options.query,
    );

    const query = client.__watchQuery(options, shouldSubscribe);

    return overrideSetOptions(query, directiveToRemove, 'query');
  };

  client.query = (options, shouldSubscribe) => {
    const directiveToRemove = [
      {
        name: DIRECTIVE_NAME,
        remove: (directive) => remove(directive, permissions),
      },
    ];

    if (!hasDirectives([DIRECTIVE_NAME], options.query)) {
      return overrideSetOptions(
        client.__query(options, shouldSubscribe),
        directiveToRemove,
        'query',
      );
    }

    options.query = removeDirectivesFromDocument(
      directiveToRemove,
      options.query,
    );

    const query = client.__query(options, shouldSubscribe);

    return overrideSetOptions(query, directiveToRemove, 'query');
  };

  client.__mutate = client.mutate;
  client.mutate = (options) => {
    const directiveToRemove = [
      {
        name: DIRECTIVE_NAME,
        remove: (directive) => remove(directive, permissions),
      },
    ];

    if (!hasDirectives([DIRECTIVE_NAME], options.mutation)) {
      return overrideSetOptions(
        client.__mutate(options),
        directiveToRemove,
        'mutation',
      );
    }

    options.mutation = removeDirectivesFromDocument(
      directiveToRemove,
      options.mutation,
    );

    const mutation = client.__mutate(options);

    return overrideSetOptions(mutation, directiveToRemove, 'mutation');
  };
};

export default removeSensitiveData;
