import { useState } from 'react';
import useShortcuts from 'utils/shortcuts/useShortcuts';

export default function useDialog() {
  const [isOpen, setIsOpen] = useState(false);

  function close() {
    setIsOpen(false);
  }

  function open() {
    setIsOpen(true);
  }
  function onClose() {
    setIsOpen(false);
  }

  useShortcuts([['Esc', close]], !isOpen);

  return {
    isOpen,
    open,
    close,
    onClose,
  };
}
