import './SectionLabel.scss';

export default function SectionLabel({
  text,
  size = 'medium',
  fontWeight = 'normal',
}: {
  text: string;
  size?: 'x-small' | 'small' | 'medium' | 'large';
  fontWeight?: 'light' | 'normal' | 'bold';
}) {
  return (
    <span className={`section-label font-weight-${fontWeight} ${size}`}>
      {text}
    </span>
  );
}
