export const getRandomisedUrl = (templateUrl: string, url: string) => {
  // create a semi random token to should be the same each time it is generated for
  // a given url.
  const token = url.charCodeAt(url.length - 6) % 5;
  return templateUrl.replace('_  _', String(token));
};

/* eslint-disable no-useless-escape */
const legacyUrlPattern =
  /^https?:\/\/media\.nmadirectdata\.com\.s3\-website\-us\-west\-2\.amazonaws\.com/g;
const legacyTemplateURL =
  // we're using '_  _' as a replacement token as it should never be used in a valid url
  'https://_  _.legacyfiles.productplacement.com';

const prodUrlPattern =
  /^https?:\/\/files-ben-productplacement-com\.s3-website-us-east-1\.amazonaws\.com/g;
const prodTemplateUrl = 'https://_  _.files.productplacement.com';

const stableUrlPattern =
  /^https?:\/\/files-ben-productplacement-com\.s3\.amazonaws\.com/g;

export const getLoadBalancedUrl = (url: string) => {
  if (!url) {
    return url;
  }

  if (url.match(legacyUrlPattern)) {
    return url.replace(
      legacyUrlPattern,
      getRandomisedUrl(legacyTemplateURL, url),
    );
  }

  if (url.match(prodUrlPattern)) {
    return url.replace(prodUrlPattern, getRandomisedUrl(prodTemplateUrl, url));
  }

  if (url.match(stableUrlPattern) && url.includes('imagecache')) {
    return url.replace(
      stableUrlPattern,
      getRandomisedUrl(prodTemplateUrl, url),
    );
  }
  return url;
};
