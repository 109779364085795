import twitchCommon from './helpers/twitchCommon';

const metric = 'gameRankAfterstream';
export default function gameRankAfterstream() {
  return {
    ...twitchCommon(metric),
    headerName: 'Game Rank After stream',
    width: 280,
  };
}
