import { shape } from 'prop-types';
import UIIcon from 'components/UI/Icon/Icon';
import checkIconImg from 'components/assets/img/check_icon.png';
import { toKeysMap } from 'utils/array';
import { PLACEMENT_QUALITY_ATTRIBUTE } from 'components/Placement/constants';

import './Attributes.scss';

const cbn = 'placement-view-attributes';

const qualityAttrsMap = toKeysMap(PLACEMENT_QUALITY_ATTRIBUTE, 'key');

function PlacementViewAttributes({ attributes }) {
  const qualityAttributes = attributes
    ? Object.entries(attributes).filter(
        ([attr, value]) => value && qualityAttrsMap[attr],
      )
    : [];

  if (!qualityAttributes.length) {
    return null;
  }

  return (
    <div className={cbn}>
      <ul className={`${cbn}__quality__list`}>
        {qualityAttributes.map(([attrKey]) => (
          <li className={`${cbn}__quality__list-item`} key={attrKey}>
            <img
              className={`${cbn}__quality__list-item__print-icon`}
              src={checkIconImg}
              alt="checked"
            />
            <UIIcon type="check" />
            {qualityAttrsMap[attrKey].label}
          </li>
        ))}
      </ul>
    </div>
  );
}

PlacementViewAttributes.propTypes = {
  attributes: shape({}),
};

PlacementViewAttributes.defaultProps = {
  attributes: {},
};

export default PlacementViewAttributes;
