import Joi from 'joi';

export const legacyPasswordSchema = Joi.string().min(8).max(20);

export const currentPasswordSchema = legacyPasswordSchema
  .regex(/^(?=.*?[A-Za-z])(?=.*?\d).*$/)
  .error((errors) => {
    errors.forEach((error) => {
      if (error.type === 'string.regex.base') {
        Object.assign(error, {
          message: `${error.context.label} must include both letters and numbers`,
        });
      }
    });
    return errors;
  });
