// @ts-nocheck
import React, { useState } from 'react';
import classnames from 'classnames';
import { FileWithPath } from 'react-dropzone';
import UIDialog, { useDialog } from 'components/UI/Dialog/Dialog';
import UIFormLabel from 'components/UI/Form/Label/Label';
import FormInputText from 'components/Form/Input/Text/Text';
import UIButton from 'components/UI/Button/Button';
import UIErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import { FileType } from '../types';
import { getMimeFromURL } from '../utils';

const cbn = 'form-file-uploader-dropzone-url';

type FormFileUploaderDropzoneURLProps = {
  disabled: boolean;
  onError: (status: string, file: FileType) => void;
  onDrop: (files: FileWithPath[] | FileType[], isFromUrl?: boolean) => void;
  description: string;
};

function FormFileUploaderDropzoneURL({
  description,
  disabled,
  onDrop,
  onError,
}: FormFileUploaderDropzoneURLProps) {
  const [error, setError] = useState('');
  const [url, setUrl] = useState('');
  const dialog = useDialog();

  const handleURL = React.useCallback(
    (newUrl) => {
      if (!newUrl) {
        onError('No text dropped in', { url: 'fromurl' });
        return;
      }

      const mime = getMimeFromURL(newUrl);

      if (!mime) {
        onError('Url is not a valid one', { url: 'fromurl' });
        return;
      }

      onDrop([
        {
          url: newUrl,
          mime: 'video/vnd.youtube.yt',
        },
      ]);
    },
    [onDrop, onError],
  );

  const onClose = React.useCallback(() => {
    dialog.close();
    setError('');
    setUrl('');
  }, [dialog]);

  const handleDrop = (ev) => {
    if (disabled) {
      return;
    }

    const data = ev.dataTransfer.getData('text');
    handleURL(data);
  };

  const saveYoutubeURL = React.useCallback(() => {
    setError('');
    if (!url) {
      return;
    }

    const mime = getMimeFromURL(url);

    if (!mime) {
      setError('Url is not a valid one');
      return;
    }

    handleURL(url);
    dialog.close();
    setUrl('');
  }, [dialog, handleURL, url]);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onDrop={handleDrop}
        onClick={dialog.open}
        onKeyDown={dialog.open}
        className={classnames(cbn, { disabled })}
      >
        <span className={`${cbn}__description`}>{description}</span>
      </div>
      <UIDialog
        isOpen={dialog.isOpen}
        onClose={onClose}
        title={description}
        description={
          <div>
            <UIFormLabel>Youtube video URL</UIFormLabel>
            <FormInputText
              value={url}
              autoFocus
              onChange={(event) => setUrl(event.target.value)}
            />
            {error && <UIErrorMessage>{error}</UIErrorMessage>}
          </div>
        }
        renderActions={() => (
          <>
            <UIButton theme="text" onClick={onClose}>
              Cancel
            </UIButton>
            <UIButton
              theme="contained-red"
              onClick={saveYoutubeURL}
              disabled={!url}
            >
              Save
            </UIButton>
          </>
        )}
      />
    </>
  );
}

FormFileUploaderDropzoneURL.defaultProps = {
  description: 'YouTube',
  disabled: false,
};

export default FormFileUploaderDropzoneURL;
