import getUTCDateAtBeginningOfDay from './getUTCDateAtBeginningOfDay';

export default function getUTCDateAtEndOfMonth(date: Date | string | null) {
  if (date === null) {
    return null;
  }
  try {
    const dateObject = getUTCDateAtBeginningOfDay(date);
    if (dateObject === null) {
      return null;
    }
    /*
     * Set date:
     * - one month in the future;
     * - first day of the year;
     * - the day before (last day of the month).
     */
    dateObject.setMonth(dateObject.getMonth() + 1);
    dateObject.setDate(1);
    dateObject.setDate(dateObject.getDate() - 1);
    return new Date(dateObject);
  } catch (e) {
    return null;
  }
}
