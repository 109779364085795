import { nonAgGridColKeys } from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/nonAgGridColKeys';
import { CreatorColDef } from 'shared/types/Campaign';

export default function removeNonAgGridKeys(colDef: CreatorColDef) {
  const newColDef = { ...colDef };

  nonAgGridColKeys.forEach((colKey) => {
    delete newColDef[colKey];
  });
  return newColDef;
}
