import './CheckboxRenderer.scss';

export default function DestinationCheckboxRenderer({
  dataCy,
  value,
  alignRight = false,
}: Readonly<{
  value?: string | number | null;
  dataCy?: string | null;
  alignRight?: boolean;
}>) {
  return (
    <div
      className={`ag-cell-destination ag-cell-destination-checkbox ${
        alignRight ? 'align-right' : ''
      }`}
    >
      <input
        type="checkbox"
        checked={Boolean(value)}
        data-cy={dataCy}
        onChange={() => null}
      />
    </div>
  );
}
