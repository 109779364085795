import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import tikTokCommon from './helpers/tikTokCommon';

export default function videoViewsPaid() {
  const tikTokMetric = 'fct_lifetime_video_performance.num_video_views_paid' as keyof typeof TCMReportMetrics;
  const metric = 'video_views_paid' as keyof typeof TCMReportMetrics;
  return {
    ...numberColDef,
    ...tikTokCommon(tikTokMetric, metric),
    field: tikTokMetric,
    headerName: 'Video Views Paid',
    width: 230,
  };
}
