import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';

import './Alert.scss';

const icons = {
  error: 'warning',
};

type AlertProps = {
  showIcon?: boolean;
  text: string;
  type: 'error';
};

const Alert: React.FC<AlertProps> = ({
  showIcon = false,
  text,
  type,
}) => (
  <div className={`Alert Alert--${type}`}>
    <div className="info-text">
      {showIcon ? <BaseIcon icon={icons[type]} /> : null}
      <p>{text}</p>
    </div>
  </div>
);

export default Alert;
