import React from 'react';
import { Formik, Form } from 'formik';
import useLazyRest from 'store/api/useLazyRest';
import Field from 'components/Form/Field/Field';
import UIGridRow from 'components/UI/Grid/Row/Row';
import UIGridCol from 'components/UI/Grid/Col/Col';
import FormSection from 'components/Form/Section/Section';
import FormRow from 'components/Form/Row/Row';
import FormGroup from 'components/Form/Group/Group';
import InputText from 'components/Form/Input/Text/Text';
import { Link } from 'react-router-dom';
import UIButton from 'components/UI/Button/Button';
import UIErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import UIIcon from 'components/UI/Icon/Icon';
import UILabel from 'components/UI/Label/Label';
import { validate } from 'components/utils/form';
import schema from './schema';

import './ResetPassword.scss';

const cbn = 'reset-password';

function UserResetPassword() {
  const [resetPassword, { data: response, error }] = useLazyRest({
    method: 'POST',
    url: 'users/password/reset',
  });

  if (response) {
    return (
      <UIGridRow>
        <UIGridCol>
          <h2>Success!</h2>
          <p>
            Your password has been reset on your account and your new temporary
            password has been sent to your registered email.
          </p>
          <br />
          <UIButton to="/user/login" theme="contained-green">
            Continue
          </UIButton>
        </UIGridCol>
      </UIGridRow>
    );
  }

  function onSubmit(data) {
    resetPassword({
      data,
    });
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validate={validate(schema)}
      initialValues={{ email: '' }}
      render={() => (
        <UIGridRow>
          <UIGridCol>
            <FormSection noTableOfContents>
              <div className="reset-password__notice">
                <p>
                  Enter the email address on your account and we will email you
                  a new temporary password.
                  <br />
                  You will be required to change this password upon sign in.
                </p>

                {error && (
                  <UIErrorMessage highlight>
                    There was a problem. Please enter a valid email address
                    associated with an account.
                  </UIErrorMessage>
                )}
              </div>
              <Form>
                <FormRow>
                  <FormGroup container>
                    <label htmlFor="email" className={`${cbn}__label`}>
                      Email
                    </label>
                    <Field
                      component={InputText}
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                      autoFocus
                    />
                  </FormGroup>

                  <div className="reset-password__actions">
                    <span>
                      <UILabel>Important</UILabel>
                      This will change your password for the BEN site.
                    </span>
                    <UIButton type="submit" theme="contained-purple">
                      Change password
                    </UIButton>
                  </div>
                </FormRow>
              </Form>
            </FormSection>
            <div className="reset-password__iframe-action">
              <Link to="/user/login" className="reset-password__back-link">
                <UIIcon type="navigate_before" size="small" />
                {' '}
                Back to Sign in
              </Link>
            </div>
          </UIGridCol>
        </UIGridRow>
      )}
    />
  );
}

export default UserResetPassword;
