import { ValueGetterParams } from 'ag-grid-community/dist/lib/main';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import percent from 'components/DataGrid/formatters/percent';
import { Placement } from 'shared/types/Placement';

export default function viewsByAiViews() {
  return {
    ...baseColDef,
    field: 'viewsByAiViews',
    headerName: 'Views/AI %',
    valueFormatter: percent,
    valueGetter: ({ getValue }: ValueGetterParams<Placement>) => {
      const aiViews = getValue('aiViews');
      const views = getValue('views');
      if (
        views &&
        aiViews &&
        Number.isFinite(Number(views)) &&
        Number.isFinite(Number(aiViews))
      ) {
        return views / aiViews;
      }
      return null;
    },
  };
}
