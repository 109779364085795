import React from 'react';
import InputText from 'components/Form/Input/Text/Text';

export default function LabelInputCombo({
  label,
  inputName,
  inputValue,
  placeholder,
  onInputChange,
}: {
  label: string;
  inputValue: string;
  onInputChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  inputName?: string;
  placeholder?: string;
}) {
  return (
    <section className="label-input-combo">
      <p>{label}</p>
      <InputText
        name={inputName ?? ''}
        onChange={onInputChange}
        value={inputValue}
        placeholder={placeholder}
      />
    </section>
  );
}
