import manualMetricsValueGetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import { Placement } from 'shared/types/Placement';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/main';

export default function crPercentage() {
  return {
    ...percentColDef,
    valueGetter: ({ data, context }: ValueGetterParams<Placement>) => {
      if (!data) {
        return null;
      }
      /**
       * Once we have automated data from Tune add it here.
       */
      const conversions = manualMetricsValueGetter(
        data,
        context,
        'conversions',
      );
      /**
       * Once we have automated data from Tune add it here.
       */
      const clicks = manualMetricsValueGetter(data, context, 'clicks');
      if (
        !conversions ||
        !clicks ||
        !Number.isFinite(Number(conversions)) ||
        !Number.isFinite(Number(clicks))
      ) {
        return null;
      }

      return Number(conversions) / Number(clicks);
    },
    editable: false,
    headerName: 'CR %',
    field: 'crPercentage',
  };
}
