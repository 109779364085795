import formatDate from 'shared/dates/formatDate';

export default function date({ value }) {
  if (!value) {
    return null;
  }
  if (value === '--') {
    return value;
  }

  return formatDate(value);
}
