import performanceReporting from 'components/Campaign/Influencer/View/Stats/Performance/columns/performanceReporting';
import { CreatorColDef } from 'shared/types/Campaign';

export default function getBaseColDef(campaignTab: string) {
  if (['stats', 'performance'].includes(campaignTab)) {
    return performanceReporting() as CreatorColDef[];
  }

  return [];
}
