export enum CreatorMediaTypes {
  video = 'video',
  story = 'story',
  stream = 'stream',
  post = 'post',
  photo = 'photo',
  reels = 'reels',
  live = 'live',
}

export enum MediaTypes {
  video = 'video',
  story = 'story',
  stream = 'stream',
  post = 'post',
  photo = 'photo',
  episode = 'episode',
  film = 'film',
  production = 'production',
  reels = 'reels',
  live = 'live',
}

export enum MediaStyles {
  integration = 'integration',
  thematic = 'thematic',
  dedicated = 'dedicated',
}

export enum CreatorPlatforms {
  youtube = 'youtube',
  instagram = 'instagram',
  facebook = 'facebook',
  twitter = 'twitter',
  twitch = 'twitch',
  tiktok = 'tiktok',
  other = 'other',
}
export enum Platforms {
  film = 'film',
  season = 'season',
  youtube = 'youtube',
  instagram = 'instagram',
  facebook = 'facebook',
  twitter = 'twitter',
  twitch = 'twitch',
  tiktok = 'tiktok',
  other = 'other',
}
