import addAgGridDefinitionsToCustomColumns from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/addAgGridDefinitionsToCustomColumns';
import mergeColumns from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/mergeColumns';
import removeNonAgGridKeys from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/removeNonAgGridKeys';
import { CampaignCreatorColDef, CreatorColDef } from 'shared/types/Campaign';

export default function useCreatorColumns(
  campaignTab: string,
  campaignColumns: CampaignCreatorColDef | null,
  columnDisplay: boolean,
  isForExternalUse: boolean,
) {
  const mergedColumns = mergeColumns(
    campaignTab,
    campaignColumns,
    columnDisplay,
    isForExternalUse,
  );

  return mergedColumns
    .map((col) => {
      if (col?.group && !col.children?.length) {
        return null;
      }
      if (col?.children?.length) {
        const anyChildrenIsVisible = (col?.children ?? []).find(
          (child: CreatorColDef) => child?.displayed,
        );
        if (!anyChildrenIsVisible) {
          return null;
        }
        return {
          ...removeNonAgGridKeys(
            addAgGridDefinitionsToCustomColumns(col, isForExternalUse),
          ),
          children: col.children.map((childCol: CreatorColDef) => ({
            ...removeNonAgGridKeys(
              addAgGridDefinitionsToCustomColumns(childCol, isForExternalUse),
            ),
          })),
        };
      }
      return removeNonAgGridKeys(
        addAgGridDefinitionsToCustomColumns(col, isForExternalUse),
      );
    })
    .filter(Boolean) as CreatorColDef[];
}
