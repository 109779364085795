import tooltips from './tooltips';

const VOCABULARY_TYPES_OPTIONS = [
  { value: 'category', label: 'Category' },
  { value: 'channel', label: 'Channel' },
  { value: 'class', label: 'Class' },
  { value: 'distributor', label: 'Distributor' },
  { value: 'genre', label: 'Genre' },
  { value: 'interest', label: 'Interest' },
  { value: 'location', label: 'Location' },
  { value: 'network', label: 'Network' },
  { value: 'productionCompany', label: 'Production Company' },
  { value: 'studio', label: 'Studio' },
  { value: 'vertical', label: 'Vertical' },
];

export { VOCABULARY_TYPES_OPTIONS, tooltips };
