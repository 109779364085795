export default function registrationReducer(state, action) {
  if (action.type === 'register') {
    return {
      ...state,
      [action.field]: {
        section: action.section,
        getValue: action.getValue,
      },
    };
  }

  if (action.type === 'unregister') {
    return {
      ...state,
      [action.field]: undefined,
    };
  }
  return state;
}
