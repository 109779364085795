import {
  TCMAudienceAge,
  TCMAudienceCountry,
  TCMAudienceGender,
  TCMAudienceLocale,
} from 'shared/types/TikTokMarketPlace';
import capitalizeString from 'shared/utils/strings/capitalizeString';
import sortTCMMetricsByProgression from 'shared/utils/productions/TikTok/sortTCMMetricsByProgression';

import { ValueType } from '../../../../../../utils/formatters';

export function getGenderRows(metrics: TCMAudienceGender[]) {
  return ['male', 'female'].map((gender) => ({
    label: capitalizeString(gender),
    type: 'percentage' as ValueType,
    value: (metrics ?? [])?.find((m) => m.gender === gender)
      ?.percentage as number,
  }));
}

export function getAgeRows(metrics: TCMAudienceAge[]) {
  return ['18-24', '25-34', '35+'].map((gender) => ({
    label: gender,
    type: 'percentage' as ValueType,
    value: (metrics ?? [])?.find((m) => m.age === gender)?.percentage as number,
  }));
}

export function getCountryRows(metrics: TCMAudienceCountry[]) {
  if (!metrics?.length) {
    return [];
  }
  const sortedMetrics = sortTCMMetricsByProgression(metrics);
  const topFive = sortedMetrics.slice(0, 5) as TCMAudienceCountry[];

  return topFive.map((audienceCountry: TCMAudienceCountry) => ({
    label: audienceCountry?.country,
    type: 'percentage' as ValueType,
    value: audienceCountry?.percentage,
  }));
}

export function getStatesRows(metrics: {
  country?: string;
  locale_distribution?: TCMAudienceLocale[];
}) {
  const country = metrics?.country;
  const states = metrics.locale_distribution;

  if (!country || !states?.length) {
    return [];
  }

  const sortedStates = sortTCMMetricsByProgression(states ?? []);
  const topFive = sortedStates.slice(0, 5) as TCMAudienceLocale[];

  return topFive.map((state: TCMAudienceLocale) => ({
    label: state?.locale,
    type: 'percentage' as ValueType,
    value: state?.percentage,
  }));
}
