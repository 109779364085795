import { PRODUCTION_TYPES_URL_MAPPING } from 'components/Production/constants';
import { Property } from 'shared/types/Property';
import { Production } from 'shared/types/Production';

export type State = {
  url: string;
  urlValid: boolean;
  errorMessage: string;
  productions?: Production[];
  property?: Property;
  loading?: boolean;
  possibleProductions: {
    id?: number | null;
    message?: string;
    url?: string;
  };
  urlValidErrorMessage?: string;
  type?: string;
  youtubeUrlToCheck?: string | null;
  class?: { id?: number };
  channel?: { id?: number };
};

type Action = {
  url?: string;
  possibleProductions?: {
    id?: number | null;
  };
  urlValidErrorMessage?: string;
  youtubeUrlToCheck?: string | null;
  class?: { id?: number };
  channel?: { id?: number };
};

function parsePossibleProductions(state: Partial<State>) {
  if (typeof state?.possibleProductions?.id !== 'number') {
    return null;
  }

  if (state?.possibleProductions?.id === 0) {
    return { message: "We couldn't process your request" };
  }

  return {
    message: 'We found an existing platform with the URL provided in BENlabs.',
    url: `/team/productions/${state.possibleProductions.id}/edit`,
  };
}

export default function reducer(state: State, action: Action) {
  const newState: Partial<State> = {
    ...structuredClone(state ?? {}),
    ...structuredClone(action ?? {}),
  };

  const isURL = newState?.url && /^(https|www)/.test(newState.url);
  newState.urlValidErrorMessage = isURL ? null : 'Please provide a valid URL.';

  newState.possibleProductions = parsePossibleProductions(newState);

  const platform = PRODUCTION_TYPES_URL_MAPPING.find(
    (t) => (newState?.url ?? '').match(t.regex),
    /* eslint-disable-next-line function-paren-newline */
  );

  newState.type = platform?.type;

  newState.urlValid = Boolean(
    isURL && newState.possibleProductions === null && platform?.type,
  );

  return newState;
}
