import formatDate from 'shared/dates/formatDate';
import {
  TCMSparkAdStatusData,
  TCMSparkAdStatusDataAuthStatus,
} from 'shared/types/TikTokMarketPlace';

import AuthorizationInfo from './AuthorizationInfo/AuthorizationInfo';

import './VideoStatusData.scss';

const authStatusMapping = {
  ACCEPTED: 'Approved',
  REJECTED: 'Rejected',
  WAITING: 'Pending',
};

export default function VideoStatusData({
  sparkAdStatusData,
  contentLink,
  tikTokSparkAdStatusRefetch,
  trackContentLinkClick,
}: {
  sparkAdStatusData: TCMSparkAdStatusData;
  contentLink: string | undefined | null;
  tikTokSparkAdStatusRefetch: () => void;
  trackContentLinkClick?: () => void;
}) {
  const authStatus = sparkAdStatusData.auth_status;
  const mappedAuthStatus =
    authStatusMapping[authStatus as TCMSparkAdStatusDataAuthStatus];

  return (
    <section className="tiktok-spark-ads__update__content__video-data">
      <div className="tiktok-spark-ads__update__content__video-data__update-status">
        <p
          className={`tiktok-spark-ads__update__content__video-data__update-status__status ${authStatus?.toLowerCase()}`}
        >
          <strong>Status:</strong> {mappedAuthStatus}
        </p>

        <p className="tiktok-spark-ads__update__content__video-data__update-status__date">
          Last updated:{' '}
          {formatDate(sparkAdStatusData?.auth_status_updated_time ?? '')}
        </p>
      </div>

      <AuthorizationInfo
        contentLink={contentLink}
        startDate={sparkAdStatusData?.auth_code_start_time ?? ''}
        endDate={sparkAdStatusData?.auth_code_end_time ?? ''}
        authCode={sparkAdStatusData?.auth_code}
        authCodeStatus={sparkAdStatusData?.auth_code_status}
        trackContentLinkClick={trackContentLinkClick}
        tikTokSparkAdStatusRefetch={tikTokSparkAdStatusRefetch}
      />
    </section>
  );
}
