import { useContext, useState } from 'react';
import classNames from 'classnames';
import { longDateFormat } from 'components/utils/formats';
import ApplicationContext from 'components/Application/Context';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import { trackPostViewed } from 'external/components/Segment/Segment';
import Chat from 'components/Chat/Chat';
import UIDate from 'components/UI/Date/Date';
import UIMedia from 'components/UI/Media/Media';
import StatusLabel from 'external/components/UI/StatusLabel/StatusLabel';
import './Row.scss';
import { Placement } from 'components/Placement/types';
import prepareClickableContainer from 'external/utils/prepareClickableContainer';
import { CreatedMessagePayload } from 'components/Chat/types';
import { IInitialsItem } from 'external/components/UI/ColoredInitials/types';
import ColoredInitials from 'external/components/UI/ColoredInitials/ColoredInitials';
import { getDeliverableExternalApprovalStatus } from '../utils/utils';

type ExternalPlacementRowProps = {
  updatedAt?: string;
  externalApproval: string | null;
  externalMessagesCount?: number;
  id?: number;
  integrationId?: number;
  isSelected: boolean;
  name?: string;
  onClick: React.MouseEventHandler;
  onCreateMessage: (payload: CreatedMessagePayload) => void;
  thumb: string;
  placement: Placement;
  initials?: IInitialsItem;
};

export default function ExternalPlacementRow({
  updatedAt,
  externalApproval,
  externalMessagesCount,
  id,
  integrationId,
  isSelected,
  name,
  onClick,
  onCreateMessage,
  thumb,
  placement,
  initials,
}: ExternalPlacementRowProps) {
  const { user } = useContext(ApplicationContext);
  const { campaign, segment } = useContext(ExternalApplicationContext);

  const [trackPlacement, setTrackPlacement] = useState<boolean>(true);

  const approvalStatus = getDeliverableExternalApprovalStatus(externalApproval);

  const onPlacementClick = (e: React.MouseEvent<HTMLElement>) => {
    if (segment && user && campaign && trackPlacement) {
      trackPostViewed({ segment, campaign, user, placement });
      setTrackPlacement(false);
    }

    onClick(e);
  };

  const clickableContainer = prepareClickableContainer(onPlacementClick);

  return (
    <div
      className={classNames('external-placement-row', {
        'external-placement-row--active': isSelected,
      })}
      {...clickableContainer}
    >
      <div className="ExternalListItem">
        <div className="ExternalListItem--item external-placement-row__thumb">
          {thumb ? (
            <UIMedia
              alt="ben-thumbnail"
              className="ExternalListItem--item external-placement-row__thumb"
              fallbackImage="placeholderImage"
              imagecachePreset="thumbnail"
              nonPlayable
              url={thumb}
            />
          ) : (
            <ColoredInitials initials={initials} icon />
          )}
        </div>

        <div className="ExternalListItem--item external-placement-row__info">
          {name}
        </div>

        <div className="ExternalListItem--item external-placement-row__status">
          <StatusLabel
            status={approvalStatus.state}
            text={approvalStatus.text}
          />
        </div>

        <div className="ExternalListItem--item external-placement-row__chat">
          <Chat
            childKey={integrationId}
            childType="integration"
            counts={{ external: externalMessagesCount }}
            onCreateMessage={onCreateMessage}
            entityId={id}
            entityType="placement"
            isExternal
          />
        </div>

        <UIDate
          as="div"
          className="ExternalListItem--item external-placement-row__date"
          format={longDateFormat}
        >
          {updatedAt}
        </UIDate>
      </div>
    </div>
  );
}
