import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import tikTokCommon from './helpers/tikTokCommon';

export default function totalPlayTime() {
  const tikTokMetric = 'fct_lifetime_video_performance.total_play_time_sec' as keyof typeof TCMReportMetrics;
  const metric = 'total_play_time';
  return {
    ...numberColDef,
    ...tikTokCommon(tikTokMetric, metric),
    headerName: 'Total Play Time',
    width: 215,
  };
}
