// @ts-nocheck
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { getPropsFor } from 'react-attrs-filter';
import getSrc from './getSrc';

import './Image.scss';

const cbn = 'ui-image';

type UIImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  imagecachePreset: string;
  fallbackImage: string;
  url: string;
};

const loadImage = (url, fallbackImage, imagecachePreset, cb) => {
  let cancelled = false;
  const img = new Image();
  const src = getSrc({ url, fallbackImage, imagecachePreset });

  img.src = src;

  img.onload = () => {
    if (!cancelled) {
      cb(src);
    }
  };

  img.onerror = () => {
    if (!cancelled) {
      cb(getSrc({ fallbackImage }));
    }
  };

  return () => {
    cancelled = true;
  };
};

function UIImage({
  alt,
  className,
  fallbackImage,
  imagecachePreset,
  onClick,
  onError,
  url,
  ...restProps
}: UIImageProps) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const cancel = loadImage(
      url,
      fallbackImage,
      imagecachePreset,
      (imageSrc) => {
        setSrc(imageSrc);
      },
    );

    return () => {
      cancel();
    };
  }, [url, fallbackImage, imagecachePreset]);

  if (!src) {
    return <div className={classnames(className, cbn)} />;
  }

  return (
    <img
      className={classnames(className, cbn, {
        [`${cbn}--has-click`]: onClick,
      })}
      alt={alt}
      {...getPropsFor(restProps, 'img')}
      src={src}
      onClick={onClick}
      onError={onError}
    />
  );
}

UIImage.defaultProps = {
  alt: '',
  className: '',
  fallbackImage: '',
  imagecachePreset: 'small',
  onClick: undefined,
  onError: undefined,
  url: '',
};

export default UIImage;
