export const language = {
  key: '',
  any: {
    empty: '!!This field is required',
    required: '!!This field is required',
    allowOnly: '!!This field is required',
  },
  object: {
    base: '!!This field is required',
  },
  array: {
    min: '!!This field is required',
  },
  string: {
    base: '!!This field is required',
    uriCustomScheme: '!!Must be a valid url',
  },
};
