import React from 'react';
import { string, shape, func } from 'prop-types';
import UILogo from 'components/UI/Logo/Logo';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import UIDate from 'components/UI/Date/Date';
import benLogo from 'components/assets/img/ben_logo.svg';

import './Footer.scss';

const cbn = 'placement-view-footer';

function PlacementViewFooter({
  brand,
  campaignId,
  campaignName,
  campaignType,
  client,
  dateRangeFrom,
  dateRangeTo,
  shouldRender,
}) {
  return (
    <div className={cbn}>
      <div className={`${cbn}__inner`}>
        <span className={`${cbn}__logos__wrapper`}>
          {client && shouldRender('footerSection.clientLogo') && (
            <div className={`${cbn}__logo`}>
              <div className={`${cbn}__logo__inner`}>
                <UILogo url={client?.logo?.url} alt={`${client?.name} logo`} />
              </div>
            </div>
          )}

          {brand && shouldRender('footerSection.brandLogo') && (
            <div className={`${cbn}__logo`}>
              <div className={`${cbn}__logo__inner`}>
                <UILogo url={brand?.logo?.url} alt={`${brand?.name} logo`} />
              </div>
            </div>
          )}
        </span>

        <div className={`${cbn}__data`}>
          {shouldRender('footerSection.brandName') && (
            <div>{get(brand, 'name') || 'N/A'}</div>
          )}

          {campaignId &&
            campaignType &&
            shouldRender('footerSection.campaignName') && (
              <div>
                <Link
                  to={`/${campaignType.toLowerCase()}/campaigns/${campaignId}`}
                >
                  {campaignName}
                </Link>
              </div>
            )}

          {campaignId && shouldRender('footerSection.campaignDates') && (
            <div className={`${cbn}__data_dates`}>
              {dateRangeFrom && (
                <UIDate format="MMM D 'YY">{dateRangeFrom}</UIDate>
              )}
              &nbsp;–&nbsp;
              {dateRangeTo && <UIDate format="MMM D 'YY">{dateRangeTo}</UIDate>}
            </div>
          )}
        </div>
        <div className={`${cbn}__ben-logo`}>
          <img src={benLogo} alt="BEN Logo" />
        </div>
      </div>
    </div>
  );
}

PlacementViewFooter.propTypes = {
  brand: shape({
    logo: shape({}),
    name: string,
  }),
  campaignId: string,
  campaignName: string,
  campaignType: string,
  client: shape({
    logo: shape({}),
    name: string,
  }),
  dateRangeFrom: string,
  dateRangeTo: string,
  shouldRender: func.isRequired,
};

PlacementViewFooter.defaultProps = {
  brand: {},
  campaignId: null,
  campaignName: null,
  campaignType: null,
  client: {},
  dateRangeFrom: null,
  dateRangeTo: null,
};

export default PlacementViewFooter;
