import React from 'react';
import { number, string } from 'prop-types';
import classnames from 'classnames';

import './ProgressLayer.scss';

function UIProgressLayer({ value, className }) {
  return (
    <div className={classnames('ui-progress-layer', className)}>
      <div
        className="ui-progress-layer__layer"
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{
          width: `${100 - value}%`,
        }}
      />
    </div>
  );
}

UIProgressLayer.propTypes = {
  value: number,
  className: string,
};

UIProgressLayer.defaultProps = {
  value: 0,
  className: undefined,
};

export default UIProgressLayer;
