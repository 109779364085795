import Overlay from 'components/Creator/UnderConstruction/Overlay';
import ProgressionTable from '@UIComponents/ProgressionTable/ProgressionTable';
import { TCMAuthorizedDataInsights } from 'shared/types/TikTokMarketPlace';
import {
  getAudienceAgeRows,
  getAudienceCountryRows,
  getAudienceGenderRows,
} from '../../utils/tableRows';
import UnauthorizedWarning from '../../../UnauthorizedWarning/UnauthorizedWarning';

import './Audience.scss';

type TCMAudienceType =
  | 'audience_countries'
  | 'audience_genders'
  | 'audience_ages';

function checkIfMetricsHaveAudienceData(metrics: TCMAuthorizedDataInsights) {
  return ['audience_countries', 'audience_genders', 'audience_ages'].some(
    (metric) => metrics?.[metric as TCMAudienceType]?.length,
  );
}

export default function Audience({
  metrics,
  trackMissingDemographics,
}: {
  metrics: TCMAuthorizedDataInsights;
  trackMissingDemographics: () => void;
}) {
  return (
    <section className="tiktok-audience-container">
      <h2>Audience</h2>

      {metrics !== null ? (
        <div className="tiktok-audience-container__progression-tables">
          <ProgressionTable
            title="Age Range"
            className="tiktok-age-range"
            rows={getAudienceAgeRows(metrics?.audience_ages)}
          />
          <ProgressionTable
            title="Gender"
            className="tiktok-gender"
            rows={getAudienceGenderRows(metrics?.audience_genders)}
          />
          {metrics?.audience_countries?.length ? (
            <ProgressionTable
              title="Countries"
              className="tiktok-countries"
              rows={getAudienceCountryRows(metrics?.audience_countries)}
            />
          ) : null}

          <Overlay
            showOverlay={!checkIfMetricsHaveAudienceData(metrics)}
            onOverlayOpenCb={trackMissingDemographics}
          />
        </div>
      ) : (
        <UnauthorizedWarning />
      )}
    </section>
  );
}
