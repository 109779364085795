import { useState, useRef, useEffect, useCallback } from 'react';
import { errorStream } from 'store/errorHandler';
import errorParser from 'store/errorHandler/errorParser';

function shouldClear(errEvents) {
  return errEvents[0] && errEvents[0].type === 'CLEAR';
}

export default function useErrorHandlerProvider() {
  const [errors, setErrors] = useState([]);
  const listenerRef = useRef(null);

  const push = useCallback((...errorArgs) => {
    setErrors((err) => [...err, ...errorArgs]);
  }, []);

  const reset = useCallback(() => setErrors([]), []);

  useEffect(() => {
    listenerRef.current = errorStream.subscribe((...errEvents) => {
      if (shouldClear(errEvents)) {
        return reset();
      }

      const parsedErrors = errEvents.map(errorParser).filter((err) => err);
      return push(...parsedErrors);
    });

    return () => {
      listenerRef.current();
    };
  }, [push, reset]);

  return {
    errors,
    push,
    reset,
  };
}
