import featureFlags from './featureFlags.json';

const prefix = 'BEN_FEATURE_FLAGS_';

function factory(override) {
  override(featureFlags, prefix);
  return featureFlags;
}

export default factory;
