import { ReactSelectOption } from 'shared/types/ReactSelect';

import './DropDownOptionCellRenderer.scss';

export default function DropDownOptionCellRenderer({
  value,
}: {
  value: ReactSelectOption;
}) {
  return <div className="dropdown-value">{value?.label}</div>;
}
