import React from 'react';
import ChevronDown from '../_assets/react_svg/ChevronDown';
import XClose from '../_assets/react_svg/XClose';
import './ToggleIcon.scss';

export default function ToggleIcon({
  collapsed,
  handleCollapse,
}: {
  collapsed: boolean;
  handleCollapse: (arg0: boolean) => void;
}) {
  const onToggle = React.useCallback(() => {
    handleCollapse(collapsed);
  }, [collapsed, handleCollapse]);

  return (
    <button
      type="button"
      className={`toggle-icon ${collapsed ? 'closed' : 'opened'}`}
      role="tab"
      onClick={onToggle}
      tabIndex={0}
    >
      {collapsed ? <ChevronDown /> : <XClose />}
    </button>
  );
}
