import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  getTikTokHandleNameFromUrl,
  getTikTokVideoIdFromUrl,
} from 'shared/regex/platformUrls';
import {
  OnRequestClose,
  Period,
} from 'components/Tiktok/TiktokSparkAds/CreateSparkAd/types';
import TiktokVideoStatus from '../graphql/TiktokVideoStatus.graphql';
import TiktokVideoApply from '../graphql/TiktokVideoApply.graphql';
import TikTokCreatorQuery from '../graphql/TikTokCreatorQuery.graphql';

export default function useSparkAds(
  url: string | undefined | null,
  onRequestClose: OnRequestClose,
  propertyId?: number,
) {
  const { videoId, handleName } = React.useMemo(
    () => ({
      videoId: getTikTokVideoIdFromUrl(url),
      handleName: getTikTokHandleNameFromUrl(url),
    }),
    [url],
  );

  const { data: tiktokCreatorData, loading: tiktokCreatorLoading } = useQuery(
    TikTokCreatorQuery,
    {
      skip: !propertyId,
      variables: {
        id: propertyId,
        videoId: String(videoId),
        username: handleName,
      },
    },
  );

  const [period, setPeriod] = React.useState<Period | null>(null);
  const {
    data: tikTokSparkAdStatusData,
    loading: tikTokSparkAdStatusLoading,
    refetch: tikTokSparkAdStatusRefetch,
  } = useQuery(TiktokVideoStatus, {
    skip: !videoId,
    variables: {
      videoId: String(videoId),
    },
  });

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = React.useState(
    false,
  );
  const [
    resendConfirmationModalIsOpen,
    setResendConfirmationModalIsOpen,
  ] = React.useState(false);

  const [createSparkAd, { loading: createSparkAdLoading }] = useMutation(
    TiktokVideoApply,
  );

  const onCreateAd = React.useCallback(
    (forceCreation?: boolean) => {
      async function call() {
        return createSparkAd({
          variables: {
            videoId,
            authorizationDays: period?.value,
          },
        });
      }

      if (
        tikTokSparkAdStatusData?.tiktokVideoStatus?.auth_status &&
        !forceCreation
      ) {
        setResendConfirmationModalIsOpen(true);
        return;
      }
      setResendConfirmationModalIsOpen(false);
      call().then((result) => {
        if (result?.data?.tiktokVideoApply !== undefined && !result?.errors) {
          setResendConfirmationModalIsOpen(false);
          setConfirmationModalIsOpen(true);
        }
      });
    },
    [
      createSparkAd,
      videoId,
      period?.value,
      tikTokSparkAdStatusData?.tiktokVideoStatus?.auth_status,
    ],
  );

  const onCloseModal = React.useCallback(() => {
    // Just close the drawer for now. Waiting for the spark ads implementation.
    onRequestClose();
  }, [onRequestClose]);

  return {
    tikTokSparkAdStatusData,
    hasSparkAd: Boolean(
      tikTokSparkAdStatusData?.tiktokVideoStatus?.auth_status,
    ),
    hasError: tikTokSparkAdStatusData?.tiktokVideoStatus === undefined,
    loading:
      tikTokSparkAdStatusLoading ||
      createSparkAdLoading ||
      tiktokCreatorLoading,
    onCreateAd,
    onCloseModal,
    createSparkAdLoading,
    period,
    setPeriod,
    confirmationModalIsOpen,
    setConfirmationModalIsOpen,
    resendConfirmationModalIsOpen,
    setResendConfirmationModalIsOpen,
    tiktokCreatorData,
    videoId,
    tikTokSparkAdStatusRefetch,
  };
}
