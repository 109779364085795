import React from 'react';
import { TCMInsight, TCMReportData } from 'shared/types/TikTokMarketPlace';

import Insight from './Insight/Insight';
import './Metrics.scss';

export default function Metrics({
  tikTokOrderReport,
  videoId,
  onReportingWindowChange,
}: {
  tikTokOrderReport?: TCMReportData;
  videoId?: string;
  onReportingWindowChange: (value: string) => void;
}) {
  const insight = React.useMemo(
    () =>
      (tikTokOrderReport?.insights ?? []).find(
        (i) => i?.dimensions?.video_id === videoId,
      ) as TCMInsight,
    [tikTokOrderReport?.insights, videoId],
  );

  return (
    <Insight
      insight={insight}
      onReportingWindowChange={onReportingWindowChange}
    />
  );
}
