/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

export type UILinkProps = {
  className?: string;
  disabled?: boolean;
  href?: string | null;
  keepReferrer?: boolean;
  onClick?: (ev: React.MouseEvent) => void;
  popReferrer?: boolean;
  pushReferrer?: boolean;
  referrers?: Array<string>;
  target?: string | null;
  to?: string | null;
  children: React.ReactNode;
  dataCy?: string;
  hrefOnClick?: () => void;
};

function UILink({
  children,
  className,
  disabled,
  href,
  keepReferrer,
  onClick,
  popReferrer,
  pushReferrer,
  referrers,
  target,
  to,
  dataCy,
  hrefOnClick = () => {},
}: UILinkProps) {
  const navigate = useNavigate();
  const onLinkClick = React.useCallback(
    (ev: React.MouseEvent) => {
      if (hrefOnClick) {
        hrefOnClick();
      }
      const needsHandle =
        disabled ||
        keepReferrer ||
        pushReferrer ||
        popReferrer ||
        onClick ||
        referrers;

      if (!needsHandle || target === '_blank') {
        return null;
      }

      if (disabled) {
        ev.preventDefault();
        return null;
      }

      if (onClick) {
        onClick(ev);

        if (ev.defaultPrevented) {
          // if ev.preventDefault() was called by onclick cb, we just skip the rest
          return null;
        }
      }

      ev.preventDefault();

      if (keepReferrer || pushReferrer || popReferrer) {
        return navigate((to ?? '')?.replace('/team', ''), {
          state: {
            keepReferrer,
            pushReferrer,
            popReferrer,
          },
        });
      }

      if (referrers) {
        return navigate(to ?? '', {
          state: {
            referrers,
          },
        });
      }

      return navigate(to ?? '');
    },
    [
      disabled,
      hrefOnClick,
      keepReferrer,
      navigate,
      onClick,
      popReferrer,
      pushReferrer,
      referrers,
      target,
      to,
    ],
  );

  if (href) {
    if (disabled) {
      return <span className={className}>{children}</span>;
    }

    return (
      <a
        className={className}
        target={target ?? '_self'}
        href={href}
        onClick={hrefOnClick}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      data-cy={dataCy}
      className={className}
      to={disabled ? '' : String(to)}
      target={target ?? '_self'}
      onClick={onLinkClick}
    >
      {children}
    </Link>
  );
}

export default UILink;
