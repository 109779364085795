import upperFirst from 'lodash/upperFirst';

export const CAMPAIGN_STATUS = [
  'internal',
  'proposed',
  'active',
  'completed',
  'deleted',
  'test',
];

export const CAMPAIGN_STATUS_SELECT_OPTIONS = CAMPAIGN_STATUS.map((opt) => ({
  value: opt,
  label: upperFirst(opt),
}));

export const CAMPAIGN_SORT_OPTIONS = [
  {
    name: 'updated',
    label: 'Updated',
    from: 'Oldest',
    to: 'Newest',
    defaultDirection: 'desc',
  },
  {
    name: 'created',
    label: 'Created',
    from: 'Oldest',
    to: 'Newest',
    defaultDirection: 'desc',
  },
  {
    name: 'flight',
    label: 'Flight Date',
    from: 'Oldest',
    to: 'Newest',
    defaultDirection: 'asc',
  },
  {
    name: 'name',
    label: 'Name',
    from: 'A',
    to: 'Z',
    defaultDirection: 'asc',
  },
  {
    name: 'relevance',
    label: 'Relevance',
    from: 'More',
    to: 'Less',
    defaultDirection: 'desc',
  },
];
export const FINANCIAL_TYPES = [
  'Traditional',
  'Influencer',
  'Performance',
  'Music Video',
  'Research',
  'Other',
];

export const FINANCIAL_TYPES_OPTIONS = FINANCIAL_TYPES.map((type) => ({
  value: type,
  label: type,
}));

export const PAID_STATUSES = ['Paid', 'Unclaimed-Paypal', 'Completed-Paypal'];

export const COGS_STATUSES = [
  'Paid',
  'Unpaid',
  'Pending/Open',
  'Cancelled',
  'Unclaimed-Paypal',
  'Completed-Paypal',
  'Ready for Payment',
];

export const COGS_PAYMENT_TYPE_OPTIONS = [
  {
    label: 'PayPal',
    value: 'paypal',
  },
  {
    label: 'ACH/Wire',
    value: 'achWire',
  },
  {
    label: 'Check',
    value: 'check',
  },
];

export const COGS_PAYMENT_TYPE_LABELS = COGS_PAYMENT_TYPE_OPTIONS.reduce(
  (acc, paymentType) => ({
    ...acc,
    [paymentType.value]: paymentType.label,
  }),
  {},
);

export const COGS_STATUSES_OPTIONS = COGS_STATUSES.map((status) => ({
  value: status,
  label: status,
}));

export const BOOKING_STATUSES = [
  'Go-Get',
  'High',
  'Med',
  'Low',
  'Value Actual',
  'Closed Lost',
];

export const BOOKING_STATUSES_OPEN = BOOKING_STATUSES.filter(
  (s) => s !== 'Closed Lost',
);

export const BOOKING_STATUSES_OPTIONS = BOOKING_STATUSES.map((status) => ({
  value: status,
  name: status,
}));

export const INVOICE_STATUSES = [
  'Prepared',
  'Not Sent',
  'Sent',
  'Paid in Full',
  'Paid in Part',
  'Outstanding',
  'Contested',
];

export const INVOICE_STATUSES_OPTIONS = INVOICE_STATUSES.map((status) => ({
  value: status,
  name: status,
}));

export const INVOICED_STATUSES = [
  'sent',
  'paid in full',
  'paid in part',
  'outstanding',
];

export const REVENUE_STATUSES = [
  'Go-Get',
  'High',
  'Med',
  'Low',
  'Value Actual',
  'Reallocate',
  'Credit Needed',
];

export const REVENUE_STATUSES_OPTIONS = REVENUE_STATUSES.map((status) => ({
  value: status,
  name: status,
}));

export const OPTIMIZER_PLATFORMS_OPTIONS = [
  { value: 'youtube', label: 'YouTube' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'twitch', label: 'Twitch' },
  { value: 'tiktok', label: 'Tik Tok' },
  { value: 'other', label: 'Other' },
];

export const OPTIMIZER_PLATFORMS_LABELS = OPTIMIZER_PLATFORMS_OPTIONS.reduce(
  (acc, p) => {
    acc[p.value] = p.label;
    return acc;
  },
  {},
);

export const PRODUCTION_OPTIMIZER_PLATFORMS_LIST = OPTIMIZER_PLATFORMS_OPTIONS.map(
  ({ value }) => value,
);

export const PRODUCTION_OPTIMIZER_YOUTUBE_CATEGORIES = [
  'Film & Animation',
  'Autos & Vehicles',
  'Music',
  'Pets & Animals',
  'Sports',
  'Short Movies',
  'Travel & Events',
  'Gaming',
  'Videoblogging',
  'People & Blogs',
  'Comedy',
  'Entertainment',
  'News & Politics',
  'Howto & Style',
  'Education',
  'Science & Technology',
  'Nonprofits & Activism',
  'Movies',
  'Anime/Animation',
  'Action/Adventure',
  'Classics',
  'Comedy',
  'Documentary',
  'Drama',
  'Family',
  'Foreign',
  'Horror',
  'Sci-Fi/Fantasy',
  'Thriller',
  'Shorts',
  'Shows',
  'Trailers',
];

export const VALID_TYPES = [
  'Traditional',
  'Influencer',
  'Performance Team',
  '360',
  'Music Video',
];

export const VALID_TYPES_OPTIONS = VALID_TYPES.map((type) => {
  if (type === 'Influencer') {
    return {
      value: type,
      label: 'Awareness',
    };
  }

  if (type === 'Performance Team') {
    return {
      value: type,
      label: 'Performance',
    };
  }

  return {
    value: type,
    label: type,
  };
});

export const metricTypeByMediaType = {
  photo: 'contentPieces',
  post: 'contentPieces',
  story: 'views',
  stream: 'ccv',
  video: 'views',
  reels: 'views',
  live: 'views',
};

export const LOCAL_STORAGE_DATA_GRID_PREFIX = '$BEN$--data-grid--';
