import svgSizeMapping, { SvgSize } from '../utils/svgSizeMapping';

export default function TwitterLogo({
  fill = 'none',
  size,
}: {
  fill?: string;
  size: SvgSize;
}) {
  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 27 28"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_743_13729)">
        <path
          d="M13.4998 9.78125C13.4998 7.46094 15.451 5.53086 17.7713 5.5625C18.5839 5.57188 19.3765 5.81575 20.0538 6.26479C20.7311 6.71383 21.2643 7.34894 21.5893 8.09375H25.3123L21.9057 11.5004C21.6859 14.9224 20.171 18.1322 17.6692 20.4773C15.1674 22.8224 11.8664 24.1267 8.43734 24.125C5.06234 24.125 4.21859 22.8594 4.21859 22.8594C4.21859 22.8594 7.59359 21.5938 9.28109 19.0625C9.28109 19.0625 2.53109 15.6875 4.21859 6.40625C4.21859 6.40625 8.43734 10.625 13.4998 11.4688V9.78125Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_743_13729">
          <rect
            width="27"
            height="27"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
