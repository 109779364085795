import React from 'react';
import useSegmentTTCMTracking, {
  AllowedAction,
} from 'utils/segment/useSegmentTTCMTracking';
import { Placement } from 'shared/types/Placement';
import { TIKTOK_PLATFORM } from 'shared/metrics/metrics';
import TiktokOrderReportDrawer from 'components/Tiktok/TiktokOrderReportDrawer/TiktokOrderReportDrawer';
import TiktokSparkAdsDrawer from 'components/Tiktok/TiktokSparkAds/TiktokSparkAdsDrawer/TiktokSparkAdsDrawer';

import './OrderReportToggle.scss';

export default function OrderReportToggle({
  placement,
  tab,
}: {
  placement: Placement;
  tab: string;
}) {
  const [showDrawer, setShowDrawer] = React.useState({
    orderReport: false,
    sparkAds: false,
  });

  const trackTikTokAction = useSegmentTTCMTracking(placement?.campaign);

  const onOpenOrderReportDrawer = React.useCallback(() => {
    setShowDrawer({ orderReport: true, sparkAds: false });
    trackTikTokAction(AllowedAction.openedDrawer, {
      drawer: 'Order Report',
      tab,
    });
  }, [tab, trackTikTokAction]);

  const onCloseOrderReportDrawer = React.useCallback(() => {
    setShowDrawer({ sparkAds: false, orderReport: false });
    trackTikTokAction(AllowedAction.closedDrawer, {
      drawer: 'Order Report',
      tab,
    });
  }, [tab, trackTikTokAction]);

  const onOpenSparkAdsDrawer = React.useCallback(() => {
    setShowDrawer({ orderReport: false, sparkAds: true });
    trackTikTokAction(AllowedAction.openedDrawer, {
      drawer: 'Spark Ads',
      tab,
    });
  }, [tab, trackTikTokAction]);

  const onCloseSparkAdsDrawer = React.useCallback(() => {
    setShowDrawer({ sparkAds: false, orderReport: true });
    trackTikTokAction(AllowedAction.closedDrawer, {
      drawer: 'Spark Ads',
      tab,
    });
  }, [tab, trackTikTokAction]);

  const { platform, postUrl } = React.useMemo(
    () => ({
      platform: placement?.platform,
      postUrl: placement?.source?.url,
    }),
    [placement?.platform, placement?.source?.url],
  );

  if (platform !== TIKTOK_PLATFORM) {
    return null;
  }

  return (
    <div className="order-report-toggle">
      <button type="button" onClick={onOpenOrderReportDrawer}>
        Open order report
      </button>

      <TiktokOrderReportDrawer
        isOpen={showDrawer.orderReport}
        placement={placement}
        tab={tab}
        onRequestClose={onCloseOrderReportDrawer}
        onManageSparkAds={onOpenSparkAdsDrawer}
        trackTikTokAction={trackTikTokAction}
      />

      <TiktokSparkAdsDrawer
        isOpen={showDrawer.sparkAds}
        onRequestClose={onCloseSparkAdsDrawer}
        postUrl={postUrl}
        placement={placement}
        tab={tab}
        trackTikTokAction={trackTikTokAction}
      />
    </div>
  );
}
