import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import { errorStream } from 'store/errorHandler';
import FileSaver from 'file-saver';
import qs from 'qs';
import storage from 'utils/storage';

const download = {
  brand: (entityId) => `brands/${entityId}/download-file`,
  campaign: (entityId, fileId) =>
    `campaigns/${entityId}/download-file/${fileId}`,
  client: (entityId) => `clients/${entityId}/download-file`,
  deliverable: (entityId, fileId) =>
    `deliverables/${entityId}/download-file/${fileId}`,
  placement: (entityId, fileId) =>
    `placements/${entityId}/download-file/${fileId}`,
  production: (entityId, fileId) =>
    `productions/${entityId}/download-file/${fileId}`,
};

export const fileIsVideo = (file) => {
  try {
    if (/^video/.test(file.mime)) {
      return 'video';
    }
    if (/^(https?:\/\/)?((www\.)?youtube\.com|(youtu\.be))/i.test(file.url)) {
      return 'youtube';
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const urlIsBlob = (file) => file && file.url && file.url.match(/blob:/);
export const urlIsValid = (file) => file && file.url && !urlIsBlob(file);

export function validateUploads(values) {
  if (!values.length) {
    return true;
  }

  return values.every((value) => {
    if (Array.isArray(value)) {
      return value.every((file) => urlIsValid(file));
    }
    return urlIsValid(value);
  });
}

export function validateFormUploads(values, fields) {
  const uploadValues = [];
  if (values) {
    fields.forEach((field) => {
      const value = values[field];
      if (value) {
        uploadValues.push(value);
      }
    });
  }

  return validateUploads(uploadValues);
}

const escapeParams = (params) => {
  Object.keys(params).forEach((key) => {
    if (key === '$regex' && params[key]) {
      // eslint-disable-next-line no-param-reassign
      params[key] = `.*${params[key].replace(
        /[-[\]{}()*+?.,\\^$|#\s]/g,
        '\\$&',
      )}.*`;
    }

    if (typeof params[key] === 'object') {
      escapeParams(params[key]);
    }
  });
};

const api = axios.create({
  baseURL: '/api',
  responseType: 'blob',
  paramsSerializer(params) {
    escapeParams(params);
    return qs.stringify(params);
  },
});

api.interceptors.request.use((config) => {
  const updatedConfig = cloneDeep(config);
  if (!updatedConfig.headers) {
    updatedConfig.headers = {};
  }

  if (window.localStorage) {
    updatedConfig.headers.authorization = `Bearer ${storage.get(
      '$BEN$--accessToken',
    )}`;
  }

  return updatedConfig;
});

export async function saveFile(url, fileName, failQuietly) {
  try {
    const res = await axios.get(url, {
      responseType: 'blob',
      headers: {
        Accept: 'blob',
      },
    });

    FileSaver.saveAs(res.data, fileName);
    return res;
  } catch (err) {
    if (!failQuietly) {
      errorStream.dispatch({ type: 'networkError', error: err });
    }
    throw err;
  }
}

export async function downloadFromAPI(
  resourceType,
  resourceId,
  fileId,
  fileName,
  isPrivate = false,
) {
  const getUrl = download[resourceType];
  const params = { isPrivate };
  try {
    const result = await api.get(getUrl(resourceId, fileId), {
      params,
      responseType: 'blob',
      headers: {
        Accept: 'blob',
      },
    });

    FileSaver.saveAs(result.data, fileName);
    return result;
  } catch (e) {
    errorStream.dispatch({ type: 'restApiError', error: e });
    return e;
  }
}

export default api;
