import BaseCard from '@UIComponents/BaseCard/BaseCard';
import ListLeftRight from '@UIComponents/ListLeftRight/ListLeftRight';
import Loading from '@UIComponents/Loading/Loading';
import { downloadFromAPI } from 'components/utils/file';
import moment from 'moment';
import { useContext } from 'react';
import ExternalApplicationContext from '../Application/Context';
import documentsCenterService, { Document } from './DocumentsCenterService';

const documentsHeaders = {
  leftHeader: 'Uploaded On',
  rightHeader: 'Report Name',
};

const formatDate = (date: string) => moment(date).utc().format('MM/DD/YYYY');

const formatRenderData = (campaignId: number, documents: Document[]) =>
  documents.map(({ filename, created, id }) => ({
    leftRow: formatDate(created),
    rightRow: filename,
    callback: () => downloadFromAPI('campaign', campaignId, id, filename, true),
    icon: 'notes-text',
  }));

const getRenderData = (campaignId: number) => {
  const documents = documentsCenterService(campaignId);

  if (!documents) {
    return [];
  }

  return formatRenderData(campaignId, documents);
};

function DocumentsRender() {
  const { campaignId } = useContext(ExternalApplicationContext);

  if (!campaignId) return <Loading />;

  const documentsRows = getRenderData(campaignId);

  return (
    /* @ts-ignore */
    <BaseCard transparent dataCy="DocumentsRender">
      <ListLeftRight headers={documentsHeaders} rows={documentsRows} />
    </BaseCard>
  );
}

export default DocumentsRender;
