import {
  METRICS_DISPLAY,
  PLACEMENT_COLUMNS,
} from 'components/Metrics/constants/metricTypes';
import { toKeysMap } from 'utils/array';
import { Placement } from 'components/Placement/types';
import { Platforms as Platform } from 'shared/types/platforms';
import MetricType from 'shared/types/MetricType';

type Metric = {
  format: string;
  label: string;
  mediaType: string;
  metricType: string;
  platform: Platform | undefined;
  value: number;
};

type MetricDisplayProps = {
  id: string;
  label: string;
  format: string;
};

type MetricsDisplay = {
  [index: string]: MetricDisplayProps;
};

const metricsDisplay: MetricsDisplay = toKeysMap(
  METRICS_DISPLAY,
  'id',
) as MetricsDisplay;

const normalizeName = (name: string, mediaType: string, platform = '') =>
  (name || '')
    .replace(new RegExp(mediaType, 'gi'), '')
    .replace(new RegExp(platform, 'gi'), '')
    .trim();

const normalizeFormat = (format: string) =>
  (format.includes('%') ? format : '0a');

export const getMetrics = (placement: Placement) => {
  if (!placement?.platformMetrics) {
    return null;
  }
  const { platform, platformMetrics } = placement;

  const metrics: Metric[] = [];

  Object.entries(platformMetrics).forEach(([mediaType, metric]) => {
    Object.entries(metric as Record<MetricType, { value: number }>).forEach(
      ([metricType, { value }]) => {
        const metricDisplay: MetricDisplayProps =
          metricsDisplay[`${platform}-${mediaType}-${metricType}_value`];
        if (
          PLACEMENT_COLUMNS.includes(
            `${platform}-${mediaType}-${metricType}_value`,
          ) &&
          metricDisplay &&
          value
        ) {
          metrics.push({
            metricType,
            platform,
            mediaType,
            value,
            label: normalizeName(metricDisplay.label, mediaType, platform),
            format: normalizeFormat(metricDisplay.format),
          });
        }
      },
    );
  });

  return metrics
    .sort((metricA: Metric, metricB: Metric) => metricB.value - metricA.value)
    .filter(Boolean);
};

export default getMetrics;
