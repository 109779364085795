import React, { useEffect, useRef } from 'react';
import { bool, func, shape, arrayOf, objectOf } from 'prop-types';
import Message from 'components/Chat/Message/Message';
import './List.scss';

const cbn = 'chat-messages-list';

function ChatMessagesList({
  editingMessages,
  messages,
  onDeleteMessage,
  onUpdateMessage,
  onToggleEdit,
  user,
}) {
  const wrapperRef = useRef(null);
  const prevMessagesRef = useRef();

  function scrollToBottom(smooth) {
    if (wrapperRef.current) {
      if ('scrollTo' in wrapperRef.current) {
        wrapperRef.current.scrollTo({
          top: wrapperRef.current.scrollHeight,
          behavior: smooth ? 'smooth' : undefined,
        });
      } else {
        wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
      }
    }
  }

  useEffect(() => {
    const prevLength =
      prevMessagesRef.current && prevMessagesRef.current.length;
    if (!prevLength && messages.length) {
      scrollToBottom();
    }

    if (prevLength && messages.length > prevLength) {
      scrollToBottom(true);
    }

    prevMessagesRef.current = messages;
  }, [messages]);

  return (
    <div ref={wrapperRef} className={cbn}>
      {messages.length ? (
        messages.map((m) => (
          <Message
            key={m.id}
            action={m.action}
            authorId={m.author.id}
            authorName={m.authorSnapshot.name}
            authorRole={m.authorSnapshot.role}
            clientName={m.authorSnapshot.client}
            createdAt={m.createdAt}
            id={m.id}
            isDeleted={m.isDeleted}
            isEditing={Boolean(editingMessages[m.id])}
            message={m.message}
            onDeleteMessage={() => onDeleteMessage(m.id)}
            onToggleEdit={() => onToggleEdit(m.id)}
            onUpdateMessage={(inputValue) =>
              onUpdateMessage(m, inputValue, m.id)
            }
            permissions={m.permissions}
            updatedAt={m.updatedAt}
            user={user}
            visibility={m.visibility}
          />
        ))
      ) : (
        <div className={`${cbn}__no-messages`}>
          <p>There are no messages yet.</p>
        </div>
      )}
    </div>
  );
}

ChatMessagesList.propTypes = {
  editingMessages: objectOf(bool),
  messages: arrayOf(shape({})),
  onDeleteMessage: func,
  onUpdateMessage: func,
  onToggleEdit: func,
  user: shape({}),
};

ChatMessagesList.defaultProps = {
  editingMessages: {},
  messages: [],
  onDeleteMessage: () => {},
  onUpdateMessage: () => {},
  onToggleEdit: () => {},
  user: {},
};

export default ChatMessagesList;
