import React, { useContext, useLayoutEffect } from 'react';
import { any, oneOfType, node, arrayOf, string, bool } from 'prop-types';
import classnames from 'classnames';
import FormTableOfContentsContext from 'components/Form/TableOfContents/Context';
import Context from './Context';

import './Section.scss';

const cbn = 'form-section';

function FormSection({
  children,
  className,
  title,
  noTableOfContents,
  noRegister,
  addon,
  description,
}) {
  const formTableOfContents = useContext(FormTableOfContentsContext);

  useLayoutEffect(() => {
    if (!noRegister) {
      formTableOfContents.registerSection();
    }

    return () => {
      if (!noRegister) {
        formTableOfContents.unregisterSection();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasHeader = title || addon;

  return (
    <div
      name={title}
      className={classnames(cbn, className, {
        [`${cbn}--no-toc`]: noTableOfContents,
        [`${cbn}--no-title`]: !title,
      })}
      role="group"
    >
      {hasHeader && (
        <div className={`${cbn}__header`}>
          <div className={`${cbn}__header-title`}>
            {title && <div className={`${cbn}__title`}>{title}</div>}
            {addon && <div className={`${cbn}__addon`}>{addon}</div>}
          </div>
          {description && (
            <div className={`${cbn}__description`}>{description}</div>
          )}
        </div>
      )}
      <div className={`${cbn}__content`}>
        <Context.Provider value={{ name: title }}>{children}</Context.Provider>
      </div>
    </div>
  );
}

FormSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  addon: any,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  className: string,
  title: string,
  noTableOfContents: bool,
  noRegister: bool,
  description: string,
};

FormSection.defaultProps = {
  addon: null,
  className: null,
  title: null,
  noTableOfContents: false,
  noRegister: false,
  description: null,
};

export default FormSection;
