import React from 'react';
import './ToggleLabel.scss';

export default function ToggleLabel({
  label,
  toggled,
  onClick,
  optionsObject,
  toggleKey,
}: {
  label: string;
  toggled: boolean;
  onClick: (tab: string, optionsObject: unknown) => void;
  optionsObject: unknown;
  toggleKey: string;
}) {
  const handleClick = React.useCallback(() => {
    onClick(toggleKey, optionsObject);
  }, [toggleKey, optionsObject]);

  return (
    <button
      className={`toggle-label ${toggled ? 'open' : 'closed'}`}
      type="button"
      onClick={handleClick}
      tabIndex={0}
    >
      <span>{label}</span>
    </button>
  );
}
