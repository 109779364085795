import storage from 'utils/storage';

import * as Sentry from '@sentry/react';

export const CREATOR_CAMPAIGN_COLLAPSED = '$BEN$--creatorCampaignCollapsed';
export const ACCESS_TOKEN = '$BEN$--accessToken';
export const PERMISSIONS = '$BEN$--permissions';
export const VERSIONING = '$BEN$--versioning';
export const USER = '$BEN$--user';
export const ISB_TOKEN_KEY = '$ISB--token';
export const ISB_USER = '$ISB--user';

export function setSentryUser(user) {
  if (!user) {
    return Sentry.setUser();
  }

  return Sentry.setUser({
    email: user.email,
    id: user.id,
    role: user.role,
    firstName: user.firstName,
    lastName: user.lastName,
  });
}

export const getPermissions = () => storage.get(PERMISSIONS);
export const getToken = () => storage.get(ACCESS_TOKEN);
export const getUser = () => storage.get(USER);
export const getISBToken = () => storage.get(ISB_TOKEN_KEY);
export const getISBUser = () => storage.get(ISB_USER);
export const getVersioning = () => storage.get(VERSIONING);

export const setISBToken = (token) => storage.set(ISB_TOKEN_KEY, token);
export const setISBUser = (user) => storage.set(ISB_USER, user);

export const destroy = () => {
  Sentry.setUser(null);

  storage.remove(USER);
  storage.remove(ACCESS_TOKEN);
  storage.remove(PERMISSIONS);
};

export const create = ({ user, accessToken, permissions, versioning }) => {
  setSentryUser(user);
  storage.set(USER, user);
  storage.set(ACCESS_TOKEN, accessToken);
  storage.set(PERMISSIONS, permissions);
  storage.set(VERSIONING, versioning);
};

export const updateUser = (user) => {
  storage.set(USER, user);
};

export const updateVersioning = (versioning) => {
  storage.set(VERSIONING, versioning);
};
export const updatePermissions = (permissions) =>
  storage.set(PERMISSIONS, permissions);
