import axios from 'axios';
import qs from 'qs';
import storage from 'utils/storage';

const escapeParams = (params) => {
  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'object') {
      params[key] = { ...params[key] }; // eslint-disable-line no-param-reassign
      escapeParams(params[key]);
    } else if (key === '$regex' && params[key]) {
      // eslint-disable-line no-param-reassign
      params[key] = `.*${params[key].replace(
        /[-[\]{}()*+?.,\\^$|#\s]/g,
        '\\$&',
      )}.*`;
    }
  });
};

const api = axios.create({
  baseURL: '/api',
  paramsSerializer(params = {}) {
    escapeParams(params);
    return qs.stringify(params);
  },
});

api.interceptors.request.use((config) => {
  const headers = config.headers || {};
  const bearerToken = storage.get('$BEN$--accessToken');
  if (bearerToken) {
    headers.authorization = `Bearer ${bearerToken}`;
  }

  return {
    ...config,
    headers: {
      ...headers,
      'Current-Url': window.location.hash,
    },
  };
});

export default api;
