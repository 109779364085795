import { Analytics } from '@segment/analytics-next';
import { Production } from 'components/Production/types';
import { Property } from 'components/Property/types';
import React from 'react';
import {
  INSTAGRAM_PLATFORM,
  YOUTUBE_PLATFORM,
  TIKTOK_PLATFORM,
} from 'shared/metrics/metrics';
import TikTok from 'components/Creator/Productions/Card/TikTok/TikTok';
import YouTube from 'components/Creator/Productions/Card/YouTube/YouTubeCard';
import Instagram from 'components/Creator/Productions/Card/Instagram/Instagram';
import RowHeader from 'components/Creator/Productions/Card/RowHeader/RowHeader';
import { Campaign } from 'components/Campaign/types';
import useCard from 'components/Creator/Productions/Card/hooks/useCard';
import './Card.scss';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import trackMissingDemographics from './utils/segment/trackMissingDemographics';

function trackSegment(
  type: 'channel' | 'production',
  segment: Analytics,
  productionType: string,
  property: Property,
  campaignId: number,
  userId: number,
  tab: string,
) {
  segment.track(
    SegmentEvents.CreatorProfile,
    {
      action:
        type === 'channel'
          ? SegmentActions.CardOpenedChannel
          : SegmentActions.CardOpenedProduction,
      tab,
      platform: productionType,
      property: {
        id: property?.id,
        name: property?.name,
      },
    },
    {
      context: { groupId: formatCampaignId(campaignId) },
      userId: formatUserId(userId),
    },
  );
}

export default function Card({
  production,
  tab,
  userId,
  campaign,
  property,
  openProductionFn,
  showRecentMedia = true,
  apolloClient,
  initialOpenPlatforms,
}: {
  production: Production;
  tab?: string;
  userId?: number;
  campaign?: Campaign;
  property?: Property;
  showRecentMedia: boolean;
  openProductionFn?: (arg0: number | undefined) => void;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  initialOpenPlatforms: string[];
}) {
  const { handleCollapse, collapsed, trackChannelOpen, segment } = useCard(
    campaign,
    userId,
    tab,
    production,
    property,
    initialOpenPlatforms,
  );

  const sendSegmentEvent = React.useCallback(
    (type: 'channel' | 'production') => {
      if (
        !segment ||
        !production?.type ||
        !property ||
        !campaign?.id ||
        !userId ||
        !tab
      ) {
        return;
      }
      trackSegment(
        type,
        segment,
        production?.type,
        property,
        campaign?.id,
        userId,
        tab,
      );
    },
    [segment, production, property, campaign?.id, userId, tab],
  );

  const onTrackMissingDemographics = React.useCallback(() => {
    if (!segment || !property || !campaign?.id || !userId) {
      return;
    }
    trackMissingDemographics(
      segment,
      property,
      production,
      campaign?.id,
      userId,
    );
  }, [segment, property, production, campaign?.id, userId]);

  const trackProductionOpen = React.useCallback(() => {
    sendSegmentEvent('production');
  }, [sendSegmentEvent]);

  return (
    <article className={`creator-profile-card ${production?.type}`}>
      <RowHeader
        production={production}
        handleCollapse={handleCollapse}
        collapsed={collapsed}
        trackChannelOpen={trackChannelOpen}
      />

      <section
        className={`creator-profile-card__details ${collapsed ? 'hidden' : ''}`}
      >
        {!collapsed ? (
          <p className="production-link">
            Production link:{' '}
            {openProductionFn ? (
              <BaseButton
                onClick={() => openProductionFn(production?.id)}
                type="button"
                variant="text"
                color="purple"
                text={`#${production?.id}`}
              />
            ) : (
              <a
                href={`/team/productions/${production?.id}/edit`}
                target="_blank"
                onClick={trackProductionOpen}
                rel="noreferrer"
              >
                <span>{`#${production?.id}`}</span>
              </a>
            )}
          </p>
        ) : null}

        {!collapsed && production?.type === YOUTUBE_PLATFORM ? (
          <YouTube
            production={production}
            property={property}
            campaign={campaign}
            userId={userId}
            segment={segment}
            apolloClient={apolloClient}
          />
        ) : null}

        {!collapsed && production?.type === INSTAGRAM_PLATFORM ? (
          <Instagram
            production={production}
            apolloClient={apolloClient}
            showRecentMedia={showRecentMedia}
          />
        ) : null}

        {!collapsed && production?.type === TIKTOK_PLATFORM ? (
          <TikTok
            production={production}
            showRecentMedia={showRecentMedia}
            trackMissingDemographics={onTrackMissingDemographics}
          />
        ) : null}
      </section>
    </article>
  );
}
