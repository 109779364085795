import { Platforms } from 'shared/types/platforms.d';

export const PlatformLabel: { [key: string]: string } = {
  youtube: 'YouTube',
  instagram: 'Instagram',
  twitter: 'Twitter',
  facebook: 'Facebook',
  twitch: 'Twitch',
  tiktok: 'TikTok',
  other: 'Other',
};

export const PlatformIconType: { [key: string]: Platforms } = {
  instagram: Platforms.instagram,
  youtube: Platforms.youtube,
  facebook: Platforms.facebook,
  twitter: Platforms.twitter,
  twitch: Platforms.twitch,
  tiktok: Platforms.tiktok,
  other: Platforms.other,
};
