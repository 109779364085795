import React from 'react';
import { Link } from 'react-router-dom';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import benLogo from 'components/assets/img/ben_logo.svg';
import UIButton from 'components/UI/Button/Button';

import './NotFound404.scss';

const CONTACT_EMAIL = 'servicedesk@bengroup.com';
const CONTACT_PARAMS = 'subject=Tech%20Support&body=How may we assist you?';
const CONTACT = `mailto:${CONTACT_EMAIL}?${CONTACT_PARAMS}`;

function ScreensNotFound404() {
  return (
    <div className="not-found__wrapper">
      <DocumentTitle>404 Page Not Found</DocumentTitle>
      <div className="not-found">
        <Link className="not-found__logo" to="/campaigns/list">
          <img src={benLogo} alt="BEN Logo" />
        </Link>
        <div className="not-found__content">
          <h3>Blame it on the smog.</h3>
          <p>The page you were looking for was not found.</p>
          <br />
          <UIButton to="/campaigns" theme="contained-red">
            Home
          </UIButton>
        </div>
        <br />
        <br />
        <p>
          Please try again later, or
          <a href={CONTACT}>&nbsp;contact us&nbsp;</a>
          for help.
        </p>
        <p className="not-found__copyright">&copy; BEN Group</p>
      </div>
    </div>
  );
}

export default ScreensNotFound404;
