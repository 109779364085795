import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { errorStream } from 'store/errorHandler';
import { getMainDefinition } from 'apollo-utilities';
import removeSensitiveData from './removeSensitiveData/removeSensitiveData';
import introspectionQueryResultData from './fragmentTypes.json';

const wsPath = () => {
  if (process.env.WS_PATH) {
    return process.env.WS_PATH;
  }

  const { hostname } = window.location;
  if (hostname === 'localhost') {
    return 'ws://127.0.0.1:7002/ws';
  }
  if (hostname === 'test.goben.rocks') {
    return `ws://${hostname}:7002/ws`;
  }
  return `wss://${hostname}/api/ws`;
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const errors = [];

  if (operation.variables.CUSTOM_ERROR_MODAL) {
    // The only way to communicate between a useMutation/useQuery and
    // this function is through Variables
    return;
  }
  if (operation.variables.DO_NOT_THROW) {
    // The only way to communicate between a useMutation/useQuery and
    // this function is through Variables
    return;
  }

  if (operation.operationName === 'Preset') {
    // a user could have a deleted preset saved in localstorage.
    // In that case, we ignore the not found error.
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      errors.push({
        type: 'graphQLError',
        error,
      });
    });
  }

  if (!graphQLErrors && networkError) {
    errors.push({
      type: 'networkError',
      error: networkError,
    });
  }

  if (errors.length) {
    errorStream.dispatch(...errors);
  }
});

const wsErrorLink = onError(({ graphQLErrors, networkError }) => {
  const errors = [];

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      errors.push({
        type: 'graphQLError',
        error: {
          ...error,
          extensions: {
            ...error.extensions,
            exception: {
              code: error.extensions.code,
              title: 'MESSAGE ERROR',
            },
          },
        },
      });
    });
  }

  if (!graphQLErrors && networkError) {
    errors.push({
      type: 'networkError',
      error: {
        ...networkError,
        extensions: {
          ...(networkError?.extensions || {}),
          exception: {
            code: networkError?.extensions?.code || '',
            title: 'MESSAGE ERROR',
          },
        },
      },
    });
  }

  if (errors.length) {
    errorStream.dispatch(...errors);
  }
});

const buildClient = (token, permissions) => {
  const httpLink = createHttpLink({
    uri: '/api/graphql',
  });

  const wsLink = token
    ? new WebSocketLink({
        uri: wsPath(),
        options: {
          reconnect: true,
          connectionParams: {
            authorization: `Bearer ${token}`,
          },
          connectionCallback: (error) => {
            if (error?.message === 'Invalid bearer token') {
              wsLink?.subscriptionClient?.close();
            }
          },
        },
      })
    : null;

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  const cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: (object) => {
      /* eslint-disable-next-line no-underscore-dangle */
      if (object.__typename === 'Email') {
        return object.messageId;
      }

      if (object.id < 0 || object.id === null) {
        return Object.values(object).join('-');
      }
      return object.id;
    },
  });

  const link = token
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        ApolloLink.from([wsErrorLink, wsLink]),
        ApolloLink.from([authLink, errorLink, httpLink]),
      )
    : ApolloLink.from([authLink, errorLink, httpLink]);

  const client = new ApolloClient({
    link,
    cache,

    defaultOptions,
    connectToDevTools: true,
  });

  removeSensitiveData(client, permissions);

  return client;
};

export default buildClient;
