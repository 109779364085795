import React, { useLayoutEffect, useRef } from 'react';
import { children as childrenType } from 'components/utils/customPropTypes';
import './FullContainer.scss';

const cbn = 'ui-full-container';

function UIFullContainer({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray | null;
}) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (wrapperRef?.current?.getBoundingClientRect) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const freeBottomSpace = window.innerHeight - rect.top - 20;

      wrapperRef.current.style.height = `${freeBottomSpace}px`;
    }
  });

  return (
    <div ref={wrapperRef} className={cbn}>
      {children}
    </div>
  );
}

UIFullContainer.propTypes = {
  children: childrenType,
};

UIFullContainer.defaultProps = {
  children: null,
};

export default UIFullContainer;
