import { formatValue } from '../../utils/formatters';
import { MetricsTableRow } from '../types';

export default function MetricRow({ row }: { row: MetricsTableRow }) {
  if (row?.type === 'link') {
    return (
      <div className="simple-metrics-table__row">
        <a
          className="label-link external"
          href={String(row?.value)}
          target="_blank"
          rel="noreferrer"
        >
          {row?.label}
        </a>
      </div>
    );
  }
  return (
    <div className="simple-metrics-table__row">
      <span className="simple-metrics-table__row__label">{row?.label}</span>
      <span className="simple-metrics-table__row__value">
        {' '}
        {formatValue(row?.value as number, row?.type)}
      </span>
    </div>
  );
}
