import CellsLabelWithIcon from 'components/DataGrid/Cells/LabelWithIcon/LabelWithIcon';
import { StatsPlacement } from 'components/Campaign/Influencer/View/Stats/types.d';
import { getDataCy } from 'components/Campaign/utils';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import { Placement } from 'shared/types/Placement';

export default function followUp() {
  return {
    ...baseColDef,
    field: 'followUp',
    headerName: 'Follow up',
    valueGetter: ({ data }: { data: Placement }) =>
      data?.integration?.deliverable?.followUp ?? null,
    cellRenderer: CellsLabelWithIcon.componentName,
    cellRendererParams: ({ data }: { data: StatsPlacement }) => ({
      icon: data?.integration?.deliverable?.followUp ? 'check' : '',
      label: '',
      dataCy: getDataCy(data, 'followUp'),
    }),
  };
}
