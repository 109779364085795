import { Placement } from 'shared/types/Placement';
import {
  INSTAGRAM_PLATFORM,
  TIKTOK_PLATFORM,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/metrics';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';

import getInstagramDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/instagram/helpers/getInstagramDataFromCampaignService';
import basePerformanceColDef from './helpers/baseColDef';
import getTikTokDataFromInsights from './tikTok/helpers/getTikTokDataFromInsight';
import getYouTubeDataFromCampaignService from './youTube/helpers/getYouTubeDataFromCampaignService';

const metric = 'likes';

export function likesAutomatedValueGetter(data: Placement) {
  if (data?.platform === TIKTOK_PLATFORM) {
    return getTikTokDataFromInsights(
      data,
      'fct_lifetime_video_performance.num_likes',
    );
  }
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric);
  }
  if (data?.platform === INSTAGRAM_PLATFORM) {
    return getInstagramDataFromCampaignService(data, metric);
  }
  return null;
}

export default function likes() {
  return {
    ...numberColDef,
    ...basePerformanceColDef(metric, likesAutomatedValueGetter),
    headerName: 'Likes',
  };
}
