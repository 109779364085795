import { Tooltip } from 'react-tippy';
import UINumber from 'components/UI/Number/Number';
import DetailsPanelMetric from '../Metric/Metric';
import PlatformTitle from '../PlatformTitle/PlatformTitle';

type DeliverableDetailsPanelTwitterProps = {
  followers?: number;
  likes?: number;
  onRedirectClick: () => void;
  title: string;
  url?: string;
};

function DeliverableDetailsPanelTwitter({
  followers,
  onRedirectClick,
  title,
  url,
}: DeliverableDetailsPanelTwitterProps) {
  return (
    <div className="external-content__detail-panel__channel">
      <PlatformTitle
        onClick={onRedirectClick}
        platform="twitter"
        title={title}
        url={url}
      />
      <div className="external-content__detail-panel__channel__info">
        {followers && (
          <div className="external-content__detail-panel__channel__data__wrapper">
            <div className="external-content__detail-panel__channel__data__content">
              <DetailsPanelMetric label="Followers">
                {/* @ts-ignore */}
                <Tooltip
                  html={<UINumber>{followers}</UINumber>}
                  position="bottom"
                  theme="dark"
                  size="big"
                  arrow
                  animation="shift"
                >
                  <UINumber format="0a">{followers}</UINumber>
                </Tooltip>
              </DetailsPanelMetric>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeliverableDetailsPanelTwitter;
