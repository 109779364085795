import { Tooltip } from 'react-tippy';
import UINumber from 'components/UI/Number/Number';
import DetailsPanelMetric from '../Metric/Metric';
import PlatformTitle from '../PlatformTitle/PlatformTitle';

type DeliverableDetailsPanelFacebookProps = {
  followers?: number;
  likes?: number;
  onRedirectClick: () => void;
  title: string;
  url?: string;
};

function DeliverableDetailsPanelFacebook({
  followers,
  likes,
  onRedirectClick,
  title,
  url,
}: DeliverableDetailsPanelFacebookProps) {
  return (
    <div className="external-content__detail-panel__channel">
      <PlatformTitle
        onClick={onRedirectClick}
        platform="facebook"
        title={title}
        url={url}
      />
      <div className="external-content__detail-panel__channel__info">
        {(likes || followers) && (
          <div className="external-content__detail-panel__channel__data__wrapper">
            <div className="external-content__detail-panel__channel__data__content">
              {likes! > 0 && (
                <DetailsPanelMetric label="Likes">
                  {/* @ts-ignore */}
                  <Tooltip
                    html={<UINumber>{likes}</UINumber>}
                    position="bottom"
                    theme="dark"
                    size="big"
                    arrow
                    animation="shift"
                  >
                    <UINumber format="0a">{likes}</UINumber>
                  </Tooltip>
                </DetailsPanelMetric>
              )}

              {followers! > 0 && (
                <DetailsPanelMetric label="Followers">
                  {/* @ts-ignore */}
                  <Tooltip
                    html={<UINumber>{followers}</UINumber>}
                    position="bottom"
                    theme="dark"
                    size="big"
                    arrow
                    animation="shift"
                  >
                    <UINumber format="0a">{followers}</UINumber>
                  </Tooltip>
                </DetailsPanelMetric>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeliverableDetailsPanelFacebook;
