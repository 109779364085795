import { arrayOf, element, func, node, oneOfType } from 'prop-types';
import addIsRequiredCheck from './addIsRequiredCheck';
import validateDate from './date';

export const child = oneOfType([node, element, func]);
export const children = oneOfType([arrayOf(child), child]);

const customPropTypes = {
  child,
  children,
  date: addIsRequiredCheck(validateDate),
};

export default customPropTypes;
