import './Avatar.scss';
import ColoredInitials from './utils/ColoredInitials';
import { getInitialsByName } from './utils/utils';
import getSrc from './utils/getSrc';

type AvatarProps = {
  size?: 'icon' | 'medium' | 'medium-large' | 'large';
  dynamic?: boolean;
  alt: string;
  thumb?: string;
  className?: string;
  name: string;
  imageError: boolean;
  setImageError: (arg0: boolean) => void;
};

export default function Avatar({
  size = 'medium',
  alt = '',
  thumb = '',
  className = '',
  name = '',
  imageError,
  setImageError,
  dynamic = false,
}: AvatarProps) {
  const initials = getInitialsByName(name);
  const image = getSrc({
    url: thumb,
    fallbackImage: 'placeholderImage',
    imagecachePreset: 'medium',
    showImageDefault: false,
  });

  return (
    <div data-cy="Avatar" className="avatar">
      {image && !imageError ? (
        <img
          className={`avatar-image ${size} ${className} ${
            dynamic ? 'dynamic' : ''
          }`}
          alt={alt}
          src={image}
          onError={() => setImageError(true)}
        />
      ) : (
        <ColoredInitials size={size} initials={initials} />
      )}
    </div>
  );
}
