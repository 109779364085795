export default function formatDateToLocal(
  date: string | null | Date | undefined,
  timeZone = 'en-US',
) {
  if (date === null || date === undefined) {
    return null;
  }

  try {
    const dateToFormat = new Date(date);
    if (!dateToFormat) {
      return null;
    }
    return Intl.DateTimeFormat(timeZone, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(dateToFormat);
  } catch (e) {
    return null;
  }
}
