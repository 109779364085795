import {
  STREAM_MEDIA_TYPE,
  TWITCH_PLATFORM,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import basePerformanceColDef from '../../helpers/baseColDef';
import getYouTubeDataFromCampaignService from '../helpers/getYouTubeDataFromCampaignService';

const metric = 'peakCCV';
export function peackCCVAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric);
  }
  return null;
}

export default function peakCCV() {
  return {
    ...basePerformanceColDef(
      metric,
      peackCCVAutomatedValueGetter,
      [YOUTUBE_PLATFORM, TWITCH_PLATFORM],
      [STREAM_MEDIA_TYPE],
    ),
    headerName: 'Peak CCV',
    width: 180,
  };
}
