import { Placement } from 'shared/types/Placement';
import { ColDef } from 'ag-grid-community/dist/lib/main';
import { ReactSelectOption } from 'shared/types/ReactSelect';
import { valueGetterObject } from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/utils/colDefFns';

import baseColDef from './baseColDef';

const dropdownColDef = {
  ...baseColDef,
  cellClass: 'ag-cell__custom-dropdown',
  cellEditor: 'agRichSelectCellEditor',
  cellEditorPopup: true,
  valueFormatter: ({
    value,
  }: {
    value?: { value?: string; label: string } | null;
  }) => value?.value ?? '',
  filter: 'agSetColumnFilter',
  filterParams: {
    valueGetter(props: { data: Placement; colDef: ColDef }) {
      const processedValue = valueGetterObject(props);
      const value = processedValue?.value !== 'none' ? processedValue : null;

      return value?.value;
    },
  },
  comparator: (
    firstOption: ReactSelectOption,
    secondOption: ReactSelectOption,
  ) =>
    (firstOption?.label ?? '')
      .toLowerCase()
      .localeCompare((secondOption?.label ?? '')?.toLowerCase()),
};

export default dropdownColDef;
