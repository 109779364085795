import React from 'react';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import UIPageTitle from 'components/UI/PageTitle/PageTitle';
import UIButton from 'components/UI/Button/Button';

import './Done.scss';

function ScreensUserChangePasswordDone() {
  return (
    <div className="change-password-done__wrapper">
      <DocumentTitle>Change Password</DocumentTitle>
      <UIPageTitle>Success!</UIPageTitle>

      <div className="change-password-done__content">
        <p>Your password has been changed.</p>
        <p>Please use this password next time you sign in.</p>
        <br />
        <UIButton to="/" theme="contained-green">
          Continue
        </UIButton>
      </div>
    </div>
  );
}

export default ScreensUserChangePasswordDone;
