import twitchCommon from './helpers/twitchCommon';

const metric = 'totalVODViews';
export default function totalVODViews() {
  return {
    ...twitchCommon(metric),
    headerName: 'Total VOD Views',
    width: 230,
  };
}
