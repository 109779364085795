import React from 'react';
import { bool, any } from 'prop-types';
import UILoader from 'components/UI/Loader/Loader';
import UIFullContainer from 'components/UI/FullContainer/FullContainer';
import UIPageError from 'components/UI/PageError/PageError';

function UIPageStatus({ loading, error }) {
  if (error) {
    return (
      <UIFullContainer>
        <UIPageError>Oops! An error occurred</UIPageError>
      </UIFullContainer>
    );
  }

  if (loading) {
    return (
      <UIFullContainer>
        <UILoader />
      </UIFullContainer>
    );
  }

  return null;
}

UIPageStatus.propTypes = {
  loading: bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: any,
};

UIPageStatus.defaultProps = {
  loading: false,
  error: null,
};

export default UIPageStatus;
