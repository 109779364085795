import './LinkButton.scss';

export default function LinkButton({
  label,
  solid,
  onClick,
}: {
  label: string;
  solid?: boolean;
  onClick?: () => void;
}) {
  return (
    <button
      type="button"
      className={`link-button ${solid ? 'solid' : ''}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
}
