import { CreatorColDef } from '../../types/Campaign';

export default function sortCreatorColDefsByIndex(
  colDefsArray?: CreatorColDef[],
) {
  if (!colDefsArray || !Array.isArray(colDefsArray)) {
    return [];
  }

  return [...colDefsArray].sort((a: CreatorColDef, b: CreatorColDef) => {
    if (!a?.index && !b?.index) {
      return -1;
    }

    if (Number.isNaN(Number(a?.index))) {
      return 1;
    }

    if (Number.isNaN(Number(b?.index))) {
      return -1;
    }
    return (a?.index ?? 0) - (b?.index ?? 0);
  });
}
