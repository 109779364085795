import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { Campaign } from 'components/Campaign/types';
import { User } from 'components/User/types';
import { Environment } from 'types';
import * as Sentry from '@sentry/react';
import { segmentKeys } from './segmentEnvironments';
import { formatCampaignId, formatUserId } from './utils';

export async function loadSegment(
  environment: Environment,
  setSegment: (arg0: Analytics) => void,
  host?: string,
  protocol?: string,
) {
  try {
    const { writeKey } = segmentKeys[environment];

    if (!writeKey) {
      return;
    }

    const [segment] = await AnalyticsBrowser.load(
      {
        writeKey,
        cdnURL: `${protocol ?? window.location.protocol}//${
          host ?? window.location.host
        }/api/segment-cdn`,
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: `${host ?? window.location.host}/api/segment-api/v1`,
            protocol: (protocol ?? window.location.protocol).replace(/:$/, ''),
          },
        },
      },
    );

    setSegment(segment);
  } catch (error) {
    Sentry.captureException(`Segment failed to load: ${error}`);
    const actions = {
      track: () => {},
      trackActions: () => {},
    } as unknown;
    setSegment(actions as Analytics);
  }
}

export function segmentPageCall(
  segment: Analytics,
  campaign?: Campaign,
  user?: User,
) {
  const category: string | undefined = undefined;
  const name: string | undefined = undefined;
  const properties: { load_time?: number | null } = {
    load_time: null,
  };

  const options = {
    context: { groupId: campaign?.id && formatCampaignId(campaign?.id) },
    userId: user?.id && formatUserId(user?.id),
  };

  segment.page(category, name, properties, options);
}
