import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useCampaignId() {
  const location = useLocation();
  return useMemo(() => {
    if (!location) {
      return null;
    }

    const id = location.pathname.match(/\/campaigns\/(\d*)(\/\w*)?/);
    try {
      return Number(id[1]) ?? null;
    } catch (e) {
      return null;
    }
  }, [location]);
}
