/*
 * split sort into sortBy and sortOrder
 * @param {String} sort - eg: name-asc
 * @returns {Object({ sortBy: string, sortOrder: asc|desc })}
 */
const splitSort = (sort) => {
  const results = sort && sort.match(/^(.+)-(asc|desc)$/);

  if (!results) {
    return {};
  }

  return {
    sortBy: results[1],
    sortOrder: results[2],
  };
};

export default splitSort;
