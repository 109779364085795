import find from 'lodash/find';
import { VOCABULARY_TYPES_OPTIONS } from './constants';

export const getVocabularyName = (vocabularyId) => {
  const vocabulary = find(VOCABULARY_TYPES_OPTIONS, { value: vocabularyId });

  return vocabulary ? vocabulary.label : 'N/A';
};

export const getFullTaxonomyName = (item) =>
  (item ? `${item.name} (${getVocabularyName(item.vocabulary)})` : 'N/A');
