import React from 'react';
import { StylesConfig } from 'react-select';
import { ReactSelectOption } from 'shared/types/ReactSelect';

export default function useReactSelectCustomStyles<
  OptionType = ReactSelectOption,
>() {
  const customStyles: StylesConfig<OptionType> = React.useMemo(
    () => ({
      control: (provided, state) => {
        const controlStyles = {
          minHeight: '2rem',
          height: '2rem',
          border: '0.063rem solid #b6bac6',
          fontSize: '0.875rem',
        };

        if (state?.isMulti && state?.getValue()?.length >= 2) {
          return {
            ...provided,
            ...{ ...controlStyles, minHeight: undefined, height: undefined },
          };
        }

        return {
          ...provided,
          ...controlStyles,
        };
      },
      placeholder: (provided) => ({
        ...provided,
        fontSize: '0.875rem',
        color: '#9EA3B3',
      }),
      valueContainer: (provided, state) => {
        if (state?.isMulti && state?.getValue()?.length >= 2) {
          return provided;
        }

        return {
          ...provided,
          height: '2rem',
        };
      },
      indicatorSeparator: () => ({
        display: 'none',
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: '2rem',
      }),
      option: (provided, state) => ({
        ...provided,
        color: '#262E46',
        fontSize: '0.875rem',
        backgroundColor:
          state?.isSelected || state?.isFocused ? '#EDEFF1' : 'white',
      }),
    }),
    [],
  );

  return {
    customStyles,
  };
}
