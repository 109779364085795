import React from 'react';
import { string, func, bool, oneOf, oneOfType, node } from 'prop-types';
import classNames from 'classnames';
import UIIcon from 'components/UI/Icon/Icon';

const propTypes = {
  id: string.isRequired,
  component: oneOfType([func, node]),
  sortable: bool,
  small: bool,
  onClick: func,
  sortOrder: oneOf(['asc', 'desc']),
  sortBy: string,
};

const defaultProps = {
  component: 'span',
  sortable: false,
  small: false,
  onClick: null,
  sortOrder: null,
  sortBy: null,
};

function UITableHCol({
  id,
  component: Component,
  sortable,
  sortOrder,
  small,
  onClick,
  sortBy,
  ...restProps
}) {
  return (
    <div
      className={classNames('ui-table__header-col', 'ui-table__col', {
        [`ui-table__col--${id}`]: id,
        [`ui-table__header-col--${id}`]: id,
        'is-sortable': sortable,
        'is-smaller': small,
      })}
      onClick={onClick}
    >
      <Component {...restProps} />
      {id && id === sortBy && (
        <UIIcon
          type={sortOrder === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down'}
        />
      )}
    </div>
  );
}

UITableHCol.propTypes = propTypes;
UITableHCol.defaultProps = defaultProps;

export default UITableHCol;
