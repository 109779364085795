import urlParser from 'js-video-url-parser';

export const isValidURL = (url) => {
  if (!url?.length) {
    return false;
  }

  return /^(https?:\/\/)(www\.)?\w*\.\w*\/?.*?/.test(url);
};

export const enforceScheme = (url) => {
  const urlHasScheme = url.match(/^http/);
  if (!urlHasScheme) {
    return `//${url}`;
  }

  return url;
};

export const getRandomisedUrl = (templateUrl, url) => {
  // create a semi random token to should be the same each time it is generated for
  // a given url.
  const token = url.charCodeAt(url.length - 6) % 5;
  return templateUrl.replace('_  _', token);
};

/* eslint-disable no-useless-escape */
const legacyUrlPattern = /^https?:\/\/media\.nmadirectdata\.com\.s3\-website\-us\-west\-2\.amazonaws\.com/g;
const legacyTemplateURL =
  // we're using '_  _' as a replacement token as it should never be used in a valid url
  'https://_  _.legacyfiles.productplacement.com';

const prodUrlPattern = /^https?:\/\/files-ben-productplacement-com\.s3-website-us-east-1\.amazonaws\.com/g;
const prodTemplateUrl = 'https://_  _.files.productplacement.com';

const stableUrlPattern = /^https?:\/\/files-ben-productplacement-com\.s3\.amazonaws\.com/g;

export const getLoadBalancedUrl = (url) => {
  if (!url) {
    return url;
  }

  if (url.match(legacyUrlPattern)) {
    return url.replace(
      legacyUrlPattern,
      getRandomisedUrl(legacyTemplateURL, url),
    );
  }

  if (url.match(prodUrlPattern)) {
    return url.replace(prodUrlPattern, getRandomisedUrl(prodTemplateUrl, url));
  }

  if (url.match(stableUrlPattern) && url.includes('imagecache')) {
    return url.replace(
      stableUrlPattern,
      getRandomisedUrl(prodTemplateUrl, url),
    );
  }
  return url;
};

export const isSupportedVideoUrl = (url) => {
  const providers = ['youtube', 'vimeo', 'twitch', 'wistia', 'dailymotion'];
  const types = ['mov', 'mp4', 'mkv', 'webm', 'ogg'];

  if (!url) {
    return false;
  }

  const parsed = urlParser.parse(url);

  if (parsed && providers.indexOf(parsed.provider) !== -1) {
    return true;
  }

  const ext = url.split('.').pop();

  return types.indexOf(ext.toLowerCase()) !== -1;
};

export default enforceScheme;
