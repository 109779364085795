import { string, shape, func } from 'prop-types';
import UINumber from 'components/UI/Number/Number';
import { PERFORMANCE_METRIC_WINDOW } from 'components/Placement/constants';
import { METRICS_DISPLAY } from 'components/Metrics/constants/metricTypes';
import { toKeysMap } from 'utils/array';
import { shortenPlatformLabel } from 'components/Metrics/utils';

import './Performance.scss';

const metrics = toKeysMap(METRICS_DISPLAY, 'id');
const metricWindows = toKeysMap(PERFORMANCE_METRIC_WINDOW, 'value');

const normalizeFormat = (format) =>
  (format.includes('%') ? format : '0,0[.]00');

function PlacementViewPerformance({
  cbn,
  summary,
  metricCountry,
  metricWindow,
  metricsDisplay,
  renderLabel,
  shouldRender,
}) {
  return (
    <>
      <div className={`${cbn}__content__block__title`}>
        Performance ({metricWindows[metricWindow].label}
        {metricCountry ? ` / (${metricCountry.toUpperCase()})` : ''})
      </div>
      <div className={`${cbn}__content`}>
        <div className={`${cbn}__performance__wrapper`}>
          {Object.entries(metricsDisplay)
            .sort((a, b) => a[1] - b[1])
            .map(
              ([key]) =>
                summary.actual &&
                summary.actual[key] > 0 &&
                shouldRender(`columns.${key}`) && (
                  <div key={key} className={`${cbn}__performance__col`}>
                    {/youtube/.test(`columns.${key}`.toLowerCase()) ??
                    /youtube/.test(metrics[key].label.toLowerCase()) ? (
                      <div
                        className={`${cbn}__performance__col__label youtube`}
                      >
                        <span className="label">YouTube</span>
                        <span className="metric">
                          {renderLabel(
                            `columns.${key}`,
                            metrics[key].label.replace(/youtube /i, ''),
                          )}
                        </span>
                      </div>
                    ) : (
                      <span className={`${cbn}__performance__col__label`}>
                        {`Global ${shortenPlatformLabel(
                          renderLabel(`columns.${key}`, metrics[key].label),
                        )}`}
                      </span>
                    )}
                    <b>
                      <UINumber format={normalizeFormat(metrics[key].format)}>
                        {summary.actual[key]}
                      </UINumber>
                    </b>
                    <span className="divider" />
                    {Object.entries(
                      summary?.actual?.[`${key}_countryBreakdown`] ?? {},
                    ).map(([countryLabel, countryValue]) => (
                      <>
                        <span
                          className={`${cbn}__performance__col__label country-breakdown`}
                        >
                          {`${countryLabel} ${shortenPlatformLabel(
                            renderLabel(`columns.${key}`, metrics[key].label),
                          )}`}
                        </span>
                        <UINumber format={normalizeFormat(metrics[key].format)}>
                          {countryValue}
                        </UINumber>
                      </>
                    ))}
                  </div>
                ),
            )}
          {summary.actual &&
            Object.values(summary.actual).filter((v) => v > 0).length === 0 && (
              <div className={`${cbn}__performance__col`}>No data yet</div>
            )}
        </div>
      </div>
    </>
  );
}

PlacementViewPerformance.propTypes = {
  cbn: string.isRequired,
  metricCountry: string,
  metricsDisplay: shape({}),
  metricWindow: string,
  summary: shape({}),
  renderLabel: func.isRequired,
  shouldRender: func.isRequired,
};

PlacementViewPerformance.defaultProps = {
  metricCountry: undefined,
  metricWindow: 'live7',
  summary: {},
  metricsDisplay: {},
};

export default PlacementViewPerformance;
