import {
  OnCreateAd,
  OnCloseModal,
  Period,
  SetPeriod,
} from 'components/Tiktok/TiktokSparkAds/CreateSparkAd/types';

import CreateSubmit from './CreateSubmit/CreateSubmit';
import CreateContent from './CreateContent/CreateContent';
import { SparkAdsSegmentEvents } from '../hooks/useSegmentEvents';

import './CreateSparkAd.scss';

export default function CreateSparkAd({
  contentLink,
  thumbnailUrl,
  onCreateAd,
  onCloseModal,
  period,
  setPeriod,
  hasError,
  segmentEvents,
}: {
  contentLink: string | undefined | null;
  thumbnailUrl: string | undefined | null;
  onCreateAd: OnCreateAd;
  onCloseModal: OnCloseModal;
  period: Period | null;
  setPeriod: SetPeriod;
  hasError: boolean;
  segmentEvents?: SparkAdsSegmentEvents;
}) {
  return (
    <section className="tiktok-spark-ads__create">
      {hasError ? (
        <p className="tiktok-spark-ads__create-spark-add__notice">
          There was an error trying validate the spark ad.
        </p>
      ) : (
        <>
          <p className="tiktok-spark-ads__create__notice">
            To use this content piece in paid media (as a &quot;Spark Ad&quot;),
            a request must be approved by the Creator. Please specify a time
            period for rights and usage, and &quot;Send Request&quot; below.
          </p>

          <CreateContent
            contentLink={contentLink}
            period={period}
            setPeriod={setPeriod}
            thumbnailUrl={thumbnailUrl}
            segmentEvents={segmentEvents}
          />

          <CreateSubmit
            period={period}
            onCreateAd={onCreateAd}
            onCloseModal={onCloseModal}
            segmentEvents={segmentEvents}
          />
        </>
      )}
    </section>
  );
}
