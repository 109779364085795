import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import UIPageStatus from 'components/UI/PageStatus/PageStatus';
import { Campaign } from 'components/Campaign/types';
import ExternalCampaignPlaceholderNoneEnabled from 'external/components/Campaign/Placeholder/NoneEnabled/NoneEnabled';
import ListHeader from 'external/components/UI/ListHeader/ListHeader';
import sortFunction from 'external/utils/sortFunction';
import useListLoader from 'external/utils/useListLoader';
import BaseSection from '@UIComponents/BaseSection/BaseSection';
import CampaignCard from '../Card/Card';
import { CampaignsListQuery } from './graphql';

import './List.scss';

const cbn = 'external-campaign-list';

const defaultValues = {
  limit: 999999,
};

const listHeaderItems = [
  { title: '', value: 'thumb', gridArea: 'thumb' },
  { title: 'Campaign Name', value: 'projectName', gridArea: 'name' },
  { title: 'Start Date', value: 'dateRangeFrom', gridArea: 'start-date' },
  { title: 'End Date', value: 'dateRangeTo', gridArea: 'end-date' },
  { title: 'Status', value: 'status', gridArea: 'status' },
];

function sortData(campaigns: Campaign[] | undefined, sorting: string | null) {
  if (!campaigns) {
    return [];
  }

  if (!sorting) return campaigns;

  const sortFn = sortFunction(
    {
      projectName: 'projectName',
      dateRangeFrom: 'dateRangeFrom',
      dateRangeTo: 'dateRangeTo',
      status: 'status',
    },
    sorting,
  );

  return Array.from(campaigns).sort(sortFn);
}

function ExternalCampaignList() {
  const [sorting, setSorting] = useState<string | null>(null);
  const [sortBy, sortOrder] = sorting?.split('-') ?? [];

  const { data: campaigns } = useListLoader({
    entityName: 'campaigns',
    query: CampaignsListQuery,
    variables: {
      limit: defaultValues.limit,
      filter: {
        clientAccess: true,
        status: ['proposed', 'active', 'completed'],
      },
    },
  } as any);

  const sortedCampaigns = useMemo(() => sortData(campaigns, sorting), [
    sorting,
    campaigns,
  ]);

  if (!campaigns) {
    return <UIPageStatus loading={!campaigns} />;
  }

  return (
    /* @ts-ignore */
    <BaseSection title="All Campaigns" type="main">
      <div className="external-campaign-list__box">
        {!campaigns?.length ? (
          <ExternalCampaignPlaceholderNoneEnabled />
        ) : (
          <>
            <ListHeader
              setSorting={setSorting}
              sorting={sorting}
              sortBy={sortBy}
              sortOrder={sortOrder}
              items={listHeaderItems}
              gridTemplate="campaign"
            />
            <ul className={`${cbn}__list`}>
              {sortedCampaigns.map((campaign) => (
                <li key={campaign.id} className={`${cbn}__item`}>
                  <Link to={`/campaigns/${campaign.id}/summary`}>
                    <CampaignCard campaign={campaign} />
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </BaseSection>
  );
}

export default ExternalCampaignList;
