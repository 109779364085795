import { VIDEO_MEDIA_TYPE, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import basePerformanceColDef from '../../helpers/baseColDef';
import manualMetricsValueGetter from '../../helpers/manualMetricValueGetter';
import getYouTubeDataFromCampaignService from '../helpers/getYouTubeDataFromCampaignService';

const metric = 'likesDislikesRatio';
export default function likesDislikesRatio() {
  return {
    ...basePerformanceColDef(
      metric,
      () => null,
      [YOUTUBE_PLATFORM],
      [VIDEO_MEDIA_TYPE],
    ),
    valueGetter: ({ data, context }: { data: Placement; context: Context }) => {
      if (data?.platform !== YOUTUBE_PLATFORM) {
        return null;
      }
      const likes =
        getYouTubeDataFromCampaignService(data, 'likes') ??
        manualMetricsValueGetter(data, context, 'likes') ??
        null;
      const dislikes =
        getYouTubeDataFromCampaignService(data, 'dislikes') ??
        manualMetricsValueGetter(data, context, 'dislikes') ??
        null;

      const likesNumber = Number(likes);
      const dislikesNumber = Number(dislikes);
      if (
        Number.isNaN(likesNumber) ||
        Number.isNaN(dislikesNumber) ||
        !likesNumber ||
        !dislikesNumber
      ) {
        return '';
      }

      if (likesNumber > dislikesNumber) {
        return `${Math.round(Number(likes) / Number(dislikes))}:1`;
      }

      return `${0 - Math.round((Number(likes) / Number(dislikes)) * 100)}:1`;
    },
    editable: false,
    headerName: 'Likes / Dislikes Ratio',
    width: 255,
    filter: 'agTextColumnFilter',
  };
}
