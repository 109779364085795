import twitchCommon from './helpers/twitchCommon';

const metric = 'chatVolume';
export default function chatVolume() {
  return {
    ...twitchCommon(metric),
    headerName: 'Chat Volume',
    width: 200,
  };
}
