import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import AddOrRequestExternalReport from 'components/Campaign/Influencer/View/DomoIframe/AddOrRequestExternalReport/AddOrRequestExternalReport';
import UILoader from 'components/UI/Loader/Loader';
import AdditionalOptionsDropdown from '@UIComponents/Campaign/CampaignSetup/AdditionalOptionsDropdown/AdditionalOptionsDropdown';
import FramesWrapper from 'components/Campaign/Influencer/View/DomoIframe/FramesWrapper/FramesWrapper';
import usePermissions from 'store/authentication/usePermissions';
import { CampaignDomoIFrameMediaTargets } from './graphql';
import './DomoIframe.scss';

export default function DomoIframe({
  campaignId,
  showEdit,
}: Readonly<{
  campaignId: number;
  showEdit?: boolean;
}>) {
  const permissions = usePermissions('campaigns', campaignId);
  const { data, refetch } = useQuery(CampaignDomoIFrameMediaTargets, {
    variables: {
      id: campaignId,
      mediaConfig: [{ allMetrics: true }],
      campaignsSeeSensitiveData: Boolean(permissions?.seeSensitiveData),
    },
  });

  const [editEmbedId, setEditEmbedId] = React.useState(false);

  if (!data?.campaign?.id) {
    return <UILoader />;
  }

  return (
    <section className="external-report-tab">
      {showEdit === true && data?.campaign?.clientReportEmbed ? (
        <nav>
          <AdditionalOptionsDropdown
            options={[
              {
                label: 'Edit Embed Id',
                onClick: () => setEditEmbedId(true),
              },
            ]}
          />
        </nav>
      ) : null}
      <div className="iframe-wrapper">
        {showEdit === true &&
        (editEmbedId || !data?.campaign?.clientReportEmbed) ? (
          <AddOrRequestExternalReport
            campaign={data?.campaign}
            refetch={refetch}
            setEditEmbedId={setEditEmbedId}
          />
        ) : null}
        {data?.campaign?.clientReportEmbed && !editEmbedId ? (
          <FramesWrapper campaign={data?.campaign} />
        ) : null}
      </div>
    </section>
  );
}
