import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import {
  INSTAGRAM_PLATFORM,
  STORY_MEDIA_TYPE,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/kpis';
import { Placement } from 'shared/types/Placement';
import { QmMetrics } from 'shared/types/Production';

export default function aiViews() {
  return {
    ...baseColDef,
    field: 'aiViews',
    headerName: 'AI Views',
    valueGetter: ({ data }: { data: Placement }) => {
      const productionType = data?.integration?.production?.type;
      if (productionType === YOUTUBE_PLATFORM) {
        return (data?.integration?.production?.qmMetrics as QmMetrics[])?.[0]
          ?.attributes?.['youtube-views-inference']?.values?.predicted_views;
      }
      if (
        productionType === INSTAGRAM_PLATFORM &&
        data?.mediaType === STORY_MEDIA_TYPE
      ) {
        return (data?.integration?.production?.qmMetrics as QmMetrics[])?.[0]
          ?.attributes?.['eureka-ig-views-inference']?.values
          ?.story_view_prediction;
      }
      return null;
    },
  };
}
