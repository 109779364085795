import FigmaIcon from '@UIComponents/FigmaIcon/FigmaIcon';

import './AiRecommendationIcon.scss';

function AiRecommendationIcon() {
  return (
    <div className="AiRecommendationIcon">
      <FigmaIcon icon="rocket-01" type="solid" size="x-small" color="white" />
    </div>
  );
}

export default AiRecommendationIcon;
