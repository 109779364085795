import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/react-hooks';
import FormFileUploader from 'components/Form/FileUploader/FileUploader';
import MediaFile from 'components/Form/FileUploader/MediaFile/MediaFile';
import MediaFileList, {
  FormFileUploaderMediaFileListProps,
} from 'components/Form/FileUploader/MediaFileList/MediaFileList';
import { validateUploads } from 'components/utils/file';
import React from 'react';
import {
  FileType,
  MediaFileComponentProps,
  ValueType,
} from 'components/Form/FileUploader/types';
import CreatorCampaignPlacementScreenshots from '../../graphql/CreatorCampaignPlacementScreenshots.graphql';
import CreatorCampaignPlacementScreenshotsUpdate from '../../graphql/CreatorCampaignPlacementScreenshotsUpdate.graphql';

const validationSchema = Yup.object({
  statsScreenshots: Yup.array(
    Yup.object({
      id: Yup.number(),
      filename: Yup.string().required(),
      mime: Yup.string().required(),
      size: Yup.number(),
      url: Yup.string().required(),
      created: Yup.string(),
    }),
  ).nullable(),
});
export default function MediaReview({ id }: { id?: number }) {
  const { data, refetch } = useQuery(CreatorCampaignPlacementScreenshots, {
    skip: !id,
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [innerMedia, setInnerMedia] = React.useState<FileType[] | null>();
  React.useEffect(() => {
    setInnerMedia(data?.placement?.statsScreenshots ?? []);
  }, [data?.placement?.statsScreenshots]);

  const [updatePlacement] = useMutation(
    CreatorCampaignPlacementScreenshotsUpdate,
  );
  const onChange = React.useCallback(async (statsScreenshots: ValueType) => {
    setInnerMedia(statsScreenshots as FileType[]);
  }, []);

  const onRemove = React.useCallback(
    async (statsScreenshots: ValueType | undefined) => {
      if (!statsScreenshots) {
        return;
      }
      setInnerMedia(statsScreenshots as FileType[]);
      updatePlacement({
        variables: {
          id,
          payload: validationSchema.validateSync(
            {
              statsScreenshots,
            },
            {
              stripUnknown: true,
            },
          ),
        },
      }).then(() => {
        refetch();
      });
    },
    [id, refetch, updatePlacement],
  );

  const onUploadComplete = React.useCallback(
    async (statsScreenshots: ValueType) => {
      setInnerMedia(statsScreenshots as FileType[]);
      if (validateUploads(statsScreenshots)) {
        updatePlacement({
          variables: {
            id,
            payload: validationSchema.validateSync(
              {
                statsScreenshots,
              },
              {
                stripUnknown: true,
              },
            ),
          },
        }).then(() => {
          refetch();
        });
      }
    },
    [id, refetch, updatePlacement],
  );
  const components = {
    File: MediaFile as unknown,
    FileList: MediaFileList as unknown,
  } as {
    File: React.Component<MediaFileComponentProps>;
    FileList: React.Component<FormFileUploaderMediaFileListProps>;
  };
  return (
    <section>
      <h2 className="campaign-setup__section-title">Upload Documents</h2>
      <FormFileUploader
        acceptMime={
          'image/jpeg, image/png, image/gif, video/mp4, video/*, video/vnd.youtube.yt'
        }
        axis="xy"
        isPrivate
        hasDragHandle
        resourceType="placement"
        resourceId={id}
        components={components}
        showLinkButton
        isMulti
        onUploadComplete={onUploadComplete}
        onChange={onChange}
        value={innerMedia}
        onRemove={onRemove}
      />
    </section>
  );
}
