import getFormattedDuration from 'shared/dates/getFormattedDuration';
import { VIDEO_MEDIA_TYPE, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import basePerformanceColDef from '../../helpers/baseColDef';
import getYouTubeDataFromCampaignService from '../helpers/getYouTubeDataFromCampaignService';

const metric = 'averageViewDuration';
export function averageViewDurationAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric);
  }
  return null;
}

export default function averageViewDuration() {
  return {
    ...basePerformanceColDef(
      metric,
      averageViewDurationAutomatedValueGetter,
      [YOUTUBE_PLATFORM],
      [VIDEO_MEDIA_TYPE],
    ),
    valueFormatter: ({ value }: { value: number | null }) =>
      getFormattedDuration(value),
    headerName: 'Average View Duration',
    width: 275,
  };
}
