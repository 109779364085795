import { Platforms } from 'shared/types/platforms.d';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const mediaConfig = [
  {
    mediaType: 'video',
    style: 'integration',
    platform: 'youtube',
  },
  {
    mediaType: 'video',
    style: 'thematic',
    platform: 'youtube',
  },
  {
    mediaType: 'video',
    style: 'dedicated',
    platform: 'youtube',
  },
  {
    mediaType: 'stream',
    style: null,
    platform: 'youtube',
  },
  {
    mediaType: 'video',
    style: null,
    platform: 'instagram',
  },
  {
    mediaType: 'story',
    style: null,
    platform: 'instagram',
  },
  {
    mediaType: 'photo',
    style: null,
    platform: 'instagram',
  },
  {
    mediaType: 'reels',
    style: null,
    platform: 'instagram',
  },
  {
    mediaType: 'live',
    style: null,
    platform: 'instagram',
  },
  {
    mediaType: 'video',
    style: null,
    platform: 'twitter',
  },
  {
    mediaType: 'post',
    style: null,
    platform: 'twitter',
  },
  {
    mediaType: 'video',
    style: null,
    platform: 'facebook',
  },
  {
    mediaType: 'post',
    style: null,
    platform: 'facebook',
  },
  {
    mediaType: 'live',
    style: null,
    platform: 'facebook',
  },
  {
    mediaType: 'stream',
    style: null,
    platform: 'twitch',
  },
  {
    mediaType: 'video',
    style: null,
    platform: 'tiktok',
  },
  {
    mediaType: 'video',
    style: null,
    platform: 'other',
  },
  {
    mediaType: 'story',
    style: null,
    platform: 'other',
  },
  {
    mediaType: 'stream',
    style: null,
    platform: 'other',
  },
  {
    mediaType: 'post',
    style: null,
    platform: 'other',
  },
  {
    mediaType: 'photo',
    style: null,
    platform: 'other',
  },
];

export interface IAgreements {
  campaign: {
    id: number;
    dateRangeFrom: string;
    dateRangeTo: string;
    targetAllocations: number;
    targetViews: number;
    mediaTargets: [
      {
        platform: keyof typeof Platforms;
        style: string;
        mediaType: string;
      },
    ];
  };
}

export type AgreementCampaign = IAgreements['campaign'];

const agreementsGql = gql`
  query CampaignAgreement($id: Int!, $mediaConfig: [MediaConfig!]!) {
    campaign(id: $id) {
      id
      status
      projectName
      dateRangeFrom
      dateRangeTo

      targetAllocations
      targetViews

      mediaTargets(mediaConfig: $mediaConfig) {
        id
        platform
        style
        mediaType
      }
    }
  }
`;

function AgreementService(campaignId: number): AgreementCampaign | null {
  const { data } = useQuery<IAgreements>(agreementsGql, {
    variables: {
      id: campaignId,
      mediaConfig,
    },
  });

  if (!data) return null;

  return data.campaign;
}

export default AgreementService;
