import React from 'react';
import './BaseSection.scss';

type BaseSectionProps = {
  subtitle?: string;
  title?: string;
  type: 'main' | 'details' | 'transparent';
  wide?: boolean;
  parentClassName?: string;
  align?: 'left' | 'center' | 'right';
};

const BaseSection: React.FC<BaseSectionProps> = ({
  /* @ts-ignore */
  children,
  subtitle,
  title,
  type = 'main',
  wide = false,
  parentClassName = '',
  align = 'left',
}) => {
  const isWide = wide ? 'BaseSection-wide' : '';

  return (
    <div
      className={`BaseSection BaseSection-${type} ${isWide} ${align} ${parentClassName}`}
      data-cy="BaseSection"
    >
      <div className="content-wrap">
        {title || subtitle ? (
          <div className="title-wrap">
            {title ? <h1 data-cy="base-section-title">{title}</h1> : null}
            {subtitle ? <p>{subtitle}</p> : null}
          </div>
        ) : null}
        {/* @ts-ignore */}
        <div className="children-wrap">{children}</div>
      </div>
    </div>
  );
};

export default BaseSection;
