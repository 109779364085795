import { Production } from 'components/Production/types';

export function orderProductionsByType(productions: Production[] = []) {
  const typeOrder = ['instagram', 'youtube', 'tiktok', 'twitch'];

  return structuredClone(productions).sort((a: Production, b: Production) => {
    const indexA = typeOrder.indexOf(a.type);
    const indexB = typeOrder.indexOf(b.type);

    // If both types are in the typeOrder array, sort them by their index in the array
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one of the types is in the typeOrder array, sort the one that is
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }

    return 0;
  });
}
