export default function getDateElementsAsArrayFromString(value: null | string) {
  if (value === null) {
    return null;
  }

  try {
    const dateElements = /(\d+)-(\d+)-(\d+)/.exec(value);
    /**
     * Remove 0 index match.
     */
    dateElements?.shift();
    /**
     * Validate we have three elements in the array.
     */
    if (dateElements?.length !== 3) {
      return null;
    }

    /**
     * Month in JS starts at 0.
     */
    return dateElements.map((ele, index) => {
      if (index === 1) {
        return Number(ele) - 1;
      }

      return Number(ele);
    });
  } catch (e) {
    return null;
  }
}
