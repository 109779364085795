import { string, number, func, shape } from 'prop-types';
import UINumber from 'components/UI/Number/Number';
import UICurrency from 'components/UI/Currency/Currency';

import './StatsTable.scss';

const attrSourcesLabels = {
  notPlacedByBEN: 'Not Placed By BEN',
  propped: 'Propped',
  paid: 'Paid',
};

const cbn = 'placement-view-stats-table';

function PlacementViewStatsTable({
  attributes,
  channelName,
  durationLength,
  mediaValue,
  cpm,
  renderLabel,
  shouldRender,
  verbalMentions,
}) {
  return (
    <div className={cbn}>
      <div className={`${cbn}__row`}>
        {shouldRender('qualitySection.statsTable.duration') && (
          <div className={`${cbn}__col`}>
            <span className={`${cbn}__col__label`}>
              {renderLabel('qualitySection.statsTable.duration', 'Duration')}
            </span>
            <UINumber format="00:00">{durationLength}</UINumber>
          </div>
        )}

        {shouldRender('qualitySection.statsTable.verbals') && (
          <div className={`${cbn}__col`}>
            <span className={`${cbn}__col__label`}>
              {renderLabel('qualitySection.statsTable.verbals', 'Verbals')}
            </span>
            {verbalMentions || 0}
          </div>
        )}

        {shouldRender('qualitySection.statsTable.type') && (
          <div className={`${cbn}__col`}>
            <span className={`${cbn}__col__label`}>
              {renderLabel('qualitySection.statsTable.type', 'Type')}
            </span>
            {attributes && attrSourcesLabels[attributes.source]}
          </div>
        )}

        {['film', 'tv'].includes(channelName) &&
          cpm > 0 &&
          shouldRender('qualitySection.statsTable.cpm') && (
            <div className={`${cbn}__col`}>
              <span className={`${cbn}__col__label`}>
                {renderLabel('qualitySection.statsTable.cpm', 'Earned CPM')}
              </span>
              <UICurrency format="$0,0[.]00">{cpm}</UICurrency>
            </div>
          )}

        {['film', 'tv'].includes(channelName) &&
          mediaValue > 0 &&
          shouldRender('qualitySection.statsTable.mediaValue') && (
            <div className={`${cbn}__col`}>
              <span className={`${cbn}__col__label`}>
                {renderLabel(
                  'qualitySection.statsTable.mediaValue',
                  'Media Value',
                )}
              </span>
              <UICurrency format="$0,0[.]00">{mediaValue}</UICurrency>
            </div>
          )}
      </div>
    </div>
  );
}

PlacementViewStatsTable.propTypes = {
  attributes: shape({}),
  durationLength: number,
  mediaValue: number,
  cpm: number,
  renderLabel: func.isRequired,
  shouldRender: func.isRequired,
  source: string,
  channelName: string.isRequired,
  verbalMentions: number,
};

PlacementViewStatsTable.defaultProps = {
  attributes: {},
  durationLength: 0,
  mediaValue: null,
  cpm: null,
  source: '',
  verbalMentions: 0,
};

export default PlacementViewStatsTable;
