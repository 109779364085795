import { Production } from 'components/Production/types';

const getMetricType = (production: Production) => {
  switch (production.type) {
    case 'facebook':
      return 'likes';
    case 'instagram':
    case 'twitter':
    case 'twitch':
    case 'tiktok':
      return 'followers';
    case 'youtube':
      return 'subscribers';
    default:
      return '';
  }
};

export default function getBestMetricResult(
  production: Production,
  metricType?: string,
) {
  if (!metricType) {
    metricType = getMetricType(production);
  }

  if (!production?.bestMetricResults) {
    return 0;
  }

  const bestMetric = production.bestMetricResults.find(
    (metric) => metric.metricType === metricType,
  );

  return bestMetric?.value;
}
