import React, { cloneElement, Children } from 'react';
import { arrayOf, oneOfType, func, node, string } from 'prop-types';
import classNames from 'classnames';
import splitSort from 'utils/splitSort/splitSort';

const childType = oneOfType([func, node]);

const injectProps = ({ onChangeSort, sort }) => (child) => {
  const { sortBy, sortOrder } = splitSort(sort);

  const props = {};
  if (child.props.sortable) {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    props.onClick = () => onChangeSort(`${child.props.id}-${newOrder}`);
  }

  return cloneElement(child, { ...props, sortBy, sortOrder });
};

function UITableHeader({ children, sort, onChangeSort }) {
  return (
    <div className={classNames('ui-table__header')}>
      {Children.toArray(children).map(injectProps({ sort, onChangeSort }))}
    </div>
  );
}

UITableHeader.propTypes = {
  sort: string,
  onChangeSort: func,
  children: oneOfType([childType, arrayOf(childType)]),
};

UITableHeader.defaultProps = {
  sort: null,
  onChangeSort() {},
  children: null,
};

export default UITableHeader;
