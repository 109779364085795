import React from 'react';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import UIPageTitle from 'components/UI/PageTitle/PageTitle';
import ResetPassword from 'components/User/ResetPassword/ResetPassword';

import './ResetPassword.scss';

function ScreensUserResetPassword() {
  return (
    <div className="reset-password__placement-wrapper ">
      <div className="reset-password__wrapper ">
        <DocumentTitle>Reset Your Password</DocumentTitle>
        <UIPageTitle>Reset password</UIPageTitle>
        <ResetPassword />
      </div>
    </div>
  );
}

export default ScreensUserResetPassword;
