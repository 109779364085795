import { useContext, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import classnames from 'classnames';

import ExternalDeliverableItem from 'external/components/Deliverable/Item/Item';

import ApplicationContext from 'components/Application/Context';
import { ExternalApplicationContext } from 'external/components/Application/Context';

import { trackCreatorViewed } from 'external/components/Segment/Segment';

import getPropertyImageForDeliverable from 'external/utils/getPropertyImageForDeliverable';
import { Deliverable } from 'components/Deliverable/types';
import { IInitialsItem } from 'external/components/UI/ColoredInitials/types';

import './Row.scss';

type ExternalDeliverableListRowProps = {
  className: string;
  isSelected: boolean;
  item: Deliverable;
  onClick: () => void;
  initials?: IInitialsItem;
};

function ExternalDeliverableListRow({
  className,
  isSelected,
  item,
  onClick,
  initials,
}: ExternalDeliverableListRowProps) {
  const { user } = useContext(ApplicationContext);
  const { campaign, segment } = useContext(ExternalApplicationContext);

  const [trackDeliverable, setTrackDeliverable] = useState<boolean>(true);

  const track = () => {
    if (segment && user && campaign && trackDeliverable) {
      trackCreatorViewed({ segment, campaign, user, deliverable: item });
      setTrackDeliverable(false);
    }
  };

  function handleClick() {
    track();
    onClick();
  }

  return (
    <div
      className={classnames('external-deliverable-list-row', className, {
        'is-selected': isSelected,
      })}
    >
      <ExternalDeliverableItem
        onClick={() => handleClick()}
        campaignId={item.campaign?.id}
        externalApproval={item.externalApproval}
        id={item.id}
        createdAt={item.createdAt}
        messagesCount={item.messagesCount}
        propertyId={item.property.id}
        propertyName={item.property.name}
        productions={item.property?.productions}
        // @ts-ignore
        thumb={getPropertyImageForDeliverable(item)}
        viewedByClient={item.viewedByClient || false}
        initials={initials}
        createdBy={item.createdBy}
      />
    </div>
  );
}

ExternalDeliverableListRow.propTypes = {
  className: string,
  isSelected: bool,
  item: shape({
    campaign: shape({
      id: number,
    }),
    externalApproval: string,
    id: number,
    messagesCount: shape({
      external: number,
    }),
    createdAt: string,
    property: shape({
      name: string,
      productions: arrayOf(
        shape({
          media: arrayOf(
            shape({
              url: string,
            }),
          ),
          type: string,
        }),
      ),
    }),
    viewedByClient: bool,
  }).isRequired,
  onClick: func,
};

ExternalDeliverableListRow.defaultProps = {
  className: '',
  isSelected: false,
  onClick: null,
};

export default ExternalDeliverableListRow;
