import React, { useMemo } from 'react';
import { arrayOf, shape, string, func, number, oneOf } from 'prop-types';
import classnames from 'classnames';

import ChatInput from 'components/Chat/Input/Input';
import ChatMessagesList from './List/List';

import './Messages.scss';

const splitMessages = (data) => {
  const result = { internal: [], external: [] };
  if (!data) {
    return result;
  }

  data.forEach((message) => {
    result[message.visibility].push(message);
  });

  return result;
};

const cbn = 'chat-messages';

function Messages({
  data,
  editingMessages,
  inputValue,
  meta,
  onDelete,
  onInputChange,
  onUpdateMessage,
  onDeleteMessage,
  sendMessage,
  onToggleEdit,
  user,
  userRole,
  activeTab,
  toggleTab,
}) {
  const messages = useMemo(() => splitMessages(data), [data]);

  return (
    <section
      className={classnames(
        cbn,
        `${cbn}--user-${userRole}`,
        `${cbn}--${activeTab}-user-${userRole}`,
      )}
    >
      <div className={`${cbn}__header`}>
        {userRole &&
          userRole !== 'external' &&
          ['internal', 'external'].map((type) => (
            <button
              key={type}
              type="button"
              className={classnames(`${cbn}__visibility-toggle-button`, {
                [`${cbn}__visibility-toggle-button--is-selected`]:
                  type === activeTab,
              })}
              onClick={() => toggleTab(type)}
            >
              <span className={`${cbn}__header__title`}>
                {type}
                <span
                  className={`${cbn}__header__count ${cbn}__header__count--${type}`}
                >
                  {meta[type] > 0 ? meta[type] : ''}
                </span>
              </span>
            </button>
          ))}
      </div>
      <ChatMessagesList
        key={activeTab}
        onDelete={onDelete}
        onToggleEdit={onToggleEdit}
        user={user}
        editingMessages={editingMessages}
        messages={messages[activeTab]}
        onUpdateMessage={onUpdateMessage}
        onDeleteMessage={onDeleteMessage}
      />
      <ChatInput
        onChange={onInputChange}
        sendMessage={sendMessage}
        value={inputValue}
      />
    </section>
  );
}

Messages.propTypes = {
  data: arrayOf(shape({})),
  editingMessages: shape({}),
  inputValue: string,
  sendMessage: func,
  onDelete: func,
  onToggleEdit: func,
  onUpdateMessage: func,
  onDeleteMessage: func,
  user: shape({}),
  userRole: string,
  activeTab: oneOf(['internal', 'external']),
  toggleTab: func,
  meta: shape({
    internal: number,
    external: number,
  }),
  onInputChange: func,
};

Messages.defaultProps = {
  user: shape({}),
  userRole: '',
  activeTab: oneOf(['internal', 'external']),
  toggleTab: func,
  data: [],
  editingMessages: {},
  inputValue: '',
  meta: {
    internal: null,
    external: null,
  },
  sendMessage: () => {},
  onDelete: () => {},
  onInputChange: () => {},
  onToggleEdit: () => {},
  onUpdateMessage: () => {},
  onDeleteMessage: () => {},
};

export default Messages;
