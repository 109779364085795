export const EXTERNAL_APPROVAL_STATUSES = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export enum ExternalApproval {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum InternalApproval {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

const REJECTED_NOT_ALIGN_BRAND = 'rejected-not-align-brand';
const REJECTED_BRAND_QUALITY = 'rejected-brand-quality';
const REJECTED_MINIMUM_PERFORMANCE = 'rejected-minimum-performance';
const REJECTED_QUESTIONABLE_HISTORY = 'rejected-questionable-history';
const REJECTED_NOT_ENOUGH_INFO = 'rejected-not-enough-info';
const REJECTED_OTHER = 'rejected-other';

const REJECTED_NOT_ALIGN_BRAND_LABEL =
  'Influencer does not align with my brand / messaging';
const REJECTED_BRAND_QUALITY_LABEL =
  'Influencer does not meet my brands quality standards';
const REJECTED_MINIMUM_PERFORMANCE_LABEL =
  'Influencer does not meet my expected performance minimum';
const REJECTED_QUESTIONABLE_HISTORY_LABEL =
  'Influencer has a questionable / unsavory history';
const REJECTED_NOT_ENOUGH_INFO_LABEL =
  'Not enough information to make an informed selection';
export const REJECTED_OTHER_LABEL = 'Other';

export const STATUS_OPTIONS = [
  { value: REJECTED_NOT_ALIGN_BRAND, label: REJECTED_NOT_ALIGN_BRAND_LABEL },
  { value: REJECTED_BRAND_QUALITY, label: REJECTED_BRAND_QUALITY_LABEL },
  {
    value: REJECTED_MINIMUM_PERFORMANCE,
    label: REJECTED_MINIMUM_PERFORMANCE_LABEL,
  },
  {
    value: REJECTED_QUESTIONABLE_HISTORY,
    label: REJECTED_QUESTIONABLE_HISTORY_LABEL,
  },
  { value: REJECTED_NOT_ENOUGH_INFO, label: REJECTED_NOT_ENOUGH_INFO_LABEL },
  { value: REJECTED_OTHER, label: REJECTED_OTHER_LABEL },
];
