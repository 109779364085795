import {
  STREAM_MEDIA_TYPE,
  TWITCH_PLATFORM,
  VIDEO_MEDIA_TYPE,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import basePerformanceColDef from '../../helpers/baseColDef';
import getYouTubeDataFromCampaignService from '../helpers/getYouTubeDataFromCampaignService';

const metric = 'totalMinutesWatched';
export function totalMinutesWatchedAutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric) ?? null;
  }
  return null;
}

export default function totalMinutesWatched() {
  return {
    ...basePerformanceColDef(
      metric,
      totalMinutesWatchedAutomatedValueGetter,
      [YOUTUBE_PLATFORM, TWITCH_PLATFORM],
      [VIDEO_MEDIA_TYPE, STREAM_MEDIA_TYPE],
    ),
    headerName: 'Total Minutes Watched',
    width: 270,
  };
}
