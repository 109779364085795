import { Placement } from 'shared/types/Placement';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';

export default function tuneAffiliateSource() {
  return {
    ...baseColDef,
    field: 'tuneAffiliateSource',
    headerName: 'TUNE Affiliate Source',
    valueGetter: ({ data }: { data: Placement }) => data?.tuneAffiliateSource,
    width: 200,
    filter: 'agTextColumnFilter',
  };
}
