import React from 'react';
import * as Sentry from '@sentry/react';
import { Campaign } from 'shared/types/Campaign';
import ApplicationContext from 'components/Application/Context';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import { SegmentEvents } from 'components/Segment/segmentEvents';
import {
  ReportingAction,
  ReportingData,
} from 'shared/campaigns/creatorCampaigns/segmentEnums';

export default function useSegmentReportingTracking(
  campaign?: Campaign | null,
) {
  const { segment, user } = React.useContext(ApplicationContext);
  return React.useCallback(
    (action: ReportingAction, extraData?: ReportingData) => {
      if (!segment || !user?.id || !campaign) {
        return;
      }
      segment
        .track(
          SegmentEvents.PerformanceReporting,
          {
            action,
            ...extraData,
          },
          {
            context: campaign
              ? { groupId: formatCampaignId(campaign?.id) }
              : undefined,
            userId: formatUserId(user?.id),
          },
        )
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
    [campaign, segment, user?.id],
  );
}
