import { getDataCy } from 'components/Campaign/utils';
import linkColDef from 'components/DataGrid/Destination/colDef/linkColDef';
import { Placement } from 'shared/types/Placement';

export default function contentLink() {
  return {
    ...linkColDef,
    field: 'contentLink',
    headerName: 'Content Link',
    valueGetter: ({ data }: { data: Placement }) => data?.source?.url,
    cellRendererParams: ({ data }: { data: Placement }) => ({
      dataCy: getDataCy(data, 'link'),
    }),
    filter: 'agTextColumnFilter',
  };
}
