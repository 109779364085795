import { IInitialsItem } from './InitialsItem';

const initialsDefault = { letters: 'BA' };

function ColoredInitials({
  initials = initialsDefault,
  size,
}: {
  initials?: IInitialsItem;
  size?: 'icon' | 'medium' | 'medium-large' | 'large';
}) {
  return (
    <div
      className={`colored-initials ${size}`}
      style={{ backgroundColor: initials.color }}
    >
      <p>{initials?.letters}</p>
    </div>
  );
}

export default ColoredInitials;
