import React from 'react';
import Tippy from '@tippyjs/react';
import UINumber from 'components/UI/Number/Number';

import './Metric.scss';

type ExternalPlacementCardMetricProps = {
  format: string;
  label: string;
  value?: number;
};

function ExternalPlacementCardMetric({
  format,
  label,
  value,
}: ExternalPlacementCardMetricProps) {
  return (
    <Tippy
      content={
        <span>
          {label}
          :
          {' '}
          <UINumber format={format === '0a' ? '0,0[.]000' : format}>
            {value}
          </UINumber>
        </span>
      }
      placement="top"
      animation="shift-toward"
      arrow
    >
      <div className="external-placement-card__metric">
        <UINumber
          className="external-placement-card__metric__value"
          format={format}
        >
          {value}
        </UINumber>
        <span className="external-placement-card__metric__label">{label}</span>
      </div>
    </Tippy>
  );
}

export default ExternalPlacementCardMetric;
