import React, { useContext } from 'react';
import { func, any } from 'prop-types';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import ApplicationContext from 'components/Application/Context';
import { getDefaultLocation } from 'utils/auth';
import { getDefaultLocation as getExternalDefaultLocation } from 'external/utils/auth';
import AbsoluteRedirect from '../Redirect/AbsoluteRedirect';

function RoutePublic({
  element: Component,
  getLocation,
  externalContext,
  ...restProps
}) {
  const { user, permissions, token } = useContext(ApplicationContext);

  const location = useLocation();
  const { ...props } = useParams();
  // Not authenticated
  if (!token || !permissions || !user) {
    return <Component {...restProps} {...props} location={location} />;
  }

  const client = user?.client || (user?.clients && user?.clients[0]);

  if (
    !externalContext &&
    user?.role === 'external' &&
    !client?.hasInternalAppAccess
  ) {
    return (
      <AbsoluteRedirect to={`/client${getExternalDefaultLocation(user)}`} />
    );
  }

  const { redirectOnAuthenticated, from } = location.state || {};
  const redirectTo = redirectOnAuthenticated ? from : getLocation(user);

  return <Navigate to={redirectTo} />;
}

RoutePublic.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  element: any.isRequired,
  getLocation: func,
};

RoutePublic.defaultProps = {
  getLocation: getDefaultLocation,
  externalContext: false,
};

export default RoutePublic;
