export default function getMetricPrediction(
  production,
  metricType,
  returnFullObject = false,
) {
  let metricObject;

  if (production.metricPredictions) {
    metricObject = production.metricPredictions.find(
      (metric) => metric.metricType === metricType,
    );

    if (metricObject) {
      if (returnFullObject) {
        return metricObject;
      }
      return metricObject.value;
    }
  }

  return null;
}
