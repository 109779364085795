import storage from './storage';

const isScanner = (user) => {
  const team = user?.team || [];
  return team.length === 1 && team[0] === 'scanner';
};

export const getDefaultLocation = (user) => {
  if (!user) {
    return '/user/login';
  }
  let pathDefault = '/campaigns/list';

  const localStorageRedirectPath = storage.get(
    '$BEN$--redirectOnAuthenticated',
  );

  if (localStorageRedirectPath?.length) {
    pathDefault = localStorageRedirectPath;
  }
  return isScanner(user) ? '/scan' : pathDefault;
};
