import React from 'react';

import Profile from 'components/Profile/Profile';
import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import UIPageTitle from 'components/UI/PageTitle/PageTitle';

import './Profile.scss';

function ScreensProfile() {
  return (
    <>
      <DocumentTitle>Profile</DocumentTitle>
      <UIPageTitle>My Account</UIPageTitle>
      <div className="user-profile-ee__wrapper">
        <Profile />
      </div>
    </>
  );
}

export default ScreensProfile;
