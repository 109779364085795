import basePerformanceColDef from './helpers/baseColDef';

const metric = 'clicks';

export default function clicks() {
  return {
    ...basePerformanceColDef(metric, () => null),
    headerName: 'Clicks',
  };
}
