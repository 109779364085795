import { TCMCreatorMediaInsights } from '../../types/Production';

export default function getMediaInsightsPostById(
  videoId: string | undefined,
  tikTokCreatorMediaInsights?: TCMCreatorMediaInsights,
) {
  const posts =
    tikTokCreatorMediaInsights?.authorizedMediaInsights?.posts ??
    tikTokCreatorMediaInsights?.publicMediaInsights?.posts;

  return (posts ?? []).find((post) => post?.video_id === videoId);
}
