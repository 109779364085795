import * as Sentry from '@sentry/react';
import {
  checkIfApplicationWasRefreshed,
  checkIfUserIsUnauthorized,
} from './utils/errorCheck';

function getError(errEv) {
  return errEv && errEv.type && errEv.error ? errEv.error : errEv;
}

export default function createErrorStream() {
  const listeners = [];

  const dispatch = (...errorEvents) => {
    if (!errorEvents.length) return;

    const filteredErrorEvents = errorEvents.filter((errEv) => {
      const opt = errEv && errEv.extra ? { extra: errEv.info } : undefined;

      if (checkIfUserIsUnauthorized(errEv?.error)) {
        Sentry.captureMessage(`Unauthorized: ${errEv?.error}`);
      } else if (
        errEv?.error?.extensions?.exception?.title !==
          'URL is already in BEN.' &&
        !checkIfApplicationWasRefreshed(errEv?.error)
      ) {
        Sentry.captureException(getError(errEv), opt);
      }
      return true;
    });

    listeners.forEach((listener) => {
      listener(...filteredErrorEvents);
    });
  };

  return {
    subscribe: (cb) => {
      listeners.push(cb);
      return () => listeners.splice(listeners.indexOf(cb), 1);
    },
    dispatch,
    clear: () => dispatch({ type: 'CLEAR' }),
  };
}
