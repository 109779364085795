import React from 'react';
import {
  TCMAuthorizedMediaInsights,
  TCMReportData,
  TCMSparkAdStatusData,
} from 'shared/types/TikTokMarketPlace';
import { Property } from 'shared/types/Property';
import getMediaInsightByVideoId from 'shared/utils/tikTok/getMediaInsightByVideoId';
import { TCMCreatorMediaInsights } from 'shared/types/Production';

import { SegmentEvents } from './types';
import Header from './Header/Header';
import Metrics from './Metrics/Metrics';
import ContentInfo from './ContentInfo/ContentInfo';

import './OrderReport.scss';

export default function OrderReport({
  property,
  videoId,
  sparkAdStatusData,
  onManageSparkAds,
  segmentEvents,
  tikTokOrderReport,
  onReportingWindowChange,
  tikTokCreatorMediaInsights,
}: {
  tikTokOrderReport?: TCMReportData;
  property?: Property;
  videoId?: string;
  sparkAdStatusData?: TCMSparkAdStatusData;
  onManageSparkAds: () => void;
  segmentEvents?: SegmentEvents | null;
  onReportingWindowChange: (value: string) => void;
  tikTokCreatorMediaInsights?: TCMCreatorMediaInsights;
}) {
  const currentPost = React.useMemo(
    () => getMediaInsightByVideoId(videoId, tikTokCreatorMediaInsights),
    [tikTokCreatorMediaInsights, videoId],
  );

  return (
    <section className="tiktok-video-content">
      <Header
        property={property}
        currentPost={currentPost as TCMAuthorizedMediaInsights}
        segmentEvents={segmentEvents}
      />
      <ContentInfo
        sparkAdStatusData={sparkAdStatusData}
        currentPost={currentPost as TCMAuthorizedMediaInsights}
        onManageSparkAds={onManageSparkAds}
        segmentEvents={segmentEvents}
      />
      <Metrics
        tikTokOrderReport={tikTokOrderReport}
        videoId={videoId}
        onReportingWindowChange={onReportingWindowChange}
      />
    </section>
  );
}
