import svgSizeMapping, { SvgSize } from '../utils/svgSizeMapping';

export default function InstagramLogo({
  fill = 'none',
  size,
}: {
  fill?: string;
  size: SvgSize;
}) {
  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 27 28"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_743_13726)">
        <path
          d="M13.5 17.375C15.364 17.375 16.875 15.864 16.875 14C16.875 12.136 15.364 10.625 13.5 10.625C11.636 10.625 10.125 12.136 10.125 14C10.125 15.864 11.636 17.375 13.5 17.375Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5625 3.875H8.4375C5.64156 3.875 3.375 6.14156 3.375 8.9375V19.0625C3.375 21.8584 5.64156 24.125 8.4375 24.125H18.5625C21.3584 24.125 23.625 21.8584 23.625 19.0625V8.9375C23.625 6.14156 21.3584 3.875 18.5625 3.875Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9844 10.2031C19.9164 10.2031 20.6719 9.44761 20.6719 8.51562C20.6719 7.58364 19.9164 6.82812 18.9844 6.82812C18.0524 6.82812 17.2969 7.58364 17.2969 8.51562C17.2969 9.44761 18.0524 10.2031 18.9844 10.2031Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_743_13726">
          <rect
            width="27"
            height="27"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
