import svgSizeMapping, { SvgSize } from './utils/svgSizeMapping';

export default function CopyIcon({
  fill = 'none',
  stroke = '#262626',
  size = 'medium',
}: {
  fill?: string;
  stroke?: string;
  size?: SvgSize;
}) {
  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 0.5H10.1667C12.0335 0.5 12.9669 0.5 13.68 0.863312C14.3072 1.18289 14.8171 1.69282 15.1367 2.32003C15.5 3.03307 15.5 3.96649 15.5 5.83333V11.75M3.16667 15.5H9.91667C10.8501 15.5 11.3168 15.5 11.6733 15.3183C11.9869 15.1586 12.2419 14.9036 12.4017 14.59C12.5833 14.2335 12.5833 13.7668 12.5833 12.8333V6.08333C12.5833 5.14991 12.5833 4.6832 12.4017 4.32668C12.2419 4.01308 11.9869 3.75811 11.6733 3.59832C11.3168 3.41667 10.8501 3.41667 9.91667 3.41667H3.16667C2.23325 3.41667 1.76654 3.41667 1.41002 3.59832C1.09641 3.75811 0.841445 4.01308 0.681656 4.32668C0.5 4.6832 0.5 5.14991 0.5 6.08333V12.8333C0.5 13.7668 0.5 14.2335 0.681656 14.59C0.841445 14.9036 1.09641 15.1586 1.41002 15.3183C1.76654 15.5 2.23325 15.5 3.16667 15.5Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
