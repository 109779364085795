import React from 'react';
import { oneOfType, node, arrayOf } from 'prop-types';

import './Row.scss';

function FormRow({ children }) {
  return <div className="form-row">{children}</div>;
}

FormRow.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
};

export default FormRow;
