import ChevronRightDouble from '../../_assets/react_svg/ChevronRightDouble';

export default function CloseHeader({
  onRequestClose,
  hideHeader,
}: {
  onRequestClose?: () => void;
  hideHeader?: boolean;
}) {
  if (hideHeader) {
    return null;
  }
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onRequestClose}
      onKeyDown={onRequestClose}
      className="close-drawer-header"
    >
      <ChevronRightDouble />
    </div>
  );
}
