import { useState, useRef, useEffect } from 'react';
import useDebounce from 'utils/useDebounce';

export default function useDebouncedInput(value, onChange, delay = 300) {
  const [localValue, setLocalValue] = useState(value);
  const valueRef = useRef(value);
  const debouncedChange = useDebounce(onChange, delay);

  function onChangeHandler(ev, rawValue) {
    if (ev.persist) {
      ev.persist();
    }
    const v = ev?.target ? ev.target.value : ev;

    valueRef.current = v;
    setLocalValue(v);
    debouncedChange.cancel();
    debouncedChange(ev, rawValue);
  }

  useEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value;
      setLocalValue(value);
    }
  }, [value]);

  return [localValue, onChangeHandler];
}
