import { IInitialsItem } from './InitialsItem';

export const defaultColor = '#5246ae';

export const initialsDefault: IInitialsItem = {
  letters: '-',
  color: defaultColor,
};
const mapColors = {
  '[A-Da-d]': '#295FCC',
  '[E-Ke-k]': '#5F29CC',
  '[L-Pl-p]': '#9629CC',
  '[Q-Tq-t]': '#CC2996',
  '[U-Zu-z]': '#006980',
};

export const getInitials = (name: string): string => {
  const sanitizedName = name.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\]\\]/gi, '');
  const names = sanitizedName.split(' ').filter(Boolean);
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

function getColorByLetter(letter: string): string {
  const [, color] = Object.entries(mapColors).find(([key, _]) => key.match(letter)) || [
    null,
    defaultColor,
  ];

  return color;
}
export function getInitialsByName(name: string): IInitialsItem {
  const letters = getInitials(name) || '';

  const color = getColorByLetter(letters.substring(0, 1));

  const initials: IInitialsItem = {
    letters,
    color,
  };

  return initials;
}
