const objectiveTypeOptions = [
  { label: 'Reach', value: 'REACH' },
  { label: 'Traffic', value: 'TRAFFIC' },
  { label: 'Video Views', value: 'VIDEO_VIEWS' },
  { label: 'Engagement', value: 'ENGAGEMENT' },
  { label: 'App Promotion', value: 'APP_PROMOTION' },
  { label: 'Lead Generation', value: 'LEAD_GENERATION' },
  { label: 'Web Conversions', value: 'WEB_CONVERSIONS' },
  { label: 'Product Sales', value: 'PRODUCT_SALES' },
];

export default objectiveTypeOptions;
