import React from 'react';
import { node, string } from 'prop-types';
import './Placeholder.scss';

function ExternalDomoIFramePlaceholder() {
  return (
    <>
      <h2 className="external-domo-placeholder__title">
        There was an error trying to load this report.
      </h2>
      <h3 className="external-domo-placeholder__content">
        Please refresh the page or try again later.
      </h3>
    </>
  );
}

ExternalDomoIFramePlaceholder.propTypes = {
  children: node,
  title: string,
};

ExternalDomoIFramePlaceholder.defaultProps = {
  children: null,
  string: '',
};

export default ExternalDomoIFramePlaceholder;
