import { Placement } from 'shared/types/Placement';
import {
  GridApi,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import ManualMetricCellRenderer from 'components/DataGrid/Destination/Cells/ManualMetric/ManualMetricCellRenderer';
import ManualMetrics from 'components/DataGrid/Editors/Destination/ManualMetrics/ManualMetrics';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import manualMetricsValueSetter from './manualMetricValueSetter';
import manualMetricsValueGetter from './manualMetricValueGetter';

type Value = string | number | null;

function manualMetricBaseColDef(
  metric: string,
  automatedMetricsGetter: (
    arg0: Placement,
    context?: Context | null,
    getValue?: (field: string) => Value,
  ) => Value,
  allowedPlatforms?: string[],
  allowedMediaTypes?: string[],
) {
  return {
    editable({
      data,
      api,
      node,
    }: {
      data: Placement;
      api: GridApi;
      node: RowNode;
    }) {
      if (!data?.source?.id || !data?.source?.url) {
        return false;
      }

      if (
        allowedPlatforms?.length &&
        !allowedPlatforms.includes(data?.platform ?? '')
      ) {
        return false;
      }

      if (
        allowedMediaTypes?.length &&
        !allowedMediaTypes.includes(data?.mediaType ?? '')
      ) {
        return false;
      }

      const automatedMetricValue = automatedMetricsGetter(data, null, (field) =>
        api.getValue(field, node),
      );

      if (automatedMetricValue === null || automatedMetricValue === undefined) {
        return true;
      }

      const numericValue = Number(automatedMetricValue);

      return !(!Number.isNaN(numericValue) && numericValue >= 0);
    },
    cellEditorPopup: true,
    cellEditor: ManualMetrics,
    cellEditorParams: () => ({ metric }),
    cellRenderer: ManualMetricCellRenderer,
    cellRendererParams: ({ value }: { value: number }) => ({
      value,
      metric,
      automatedMetricsGetter,
    }),
    valueGetter({ data, context, getValue }: ValueGetterParams<Placement>) {
      if (!data) {
        return null;
      }

      const automatedValue = automatedMetricsGetter(data, context, getValue);

      if (automatedValue === null || automatedValue === undefined) {
        return manualMetricsValueGetter(data, context, metric);
      }

      const automatedValueAsNumber = Number(automatedValue);

      if (Number.isFinite(automatedValueAsNumber)) {
        return automatedValueAsNumber;
      }

      return automatedValue;
    },
    valueSetter(params: ValueSetterParams<Placement>) {
      return manualMetricsValueSetter(params, metric);
    },
  };
}

export default function basePerformanceColDef(
  metric: string,
  automatedMetricsGetter: (
    arg0: Placement,
    context?: Context | null,
    getValue?: (field: string) => Value,
  ) => Value,
  allowedPlatforms?: string[],
  allowedMediaTypes?: string[],
) {
  return {
    ...baseColDef,
    headerClass: ['header-align-right', 'manual-metric-header'],
    cellClass: 'cell-align-right',
    field: metric,
    ...manualMetricBaseColDef(
      metric,
      automatedMetricsGetter,
      allowedPlatforms,
      allowedMediaTypes,
    ),
  };
}
