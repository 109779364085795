import { messagesQuery } from './graphql';

export const updateCacheOnCreate = (props) => (
  proxy,
  { data: { createMessage } },
) => {
  const { childKey, childType, entityId, entityType } = props;
  const data = proxy.readQuery({
    query: messagesQuery,
    variables: {
      childKey,
      childType,
      entityId,
      entityType,
    },
  });

  proxy.writeQuery({
    query: messagesQuery,
    variables: {
      childKey,
      childType,
      entityId,
      entityType,
    },
    data: {
      ...data,
      messages: {
        ...data.messages,
        data: [...data.messages.data, createMessage],
        meta: {
          ...data.messages.meta,
          [createMessage.visibility]:
            data.messages.meta[createMessage.visibility] + 1,
        },
      },
    },
  });
};

export const updateCacheOnUpdate = (props) => (
  proxy,
  { data: { updateLog, deleteLog } },
) => {
  const { childKey, childType, entityId, entityType } = props;
  const data = proxy.readQuery({
    query: messagesQuery,
    variables: {
      childKey,
      childType,
      entityId,
      entityType,
    },
  });

  const result = updateLog || deleteLog;

  const replaceItem = (log) => {
    if (!log?.id || !result?.id || log.id === result.id) {
      return log;
    }
    return { ...log, ...result };
  };

  proxy.writeQuery({
    query: messagesQuery,
    variables: {
      childKey,
      childType,
      entityId,
      entityType,
    },
    data: {
      ...data,
      messages: {
        ...data.messages,
        data: data.messages.data.map(replaceItem),
      },
    },
  });
};
