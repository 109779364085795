/* eslint-disable max-lines */
import upperfirst from 'lodash/upperFirst';
import {
  PLATFORMS_DISPLAY_MAP,
  FILM_PLATFORM,
  SEASON_PLATFORM,
  YOUTUBE_PLATFORM,
  INSTAGRAM_PLATFORM,
  FACEBOOK_PLATFORM,
  TWITTER_PLATFORM,
  TWITCH_PLATFORM,
  TIKTOK_PLATFORM,
  OTHER_PLATFORM,
  VIDEO_MEDIA_TYPE,
  REELS_MEDIA_TYPE,
  LIVE_MEDIA_TYPE,
  STORY_MEDIA_TYPE,
  STREAM_MEDIA_TYPE,
  POST_MEDIA_TYPE,
  PHOTO_MEDIA_TYPE,
  EPISODE_MEDIA_TYPE,
  FILM_MEDIA_TYPE,
  PRODUCTION_MEDIA_TYPE,
  INTEGRATION_STYLE,
  THEMATIC_STYLE,
  DEDICATED_STYLE,
  TRADITIONAL_MEDIA_TARGETS,
  INFLUENCER_MEDIA_TARGETS,
  SUBSCRIBERS,
  VIEWS,
  CLICKS,
  REVENUE,
  CONVERSIONS,
  DURATION_IN_HOURS,
  CCV,
  CTR,
  VPC,
  ACTIONS,
  CONTENT_PIECES,
  AVG_CONCURRENTS,
  IMPRESSIONS,
  AVERAGE_ENGAGEMENT,
  AVERAGE_ENGAGEMENT_RATE,
  ENGAGEMENT_RATE,
  FOLLOWERS,
  BOT_PERCENTAGE,
  AVERAGE_VIEWS,
  LIVE_VIEWS,
  UNIQUE_VISITORS,
  MINUTES_STREAMED,
  CHAT_VOLUME,
  METRICS_PER_MEDIA_TYPE,
  AWARENESS_METRIC_TYPES_WITHOUT_DEMOGRAPHICS,
  PERFORMANCE_METRIC_TYPES_WITHOUT_DEMOGRAPHICS,
} from 'shared/metrics/metrics';
import { DEMOGRAPHIC_METRICS } from './demographicMetrics';

export const MEDIA_TYPES_DISPLAY_MAP = {
  [EPISODE_MEDIA_TYPE]: 'Episode',
  [VIDEO_MEDIA_TYPE]: 'Video',
  [STORY_MEDIA_TYPE]: 'Story',
  [POST_MEDIA_TYPE]: 'Post',
  [STREAM_MEDIA_TYPE]: 'Stream',
  [FILM_MEDIA_TYPE]: 'Film',
  [PHOTO_MEDIA_TYPE]: 'Photo',
  [REELS_MEDIA_TYPE]: 'Reels',
  [LIVE_MEDIA_TYPE]: 'Live',
  [PRODUCTION_MEDIA_TYPE]: 'Production',
};

export const PLATFORMS_SELECT_OPTIONS = Object.keys(PLATFORMS_DISPLAY_MAP).map(
  (platform) => ({
    value: platform,
    label: PLATFORMS_DISPLAY_MAP[platform],
  }),
);

export const PLATFORMS_MEDIA_TYPES = {
  [FILM_PLATFORM]: [FILM_MEDIA_TYPE],
  [SEASON_PLATFORM]: [EPISODE_MEDIA_TYPE],
  [YOUTUBE_PLATFORM]: [VIDEO_MEDIA_TYPE, STREAM_MEDIA_TYPE],
  [INSTAGRAM_PLATFORM]: [
    VIDEO_MEDIA_TYPE,
    STORY_MEDIA_TYPE,
    PHOTO_MEDIA_TYPE,
    REELS_MEDIA_TYPE,
    LIVE_MEDIA_TYPE,
  ],
  [FACEBOOK_PLATFORM]: [VIDEO_MEDIA_TYPE, POST_MEDIA_TYPE, LIVE_MEDIA_TYPE],
  [TWITTER_PLATFORM]: [VIDEO_MEDIA_TYPE, POST_MEDIA_TYPE],
  [TWITCH_PLATFORM]: [STREAM_MEDIA_TYPE],
  [TIKTOK_PLATFORM]: [VIDEO_MEDIA_TYPE],
  [OTHER_PLATFORM]: [
    VIDEO_MEDIA_TYPE,
    STORY_MEDIA_TYPE,
    STREAM_MEDIA_TYPE,
    POST_MEDIA_TYPE,
    PHOTO_MEDIA_TYPE,
  ],
};

export const PLATFORMS_STYLES = {
  [FILM_PLATFORM]: [null],
  [SEASON_PLATFORM]: [null],
  [YOUTUBE_PLATFORM]: [INTEGRATION_STYLE, THEMATIC_STYLE, DEDICATED_STYLE],
  [INSTAGRAM_PLATFORM]: [null],
  [FACEBOOK_PLATFORM]: [null],
  [TWITTER_PLATFORM]: [null],
  [TWITCH_PLATFORM]: [null],
  [TIKTOK_PLATFORM]: [null],
  [OTHER_PLATFORM]: [null],
};

export const STYLES_DISPLAY_MAP = {
  [INTEGRATION_STYLE]: 'Integration',
  [THEMATIC_STYLE]: 'Thematic',
  [DEDICATED_STYLE]: 'Dedicated',
};

export const STYLES_SELECT_OPTIONS = Object.keys(STYLES_DISPLAY_MAP).map(
  (style) => ({
    value: style,
    label: STYLES_DISPLAY_MAP[style],
  }),
);

export const INFLUENCER_PLATFORMS = [
  YOUTUBE_PLATFORM,
  FACEBOOK_PLATFORM,
  INSTAGRAM_PLATFORM,
  TWITTER_PLATFORM,
  TWITCH_PLATFORM,
  TIKTOK_PLATFORM,
];

const getMediaConfig = (mediaTargetsByPlatform) =>
  Object.entries(mediaTargetsByPlatform).reduce(
    (acc, [platform, mediaTargets]) => {
      mediaTargets.forEach(({ mediaType, style }) => {
        acc.push({
          mediaType,
          style,
          platform,
        });
      });
      return acc;
    },
    [],
  );

const getMetricConfig = (mediaTargetsByPlatform) => {
  const result = Object.entries(mediaTargetsByPlatform).reduce(
    (acc, [platform, mediaTargets]) => {
      mediaTargets.forEach(({ mediaType, kpi, defaultKpi }) => {
        acc.set(`${platform}-${mediaType}-${kpi}`, {
          mediaType,
          metricType: kpi ?? defaultKpi,
          platform,
        });
      });
      return acc;
    },
    new Map(),
  );
  return [...result.values()];
};
export const INFLUENCER_MEDIA_CONFIG = getMediaConfig(INFLUENCER_MEDIA_TARGETS);
export const TRADITIONAL_MEDIA_CONFIG = getMediaConfig(
  TRADITIONAL_MEDIA_TARGETS,
);

export const INFLUENCER_METRIC_CONFIG = getMetricConfig(
  INFLUENCER_MEDIA_TARGETS,
);
export const TRADITIONAL_METRIC_CONFIG = getMetricConfig(
  TRADITIONAL_MEDIA_TARGETS,
);

export const PLATFORM_NETWORKS_MAP = {
  [YOUTUBE_PLATFORM]: 39015,
  [INSTAGRAM_PLATFORM]: 40315,
  [TWITTER_PLATFORM]: 40289,
  [FACEBOOK_PLATFORM]: 50709,
  [TWITCH_PLATFORM]: 54367,
  [TIKTOK_PLATFORM]: 54368,
};

export const METRIC_DEFINITIONS = {
  [SUBSCRIBERS.metricType]: SUBSCRIBERS,
  [VIEWS.metricType]: VIEWS,
  [CLICKS.metricType]: CLICKS,
  [REVENUE.metricType]: REVENUE,
  [CONVERSIONS.metricType]: CONVERSIONS,
  [CCV.metricType]: CCV,
  [CTR.metricType]: CTR,
  [VPC.metricType]: VPC,
  [ACTIONS.metricType]: ACTIONS,
  [AVERAGE_ENGAGEMENT.metricType]: AVERAGE_ENGAGEMENT,
  [ENGAGEMENT_RATE.metricType]: ENGAGEMENT_RATE,
  [AVERAGE_ENGAGEMENT_RATE.metricType]: AVERAGE_ENGAGEMENT_RATE,
  [FOLLOWERS.metricType]: FOLLOWERS,
  [BOT_PERCENTAGE.metricType]: BOT_PERCENTAGE,
  [AVERAGE_VIEWS.metricType]: AVERAGE_VIEWS,
  [IMPRESSIONS.metricType]: IMPRESSIONS,
  [CONTENT_PIECES.metricType]: CONTENT_PIECES,
  [LIVE_VIEWS.metricType]: LIVE_VIEWS,
  [UNIQUE_VISITORS.metricType]: UNIQUE_VISITORS,
  [AVG_CONCURRENTS.metricType]: AVG_CONCURRENTS,
  [MINUTES_STREAMED.metricType]: MINUTES_STREAMED,
  [CHAT_VOLUME.metricType]: CHAT_VOLUME,
  [DURATION_IN_HOURS.metricType]: DURATION_IN_HOURS,
};

export const METRIC_TYPES_DISPLAY_MAP = {
  [VIEWS.metricType]: 'Views',
  [CCV.metricType]: 'CCV',
  [CONTENT_PIECES.metricType]: 'Content Pieces',
};

const PERFORMANCE_OPTIONAL_METRIC_TYPES = [CTR, VPC, ACTIONS];

export const DEMOGRAPHIC_METRICS_FIELDS = DEMOGRAPHIC_METRICS.map(
  (m) => m.metricType,
);

export const METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS = {
  [POST_MEDIA_TYPE]: [
    ...METRICS_PER_MEDIA_TYPE[POST_MEDIA_TYPE],
    ...DEMOGRAPHIC_METRICS,
  ],
  [PHOTO_MEDIA_TYPE]: [
    ...METRICS_PER_MEDIA_TYPE[PHOTO_MEDIA_TYPE],
    ...DEMOGRAPHIC_METRICS,
  ],
  [VIDEO_MEDIA_TYPE]: [
    ...METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
    ...DEMOGRAPHIC_METRICS,
  ],
  [STORY_MEDIA_TYPE]: [
    ...METRICS_PER_MEDIA_TYPE[STORY_MEDIA_TYPE],
    ...DEMOGRAPHIC_METRICS,
  ],
  [STREAM_MEDIA_TYPE]: [
    ...METRICS_PER_MEDIA_TYPE[STREAM_MEDIA_TYPE],
    ...DEMOGRAPHIC_METRICS,
  ],
  [REELS_MEDIA_TYPE]: [
    ...METRICS_PER_MEDIA_TYPE[REELS_MEDIA_TYPE],
    ...DEMOGRAPHIC_METRICS,
  ],
  [LIVE_MEDIA_TYPE]: [
    ...METRICS_PER_MEDIA_TYPE[LIVE_MEDIA_TYPE],
    ...DEMOGRAPHIC_METRICS,
  ],
};

const PLATFORM_METRICS_WITHOUT_DEMOGRAPHICS = {
  [YOUTUBE_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [STREAM_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[STREAM_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [SUBSCRIBERS],
  },
  [INSTAGRAM_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PHOTO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[PHOTO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [STORY_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[STORY_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS, BOT_PERCENTAGE],
    [REELS_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[REELS_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [LIVE_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[LIVE_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
  },
  [FACEBOOK_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [POST_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[POST_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [LIVE_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[LIVE_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS],
  },
  [TWITTER_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [POST_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[POST_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS],
  },
  [TWITCH_PLATFORM]: {
    [STREAM_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[STREAM_MEDIA_TYPE],
      SUBSCRIBERS,
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS],
  },
  [TIKTOK_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS],
  },
  [OTHER_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
    [STORY_MEDIA_TYPE]: METRICS_PER_MEDIA_TYPE[STORY_MEDIA_TYPE],
    [POST_MEDIA_TYPE]: METRICS_PER_MEDIA_TYPE[POST_MEDIA_TYPE],
    [PHOTO_MEDIA_TYPE]: METRICS_PER_MEDIA_TYPE[PHOTO_MEDIA_TYPE],
    [STREAM_MEDIA_TYPE]: METRICS_PER_MEDIA_TYPE[STREAM_MEDIA_TYPE],
  },
};

const PLATFORM_METRICS = {
  [YOUTUBE_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [STREAM_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[STREAM_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [SUBSCRIBERS],
  },
  [INSTAGRAM_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PHOTO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[PHOTO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [STORY_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[STORY_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS, BOT_PERCENTAGE],
    [REELS_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[REELS_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [LIVE_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[LIVE_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
  },
  [FACEBOOK_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [POST_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[POST_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [LIVE_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[LIVE_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS],
  },
  [TWITTER_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [POST_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[POST_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS],
  },
  [TWITCH_PLATFORM]: {
    [STREAM_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[STREAM_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS],
  },
  [TIKTOK_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]: [
      ...METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[VIDEO_MEDIA_TYPE],
      ...PERFORMANCE_OPTIONAL_METRIC_TYPES,
    ],
    [PRODUCTION_MEDIA_TYPE]: [FOLLOWERS],
  },
  [OTHER_PLATFORM]: {
    [VIDEO_MEDIA_TYPE]:
      METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[VIDEO_MEDIA_TYPE],
    [STORY_MEDIA_TYPE]:
      METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[STORY_MEDIA_TYPE],
    [POST_MEDIA_TYPE]:
      METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[POST_MEDIA_TYPE],
    [PHOTO_MEDIA_TYPE]:
      METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[PHOTO_MEDIA_TYPE],
    [STREAM_MEDIA_TYPE]:
      METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[STREAM_MEDIA_TYPE],
  },
};

export const getPlatformMetrics = (platform, platformMetrics) =>
  platformMetrics[platform].map((m) => ({
    ...m,
    platform,
  }));

export const metricsBuilder = (
  overrides,
  removeExtraFields = true,
  useDemographics = true,
) => {
  const valuesToUse = useDemographics
    ? PLATFORM_METRICS
    : PLATFORM_METRICS_WITHOUT_DEMOGRAPHICS;
  return Object.keys(valuesToUse).reduce((acc, platform) => {
    if (overrides?.[platform] === false) {
      return acc;
    }

    Object.keys(valuesToUse[platform]).forEach((mediaType) => {
      if (overrides?.[platform]?.[mediaType] === false) {
        return;
      }

      valuesToUse[platform][mediaType].forEach((metric) => {
        if (overrides?.[platform]?.[mediaType]?.[metric.metricType] === false) {
          return;
        }
        let override = {};
        if (overrides?.[platform]?.[mediaType]?.[metric.metricType]) {
          override = overrides[platform][mediaType][metric.metricType];
        }
        const metricToPush = {
          ...metric,
          platform,
          mediaType,
          ...override,
        };

        if (removeExtraFields) {
          delete metricToPush.format;
          delete metricToPush.formatter;
        }
        acc.push(metricToPush);
      });
    });
    return acc;
  }, []);
};

export const METRICS = metricsBuilder();

export const AWARENESS_PLATFORMS_MEDIATYPES = {
  [YOUTUBE_PLATFORM]: [VIDEO_MEDIA_TYPE, STREAM_MEDIA_TYPE],
  [INSTAGRAM_PLATFORM]: [
    STORY_MEDIA_TYPE,
    VIDEO_MEDIA_TYPE,
    PHOTO_MEDIA_TYPE,
    REELS_MEDIA_TYPE,
    LIVE_MEDIA_TYPE,
  ],
  [TWITTER_PLATFORM]: [POST_MEDIA_TYPE, VIDEO_MEDIA_TYPE],
  [FACEBOOK_PLATFORM]: [POST_MEDIA_TYPE, VIDEO_MEDIA_TYPE, LIVE_MEDIA_TYPE],
  [TWITCH_PLATFORM]: [STREAM_MEDIA_TYPE],
  [TIKTOK_PLATFORM]: [VIDEO_MEDIA_TYPE],
};

const AWARENESS_METRIC_TYPES = [
  ...AWARENESS_METRIC_TYPES_WITHOUT_DEMOGRAPHICS,
  ...DEMOGRAPHIC_METRICS.map((m) => m.metricType),
];
const PERFORMANCE_METRIC_TYPES = [
  ...PERFORMANCE_METRIC_TYPES_WITHOUT_DEMOGRAPHICS,
  ...DEMOGRAPHIC_METRICS.map((m) => m.metricType),
];

export const getPerformanceMetrics = (
  allowedMetrics,
  removeExtraFields = true,
  campaignType,
) => {
  const filteredObject = INFLUENCER_PLATFORMS.reduce((acc, platform) => {
    if (!acc[platform]) {
      acc[platform] = {};
    }
    PLATFORMS_MEDIA_TYPES[platform].forEach((mediaType) => {
      if (!acc[platform][mediaType]) {
        acc[platform][mediaType] = {};
      }

      if (PLATFORM_METRICS?.[platform]?.[mediaType]) {
        PLATFORM_METRICS[platform][mediaType].forEach((metric) => {
          if (!allowedMetrics.includes(metric.metricType)) {
            acc[platform][mediaType][metric.metricType] = false;
            return;
          }

          if (
            metric?.allowedPlatforms?.length &&
            !metric.allowedPlatforms.includes(platform)
          ) {
            acc[platform][mediaType][metric.metricType] = false;
          }

          if (
            metric?.notAllowedPlatforms?.length &&
            metric.notAllowedPlatforms.includes(platform)
          ) {
            acc[platform][mediaType][metric.metricType] = false;
          }

          if (
            metric?.allowedCampaigns?.length &&
            (!campaignType || !metric.allowedCampaigns.includes(campaignType))
          ) {
            acc[platform][mediaType][metric.metricType] = false;
          }
        });
      }
    });
    return acc;
  }, {});
  return metricsBuilder(filteredObject, removeExtraFields);
};

export const PERFORMANCE_METRICS = getPerformanceMetrics(
  PERFORMANCE_METRIC_TYPES,
  true,
  'performance',
);

export const AWARENESS_METRIC_WITHOUT_DEMOGRAPHICS = getPerformanceMetrics(
  AWARENESS_METRIC_TYPES_WITHOUT_DEMOGRAPHICS,
  false,
);
export const AWARENESS_METRICS = getPerformanceMetrics(AWARENESS_METRIC_TYPES);

function stringMatch(platform, mediaType, metricType) {
  if (metricType && platform && mediaType) {
    const stringMatches = `${platform}-${mediaType}-${metricType}`;
    switch (stringMatches) {
      case 'twitter-video-shares':
      case 'twitter-post-shares':
        return 'Retweets';
      default:
        return null;
    }
  }

  return null;
}
export function getMetricTypeLabel(metricType, isActual, platform, mediaType) {
  const stringMatches = stringMatch(platform, mediaType, metricType);
  if (stringMatches) {
    return stringMatches;
  }

  switch (metricType) {
    case 'maxConcurrents':
      return 'Max CCVs';
    case 'estimatedMinutesWatched':
      return 'Est. Minutes Watched';
    case 'viewDuration':
      return 'Minutes Watched';
    case 'averageViewDuration':
      return 'Avg. View Duration';
    case 'averageEngagementRate':
      return 'Avg. Engagement Rate';
    case 'ccv':
      return 'CCV';
    case 'contentPieces':
      return 'Content Pieces';
    case 'durationInHours':
      return isActual ? 'Hours Streamed' : 'Hours/Stream';
    case 'liveViews':
      return 'Total Live Views';
    case 'Unique Visitors':
      return 'Unique Visitors';
    case 'cac':
      return 'Customer aquisition cost (CAC)';
    case 'ctr':
      return 'Click through rate (CTR)';
    case 'cpc':
      return 'Cost per click (CPC)';
    case 'cpv':
      return 'Cost per view (CPV)';
    default:
      return metricType
        .split(/(?=[A-Z])/)
        .map((element) => upperfirst(element))
        .join(' ');
  }
}
