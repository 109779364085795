import ManualMetricCellRenderer from 'components/DataGrid/Destination/Cells/ManualMetric/ManualMetricCellRenderer';
import ManualMetrics from 'components/DataGrid/Editors/Destination/ManualMetrics/ManualMetrics';
import { Placement } from 'shared/types/Placement';
import { ColDef, ValueSetterParams } from 'ag-grid-community';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import manualMetricsValueGetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import manualMetricsValueSetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueSetter';

export function manualMetricColDef(newColDef: ColDef) {
  return {
    ...newColDef,
    editable({ data }: { data: Placement }) {
      return !(!data?.source?.id || !data?.source?.url);
    },
    cellRenderer: ManualMetricCellRenderer,
    cellRendererParams: ({
      value,
      colDef,
    }: {
      value: number;
      colDef: ColDef;
    }) => ({
      value,
      metric: 'customManualMetric',
      automatedMetricsGetter: () => false,
      postCustomColumnsUuid: colDef?.field,
    }),
    valueGetter({
      data,
      context,
      colDef,
    }: {
      data: Placement;
      context: Context;
      colDef: ColDef;
    }) {
      if (!colDef?.field) {
        return null;
      }
      return manualMetricsValueGetter(
        data,
        context,
        'customManualMetric',
        colDef?.field,
      );
    },
    valueSetter(params: ValueSetterParams<Placement>) {
      return manualMetricsValueSetter(
        params,
        'customManualMetric',
        params?.colDef?.field,
      );
    },
    cellEditor: ManualMetrics,
    cellEditorParams: ({ colDef }: { colDef: ColDef }) => ({
      metric: 'customManualMetric',
      postCustomColumnsUuid: colDef?.field,
    }),
    cellEditorPopup: true,
  };
}
