import React from 'react';
import EmptyState from 'external/components/UI/EmptyState/EmptyState';

import noResultsEmptyState from 'components/assets/img/brand-app-no-results-empty-state.svg';

function NoResultsEmptyState() {
  return (
    <EmptyState
      message="Looks like there’s no results for this filter"
      info="We’ll start populating this as soon as we get content. For now, try clicking on another filter."
      image={noResultsEmptyState}
    />
  );
}

export default NoResultsEmptyState;
