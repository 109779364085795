import React from 'react';
import ToggleLabel from '@UIComponents/ToggleLabel/ToggleLabel';
import { drawerTabsTexts } from 'components/Creator/constants';
import { DrawerTabsLabels } from 'components/Creator/types';
import { Property } from 'components/Property/types';
import './NavBar.scss';

export default function NavBar({
  toggledTab,
  property,
  onClick,
}: {
  toggledTab: DrawerTabsLabels;
  property: Property;
  onClick: (tab: DrawerTabsLabels, property: Property) => void;
}) {
  return (
    <nav className="nav-bar">
      {(Object.keys(drawerTabsTexts) as Array<DrawerTabsLabels>).map((key) => (
        <ToggleLabel
          key={key}
          toggleKey={key}
          label={String(drawerTabsTexts[key].label)}
          toggled={String(toggledTab) === key}
          onClick={onClick}
          optionsObject={property}
        />
      ))}
    </nav>
  );
}
