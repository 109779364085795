import { useEffect, useRef } from 'react';

export default function useClickOutside(onClick, disabled) {
  const containerRef = useRef(null);

  useEffect(() => {
    function onDocumentClick(e) {
      const portal = document.getElementById('portal-root');

      if (
        containerRef.current &&
        !containerRef.current.contains(e.target) &&
        !portal.contains(e.target)
      ) {
        onClick(e);
      }
    }

    if (!disabled) {
      document.addEventListener('click', onDocumentClick, true);
    }

    return () => {
      if (!disabled) {
        document.removeEventListener('click', onDocumentClick, true);
      }
    };
  }, [onClick, disabled]);

  return containerRef;
}
