import { Placement } from 'shared/types/Placement';
import {
  CellValueChangedEvent,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import currency from 'components/DataGrid/formatters/currency';
import { getTotalFee } from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/totalFee';
import UpdatePlacement from 'components/Campaign/Influencer/View/Stats/Performance/graphql/UpdatePlacement.graphql';
import currencyColDef from 'components/DataGrid/Destination/colDef/currencyColDef';

function getMatchingMediaTargetQty(data?: Placement) {
  const matchingMediaTarget = (data?.integration?.mediaTargets ?? []).find(
    (m) =>
      m?.platform === data?.platform &&
      m?.mediaType === data?.mediaType &&
      (m?.style ?? null) === (data?.style ?? null),
  );

  return Number(matchingMediaTarget?.quantity ?? 0);
}

export function getPostOrTotalFee(data: Placement) {
  const matchingMediaTargetQty = getMatchingMediaTargetQty(data);
  if (matchingMediaTargetQty && matchingMediaTargetQty < 2) {
    return getTotalFee(data);
  }
  return data?.postFee ?? null;
}

export default function postFee() {
  return {
    ...currencyColDef,
    valueFormatter: ({ value }: { value: number }) => currency({ value }, 2),
    valueGetter: ({ data }: ValueGetterParams<Placement>) => {
      if (!data) {
        return null;
      }
      return getPostOrTotalFee(data);
    },
    editable: ({ data }: ValueGetterParams<Placement>) => {
      const matchingMediaTargetQty = getMatchingMediaTargetQty(data);
      return matchingMediaTargetQty > 1;
    },
    valueSetter: (params: ValueSetterParams<Placement>) => {
      if (params.newValue === params.oldValue) {
        return false;
      }

      if (Number.isNaN(Number(params.newValue))) {
        return false;
      }

      /* eslint-disable-next-line no-param-reassign */
      params.data.postFee = params.newValue;
      return true;
    },

    onCellValueChanged: ({
      data,
      context,
      newValue,
    }: CellValueChangedEvent<Placement>) => {
      context.apolloClient.mutate({
        mutation: UpdatePlacement,
        variables: {
          id: data?.id,
          payload: {
            postFee: Number(newValue),
          },
        },
      });
    },
    headerName: 'Post Fee',
    field: 'postFee',
  };
}
