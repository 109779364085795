import { number, oneOfType, bool, node } from 'prop-types';
import classnames from 'classnames';

import './IconChatCounter.scss';

const cbn = 'ui-icon-chat-counter';

const formatNumber = (value: number) => (value > 99 ? '+99' : value);

const getNotesText = (value: number) =>
  (value > 1 ? `${formatNumber(value)} Notes` : `${value} Note`);

type UIIconChatCounterProps = WithChildren<{
  otherCount: number;
  onClick: () => void;
}>;

function UIIconChatCounter({
  children,
  otherCount,
  onClick,
}: UIIconChatCounterProps) {
  const notesText = getNotesText(otherCount);

  return (
    <span
      data-cy="chat-container"
      onClick={onClick}
      className={classnames(cbn)}
    >
      {children}
      <span
        data-cy="chat-container-value"
        className={classnames(`${cbn}__value`)}
      >
        <span
          data-cy="chat-container-count"
          className={classnames(`${cbn}__other-count`)}
        >
          {notesText}
        </span>
      </span>
    </span>
  );
}

UIIconChatCounter.propTypes = {
  children: node.isRequired,
  otherCount: oneOfType([number, bool, node]),
};

UIIconChatCounter.defaultProps = {
  otherCount: false,
};

export default UIIconChatCounter;
