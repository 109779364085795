import BaseDomoIframe from './BaseDomoIframe';
import { FrameProps } from './types';

function DomoIframeTwitch({ campaignId = null }: FrameProps) {
  return (
    <BaseDomoIframe
      IframeName="Twitch"
      campaignId={campaignId}
      platform="twitch"
    />
  );
}

export default DomoIframeTwitch;
