// @ts-nocheck
/* eslint-disable react/no-danger */
import React from 'react';

type FormFileUploaderDropzoneTextAcceptMessageProps = {
  children: string;
};

function FormFileUploaderDropzoneTextAcceptMessage({
  children,
}: FormFileUploaderDropzoneTextAcceptMessageProps) {
  return !children ? null : (
    <small className="accepts" dangerouslySetInnerHTML={{ __html: children }} />
  );
}

FormFileUploaderDropzoneTextAcceptMessage.defaultProps = {
  children: null,
};

export default FormFileUploaderDropzoneTextAcceptMessage;
