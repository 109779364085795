import React from 'react';
import { any, oneOfType, string, number, bool } from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  colHeader: string,
  // eslint-disable-next-line react/forbid-prop-types
  component: any,
  id: oneOfType([string, number]),
  small: bool,
};

const defaultProps = {
  colHeader: null,
  component: 'div',
  id: null,
  small: false,
};

/*
  @params {Component} component - component to render the column content;
*/
function UITableCol({
  id,
  colHeader,
  small,
  component: Component,
  ...restProps
}) {
  return (
    <div
      className={classNames('ui-table__col', 'ui-table__body__col', {
        [`ui-table__col--${id}`]: id,
        [`ui-table__body__col--${id}`]: id,
        [`ui-table__header-col--${colHeader}`]: colHeader,
        'is-smaller': small,
      })}
    >
      <Component {...restProps} />
    </div>
  );
}

UITableCol.propTypes = propTypes;
UITableCol.defaultProps = defaultProps;

export default UITableCol;
