import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export interface QuickLink {
  created: string;
  displayName: string;
  url: string;
}

export interface ICampaignQuickLinks {
  campaign: {
    id: number;
    quickLinks: QuickLink[];
  };
}

const quickLinksGql = gql`
  query CampaignQuickLinks($id: Int!) {
    campaign(id: $id) {
      id
      quickLinks {
        url
        displayName
        created
      }
    }
  }
`;

function QuickLinksService(campaignId: number): QuickLink[] | null {
  const { data } = useQuery<ICampaignQuickLinks>(quickLinksGql, {
    variables: {
      id: campaignId,
    },
  });

  if (!data) return null;

  return data.campaign.quickLinks;
}

export default QuickLinksService;
