import React from 'react';
import {
  TCMReportMetrics,
  TCMStateCountryAbreviations,
} from 'shared/types/TikTokMarketPlace';

import ProgressionTable from '../../../../../ProgressionTable/ProgressionTable';
import {
  getAgeRows,
  getGenderRows,
  getCountryRows,
  getStatesRows,
} from './utils/getRows';

import './Audience.scss';
import stateCountryMapping from './constants/stateCountryMapping';

export default function Audience({ metrics }: { metrics?: TCMReportMetrics }) {
  const [stateCountry, setStateCountry] = React.useState<
    TCMStateCountryAbreviations | undefined
  >(undefined);

  const regionRows = React.useMemo(() => {
    if (metrics?.lifetime_top_country_distribution?.length) {
      return getCountryRows(metrics?.lifetime_top_country_distribution);
    }

    if (metrics?.most_popular_country_state_distribution) {
      const stateDistribution = Array.isArray(
        metrics?.most_popular_country_state_distribution,
      )
        ? metrics?.most_popular_country_state_distribution[0]
        : metrics?.most_popular_country_state_distribution;

      setStateCountry(stateDistribution?.country);
      return getStatesRows(stateDistribution);
    }

    return [];
  }, [
    metrics?.lifetime_top_country_distribution,
    metrics?.most_popular_country_state_distribution,
  ]);

  return (
    <section className="tiktok-video-content__metrics__audience">
      <h2 className="header-title">Audience</h2>
      <ProgressionTable
        title="Age range"
        subtitle="AUTH"
        rows={getAgeRows(metrics?.lifetime_top_age_distribution ?? [])}
      />

      <ProgressionTable
        title="Gender"
        rows={getGenderRows(metrics?.lifetime_gender_distribution ?? [])}
      />

      {regionRows?.length ? (
        <ProgressionTable
          title={
            stateCountry
              ? `Top Regions ( ${stateCountryMapping[stateCountry]} )`
              : 'Top Regions'
          }
          rows={regionRows}
          showOverflowTooltip
        />
      ) : null}
    </section>
  );
}
