import formatters from 'components/DataGrid/formatters';
import { CellClassParams } from 'ag-grid-community';

const defaultColDef = {
  width: 140,
  valueFormatter: formatters.auto,
  filter: 'agNumberColumnFilter',
  filterParams: {
    defaultOption: 'greaterThan',
    buttons: ['reset'],
    newRowsAction: 'keep',
    debounceMs: 200,
  },
  sortable: true,
  cellClassRules: {
    editable: (params: CellClassParams): boolean => {
      if (typeof params?.colDef?.editable === 'function') {
        // @ts-ignore
        return params?.colDef?.editable(params);
      }
      return params?.colDef?.editable;
    },
  },
  enableCellChangeFlash: true,
  resizable: true,
};

export default defaultColDef;
