import './CancelButton.scss';

export default function CancelButton({
  onClick,
  text = 'Cancel',
  dataCy,
}: {
  onClick?: () => void;
  text?: string;
  dataCy?: string;
}) {
  return (
    <button
      data-cy={dataCy ?? null}
      type="button"
      className="btn-cancel"
      onClick={onClick}
    >
      {text}
    </button>
  );
}
