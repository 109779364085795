import UIImage from 'components/UI/Image/Image';
import { TCMSparkAdStatusData } from 'shared/types/TikTokMarketPlace';

import VideoStatusData from './VideoStatusData/VideoStatusData';

import './UpdateContent.scss';

export default function UpdateContent({
  sparkAdStatusData,
  contentLink,
  thumbnailUrl,
  trackContentLinkClick,
  tikTokSparkAdStatusRefetch,
}: {
  sparkAdStatusData: TCMSparkAdStatusData;
  contentLink: string | undefined | null;
  thumbnailUrl: string | undefined | null;
  trackContentLinkClick?: () => void;
  tikTokSparkAdStatusRefetch: () => void;
}) {
  return (
    <section
      className={`tiktok-spark-ads__update__content ${
        thumbnailUrl ? '' : 'no-image'
      }`}
    >
      {thumbnailUrl ? <UIImage url={thumbnailUrl} /> : null}

      <VideoStatusData
        sparkAdStatusData={sparkAdStatusData}
        contentLink={contentLink}
        trackContentLinkClick={trackContentLinkClick}
        tikTokSparkAdStatusRefetch={tikTokSparkAdStatusRefetch}
      />
    </section>
  );
}
