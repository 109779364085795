import { Placement } from 'shared/types/Placement';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';

import { Context } from '../../../shared/types';
import { ReportingWindow } from '../../../types';

export default function tuneClicks() {
  return {
    ...numberColDef,
    field: 'tuneClicks',
    headerName: 'TUNE Clicks',
    valueGetter: ({ data, context }: { data: Placement; context: Context }) => {
      if (!context?.reportingWindow || !data?.tuneMetrics) {
        return null;
      }

      return data?.tuneMetrics?.[context?.reportingWindow as ReportingWindow]
        ?.clicks;
    },
  };
}
