import { useQuery } from '@apollo/react-hooks';
import DeliverablesQuery from 'components/Creator/graphql/DeliverablesSearch.graphql';
import { Property } from 'components/Property/types';
import Loader from 'components/UI/Loader/Loader';
import ProjectHistory from '@UIComponents/PlatformCards/ProjectHistory/ProjectHistory';

const LIMIT = 50;

export default function ProjectHistoryContainer({
  property,
}: {
  property?: Property;
}) {
  const { data, loading } = useQuery(DeliverablesQuery, {
    skip: !property?.id,
    variables: {
      limit: LIMIT,
      skip: 0,
      sort: 'updated-desc',
      f: {
        propertyId: [property?.id],
      },
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{ margin: '2rem 1rem' }}>
      {Number(data?.deliverables?.data?.length) === 0
        ? 'Creator has no other projects'
        : null}
      {data?.deliverables?.data?.length > 0 ? (
        <ProjectHistory deliverables={data?.deliverables?.data ?? []} />
      ) : null}
    </div>
  );
}
