import { Placement } from 'components/Placement/types';
import { Platforms as Platform } from 'shared/types/platforms';

export function formatUserId(userId: number) {
  return `user_${userId}`;
}

export function formatCampaignId(campaignId: number) {
  return `ben-campaign-${campaignId}`;
}

export function formatRemoteId(platform: Platform | undefined, remoteId: string | undefined) {
  return `${platform}-channel-${remoteId}`;
}

export function getPlacementRemoteId(placement: Placement) {
  return placement.platform === 'youtube'
    ? placement.source?.production?.channelId
    : placement.source?.production?.username;
}

export function isValidPlacement(placement: Placement) {
  if (!placement.platform) {
    return false;
  }

  const remoteId = getPlacementRemoteId(placement);

  if (!remoteId) {
    return false;
  }

  return true;
}
