import getUTCDateAtBeginningOfDay from '../../dates/getUTCDateAtBeginningOfDay';
import { TIKTOK_PLATFORM } from '../../metrics/metrics';
import { Placement } from '../../types/Placement';

export function getPostReleaseDate(placement: Placement) {
  if (placement?.platform === TIKTOK_PLATFORM) {
    /*
     * Use the fixed  media insight endpoint to get the proper value;
     */
  }

  return getUTCDateAtBeginningOfDay(
    // @ts-ignore
    placement?.source?.releaseDate ?? placement?.source?.estimatedReleaseDate,
  );
}
