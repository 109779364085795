import tikTokCommon from 'components/Campaign/Influencer/View/Stats/Performance/columns/tikTok/helpers/tikTokCommon';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import TCMReportMetrics from 'shared/types/TikTokMarketPlace';

const tikTokMetric = 'fct_lifetime_video_performance.video_views_by_source_personal_profile' as keyof typeof TCMReportMetrics;
const metric = 'video_views_by_source-personal_profile';
export default function personalProfileTraffic() {
  return {
    ...numberColDef,
    ...tikTokCommon(tikTokMetric, metric),
    headerName: 'Personal Profile Traffic',
    width: 270,
  };
}
