export const isVideo = (mime: string) => /video\/vnd/.test(mime);

export function getMediaTypeFromUrl(url: string) {
  const lowerUrl = url?.toLowerCase() ?? '';
  if (/youtube\.|youtu\.be/.test(lowerUrl)) {
    return 'video';
  }

  if (/\.[mp4|mov]+$/.test(lowerUrl)) {
    return 'video';
  }

  if (/\.[jpeg|jpg|png|gif]+$/.test(lowerUrl)) {
    return 'image';
  }
  return 'unknown';
}
