import formatters from 'components/DataGrid/formatters';
import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';

import tikTokCommon from './helpers/tikTokCommon';

export default function averageViewTime() {
  const tikTokMetric = 'fct_lifetime_video_performance.average_view_time_sec' as keyof typeof TCMReportMetrics;
  const metric = 'average_view_time' as keyof typeof TCMReportMetrics;
  return {
    ...numberColDef,
    ...tikTokCommon(tikTokMetric, metric),
    valueFormatter: ({ value }: { value: number | null }) =>
      formatters.number({ value, format: '0,0.00' }),
    field: 'averageViewTime',
    headerName: 'Average View Time',
    width: 245,
  };
}
