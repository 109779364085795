import React, { useState } from 'react';

import BaseButton from '@UIComponents/BaseButton/BaseButton';
import BaseModal from '@UIComponents/BaseModal/BaseModal';
import RadioButton from '@UIComponents/RadioButton/RadioButton';

const rejectionOptions: string[] = [
  'Doesn’t align with my brand/messaging',
  'Doesn’t meet my brand’s quality standards',
  'Doesn’t meet my expected performance minimum',
  'Has a questionable / unsavory history',
  'Not enough info to make approve for outreach',
  'Other',
];

type RejectionModalProps = {
  handleRejection: (arg1: string) => Promise<void>;
  isOpen: boolean;
  name: string;
  onClose: () => void;
  rejected: boolean;
};

function RejectionModal({
  handleRejection,
  isOpen,
  name,
  onClose,
  rejected,
}: RejectionModalProps) {
  const [activeOption, setActiveOption] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setActiveOption(event.target.name);

  const onConfirmRejection = (option: string) => {
    let rejectionReason = option;

    if (option === 'Other') {
      const textarea = (document?.getElementById(
        'rejection-reason-textarea',
      ) as HTMLTextAreaElement)?.value;

      rejectionReason = textarea ? `Other: ${textarea}` : option;
    }

    handleRejection(rejectionReason);
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={onClose} title="Decline creator">
      <div className="external-content-detail-panel-modal">
        {rejected ? (
          <>
            <p className="bold">{`${name} has been declined`}</p>
            <p>
              Thank you! We’ll note your reason and feed it to our data models
              to make better suggestions in the future
            </p>
            <div className="button-wrapper">
              <BaseButton text="Got it!" onClick={onClose} />
            </div>
          </>
        ) : (
          <>
            <p className="bold">Please select a declination reason</p>
            <div>
              {rejectionOptions.map((option, key) => {
                const checked = option === activeOption;

                return (
                  <RadioButton
                    checked={checked}
                    label={option}
                    onClick={handleChange}
                    value={option}
                    key={key}
                  />
                );
              })}
            </div>
            {activeOption === 'Other' ? (
              <div className="other-rejection-option-textarea">
                <p>Please tell us why in a few short words</p>
                <textarea id="rejection-reason-textarea" />
              </div>
            ) : null}
            <div className="button-wrapper">
              <BaseButton
                text="Decline creator"
                onClick={() => onConfirmRejection(activeOption!)}
                disabled={!activeOption}
              />
              <BaseButton text="Cancel" variant="text" onClick={onClose} />
            </div>
          </>
        )}
      </div>
    </BaseModal>
  );
}

export default RejectionModal;
