import { INSTAGRAM_PLATFORM, STORY_MEDIA_TYPE } from 'shared/metrics/kpis';
import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';

const metric = 'linkClickSwipeUps';
export default function linkClickSwipeUps() {
  return {
    ...basePerformanceColDef(
      metric,
      () => null,
      [INSTAGRAM_PLATFORM],
      [STORY_MEDIA_TYPE],
    ),
    headerName: 'Link Click/Swipe ups',
    width: 255,
  };
}
