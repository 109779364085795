import React from 'react';
import Avatar from '@UIComponents/Avatar/Avatar';
import { Property } from 'components/Property/types';
import Details from 'components/Creator/Profile/Details/Details';
import SectionHeader from '@UIComponents/SectionHeader/SectionHeader';
import getThumbnailFromProductions from 'shared/utils/productions/getThumbnailFromProductions';
import MultiPlatformsCombo from '@UIComponents/MultiPlatformCombo/MultiPlatformCombo';
import getPlatformsData from 'components/Creator/Profile/Header/utils/getPlatformsData';
import LabelValueComboHighlighted from '@UIComponents/LabelValueComboHighlighted/LabelValueComboHighlighted';
import { Campaign } from 'components/Campaign/types';
import Blacklisted from 'components/Creator/Profile/Header/Blacklisted/Blacklisted';
import './Header.scss';

export default function Header({
  property,
  refetchProperty,
  campaign,
  canEdit = true,
}: {
  property?: Property;
  refetchProperty: () => void;
  campaign?: Campaign;
  canEdit: boolean;
}) {
  const [imageError, setImageError] = React.useState(false);

  const isBlacklist = property?.isBlacklist;
  const blacklistReason = property?.blacklistReason;

  return (
    <article className="creator-profile__header-container">
      <Avatar
        alt="avatar"
        name="avatar"
        size="medium-large"
        imageError={imageError}
        setImageError={setImageError}
        thumb={getThumbnailFromProductions(property?.productions)}
        dynamic
      />

      <SectionHeader
        className={isBlacklist ? 'blocked' : ''}
        label={property?.name}
      />

      {isBlacklist ? <Blacklisted reason={blacklistReason} /> : null}

      <LabelValueComboHighlighted
        label="Vendor ID:"
        value={property?.vendorId}
      />
      <MultiPlatformsCombo
        platforms={getPlatformsData(property?.productions)}
      />
      {canEdit ? (
        <Details
          property={property}
          refetchProperty={refetchProperty}
          campaign={campaign}
        />
      ) : null}
    </article>
  );
}
