import React from 'react';
import { ICellEditorParams } from 'ag-grid-community/dist/lib/main';

const NumberInput = React.forwardRef(({ value }: ICellEditorParams, ref) => {
  const [innerValue, setInnerValue] = React.useState(value);
  const refInput = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (refInput?.current?.focus) {
      refInput.current.focus();
    }
  }, []);
  React.useImperativeHandle(ref, () => ({
    getValue: () => innerValue,
  }));

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInnerValue(ev?.target?.value);
  };

  return (
    <input
      ref={refInput}
      onChange={handleInputChange}
      defaultValue={value || ''}
      className="form-input-text"
      type="number"
    />
  );
});

NumberInput.displayName = 'NumberInput';

export default NumberInput;
