export default function ChevronRightDouble({
  fill = 'none',
}: {
  fill?: string;
}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 17L11 12L6 7M13 17L18 12L13 7"
        stroke="#8F8F8F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
