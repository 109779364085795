import { TWITCH_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';
import basePerformanceColDef from '../../helpers/baseColDef';

export default function twitchCommon(metric: string) {
  return {
    ...basePerformanceColDef(metric, () => null, [TWITCH_PLATFORM]),
    editable({ data }: { data: Placement }) {
      if (!data?.source?.id) {
        return false;
      }
      return data?.platform === TWITCH_PLATFORM;
    },
  };
}
