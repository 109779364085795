import React from 'react';
import { string, number } from 'prop-types';
import { saveFile, downloadFromAPI } from 'components/utils/file';

const canBeDownloaded = (url) => {
  if (!url) {
    return false;
  }

  const isHttps = /^https/.test(url);

  if (!isHttps) {
    return false;
  }

  const urlObject = new URL(url);
  return urlObject.origin === window.location.origin;
};

function DownloadFile({
  url,
  cbn,
  fileName,
  resourceId,
  resourceType,
  fileId,
}) {
  async function getBlob() {
    const windowProtocol = new URL(window.location).protocol;
    const fileProtocol = new URL(url).protocol;
    if (
      resourceType &&
      resourceId &&
      fileId &&
      (windowProtocol === 'http:' || fileProtocol === 'http:')
    ) {
      downloadFromAPI(resourceType, resourceId, fileId, fileName);
    } else {
      saveFile(url, fileName);
    }
  }

  const directDownload = canBeDownloaded(url);

  if (directDownload) {
    return (
      <a
        className={`${cbn}__download`}
        href={url}
        /* eslint-disable-next-line react/no-unknown-property */
        donwload={fileName}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    );
  }

  return (
    <button
      type="button"
      className={`${cbn}__download`}
      onClick={getBlob}
      onKeyPress={getBlob}
    >
      Download
    </button>
  );
}

DownloadFile.propTypes = {
  cbn: string,
  url: string,
  fileName: string,
  resourceId: number,
  resourceType: string,
  fileId: number,
};

DownloadFile.defaultProps = {
  cbn: 'file',
  url: null,
  fileName: null,
  resourceId: null,
  resourceType: null,
  fileId: null,
};

export default DownloadFile;
