import { InitialData } from 'components/Tiktok/types';
import { Deliverable } from 'shared/types/Deliverable';
import { Production, TCMCreatorMediaInsights } from 'shared/types/Production';
import { TCMSparkAdStatusData } from 'shared/types/TikTokMarketPlace';

export default function mergeData(
  initialData: InitialData | null,
  data?: {
    deliverable?: Deliverable;
    tiktokVideoStatus: TCMSparkAdStatusData;
    tikTokCreatorMediaInsights: TCMCreatorMediaInsights;
  },
) {
  if (!initialData || !data?.deliverable) {
    return null;
  }

  const productionUrl = initialData?.sourceUrl?.match(
    /(^.*)(?:\/video).*/,
  )?.[1];

  if (!productionUrl) {
    return null;
  }
  const production = (data?.deliverable?.property?.productions ?? []).find(
    (prod: Production) => prod?.url === productionUrl,
  );

  if (!production) {
    return null;
  }

  return {
    ...initialData,
    production,
    deliverable: data?.deliverable,
    tiktokVideoStatus: data?.tiktokVideoStatus,
    tikTokCreatorMediaInsights: data?.tikTokCreatorMediaInsights,
  };
}
