import React from 'react';
import ReportingWindowSelector from 'components/Campaign/Influencer/View/ReportingWindows/Selector/ReportingWindowSelector';
import { Placement } from 'shared/types/Placement';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import {
  getOffsetByReportingKey,
  reportingWindows,
} from 'shared/campaigns/creatorCampaigns/reportingWindows';
import { getPostReleaseDate } from 'shared/campaigns/creatorCampaigns/postDates';
import { ManualMetricValue } from 'components/Campaign/Influencer/View/Stats/Performance/columns/types';
import { ReportingSegmentEvent } from 'shared/campaigns/creatorCampaigns/segmentEnums';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import PlacementDrawer from 'components/Placement/PlacementDrawer/Drawer/PlacementDrawer';
import { ReportingWindowOption } from 'components/Campaign/Influencer/View/ReportingWindows/types/ReportingWindow';

import useManualMetricAlertMessage from './hooks/useManualMetricAlertMessage';
import useManualMetricActions from './hooks/useManualMetricActions';

import './ManualMetrics.scss';

const reportingWindowsOptions = Object.entries(
  reportingWindows,
).map((entry) => ({ value: entry[0], label: entry[1] }));

const ManualMetrics = React.forwardRef(
  (
    {
      metric,
      data,
      stopEditing,
      value: originalValue,
      context,
      trackSegmentEvent,
    }: {
      metric: string;
      data: Placement;
      stopEditing: () => void;
      value: number;
      context: Context;
      trackSegmentEvent: ReportingSegmentEvent;
    },
    ref,
  ) => {
    const [done, setDone] = React.useState(false);
    const [showDrawer, setShowDrawer] = React.useState(false);
    const inputRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(
      null,
    );

    const {
      contextReportingWindow,
      postReleaseDate,
      campaignDateRangeFrom,
    } = React.useMemo(
      () => ({
        contextReportingWindow:
          (context?.reportingWindow as keyof typeof reportingWindows) ?? null,
        postReleaseDate: getPostReleaseDate(data),
        campaignDateRangeFrom: context?.campaign?.dateRangeFrom ?? null,
      }),
      [context?.reportingWindow, data, context?.campaign?.dateRangeFrom],
    );

    const [
      reportingWindow,
      setReportingWindow,
    ] = React.useState<ReportingWindowOption | null>(() => {
      if (!contextReportingWindow) {
        return null;
      }

      const reportingWindowOption = reportingWindowsOptions.find(
        (option) => option?.value === contextReportingWindow,
      );

      return {
        label: reportingWindowOption?.label ?? '',
        value: { key: reportingWindowOption?.value ?? '' },
      } as ReportingWindowOption;
    });

    const [
      metricValue,
      setMetricValue,
    ] = React.useState<ManualMetricValue | null>({
      value: originalValue,
      reportingWindow: {
        key: context?.reportingWindow,
        date: getOffsetByReportingKey(
          contextReportingWindow,
          campaignDateRangeFrom ?? undefined,
          postReleaseDate ? postReleaseDate?.toISOString() : undefined,
        )?.toISOString(),
      },
    });

    const {
      onChange,
      onCancel,
      onUpdate,
      onReportingWindowChange,
    } = useManualMetricActions(
      metric,
      metricValue,
      setReportingWindow,
      setMetricValue,
      setDone,
      trackSegmentEvent,
    );

    React.useEffect(() => {
      if (!inputRef?.current?.focus) {
        return;
      }
      inputRef.current.focus();
    }, []);

    React.useEffect(() => {
      if (done) {
        stopEditing();
      }
    }, [done, stopEditing]);

    React.useImperativeHandle(ref, () => ({
      getValue: () => metricValue,
      isPopup: () => true,
    }));

    const alertMessage = useManualMetricAlertMessage(data, context, metric);

    if (!reportingWindow) {
      return null;
    }
    return (
      <section className="destination-manual-metrics">
        {alertMessage ? (
          <p className="destination-manual-metrics__alert-message">
            {alertMessage}
          </p>
        ) : null}

        <input
          data-cy="manual-metric-input"
          className="destination-manual-metrics__value"
          ref={inputRef}
          type="number"
          value={metricValue?.value ?? ''}
          onChange={onChange}
        />

        <div className="destination-manual-metrics__reporting-window">
          <ReportingWindowSelector
            value={reportingWindow?.value?.key ?? null}
            onChange={onReportingWindowChange}
            campaignDateRangeFrom={campaignDateRangeFrom ?? undefined}
            postReleaseDate={postReleaseDate?.toISOString()}
            campaignReportingWindows={
              context?.campaign?.creatorReportingWindows ?? null
            }
          />
          <BaseButton
            text="View History"
            color="grey-dark"
            size="x-small"
            variant="text"
            className="btn-view-history"
            onClick={() => setShowDrawer(true)}
          />
        </div>

        <div className="destination-manual-metrics__buttons">
          <button
            data-cy="manual-metric-update"
            type="button"
            className="btn-update"
            onClick={onUpdate}
          >
            Update
          </button>

          <button type="button" className="btn-cancel" onClick={onCancel}>
            Cancel
          </button>
        </div>
        <PlacementDrawer
          placement={data}
          showDrawer={showDrawer}
          onRequestClose={() => setShowDrawer(false)}
        />
      </section>
    );
  },
);

ManualMetrics.displayName = 'ManualMetrics';
export default ManualMetrics;
