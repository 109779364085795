import UIModal from 'components/UI/Modal/Modal';
import SectionLabel from '@UIComponents/SectionLabel/SectionLabel';

import '../SparkAdsModals.scss';

export default function SendRequestConfirmationModal({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) {
  return (
    <UIModal
      className="spark-ads-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <SectionLabel text="Success!" size="large" />
      <p className="confirmation-text">
        You&apos;ve requested approval to use this content for a TikTok Spark
        Ad! The creator will receive a notification in their Tiktok account.
        Please send them the Spark Ads guide to help them through the process.
      </p>
      <a
        className="send-request"
        href="https://benlabs-whiteglove-influencer.s3.us-west-2.amazonaws.com/ttcm-integration/spark-ads/Spark+Ads+Guide.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Download Spark Ads guide
      </a>
    </UIModal>
  );
}
