import { getIn } from 'formik';
import { isArray } from 'lodash';

export const hasValue = (v) => {
  if (v === false) {
    return true;
  }

  if (!v) {
    return false;
  }

  if (Array.isArray(v)) {
    return v.length > 0;
  }

  if (typeof v === 'object') {
    return (
      Object.values(v).filter((objectValue) => objectValue || objectValue === 0)
        .length > 0
    );
  }

  return Boolean(v);
};

export function getActiveSectionsAndFields(fields, values) {
  const activeSections = {};
  const activeFilters = {};

  Object.entries(fields).forEach(([field, registration]) => {
    if (!registration) {
      return;
    }
    const { section, getValue } = registration;
    const value = getValue ? getValue(values, field) : getIn(values, field);

    if (hasValue(value)) {
      activeFilters[field] = true;
      activeSections[section] = true;
    }
  });
  return [activeSections, activeFilters];
}

export function getFieldSelectedCount(fields, values) {
  // returns full count for all selected options in all fields
  let count = 0;
  Object.entries(fields).forEach(([field, registration]) => {
    if (!registration) {
      return;
    }
    const value = getIn(values, field);
    if (hasValue(value)) {
      count += isArray(value) ? value.length : 1;
    }
  });
  return count;
}
