import React from 'react';
import { bool, oneOfType, node, arrayOf, string } from 'prop-types';
import classnames from 'classnames';

import './Col.scss';

function UIGridCol({
  children,
  xs,
  sm,
  md,
  lg,
  xlg,
  xxlg,
  mtn,
  className,
  noMargin,
  noPadding,
}) {
  return (
    <div
      className={classnames('ui-grid-col', className, {
        [`ui-grid-col--xs-${xs}`]: xs,
        [`ui-grid-col--sm-${sm}`]: sm,
        [`ui-grid-col--md-${md}`]: md,
        [`ui-grid-col--lg-${lg}`]: lg,
        [`ui-grid-col--xlg-${xlg}`]: xlg,
        [`ui-grid-col--xxlg-${xlg}`]: xxlg,
        'ui-grid-col--mtn': mtn,
        'ui-grid-col--no-margin': noMargin,
        'ui-grid-col--no-padding': noPadding,
      })}
    >
      {children}
    </div>
  );
}

UIGridCol.propTypes = {
  className: string,
  children: oneOfType([node, arrayOf(node)]),
  xs: string,
  sm: string,
  md: string,
  lg: string,
  xlg: string,
  xxlg: string,
  mtn: bool,
  noMargin: bool,
  noPadding: bool,
};

UIGridCol.defaultProps = {
  className: null,
  children: null,
  xs: null,
  sm: null,
  md: null,
  lg: null,
  xlg: null,
  xxlg: null,
  mtn: false,
  noMargin: false,
  noPadding: false,
};

export default UIGridCol;
