import * as constants from 'constants/constants';
import { getLoadBalancedUrl } from 'utils/urls';

const getSrc = ({ url, imagecachePreset, fallbackImage }) => {
  const defaultImage = constants[fallbackImage || 'defaultImage'];

  if (!imagecachePreset) {
    return getLoadBalancedUrl(url || defaultImage);
  }
  try {
    const urlObj = new URL(url);
    /**
     * We only have imagecache support for buckets
     * `*.files-ben-productplacement-com.s3-website-us-east-1.amazonaws.com/`
     * and `media.nmadirectdata.com`.
     */
    const hostSupportsImageCache = /files-ben-productplacement-com|media\.nmadirectdata\.com/.test(
      urlObj.host,
    );
    if (!hostSupportsImageCache) {
      return urlObj.href;
    }
    // Make an imagecache URL to use
    return getLoadBalancedUrl(
      `${urlObj.origin}/imagecache/${imagecachePreset}${urlObj.pathname}`,
    );
  } catch {
    return defaultImage;
  }
};

export default getSrc;
