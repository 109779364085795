export type SvgSize =
  | 'x-small'
  | 'small'
  | 'mid-small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'huge';

const svgSizeMapping = {
  'x-small': 14,
  small: 16,
  'mid-small': 20,
  medium: 24,
  large: 32,
  'x-large': 36,
  huge: 40,
};

export default svgSizeMapping;
