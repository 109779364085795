import UIIcon from 'components/UI/Icon/Icon';
import { Platforms } from 'shared/types/platforms';
import PlatformIcon from '@UIComponents/PlatformIcon/PlatformIcon';

import './LabelWithIcon.scss';

const cbn = 'data-grid-cell-label-with-icon';

export default function LabelWithIcon({
  label,
  icon,
  tooltip,
  dataCy,
  platform,
}: Readonly<{
  label: string;
  icon: string;
  tooltip: string;
  dataCy: string;
  platform: Platforms;
}>) {
  return (
    <div className={cbn} data-cy={dataCy}>
      {platform?.length ? (
        <PlatformIcon
          platform={platform}
          colorType="blackWhite"
          size="x-large"
        />
      ) : (
        <UIIcon type={icon} size="small" tooltip={tooltip} />
      )}

      <span>{label}</span>
    </div>
  );
}
