import twitterCommon from './helpers/twitterCommon';

const metric = 'detailExpands';
export default function detailExpands() {
  return {
    ...twitterCommon(metric),
    headerName: 'Detail Expands',
    width: 215,
  };
}
