import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { Placement } from 'shared/types/Placement';
import { YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import percent from 'components/DataGrid/formatters/percent';
import basePerformanceColDef from './baseColDef';
import getYouTubeDistributionDataFromCampaignService from '../youTube/helpers/getYouTubeDistributionDataFromCampaignService';

const youTubeMetric = 'viewers_by_age_group';
const youTubeMetricKey = 'Age13-17';
const tikTokMetricKeyType = '13-17';
const metric = `age-${tikTokMetricKeyType}`;

export function age1317AutomatedValueGetter(data: Placement) {
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDistributionDataFromCampaignService(
      data,
      [youTubeMetric, youTubeMetricKey],
      { parsePercentageFromString: true },
    );
  }
  return null;
}

export default function age1317() {
  return {
    ...percentColDef,
    ...basePerformanceColDef(metric, age1317AutomatedValueGetter, [
      YOUTUBE_PLATFORM,
    ]),
    headerName: 'Age 13-17',
    width: 175,
    valueFormatter: ({ value }: { value?: number | null }) =>
      percent({ value }),
  };
}
