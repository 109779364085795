import { useContext } from 'react';
import classnames from 'classnames';
import ApplicationContext from 'components/Application/Context';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import { toggleOrder } from 'external/utils/sortFunction';
import { trackSortClick } from 'external/components/Segment/Segment';
import { Analytics } from '@segment/analytics-next';
import { Campaign } from 'components/Campaign/types';
import { User } from 'components/User/types';

import './ListHeader.scss';

const cbn = 'external-ui-list-header';

type HeaderItemProps = {
  title: string;
  value: string;
  gridArea: string;
};

type ExternalUIListHeaderProps = {
  items: HeaderItemProps[];
  sortBy: string | null;
  sortOrder: string | null;
  setSorting: (arg1: string | null) => void;
  sorting: string | null;
  gridTemplate: 'deliverable' | 'placement' | 'campaign';
};

type TrackSortEventProps = {
  segment: Analytics | null;
  user: User | null;
  campaign?: Campaign | null;
  columnName: string;
};

const trackSortEvent = ({
  segment,
  user,
  campaign,
  columnName,
}: TrackSortEventProps) => {
  if (segment && user) {
    trackSortClick({ segment, campaign, user, columnName });
  }
};

function ExternalUIListHeader({
  items = [],
  sortBy,
  sortOrder,
  setSorting,
  sorting,
}: ExternalUIListHeaderProps) {
  const { user } = useContext(ApplicationContext);
  const { campaign, segment } = useContext(ExternalApplicationContext);

  const handleClick = (item: HeaderItemProps) => {
    trackSortEvent({ segment, user, campaign, columnName: item.title });

    setSorting(toggleOrder(item.value, sorting));
  };

  return (
    <div className="ExternalListItem ListHeader">
      {items.map((item) => (
        <h3
          key={item.value}
          className={classnames(
            `${cbn}__sort__${item.gridArea}`,
            { [`${cbn}__sort--${sortOrder}`]: sortBy === item.value },
            'ExternalListItem--header',
            'ExternalListItem--item',
            `external-deliverable-item__${item.gridArea}`,
          )}
          onClick={() => handleClick(item)}
        >
          {item.title}
        </h3>
      ))}
    </div>
  );
}

export default ExternalUIListHeader;
