import basePerformanceColDef from './helpers/baseColDef';

const metric = 'conversions';

export default function conversions() {
  return {
    ...basePerformanceColDef(metric, () => null),
    headerName: 'Conversions',
  };
}
