import { Context } from 'components/Campaign/Influencer/View/shared/types';
import PlacementDrawerIdToggle from 'components/DataGrid/Cells/Placement/PlacementDrawerIdToggle';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import { Placement } from 'shared/types/Placement';

export default function id() {
  return {
    ...numberColDef,
    field: 'placementId',
    colId: 'placementId',
    headerName: 'Id Edit',
    sortable: true,
    width: 150,
    valueGetter: ({ data }: { data: Placement }) => data?.id,
    cellRenderer: PlacementDrawerIdToggle,
    cellRendererParams: ({
      data,
      context,
    }: {
      data: Placement;
      context: Context;
    }) => ({
      placement: data,
      openPlacementDrawer: () => {
        if (context?.onOpenPlacementDrawer) {
          context.onOpenPlacementDrawer(data);
        }
      },
    }),
  };
}
