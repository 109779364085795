// @ts-nocheck
import React from 'react';
import { getFileType } from '../utils';
import {
  FileType,
  MediaFileComponentProps,
  SortChangePosition,
} from '../types';
import './MediaFileList.scss';

const cbn = 'form-file-uploader-media-file-list';

export type FormFileUploaderMediaFileListProps = {
  allowFullScreen?: boolean;
  disabled?: boolean;
  disablePreview?: boolean;
  disableSort?: boolean;
  downloadingById: { [x: string]: boolean };
  errorsByUrl: { [x: string]: string };
  files?: FileType[];
  isPrivate?: boolean;
  itemComponent: React.ComponentType<MediaFileComponentProps>;
  nonPlayable?: boolean;
  onClick?: (index: number, file: FileType) => void;
  onDownload?: (event: React.MouseEvent, file: FileType) => void;
  onFileRemove: (index: number, url: string) => void;
  onSortEnd?: (position: SortChangePosition) => void;
  progressByUrl: { [x: string]: number };
  resourceId?: number;
  resourceType?: string;
  showLinkButton?: boolean;
  sorting?: boolean;
};

function FormFileUploaderMediaFileList({
  allowFullScreen,
  disabled,
  disablePreview,
  disableSort,
  downloadingById,
  errorsByUrl,
  files,
  isPrivate,
  itemComponent: ItemComponent,
  nonPlayable,
  onClick,
  onDownload,
  onFileRemove,
  onSortEnd,
  progressByUrl,
  resourceId,
  resourceType,
  showLinkButton,
  sorting,
}: FormFileUploaderMediaFileListProps) {
  return (
    <div className={cbn}>
      {files.map((file, index) => (
        <ItemComponent
          allowFullScreen={allowFullScreen}
          className={`${cbn}-list__item`}
          disablePreview={disablePreview}
          downloading={downloadingById[file.id]}
          error={errorsByUrl[file.url]}
          file={file}
          isPrivate={isPrivate}
          key={file.id ?? file.key ?? file.url}
          nonPlayable={nonPlayable}
          onClick={() => onClick && onClick(index, file)}
          onDownload={(e) => onDownload(e, file)}
          onRemove={() => onFileRemove(index, file.url)}
          onSortEnd={onSortEnd}
          progress={progressByUrl[file.url]}
          resourceId={resourceId}
          resourceType={resourceType}
          showDragButton={!disabled && !disableSort}
          showLinkButton={showLinkButton}
          showRemoveButton={!sorting && !disabled}
          type={getFileType(file)}
          /* sortable props */
          disabled={disableSort || disabled}
          index={index}
        />
      ))}
    </div>
  );
}

FormFileUploaderMediaFileList.defaultProps = {
  allowFullScreen: undefined,
  disabled: false,
  disablePreview: false,
  disableSort: false,
  files: [],
  isPrivate: null,
  nonPlayable: false,
  onClick: undefined,
  onDownload: () => {},
  onSortEnd: null,
  resourceId: null,
  resourceType: null,
  showLinkButton: false,
  sorting: false,
};

export default FormFileUploaderMediaFileList;
