type productionRatingOptions = {
  label: string;
  value: string;
};

export const PRODUCTION_RATINGS: { [key: string]: string[] } = {
  film: ['Unrated', 'G', 'PG', 'PG-13', 'R', 'NC-17', 'NR', 'Adult'],
  tv: ['Unrated', 'TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA'],
  digital: [
    'Unrated',
    'Y',
    'Y7',
    'G',
    'PG',
    'Teen+',
    'Mature Audiences',
    'NR',
    'Adult',
  ],
  influencer: [
    'Unrated',
    'Y',
    'Y7',
    'G',
    'PG',
    'Teen+',
    'Mature Audiences',
    'NR',
    'Adult',
  ],
  music: [
    'Unrated',
    'Y',
    'Y7',
    'G',
    'PG',
    'Teen+',
    'Mature Audiences',
    'NR',
    'Adult',
  ],
  instagram: [],
  youtube: [],
  facebook: [],
  twitter: [],
  tiktok: [],
  event: [],
};

export const PRODUCTION_RATINGS_FLATTED: string[] = Object.keys(
  PRODUCTION_RATINGS,
).reduce(
  (acc: string[], item: string) => acc.concat(PRODUCTION_RATINGS[item]),
  [],
);

export const PRODUCTION_RATING_OPTIONS: {
  [key: string]: productionRatingOptions[];
} = Object.keys(PRODUCTION_RATINGS).reduce(
  (options: { [key: string]: productionRatingOptions[] }, channel: string) => {
    const newOptions = { ...options };
    newOptions[channel] = PRODUCTION_RATINGS[channel].map((rating) => ({
      label: rating,
      value: rating,
    }));
    return newOptions;
  },
  {},
);
