import twitchCommon from './helpers/twitchCommon';

const metric = 'totalCCV';
export default function totalCCV() {
  return {
    ...twitchCommon(metric),
    headerName: 'Total CCV',
    width: 180,
  };
}
