import {
  Production,
  QmMetrics,
  Audience as AudienceType,
} from 'components/Production/types';
import { Property } from 'components/Property/types';
import { Campaign } from 'components/Campaign/types';
import { Analytics } from '@segment/analytics-next';
import './CardMetrics.scss';
import Audience from './Audience/Audience';
import BasicStats from './BasicStats/BasicStats';

export default function CardMetrics({
  metrics,
  production,
  property,
  campaign,
  userId,
  segment,
}: {
  metrics: {
    platformMetrics?: QmMetrics | null;
    audienceMetrics?: AudienceType | null;
  };
  production: Production;
  property?: Property;
  campaign?: Campaign;
  userId?: number;
  segment?: Analytics | null;
}) {
  return (
    <section className="youtube-card-metrics">
      <BasicStats
        platformMetrics={metrics?.platformMetrics}
        production={production}
      />
      <Audience
        audienceMetrics={metrics?.audienceMetrics}
        production={production}
        property={property}
        campaignId={campaign?.id}
        userId={userId}
        segment={segment}
      />
    </section>
  );
}
