import svgSizeMapping, { SvgSize } from '../utils/svgSizeMapping';

export default function YouTubeLogo({
  blackWhite,
  size,
}: {
  blackWhite?: boolean;
  size: SvgSize;
}) {
  const color = blackWhite ? '#282828' : '#FF0000';

  return (
    <svg
      width={svgSizeMapping[size]}
      height={svgSizeMapping[size]}
      viewBox="0 0 28 28"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1784 8.39986C25.0524 7.89653 24.7958 7.43537 24.4346 7.06294C24.0733 6.69051 23.6202 6.42001 23.1209 6.27876C21.2968 5.83333 14.0002 5.83333 14.0002 5.83333C14.0002 5.83333 6.70357 5.83333 4.87942 6.32118C4.38017 6.46243 3.92703 6.73293 3.56577 7.10536C3.20451 7.47779 2.94794 7.93896 2.82196 8.44228C2.48811 10.2935 2.32481 12.1715 2.3341 14.0526C2.3222 15.9479 2.48551 17.8401 2.82196 19.7053C2.96084 20.193 3.22317 20.6367 3.58358 20.9934C3.944 21.3501 4.39032 21.6078 4.87942 21.7416C6.70357 22.2294 14.0002 22.2294 14.0002 22.2294C14.0002 22.2294 21.2968 22.2294 23.1209 21.7416C23.6202 21.6003 24.0733 21.3298 24.4346 20.9574C24.7958 20.585 25.0524 20.1238 25.1784 19.6205C25.5096 17.7832 25.6729 15.9195 25.6662 14.0526C25.6781 12.1573 25.5148 10.2651 25.1784 8.39986Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3752 11.0429C11.3752 10.4861 11.3752 10.2076 11.4915 10.0522C11.5929 9.91675 11.7482 9.832 11.9169 9.81995C12.1106 9.80612 12.3448 9.95667 12.8132 10.2578L17.4132 13.2149C17.8196 13.4762 18.0228 13.6068 18.093 13.773C18.1544 13.9181 18.1544 14.0819 18.093 14.227C18.0228 14.3932 17.8196 14.5238 17.4132 14.7851L12.8132 17.7422C12.3448 18.0433 12.1106 18.1939 11.9169 18.18C11.7482 18.168 11.5929 18.0832 11.4915 17.9478C11.3752 17.7924 11.3752 17.5139 11.3752 16.9571V11.0429Z"
        fill="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
