import { HistoricData } from 'components/Production/types';

export default function getLatestHistoricData(data: HistoricData[] = []) {
  const latestData = [...data].sort((a, b) => {
    if (new Date(a.day) > new Date(b.day)) {
      return -1;
    }
    if (new Date(a.day) < new Date(b.day)) {
      return 1;
    }
    return 0;
  })?.[0];

  return latestData ?? null;
}
