import AiRecommendationIcon from 'external/components/UI/AiRecommendationIcon/AiRecommendationIcon';
import isSimilarityRecommendation from 'external/utils/isSimilarityRecommendation';
import { Deliverable } from 'components/Deliverable/types';

function getRecommendedByName(
  recommendedBy: Deliverable['createdBy'],
  isSimilarityTool: boolean,
): string {
  return isSimilarityTool
    ? 'BEN AI Audience Similarity Engine'
    : `${recommendedBy.firstName} ${recommendedBy.lastName}`;
}

function getRecommendedByTitle(
  recommendedBy: Deliverable['createdBy'],
  isSimilarityTool: boolean,
): string {
  return isSimilarityTool ? '' : recommendedBy.title;
}

function RecommendedBy({
  recommendedBy,
}: {
  recommendedBy: Deliverable['createdBy'];
}) {
  const isSimilarityTool: boolean = isSimilarityRecommendation(recommendedBy);
  const recommendedByName: string = getRecommendedByName(
    recommendedBy,
    isSimilarityTool,
  );
  const recommendedByTitle: string = getRecommendedByTitle(
    recommendedBy,
    isSimilarityTool,
  );

  return (
    <div className="RecommendedBy highlight-wrapper">
      <h5 className="highlight-title">Recommended by</h5>
      <div
        className={`name-wrapper recommended-by-${
          isSimilarityTool ? 'ai' : 'ben'
        }`}
      >
        {isSimilarityTool ? <AiRecommendationIcon /> : null}
        <p className="highlight-text">{recommendedByName}</p>
      </div>
      <p className="highlight-text">{recommendedByTitle}</p>
    </div>
  );
}

export default RecommendedBy;
