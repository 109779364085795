import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import EmailsQuery from 'components/Creator/graphql/Emails.graphql';
import { Property } from 'components/Property/types';
import EmailResults from 'components/Emails/List/Results/Results';
import UILoader from 'components/UI/Loader/Loader';

export default function ContactHistory({ property }: { property?: Property }) {
  const { data, loading } = useQuery(EmailsQuery, {
    skip: !property?.primaryContact?.email,
    variables: {
      limit: 50,
      skip: 0,
      sort: 'delivered-desc',
      f: {
        query: property?.primaryContact?.email,
        source: null,
      },
    },
  });

  return (
    <div style={{ margin: '2rem 1rem' }}>
      {loading ? <UILoader /> : null}
      {!property?.primaryContact?.email
        ? 'Creator has no email assigned.'
        : null}
      {data?.emails?.data?.length > 0 ? (
        <>
          <h2>{`Displaying results for ${property?.primaryContact?.email}`}</h2>
          <EmailResults data={data?.emails?.data} loading={loading} />
        </>
      ) : null}

      {data?.emails?.data?.length === 0
        ? `We have no records of emails with ${property?.primaryContact?.email}`
        : null}
    </div>
  );
}
