import { bool, oneOf, string } from 'prop-types';
import classNames from 'classnames';

import './Loader.scss';

function UILoader({ size, type, white, className }) {
  return (
    <div
      className={classNames('ben-loader__wrapper', className, {
        [`ben-loader__wrapper--${type}`]: type,
      })}
    >
      <div
        className={classNames('ben-loader', {
          'ben-loader--white': white,
          [`ben-loader--${type}`]: type,
          [`ben-loader--${size}`]: size,
        })}
      />
    </div>
  );
}

UILoader.propTypes = {
  size: oneOf(['small', 'medium', 'x-small']),
  type: oneOf(['', 'inline']),
  white: bool,
  className: string,
};

UILoader.defaultProps = {
  size: 'medium',
  type: '',
  white: false,
  className: null,
};

export default UILoader;
