import NumberInput from 'components/DataGrid/Editors/Destination/NumberInput/NumberInput';
import number from 'components/DataGrid/formatters/number';

const numberColDef = {
  valueFormatter: number,
  cellEditor: NumberInput,
  headerClass: 'header-align-right',
  cellClass: 'cell-align-right',
};

export default numberColDef;
