import { useState, useRef, useEffect } from 'react';

export default function useDebouncedValue(
  value,
  delay = 300,
  defaultValue = {},
) {
  const [debouncedValue, setDebouncedValue] = useState(
    () => value || defaultValue,
  );
  const refContainer = useRef(null);

  useEffect(() => {
    if (value !== debouncedValue) {
      refContainer.current = setTimeout(() => {
        refContainer.current = null;
        setDebouncedValue(value);
      }, delay);
    }

    return () => {
      if (refContainer.current) {
        clearTimeout(refContainer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);

  return debouncedValue;
}
const getCustomDelay = (customDelays, value, debouncedValue, defaultDelay) =>
  Object.keys(customDelays).reduce((effectiveDelay, key) => {
    const path = key.split('.');
    let currentValue = value;
    let currentDebouncedValue = debouncedValue;

    const allPropsExist = path.every((p) => {
      if (currentValue && Object.hasOwn(currentValue, p)) {
        currentValue = currentValue[p];
        currentDebouncedValue = currentDebouncedValue[p];
        return true;
      }
      return false;
    });

    if (allPropsExist && currentValue !== currentDebouncedValue) {
      return customDelays[key];
    }

    return effectiveDelay;
  }, defaultDelay);

export function useDebouncedValueWithCustomDelays(
  value,
  delay = 300,
  defaultValue = {},
  customDelays = {},
) {
  const [debouncedValue, setDebouncedValue] = useState(
    () => value || defaultValue,
  );
  const refContainer = useRef(null);

  useEffect(() => {
    const effectiveDelay = getCustomDelay(
      customDelays,
      value,
      debouncedValue,
      delay,
    );

    if (value !== debouncedValue) {
      refContainer.current = setTimeout(() => {
        refContainer.current = null;
        setDebouncedValue(value);
      }, effectiveDelay);
    }

    return () => {
      if (refContainer.current) {
        clearTimeout(refContainer.current);
      }
    };
  }, [value, delay, debouncedValue, customDelays]);

  return debouncedValue;
}
