import { getDataCy } from 'components/Campaign/utils';
import SimpleCellRenderer from 'components/DataGrid/Cells/SimpleCellRenderer';
import formatters from 'components/DataGrid/formatters';
import { Placement } from 'shared/types/Placement';

export default function deliverableExpRevenue() {
  return {
    field: 'deliverableRevenue',
    headerName: 'Deliverable Exp. Revenue',
    valueGetter: ({ data }: { data: Placement }) =>
      data?.integration?.deliverable?.financials?.revenue ?? null,
    valueFormatter: formatters.currency,
    cellRenderer: SimpleCellRenderer.componentName,
    cellRendererParams: ({ data }: { data: Placement }) => ({
      dataCy: getDataCy(data, 'deliverableRevenue'),
    }),
  };
}
