export const childrenToString = (str) => {
  function getString(node) {
    if (!node || !node.$$typeof) return node;
    return childrenToString(node.props.children);
  }

  if (Array.isArray(str)) {
    return str
      .map(getString)
      .filter((item) => typeof item === 'string')
      .join(' ');
  }

  return getString(str);
};

export const trunc = (str, maxlength) =>
  (str && str.length > maxlength
    ? `${str.substr(0, maxlength - 3).trim()}…`
    : str);

export const truncMiddle = (string, maxlength) => {
  const startLength = Number.parseInt(maxlength / 2, 10);
  const endLength = maxlength - startLength - 3;

  return string.length > maxlength
    ? `${string.substr(0, startLength)}…${string.substr(-endLength, endLength)}`
    : string;
};

export const capitalizeFirst = (str) =>
  (!str ? '' : str.charAt(0).toUpperCase() + str.slice(1));

export const stringSearch = (str, search) => {
  if (!search) return true;

  return str && String(str).toLowerCase().includes(search.toLowerCase());
};

export const toSnakeUpperCase = (str) =>
  str && str.replace(/-/g, '_').toUpperCase();
export const toDashCase = (str) => str && str.replace(/_/g, '-').toLowerCase();

export const clearClassName = (str) => str && str.replace(/[^A-Za-z-]/g, '');
