// @ts-nocheck
import React, { useState } from 'react';
import classnames from 'classnames';
import { sortableHandle } from 'react-sortable-hoc';
import UIProgresLayer from 'components/UI/ProgressLayer/ProgressLayer';
import UIIconButton from 'components/UI/IconButton/IconButton';
import UIIcon from 'components/UI/Icon/Icon';
import UIModal from 'components/UI/Modal/Modal';
import useModal from 'components/UI/Modal/useModal';
import ClipboardCopy from 'components/UI/ClipboardCopy/ClipboardCopy';
import UIImage from 'components/UI/Image/Image';
import useImagePreLoader from 'components/UI/Image/useImagePreLoader';
import UIVideo from 'components/UI/Video/Video';
import { fileIsVideo, urlIsBlob } from 'components/utils/file';
import storage from 'utils/storage';
import { MediaFileComponentProps } from '../types';

import './MediaFile.scss';

const cbn = 'form-file-uploader-media-file';

const DragHandle = sortableHandle(() => (
  <UIIcon className={`${cbn}__drag`} type="swap_horiz" />
));

const checkIsCampaignMediaReview = () =>
  Boolean(
    document.querySelector('.campaign-influencer-view-media-review__wrapper'),
  );

const getContainerHeight = () => {
  const { clientHeight } = document.documentElement;

  return clientHeight * 0.3;
};

const getWidth = (size) => {
  const { width, height } = { ...size };
  const isCampaignMediaReview = checkIsCampaignMediaReview();

  if (!isCampaignMediaReview) return null;

  if (width && height) {
    const containerHeight = getContainerHeight();
    const ratio = containerHeight / height;
    const proportionalWidth = width * ratio;
    const roundedWidth = Math.round(proportionalWidth * 1e2) / 1e2;

    return {
      minWidth: roundedWidth,
      width: roundedWidth,
    };
  }
};

type FormFileUploaderMediaFileProps = MediaFileComponentProps;

// eslint-disable-next-line max-lines-per-function, complexity
function FormFileUploaderMediaFile({
  allowFullScreen,
  downloading,
  error,
  file,
  isPrivate,
  nonPlayable,
  onClick,
  onDownload,
  onRemove,
  progress,
  resourceId,
  resourceType,
  showDragButton,
  showLinkButton,
  showRemoveButton,
}: FormFileUploaderMediaFileProps) {
  const [hasMediaError, setMediaError] = useState(false);
  const bearerToken = storage.get('$BEN$--accessToken');
  const fileUrl =
    isPrivate && file.id
      ? `/api/${resourceType}s/${resourceId}/download-file/${file.id}?isPrivate=true&access_token=${bearerToken}`
      : file.url;

  const isBlobUrl = urlIsBlob(file);
  const isVideo = fileIsVideo(file);
  const wrapperWidth = getWidth(file?.attributes, isVideo);
  const isYoutubeVideo =
    isVideo && file && file.url && file.url.includes('youtube');
  const showProgressLayer = isBlobUrl && !error;
  const imgUrl = useImagePreLoader(isVideo ? null : fileUrl);
  const { isOpen, open, close } = useModal();

  return (
    <div className={cbn} style={wrapperWidth}>
      {showProgressLayer && (
        <UIProgresLayer
          value={progress || 0}
          className={`${cbn}__progress-layer`}
        />
      )}
      <div
        className={classnames(`${cbn}__info`, {
          'has-error': error || hasMediaError,
          pending: isBlobUrl && !error,
        })}
      >
        {isVideo && (
          <UIVideo
            className={`${cbn}__video`}
            url={fileUrl}
            style={{ width: 'auto' }}
            nonPlayable={nonPlayable || (!file.id && isPrivate)}
            onClick={onClick}
            onError={() => setMediaError(true)}
            thumbnail={file?.attributes?.thumbnail}
          />
        )}
        {!isVideo && (
          <UIImage
            className={`${cbn}__image`}
            url={imgUrl}
            imagecachePreset={null}
            onClick={onClick}
          />
        )}
      </div>
      <div className={`${cbn}__actions`}>
        {showRemoveButton && (
          <UIIconButton
            className={`${cbn}__remove`}
            onClick={onRemove}
            icon="delete"
            tooltip="Delete"
          />
        )}
        {!isYoutubeVideo && (
          <UIIconButton
            className={classnames(`${cbn}__download`, {
              [`${cbn}__download--is-downloading`]: downloading,
            })}
            tooltip={downloading ? 'Downloading...' : 'Download'}
            onClick={onDownload}
            icon="download"
          />
        )}
        {showLinkButton && (
          <ClipboardCopy
            value={fileUrl}
            className={`${cbn}__clipboard-copy__wrapper`}
            onCopyTooltip="File link copied to clipboard!"
            copyReadyTooltip="Copy file link to clipboard"
          />
        )}
        {showDragButton && <DragHandle />}
        {allowFullScreen && (
          <UIIconButton
            onClick={() => open()}
            icon="search"
            tooltip="Show full screen modal"
          />
        )}
      </div>
      {error && <span className={`${cbn}__error`}>{error.toString()}</span>}
      <UIModal
        className={`${cbn}__modal`}
        isOpen={isOpen}
        onRequestClose={() => {
          close();
        }}
        title=""
        size="full-screen"
      >
        {isVideo && (
          <UIVideo
            className={`${cbn}__video`}
            url={fileUrl}
            style={{ width: 'auto' }}
            nonPlayable={nonPlayable}
            onClick={onClick}
          />
        )}
        {!isVideo && (
          <UIImage
            className={`${cbn}__image`}
            url={imgUrl}
            imagecachePreset={null}
            onClick={onClick}
          />
        )}
      </UIModal>
    </div>
  );
}

FormFileUploaderMediaFile.defaultProps = {
  allowFullScreen: true,
  downloading: false,
  error: null,
  isPrivate: null,
  nonPlayable: false,
  onClick: undefined,
  onDownload: undefined,
  progress: 0,
  resourceId: null,
  resourceType: null,
  showLinkButton: false,
};

export default FormFileUploaderMediaFile;
