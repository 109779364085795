import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';
import getInstagramDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/instagram/helpers/getInstagramDataFromCampaignService';
import getTikTokDataFromInsights from 'components/Campaign/Influencer/View/Stats/Performance/columns/tikTok/helpers/getTikTokDataFromInsight';
import { INSTAGRAM_PLATFORM, TIKTOK_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';

const metric = 'saves';
export function savesAutomatedValueGetter(data: Placement) {
  if (data?.platform === INSTAGRAM_PLATFORM) {
    return getInstagramDataFromCampaignService(data, metric);
  }
  if (data?.platform === TIKTOK_PLATFORM) {
    return getTikTokDataFromInsights(data, metric);
  }
  return null;
}

export default function saves() {
  return {
    ...basePerformanceColDef(metric, savesAutomatedValueGetter),
    headerName: 'Saves',
  };
}
