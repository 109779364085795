import BaseIcon from '@UIComponents/BaseIcon/BaseIcon';

type ReasonToBelieveProps = {
  reasonToBelieve: string;
  propertyName: string;
};

function ReasonToBelieve({
  reasonToBelieve,
  propertyName,
}: ReasonToBelieveProps) {
  return (
    <div className="ReasonToBelieve highlight-wrapper">
      <div className="highlight-title-wrapper">
        <BaseIcon icon="star_filled" />
        <h5 className="highlight-title">
          Why should you consider this creator
        </h5>
      </div>
      <p
        className="highlight-text"
        data-cy={`reasonToBelieve-text-${propertyName}`}
      >
        {reasonToBelieve}
      </p>
    </div>
  );
}

export default ReasonToBelieve;
