import { Placement } from 'shared/types/Placement';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import OrderReportToggle from 'components/DataGrid/Cells/TikTok/OrderReportToggle/OrderReportToggle';
import { TIKTOK_PLATFORM } from 'shared/metrics/metrics';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';

export default function tikTokOrderReport() {
  return {
    ...baseColDef,
    field: 'tiktok_order_report',
    headerName: 'TikTok Order Report',
    cellRenderer: ({
      data,
      context,
    }: {
      data: Placement;
      context: Context;
    }) => {
      if (data?.platform === TIKTOK_PLATFORM && data?.source?.url) {
        return <OrderReportToggle placement={data} tab={context?.tab ?? ''} />;
      }

      return null;
    },
  };
}
