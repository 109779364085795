import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  EXTERNAL_APPROVAL_STATUSES,
  ExternalApproval,
} from 'constants/approvalStatuses';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import { UpdatePlacementExternalApproval } from './graphql';

import ApprovalModal from './Modal/ApprovalModal';
import RejectionModal from './Modal/RejectionModal';

import './ExternalApproval.scss';

const { REJECTED, APPROVED } = EXTERNAL_APPROVAL_STATUSES;

type ExternalPlacementExternalApprovalProps = {
  id: number;
  externalApproval: ExternalApproval | null;
  externalMessagesCount: number;
  handleApproveClick: () => void;
  handleRejectClick: (message: string) => void;
};

function ExternalPlacementExternalApproval({
  id,
  externalApproval,
  externalMessagesCount,
  handleRejectClick,
  handleApproveClick,
}: ExternalPlacementExternalApprovalProps) {
  const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
  const [showRejectionModal, setShowRejectionModal] = useState<boolean>(false);

  const [updatePlacementExternalApproval] = useMutation(
    UpdatePlacementExternalApproval,
  );

  const handleApproveContent = async () => {
    await updatePlacementExternalApproval({
      optimisticResponse: {
        updatePlacementExternalApproval: {
          __typename: 'Placement',
          id,
          rejectedReason: '',
          externalApproval: APPROVED,
          messagesCount: {
            __typename: 'MessagesResultMeta',
            external: externalMessagesCount,
          },
        },
      },
      variables: {
        id,
        payload: {
          rejectedReason: null,
          externalApproval: APPROVED,
        },
      },
    });

    handleApproveClick();
  };

  const handleDeclineContent = async (rejectedReason: string) => {
    await updatePlacementExternalApproval({
      optimisticResponse: {
        updatePlacementExternalApproval: {
          __typename: 'Placement',
          id,
          rejectedReason: '',
          externalApproval: REJECTED,
          messagesCount: {
            __typename: 'MessagesResultMeta',
            external: externalMessagesCount,
          },
        },
      },
      variables: {
        id,
        payload: {
          rejectedReason,
          externalApproval: REJECTED,
        },
      },
    });

    handleRejectClick(rejectedReason);
  };

  return (
    <>
      <div className="external-placement-external-approval__actions">
        {!externalApproval ? (
          <>
            <BaseButton
              text="Approve"
              onClick={() => setShowApprovalModal(true)}
            />
            <BaseButton
              text="Decline"
              variant="outlined"
              onClick={() => setShowRejectionModal(true)}
            />
          </>
        ) : null}
      </div>
      <ApprovalModal
        handleApproval={handleApproveContent}
        isOpen={showApprovalModal}
        onClose={() => setShowApprovalModal(false)}
        approved={externalApproval === APPROVED}
      />
      <RejectionModal
        handleRejection={handleDeclineContent}
        isOpen={showRejectionModal}
        onClose={() => setShowRejectionModal(false)}
        rejected={externalApproval === REJECTED}
      />
    </>
  );
}

export default ExternalPlacementExternalApproval;
