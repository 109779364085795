import basePerformanceColDef from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/baseColDef';
import {
  STREAM_MEDIA_TYPE,
  TWITCH_PLATFORM,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/metrics';

const metric = 'averageCCV';
export default function averageCCV() {
  return {
    ...basePerformanceColDef(
      metric,
      () => null,
      [YOUTUBE_PLATFORM, TWITCH_PLATFORM],
      [STREAM_MEDIA_TYPE],
    ),
    headerName: 'Average CCV',
    width: 205,
  };
}
