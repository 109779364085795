import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { Placement } from 'shared/types/Placement';
import { Filter } from 'components/Campaign/Influencer/View/hooks/types';

import useLoadAdditionalData from './useLoadAdditionalData';
import useLoadEntitiesHelpers from './useLoadEntitiesHelpers';
import PlacementsAdditionalDataQuery from '../graphql/PlacementsAdditionalData.graphql';

function getShouldLoadMore(skip: number, limit: number, total: number) {
  if (!Number.isFinite(Number(skip))) {
    return false;
  }

  if (!Number.isFinite(Number(limit))) {
    return false;
  }

  if (!Number.isFinite(Number(total))) {
    return false;
  }

  return skip + limit < total;
}

export default function useLoadEntities({
  entitiesName,
  query,
  singleEntityQuery = null,
  filter,
}: {
  entitiesName: string;
  query: DocumentNode;
  singleEntityQuery: DocumentNode | null;
  filter: Filter | null;
}) {
  const {
    gridApi,
    setGridApi,
    loading,
    setLoading,
    skip,
    setSkip,
    refetchSingleEntity,
  } = useLoadEntitiesHelpers(filter, singleEntityQuery);

  const {
    additionalDataIdsToQuery,
    setAdditionalDataIdsToQuery,
  } = useLoadAdditionalData(
    gridApi,
    loading,
    setLoading,
    PlacementsAdditionalDataQuery,
    filter ?? {},
  );

  const [gridQuery, gridQueryOptions] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      const responsePayload = response?.[entitiesName];
      const entities = responsePayload?.data ?? [responsePayload];
      const total = responsePayload?.meta?.total;

      if (!gridApi) {
        return;
      }

      const idsToRefetch = (responsePayload?.data ?? [])
        .map((placement: Placement) => {
          if (
            placement?.source?.postMetrics?.postAutomatedMetrics?.[0]
              ?.errorDetail === 'Continue wait'
          ) {
            return placement?.id;
          }

          return null;
        })
        .filter(Boolean);

      setAdditionalDataIdsToQuery(
        new Set([...additionalDataIdsToQuery, ...(idsToRefetch ?? [])]),
      );

      gridApi.api.applyTransaction({ add: entities });

      const limit = filter?.variables?.limit ?? 0;
      const loadMore = getShouldLoadMore(skip, limit, total);
      if (loadMore) {
        setSkip(limit + skip);
        return;
      }

      setLoading(false);
      setSkip(0);
    },
  });

  React.useEffect(() => {
    if (!filter || !gridApi) {
      return;
    }

    gridApi.api.setRowData([]);
    setLoading(true);
    setSkip(0);
    gridQuery(filter);
  }, [filter, gridApi, gridQuery, setLoading, setSkip]);

  React.useEffect(() => {
    if (!skip) {
      return;
    }
    setLoading(true);
    gridQuery({
      ...filter,
      variables: {
        ...filter?.variables,
        skip,
      },
    });
  }, [filter, gridQuery, skip, setLoading]);

  return {
    refetchGridEntities: () => {
      setLoading(true);
      if (filter) {
        gridQuery(filter);
      }
    },
    gridQuery,
    gridQueryOptions,
    gridApi,
    setGridApi,
    loading,
    refetchSingleEntity,
  };
}
