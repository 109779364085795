import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import useTheme from 'external/utils/useTheme';

const cbn = 'mode-switcher';

function ExternalUIModeSwitcher({ className }) {
  const [theme, setTheme] = useTheme();

  const onClick = () => {
    const newMode = theme === 'light' ? 'dark' : 'light';
    setTheme(newMode);
  };

  return (
    <button
      style={{ display: 'none' }}
      className={classnames(cbn, className)}
      onClick={onClick}
      type="button"
    >
      {`Switch to ${theme === 'light' ? 'Dark' : 'Light'} Mode`}
    </button>
  );
}

ExternalUIModeSwitcher.propTypes = {
  className: string,
};

ExternalUIModeSwitcher.defaultProps = {
  className: '',
};

export default ExternalUIModeSwitcher;
