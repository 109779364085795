/* eslint-disable react/button-has-type */
import React, { forwardRef } from 'react';
import classnames from 'classnames';
import UILink from 'components/UI/Link/Link';

import './Button.scss';

type ButtonTheme =
  | 'contained-red'
  | 'contained-grey'
  | 'contained-dark'
  | 'contained-green'
  | 'contained-light'
  | 'contained-purple'
  | 'bordered-light'
  | 'bordered-red'
  | 'bordered-grey'
  | 'bordered-dark'
  | 'bordered-green'
  | 'bordered-grey-dark'
  | 'bordered-purple'
  | 'text'
  | 'text-dark'
  | 'text-red'
  | 'text-green'
  | 'text-blue';

export type UIButtonProps = Partial<
  WithChildren<{
    active: boolean;
    className: string;
    disabled: boolean;
    href: string;
    id: number | string;
    keepReferrer: boolean;
    onClick: React.MouseEventHandler;
    hrefOnClick?: () => void;
    popReferrer: boolean;
    pushReferrer: boolean;
    referrers: Array<string>;
    size: 'small' | 'medium';
    theme: ButtonTheme;
    to: string;
    type: 'button' | 'submit' | 'reset' | undefined;
    uncontrolledChild: boolean;
    target: string;
    value: string | number | readonly string[] | undefined;
    dataCy?: string;
  }>
>;

const UIButton = forwardRef<HTMLButtonElement, UIButtonProps>(
  (
    {
      active,
      children,
      className,
      disabled,
      id,
      onClick,
      pushReferrer, // used by FormButtonGroupSelector
      referrers,
      size,
      theme,
      to,
      type,
      dataCy,
      hrefOnClick,
      ...restProps
    }: UIButtonProps,
    ref,
  ) => {
    const classNames = classnames(
      'ui-button',
      `ui-button--${theme}`,
      className,
      {
        'ui-button--is-active': active,
        [`ui-button--${size}`]: size,
      },
    );

    const buttonRestProps = React.useMemo(() => {
      const tmp = { ...restProps };
      delete tmp?.uncontrolledChild;
      return tmp;
    }, [restProps]);

    if (to) {
      return (
        <UILink
          {...restProps}
          pushReferrer={pushReferrer}
          referrers={referrers}
          onClick={onClick}
          hrefOnClick={hrefOnClick}
          className={classNames}
          disabled={disabled}
          to={to}
        >
          {children}
        </UILink>
      );
    }
    return (
      <button
        {...buttonRestProps}
        data-cy={dataCy}
        ref={ref}
        id={id ? `${id}` : undefined}
        type={type === 'button' ? 'button' : 'submit'}
        onClick={onClick}
        className={classNames}
        disabled={disabled}
      >
        {children}
      </button>
    );
  },
);

UIButton.defaultProps = {
  active: false,
  children: null,
  className: undefined,
  disabled: false,
  id: undefined,
  onClick: undefined,
  size: 'medium',
  theme: 'bordered-light',
  to: undefined,
  type: 'button',
};

UIButton.displayName = 'UIButton';

export default UIButton;
