import getInstagramDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/instagram/helpers/getInstagramDataFromCampaignService';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import {
  INSTAGRAM_PLATFORM,
  PHOTO_MEDIA_TYPE,
  POST_MEDIA_TYPE,
  REELS_MEDIA_TYPE,
  STORY_MEDIA_TYPE,
  VIDEO_MEDIA_TYPE,
  TIKTOK_PLATFORM,
} from 'shared/metrics/kpis';
import { Placement } from 'shared/types/Placement';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import manualMetricsValueGetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import { viewsAutomatedValueGetter } from 'components/Campaign/Influencer/View/Stats/Performance/columns/impressions';
import getPlatformMetric from 'components/Campaign/Influencer/View/Stats/columns/utils/getPlatformMetric';
import MetricType from 'shared/types/MetricType';
import getTikTokDataFromInsights from 'components/Campaign/Influencer/View/Stats/Performance/columns/tikTok/helpers/getTikTokDataFromInsight';
import basePerformanceColDef from './helpers/baseColDef';

type Value = string | number | null | undefined;

const metric = 'engagementRate';
function calculateEngagementRateDivision(interactions: Value, views: Value) {
  if (!interactions || !views) {
    return null;
  }
  if (
    Number.isFinite(Number(interactions)) &&
    Number.isFinite(Number(views)) &&
    Number(interactions) &&
    Number(views)
  ) {
    return Number(interactions) / Number(views);
  }
  return null;
}

function getInteractionMetrics(data: Placement, context: Context) {
  const comments =
    getInstagramDataFromCampaignService(data, 'comments_count') ??
    manualMetricsValueGetter(data, context, 'comments') ??
    0;

  const initalValue = Number.isFinite(Number(comments)) ? Number(comments) : 0;
  return ['likes', 'shares', 'saves'].reduce((acc, field) => {
    const value =
      getInstagramDataFromCampaignService(data, field) ??
      manualMetricsValueGetter(data, context, field);

    if (value && Number.isFinite(Number(value))) {
      return acc + Number(value);
    }
    return acc;
  }, initalValue);
}
export function engagementRateAutomatedValueGetter(
  data?: Placement,
  context?: Context | null,
  getValue?: (arg0: string) => Value,
) {
  if (data?.platform === TIKTOK_PLATFORM) {
    return getTikTokDataFromInsights(
      data,
      'fct_lifetime_video_performance.engagement_rate',
    );
  }
  if (!getValue) {
    return null;
  }
  if (context && data?.platform === INSTAGRAM_PLATFORM) {
    /*
     * Engagement rate for reels should be:
     * ⚠️  engagement rate = likes + shares + saved  + comments / views ⚠️
     */
    if (
      [REELS_MEDIA_TYPE, VIDEO_MEDIA_TYPE, STORY_MEDIA_TYPE].includes(
        data?.mediaType ?? '',
      )
    ) {
      const interactions = getInteractionMetrics(data, context);
      const views =
        viewsAutomatedValueGetter(data) ??
        manualMetricsValueGetter(data, context, 'views');
      return calculateEngagementRateDivision(interactions, views);
    }

    /*
     * Engagement rate for posts should be:
     * ⚠️  engagement rate = likes + shares + saved  + comments / followers ⚠️
     */
    if ([POST_MEDIA_TYPE, PHOTO_MEDIA_TYPE].includes(data?.mediaType ?? '')) {
      const interactions = getInteractionMetrics(data, context);
      const followers =
        getPlatformMetric(data, data?.mediaType, 'followers' as MetricType)
          ?.value ??
        manualMetricsValueGetter(data, context, 'followers') ??
        null;
      return calculateEngagementRateDivision(interactions, followers);
    }
  }
  return null;
}

export default function engagementRate() {
  return {
    ...percentColDef,
    ...basePerformanceColDef(metric, engagementRateAutomatedValueGetter),
    headerName: 'Engagement Rate',
    width: 240,
  };
}
