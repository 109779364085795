import { useEffect } from 'react';
import { string, oneOfType, arrayOf } from 'prop-types';

function DocumentTitle({ children }) {
  const childrenArray = Array.isArray(children) ? children : [children];

  useEffect(() => {
    const title = childrenArray.join(' ');

    document.title = `${title} - BENlabs`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, childrenArray);

  return null;
}

DocumentTitle.propTypes = {
  children: oneOfType([string.isRequired, arrayOf(string)]),
};

export default DocumentTitle;
