import React from 'react';
import classnames from 'classnames';

import { getMediaTypeFromUrl } from 'utils/files';
import UIMedia from 'components/UI/Media/Media';

type ExternalPlacementCardMediaProps = {
  className?: string;
  file: {
    attributes: {
      thumbnail: string | null;
    };
    url: string;
  };
  forceAspectRatio?: boolean;
};

function ExternalPlacementCardMedia({
  className,
  file,
  forceAspectRatio = false,
  ...restProps
}: ExternalPlacementCardMediaProps) {
  const mediaType = getMediaTypeFromUrl(file?.url);

  return (
    <UIMedia
      className={classnames('external-placement-card__image', className, {
        'external-placement-card__image--force-aspect-ratio': forceAspectRatio,
        'external-placement-card__image--video-thumb': mediaType === 'video',
      })}
      fallbackImage="placeholderImage"
      url={file?.url}
      {...restProps}
      thumbnail={file?.attributes?.thumbnail}
    />
  );
}

export default ExternalPlacementCardMedia;
