// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import usePortal from 'components/UI/utils/usePortal';
import UIIconButton from 'components/UI/Icon/Button/Button';
import useDialog from './useDialog';
import './Dialog.scss';

const cbn = 'ui-dialog';

type UIDialogProps = {
  className: string;
  description: React.ReactNode | string;
  isOpen: boolean;
  onClose: (event: React.MouseEvent) => void;
  overlayClassName: string;
  renderActions: () => JSX.Element;
  title: string;
};

function UIDialog({
  className,
  description,
  isOpen,
  onClose,
  overlayClassName,
  renderActions,
  title,
}: UIDialogProps) {
  const Portal = usePortal();

  if (!isOpen) return null;

  return (
    <Portal>
      <div className={classnames(`${cbn}__overlay`, overlayClassName)}>
        <div className={classnames(cbn, className)}>
          <UIIconButton
            className={`${cbn}__close`}
            type="close"
            onClick={onClose}
          />
          <h1 className={`${cbn}__title`}>{title}</h1>
          {description && (
            <div className={`${cbn}__description`}>{description}</div>
          )}
          {renderActions && (
            <div className={`${cbn}__actions`}>{renderActions()}</div>
          )}
        </div>
      </div>
    </Portal>
  );
}

UIDialog.defaultProps = {
  className: null,
  overlayClassName: null,
  description: null,
  isOpen: false,
  onClose: () => {},
  renderActions: null,
};

export { useDialog };

export default UIDialog;
