// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import { OptionProps, OptionTypeBase } from 'react-select';

import './LabelOption.scss';

const cbn = 'form-selector-label-option';

type FormSelectorLabelOptionProps = OptionProps<OptionTypeBase> & {
  innerRef: React.Ref<HTMLButtonElement>;
};

function FormSelectorLabelOption({
  children,
  innerProps,
  innerRef,
  isDisabled,
  isFocused,
  isSelected,
}: FormSelectorLabelOptionProps) {
  return (
    <div
      {...innerProps}
      ref={innerRef}
      className={classnames(cbn, {
        'is-focused': isFocused,
        'is-selected': isSelected,
        'is-disabled': isDisabled,
      })}
      title={`${children}`}
    >
      <div className={`${cbn}__label-wrapper`}>
        <span className={`${cbn}__label`}>{children}</span>
      </div>
    </div>
  );
}

FormSelectorLabelOption.defaultProps = {
  children: null,
  innerRef: null,
  isDisabled: false,
  isFocused: false,
  isSelected: false,
};

export default FormSelectorLabelOption;
