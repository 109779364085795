import { Placement } from 'shared/types/Placement';
import {
  INSTAGRAM_PLATFORM,
  POST_MEDIA_TYPE,
  REELS_MEDIA_TYPE,
  TIKTOK_PLATFORM,
  VIDEO_MEDIA_TYPE,
  YOUTUBE_PLATFORM,
} from 'shared/metrics/metrics';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';

import getInstagramDataFromCampaignService from 'components/Campaign/Influencer/View/Stats/Performance/columns/instagram/helpers/getInstagramDataFromCampaignService';
import basePerformanceColDef from './helpers/baseColDef';
import getTikTokDataFromInsights from './tikTok/helpers/getTikTokDataFromInsight';
import getYouTubeDataFromCampaignService from './youTube/helpers/getYouTubeDataFromCampaignService';

const metric = 'views';

export function viewsAutomatedValueGetter(data: Placement) {
  if (data?.platform === TIKTOK_PLATFORM) {
    return getTikTokDataFromInsights(
      data,
      'fct_lifetime_video_performance.num_views',
    );
  }
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDataFromCampaignService(data, metric);
  }
  if (data?.platform === INSTAGRAM_PLATFORM) {
    if (data?.mediaType === REELS_MEDIA_TYPE) {
      return getInstagramDataFromCampaignService(data, 'plays');
    }
    if ([POST_MEDIA_TYPE, VIDEO_MEDIA_TYPE].includes(data?.mediaType ?? '')) {
      return getInstagramDataFromCampaignService(data, 'video_views');
    }
    return getInstagramDataFromCampaignService(data, metric);
  }
  return null;
}

export default function views() {
  return {
    ...numberColDef,
    ...basePerformanceColDef(metric, viewsAutomatedValueGetter),
    headerName: 'Views',
  };
}
