import { Context } from 'components/Campaign/Influencer/View/shared/types';
import { getMatchingPostManualMetric } from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import React from 'react';
import { getPostReleaseDate } from 'shared/campaigns/creatorCampaigns/postDates';
import { getOffsetByReportingKey } from 'shared/campaigns/creatorCampaigns/reportingWindows';
import { PostManualMetric } from 'shared/types/CreatorCampaigns';
import { Placement } from 'shared/types/Placement';

const NO_ENTRY =
  'A value has not yet been entered for this content. Please add a metric value and select a reporting window.';
const NO_CURRENT_ENTRY =
  'There is no value entered for the selected reporting window.';

export default function useManualMetricAlertMessage(
  placement: Placement,
  context: Context,
  metric: string,
) {
  return React.useMemo(() => {
    const postManualMetric = getMatchingPostManualMetric(
      placement,
      context,
      metric,
    );

    if (!postManualMetric?.length) {
      return NO_ENTRY;
    }

    const reportingWindowDateWithOffset = getOffsetByReportingKey(
      context?.reportingWindow,
      context?.campaign?.dateRangeFrom,
      getPostReleaseDate(placement)?.toISOString(),
    );

    const matchingPostManualMetric = (postManualMetric ?? []).find(
      (m: PostManualMetric) =>
        m?.date === reportingWindowDateWithOffset?.toISOString(),
    );

    return !matchingPostManualMetric ? NO_CURRENT_ENTRY : null;
  }, [context, metric, placement]);
}
