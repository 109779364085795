import { getDataCy } from 'components/Campaign/utils';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import { Placement } from 'shared/types/Placement';

export default function conversionCode() {
  return {
    ...baseColDef,
    field: 'conversionCode',
    headerName: 'Conversion Code',
    width: 200,
    valueGetter: ({ data }: { data: Placement }) =>
      data?.integration?.deliverable?.conversionCode,
    cellRendererParams: ({ data }: { data: Placement }) => {
      const dataCY = getDataCy(data, 'conversionCode');
      return { dataCy: dataCY };
    },
  };
}
