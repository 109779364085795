import { toArray } from 'utils/array';

export const hasValue = (value) =>
  Boolean(Array.isArray(value) ? value.length : value);
export const inMap = (itemsMap, value) => {
  if (!itemsMap || !value) {
    return null;
  }

  return Array.isArray(value)
    ? value.every((item) => itemsMap[item])
    : Boolean(itemsMap[value]);
};

export const getPopulatedValue = (itemsMap, value) => {
  if (!itemsMap || !hasValue(value)) {
    return value;
  }

  if (!Array.isArray(value)) {
    return itemsMap[value];
  }

  const result = [];

  value.forEach((item) => {
    if (itemsMap[item]) {
      result.push(itemsMap[item]);
    }
  });

  return result;
};

export const getValue = (value, valueKey) => {
  const key = value[valueKey];

  if (key) {
    return key;
  }

  const type = typeof value;

  if (!['number', 'string'].includes(type)) {
    return undefined;
  }

  const numericValue = parseFloat(value, 10);
  if (Number.isNaN(numericValue)) {
    return value;
  }
  return numericValue;
};

const isPopulatedObject = (item) => {
  // return true if item/array items is object with more than just id
  const isNumberOrString = ['number', 'string'].includes(typeof item);
  if (isNumberOrString) {
    return false;
  }
  const keys = Object.keys(item);
  return keys.length > 1;
};

export const hasObjects = (value) =>
  (Array.isArray(value)
    ? value.some(isPopulatedObject)
    : isPopulatedObject(value));

export const getValueKeys = (value, valueKey) => {
  if (!value || !valueKey) {
    return value;
  }

  return Array.isArray(value)
    ? value.map((item) => getValue(item, valueKey))
    : getValue(value, valueKey);
};

export const mapItems = (items, valueKey, noneOption) => {
  const map = {};
  if (!valueKey) {
    return null;
  }

  if (noneOption && noneOption[valueKey]) {
    map[noneOption[valueKey]] = noneOption;
  }

  if (!items) {
    return map;
  }

  toArray(items).forEach((item) => {
    const key = item && item[valueKey];

    if (key && isPopulatedObject(item)) {
      map[key] = item;
    }
  });

  return map;
};

export const normalizeInputValue = (inputText, minLength) =>
  (!inputText || inputText.length < minLength ? undefined : inputText);
