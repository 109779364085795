import React from 'react';
import InternalRoutePublic from 'components/Route/Public/Public';
import { getDefaultLocation } from 'external/utils/auth';

function RoutePublic({ ...restProps }) {
  return (
    <InternalRoutePublic
      {...restProps}
      externalContext
      getLocation={getDefaultLocation}
    />
  );
}

export default RoutePublic;
