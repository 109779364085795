import {
  AllowedAction,
  SegmentData,
} from 'utils/segment/useSegmentTTCMTracking';
import { Size } from '@UIComponents/Drawer/Drawer';
import { Placement } from 'shared/types/Placement';
import TiktokSparkAdsDrawerDisplay from 'components/Tiktok/TiktokSparkAds/TiktokSparkAdsDrawer/TiktokSparkAdsDrawerDisplay/TiktokSparkAdsDrawerDisplay';

export default function TiktokSparkAdsDrawer({
  isOpen,
  onRequestClose,
  placement,
  postUrl,
  tab,
  trackTikTokAction,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  postUrl: string | undefined | null;
  placement: Placement;
  tab: string;
  trackTikTokAction: (action: AllowedAction, extraData?: SegmentData) => void;
}) {
  if (!isOpen) {
    return null;
  }
  return (
    <TiktokSparkAdsDrawerDisplay
      isOpen={isOpen}
      size={Size.large}
      placement={placement}
      onRequestClose={onRequestClose}
      postUrl={postUrl}
      tab={tab}
      trackTikTokAction={trackTikTokAction}
    />
  );
}
