import { getLoadBalancedUrl } from './urls';
import constants from './constants';

type SrcProps = {
  url: string;
  fallbackImage: string;
  imagecachePreset: string;
  showImageDefault: boolean;
};
const getSrc = ({
  url,
  imagecachePreset,
  fallbackImage,
  showImageDefault,
}: SrcProps) => {
  const defaultImage =
    constants[(fallbackImage || 'defaultImage') as keyof typeof constants];

  if (!imagecachePreset) {
    return getLoadBalancedUrl(url || showImageDefault ? defaultImage : '');
  }
  try {
    const urlObj = new URL(url);
    const hostSupportsImageCache =
      /files-ben-productplacement-com|media\.nmadirectdata\.com/.test(
        urlObj.host,
      );
    if (!hostSupportsImageCache) {
      return urlObj.href;
    }
    // Make an imagecache URL to use
    return getLoadBalancedUrl(
      `${urlObj.origin}/imagecache/${imagecachePreset}${urlObj.pathname}`,
    );
  } catch {
    return showImageDefault ? defaultImage : null;
  }
};

export default getSrc;
