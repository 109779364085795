import numeral from 'numeral';

const BASE_FORMAT = '$0,0[.]';

export default function currency(
  { value }: { value: number | string },
  decimalPlaces = 2,
  showZero = true,
) {
  if (showZero && !value) {
    return 0;
  }

  if (!showZero && !value) {
    return null;
  }

  if (value === '--') {
    return value;
  }

  if (Number.isNaN(Number(value))) {
    return '--';
  }

  return numeral(value).format(
    BASE_FORMAT.padEnd(BASE_FORMAT.length + decimalPlaces, '0'),
  );
}
