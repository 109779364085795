import { Campaign, ClientAccessTabs } from 'components/Campaign/types';

import { permissionsByTab } from 'external/components/Campaign/Tabs/Tabs';

const getCampaignInitialTab = (
  campaign: Campaign,
  enabledFlags: { [x: string]: boolean },
) => {
  if (!campaign?.clientAccessTabs || !enabledFlags) {
    return null;
  }

  const [tab] =
    Object.entries(
      permissionsByTab,
    ).find(
      ([, hasAccess]: [
        string,
        (clientAccessTabs: ClientAccessTabs) => boolean,
      ]) => hasAccess(campaign.clientAccessTabs),
    ) || [];

  return tab;
};

export default getCampaignInitialTab;
