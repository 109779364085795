import DataPill from '@UIComponents/DataPill/DataPill';
import Loading from '@UIComponents/Loading/Loading';
import { useContext } from 'react';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import { nFormatter } from 'utils/nFormatter';
import campaignMetricsService from 'external/components/CampaignSummary/_services/CampaignMetricsService';

function CampaignComplete() {
  const { campaignId } = useContext(ExternalApplicationContext);

  if (!campaignId) return <Loading white />;

  const data = campaignMetricsService(campaignId);

  const { clickTracking } = data || {};
  const { views, clicks } = data?.contentMetrics.byAll || {};

  return (
    <div>
      {data ? (
        <>
          <DataPill
            label="Campaign guaranteed views"
            value={nFormatter(views!)}
            vertical
            className="total-views"
          />
          {clickTracking ? (
            <DataPill
              label="Total clicks"
              value={nFormatter(clicks!)}
              className="total-clicks"
            />
          ) : null}
        </>
      ) : (
        <Loading white />
      )}
    </div>
  );
}

export default CampaignComplete;
