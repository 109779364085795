import twitchCommon from './helpers/twitchCommon';

const metric = 'hoursStreamed';
export default function hoursStreamed() {
  return {
    ...twitchCommon(metric),
    headerName: 'Hours Streamed',
    width: 225,
  };
}
