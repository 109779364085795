import BaseDomoIframe from './BaseDomoIframe';
import { FrameProps } from './types';

function DomoIframeFacebook({ campaignId = null }: FrameProps) {
  return (
    <BaseDomoIframe
      campaignId={campaignId}
      IframeName="Facebook"
      platform="facebook"
    />
  );
}

export default DomoIframeFacebook;
