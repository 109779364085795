export default function cancellablePromise(callback) {
  let cancel = () => {};

  const promise = new Promise(async (resolve, reject) => {
    let isCancelled = false;

    cancel = () => {
      if (!isCancelled) {
        isCancelled = true;
        resolve({ cancelled: true });
      }
    };

    try {
      const result = await callback();
      if (!isCancelled) {
        resolve(result);
      }
    } catch (e) {
      if (!isCancelled) {
        reject(e);
      }
    }
  });

  return { promise, cancel };
}
