import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Select from 'react-select';
import UILoader from 'components/UI/Loader/Loader';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import TiktokPushAdToLinkedBusinessAccount from '../../graphql/TiktokPushAdToLinkedBusinessAccount.graphql';
import TiktokAdvertiserNames from '../../graphql/TiktokAdvertiserNames.graphql';
import './SparkAdsUsingAuthorizedPosts.scss';

export default function SparkAdsUsingAuthorizedPosts({
  authCode,
  tikTokSparkAdStatusRefetch,
}: {
  authCode?: string;
  tikTokSparkAdStatusRefetch: () => void;
}) {
  const [advertiser, setAdvertiser] = React.useState<{
    value: string;
    label: string;
  } | null>(null);
  const {
    data: tiktokAdvertiserNames,
    loading: tiktokAdvertiserNamesLoading,
  } = useQuery(TiktokAdvertiserNames);

  const [
    onPushAdToLinkedBusinessAccount,
    { loading: pushingAdToLinkedBusinessAccount },
  ] = useMutation(TiktokPushAdToLinkedBusinessAccount, {
    onCompleted: () => {
      tikTokSparkAdStatusRefetch();
    },
  });

  const pushAdToLinkedBusinessAccount = React.useCallback(() => {
    onPushAdToLinkedBusinessAccount({
      variables: {
        authCode,
        advertiserId: advertiser?.value,
      },
    });
  }, [authCode, advertiser?.value, onPushAdToLinkedBusinessAccount]);

  if (tiktokAdvertiserNamesLoading) {
    return <UILoader />;
  }

  if (!tiktokAdvertiserNames?.tiktokAdvertiserNames?.length) {
    return <p>There are no advertiser accounts authed in BEN.</p>;
  }

  return (
    <section className="spark-ads-using-authorized-posts">
      <h1>Authorize advertiser</h1>
      {!authCode ? (
        <p className="description">
          The creator has to accept the Spark Ads auth before we can use the
          authorized post.
        </p>
      ) : (
        <p className="description">
          Please select an authorized advertiser to pass Spark Ads permissions
          to, for this video
        </p>
      )}
      <Select
        value={advertiser}
        className="period-selector"
        placeholder="-- Select --"
        options={tiktokAdvertiserNames.tiktokAdvertiserNames.map(
          (a: { advertiser_id?: string; advertiser_name?: string }) => ({
            value: a?.advertiser_id,
            label: a?.advertiser_name,
          }),
        )}
        onChange={setAdvertiser}
        isClearable
        isDisabled={!authCode || pushingAdToLinkedBusinessAccount}
      />
      <BaseButton
        variant="outlined"
        color="purple"
        text={`Pass Spark Ads permissions to ${advertiser?.label ?? '--'}`}
        disabled={
          !advertiser?.value || !authCode || pushingAdToLinkedBusinessAccount
        }
        onClick={pushAdToLinkedBusinessAccount}
      />
    </section>
  );
}
