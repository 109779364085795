import gql from 'graphql-tag';
import BaseCard from '@UIComponents/BaseCard/BaseCard';
import ListLeftRight, {
  ListLeftRightRow,
} from '@UIComponents/ListLeftRight/ListLeftRight';
import Loading from '@UIComponents/Loading/Loading';
import { useQuery } from '@apollo/react-hooks';
import { useContext } from 'react';
import { ExternalApplicationContext } from 'external/components/Application/Context';

type UserContact = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};

interface CampaignContacts {
  campaign: {
    id: number;
    accountDirector: UserContact;
    accountManager: UserContact;
    teamLead: UserContact;
    campaignManager: UserContact;
    negotiationsManager: UserContact;
    contentManager: UserContact;
  };
}

interface IRoleMap {
  [key: string]: string;
}

const roleMap: IRoleMap = {
  accountDirector: 'Account Director',
  accountManager: 'Account Manager',
  teamLead: 'Team Lead',
  campaignManager: 'Campaign Manager',
  negotiationsManager: 'Negotiations Manager',
  contentManager: 'Content Manager',
};

const contactsHeaders = {
  leftHeader: '',
  rightHeader: '',
};

const contactsQuery = gql`
  query Campaign($id: Int!) {
    campaign(id: $id) {
      id
      accountDirector {
        id
        firstName
        lastName
        email
        __typename
      }
      accountManager {
        id
        firstName
        lastName
        email
        __typename
      }
      teamLead {
        id
        firstName
        lastName
        email
        __typename
      }
      campaignManager {
        id
        firstName
        lastName
        email
        __typename
      }
      negotiationsManager {
        id
        firstName
        lastName
        email
        __typename
      }
      contentManager {
        id
        firstName
        lastName
        email
        __typename
      }
    }
  }
`;

function ContactsRender() {
  const { campaignId } = useContext(ExternalApplicationContext);

  const { data } = useQuery<CampaignContacts>(contactsQuery, {
    variables: {
      id: campaignId,
    },
  });

  if (!data) return <Loading />;

  const entries = Object.entries(data?.campaign) as [string, UserContact][];

  const filtered = entries.filter(
    ([key, value]) => value && key !== 'id' && key !== '__typename',
  );

  const contactsRows: Array<ListLeftRightRow> = filtered.map<ListLeftRightRow>(
    ([key, value]) => {
      const roleName = roleMap[key];
      const userName = `${value.firstName} ${value.lastName}`;
      const callback = () => window.open(`mailto:${value.email}`);

      return { leftRow: roleName, rightRow: userName, callback };
    },
  );

  return (
    /* @ts-ignore */
    <BaseCard transparent dataCy="ContactsRender">
      <ListLeftRight headers={contactsHeaders} rows={contactsRows} />
    </BaseCard>
  );
}

export default ContactsRender;
