import TCMReportMetrics from 'shared/types/TikTokMarketPlace';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import tikTokCommon from 'components/Campaign/Influencer/View/Stats/Performance/columns/tikTok/helpers/tikTokCommon';

export default function hashtagTraffic() {
  const tikTokMetric = 'fct_lifetime_video_performance.video_views_by_source_hashtag' as keyof typeof TCMReportMetrics;
  const metric = 'video_views_by_source-hashtag';
  return {
    ...numberColDef,
    ...tikTokCommon(tikTokMetric, metric),
    headerName: 'Hashtag Traffic',
    width: 215,
  };
}
