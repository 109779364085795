import React from 'react';
import './BaseIcon.scss';

type BaseIconProps = {
  icon: string;
  size?:
    | 'x-small'
    | 'small'
    | 'mid-small'
    | 'medium'
    | 'large'
    | 'x-large'
    | 'huge';
};

const BaseIcon: React.FC<BaseIconProps> = ({ icon, size = 'medium' }) => (
  <span className={`BaseIcon BaseIcon--${size} icon-${icon}`} />
);

export default BaseIcon;
