import React from 'react';
import './Loading.scss';

type LoadingProps = {
  inline?: boolean;
  size?: 'small' | 'medium' | 'large';
  white?: boolean;
};

const Loading: React.FC<LoadingProps> = ({ inline = false, size = 'large', white = false }) => (
  <div className={`Loading ${inline ? 'Loading-wrapper--inline ' : null}`}>
    <div
      className={`Loading-container Loading--${size} ${inline ? 'Loading--inline ' : null} ${
        white ? 'Loading--white ' : null
      }`}
    />
  </div>
);

export default Loading;
