import { useState, useContext, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';
import ApplicationContext from 'components/Application/Context';
import UILoader from 'components/UI/Loader/Loader';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import {
  optimisticSetViewedByClientParams,
  getDeliverableExternalApprovalStatus,
} from 'external/components/Deliverable/utils';
import sortFunction from 'external/utils/sortFunction';
import useListLoader from 'external/utils/useListLoader';
import usePermissions from 'store/authentication/usePermissions';
import { PRODUCTION_EXTERNAL_VIEW_METRICS } from 'components/Metrics/constants/metricTypes';
import StatusFilter from 'external/components/UI/StatusFilter/StatusFilter';
import NoneEnabled from 'external/components/Campaign/Placeholder/NoneEnabled/NoneEnabled';
import NoResultsEmptyState from 'external/components/shared/NoResultsEmptyState';
import NoDetailsEmptyState from 'external/components/shared/NoDetailsEmptyState/NoDetailsEmptyState';
import AiRecommendationIcon from 'external/components/UI/AiRecommendationIcon/AiRecommendationIcon';
import useTabletOrMobile from 'external/utils/useTabletOrMobile';
import useFacets from 'external/utils/useFacets';
import { Deliverable } from 'components/Deliverable/types';

import {
  UpdateViewedByClientDeliverable,
  DeliverablesListQuery,
} from './graphql';

import ExternalDeliverableListRow from './Row/Row';
import DetailsPanel from '../DetailsPanel/DetailsPanel';
import ListHeader from 'external/components/UI/ListHeader/ListHeader';
import './List.scss';

import BaseSection from '@UIComponents/BaseSection/BaseSection';

import { getInitialsMapByDeliverable } from 'external/components/UI/ColoredInitials/ColoredInitials';
import isSimilarityRecommendation from 'external/utils/isSimilarityRecommendation';

const defaultValues = {
  context: 'campaign',
  skip: 0,
  limit: 24,
  sort: 'externalApprovalSortIndex-desc',
};

const listHeaderItems = [
  { title: '', value: 'thumb', gridArea: 'thumb' },
  { title: 'Name', value: 'propertyName', gridArea: 'name' },
  { title: 'Status', value: 'status', gridArea: 'status' },
  { title: 'Notes', value: 'chat', gridArea: 'chat' },
  { title: 'Date Added', value: 'createdAt', gridArea: 'date' },
  {
    title: 'AI',
    value: 'recommendedByAI',
    gridArea: 'recommendedByAI',
  },
];

type DeliverableListProps = {
  campaignId: number;
};

type ListLoaderResponseProps = {
  deliverables: {
    data: Deliverable[];
  };
  meta: {
    total: number;
  };
};

function sortRecommendedByAI(sort: string | null) {
  const order = sort?.split('-').pop();

  return function innerSort(a: Deliverable, b: Deliverable) {
    const vA = get(a, 'createdBy') ?? null;
    const vB = get(b, 'createdBy') ?? null;

    if ((!vA && !vB) || order == null) {
      return 0;
    }

    /* @ts-ignore */
    const varA = isSimilarityRecommendation(vA);
    /* @ts-ignore */
    const varB = isSimilarityRecommendation(vB);

    if (varA === varB) return 0;
    if (order === 'asc') return Number(varB) - Number(varA);
    return Number(varA) - Number(varB);
  };
}

function sortData(
  data: Deliverable[],
  sorting: string | null,
  activeFilter: string | null,
  sortBy: string | null,
) {
  if (!data) {
    return [];
  }

  const sortFn =
    sortBy === 'recommendedByAI'
      ? sortRecommendedByAI(sorting)
      : sortFunction(
          {
            createdAt: 'createdAt',
            propertyName: 'property.name',
            status: 'status',
            chat: 'messagesCount.external',
          },
          sorting,
        );

  const normalizedData = data.map((deliverable) => {
    const { externalApproval, viewedByClient } = deliverable;
    const { state } = getDeliverableExternalApprovalStatus({
      externalApproval,
      viewedByClient,
    });

    return { ...deliverable, status: state };
  });

  return normalizedData
    .filter(({ createdBy, externalApproval }) => {
      if (!activeFilter) {
        return true;
      }

      if (activeFilter === 'recommendedByAI') {
        return isSimilarityRecommendation(createdBy);
      }

      return activeFilter === 'forReview'
        ? externalApproval === null
        : externalApproval === activeFilter;
    })
    .sort(sortFn);
}

function setFilter(
  setActiveFilter: (arg0: string | null) => void,
  activeFilter: string | null,
  filter: string | null,
) {
  if (activeFilter === filter) {
    return setActiveFilter(null);
  }

  return setActiveFilter(filter);
}

function ExternalDeliverableList({ campaignId }: DeliverableListProps) {
  const { user } = useContext(ApplicationContext);
  const { campaign } = useContext(ExternalApplicationContext);
  const campaignsPermissions = usePermissions('campaigns', campaignId);
  const [deliverableId, setDeliverableId] = useState<number | null>(null);
  const isTabletOrMobile = useTabletOrMobile();

  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [sorting, setSorting] = useState<string | null>(null);
  const [sortBy, sortOrder] = sorting?.split('-') ?? [];

  const [setViewedByClient] = useMutation(UpdateViewedByClientDeliverable);
  const enableEdit = campaign?.clientAccessTabs?.listReview;

  const DEFAULT_VARIABLES = {
    metricConfig: PRODUCTION_EXTERNAL_VIEW_METRICS,
    limit: defaultValues.limit,
    sort: defaultValues.sort,
    filter: {
      displayToClient: true,
      campaignId,
      executionStatus: {
        phase: 'list',
      },
    },
    campaignsSeeSensitiveData: Boolean(campaignsPermissions?.seeSensitiveData),
  };

  const { data, meta } = useListLoader({
    entityName: 'deliverables',
    skip: undefined,
    query: DeliverablesListQuery,
    variables: DEFAULT_VARIABLES,
    onCompleted: (response: ListLoaderResponseProps) => {
      if (
        !isTabletOrMobile &&
        response?.deliverables?.data?.length &&
        response?.deliverables?.data?.length <= defaultValues.limit &&
        deliverableId === null
      ) {
        /* @ts-ignore */
        setDeliverableId(response.deliverables.data[0].id);
      }
    },
  });

  const selectedDeliverable = data?.find(
    (d: Deliverable) => d.id === deliverableId,
  );

  const handleDeliverableClick = async (deliverable: Deliverable) => {
    const { id, viewedByClient } = deliverable;
    if (user?.role === 'external' && !viewedByClient) {
      setViewedByClient(optimisticSetViewedByClientParams({ id }));
    }

    /* @ts-ignore */
    setDeliverableId(id);
  };

  const sortedData = useMemo(
    () => sortData(data, sorting, activeFilter, sortBy),
    [activeFilter, sorting, data],
  );

  const facets = useFacets(data);
  const loadedData = data && data?.length === meta.total;

  if (!loadedData) return <UILoader className="external-content__loader" />;

  if (!data?.length) {
    return (
      /* @ts-ignore */
      <BaseSection title="Creator review" type="main">
        <NoneEnabled />
      </BaseSection>
    );
  }

  const initialsMap = getInitialsMapByDeliverable(sortedData);

  return (
    /* @ts-ignore */
    <BaseSection title="Creator review" type="main" wide>
      <div className="creators-grid DeliverableList">
        <div className="grid-box creators-list external-content__list">
          <h3 className="grid-box-list-title">Creator list</h3>

          <div className="external-content__list-actions">
            <div className="external-content__list-actions__filters">
              <StatusFilter
                recommendedByAI
                tab="Creators"
                facets={facets}
                onFilterChangeCallback={(filter) => {
                  setFilter(setActiveFilter, activeFilter, filter);
                }}
              />
            </div>
          </div>
          {sortedData?.length ? (
            <>
              <ListHeader
                setSorting={setSorting}
                sorting={sorting}
                sortBy={sortBy}
                sortOrder={sortOrder}
                items={listHeaderItems}
                gridTemplate="deliverable"
              />
              <div className="campaign-deliverables__list">
                {sortedData?.map((item) => (
                  <ExternalDeliverableListRow
                    key={item.id}
                    isSelected={deliverableId === item.id}
                    item={item}
                    onClick={() => handleDeliverableClick(item)}
                    /* @ts-ignore */
                    initials={initialsMap.get(item.id)}
                  />
                ))}
              </div>
              <div className="recommended-by-ai-info">
                <AiRecommendationIcon />= BEN AI recommendation
              </div>
            </>
          ) : (
            <NoResultsEmptyState />
          )}
        </div>
        <div className="grid-box creators-card">
          {selectedDeliverable ? (
            <DetailsPanel
              key={selectedDeliverable.id}
              deliverable={selectedDeliverable}
              enableEdit={enableEdit}
              campaignReportingWindow={campaign?.reportingWindow}
              onClickClosePanel={() => setDeliverableId(null)}
              initials={initialsMap.get(selectedDeliverable.id)}
            />
          ) : (
            <NoDetailsEmptyState />
          )}
        </div>
      </div>
    </BaseSection>
  );
}

export default ExternalDeliverableList;
