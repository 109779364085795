import percent from 'components/DataGrid/formatters/percent';
import percentColDef from 'components/DataGrid/Destination/colDef/percentColDef';
import { Placement } from 'shared/types/Placement';
import { TIKTOK_PLATFORM, YOUTUBE_PLATFORM } from 'shared/metrics/metrics';
import getYouTubeDistributionDataFromCampaignService from '../youTube/helpers/getYouTubeDistributionDataFromCampaignService';
import basePerformanceColDef from './baseColDef';
import getTikTokDataFromInsights from '../tikTok/helpers/getTikTokDataFromInsight';

const youTubeMetric = 'viewers_by_gender';
const youTubeMetricKey = 'Female';
const metric = 'gender-female';

export function genderFemaleAutomatedValueGetter(data: Placement) {
  if (data?.platform === TIKTOK_PLATFORM) {
    return getTikTokDataFromInsights(
      data,
      'fct_lifetime_video_performance.lifetime_female_distribution',
    );
  }
  if (data?.platform === YOUTUBE_PLATFORM) {
    return getYouTubeDistributionDataFromCampaignService(
      data,
      [youTubeMetric, youTubeMetricKey],
      { parsePercentageFromString: true },
    );
  }
  return null;
}

export default function genderFemale() {
  return {
    ...percentColDef,
    ...basePerformanceColDef(metric, genderFemaleAutomatedValueGetter, [
      YOUTUBE_PLATFORM,
      TIKTOK_PLATFORM,
    ]),
    headerName: 'Female',
    width: 165,
    valueFormatter: ({ value }: { value?: number | null }) =>
      percent({ value }),
  };
}
